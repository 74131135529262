import { cacheDatabase } from "../firebase/firebase.config";

type Response = {
  slug: string;
  uid: string;
  userName: string;
  profileLogoUrl?: string;
  country?: string;
};
export async function searchUsernameByName(
  userName: string,
  platform: "WOG" | "LEGION" | string,
  limit = 10
) {
  const userNameDocs = await cacheDatabase
    .ref("/userNames")
    .child(platform)
    .orderByChild("userNameIndex")
    .startAt(userName)
    .endAt(userName + "\uf8ff")
    .limitToFirst(limit)
    .get();

  const users: Response[] = [];
  userNameDocs.forEach((val) => {
    const user = val.val() as Response;
    users.push(user);
  });
  return users;
}

export async function getAuthorProfileBasedOnUid(
  uid: string,
  platform: "WOG" | "LEGION" | string
) {
  const userNameDoc = await cacheDatabase
    .ref("/userNames")
    .child(platform)
    .orderByChild("uid")
    .equalTo(uid)
    .get();

  const users: Response[] = [];
  userNameDoc.forEach((val) => {
    const user = val.val() as Response;
    users.push(user);
  });
  return users[0];
}

export async function getAuthorProfileBasedOnUserName(
  userName: string,
  platform: "WOG" | "LEGION" | string
) {
  const userNameDoc = await cacheDatabase
    .ref("/userNames")
    .child(platform)
    .child(userName)
    .get();

  if (userNameDoc.val()) {
    return [
      {
        ...userNameDoc.val(),
      },
    ];
  }
  return false;
}
