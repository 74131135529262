import React from "react";
import "./styles/_selectDropdownFilter.scss";
export const SelectDropdownFilter: React.FunctionComponent<{
  setState: any;
  state: any;
  optionsList: {
    name: string;
    value: string;
  }[];
  defaultSelected: string;
  hideDefault?: boolean;
}> = (props) => {
  return (
    <select
      className=""
      id={props.defaultSelected}
      onChange={(e: any) => {
        props.setState(e.target.value);
      }}
    >
      {props.optionsList &&
        props.optionsList.map((option) => {
          return (
            <option
              // selected={props.state === option.value}
              className={props.state === option.value ? "selected" : ""} // selected is unused in css/ remove className if not needed
              value={option.value}
              key={option.value}
            >
              {option.name}
            </option>
          );
        })}

      {!props.optionsList && <option>Error</option>}
    </select>
  );
};
