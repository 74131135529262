import { FC } from "react";
import ChallengeCardComponent from "./ChallengeCardComponent";
import { Timestamp } from "@/api/firebase/firebase.config";
import { AuthorProfile } from "@/models/database/profiles/profile.model";

type Props = {
  name: string;
  slug: string;
  goal: string;
  game: string;
  logoUrl: string;
  createdBy: string;
  creatorType: string;
  startDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  endDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  created: Timestamp;
  fieldToCompare: string[];
  author: AuthorProfile;
};

export const ChallengeCard: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <ChallengeCardComponent
        key={props.slug}
        slug={props.slug}
        text={props.goal ?? "No goal provided."}
        name={props.name}
        game={props.game}
        logo={props.logoUrl}
        createdBy={props.createdBy}
        creatorType={props.creatorType}
        startDate={props.startDate}
        endDate={props.endDate}
        fieldToCompare={props.fieldToCompare}
        author={props.author}
      />
    </>
  );
};
