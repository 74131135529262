import confetti from "canvas-confetti";

export const cannonEffect = () => {
  const runEffect = () => {
    return confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  return runEffect();
};
