type Tab = {
  title: string;
  inputs: string[];
};

const PreferencesData: {
  tabOne: Tab;
  tabTwo: Tab;
  tabThree: Tab;
  tabFour: Tab;
} = {
  tabOne: {
    title: "Social and feed notifications",
    inputs: [
      "Enable notifications in chat bot",
      "Get all notifications",
      "New messages",
      "Friend requests",
      "New friends",
      "New followers",
      "New posts in feed",
      "New comments on your posts",
      "New likes",
    ],
  },
  tabTwo: {
    title: "Challenges notifications",
    inputs: [
      "Enable notifications in chat bot",
      "Get all notifications",
      "Leaderboard updates",
      "New participants in your challenges",
    ],
  },
  tabThree: {
    title: "Teams notifications",
    inputs: [
      "Enable notifications in chat bot",
      "Get all notifications",
      "New posts in feed",
      "New comments in feed",
      "Member requests in your teams",
      "Player of the month updates",
      "Upcoming events",
    ],
  },
  tabFour: {
    title: "Gathers notifications",
    inputs: [
      "Enable notifications in chat bot",
      "Get all notifications",
      "Changes of location or schedule",
      "New discussions",
      "New comments in discussions",
      "New participants",
      "Requests to participate in your gathers",
    ],
  },
};

export default PreferencesData;
