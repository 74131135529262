import { FC } from "react";

const TeamsAdminsListLoader: FC = (): JSX.Element => {
  return (
    <div className="content__permissions">
      {/* Type */}
      <div className="admins">
        <fieldset className="input-group">
          <legend className="input-group__heading">Admins</legend>
          <div className="user user__admin placeholder">
            <div className="user__avatar">
              <div className="avatar">
                <div className="avatar__content">
                </div>
              </div>
            </div>
            <div className="user__content">
              <div className="user__name">
                <span className="user__username" />
                <span className="user__fullname" />
              </div>
            </div>
            <div className="user__actions">
              <div className="user__role" />
                <div className="user__options" />
            </div>
          </div>

        </fieldset>
      </div>
    </div>
  );
};

export default TeamsAdminsListLoader;