import firebase from "firebase/app";

export default async function (activisionId: string, currUserId?: string) {
  if (!activisionId || !currUserId) return false;

  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("isActivisionIdValidForCOD");

  try {
    const result = await func({ currUserId, activisionId });

    return !!result.data;
  } catch (error: any) {
    return error.message || "An error occurred. Please try later.";
  }
}
