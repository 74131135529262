import { firestore } from "@/api/firebase/firebase.config";

export async function _deleteFirebaseDoc(
  collectionName: string,
  docId: string,
  whereStart?: string,
  whereEnd?: string
) {
  if (!whereStart) {
    firestore.collection(collectionName).doc(docId).delete();
  }
  if (whereStart && whereEnd) {
    const results = await firestore
      .collection(collectionName)
      .where(whereStart, "==", whereEnd)
      .get();

    results.docs.map(async (doc) => {
      await firestore.collection(collectionName).doc(doc.id).delete();
    });
  }
}
