import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import BaseModalWindow from "../BaseModalWindow/BaseModalWindow";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { useNavigate } from "react-router-dom";
import { createToast } from "@/utils/toaster/createToast";
import { BaseTextInput } from "../BaseTextInput/BaseTextInput";
import EmojiContainer from "../EmojiWrapper";
import CloudFunctions from "./../../api/cloudFunctions/CloudFunctions";
import { Spinner } from "..";
import { SinglePostComponent } from "@/features/feed";
import { PostData } from "@/features/feed/types/singlePostsProps";
export const ShareContentModalWindow: React.FunctionComponent<{
  closeWindow: () => void;
  show: boolean;
  sharedFrom: "gather" | "team" | "challenge" | "news" | "post";
  createType: "share" | "create";

  additionalData?: any;

  postData: PostData;
}> = ({
  show,
  sharedFrom,
  closeWindow,
  createType,
  additionalData,
  postData,
}) => {
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [shareText, setShareText] = useState("");
  const [emojiOpen, setEmojiOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (!currentUser.uid) {
      navigate("/sign-up-1");
      closeWindow();
      return;
    }
  }, [currentUser.uid, history]);

  const shareExistingPost = async () => {
    createToast({
      type: "info",
      message: "Sharing post...",
    });
    setLoading(false);
    closeWindow();
    await CloudFunctions.sharePost(postData.id, currentUser.uid!, shareText)
      .then(() => {
        createToast({
          type: "success",
          message: "Shared post!",
        });
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to share post, please try again later!",
        });
      });
  };

  const shareChallengeAsPost = async () => {
    await CloudFunctions.shareChallengeAsPost(
      postData.id,
      currentUser.uid!,
      shareText
    ).then(() => {
      createToast({
        type: "success",
        message: "Shared post!",
      });
      closeWindow();
    });
  };

  const shareItemAsPost = async (itemType: "gather" | "team" | "news") => {
    await QueryDB.createNewDoc("posts", {
      [`${itemType}Id`]: postData.id,
      isVisible: true,
      postText: shareText,
      type: postData.type,
      uid: currentUser.uid!,
      totalSharesNumber: 0,
      postedIn: "feed",
    }).then(() => {
      createToast({
        type: "success",
        message: `Shared ${sharedFrom} to profile and feed!`,
      });
    });
  };
  return (
    <BaseModalWindow
      show={show}
      closeModal={closeWindow}
      size={
        postData.type === "userPost"
          ? "modal--share-post"
          : "modal--share-challenge"
      }
      title={`Share ${sharedFrom} on your profile`}
    >
      <div className="share-post">
        <div id="share-post__content">
          <div className="post-form__input sharing-form__input">
            <div className="form">
              <div className="form__content">
                <div className="form__input">
                  <BaseTextInput
                    label=""
                    textType="text"
                    value={shareText}
                    placeholder="Write a comment before sharing"
                    setSingleState={setShareText}
                    error={undefined}
                  />
                  <EmojiContainer
                    setInputText={setShareText}
                    inputText={shareText ?? undefined}
                    setShowImoji={() => setEmojiOpen(!emojiOpen)}
                    showImoji={emojiOpen}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {postData && (
              <SinglePostComponent
                postData={postData}
                isFullPagePost={true}
                actionButtons={false}
                isInModal
                isFriendsOfCurrentUser={false}
                likeParentId={postData.id}
              />
            )}
          </div>
        </div>

        <div className="share-post__actions">
          <Button variant="secondary" onClick={closeWindow} className="">
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              setLoading(true);

              if (createType === "share") {
                // Share any as existing post
                await shareExistingPost();
              }

              if (createType === "create") {
                // Share news as new post

                if (sharedFrom === "news") {
                  await shareItemAsPost("news");
                }
                // Share challenge as new post
                if (sharedFrom === "challenge") {
                  await shareChallengeAsPost();
                }

                if (sharedFrom === "team") {
                  await shareItemAsPost("team");
                }

                if (sharedFrom === "gather") {
                  await shareItemAsPost("gather");
                }
              }
              setLoading(false);
              closeWindow();
            }}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Share"}
          </Button>
        </div>
      </div>
    </BaseModalWindow>
  );
};
