import { auth } from "@/api/firebase/firebase.config";
import firebase from "firebase/app";

export const _getUserStatus = async (uid?: string) => {
  if (uid) {
    const dbRef = firebase.database().ref(`status/${uid}/state`);
    const snapshot = await dbRef.get();
    if (snapshot.exists()) {
      return snapshot.val() === "online";
    }
    return false;
  }
  return false;
};
