import { Button } from "@/components";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { mobileLinksAndPages } from "../utils/ProfileMobileLinksAndPages";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import i18next from "i18next";

export const ProfileTabsMobile: FC<{}> = () => {
  const navigate = useNavigate();
  return (
    <div className="profile__mobile">
      <div className="profile__tab-grid">
        {mobileLinksAndPages.map((obj, index) => {
          const key = `${obj.tab}_${index}`;
          if (obj.hasFeature) {
            return (
              <Button
                key={key}
                onClickAction={() =>
                  navigate(`${siteRoutes["profileTabAndSlug"]}${obj.slug}`)
                }
                variant="secondary"
                customStyles="profile__link"
              >
                <img src={obj.vector} alt="" className="profile__link--team" />
                <img src={obj.icon} alt="" className="profile__link--icon" />

                {/* <span>{obj.tab}</span> */}
                {i18next.t(`profile.tabs.${obj.tab.toLocaleLowerCase()}`)}
              </Button>
            );
          }
        })}
      </div>
    </div>
  );
};
