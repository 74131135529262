import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { ReactComponent as IconGather } from "../../assets/share-news.svg";
import { Query_shared } from "../../../../api/shared/index";
import { Query_Imgix } from "../../../../api/imgix/index";

const SharedNews: React.FunctionComponent<{
  data: any;
  isFromFeed: boolean;
}> = ({ data, isFromFeed }) => {
  const [logoError, setLogoError] = useState<boolean>(false);
  if (data) {
    try {
      return (
        <Link
          to={`/news/${data.additionalData?.slug || data.slug}`}
          title={data.additionalData?.title || data.title}
        >
          <div
            aria-hidden
            className={`shared-news ${isFromFeed ? "feed" : ""}`}
          >
            <div className="shared-news__image">
              <div className="image">
                <div className="image__content">
                  <Image
                    onError={() => setLogoError(true)}
                    alt={`User-uploaded image for ${
                      data.additionalData?.slug || data.slug
                    } team`}
                    src={Query_shared.imageErrorHandler(
                      data.additionalData?.imageURL.includes("wog.imgix") ||
                        data.additionalData?.imageURL.includes("iplay.imgix") ||
                        data.postPhoto?.includes("wog.imgix") ||
                        data.postPhoto?.includes("iplay.imgix")
                        ? `${
                            data.additionalData?.imageURL || data.postPhoto
                          }?${Query_Imgix.getImgixUrlProps({
                            w: "248",
                            h: "165",
                          })}`
                        : data.additionalData?.imageURL || data.postPhoto,
                      logoError,
                      undefined
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="shared-news__content">
              <h3 className="shared-news__heading">
                {data.additionalData?.title || data.title}
              </h3>
              <p className="shared-news__excerpt">
                {data.additionalData?.intro || data.postText}
              </p>
            </div>
            <div className="shared-news__icon">
              <IconGather width={36} height={36} />
            </div>
          </div>
        </Link>
      );
    } catch (error) {
      return (
        <em>
          Failed to load shared news article, it might no longer be available.
        </em>
      );
    }
  } else {
    return (
      <em>
        Failed to load shared news article, it might no longer be available.
      </em>
    );
  }
};

export default SharedNews;
