import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { FC, useEffect } from "react";
import { ReactComponent as IconCheck } from "../../../../assets/iconCheck.svg";
import { ReactComponent as IconCross } from "../../../../assets/iconCross.svg";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { AuthenticationResponse } from "@/features/auth/types/authenticationTypes";
import { Button, Spinner } from "@/components";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";

export const ChangePasswordResetPassword: FC<{
  accountCredentials: {
    email: string;
    password: string | undefined;
    passwordVerify: string | undefined;
  };
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  isPasswordValid: boolean;
  passwordsMatch: boolean;
  code: {
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
  handleReset: any;
}> = ({
  accountCredentials,
  setFieldValue,
  isPasswordValid,
  passwordsMatch,
  code,
  handleReset,
}) => {
  const passwordRules = [
    {
      rule: "Min. 8 characters",
      isValid: accountCredentials.password?.length >= 8,
    },
    {
      rule: "One uppercase character",
      isValid: /^(?=.*[A-Z])/.test(accountCredentials.password),
    },
    {
      rule: "One number",
      isValid: /^(?=.*\d)/.test(accountCredentials.password),
    },
    {
      rule: "One lowercase character",
      isValid: /^(?=.*[a-z])/.test(accountCredentials.password),
    },
    {
      rule: "Passwords match",
      isValid:
        accountCredentials.password !== "" &&
        accountCredentials.password === accountCredentials.passwordVerify,
    },
    {
      rule: "One special character",
      isValid: /^(?=.*[0-9])(?=.*[!@#$%^&*()+\-\\[\]{};:'",.<>/?]).{6,}$/.test(
        accountCredentials.password
      ),
    },
  ];
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  const handleNextStep = async () => {
    let concatenatedString = "";

    Object.keys(code).forEach((key) => {
      const value = code[key];
      if (typeof value === "number") {
        concatenatedString += value.toString();
      }
    });

    await authenticate({
      method: "email",
      platform: brand.brandValue,
      action: "reset-password",
      newPassword: accountCredentials.password,
      emailAddress: accountCredentials.email,
      passwordResetCode: concatenatedString,
    });
  };

  useEffect(() => {
    if (
      code &&
      response &&
      accountCredentials.password &&
      accountCredentials.passwordVerify &&
      passwordsMatch
    ) {
      if (response.status === "password-reset-success") {
        createToast({
          type: "success",
          message: "Password successfully updated",
        });
        handleReset();
      } else {
        createToast({
          type: "info",
          message: response.payload,
        });
      }
    }
  }, [response]);

  return (
    <>
      {/* New password */}
      <div>
        <BaseTextInput
          label="New password"
          textType="password"
          value={accountCredentials.password}
          placeholder=""
          setObjectState={setFieldValue}
          setObjectStateTargetName="password"
          error={
            accountCredentials.password &&
            !isPasswordValid &&
            "Password does not meet the requirements"
          }
        />
      </div>

      {/* Repeat new password */}
      {accountCredentials.password && isPasswordValid && (
        <div>
          <BaseTextInput
            label="Repeat new password"
            textType="password"
            value={accountCredentials.passwordVerify}
            placeholder=""
            setObjectState={setFieldValue}
            setObjectStateTargetName="passwordVerify"
            error={
              accountCredentials.password &&
              !isPasswordValid &&
              "Password does not meet the requirements"
            }
          />
        </div>
      )}

      <Button
        disabled={!isPasswordValid || !passwordsMatch}
        variant="primary"
        onClickAction={async (e) => {
          handleNextStep();
        }}
      >
        {_loading ? <Spinner /> : "Reset password"}
      </Button>

      <div className="rules">
        <p>Password must consist of at least:</p>
        <ul>
          {passwordRules.map((passRule, index) => {
            const key = `passRule_${index}`;
            return (
              <li className={passRule.isValid ? "valid" : ""} key={key}>
                {passRule.isValid ? <IconCheck /> : <IconCross />}
                {passRule.rule}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
