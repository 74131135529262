import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";

const LandingHeading: React.FC = () => {    
    return (                   
        <div className="landing__heading">
            <div>
                <h2>
                    {i18next.t("landing.heading.headline")}
                </h2>
                <p>
                    {i18next.t("landing.heading.description")}
                </p>
            </div>
        </div>
    );
};

export default LandingHeading;