import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import React, { memo, FC, useState } from "react";

type ChatViewProps = {
  chatMedia: string[];
};

export const ChatMedia: FC<ChatViewProps> = ({ chatMedia }): JSX.Element => {
  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<string>("");

  return (
    <>
      <BaseModalWindow
        show={imageModalOpen}
        closeModal={() => {
          setImageModalOpen(false);
        }}
        styles="chat-media__modal--single"
      >
        <img
          aria-hidden
          onClick={() => {
            setImageModalOpen(false);
          }}
          src={activeImage}
          alt=""
        />
      </BaseModalWindow>

      <div className="chat-media">
        <h3>Media</h3>
        <ul>
          {chatMedia &&
            chatMedia.map((media, index) => {
              return (
                <li key={`chatMedia_${index}`}>
                  <figure className="aspect">
                    <picture className="aspect-ratio">
                      <img
                        aria-hidden
                        onClick={() => {
                          setActiveImage(media);
                          setImageModalOpen(true);
                        }}
                        src={media}
                        alt=""
                      />
                    </picture>
                  </figure>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};
