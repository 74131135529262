import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorFallback } from "@/components";
import { singleNewsOverviewTypes } from "../../types/NewsTypes";
import { SingleNewsUI } from "./SingleNews.ui";
import NewsSingleLoader from "@/components/skeletonLoaders/NewsSingleLoader";
import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import { getBrandVariables } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useAtom } from "jotai";
import { visitedNewsAtom } from "@/utils/atoms/newsAtoms/newsAtoms";
import BrandProps from "../../../../../branchAndBrandSpecific/utils/brandVariables/brands/BrandProps";

export const SingleNews: FC = (): JSX.Element => {
  const { slug } = useParams();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [visitedNewsAtomData, setVisitedNewsAtomData] =
    useAtom(visitedNewsAtom);

  // Check if doc is visited before, if so set as default state for newsData
  const visitedNewsDoc = visitedNewsAtomData?.[slug];

  const [newsData, setNewsData] = useState<singleNewsOverviewTypes[]>(
    visitedNewsDoc || undefined
  );

  // getSingleNews
  const fetch = async () => {
    // Only set loading true if fetching new news
    if (!visitedNewsDoc || !newsData) {
      setLoading(true);
    }

    // Get doc
    const newsDoc = (
      await collectionDoc.news(decodeURIComponent(slug!))
    ).data();

    // Get author
    const response = getBrandVariables(newsDoc.client) as unknown as BrandProps;

    // const author = getBrandVariables(doc.data().client) as BrandProps;

    if (!newsDoc) {
      setError(true);
    }

    // If Doc has been loaded before
    if (visitedNewsDoc) {
      let deepArr = visitedNewsAtomData[slug];
      deepArr = newsDoc;
      const updatedItems = deepArr;

      // update existing doc with new data
      setVisitedNewsAtomData(updatedItems);
    } else {
      // If Doc has not been loaded before
      setVisitedNewsAtomData({
        ...(visitedNewsAtomData || []),
        [slug]: {
          ...newsDoc,
          author: {
            fullName: "",
            profileLogoUrl: response.logos.small,
            slug: "",
            uid: "",
            userName: response.name,
          },
        },
      });
    }

    // Set data to show to latest data
    setNewsData({
      ...newsDoc,
      author: {
        fullName: "",
        profileLogoUrl: response.logos.small,
        slug: "",
        uid: "",
        userName: response.name,
      },
    } as any);

    setLoading(false);
  };

  useEffect(() => {
    if (slug) {
      fetch();
    }
  }, [slug]);

  if (error) {
    return <ErrorFallback>Failed to load news article</ErrorFallback>;
  }

  if (loading) {
    return <NewsSingleLoader />;
  }

  return (
    <>
      {!loading && !error && (
        <>{newsData && <SingleNewsUI data={newsData as any} />}</>
      )}
    </>
  );
};
