import { FC } from "react";
import { OverviewArchive, SelectDropdownFilter } from "@/components";
import i18next from "i18next";
import background from "../../assets/background/overview-gathers.jpg";
import close_icon from "@/assets/actions/close.svg";
import { GatherUiProps } from "../../types/GatherTypes";
import { GameFilterPicker } from "@/components/GameFilterPicker.tsx/GameFilterPicker";
import {
  GatherSortByOptions,
  StatusOptions,
} from "@/utils/constants/dropDownOption";

export const GatherOverviewUi: FC<GatherUiProps> = ({
  selectedCreatorType,
  setSelectedCreatorType,
  loading,
  selectedGame,
  setSelectedGames,
  selectedSortType,
  setSelectedSortType,
  selectedStatus,
  setSelectedStatus,
  children,
}) => {
  const filterButtons = [
    {
      text: "overview.gathers.badges.new",
      value: "NEW" as "NEW" | "MINE",
    },
    {
      text: "overview.gathers.badges.mine",
      value: "MINE" as "NEW" | "MINE",
    },
  ];

  return (
    <>
      <OverviewArchive
        headline={i18next.t("strings.gathers")}
        linkToBtnText="overview.gathers.create_btn"
        linkToBtnLink="/create-gather"
        subtitle={i18next.t("overview.gathers.desc")}
        filterButtons={filterButtons}
        selectedFilter={selectedCreatorType}
        setSelectedFilter={setSelectedCreatorType}
        disableBadges={loading}
        background={background}
      />

      <div className="filters">
        <div className="filters__heading">
          <h4>
            {i18next.t(
              filterButtons.find((obj) => obj.value === selectedCreatorType)
                .text
            )}
          </h4>
        </div>
        <div className="filters__filter">
          {(selectedCreatorType === "NEW" && selectedGame !== undefined) ||
          selectedSortType !== "date" ||
          selectedStatus !== "active" ? (
            <div
              aria-hidden
              onClick={() => {
                setSelectedCreatorType("NEW");

                setSelectedGames(undefined);

                setSelectedSortType("date");

                setSelectedStatus("active");
              }}
              className="filters-wrapper"
            >
              <img
                width={36}
                height={36}
                src={close_icon}
                className=""
                alt=""
              />
            </div>
          ) : (
            ""
          )}

          {selectedCreatorType === "NEW" && (
            <div className="filters__options">
              <SelectDropdownFilter
                setState={setSelectedSortType}
                state={selectedSortType}
                defaultSelected="date"
                optionsList={GatherSortByOptions}
                hideDefault
              />
              <SelectDropdownFilter
                setState={setSelectedStatus}
                state={selectedStatus}
                defaultSelected="active"
                optionsList={StatusOptions}
                hideDefault
              />
              <GameFilterPicker
                selectedGame={selectedGame}
                setSelectedGame={setSelectedGames}
                setUpdateTrigger={undefined}
                filterLocation="gathers"
              />
            </div>
          )}
        </div>
      </div>

      {children}
    </>
  );
};
