import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import React, { FC, useEffect } from "react";
import { SnazzyEmeraldStyles } from "./../utils/SnazzyMapsStyles";
import SnazzyMarker2 from "../assets/mapMarker.svg";
import { Spinner } from "@/components";

export const BestGamerHomePageMap: FC<{}> = ({}) => {
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    country: "est",
  };

  const [data, loading, error, fetch_api] = useCloudFunctionCall(
    "getGoogleMapApi",
    undefined
  );

  //   Get api key
  useEffect(() => {
    const getKey = async () => {
      await fetch_api({}).then(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCErxVmyTaDZVDlh7cEYvRHCX139bTH1eo&libraries=places`;
        script.async = true;
        script.id = "googleMapsApi";
        script.onload = () => handleMap();
        document.body.appendChild(script);
      });
    };
    getKey();
  }, []);

  //   Render map
  const handleMap = () => {
    const iconImage = document.createElement("img");
    iconImage.src =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png";

    const map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 56.12190101119821, lng: 11.01111737986426 }, // Coordinates for the center of the map
      zoom: 8.4, // Zoom level,
      styles: SnazzyEmeraldStyles,
    });

    const markers = [
      {
        loc: { lat: 56.147940, lng: 10.204650 },
        title: "Aarhus",
        description: "Storcenter Nord",
        dates: "8. februar 2025",
      },
      {
        loc: { lat: 55.382490, lng: 10.428080 },
        title: "Odense",
        description: "Rosengårdcentret",
        dates: "14. februar 2025",
      },
      {
        loc: { lat: 55.678829, lng: 12.456710 },
        title: "Rødovre",
        description: "Rødovre Centrum",
        dates: "15. februar 2025",
      },
    ];

    markers.forEach((marker, index) => {
      const markerObj = new google.maps.Marker({
        position: marker.loc,
        map: map,
        title: marker.title,
        icon: SnazzyMarker2,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `<div class="map__marker">
            <div class="marker">
            <div class="marker__date"}>${marker.dates}</div>
            <div class="marker__title">${marker.title}</div>
            <div class="marker__description">${marker.description}</div>
            </div></div>`,
      });

      infoWindow.open(map, markerObj);
    });
  };
  if (loading) {
    return <Spinner />;
  }
  return <div id="map" className="map" />;
};
