import { FC, useState } from "react";
import { useChallenges } from "../hooks/useChallengesAlternate";
import { Badge } from "@/components";
import { useAtom } from "jotai";
import { ChallengesStatusAtoms } from "../utils/ChallengeItemAtom";
import ChallengeCardAlt from "../components/challengeCard/ChallengeCardAlt";
import { games } from "../utils/games";
import ChallengeCardAltLoader from "@/components/skeletonLoaders/ChallengeCardAlt";
import useDocumentTitle from "@/hooks/useDocumentTitle";

const ChallengesPage: FC = (): JSX.Element => {
  const [status, setStatus] = useAtom<string>(ChallengesStatusAtoms);
  const { challengeData, loadMoreChallenges, isLoading, isEmpty } =
    useChallenges(status);
  const [selectedGame, setSelectedGame] = useState<string | null>(null);

  const filteredChallengeData = selectedGame
    ? challengeData[status].filter(
        (challenge: any) => challenge.gameType === selectedGame
      )
    : challengeData[status];

  const handleButtonClickOngoing = () => {
    setStatus("ongoing");
    setSelectedGame(null);
  };
  const handleButtonClick = () => {
    setStatus("finished");
    setSelectedGame(null);
  };

  const doesGameExistInChallenges = (gameValue: string) => {
    return challengeData[status].some(
      (challenge: any) => challenge.gameType === gameValue
    );
  };

  useDocumentTitle("Challenges");

  return (
    <div className="page page__challenges">
      <h1>Challenges</h1>

      <div className="badge-wrapper">
        <Badge
          variant={status === "ongoing" ? "primary" : "secondary"}
          onClickAction={handleButtonClickOngoing}
        >
          Ongoing
        </Badge>
        <Badge
          variant={status === "finished" ? "primary" : "secondary"}
          onClickAction={handleButtonClick}
        >
          Finished
        </Badge>
      </div>

      <div className="badge-wrapper">
        <Badge
          variant={selectedGame === null ? "primary" : "secondary"}
          onClickAction={() => setSelectedGame(null)}
        >
          All games
        </Badge>
        {games.map(
          (game) =>
            doesGameExistInChallenges(game.value) && (
              <Badge
                variant={selectedGame === game.value ? "primary" : "secondary"}
                onClickAction={() => setSelectedGame(game.value)}
              >
                {game.label}
              </Badge>
            )
        )}
        {selectedGame && (
          <>
            <Badge
              variant="secondary"
              onClickAction={() => setSelectedGame(null)}
            >
              Clear filter
            </Badge>
          </>
        )}
      </div>

      {isLoading && <div className="loader">Loading...</div>}

      {isEmpty[status] && challengeData[status] == 0 && (
        <div className="empty">
          <p>There are no {status} challenges</p>
        </div>
      )}

      <div className="challengesAlt__list">
        {!isLoading &&
          (filteredChallengeData
            ? filteredChallengeData.map((challenge: any) => (
                <ChallengeCardAlt
                  key={challenge.id}
                  id={challenge.id}
                  game={challenge.game}
                  name={challenge.name}
                  slug={challenge.slug}
                  logoUrl={challenge.logoUrl}
                  challengeAdditionalData={challenge.challengeAdditionalData}
                  startDate={challenge.created}
                  endDate={challenge.endDate}
                  description={challenge.description}
                  createdBy={challenge.createdBy}
                  client={challenge.client}
                />
              ))
            : challengeData[status].map((challenge) => (
                <ChallengeCardAlt
                  key={challenge.id}
                  id={challenge.id}
                  game={challenge.game}
                  name={challenge.name}
                  slug={challenge.slug}
                  logoUrl={challenge.logoUrl}
                  challengeAdditionalData={challenge.challengeAdditionalData}
                  startDate={challenge.created}
                  endDate={challenge.endDate}
                  description={challenge.description}
                  createdBy={challenge.createdBy}
                  client={challenge.client}
                />
              )))}

        {isLoading
          ? Array.from(Array(5), (_, ind) => {
              return <ChallengeCardAltLoader key={ind} />;
            })
          : ""}
      </div>

      {!isEmpty[status] && (
        <button className="btn btn-primary" onClick={loadMoreChallenges}>
          Load more challenges
        </button>
      )}

      {isEmpty[status] && challengeData[status] != 0 && (
        <div className="empty">
          <p>That's it! There are no more {status} challenges.</p>
        </div>
      )}
    </div>
  );
};

export default ChallengesPage;
