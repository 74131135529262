import SingleFeaturedNewsSlider from "./FeaturedNewsSlider";

export const NewsOverviewUi = (props): JSX.Element => {
  return (
    <>
      {/* News slider */}
      <SingleFeaturedNewsSlider
        featuredNewsLoading={props.featuredNewsLoading}
        featuredNews={props.featuredNews}
      />

      {/* Cards list */}
      {props.children}
    </>
  );
};
