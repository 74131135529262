export const handleScrollToTreasureHunt = (hasAllCodes, dataId) => {
  const element = document.querySelector(`[data-id='${dataId}']`);
  return window.scrollTo({
    top: 0,
  });
  // if (hasAllCodes) {
  // }

  // if (!hasAllCodes && element) {
  //   return element.scrollIntoView({ behavior: "smooth", block: "center" });
  // } else {
  //   return console.log(`Element with data-id="${dataId}" not found.`);
  // }
};
