import { convertStringToSlug } from "@/utils/sharedHelperFunctions";
import { CreateGatherTypes } from "./createGatherValuesAndProps";
import { validateNotTaken } from "@/utils/constants/validationUtils";

const ValidateGatherForm = async (
  gatherValues: CreateGatherTypes,
  step: number,
  setError: any
) => {
  if (step === 0) {
    if (!gatherValues.name) {
      setError({
        error: "name",
        message: "Name is required",
      });
      return false;
    }

    const slugToBeChecked = encodeURI(
      decodeURI(convertStringToSlug(gatherValues.name))
    );
    const result = await validateNotTaken(
      "create",
      slugToBeChecked,
      "gathers",
      "slug",
      "Gather name",
      false
    );
    const resultName = await validateNotTaken(
      "create",
      gatherValues.name,
      "gathers",
      "name",
      "Gather name",
      false
    );

    if (result) {
      setError({
        error: "name",
        message: result,
      });
      return false;
    }

    if (resultName) {
      setError({
        error: "name",
        message: resultName,
      });
      return false;
    }

    if (!gatherValues.type) {
      setError({
        error: "type",
        message: "Type is required",
      });
      return false;
    }
    if (!gatherValues.logoUrl) {
      setError({
        error: "logoUrl",
        message: "An image is required",
      });
      return false;
    }
  }
  if (step === 1) {
    if (!gatherValues.meetUrlOrAddress) {
      setError({
        error: "meetUrlOrAddress",
        message: "An address is required",
      });
      return false;
    }
    if (!gatherValues.startDate) {
      setError({
        error: "date",
        message: "Start date is required",
      });
      return false;
    }

    if (!gatherValues.isAllDay && !gatherValues.startTime) {
      setError({
        error: "date",
        message: "Start time is required",
      });
      return false;
    }
    if (!gatherValues.endDate) {
      setError({
        error: "date",
        message: "End date is required",
      });
      return false;
    }
    if (!gatherValues.isAllDay && !gatherValues.endTime) {
      setError({
        error: "date",
        message: "End time is required",
      });
      return false;
    }
  }
  setError();
  return true;
};

export default ValidateGatherForm;
