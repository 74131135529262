import React from "react";
import { ReactComponent as ElgigantenBadge } from "../../assets/elgiganten_stamp.svg";
import { ReactComponent as BestgamerBadge } from "../../assets/bestgamer_stamp.svg";
import { ReactComponent as BestgamerBadgeOpacity } from "../../assets/bestgamer_stamp_opacity.svg";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

type Props = {
  scoreInProcentage: string | number;
};

const CompletionBadge: React.FC<Props> = ({ scoreInProcentage }) => {
  // Variables
  const isBestGamer = brand.brandValue === "BESTGAMER";
  const isElgiganten = brand.brandValue === "ELGIGANTEN";

  const getBrandLogo = (hasClass) => {
    if (isElgiganten) {
      return (
        <ElgigantenBadge
          className={hasClass ? "completion-badge__image" : ""}
        />
      );
    }
    if (isBestGamer) {
      return (
        <BestgamerBadge className={hasClass ? "completion-badge__image" : ""} />
      );
    }
  };

  return (
    <div className="completion-badge">
      {isBestGamer ? (
        <BestgamerBadgeOpacity className={"completion-badge__image"} />
      ) : (
        getBrandLogo(true)
      )}
      <div
        className="completion-badge__overlay"
        style={{ height: `${scoreInProcentage}%` }}
      >
        {getBrandLogo(false)}
      </div>
      {scoreInProcentage !== 100 && (
        <span className="completion-badge__incomplete">
          <p>{scoreInProcentage}&#37;</p>
        </span>
      )}
    </div>
  );
};

export default CompletionBadge;
