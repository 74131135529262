import { showActionTypes } from "@/utils/constants/notificationVariables";
import { archiveOrReadNotification } from "./readNotification";

export default async function readAllMessages(notifications: Notification[]) {
  if (notifications.length > 0) {
    const filteredDocs = notifications.filter(
      (doc: any) =>
        doc.data.archived === false &&
        doc.data.read === false &&
        !showActionTypes[doc.data.type]
    );

    const promises = filteredDocs.map((doc: any) => {
      return archiveOrReadNotification(doc.realtimeDocumentID, true, false);
    });

    await Promise.all(promises);
  }
}
