import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { auth } from "@/api/firebase/firebase.config";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";
import { archiveOrReadNotification } from "./readNotification";
import QueryDB from "@/api/queryDB/common";
import QueryDB_Notifications from "./../../../api/queryDB/notifications/index";
import QueryDB_Profiles from "@/api/queryDB/profiles";
import { participateInChallenge } from "@/features/challenge/helperFunctions/participateUserIntoChallenge";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { leaveTeam } from "@/features/team/helperFunctions/leaveTeam";
import QueryRTDB from "@/api/queryDB/realTimeDB";
import { Profile } from "@/models/database/profiles/profile.model";

const NotificationsActions = async (
  data: any,
  docId: string,
  title: "Delete" | "Join" | "Accept" | "Reject",
  notificationRespType: string,
  dataObj: any,
  currentUser: Profile
) => {
  // const navigate = useNavigate();
  const currentUserUid = auth.currentUser.uid;
  if (!currentUserUid || !data.contentId || !data) {
    return;
  }

  try {
    if (title !== "Delete" && title !== "Reject") {
      // Challenges actions
      if (data.type === "challenges-requests") {
        const challenge = await collectionRef.challenge
          .doc(data.contentId)
          .get();
        const games = collectionRef.games;
        const gamesRes = await games
          .where("value", "==", challenge.data().gameType)
          .get();

        await participateInChallenge(
          undefined,
          data.userType,
          gamesRes.docs[0].data(),
          currentUserUid,
          data.contentId
        );
      }

      //   Gathers actions
      if (data.type === "gather-requests") {
        await QueryDB.createUserProfileJunction(
          "userGatherJunction",
          `${currentUserUid}_${data.contentId}`,
          {
            uid: currentUserUid,
            gatherSlug: data.contentId,
            status: "participant",
            gatherId: data.contentId,
            isFavorite: false,
            generalRole: "member",
            isInterested: true,
            notificationSettings: {
              memberRequests: true,
              newMembers: true,
              newPostsInFeed: true,
              newComntsInFeed: true,
            },
          }
        );
      }

      //   Teams actions
      if (data.type === "request-to-join-team") {
        try {
          await QueryDB.updateDoc(
            "userTeamJunction",
            `${data.senderUid}_${data.contentId}`,
            {
              generalRole: "member",
              status: "accepted",
              currUserStatus: "accepted",
              joinedTeamTimestamp:
                firebase.firestore.FieldValue.serverTimestamp(),
              requestAccepted: {
                acceptedByUid: currentUser.uid,
                acceptedByUsername: currentUser.userName,
                acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
              },
            }
          );
        } catch (error) {
          createToast({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      }

      if (data.type === "teams-requests") {
        try {
          await QueryDB.updateDoc(
            "userTeamJunction",
            `${currentUserUid}_${data.contentId}`,
            {
              generalRole: "member",
              status: "accepted",
              currUserStatus: "accepted",
              joinedTeamTimestamp:
                firebase.firestore.FieldValue.serverTimestamp(),
            }
          );
        } catch (error) {
          createToast({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      }

      // Friend requests
      if (data.type === "friends-requests") {
        await QueryDB_Profiles.updateUserCommunityStatus(
          "accept_friend_request",
          data.senderUid
        );
      }

      createToast({
        type: "success",
        message: "You accepted the request",
      });

      QueryRTDB.updateRealtimeDoc("notifications", docId, {
        actionSelected: "Accepted",
      });

      await archiveOrReadNotification(docId, true, false);

      if (
        window.location.pathname.includes(data.contentId) &&
        data.type === "teams-requests"
      ) {
        document.dispatchEvent(new Event("reload_singleTeam"));
      }

      return;
    }

    if (title === "Delete" || title === "Reject") {
      // request-to-join-team => senderUid
      // teams-request => currentUser.uid

      if (data.type === "friends-requests") {
        await QueryDB_Profiles.updateUserCommunityStatus(
          "cancel_request",
          data.senderUid
        );
      }
      if (
        data.type === "teams-requests" ||
        data.type === "request-to-join-team"
      ) {
        if (data.type === "teams-requests") {
          QueryDB.deleteDoc(
            "userTeamJunction",
            `${currentUser.uid}_${data.contentId}`
          );
        }
        if (data.type === "request-to-join-team") {
          await QueryDB.updateDoc(
            "userTeamJunction",
            `${data.senderUid}_${data.contentId}`,
            {
              generalRole: "random",
              status: "rejected",
              currUserStatus: "rejected",
            }
          );
        }
        QueryDB_Notifications.createNewNotification(
          currentUser.uid,
          data.senderUid,
          data.contentId,
          "teams-requests-rejected",
          `${notificationsTextMapping["teams-requests-rejected"]}!`
        );
      }

      createToast({
        type: "info",
        message: "You rejected the request",
      });

      QueryRTDB.updateRealtimeDoc("notifications", docId, {
        actionSelected: "Rejected",
      });

      if (
        window.location.pathname.includes(data.contentId) &&
        data.senderUsername === currentUser.userName
      ) {
        setTimeout(() => {
          // window.location.reload();
        }, 1150);
      }
      return await archiveOrReadNotification(docId, true, false);
    }
  } catch {
    createToast({
      type: "error",
      message: "Something went wrong, please try again later",
    });
    return await archiveOrReadNotification(docId, true, false);
  }
};
export default NotificationsActions;
