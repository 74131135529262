import { Button, Image, Spinner } from "@/components";
import { Avatars } from "@/features/auth/utils/avatars";
import { FC } from "react";

export const SettingsProfileAvatars: FC<{
  handleInput: any;
  setIsAvatar: any;
}> = ({ handleInput, setIsAvatar }) => {
  return (
    <ul className="settings__avatars">
      {Avatars.map((item) => {
        return (
          <li key={`${item.category}`}>
            <h5>
              {item.category}
              <div className="settings__avatars--images">
                {item.values.map((avt) => {
                  return (
                    <li key={`${avt.img}`}>
                      <Button
                        variant="link"
                        onClickAction={() => {
                          handleInput(avt.img);
                          setIsAvatar(true);
                        }}
                        // disabled={isLoading === avt.img}
                      >
                        <Image
                          imageSrc={avt.img}
                          customStyles="profile-image-input hover-image"
                          alt=""
                          imgIxProps={[{ w: "" }]}
                        />
                        {/* {isLoading === avt.img && (
                          <Spinner/>
                        )} */}
                      </Button>
                    </li>
                  );
                })}
              </div>
            </h5>
          </li>
        );
      })}
    </ul>
  );
};
