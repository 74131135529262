import React, { FC } from "react";
import { DropDown, ErrorFallback } from "@/components";
import { useNavigate } from "react-router-dom";
import NotificationsBell from "./NotificationsBell";
import NotificationsList from "./NotificationsList";

type Props = {
  loading: boolean;
  isFullPage?: boolean;
  notifications?: any;
  unreadNotifications: number;
  updateNotifications: any;
};
const NotificationsUi: FC<Props> = ({
  notifications,
  isFullPage,
  loading,
  unreadNotifications,
  updateNotifications,
}): JSX.Element => {
  const navigate = useNavigate();
  try {
    return (
      <>
        <div className="notification-bell">
          <DropDown
            logo={<NotificationsBell newNotifications={unreadNotifications} />}
          >
            <NotificationsList
              updateNotifications={() => updateNotifications()}
              isFullPage={false}
              notifications={notifications}
              loading={loading}
              setDropdownOpen={undefined}
              unreadNotifications={unreadNotifications}
            />
          </DropDown>
        </div>
        <div
          className="notification-bell notification-bell__mobile"
          onClick={() => navigate("/notifications")}
        >
          <NotificationsBell newNotifications={unreadNotifications} />
        </div>
      </>
    );
  } catch {
    return <ErrorFallback></ErrorFallback>;
  }
};

export default NotificationsUi;
