import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ProfileSettingsTabData: React.FC<{
  setCurrentChildTab: React.Dispatch<React.SetStateAction<string>>;
  currentChildTab: string;
  settingsTabs: { [key: string]: JSX.Element | undefined };
  currentTab: string;
  activePreferenceTab: string[];
}> = ({ settingsTabs, currentTab, activePreferenceTab, currentChildTab }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <>
      {currentUser && !currentChildTab && settingsTabs
        ? settingsTabs[currentTab]
        : ""}
      {currentUser && activePreferenceTab
        ? activePreferenceTab.map((input, i) => (
            <div className="preferences__content" key={i}>
              <h5>{input}</h5>
              <div className="preferences__active switch">
                <input
                  // onClick={() => {
                  //   handleSwitchButton(isActivated);
                  // }}
                  // checked={!isConnectedCorrectly ? false : isActivated}
                  id="toggle"
                  type="checkbox"
                  name="toggle"
                />
                <span />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};
