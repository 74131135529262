// Backgrounds
import subwaySurfersBackground from "../assets/partners/subwaysurfers.png";
import fortniteBackground from "../assets/partners/fortnite.png";
import fallguysBackground from "../assets/partners/fallguys.png";
import racingBackground from "../assets/partners/racing.png";

// mobile backgrounds
import subwaySurfersBackgroundMobile from "../assets/partners/subwaysurfersMobile.png";
import fortniteBackgroundMobile from "../assets/partners/fortniteMobile.png";
import fallguysBackgroundMobile from "../assets/partners/fallguysMobile.png";
import racingBackgroundMobile from "../assets/partners/racingMobile.png";

// Best gamer logos
import { ReactComponent as BestGamerLogoPurple } from "../../../../utils/brandVariables/assets/bestgamer/bestGamerPurple.svg";
import { ReactComponent as BestGamerLogoGreen } from "../../../../utils/brandVariables/assets/bestgamer/bestGamerGreen.svg";
import { ReactComponent as BestGamerLogoPink } from "../../../../utils/brandVariables/assets/bestgamer/bestGamerPink.svg";
import { ReactComponent as BestGamerLogoBlue } from "../../../../utils/brandVariables/assets/bestgamer/bestGamerBlue.svg";

// Challenge logos
import { ReactComponent as SubwaySurferChallengeLogo } from "../assets/partners/subwaySurfersChallengeLogo.svg";
import { ReactComponent as FortniteChallengeLogo } from "../assets/partners/fortniteChallengeLogo.svg";
import { ReactComponent as FallguysChallengeLogo } from "../assets/partners/fallguysChallengeLogo.svg";
import { ReactComponent as RacingChallengeLogo } from "../assets/partners/racingChallengeLogo.svg";

import { ReactComponent as PartnerLogo } from "../../../../../branchAndBrandSpecific/utils/brandVariables/assets/bestgamer/partnerlogo.svg"

export const BestGamerPartnerData = [
  {
    backgroundImage: subwaySurfersBackground, // image
    backgroundImageMobile: subwaySurfersBackgroundMobile, // image
    partnerLogo: PartnerLogo,
    bestGamerLogo: BestGamerLogoPurple, // svg
    challengeLogo: SubwaySurferChallengeLogo, // svg
    gameType: "subwaysurfers",
  },
  {
    backgroundImage: fortniteBackground, // image
    backgroundImageMobile: fortniteBackgroundMobile, // image
    partnerLogo: PartnerLogo,
    bestGamerLogo: BestGamerLogoGreen, // svg
    challengeLogo: FortniteChallengeLogo, // svg
    gameType: "fortnite",
  },
  {
    backgroundImage: fallguysBackground, // image
    backgroundImageMobile: fallguysBackgroundMobile, // image
    partnerLogo: PartnerLogo,
    bestGamerLogo: BestGamerLogoPink, // svg
    challengeLogo: FallguysChallengeLogo, // svg
    gameType: "fallguys",
  },
  {
    backgroundImage: racingBackground, // image
    backgroundImageMobile: racingBackgroundMobile, // image
    partnerLogo: PartnerLogo,
    bestGamerLogo: BestGamerLogoBlue, // svg
    challengeLogo: RacingChallengeLogo, // svg
    gameType: "f12023",
  },
];
