import firebase from "firebase/app";

export const _shareChallengeAsPost = async (
  challengeId?: string,
  currUserId?: string,
  postText?: string
) => {
  if (!challengeId || !currUserId) return undefined;

  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("shareChallengeAsAPost");

  const result = await func({
    challengeId,
    currUserId,
    postText,
  });

  return result;
};
