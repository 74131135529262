import { createToast } from "./../../../../src/utils/toaster/createToast";
import { codes, combinedCodes } from "./../consts/codes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import firebase from "firebase/app";
import { checkIfCompletedTreasureHunt } from "./checkIfCompletedTreasureHunt";

export const setTreasureHuntState = async (
  currentUser,
  isCheckingProgress,
  checkingProgress,
  thidToUse,
  usersCollectedData,
  setScoreInProcentage,
  scoreInProcentage
) => {
  console.log("SCORE", scoreInProcentage);

  const scorePerPoint = 100 / codes.length;
  console.log("setting");
  const dbRef = firebase.database();
  console.log(checkingProgress);

  if (checkingProgress) {
    return;
  }

  if (usersCollectedData[thidToUse]) {
    createToast({
      type: "info",
      message: "Code already found",
    });
    return;
  }

  isCheckingProgress(true);
  console.log("setting");

  dbRef
    .ref(`treasure_hunt/${brand.brandValue}/testing/users/${currentUser.uid}`)
    .update({
      [thidToUse]: true,
    });

  let scoreToProcentage = scoreInProcentage; // Move this declaration outside the loop

  if (combinedCodes[thidToUse] && !usersCollectedData[thidToUse]) {
    for (let i = 0; i < scorePerPoint; i++) {
      setTimeout(() => {
        scoreToProcentage += 1;
        setScoreInProcentage(scoreToProcentage);
      }, i * 75);
    }

    createToast({
      type: "success",
      message: `You found treasure hunt code: ${combinedCodes[thidToUse]}`,
    });

    setTimeout(
      () => {
        isCheckingProgress(false);
      },
      scorePerPoint * 75 + 200
    );

    return true;
  } else {
    createToast({
      type: "error",
      message: `Treasure hunt code doesnt exist`,
    });
    isCheckingProgress(false);
    return false;
  }
};
