import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import placeholderImage from "../../../../assets/placeholders/placeholder_sensitive.gif";
import { TeamOverviewCardProps } from "@/features/team/types/TeamTypes";

export const TeamCardComponent: FC<{
  teamData: TeamOverviewCardProps;
}> = ({ teamData }): JSX.Element => {
  // const [favoriteVisible, setFavoriteVisible] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);

  useEffect(() => {
    setLogoError(false);
  }, []);

  return (
    <Link to={`/teams/${teamData?.slug}`} title={teamData?.name}>
      <div className="team-card">
        <div className="team-card__image">
          <div className="image">
            <div className="image__content">
              <img
                src={logoError ? placeholderImage : teamData?.teamLogo}
                onError={() => {
                  setLogoError(true);
                }}
                alt={`${teamData?.name} image`}
              />
            </div>
            {teamData?.type && (
              <div className="team-card__type" title="CS2">
                <span className="team-card__type--text">{teamData?.type}</span>
              </div>
            )}
          </div>
        </div>
        <div className="team-card__meta">
          <div className="team-card__heading">
            <h5 title={teamData?.name}>{teamData?.name}</h5>
          </div>

          <ul className="team-card__badges">
            {teamData?.games &&
              teamData?.games?.length !== 0 &&
              teamData?.games.map((field) => {
                return <li key={field}>{field}</li>;
              })}
          </ul>
        </div>
      </div>
    </Link>
  );
};
