import { Button } from "@/components";
import { FC } from "react";
import { createToast } from "@/utils/toaster/createToast";

export const DeleteModal: FC<{
  logoSrc: string;
  setCurrentContentOfModal: any;
  removeImage: any;
}> = ({ logoSrc, setCurrentContentOfModal, removeImage }) => {
  return (
    <div className="edit-profile__confirm-delete">
      <h3>Delete avatar</h3>
      <p>
        Are you sure you want to delete this image? You will not be able to
        reverse this action.
      </p>

      <div className="edit-profile__confirm-delete--buttons">
        <Button
          variant="secondary"
          onClickAction={() =>
            setCurrentContentOfModal(
              logoSrc
                ? "Edit image with initial image"
                : "Edit image with no initial image"
            )
          }
        >
          Go back
        </Button>

        <Button
          variant="primary"
          onClickAction={async () => {
            await removeImage();
            createToast({
              type: "success",
              message: "Avatar updated",
            });
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
