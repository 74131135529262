export const challengesPlatformOptions = [
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "PC",
    value: "pc",
  },
  {
    name: "Console",
    value: "console",
  },
];
export const DropdownStatusOptions = [
  {
    name: "Ongoing",
    value: "ongoing",
  },
  {
    name: "Upcoming",
    value: "upcoming",
  },
  {
    name: "Finished",
    value: "finished",
  },
];
export const CreateGatherMeetAmount = [
  {
    name: "One-time event",
    value: "once",
  },
  {
    name: "Every week",
    value: "weekly",
  },
  {
    name: "Every month",
    value: "monthly",
  },
  {
    name: "Every year",
    value: "yearly",
  },
];
export const StatusOptions = [
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Finished",
    value: "finished",
  },
];
export const GatherSortByOptions = [
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Popularity",
    value: "popularity",
  },
];
