import { Button } from "@/components";
import { FC } from "react";

export const CreateGatherHeader: FC<{
  step: number;
  stepsAmount: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handleNextStep: () => Promise<false | void>;
  name: string;
}> = ({ step, stepsAmount, setStep, handleNextStep, name }) => {
  return (
    <>
      {step === stepsAmount - 1 ? (
        <>
          <div className="preview-create-container">
            <h3>Is {name} ready to go live?</h3>
            <div className="d-flex mt-1">
              <Button
                variant="secondary"
                customStyles="me-2"
                onClickAction={() => setStep((s: number) => s - 1)}
              >
                Edit
              </Button>
              <Button
                variant="primary"
                customStyles="ms-2"
                type="submit"
                onClickAction={() => {
                  handleNextStep();
                }}
              >
                Yes, activate
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="create-gather__step">
            <h2>New gather</h2>
            <p>
              step {step + 1}/{stepsAmount}
            </p>
          </div>
        </>
      )}
    </>
  );
};
