export const getProgressBarValue = (
  startDateSeconds: number | undefined,
  endDateSeconds: number | undefined
): number => {
  if (!startDateSeconds || !endDateSeconds) return 50;
  const now = Date.now() / 1000;

  if (now < startDateSeconds) return 0;
  if (now > endDateSeconds) return 100;

  return Math.trunc(
    ((now - startDateSeconds) / (endDateSeconds - startDateSeconds)) * 100
  );
};
