import React, { FC, useState, useEffect } from "react";
import i18next from "i18next";
import closeIcon from "../../../../assets/actions/close.svg";
import background from "../../assets/background/overview-teams.jpg";
import { OverviewArchive } from "@/components";

import DiscoverNewTeams from "./DiscoverNewTeams.fetcher";
import MineTeams from "./MineTeams.fetcher";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export const TeamsOverviewFetcher: FC = (): JSX.Element => {
  const [selectedGame, setSelectedGames] = useState<string | undefined>("");
  const [loading, setLoading] = useState(true);
  const [selectedCreatorType, setSelectedCreatorType] = useState<
    "NEW" | "MINE"
  >((localStorage.getItem("teams_last_tab") as "NEW" | "MINE") || "NEW");

  const filterButtons = [
    {
      text: "overview.teams.badges.new",
      value: "NEW",
    },
    {
      text: "overview.teams.badges.mine",
      value: "MINE",
    },
  ];

  useEffect(() => {
    localStorage.setItem("teams_last_tab", selectedCreatorType);
  }, [selectedCreatorType]);

  if (selectedCreatorType === "NEW") {
    useDocumentTitle('Discover new teams');
  }
  if (selectedCreatorType === "MINE") {
    useDocumentTitle('Your teams');
  }

  return (
    <>
      <OverviewArchive
        headline={i18next.t("strings.teams")}
        linkToBtnText="overview.teams.create_btn"
        linkToBtnLink="/create-team"
        subtitle="overview.teams.desc"
        filterButtons={filterButtons}
        selectedFilter={selectedCreatorType}
        setSelectedFilter={setSelectedCreatorType}
        disableBadges={loading}
        background={background}
      />

      <div className="filters">
        <div className="filters__heading">
          {selectedCreatorType === "MINE" && (
            <h4>{i18next.t("overview.teams.headline.mine")}</h4>
          )}
          {selectedCreatorType === "NEW" && (
            <h4>{i18next.t("overview.teams.headline.new")}</h4>
          )}
        </div>
        <div className="filters__filter">
          {selectedCreatorType === "NEW" && selectedGame && (
            <div
              aria-hidden
              onClick={() => {
                setSelectedGames(undefined);
              }}
              className="filters__reset"
            >
              <button className="filters__btn">
                <img width={21} height={21} src={closeIcon} alt="Close icon" />
              </button>
            </div>
          )}
          <div className="filters__options">
            {selectedCreatorType === "NEW" && (
              // <GamesCheckbox
              //   selectedGame={selectedGame}
              //   setSelectedGame={setSelectedGames}
              //   setUpdateTrigger={setUpdateTrigger}
              //   filterLocation="teams"
              // />
              <></>
            )}
          </div>
        </div>
      </div>
      {selectedCreatorType === "NEW" ? (
        <DiscoverNewTeams setLoading={setLoading} />
      ) : (
        <MineTeams setLoading={setLoading} />
      )}
    </>
  );
};
