import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { myTeamsAtom } from "@/utils/atoms/teamsAtoms/teamsAtoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

export const useMyTeamsData = ({
  client,
  limit,
}: {
  client: string;
  limit: number;
}) => {
  const [MyTeamsData, setMyTeamsData] = useAtom(myTeamsAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lasVisibleAmount, setLastVisibleAmount] = useState<number>(0);

  const queryInit = collectionRef.teams
    .where("client", "==", client)
    .where("createdBy", "==", currentUser?.uid);

  const fetchMyTeams = async () => {
    const query = await queryInit.limit(20).get();

    const response = query.docs.map((doc) => {
      return doc.data();
    });

    setLastVisibleAmount(lasVisibleAmount + response.length);

    if (response.length < limit) {
      setHasMore(false);
    }
    if (response) {
      setMyTeamsData(response);
    }
    return setIsLoading(false);
  };

  const fetchMoreMyNews = async () => {
    const newsQuery = await queryInit
      .startAfter(lasVisibleAmount)
      .limit(20)
      .get();

    const newsResponse = await Promise.all(
      newsQuery.docs.map(async (doc) => {
        return doc.data();
      })
    );

    setLastVisibleAmount(lasVisibleAmount + newsResponse.length);

    if (newsResponse.length < limit) {
      setHasMore(false);
    }
    if (newsResponse) {
      setMyTeamsData([...MyTeamsData, ...newsResponse]);
    }
    return setIsLoading(false);
  };

  // News
  useEffect(() => {
    if (MyTeamsData?.length === 0) {
      setIsLoading(true);
    }
    fetchMyTeams();
  }, [client]);

  const fetchNewData = () => {
    fetchMyTeams();
  };

  return {
    data: MyTeamsData,
    loading: isLoading,
    fetchMyTeamsData: fetchMyTeams,
    fetchMoreMyTeamsNews: fetchMoreMyNews,
    hasMore: hasMore,
  };
};
