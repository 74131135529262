import React, { useState } from "react";
import { cloudFunctionUrl } from "@/features/auth/components/AuthConnectOptions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

import { ReactComponent as CloseIcon } from "../../../../../../assets/actions/close.svg";
import refresh from "../../../../../../assets/actions/Refresh.svg";
import { ReactComponent as BnetIcon } from "../../../../assets/battlenet-icon.svg";
import { ReactComponent as SteamIcon } from "../../../../assets/steam-icon.svg";
import { Button, Image } from "@/components";
import i18next from "i18next";

const games: {
  [key: string]: {
    icon: JSX.Element;
    name: string;
  };
} = {
  "Battle.net": {
    icon: <BnetIcon width={36} height={36} className="m-1" fill="#f1f5ff" />,
    name: "Battle.net",
  },
  "Call of Duty": {
    name: "Call of Duty",
    icon: <h3 className="settings-warzone">WZ</h3>,
  },
  Steam: {
    name: "Steam",
    icon: <SteamIcon width={36} height={36} className="m-1" fill="#f1f5ff" />,
  },
  PUBG: {
    name: "PUBG",
    icon: <h3 className="settings-pubg">PUBG</h3>,
  },
};

export const SettingsAccountsCard: React.FunctionComponent<{
  game: string;
  isConnectedCorrectly: boolean;
  userName?: string;
  handleSwitchButton: (state: boolean) => void;
  handleClick: () => void;
  handleRefreshBtn: () => any;
  isActivated: boolean;
  linkedBattletag?: any;
  steamId?: any;
  activisionId?: any;
  pubgUsername?: any;
  setOpenPubgModal?: any;
  setOpenCoDModal?: any;
}> = ({
  game,
  isConnectedCorrectly,
  userName,
  isActivated,
  handleSwitchButton,
  handleClick,
  handleRefreshBtn,
  linkedBattletag,
  steamId,
  activisionId,
  pubgUsername,
  setOpenPubgModal,
  setOpenCoDModal,
}) => {
  const [spinBtn, setSpinBtn] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <>
      <div className="connections">
        {/* <Card> */}
        <div className="connections__header">
          <div className="connections__game">{games[game].icon}</div>
          <div className="connections__info">
            {/* {userName && isConnectedCorrectly && <h4>{userName}</h4>} */}
            <h4>
              {userName}
              {!userName && "Your nickname"}
            </h4>
            <span>{games[game].name}</span>
          </div>
          {/* {games[game].name === "Steam" && (
              <Button
                variant="link"
                customStyles="connections__refresh"
                onClickAction={async () => {
                  if (!spinBtn) {
                    setSpinBtn(true);
                    const res = await handleRefreshBtn()
                      .then(() => {
                        if (res) {
                          setSpinBtn(false);
                        }
                      })
                      .catch(() => {
                        setSpinBtn(false);
                      });
                  }
                }}
              >
                <Image
                  alt=""
                  imageSrc={refresh}
                  // width={22}
                  // height={22}
                  customStyles={` ${spinBtn ? "refresh-btn" : ""} `}
                />
              </Button>
            )}
            <Button
              variant="link"
              customStyles="connections__disconnect"
              onClickAction={handleClick}
            >
              <CloseIcon width={30} height={30} />
            </Button> */}
          {isConnectedCorrectly && (
            <Button
              variant="primary"
              customStyles={"btn-connected"}
              onClickAction={handleClick}
            >
              {i18next.t("buttons.connected")} &#x2713;
            </Button>
          )}
          {!isConnectedCorrectly && (
            <Button
              variant="primary"
              type="button"
              onClickAction={() => {
                if (games[game].name === "Battle.net") {
                  if (!linkedBattletag) {
                    window.open(
                      `${cloudFunctionUrl}bnet?uid=${currentUser.uid}`
                    );

                    // const timer = setInterval(function () {
                    //   if (win.closed) {
                    //     clearInterval(timer);
                    //   }
                    // }, 2000);
                  }
                }
                if (games[game].name === "PUBG") {
                  if (!pubgUsername) {
                    setOpenPubgModal(true);
                  }
                }
                if (games[game].name === "Steam") {
                  if (!steamId) {
                    window.open(
                      `${cloudFunctionUrl}steam?uid=${currentUser.uid}`
                    );
                    // const timer = setInterval(function () {
                    //   if (win.closed) {
                    //     clearInterval(timer);
                    //     refreshSteamConnection();
                    //   }
                    // }, 2000);
                  }
                }
                if (games[game].name === "Call of Duty") {
                  setOpenCoDModal(true);
                }
              }}
            >
              {i18next.t("buttons.connect")}
            </Button>
          )}
        </div>
        <div
          className={`connections__body ${
            !isConnectedCorrectly && "connections__body--disabled"
          }`}
        >
          <h5>
            {games[game].name === "Steam" && "Use account for Dota2"}
            {games[game].name === "PUBG" && "Use for PUBG challenges"}
            {games[game].name === "Call of Duty" && "Use for Warzone challenge"}
            {games[game].name === "Battle.net" && "Use for Warzone"}
          </h5>
          <div
            className={`${
              isActivated ? "settings__active" : "settings__disabled"
            } switch`}
          >
            <input
              onClick={() => {
                handleSwitchButton(isActivated);
              }}
              checked={!isConnectedCorrectly ? false : isActivated}
              id="toggle"
              type="checkbox"
              name="toggle"
            />
            <span />
          </div>
        </div>
      </div>
    </>
  );
};
