import React, { FC, useState } from "react";
import { Image } from "react-bootstrap";
import arrow from "../../../../assets/actions/chevron-left.svg";
import UserList from "@/components/UserList/UserList";
import ParticipateInterestedModel from "../participatingModel/ParticipateInterestedModel";
import { Profile } from "../../types/cloudFunctions/SingleGatherTypes";

type prop = {
  profiles: Profile[] | [];
  otherCount: number;
  title: "Interested" | "Participants";
  gatherSlug: string;
  gatherName: string;
  currentUserGeneralRole?: "owner" | "admin" | "member" | "random";
};
const SingleGatherParticipatingInterested: FC<prop> = (props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  return (
    <>
      <ParticipateInterestedModel
        gatherSlug={props.gatherSlug}
        gatherName={props.gatherName}
        currentUserGeneralRole={props.currentUserGeneralRole}
        model={modalOpen}
        setModal={setModalOpen}
        setIsRequest={setIsRequest}
      />

      <div
        className="gather__participants--container"
        onClick={() => {
          setModalOpen(true);
        }}
        aria-hidden
      >
        <div>
          <div>
            <p>{props.title}</p>
            {props.title === "Participants" && isRequest ? (
              <div className="mark" />
            ) : (
              ""
            )}
          </div>
          {props.profiles && props.profiles.length !== 0 && (
            <UserList profiles={props.profiles} otherCount={props.otherCount} />
          )}
        </div>
        <div className="gather__participants--icon">
          <Image
            width={5}
            height={10}
            className="cursor-pointer rotate-180"
            src={arrow}
          />
        </div>
      </div>
    </>
  );
};
export default SingleGatherParticipatingInterested;
