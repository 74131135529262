import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import BaseModalWindow from "../BaseModalWindow/BaseModalWindow";
import { BaseTextInput } from "../BaseTextInput/BaseTextInput";
import { reportOptions } from "./utils/reportOptions";
import "../../utils/globalStyles/modals/_reportModal.scss";
import { Button } from "..";
import { createToast } from "@/utils/toaster/createToast";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { Query_Imgix } from "@/api/imgix";

export const ReportFormModal: FC<{
  show: boolean;
  closeModal: () => void;
  reportFrom: "post" | "profile" | "team" | "challenge" | "comment"; // Where is it reported from
  reportItemId: string; // ID of the item being reported
  reportItemParentId?: string; // in case of comment -> pass post id as well

  children: React.ReactNode;
}> = (props) => {
  const [reason, setReason] = useState<string>("");
  const [hasOtherReason, setHasOtherReason] = useState<boolean>(false);
  const [reportScreenShot, setReportScreenShot] = useState<File>(undefined);
  const [imageUrl, setImageUrl] = useState<string>("");
  const pickedOptions = document.querySelectorAll(
    '#reportFromItems input[type="checkbox"]'
  ) as any;

  const checkedValues = Array.from(pickedOptions)
    .filter((checkbox: any) => checkbox.checked)
    .map((checkbox: any) => checkbox.value);

  const [reportResponse, loading, error, report_item] =
    useCloudFunctionCall<"">("reportItem", undefined);

  const handleSubmitReport = async () => {
    const pickedOptionsEnd = document.querySelectorAll(
      '#reportFromItems input[type="checkbox"]'
    ) as any;

    const checkedValuesEnd = Array.from(pickedOptionsEnd)
      .filter((checkbox: any) => checkbox.checked)
      .map((checkbox: any) => checkbox.value);

    if (
      (hasOtherReason && reason.length === 0) ||
      checkedValuesEnd.length === 0
    ) {
      return createToast({
        type: "info",
        message: "Please fill in a reason.",
      });
    }

    props.closeModal();

    const finalStringFormat = `${Object.values(checkedValuesEnd).join(", ")}${
      hasOtherReason && reason ? `User reasons:  ${reason}` : ""
    }`;

    const imgUrl = reportScreenShot
      ? await Query_Imgix.convertImageToImgIX(
          reportScreenShot,
          `report/${props.reportFrom}/${props.reportItemId}`
        )
      : undefined;

    await report_item({
      itemType: props.reportFrom,
      itemId: props.reportItemId, //slug of profile/post id, etc...
      itemParentId: props.reportItemParentId, //in case of comment -> pass post id as well
      reportReason: finalStringFormat, //comment by user
      screenshot: imgUrl,
    });
    createToast({ type: "info", message: `Reported ${props.reportFrom}.` });
    if (props.reportFrom === "post") {
      createToast({
        type: "info",
        message:
          "This post will be removed from your feed, but will still be on the creators profile.",
      });
    }
    setReason("");
    setHasOtherReason(false);
    setReportScreenShot(undefined);
    setImageUrl("");
    return;
  };
  useEffect(() => {
    if (error) {
      createToast({
        type: "error",
        message: `Failed to report ${props.reportFrom}.`,
      });
    }
  }, [error]);
  return (
    <>
      <BaseModalWindow
        show={props.show}
        closeModal={() => {
          props.closeModal();
        }}
        title={`Report this ${props.reportFrom}`}
      >
        <div className="report-form">
          <p>
            Feel like this {props.reportFrom} does not follow our guidelines?
          </p>
          <form className="report-form__form" id="reportFromItems">
            <ul className="report-form__list">
              {reportOptions.map((opt, index) => {
                const key = index;
                return (
                  <li className="report-form__item" key={key}>
                    <label>
                      <span>{opt}</span>
                      <input
                        onChange={() => {}}
                        type="checkbox"
                        name="option"
                        value={opt}
                      />
                    </label>
                  </li>
                );
              })}
              <li className="report-form__item">
                <label>
                  <span>Other reason</span>
                  <input
                    onChange={(e) => {
                      setHasOtherReason(e.target.checked);
                    }}
                    type="checkbox"
                    name="option"
                    checked={hasOtherReason}
                    value=""
                  />
                </label>
              </li>
            </ul>
          </form>
          {hasOtherReason && (
            <div className="report-form__description">
              <BaseTextInput
                label=""
                textType="text"
                value={reason}
                placeholder="Add description"
                setSingleState={setReason}
                error={undefined}
              />
            </div>
          )}
          <div className="report-form__actions">
            <Button
              onClickAction={() => {
                handleSubmitReport();
              }}
              variant={
                (checkedValues.length !== 0 && !hasOtherReason) ||
                (checkedValues.length !== 0 && hasOtherReason) ||
                (hasOtherReason && reason)
                  ? "primary"
                  : "secondary"
              }
            >
              Report
            </Button>

            {/* <Button variant="link">
              <label htmlFor="reportImage">
                {imageUrl ? (
                  <div>
                    <div className="adaptive">
                      <div className="adaptive-photo">
                        {imageUrl && <img src={imageUrl} />}
                      </div>
                    </div>
                  </div>
                ) : (
                  "Upload image"
                )}
                <input
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      const FileToBlob = URL.createObjectURL(e.target.files[0]);

                      setReportScreenShot(e.target.files[0]);
                      setImageUrl(FileToBlob);
                    }
                  }}
                  id="reportImage"
                  name="reportImage"
                  type="file"
                />
              </label>
            </Button> */}
          </div>
        </div>
      </BaseModalWindow>
    </>
  );
};
