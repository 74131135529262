import { useState, useEffect, useMemo } from "react";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { GatherOverviewUi } from "./GatherOverview.ui";
import {
  GatherOverviewFilters,
  GatherOverviewProps,
  GatherOverviewTypes,
  myGatherProps,
} from "../../types/cloudFunctions/GatherOverview";
import { GatherCard } from "../gatherCard/index";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const GatherOverview = () => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [selectedCreatorType, setSelectedCreatorType] = useState<
    "MINE" | "NEW"
  >("NEW");

  const [selectedGame, setSelectedGames] = useState<string | undefined>(
    undefined
  );
  const [selectedStatus, setSelectedStatus] = useState<"active" | "finished">(
    "active"
  );
  const [selectedSortType, setSelectedSortType] = useState<
    "date" | "popularity"
  >("date");

  const filterKey = `${selectedStatus}_${selectedSortType}_${selectedGame}`;
  const filterKeyForMineGathers = "MINE";

  const [
    gathers,
    lastVisiblePostDateOrNumber,
    hasMore,
    _loading,
    error,
    fetchGather,
    ref,
    inView,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    GatherOverviewTypes[] | undefined,
    GatherOverviewProps // props types for cloud function
  >(
    "getGathersBasedOnSelectedFiltersDataArray",
    "gather_based_on_filters",
    filterKey,
    false // persisting
  );

  //when there's no gather in the list and loading state is true
  const isLoading = !!gathers?.length && _loading;

  const [
    myGather,
    myGather_LastVisible,
    myGather_HasMore,
    _myGather_loading,
    _myGatherError,
    fetch_myGather,
    reff,
    myGather_inView,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    GatherOverviewTypes[] | undefined,
    any
  >(
    "getUserFeedBasedOnSlug",
    "my_gather",
    filterKeyForMineGathers,
    true // persisting
  );

  const fetchProp = {
    filter: {
      status: selectedStatus,
      sortBy: selectedSortType,
      game: selectedGame,
    },
    // type: "NON-SCROLL",// Not required
  };

  //only run at first load!
  useEffect(() => {
    (async () => {
      fetchGather(
        {
          lastVisiblePostDateOrNumber: undefined,
          filter: {
            status: selectedStatus,
            sortBy: selectedSortType,
            game: selectedGame,
          },
        },
        filterKey,
        false
      );
    })();
  }, []);

  //on filter change
  useEffect(() => {
    (async () => {
      fetchGather(
        {
          lastVisiblePostDateOrNumber: undefined,
          filter: {
            status: selectedStatus,
            sortBy: selectedSortType,
            game: selectedGame,
          },
        },
        filterKey,
        true
      );
    })();
  }, [filterKey]);

  //run Mine gather
  useEffect(() => {
    if (selectedCreatorType === "MINE") {
      (async () => {
        fetch_myGather(
          {
            lastDocId: myGather_LastVisible,
            slug: currentUser.slug,
            type: "teams",
          },
          filterKeyForMineGathers,
          false
        );
      })();
    }
  }, [selectedCreatorType]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  // call this function when inView is true
  const fetchMore = async () => {
    await fetchGather(
      {
        ...fetchProp,

        lastVisiblePostDateOrNumber: lastVisiblePostDateOrNumber,
      },
      filterKey,
      true
    );
  };

  const inViewRefElementIndex = useMemo(() => {
    return gathers ? gathers?.length - 9 : -1;
  }, [gathers, hasMore]);

  useEffect(() => {
    if (myGather_inView) {
      fetchMyGatherMore();
    }
  }, [myGather_inView]);

  // call this function when inView is true
  const fetchMyGatherMore = async () => {
    await fetch_myGather(
      {
        myGather_LastVisible: myGather_LastVisible,
      },
      filterKeyForMineGathers,
      true
    );
  };
  const myGatherInViewRefElementIndex = useMemo(() => {
    return myGather ? myGather?.length - 9 : -1;
  }, [myGather, myGather_HasMore]);

  // TO DO
  // error component for error fall back

  return (
    <GatherOverviewUi
      selectedCreatorType={selectedCreatorType}
      setSelectedCreatorType={setSelectedCreatorType}
      loading={_loading}
      selectedGame={selectedGame}
      setSelectedGames={setSelectedGames}
      setSelectedSortType={setSelectedSortType}
      selectedSortType={selectedSortType}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
    >
      <div className="gathers-overview">
        {selectedCreatorType === "NEW" &&
          gathers &&
          gathers.map((gather, ind) => {
            const key = gather.slug;

            return (
              <div key={key} ref={ind === inViewRefElementIndex ? ref : null}>
                <GatherCard
                  logoUrl={gather.logoUrl}
                  name={gather.name}
                  meetType={gather.meetType}
                  games={gather.games}
                  startDate={gather.startDate}
                  participantsCount={gather.participantsCount}
                  interestedInCount={gather.interestedInCount}
                  slug={gather.slug}
                  isFavoriteByCurrentUser={gather.isFavoriteByCurrentUser}
                  gatherId={gather.gatherId}
                  isPlaceholder={false}
                  generalRole={gather.generalRole}
                />
              </div>
            );
          })}
        {selectedCreatorType === "MINE" &&
          myGather &&
          myGather.map((gather, ind) => {
            const key = gather.slug;

            return (
              <div
                key={key}
                ref={ind === myGatherInViewRefElementIndex ? reff : null}
              >
                <GatherCard
                  logoUrl={gather.logoUrl}
                  name={gather.name}
                  meetType={gather.meetType}
                  games={gather.games}
                  startDate={gather.startDate}
                  participantsCount={gather.participantsCount}
                  interestedInCount={gather.interestedInCount}
                  slug={gather.slug}
                  isFavoriteByCurrentUser={gather.isFavoriteByCurrentUser}
                  gatherId={gather.gatherId}
                  isPlaceholder={false}
                  generalRole={gather.generalRole}
                />
              </div>
            );
          })}
        {error && (
          <>
            <h2>Error: Failed to fetch more data!</h2>
          </>
        )}
      </div>
    </GatherOverviewUi>
  );
};
