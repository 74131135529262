import { Button } from "@/components";
import React, { memo, FC, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/actions/edit.svg";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { FavoriteButton } from "@/components/FavoriteButton";
import { InviteButton } from "@/components/InviteButton";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";
import { ReactComponent as ShareIcon } from "../../../feed/assets/share-post-profile.svg";
import { ReactComponent as ReportIcon } from "../../../../assets/actions/report_triangle.svg";
import { ReportFormModal } from "@/components/ReportFormModal/ReportFormModal";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const SingleChallengeActionButtons: FC<{
  data: singleChallengeProps;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
}> = ({ data, isPreview, setIsEdit }): JSX.Element => {
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <div>
      {data.isCreatedByCurrUser ? (
        <Button
          variant="link"
          disabled={isPreview || data.challengeData.status === "finished"}
          onClickAction={() => {
            setIsEdit(true);
          }}
        >
          <EditIcon />
        </Button>
      ) : (
        ""
      )}
      <FavoriteButton
        slug={`${data.challengeData.slug}`}
        isPreview={isPreview}
        isInFavoritesOfCurrentUser={data.isInCurrUserFavorites}
        featureType="challenge"
        name={data.challengeData.name}
      />

      {data.isCreatedByCurrUser || data.challengeData.type ? (
        <InviteButton
          slug={`${data.challengeData.slug}`}
          isPreview={isPreview || data.challengeData.status !== "ongoing"}
          notificationType="challenges-requests"
          featureName={data.challengeData.name}
        />
      ) : (
        ""
      )}

      <Button variant="link" onClickAction={() => setShareModalOpen(true)}>
        <ShareIcon />
      </Button>

      <Button
        variant="link"
        onClickAction={() => {
          setReportModalOpen(true);
        }}
      >
        <ReportIcon />
      </Button>

      {/* Challenge report modal */}
      <ReportFormModal
        show={reportModalOpen}
        closeModal={() => {
          setReportModalOpen(false);
        }}
        children={""}
        reportFrom="challenge"
        reportItemId={data.challengeData.slug}
      />

      {/* General modal for sharing | New */}
      <ShareContentModalWindow
        sharedFrom={"challenge"}
        createType="create"
        show={shareModalOpen}
        closeWindow={() => setShareModalOpen(false)}
        postData={{
          uid: currentUser.uid,
          postText: "",
          postedIn: "",
          totalSharesNumber: 0,
          postVideo: "",
          created: data.challengeData.created,
          postPhoto: "",
          wasEdited: false,
          commentsCount: 0,
          totalLikesNumber: 0,
          totalCommentsNumber: 0,
          id: data.challengeData.slug,
          authorSlug: currentUser.slug,
          authorUsername: currentUser.userName,
          authorLogo: currentUser.profileLogoUrl,
          hasCurrentUserLikedThisPost: false,
          type: "sharedChallenge",
          additionalData: data.challengeData,
        }} // NEW
      />
    </div>
  );
};
