import { Button } from "@/components";
import { FC } from "react";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/actions/plus.svg";
import { AiOutlineMinus as RemoveIcon } from "@react-icons/all-files/ai/AiOutlineMinus";
import Cropper from "react-easy-crop";

export const EditWithInitImage: FC<{
  onCropComplete: any;
  zoom: any;
  crop: any;
  profileCroppedImage: any;
  setCrop: any;
  setZoom: any;
}> = ({
  onCropComplete,
  zoom,
  crop,
  profileCroppedImage,
  setCrop,
  setZoom,
}) => {
  return (
    <div className="edit-profile__includes-initial">
      <div>
        {/* <h3>{i18next.t("profile.edit.title")}</h3> */}
        {profileCroppedImage && (
          <div className="edit-profile__includes-initial--wrapper profile__cropper-wrapper">
            <Cropper
              image={profileCroppedImage!}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />

            <div className="profile__cropper-controls">
              {/* <Button
                variant=""
                onClickAction={() => setCurrentContentOfModal("Confirm delete")}
              >
                <BinIcon />
              </Button> */}

              <Button
                variant=""
                onClickAction={() => {
                  setZoom((prev: any) => {
                    if (prev - 0.1 <= 1) {
                      return prev;
                    }
                    return prev - 0.1;
                  });
                }}
                disabled={zoom <= 1}
              >
                <RemoveIcon />
              </Button>

              <Button
                variant=""
                onClickAction={() => {
                  setZoom((prev: any) => {
                    if (prev + 0.1 >= 3) {
                      return prev;
                    }
                    return prev + 0.1;
                  });
                }}
                disabled={zoom >= 3}
              >
                <PlusIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
