import { useParams } from "react-router-dom";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useAtom } from "jotai";
import SingleChallengeLoader from "@/components/skeletonLoaders/singleChallengeLoader/SingleChallengeLoader";
import { PageDoesntExist } from "@/pageDoesntExist";
import { useEffect } from "react";
import { SingleChallengeBestgamerUI } from "@/features/challenge/components/single/layouts/bestgamer/SingleChallengeBestgamer.ui";
import useSingleChallenge from "@/features/challenge/hooks/useSingleChallenge";

export default function SingleChallengeBestgamerPage() {
  const { slug } = useParams();
  const { challenge, loading, error } = useSingleChallenge(slug);
  const [currentClient, setCurrentClient] = useAtom(clientAtom);

  useEffect(() => {
    if (challenge && challenge.client) {
      setCurrentClient(challenge.client);
    }
  }, [challenge, currentClient, setCurrentClient]);

  if (loading) {
    return <SingleChallengeLoader />;
  }

  if (error) {
    return <PageDoesntExist />;
  }

  return <SingleChallengeBestgamerUI data={challenge} />;
}
