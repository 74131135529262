import { EventDetails } from "../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventDetails";
import { FC } from "react";
import { activityContent } from "../consts/activityContent";
import { EventActivityWinnerCard } from "./../../../components/eventActivityWinnerCard/EventActivityWinnerCard";
import { Button } from "@/components";

export const EventActivityContent: FC<{ eventId: string }> = ({ eventId }) => {
  const content = activityContent?.[eventId];
  return (
    <div className="content">
      <section className="content__details">
          <EventDetails
            goal={content.goal}
            description={content.desc}
            game={content.name}
          />
      </section>

      <section className="content__winner">
        <EventActivityWinnerCard />
        <div className="content__actions">
          <Button variant="primary">Share</Button>
        </div>
      </section>
    </div>
  );
};
