import { Image } from "@/components";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { getDifferenceInTime } from "@/utils/constants/formatDate";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import SingleTeamRoleAssignMenu from "./SingleTeamRoleAssignMenu";
import SingleTeamMemberOptions from "./SingleTeamMemberOptions";
import BaseUserAvatar from "@/components/BaseUserAvatar";

export const SingleTeamSeeAllMembers: FC<{
  teamMembers: any;
  teamData: SingleTeamResponseTypes;
  userRole: "owner" | "admin" | "member" | "random";
  handleUpdateMembersList?: (uid: string) => void;
  handleAcceptUser: any;
}> = ({
  teamMembers,
  teamData,
  userRole,
  handleUpdateMembersList,
  handleAcceptUser,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <>
      <ul className="team-members__list">
        <li
          className="team-members__item team-members__item--legend"
          key="team-members__legend"
        >
          <div className="legend">
            <div className="legend__user">User</div>
            <div className="legend__role">Role</div>
            <div className="legend__time">On team</div>
            <div className="legend__admin"></div>
          </div>
        </li>
      </ul>
      <ul className="team-members__list team-members__list--members-only">
        {teamMembers
          ? teamMembers.map((member: any, index) => {
              if (member) {
                return (
                  <li
                    className="team-members__item"
                    key={`singleTeamMember_${index}`}
                  >
                    <div className="team-members__member">
                      <div className="team-members__profile">
                        <Link
                          to={
                            member.slug === currentUser.slug
                              ? `/profile`
                              : `/profile/${member.slug}`
                          }
                        >
                          <div className="team-members__avatar">
                            <BaseUserAvatar
                              logoUrl={member.logoUrl}
                              userName={member.userName}
                            />
                          </div>
                          <div className="team-members__name">
                            <span className="team-members__username">
                              {member.userName}
                            </span>
                            {member.fullName && (
                              <span className="team-members__fullname">
                                {member.fullName}
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                      <div className="team-members__role">
                        <SingleTeamRoleAssignMenu
                          user={member}
                          teamId={teamData.slug}
                          userRole={userRole}
                          teamOwner={teamData.createdBy}
                        />
                      </div>
                      <div className="team-members__time">
                        {member?.joinedTeamTimestamp?._seconds && (
                          <time>
                            {getDifferenceInTime(
                              new Date(
                                member?.joinedTeamTimestamp?._seconds * 1000
                              ).toISOString(),
                              new Date(Date.now()).toISOString()
                            )}
                          </time>
                        )}
                      </div>
                      {(userRole === "owner" &&
                        member.uid !== currentUser.uid) ||
                      (userRole === "admin" &&
                        member.uid !== currentUser.uid &&
                        teamData.createdBy !== member.uid) ? (
                        <SingleTeamMemberOptions
                          userRole={userRole}
                          user={member}
                          handleUpdateMembersList={handleUpdateMembersList}
                          teamName={teamData.name}
                          teamData={teamData}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </li>
                );
              }
            })
          : ""}
      </ul>
    </>
  );
};
