import { BestGamerHomePageHeading } from "./components/BestGamerHomePage.heading";
import { BestGamerHomePagePartners } from "./components/BestGamerHomePage.partners";
import { BestGamerHomePageMap } from "./components/BestGamerHomePage.map";
import { BestGamerHomePageDivider } from "./components/BestGamerHomePage.divider";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useAtomValue, useSetAtom } from "jotai";
import PaperTop from "../../../../public/assets/paperRipTop.webp"
import PaperBottom from "../../../../public/assets/paperRipBottom.webp"
import { useEffect } from "react";
import { headerHeight } from "../../../../branchAndBrandSpecific/layout/topNavigation/components/NavigationBar.ui";
import { navWidth } from "../../../../branchAndBrandSpecific/layout/sideNavigation/components/SideMenu.ui";

export const BestGamerHomePage = () => {
  const setCurrentClient = useSetAtom(clientAtom);  
  const headerRef = useAtomValue(headerHeight);
  const navRef = useAtomValue(navWidth);

  const homeBgStyle = {
    backgroundPosition: `${navRef}px ${headerRef}px`,
  };

  useEffect(() => {
    Object.assign(document.body.style, homeBgStyle);

    return () => {
      Object.keys(homeBgStyle).forEach(key => {
        document.body.style[key] = "";
      });
    };
  }, [navRef, headerRef]);
  
  useEffect(() => {
    document.body.classList.add("home-bg");
    setCurrentClient("");
    
    return () => {
      document.body.classList.remove("home-bg");
      setCurrentClient("");
    };
  }, []);

  return (
    <div className="home">
      <section className="home__head">
        <BestGamerHomePageHeading />
      </section>

      <BestGamerHomePageDivider
        image={PaperTop}
        heading="Er du Danmarks Bedste Gamer?"
        paragraph="Vælg et spil forneden og se hvordan du kan deltage i konkurrencerne."
        position="top"
      />

      <section className="home__partners">
        <BestGamerHomePagePartners />
      </section>

      <BestGamerHomePageDivider
        image={PaperBottom}
        heading="Se om vi kommer til en by nær dig"
        paragraph="Se hvilke byer vi kommer til og hvornår i foråret 2025."
        position="bottom"
      />

      <section className="home__map">
        <BestGamerHomePageMap />
      </section>
    </div>
  );
};
