import QueryDB from "@/api/queryDB/common";
import firebase from "firebase/app";

export const disconnectGameAccount = async (
  collectionName: string,
  uid?: string
): Promise<void | unknown | null> => {
  if (!uid) return null;

  try {
    if (collectionName === "profileBnet") {
      await QueryDB.updateDoc(collectionName, uid, {
        activisionId: firebase.firestore.FieldValue.delete(),
        battletag: firebase.firestore.FieldValue.delete(),
        activisionSetProperly: firebase.firestore.FieldValue.delete(),
      });
      return;
    }
    if (collectionName === "profilePubg") {
      await QueryDB.updateDoc(collectionName, uid, {
        pubgName: firebase.firestore.FieldValue.delete(),
      });
      return;
    }
    if (collectionName === "profileSteam") {
      await QueryDB.updateDoc(collectionName, uid, {
        displayName: firebase.firestore.FieldValue.delete(),
      });
      return;
    }
    if (collectionName === "profileDota2") {
      await QueryDB.deleteDoc(collectionName, uid);
      return;
    }
    await QueryDB.updateDoc(collectionName, uid, {
      connected: false,
    });
  } catch (error) {
    return error;
  }
  return null;
};
