import { AuthorProfile } from "@/models/database/profiles/profile.model";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IAuthorProfile {
  profilesMap: {
    [uid: string]: AuthorProfile;
  };
  getProfile: (uid: string) => AuthorProfile | undefined;
  setProfile: (profile: AuthorProfile) => void;
}

export const useAuthorProfile = create<IAuthorProfile>()(
  persist(
    (set, get) => ({
      profilesMap: {},
      getProfile: (uid) => {
        return get().profilesMap[uid];
      },
      setProfile: (newAuthor) =>
        set((state) => {
          return {
            profilesMap: {
              ...state.profilesMap,
              [newAuthor.uid]: newAuthor,
            },
          };
        }),
    }),
    {
      name: "author_profiles_storage",
    }
  )
);
