import React, { memo, useState } from "react";
import Button from "react-bootstrap/Button";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import QueryDB from "@/api/queryDB/common";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import checkIfCoDUsernameExists from "@/features/profile/helperFunctions/checkIfCoDUsernameExists";
import { Spinner } from "@/components/Spinner";

export const ConnectCoDForm: React.FunctionComponent<{
  onHide: () => void;
}> = ({ onHide }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [error, setError] = useState<string | null>(null);
  const [values, setValues] = useState({ activisionId: "" });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const setFieldValue = async (targetName: string, value: any) => {
    return setValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    setError(null);

    const nameExist = await checkIfCoDUsernameExists(
      values.activisionId,
      currentUser.uid
    );

    if (typeof nameExist === "string") {
      setSubmitting(false);
      return setError(nameExist);
    }

    if (!nameExist) {
      setSubmitting(false);
      return setError("This ID doesn't exist.");
    }
    setError("");
    await QueryDB.createNewDoc(
      "profileBnet",
      {
        activisionId: values.activisionId,
        activisionSetProperly: true,
      },
      `${currentUser.uid}`
    );
    setSubmitting(true);

    onHide();
  };
  return (
    <form
      onSubmit={(e) => {
        if (!submitting) {
          e.preventDefault();
          handleSubmit();
        }
      }}
      autoComplete="off"
    >
      <div className="profile-connect-cod__field--username">
        <BaseTextInput
          label="Activision ID"
          textType="text"
          value={values.activisionId}
          placeholder="Activision ID"
          setObjectState={setFieldValue}
          setObjectStateTargetName="activisionId"
          error={error ? error : ""}
        />
      </div>
      <div className="profile-connect-cod__connect--btn">
        {submitting ? (
          <Spinner />
        ) : (
          <Button variant="primary" type="submit" disabled={submitting}>
            Connect
          </Button>
        )}
      </div>
    </form>
  );
};
