/* eslint-disable consistent-return */
import React, { memo, FC, useEffect, useState, useRef } from "react";
import fileIcon from "./assets/paper-clip.svg";
import "firebase/database";
import { ReactComponent as ActiveFire } from "./assets/fire-active.svg";
// import ActiveFireReact from "@assets/posts/fire-active.svg";

import { Chat, Content, Message } from "./types/chatTypes";
import firebase from "firebase";
import EmojiPicker from "emoji-picker-react";
// import { profileTypes } from "@type/UsersTypes/ProfileTypes";
import { Button, Image } from "react-bootstrap";
// import { icon_close, icon_emoji } from "@utils/images/images";
// import { convertImageToImgIX } from "@utils/shared/generalUtils";
// import { addNewNotification } from "@services/notifications/sendNotification";
// import EmojiWrapper from "@inputscomponents/EmojiWrapper";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
// import { ChatMessage } from "./ChatMessage";
import { Query_Imgix } from "@/api/imgix/index";
import { ChatMessage } from "./types/ChatMessage";

type ChatViewProps = {
  selectedChat: string;
  searchChatQuerry: string;
  setChatMedia: any;
  chatMedia: any;
};

export const SingleChat: FC<ChatViewProps> = ({
  selectedChat,
  searchChatQuerry,
  setChatMedia,
  chatMedia,
}): JSX.Element => {
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [messageValue, setMessageValue] = useState<string>("");
  const [messageMedia, setMessageMedia] = useState<any>([]);
  const [initLoading, hasInitLoading] = useState<boolean>(false);
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const bottomEl = useRef<any>(null);
  const currentUser = useCurrentUserProfile((s) => s.user);
  // ?? but it works | Needs to be changes for later group chats
  const currentReceiverId = selectedChat
    .replace(`${currentUser.uid}_`, "")
    .replace(`_${currentUser.uid}`, "");

  useEffect(() => {
    setChatMedia([]);
    setChatMessages([]);
    const databaseRef = firebase.database().ref("chat").child(selectedChat);

    databaseRef.on("value", (snapshot) => {
      //  TODO  TimeStamp doesnt update everytime when creating new chat, sometimes it  will keep same timestamp of previous created chat
      if (snapshot.val()) {
        // turn data into array of object, wich each object looking as below
        // data: snapchat.val() data as message modal
        // uid: the id of the current doc | needed to be able to update and delete messages // works
        const messages = Object.values(snapshot.val()).map((snap, index) => {
          return {
            data: snap as Message[],
            uid: Object.entries(snapshot.val())[index][0], // doc uid
          };
        }) as any[];
        if (snapshot.val().unread) {
          // remove newer readBy field
          messages.pop();
        }
        setChatMessages(messages);
      } else {
        setChatMessages([]);
      }
    });
    return () => {
      databaseRef.off("value");
    };
  }, [selectedChat]);
  useEffect(() => {
    if (!searchChatQuerry) {
      if (!initLoading) {
        bottomEl?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      } else {
        hasInitLoading(true);
        bottomEl?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [chatMessages]);

  const readMessage = (messageUID) => {
    const dbRef = firebase.database().ref();

    // Set message read to true for current user
    dbRef.update({
      [`chat/${selectedChat}/${messageUID}/readBy/${currentUser.uid}`]: true,
    });

    // Decrease unread counter value by -1 per message for current user
    dbRef.update({
      [`chat/${selectedChat}/unread/${currentUser.uid}`]:
        firebase.database.ServerValue.increment(-1),
    });
  };

  const sendMessage = async (messageContent: Content, messageChat: Chat) => {
    const uniqueId = selectedChat;
    const databaseRef = firebase.database().ref("chat").child(uniqueId);
    const messageRef = databaseRef.push();

    const newMessage = {
      created: Date.now(),
      sender: currentUser.uid,
      receiver: currentReceiverId,
      content: messageContent,
      readBy: {
        [currentUser.uid]: true,
        [currentReceiverId]: false,
      },
      edited: {
        state: false,
        0: "",
      },
      deletedBy: {
        [currentUser.uid]: false,
        [currentReceiverId]: false,
      },
      reactions: {
        [currentUser.uid]: "",
        [currentReceiverId]: "",
      },
    };

    if (!messageChat.readBy?.[currentReceiverId]) {
      const dbRef = firebase.database().ref();
      dbRef.update({
        [`chat/${uniqueId}/unread/${currentReceiverId}`]:
          firebase.database.ServerValue.increment(1),
      });
    }
    bottomEl?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    await messageRef.set(newMessage);
    setMessageMedia([]);
    setMessageValue("");
  };
  const handleSendMessage = async () => {
    const inputField = document.getElementById(
      "message-input"
    ) as HTMLInputElement;

    const message = inputField.value.trim() || "";
    const messageContent: Content = {};
    const messageChat: Chat = {};

    if (message) {
      messageContent.message = message;
    }

    if (messageMedia[0]) {
      messageContent.imageURL = messageMedia[0].blob || null;
    }

    if (messageContent.message || messageContent.imageURL) {
      sendMessage(messageContent, messageChat);
      inputField.value = "";
    }
  };

  const handleRemoveImage = (image: string) => {
    const newArray = messageMedia as string[];
    const loc = newArray.indexOf(image);
    if (loc === 0) {
      newArray.shift();
    }
    if (loc) {
      newArray.splice(loc, 1);
    }
    return setMessageMedia([...newArray]);
  };

  return (
    <>
      <div className="chat-view">
        {chatMessages &&
          chatMessages
            .filter((chat: any) => {
              if (searchChatQuerry) {
                return chat.data.content?.message?.includes(searchChatQuerry);
              }
              if (!searchChatQuerry) {
                return chat;
              }
              return false;
            })
            .map((message: any) => {
              if (
                message.data.content?.imageURL &&
                !chatMedia.find(
                  (chatMediaStr) =>
                    chatMediaStr === message.data.content?.imageURL
                )
              ) {
                setChatMedia((prev) => [
                  ...prev,
                  message.data.content?.imageURL,
                ]);
              }
              if (
                !message.data.readBy.wog &&
                message.data.readBy[currentUser.uid] === false
              ) {
                readMessage(message.uid);
              }
              if (message.data.receiver === "wog") {
                return (
                  <div className="chat-view__message">
                    <p className="chat-view__message-new">
                      <span>
                        {message.data.content?.message} -{" "}
                        {new Date(message.data.created).toLocaleTimeString()}
                      </span>
                    </p>
                  </div>
                );
              }
              return (
                <>
                  <ChatMessage
                    selectedChat={selectedChat}
                    message={message}
                    profile={currentUser}
                  />
                </>
              );
            })}
        <div ref={bottomEl} />
      </div>

      <div className="chat-send">
        <div className="chat-send__options">
          <div className="chat-send__input">
            <input
              onChange={(e) => {
                setMessageValue(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSendMessage();
                }
              }}
              id="message-input"
              className="base-input"
              value={messageValue}
              type="text"
            />
            <div className="chat-send__file">
              <label htmlFor="file-picker">
                <img src={fileIcon} alt="Upload file(s)" />
                <input
                  id="file-picker"
                  type="file"
                  accept="image/*"
                  // TODO multiple // ImgIX doesnt like multiple calls to short in a row, have to rethink how to upload multiple images in a row
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    if (e.target.files && e.target.files[0]) {
                      if (messageMedia.length > 0) {
                        setMessageMedia([
                          ...messageMedia,
                          {
                            blob: await Query_Imgix.convertImageToImgIX(
                              e.target.files[0],
                              "chatMedia"
                            ),
                            file: e.target.files[0],
                          },
                        ]);
                      }
                      if (messageMedia.length === 0)
                        setMessageMedia([
                          {
                            blob: await Query_Imgix.convertImageToImgIX(
                              e.target.files[0],
                              "chatMedia"
                            ),
                            file: e.target.files[0],
                          },
                        ]);
                    }
                  }}
                />
              </label>
            </div>

            <Button
              variant="link"
              onClick={() => setShowEmoji((pre) => !pre)}
              className="position-absolute emojy--icon-position-comment d-none d-md-block"
            >
              {/* <img src={icon_emoji} alt="" /> */}
            </Button>
          </div>
          <div className="chat-send__button">
            <ActiveFire color="white" onClick={handleSendMessage} />
          </div>
        </div>
        <ul className="chat-upload-list">
          {messageMedia.map((media) => {
            return (
              <li key={media.blob}>
                <picture className="adaptive">
                  <figure className="adaptive-photo">
                    <Image width={50} height={50} src={media.blob} alt="" />
                  </figure>
                  {/* <Image
                      onClick={() => {
                        handleRemoveImage(media.blob);
                      }}
                      width={25}
                      height={25}
                      src={icon_close}
                      className="deleteBtn position-absolute"
                    /> */}
                </picture>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
