import React, { useRef, useState } from "react";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";
import { v4 } from "uuid";
import { useCommunityData } from "@/hooks/useCommunityData";
import { calculateDropdownPosition } from "./helperFunctions/calculateDropdownPosition";
import BaseUserAvatar from "../BaseUserAvatar";

export const BaseTextInputWithMentions: React.FunctionComponent<{
  label?: string | any;
  isDisabled?: boolean;
  textType: "text" | "textarea";
  value?: string;
  setSingleState?: any;
  setObjectState?: any;
  setObjectStateTargetName?: string;
  placeholder?: string;
  error?: string | undefined;
  functionOnEnter?: any;
  hasMentionOption?: boolean;
}> = (props) => {
  const [communityData, loading_community, communityError] = useCommunityData();

  const overlayRef = useRef<HTMLDivElement | null>(null);

  const updateOverlayContent = (content: string) => {
    if (overlayRef.current) {
      overlayRef.current.innerHTML = content
        .replace(/@(\w+)/g, '<span style="background-color: yellow;">$&</span>')
        .replace(/\n/g, "<br/>");
    }
  };
  const [currentMention, setCurrentMention] = useState<string>("");
  const [caretPosition, setCaretPosition] = useState(0);

  // handle input change
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    const mentionRegex = /@(\w+)$/;
    const match = newValue.match(mentionRegex);

    if (match) {
      setCurrentMention(match[1]);
      const caretPos = e.target.selectionStart;
      if (caretPos !== null) {
        setCaretPosition(caretPos);
      }
    } else {
      setCurrentMention("");
    }
    if (props.setSingleState) {
      return props.setSingleState(e.target.value);
    }
    if (props.setObjectState) {
      props.setObjectState(props.setObjectStateTargetName, e.target.value);
    }
    updateOverlayContent(newValue);
  };

  // Handle click on user from tags list
  const handleMentionClick = (friend: { uid: string; userName: string }) => {
    const newMention = `@${friend.userName} `;
    const newValue = props.value.replace(
      new RegExp(`@${currentMention}\\b`, "g"),
      newMention
    );
    if (props.setSingleState) {
      return props.setSingleState(newValue);
    }
    if (props.setObjectState) {
      props.setObjectState(props.setObjectStateTargetName, newValue);
    }
    setCurrentMention("");
  };

  const handleClickOutside = () => {
    const handleClickOutside = () => {
      if (caretPosition !== 0) {
        setCaretPosition(0);
        setCurrentMention("");
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  };
  // Ref logic for all different types of input fields
  const refLogic = (textareaRef: any) => {
    if (textareaRef && currentMention !== "") {
      const position = calculateDropdownPosition(textareaRef, caretPosition);
      const dropdown =
        textareaRef.parentElement?.querySelector(".mention-dropdown");
      if (dropdown) {
        (dropdown as HTMLElement).style.top = `${position.top}px`;
        (dropdown as HTMLElement).style.left = `${position.left}px`;
      }
    }
    handleClickOutside();
  };

  return (
    <>
      {props.label && <label className="settings__label">{props.label}</label>}

      {props.textType === "text" ? (
        <input
          ref={(textareaRef) => refLogic(textareaRef)}
          className="base-input settings__input"
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          value={props.value}
          autoComplete="disabled"
          id={v4()}
          onKeyDown={(e) => {
            if (props.functionOnEnter && e.key === "Enter") {
              return props.functionOnEnter();
            }
          }}
          onChange={(e) => {
            handleInputChange(e);
            return false;
          }}
          type={props.textType}
        />
      ) : (
        <textarea
          ref={(textareaRef) => refLogic(textareaRef)}
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          className="base-input settings__textarea"
          autoComplete="disabled"
          id={v4()}
          onKeyDown={(e) => {
            if (props.functionOnEnter && e.key === "Enter") {
              props.functionOnEnter();
            }
          }}
          onChange={(e) => {
            handleInputChange(e);
            return null;
          }}
          name=""
          value={props.value}
        >
          {props.value}
        </textarea>
      )}

      {currentMention !== "" && (
        <div className="mention-dropdown">
          {communityData &&
            communityData.friends.users
              .filter((friend) =>
                friend.userName
                  .toLowerCase()
                  .includes(currentMention.toLowerCase())
              )
              .map((friend: any) => {
                return (
                  <button
                    type="button"
                    key={friend.uid}
                    onClick={() => handleMentionClick(friend)}
                  >
                    <div className="mention-dropdown__image">
                      <BaseUserAvatar
                        userName={friend.userName}
                        logoUrl={friend.profileLogoUrl}
                      />
                    </div>
                    <div className="mention-dropdown__user">
                      <h4>{friend.userName}</h4>
                      {friend.fullName && <p>{friend.fullName}</p>}
                    </div>
                  </button>
                );
              })}

          {communityData.friends.users.filter((friend) =>
            friend.userName.toLowerCase().includes(currentMention.toLowerCase())
          ).length === 0 && <h4>No results</h4>}
        </div>
      )}

      {props.error && <FormErrorMessage text={props.error} isVisible={true} />}
    </>
  );
};
