import firebase from "firebase";
import { CreateGatherTypes } from "./createGatherValuesAndProps";
import { auth } from "@/api/firebase/firebase.config";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { Query_Imgix } from "@/api/imgix";

export const submitGather = async (values: CreateGatherTypes, slug: string) => {
  const validateDate = async (date: any, time: any) => {
    const test = new Date(time).toTimeString().split(":") as any;
    const splitHourToSeconds = test[0] * 3600;
    const splitMinutesToSeconds = test[1] * 60;
    const baseDate = new Date(date).getTime();
    const newDate =
      baseDate + (splitHourToSeconds + splitMinutesToSeconds) * 1000;
    return new Date(newDate) as any;
  };

  const getCorrectImage = async () => {
    if (values.logoUrl.includes("blob")) {
      const imgUrl = await Query_Imgix.convertImageToImgIX(
        values.logoUrlFile,
        `gathers/${slug}`
      );
      if (imgUrl) {
        return imgUrl;
      } else {
        return;
      }
    } else {
      return values.logoUrl;
    }
  };

  const currentUserId = auth.currentUser!.uid;
  const gather: any = {
    name: values.name,
    slug,
    type: values.type as "public" | "private",
    meetType: values.meetType,
    meetTypeSecondary: values.meetTypeSecondary,
    meetUrlOrAddress: values.meetUrlOrAddress,
    createdBy: firebase.auth().currentUser!.uid!,
    description: values.description || "",
    startDate: values.isAllDay
      ? values.startDate
      : await validateDate(values.startDate, values.startTime),
    endDate: values.isAllDay
      ? values.endDate
      : await validateDate(values.endDate, values.endTime),
    logoUrl: await getCorrectImage(),
    eventFrequencyType: values.eventFrequencyType,
    isAllDay: values.isAllDay,
    games: values.games,
    participantsCount: 0,
    interestedInCount: 0,
    status: "active",
    created: firebase.firestore.FieldValue.serverTimestamp(),
    canSendRequest: "all",
    postInFeed: "members",
    canLeaveComments: "members",
    favoriteBy: [],
  };

  await QueryDB.createNewDoc("gathers", gather, slug)
    .then(() => {
      createToast({
        type: "success",
        message: `Gather \`${gather.name}\` has been created!`,
      });
    })
    .catch((err) => {
      createToast({
        type: "error",
        message: `Something went wrong trying to create ${gather.name}. ${err}`,
      });
    });

  await QueryDB.createNewDoc(
    "userGatherJunction",
    {
      uid: currentUserId,
      gatherSlug: gather.slug,
      gatherId: gather.slug,
      status: "participant",
      created: firebase.firestore.FieldValue.serverTimestamp(),
      isFavorite: true,
      generalRole: "owner",
      isInterested: false,
      notificationSettings: {
        memberRequests: true,
        newMembers: true,
        newPostsInFeed: true,
        newComntsInFeed: true,
      },
    },
    `${currentUserId}_${gather.slug}`
  )
    .then(() => {
      createToast({
        type: "success",
        message: `Gather \`${gather.name}\` has been created!`,
      });
    })
    .catch((err) => {
      createToast({
        type: "error",
        message: `Something went wrong trying to create ${gather.name}.`,
      });
    });
};
