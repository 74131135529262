import { Button } from "@/components";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow1 } from "../../assets/Arrow1.svg";
import { ReactComponent as Arrow2 } from "../../assets/Arrow2.svg";
import { ReactComponent as Arrow3 } from "../../assets/Arrow3.svg";
import { ReactComponent as Arrow1Green } from "../../assets/Arrow1_green.svg";
import { ReactComponent as Arrow2Green } from "../../assets/Arrow2_green.svg";
import { ReactComponent as Arrow3Green } from "../../assets/Arrow3_green.svg";

interface CallToActionProps {
  title: string;
  desc: string;
  image: string;
  link?: string;
  label?: string;
  float: "left" | "right";
  arrow?: "1" | "2" | "3";
  arrowClr?: "green";
}

const LandingCallToAction: React.FC<CallToActionProps> = ({
  title,
  desc,
  image,
  link,
  label,
  float,
  arrow,
  arrowClr,
}) => {
  const navigate = useNavigate();
  const ctaVariables = `cta cta--${float}`;

  return (
    <div className="landing__cta">
      <div className={ctaVariables}>
        <div className="cta__image">
          <img src={image} />
          {arrow === "1" && (
            <div className={`cta__arrow cta__arrow--${arrow}`}>
              {arrowClr && arrowClr === "green" ? <Arrow1Green /> : <Arrow1 />}
            </div>
          )}
          {arrow === "2" && (
            <div className={`cta__arrow cta__arrow--${arrow}`}>
              {arrowClr && arrowClr === "green" ? <Arrow2Green /> : <Arrow2 />}
            </div>
          )}
          {arrow === "3" && (
            <div className={`cta__arrow cta__arrow--${arrow}`}>
              {arrowClr && arrowClr === "green" ? <Arrow3Green /> : <Arrow3 />}
            </div>
          )}
        </div>
        <div className="cta__content">
          <div className="cta__heading">
            <h3>{title}</h3>
          </div>
          <div className="cta__description">
            <p>{desc}</p>
          </div>
          {label && (
            <div className="cta__actions">
              <Button
                variant="primary"
                onClickAction={() => navigate(`${link}`)}
              >
                {label}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingCallToAction;
