import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_DAN from "./Danish";
import TRANSLATIONS_EN from "./English";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "eng",
    resources: {
      eng: {
        translation: TRANSLATIONS_EN,
      },
      dan: {
        translation: TRANSLATIONS_DAN,
      },
    },
  });
export default i18n;
