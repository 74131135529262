import { DropDown } from "@/components";
import { AuthorProfile } from "@/models/database/profiles/profile.model";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { FC, useState } from "react";
import { v4 } from "uuid";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/actions/more-vertical.svg";
import QueryDB from "@/api/queryDB/common";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import firebase from "firebase/app";
export const UserListItem: FC<{
  admin: AuthorProfile;
  index: number;
  teamSlug: string;
  role: "owner" | "admin" | "random" | "member";
  currentUserRole: "owner" | "admin" | "random" | "member";
}> = ({ admin, index, teamSlug, role, currentUserRole }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [isHidden, setIsHidden] = useState<boolean>();

  const canUpdateRights =
    (admin.uid !== currentUser.uid &&
      currentUserRole === "admin" &&
      role !== "owner") ||
    (currentUserRole === "owner" && admin.slug !== currentUser.slug);

  const handleUpdate = async () => {
    await QueryDB.updateDoc(
      "userTeamJunction",
      `${admin.uid}_${teamSlug}`,

      {
        isFavorite: false,
        status: "accepted",
        generalRole: "member",
        requestAccepted: {
          acceptedByUid: currentUser.uid,
          acceptedByUsername: currentUser.userName,
          acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
      }
    )
      .then(() => {
        setIsHidden(true);
      })
      .catch((err) => {
        setIsHidden(false);
      });
  };

  if (!isHidden) {
    return (
      <div key={v4()} className="user user__admin">
        <div className="user__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo name={admin.userName} logoSrc={admin.profileLogoUrl} />
            </div>
          </div>
        </div>
        <div className="user__content">
          <div className="user__name">
            <span className="user__username">{admin.userName}</span>
            <span className="user__fullname">{admin.fullName}</span>
          </div>
        </div>
        <div className="user__actions">
          <div className="user__role">
            {index === 0 ? "Owner, Admin" : "Admin"}
          </div>
          {canUpdateRights && (
            <div className="user__options">
              <DropDown logo={<ThreeDotsIcon />}>
                <a
                  className="dropdown__option"
                  onClick={async () => {
                    handleUpdate();
                  }}
                >
                  Take away admin rights
                </a>
              </DropDown>
            </div>
          )}
        </div>
      </div>
    );
  }

  return <> </>;
};
