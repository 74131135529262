import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import i18next from "i18next";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { SettingsAccountsCard } from "./SettingsAccountsCard";
import { SettingsAccountsHeader } from "./SettingsAccountsHeader";
import { SettingsAccountsModals } from "./SettingsAccountsModals";
import icon_search from "../../../../../../assets/actions/search-icon.svg";
import { createToast } from "@/utils/toaster/createToast";
import { disconnectGameAccount } from "@/features/profile/helperFunctions/disconnectGameAccount";
import refreshSteamConnection from "@/features/profile/helperFunctions/refreshSteamConnection";
import { checkGameActivation } from "@/features/profile/helperFunctions/checkGameActivation";
import { Image } from "@/components";

export const SettingsAccounts: React.FunctionComponent = () => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const [activisionId, setActivionId] = useState<string | null>(null);
  const [openCoDModal, setOpenCoDModal] = useState(false);
  const [disconnectedPubgModal, setDisconnectedPubgModal] = useState(false);
  const [disconnectedSteamModal, setDisconnectedSteamModal] = useState(false);
  const [disconnectedBnetModal, setDisconnectedBnetModal] = useState(false);
  const [activatedForDota, isActivatedForDota] = useState(false);
  const [activatedForPubg, isActivatedForPubg] = useState(false);
  const [activatedForBnet, isActivatedForBnet] = useState(false);
  const [activatedForWarzon, isActivatedForWarzon] = useState(false);

  const [steamId, setSteamId] = useState<string | null>(null);
  const [linkedBattletag, setLinkedBattletag] = useState<string | null>(null);
  const [pubgUsername, setPubgUsername] = useState<string | null>(null);
  const [openPubgModal, setOpenPubgModal] = useState(false);

  useEffect(() => {
    const getUserData = async (collectionName: string) => {
      await firebase
        .firestore()
        .collection(collectionName)
        .doc(`${currentUser.uid}`)
        .get()
        .then((res) => {
          if (!res || !res.data()) return;
          if (collectionName === "profileBnet") {
            setLinkedBattletag(res?.data()?.battletag);
            setActivionId(res?.data()?.activisionId);
            isActivatedForBnet(res?.data()?.isBnetActive);
          }
          if (collectionName === "profileSteam") {
            setSteamId(res?.data()?.displayName);
          }
          if (collectionName === "profileDota2") {
            isActivatedForDota(false);
          }
          if (collectionName === "profilePubg") {
            setPubgUsername(res?.data()?.pubgName);
            isActivatedForPubg(res?.data()?.inActive);
          }
        });
    };

    if (currentUser.uid) {
      getUserData("profileBnet");
      getUserData("profileSteam");
      getUserData("profileDota2");
      getUserData("profilePubg");
    }
  }, [currentUser.uid]);

  const checkGameAccount = async (collectionName: string): Promise<void> => {
    if (currentUser.uid && collectionName === "profileDota2") {
      firebase
        .firestore()
        .collection(collectionName)
        .doc(currentUser.uid)
        .get()
        .then((res) => {
          if (!res || !res.data()) {
            isActivatedForDota(false);
            return;
          }
          isActivatedForDota(true);
        });
    }
  };

  return (
    <div className="connections">
      {/* Modals */}
      <SettingsAccountsModals
        disconnectedBnetModal={disconnectedBnetModal}
        setDisconnectedBnetModal={setDisconnectedBnetModal}
        setLinkedBattletag={setLinkedBattletag}
        disconnectedSteamModal={disconnectedSteamModal}
        setSteamId={setSteamId}
        setDisconnectedSteamModal={setDisconnectedSteamModal}
        disconnectedPubgModal={disconnectedPubgModal}
        setPubgUsername={setPubgUsername}
        setDisconnectedPubgModal={setDisconnectedPubgModal}
        openPubgModal={openPubgModal}
        setOpenPubgModal={setOpenPubgModal}
        openCoDModal={openCoDModal}
        setOpenCoDModal={setOpenCoDModal}
      />

      {/* Header with list of accounts your are/arent connected to */}
      <SettingsAccountsHeader />
      <div className="settings__connections">
        <SettingsAccountsCard
          steamId={steamId}
          game="Steam"
          isConnectedCorrectly={!!steamId}
          userName={steamId as string}
          handleSwitchButton={async (state: boolean) => {
            if (state) {
              disconnectGameAccount("profileDota2", currentUser.uid).then(
                (res) => {
                  if (res) {
                    isActivatedForDota(false);
                  }
                }
              );
            }
            if (!state) {
              isActivatedForDota(true);
              const res = await refreshSteamConnection();
              if (res && res === "success") {
                checkGameAccount("profileDota2");
              } else {
                isActivatedForDota(false);

                createToast({
                  type: "error",
                  message: "Failed to connect",
                });
              }
            }
          }}
          handleClick={async () => {
            if (steamId) {
              setDisconnectedSteamModal(true);
            }
          }}
          isActivated={activatedForDota}
          handleRefreshBtn={async () => {
            const res = await refreshSteamConnection();
            if (res && res === "success") {
              checkGameAccount("profileDota2");
            } else {
              isActivatedForDota(false);

              createToast({
                type: "error",
                message: "Failed to connect",
              });
            }
            return false;
          }}
        />

        <SettingsAccountsCard
          setOpenPubgModal={setOpenPubgModal}
          pubgUsername={pubgUsername}
          game="PUBG"
          isConnectedCorrectly={!!pubgUsername}
          userName={pubgUsername as string}
          handleSwitchButton={(state: boolean) => {
            checkGameActivation(
              "profilePubg",
              "inActive",
              currentUser.uid,
              !state
            ).then(() => {
              isActivatedForPubg(!state);
            });
          }}
          handleClick={async () => {
            if (pubgUsername) {
              // disconnect
              setDisconnectedPubgModal(true);
            }
          }}
          isActivated={activatedForPubg}
          handleRefreshBtn={() => false}
        />

        <SettingsAccountsCard
          setOpenCoDModal={setOpenCoDModal}
          activisionId={activisionId}
          game="Call of Duty"
          isConnectedCorrectly={!!activisionId}
          userName={activisionId || ""}
          handleSwitchButton={() => {}}
          isActivated={activatedForWarzon}
          handleClick={async () => {
            if (activisionId) {
              await disconnectGameAccount("profileBnet", currentUser.uid);
              setActivionId(null);
            }
          }}
          handleRefreshBtn={() => false}
        />
        <SettingsAccountsCard
          linkedBattletag={linkedBattletag}
          game="Battle.net"
          isConnectedCorrectly={!!linkedBattletag}
          userName={linkedBattletag as string}
          handleSwitchButton={(state: boolean) => {
            checkGameActivation(
              "profileBnet",
              "isBnetActive",
              currentUser.uid,
              !state
            ).then(() => {
              isActivatedForBnet(!state);
            });
          }}
          handleClick={async () => {
            if (linkedBattletag) {
              setDisconnectedBnetModal(true);
            }
          }}
          isActivated={activatedForBnet}
          handleRefreshBtn={() => false}
        />
        {/* {!steamId && !linkedBattletag && !pubgUsername && !activisionId ? (
          <div className="connections__no-accounts">
            <div>
              <Image alt="icon_search" imageSrc={icon_search} />
            </div>
            <h4>{i18next.t("profile.edit.connections.no_connections")}</h4>
            <p>
              {i18next.t("profile.edit.connections.no_connections_desc1")} {brand.name}{" "}
              {i18next.t("profile.edit.connections.no_connections_desc2")}
            </p>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};
