import React, { FC, useState } from "react";
import { fullDays, monthNames } from "@/utils/constants/weekDays";

const DataView: FC<{
  isAllDay: boolean;
  startDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  endDate?: {
    _seconds: number;
    _nanoseconds: number;
  };
}> = (props): JSX.Element => {
  const [dateCase, setCase] = useState<number>(0);
  const formatDate = (date: { _seconds: number; _nanoseconds: number }) => {
    // isAllDay && start === end

    if (
      props.isAllDay &&
      props.endDate &&
      new Date(props.endDate._seconds * 1000).toDateString() ===
        new Date(props.startDate._seconds * 1000).toDateString()
    ) {
      if (dateCase !== 0) {
        setCase(0);
      }
      return {
        top: fullDays[
          new Date(date._seconds * 1000).toDateString().split(" ")[0] as "Sun"
        ],
        bottom: `
        ${monthNames[new Date(date._seconds * 1000).getMonth()]}
        ${new Date(date._seconds * 1000).getDate()}, ${new Date(
          date._seconds * 1000
        ).getFullYear()}
        `,
      };
    }
    // isAllDay && start !== end

    if (
      props.isAllDay &&
      props.endDate &&
      new Date(props.endDate._seconds * 1000).toDateString() !==
        new Date(props.startDate._seconds * 1000).toDateString()
    ) {
      if (dateCase !== 1) {
        setCase(1);
      }
      return {
        top: fullDays[
          new Date(date._seconds * 1000).toDateString().split(" ")[0] as "Sun"
        ],
        bottom: `
        ${monthNames[new Date(date._seconds * 1000).getMonth()]}
        ${new Date(date._seconds * 1000).getDate()}, ${new Date(
          date._seconds * 1000
        ).getFullYear()}
        `,
      };
    }

    // not AllDay && start === end

    if (
      !props.isAllDay &&
      props.endDate &&
      new Date(props.endDate._seconds * 1000).toDateString() ===
        new Date(props.startDate._seconds * 1000).toDateString()
    ) {
      if (dateCase !== 2) {
        setCase(2);
      }
      return {
        top: `
              ${monthNames[new Date(date._seconds * 1000).getMonth()]}
              ${new Date(date._seconds * 1000).getDate()}, ${new Date(
                date._seconds * 1000
              ).getFullYear()} `,
        bottom: `
        
        ${
          new Date(props.startDate._seconds * 1000)
            .toLocaleTimeString()
            .split(":")[0]
        }:${
          new Date(props.startDate._seconds * 1000)
            .toLocaleTimeString()
            .split(":")[1]
        }          ${
          Number(new Date(props.startDate._seconds * 1000).getHours()) >= 12
            ? "PM"
            : "AM"
        }-  ${
          new Date(date._seconds * 1000).toLocaleTimeString().split(":")[0]
        }:${
          new Date(date._seconds * 1000).toLocaleTimeString().split(":")[1]
        }           ${
          Number(new Date(date._seconds * 1000).getHours()) >= 12 ? "PM" : "AM"
        }
                  `,
      };
    }

    // not AllDay && start !== end

    if (
      !props.isAllDay &&
      props.endDate &&
      new Date(props.endDate._seconds * 1000).toDateString() !==
        new Date(props.startDate._seconds * 1000).toDateString()
    ) {
      if (dateCase !== 3) {
        setCase(3);
      }
      return {
        top: `
              ${monthNames[new Date(date._seconds * 1000).getMonth()]}
              ${new Date(date._seconds * 1000).getDate()}, ${new Date(
                date._seconds * 1000
              ).getFullYear()} `,
        bottom: `
                  ${
                    new Date(date._seconds * 1000)
                      .toLocaleTimeString()
                      .split(":")[0]
                  }:${
                    new Date(date._seconds * 1000)
                      .toLocaleTimeString()
                      .split(":")[1]
                  }           ${
                    Number(new Date(date._seconds * 1000).getHours()) >= 12
                      ? "PM"
                      : "AM"
                  }`,
      };
    }

    return {
      top: ``,
      bottom: ``,
      extra: ``,
    };
  };
  return (
    <>
      {dateCase === 0 && (
        <div>
          <h5 className="date-title__first">
            {formatDate(props.startDate).top}
          </h5>
          <h5 className="date-title__second">
            {formatDate(props.startDate).bottom}
          </h5>
          <h5 className="date-title__second">
            {formatDate(props.startDate).extra}
          </h5>
        </div>
      )}
      {dateCase === 1 && props.endDate && (
        <div>
          <h5 className="date-title__first">
            {formatDate(props.startDate).top}
          </h5>
          <h5 className="date-title__second">
            {formatDate(props.startDate).bottom}
          </h5>
          <h5 className="date-title__first">{formatDate(props.endDate).top}</h5>
          <h5 className="date-title__second">
            {formatDate(props.endDate).bottom}
          </h5>
        </div>
      )}
      {dateCase === 2 && !props.isAllDay && props.endDate ? (
        <div>
          <h5 className="date-title__first">{formatDate(props.endDate).top}</h5>
          <h5 className="date-title__second">
            {formatDate(props.endDate).bottom}
          </h5>
          <h5 className="date-title__second">
            {formatDate(props.endDate).extra}
          </h5>
        </div>
      ) : (
        ""
      )}
      {dateCase === 3 && props.endDate ? (
        <div>
          <h5 className="date-title__first">
            {formatDate(props.startDate).top}
          </h5>
          <h5 className="date-title__second">
            {formatDate(props.startDate).bottom}
          </h5>

          <h5 className="date-title__first">{formatDate(props.endDate).top}</h5>
          <h5 className="date-title__second">
            {formatDate(props.endDate).bottom}
          </h5>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DataView;
