import { codes } from "../consts/codes";

export const checkIfCompletedTreasureHunt = async (
  setScoreInProcentage,
  usersCollectedData,
  scoreInProcentage
) => {
  let hasCodeMissing = false;
  console.log("CHECKING+");

  let qrsScanned = 0;
  const scorePerPoint = 100 / codes.length;
  codes.map((code) => {
    if (!usersCollectedData[code]) {
      hasCodeMissing = true;
      return;
    } else {
      qrsScanned++;
      return false;
    }
  }) as any[];

  let scoreToProcentage = 0;

  const incrementScore = (increment) => {
    return new Promise((resolve) => {
      let completedIncrements = 0;
      for (let i = 0; i < increment; i++) {
        setTimeout(() => {
          setScoreInProcentage((scoreToProcentage += 1));
          completedIncrements++;
          if (completedIncrements === increment) {
            // @ts-ignore
            resolve();
          }
        }, i * 15);
      }
    });
  };

  for (let i = 0; i < qrsScanned; i++) {
    await incrementScore(scorePerPoint);
  }

  return { hasCodeMissing: !hasCodeMissing, score: scoreToProcentage };
};
