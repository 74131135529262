import firebase from "firebase/app";

export const _uploadFromBlobAsync = async ({
  blobUrl,
  fileName,
  metadata,
}: any): Promise<string> => {
  if (!blobUrl || !fileName) throw Error("BlobUrl and fileName error");

  const blob: Blob = await fetch(blobUrl).then((r) => r.blob());
  const snapshot: firebase.storage.UploadTaskSnapshot = await firebase
    .storage()
    .ref()
    .child(fileName)
    .put(blob, metadata);
  const answer: string = await snapshot.ref.getDownloadURL();
  return answer;
};
