import React from "react";

const BaseVideoTagWrapper: React.FunctionComponent<{
  styleProps?: string;
  videoSrc?: string;
  style?: any;
}> = ({ styleProps, videoSrc, style }) => {
  return (
    <video
      width="100%"
      controls
      preload="metadata"
      className={styleProps ?? ""}
      style={style}
    >
      <track kind="captions" />
      <source src={`${videoSrc}#t=0.5`} />
    </video>
  );
};

export default BaseVideoTagWrapper;
