import { DropDown } from "@/components";
import { FC, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/actions/more-vertical.svg";
import { SingleTeamDisbandModal } from "../modals/SingleTeamDisbandModal";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { ReportFormModal } from "@/components/ReportFormModal/ReportFormModal";
import { createToast } from "@/utils/toaster/createToast";
import { useTeamMembersHook } from "../../utils/useTeamMembersHook";
import { leaveTeam } from "../../helperFunctions/leaveTeam";
import { useNavigate } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
export const SingleTeamDropdownOptions: FC<{
  userRole: string;
  hasMembers: boolean;
  leaveTeamCheck: any;
  setSettingsOpen: any;
  teamData: SingleTeamResponseTypes;
}> = ({ userRole, hasMembers, leaveTeamCheck, setSettingsOpen, teamData }) => {
  const [disbandTeamOpen, setDisbandTeamOpen] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [teamMembers, _loadingAllMembers, errorMembers] = useTeamMembersHook();
  const [deleting, setIsDeleting] = useState<boolean>(false);

  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const isAdmin = userRole === "admin";
  const isOwner = userRole === "owner";
  const isMember = userRole === "member";
  const isInTeam = userRole !== "random";

  const handleDisbandTeam = async () => {
    if (!teamMembers || _loadingAllMembers) {
      return createToast({
        type: "error",
        message:
          "Failed to load team members, Disband canceled. Please try again later",
      });
    }
    setIsDeleting(true);

    // Delete team
    if (teamMembers.length !== 0) {
      await teamMembers.map(async (member: any, index: number) => {
        // If members, Delete all members
        if (member && member !== undefined) {
          await leaveTeam(
            "userTeamJunction",
            member.uid,
            teamData.slug,
            teamData.name,
            false,
            undefined
          );
        }
      });

      //  Delete current user
      await leaveTeam(
        "userTeamJunction",
        currentUser.uid!,
        teamData.slug,
        teamData.name,
        true,
        undefined
      ).then(async () => {
        setIsDeleting(false);
        navigate("/teams");
      });
    } else {
      await leaveTeam(
        "userTeamJunction",
        currentUser.uid,
        teamData.slug,
        teamData.name,
        true,
        undefined
      );
    }
    await QueryDB.deleteDoc("teams", teamData.slug);
    navigate("/teams");
    setIsDeleting(false);
    createToast({
      type: "info",
      message: `Deleted team '${teamData.name}' and removed all members in it.`,
    });
  };

  return (
    <>
      {disbandTeamOpen && (
        <SingleTeamDisbandModal
          disbandTeamOpen={disbandTeamOpen}
          setDisbandTeamOpen={setDisbandTeamOpen}
          teamName={teamData.name}
          teamSlug={teamData.slug}
          teamData={teamData}
          deleting={deleting}
          handleDisbandTeam={handleDisbandTeam}
        />
      )}
      {reportModal && (
        <ReportFormModal
          show={reportModal}
          closeModal={() => {
            setReportModal(false);
          }}
          children={""}
          reportFrom="team"
          reportItemId={teamData.slug}
        />
      )}

      <div className="card__dropdown">
        <DropDown logo={<ThreeDotsIcon />}>
          {(isAdmin || isOwner) && (
            <a
              onClick={() => setSettingsOpen((prev: boolean) => !prev)}
              className="dropdown__option"
            >
              Settings
            </a>
          )}
          <a
            onClick={() => {
              setReportModal(true);
            }}
            className="dropdown__option"
          >
            Report team
          </a>
          {isMember && (
            <>
              <a
                className="dropdown__option"
                onClick={() => setSettingsOpen((prev: boolean) => !prev)}
              >
                Notification settings
              </a>
            </>
          )}
          {isInTeam && (
            <>
              {hasMembers && (
                <a
                  className="dropdown__option"
                  onClick={() => {
                    leaveTeamCheck();
                  }}
                >
                  Leave team
                </a>
              )}
              {isOwner && (
                <a
                  className="dropdown__option"
                  onClick={() => {
                    setDisbandTeamOpen(true);
                  }}
                  aria-hidden="true"
                >
                  Delete team
                </a>
              )}
            </>
          )}
        </DropDown>
      </div>
    </>
  );
};
