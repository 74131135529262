import React, { FC } from "react";
import BaseUserAvatar from "../BaseUserAvatar";
import { Query_Imgix } from "@/api/imgix";
import { ReactComponent as IconAlert } from "../../assets/actions/alert-circle.svg";
import { ReactComponent as IconCheck } from "../../assets/actions/check.svg";
import BaseUserLoader from "../skeletonLoaders/BaseUserLoader/BaseUserLoader";
import { handleAddMember } from "./helperFunction/handleAddRemoveMember";
import { Button } from "..";

export const InviteUserModalList: FC<{
  searchValue: any;
  loading_community: any;
  membersToRender: any;
  checkIfSelected: any;
  membersToAdd: any[];
  setMembersToAdd: any;
  searchedMembers: any;
}> = ({
  searchValue,
  loading_community,
  membersToRender,
  checkIfSelected,
  membersToAdd,
  setMembersToAdd,
  searchedMembers,
}) => {
  return (
    <ul className="invite__list">
      {!searchValue &&
        !loading_community &&
        membersToRender &&
        membersToRender.map((friend: CommunityUser, index) => {
          const key = `${friend.userName}_${index}`;
          return (
            <li
              className={`invite__item ${
                checkIfSelected(friend) && "invite__item--selected"
              }`}
              aria-hidden
              onClick={() => {
                handleAddMember(
                  friend,
                  membersToAdd,
                  setMembersToAdd,
                  checkIfSelected
                );
              }}
              key={key}
            >
              <BaseUserAvatar
                logoUrl={friend.profileLogoUrl}
                userName={friend.userName}
                customStyles=""
              />
              <div className="item__info">
                <p>{friend.userName}</p>
                <span>{friend.fullName}</span>
              </div>
              {checkIfSelected(friend) && (
                <div className="invite__checkmark">
                  <IconCheck />
                </div>
              )}
            </li>
          );
        })}

      {searchValue.length !== 0 &&
        !loading_community &&
        membersToRender &&
        (searchedMembers && searchedMembers.length === 0 ? (
          <li className="invite__item invite__item--empty" key="friends_empty">
            <div>
              <p>
                <IconAlert /> Nothing matched your search.. Try again!
              </p>
            </div>
          </li>
        ) : (
          searchedMembers &&
          searchedMembers.map((friend, index) => {
            const key = `${friend.userName}_${index}`;
            return (
              <li
                className={`invite__item ${
                  checkIfSelected(friend) && "invite__item--selected"
                }`}
                aria-hidden
                onClick={() => {
                  handleAddMember(
                    friend,
                    membersToAdd,
                    setMembersToAdd,
                    checkIfSelected
                  );
                }}
                key={key}
              >
                <BaseUserAvatar
                  logoUrl={friend.profileLogoUrl}
                  userName={friend.userName}
                  customStyles=""
                />
                <div className="item__info">
                  <p>{friend.userName}</p>
                  <span>{friend.fullName}</span>
                </div>

                {checkIfSelected(friend) && (
                  <div className="invite__checkmark">
                    <IconCheck />
                  </div>
                )}
              </li>
            );
          })
        ))}

      {!searchValue &&
        !loading_community &&
        membersToRender &&
        membersToRender.length === 0 && (
          <div className="invite__add">
            <p>You don't have any new friends to add.</p>
          </div>
        )}

      {loading_community && (
        <li className="invite__item" key="comunity_invite">
          <div>
            <BaseUserLoader />
          </div>
        </li>
      )}
    </ul>
  );
};
