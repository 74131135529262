import { Button, Spinner } from "@/components";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { AuthenticationResponse } from "@/features/auth/types/authenticationTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { createToast } from "@/utils/toaster/createToast";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { FC, useEffect } from "react";
import { auth } from "@/api/firebase/firebase.config";

export const ChangePasswordSendEmail: FC<{
  accountCredentials: {
    email: string;
    password: string | undefined;
    passwordVerify: string | undefined;
  };
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  passwordResetCodeSent: boolean;
  setPasswordResetCodeSent: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  accountCredentials,
  setFieldValue,
  passwordResetCodeSent,
  setPasswordResetCodeSent,
}) => {
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );
  const currentUserEmail = auth.currentUser?.email;

  useEffect(() => {
    if (accountCredentials.email && response && !passwordResetCodeSent) {
      if (response.status === "email-sent") {
        createToast({
          type: "success",
          message: response.payload,
        });
        setPasswordResetCodeSent(true);
      }

      if (
        response.status === "not-found" ||
        response.status === "email-sent-failed"
      ) {
        createToast({
          type: "error",
          message: response.payload,
        });
      }
    }
  }, [response]);

  return (
    <>
      <div>
        <Button
          disabled={passwordResetCodeSent}
          variant="primary"
          onClickAction={async () => {
            setFieldValue("email", currentUserEmail);
            await authenticate({
              method: "email",
              emailAddress: currentUserEmail,
              platform: brand.brandValue,
              action: "forget-password",
            });
          }}
        >
          {_loading ? <Spinner /> : "Send reset password code"}
        </Button>
      </div>
    </>
  );
};
