import firebase from "firebase/app";

export const _sharePost = async (
  postId?: string,
  currUserId?: string,
  postText?: string
) => {
  if (!postId || !currUserId) return;

  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("shareCurrPost");

  await func({
    postId,
    currUserId,
    postText,
  });
};
