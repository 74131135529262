import firebase from "firebase/app";
import {
  convertCleanEntityNameToSlug,
  cleanEntityName,
  checkEntityNameValidity,
} from "./stringProcessing";
import { auth } from "@/api/firebase/firebase.config";
// import { CreateChallengeFormValues } from "@/features/challenge/utils/CreateChallengeTypes";
import { getDifferenceInDays } from "./formatDate";
import { CreateTeamFormValues } from "@/features/team/types/TeamTypes";

export const validateNotEmpty = (value: string): string | null => {
  if (!value) {
    return "Empty field is not allowed";
  }
  return null;
};

const validateNameIsCorrect = (value: string, title: string): string | null => {
  if (value.length > 0 && !checkEntityNameValidity(value)) {
    return `${title} should contain only letters and numbers`;
  }
  return null;
};
const validateEmailIsCorrect = (value: string): string | null => {
  if (value.length > 0) {
    const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const isValid = expression.test(String(value).toLowerCase());
    if (!isValid) {
      return ` That email address isn't correct`;
    }
  }
  return null;
};

export const validateNotTaken = async (
  mode = "create",
  newValue: string,
  collectionName: string,
  fieldToCheck: string,
  title: string,
  isSame?: boolean,
  oldValue?: string
): Promise<string | null | undefined> => {
  const db = firebase.firestore();
  const doc = await db
    .collection(collectionName)
    .where(`${fieldToCheck}`, "==", newValue)
    .get();
  if (mode === "create") {
    if (!doc.empty) {
      return "This name is already taken";
    }
    return null;
  }

  if (mode === "edit") {
    if (!doc.empty) {
      if (newValue === oldValue) {
        return null;
      }
      return `${title} is already taken, please choose another one`;
    }
  }
  return null;
};

export const validateName = async (
  mode: "edit" | "create",
  newValue: string,
  isSame: boolean,
  title: string,
  collectionName: string,
  fieldToCheck: string,
  oldValue?: string
): Promise<string | null | undefined> => {
  const isEmpty = validateNotEmpty(newValue);
  if (isEmpty) return isEmpty;

  const isCorrect = validateNameIsCorrect(newValue, title);
  if (isCorrect) return isCorrect;

  const isExist = await validateNotTaken(
    mode,
    newValue,
    collectionName,
    fieldToCheck,
    title,
    isSame,
    oldValue
  );
  if (isExist) return isExist;

  return null;
};
export const validateEmail = async (
  newValue: string,
  collectionName: string,
  fieldToCheck: string
): Promise<string | null | undefined> => {
  const isEmpty = validateNotEmpty(newValue);
  if (isEmpty) return isEmpty;

  const isCorrect = validateEmailIsCorrect(newValue);
  if (isCorrect) return isCorrect;
  const db = firebase.firestore();
  const doc = await db
    .collection(collectionName)
    .where(`${fieldToCheck}`, "==", newValue)
    .get();
  if (!doc.empty) {
    return "This Email is already taken, please choose another one";
  }
  const doesEmailExist = await auth.fetchSignInMethodsForEmail(newValue);
  if (doesEmailExist.length !== 0) {
    return "This Email is already taken, please choose another one";
  }
  return null;
};
export const validatePostNotEmpty = (values: any) => {
  const errors: {
    postText?: string;
    postMedia?: string;
  } = {};
  if (!values.postMedia && !values.postText) {
    errors.postText = "Empty field is not allowed";
  }
  return errors;
};

export const validateSettingsValues = async (values: any, oldValue: string) => {
  const errors: any = {};
  return validateName(
    "edit",
    values.userName.toLowerCase(),
    true,
    "Username",
    "profiles",
    "slug",
    oldValue.toLowerCase()
  ).then((res) => {
    if (res) {
      errors.userName = res?.toString();
    }
    if (values.userName.length < 3) {
      errors.userName = "Min 3 characters";
    }
    if (values.profileDescription.length > 500) {
      errors.profileDescription = "Max 500 characters";
    }
    // if (!values.region) {
    //   errors.region = "Please select your region";
    // }
    return errors;
  });
};

export const validateTeamSettingsValues = async (
  values: any,
  oldName: string
) => {
  const errors: any = {};
  const valueToBeChecked = convertCleanEntityNameToSlug(
    cleanEntityName(values.name)
  );
  const oldValueToBeChecked = convertCleanEntityNameToSlug(
    cleanEntityName(oldName ?? "")
  );

  const resSlug = await validateNotTaken(
    "edit",
    valueToBeChecked,
    "teams",
    "slug",
    "Team name",
    false,
    oldValueToBeChecked
  );
  const resName = await validateNotTaken(
    "edit",
    valueToBeChecked,
    "teams",
    "name",
    "Team name",
    false,
    oldValueToBeChecked
  );
  if (resSlug || resName) {
    errors.name = "This name has already been taken";
  }
  return Object.entries(errors).length === 0 ? undefined : errors;
};

export const validateTeam = async (
  values: Omit<CreateTeamFormValues, "teamLogo">,
  from = "create",
  oldValue?: string
) => {
  const errors: {
    name?: string;
    game?: string;
    description?: string;
  } = {};
  const valueToBeChecked = convertCleanEntityNameToSlug(
    cleanEntityName(values.name)
  );
  const oldValueToBeChecked = convertCleanEntityNameToSlug(
    cleanEntityName(oldValue ?? "")
  );

  const res = await validateNotTaken(
    from,
    valueToBeChecked,
    "teams",
    "slug",
    "Team name",
    false,
    oldValueToBeChecked
  );

  if (res) {
    errors.name = res?.toString();
  }
  if (!values.name) {
    errors.name = "Name must not be empty";
  }
  if (!values.games) {
    errors.game = "Games must not be empty";
  }
  return errors;
};

export const validatePUBGForm = async (values: any) => {
  const errors: {
    pubgName?: string;
    platform?: string;
  } = {};

  if (!values.pubgName) {
    errors.pubgName = "Username must not be empty";
  }
  if (!values.platform) {
    errors.platform = "Platform must not be empty";
  }
  return errors;
};

export const validateSubmitResults = (values: any) => {
  const errors: {
    ownScores?: string;
    opponentScores?: string;
    screenshot?: string;
  } = {};

  if (!values.opponentScores) {
    errors.opponentScores = "Scores must not be empty";
  }
  if (!values.ownScores) {
    errors.ownScores = "Scores must not be empty";
  }
  if (!values.screenshot) {
    errors.screenshot = "Screenshot is a mandatory field";
  }

  return errors;
};
export const validateReportResults = (values: any) => {
  const errors: {
    reportTitle?: string;
    emailAddress?: string;
    reportDescription?: string;
  } = {};

  if (!values.reportTitle) {
    errors.reportTitle = "Field is empty";
  }
  if (!values.emailAddress) {
    errors.emailAddress = "Field is empty";
  }
  if (!values.reportDescription) {
    errors.reportDescription = "Description field is empty";
  }
  return errors;
};

const now = new Date();
now.setDate(now.getDate() - 1);
now.setHours(0);
now.setMinutes(0);
now.setSeconds(0);

export const validateChallengeForm = async (values: any, step: number) => {
  const errors: {
    [key: string]: string;
  } = {};
  if (step === 0) {
    if (!values.game) {
      errors.game = "Pick a game";
    }
  }

  if (step === 1) {
    if (!values.onlyWins) {
      errors.onlyWins = "Choose challenge type";
    }

    // Object.keys(values.criteria)[0] === ""
    if (
      !Object.keys(values.criteria) ||
      Object.keys(values.criteria).length === 0 ||
      (Object.keys(values.criteria).length === -1 && values.game === "dota2")
    ) {
      errors.criteria = "Set at least 1 criteria";
    }

    if (["PUBG", "Warzone"].includes(values.game) && !values.teamSize) {
      errors.teamSize = "Choose team size";
    }
    if (!values.type) {
      errors.type = "Choose access type";
    }
  }
  if (step === 2) {
    /* name */

    if (!values.duration[0] || !values.duration[1]) {
      errors.duration = "Set challenge duration";
    }
    if (new Date(values.duration[0]) < now) {
      errors.duration = "Invalid start date";
    }
    if (new Date(values.duration[1]) < now) {
      errors.duration = "Invalid end date";
    }
    if (getDifferenceInDays(values.duration[0], values.duration[1]) > 31) {
      errors.duration = "Max length is 31 days";
    }
    if (values.name.length > 25) {
      errors.name = "Max 25 characters";
    }
    if (values.name.length < 3) {
      errors.name = "Min 3 characters";
    }

    if (values.description && values.description.length > 470) {
      errors.description = "Max 470 characters";
    }
    const valueToBeChecked = convertCleanEntityNameToSlug(
      cleanEntityName(values.name)
    );
    const result = await validateNotTaken(
      "create",
      valueToBeChecked,
      "challenges",
      "slug",
      "Challenge name",
      false
    );
    if (result) {
      errors.name = result?.toString();
    }
    /* other */
    if (!values.region) {
      errors.region = "Choose a region";
    }
    if (!values.logoUrl) {
      errors.logoUrl = "Upload a picture";
    }
    if (!values.game) {
      errors.game = "Choose a game";
    }
    if (values.goal.length > 70) {
      errors.goal = "Max 70 characters";
    }
    if (values.goal.length < 3) {
      errors.goal = "Use at least 3 characters";
    }
  }
  return Object.entries(errors).length === 0 ? undefined : errors;
};

// export const validateCoDForm = async (values: CoDModalFormValues) => {
//   const errors: any = {};

//   if (!values.activisionId) {
//     errors.activisionId = "ID must not be empty";
//   }

//   return errors;
// };
