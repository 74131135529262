import React, { FC, memo, useEffect, useState } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SingleChallengeUi } from "../../single/SingleChallenge.ui";
import { CreateChallengeFormTypes } from "@/features/challenge/utils/CreateChallengeTypes";
import { Query_Imgix } from "@/api/imgix";
import { Timestamp } from "@/api/firebase/firebase.config";

const CreateChallengeStep3: FC<{
  values: CreateChallengeFormTypes;
}> = ({ values }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const coefficients = { ...values.criteria };
  const [newLogo, setNewLogo] = useState<string>("");
  if ("" in coefficients) {
    delete coefficients[""];
  }

  useEffect(() => {
    const convertedImageUrl = async () => {
      const resp = URL.createObjectURL(values.logoUrl!);
      setNewLogo(resp);
    };
    if (!newLogo) {
      convertedImageUrl();
    }
  }, []);

  return (
    <SingleChallengeUi
      isEdit={false}
      setIsEdit={undefined}
      isPreview={true}
      additionalFields={[
        {
          title: "Team size",
          value: values.teamSize ?? "Not specified",
          toolTipKey: "Team size",
        },
        {
          title: "Challenge type",
          value: values.onlyWins
            ? values.onlyWins === "Cumulative"
              ? "Accumulative"
              : values.onlyWins
            : "Not specified",
          toolTipKey: "Challenge type",
        },
        {
          title: values?.mapPool ? "Map pool" : "Maps",
          value: values?.mapNames ? values?.mapNames.join(", ") : "All maps",
          toolTipKey: "maps",
        },
        {
          title: "Modes",
          value: values?.mapModes ? values?.mapModes.join(", ") : "All modes",
          toolTipKey: "modes",
        },
      ]}
      // Contains main data about challenge | needs to be added as is, so it matched the types structure
      data={{
        challengeData: {
          client: "WOG",
          gameType: values.gameType || "",
          game: values.game,
          goal: values.goal,
          creatorType: "Community",
          pointCountingType: values.onlyWins,
          created: {
            _seconds: 0,
            _nanoseconds: 0,
          },
          description: values.description || "",
          challengeAdditionalData: {
            fieldToCompare: values.fieldToCompare || [],
            fieldToCompareCoefficients: coefficients,
          },
          type: values.type,
          logoUrl: newLogo || "",
          onlyWins: values.onlyWins,
          createdBy: currentUser.uid,
          teamSize: values.teamSize,
          name: values.name,
          region: values.region,
          slug: "",
          startDate: {
            _seconds: new Date(values.duration[0]).getTime() / 1000,
            _nanoseconds: 0,
          },
          hasFinalPoints: false,
          participantsCount: 0,
          participantUids: [],
          endDate: {
            _seconds: new Date(values.duration[1]).getTime() / 1000,
            _nanoseconds: 0,
          },
          status: "ongoing",
        },
        status: "",
        challengeAdditionalData: {
          fieldToCompare: values.fieldToCompare || [],
          fieldToCompareCoefficients: coefficients,
        },
        isInCurrUserFavorites: false,
        isCurrUserParticipant: false,
        isCreatedByCurrUser: false,
        creatorProfile: {
          uid: currentUser.uid,
          region: currentUser.country,
          created: currentUser.created as any,
          profileDescription: currentUser.profileDescription,
          fullName: currentUser.fullName || "",
          favoriteGames: currentUser.favoriteGames || [],
          userName: currentUser.userName,
          slug: currentUser.slug,
          profileLogoUrl: currentUser.profileLogoUrl || "",
        },
        participantsCount: 0,
        lastThreeParticipantsProfiles: [],
        isCreatorFollowedByCurrentUser: true,
        creatorFollowersCount: currentUser.followersCount,
      }}
      isPrivateForUser={false}
      children={""}
    />
  );
};

export default memo(CreateChallengeStep3);
