import React, { memo } from "react";
import Badge from "react-bootstrap/Badge";
import { ReactComponent as BellIcon } from "../assets/bell.svg";
import { Notification } from "@/models/database/notifications/Notification.model";
import { archiveOrReadNotification } from "../helperFunctions/readNotification";
import { Button, ErrorFallback, Spinner } from "@/components";
import NotificationsActions from "../helperFunctions/notificationsActions";
import { showActionTypes } from "@/utils/constants/notificationVariables";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { userTeamJunctionStore } from "@/store/team/userTeamJunction.store";

const SingleNotificationActions: React.FunctionComponent<{
  read: boolean;
  setRead: any;
  data: Notification;
  docId: string;
  dataObj: any; // THIS HOLD THE OBJECT OF A SINGLE TEAM, GATHER, CHALLENGE etc
  setLoading: any;
  loading: boolean;
}> = ({ read, setRead, data, docId, dataObj, setLoading, loading }) => {
  const buttonsToShow = ["Accept", "Reject"];
  const currentUser = useCurrentUserProfile((s) => s.user);
  const actionSelected = data.actionSelected;
  const setTeamJunction = userTeamJunctionStore((s) => s.setTeamJunction);

  try {
    return (
      <div
        className={`notifications__actions ${
          actionSelected
            ? "notifications__actions--inactive"
            : "notifications__actions--active"
        }`}
      >
        {!loading &&
          !read &&
          !data.read &&
          buttonsToShow.map((title, index) => {
            return (
              <Button
                key={title}
                variant={`${
                  title === "Join" || title === "Accept"
                    ? "primary"
                    : "secondary"
                }`}
                onClickAction={async () => {
                  setLoading(true);

                  try {
                    await NotificationsActions(
                      data,
                      docId,
                      title as "Delete" | "Join" | "Accept" | "Reject",
                      showActionTypes[data.type].notificationRespType,
                      dataObj,
                      currentUser
                    ).then(() => {
                      setRead(true);
                      setLoading(false);
                      setTeamJunction(data.contendId, {
                        currUserStatus: "accepted",
                        generalRole: "member",
                      });
                    });
                  } catch (error) {
                    console.error(
                      "Error executing NotificationsActions:",
                      error
                    );
                    setLoading(false);
                  }
                }}
              >
                {title}
              </Button>
            );
          })}
        {actionSelected && (
          <div className="notifications__response">{actionSelected}</div>
        )}
        {loading && <Spinner />}
      </div>
    );
  } catch {
    return <ErrorFallback></ErrorFallback>;
  }
};

export default memo(SingleNotificationActions);
