export const codes = [
  "da3a47f5-9fec-4c25-af96-6a7ab037bf36_1",
  "4ef130d4-f317-4b5d-ab25-3a888b8db330_2",
  "f4f53fc9-a8cb-43b5-984b-dff41342faa0_3",
  "2a4a79bb-7d68-40a9-83ad-e0d272779899_4",
  "ca19d607-ac90-4544-ac77-f90365499694_5",
  "676b63be-e7d2-4903-b34b-913683d82d77_6",
  "2a094234-9e74-4c16-86ea-9b51e19cc4ee_7",
  "ae716bd4-fbc5-4c24-84c9-c133a08f8d98_8",
  "502a27c2-b175-4228-9267-72a6d0d8de7e_9",
  "5270a199-b3ef-4244-84cd-e3552023947e_10",
];

export const names = [
  "meeting table",
  "bathroom",
  "window",
  "tv",
  "fridge",
  "fire exit",
  "entrance",
  "desk",
  "kitchen",
  "door",
];

export const combinedCodes = {
  "da3a47f5-9fec-4c25-af96-6a7ab037bf36_1": "meeting table",
  "4ef130d4-f317-4b5d-ab25-3a888b8db330_2": "bathroom",
  "f4f53fc9-a8cb-43b5-984b-dff41342faa0_3": "window",
  "2a4a79bb-7d68-40a9-83ad-e0d272779899_4": "tv",
  "ca19d607-ac90-4544-ac77-f90365499694_5": "fridge",
  "676b63be-e7d2-4903-b34b-913683d82d77_6": "fire exit",
  "2a094234-9e74-4c16-86ea-9b51e19cc4ee_7": "entrance",
  "ae716bd4-fbc5-4c24-84c9-c133a08f8d98_8": "desk",
  "502a27c2-b175-4228-9267-72a6d0d8de7e_9": "kitchen",
  "5270a199-b3ef-4244-84cd-e3552023947e_10": "door",
};

//  da3a47f5-9fec-4c25-af96-6a7ab037bf36_1 -  meeting table
//  4ef130d4-f317-4b5d-ab25-3a888b8db330_2 -  bathroom
//  f4f53fc9-a8cb-43b5-984b-dff41342faa0_3 -  window
//  2a4a79bb-7d68-40a9-83ad-e0d272779899_4 -  tv
//  ca19d607-ac90-4544-ac77-f90365499694_5 -  fridge
//  676b63be-e7d2-4903-b34b-913683d82d77_6 -  fire exit
//  2a094234-9e74-4c16-86ea-9b51e19cc4ee_7 -  entrance
//  ae716bd4-fbc5-4c24-84c9-c133a08f8d98_8 -  desk
//  502a27c2-b175-4228-9267-72a6d0d8de7e_9 -  kitchen
//  5270a199-b3ef-4244-84cd-e3552023947e_10 - door
