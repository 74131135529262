import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as IconViews } from "@/assets/actions/eye.svg";
import { ReactComponent as IconCheck } from "@/assets/actions/check.svg";
import i18next from "i18next";

interface SingleChallengeBestgamerSingleHighlightProps {
    video: {
        createdAt: number;
        duration: number;
        game: string;
        isViewed: boolean;
        url: string;
    }
    index: number;
}

export const SingleChallengeBestgamerSingleHighlight: FC<SingleChallengeBestgamerSingleHighlightProps> = ({
    video,
    index,
}) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [viewCount, setViewCount] = useState(0);
    const [isViewed, setIsViewed] = useState(video.isViewed);

    useEffect(() => {
        setViewCount(Math.floor((Math.random() * (1000 - 1) + 0)));
    }, [video]);

    const resetVideo = () => {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
    }

    const handlePlay = () => {
        if (videoRef.current && videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
            setViewCount(viewCount + 1);
            setIsViewed(true);
        } else if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }

    // Variables
    const itemClass = isViewed ? "challenge-highlights__item challenge-highlights__item--viewed" : "challenge-highlights__item";

    return (
        <div className={itemClass} id={`video-${index}`} key={index} onClick={handlePlay}>
            <div className={`${isViewed ? "video video--viewed" : "video"} ${isPlaying && "video--playing"}`}>
                <div className="video__content">
                    <video
                        ref={videoRef}
                        preload="metadata"
                        disablePictureInPicture
                        muted
                        onEnded={resetVideo}
                        >
                        <source src={video.url} type="video/mp4" />
                    </video>
                </div>
                <div className={`${isViewed ? "video__badge video__badge--viewed" : "video__badge"} ${isPlaying && "video__badge--hidden"}`}>
                    {isViewed ? (
                        <div className="video__label video__label--viewed">
                            <IconCheck width={16} height={16} strokeWidth={2} />
                        </div>
                    ) : (
                        <div className="video__label">
                            {i18next.t("challenge.highlights.new")}
                        </div>
                    )}
                </div>
            </div>
            <div className="views">
                <div className="views__icon">
                    <IconViews stroke="#fff" strokeWidth={2} />
                </div>
                <div className="views__count">
                    {viewCount.toFixed(0)}
                </div>
            </div>
        </div>
    );
};