import QueryDB from "@/api/queryDB/common";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import firebase from "firebase/app";

export const leaveTeam = async (
  collectionName: string,
  uid: string,
  teamSlug: string,
  teamName: string,
  isOwner: boolean,
  newAdminUid?: string
): Promise<void> => {
  if (isOwner || newAdminUid) {
    const docExists = await QueryDB.getSingleDoc(
      "userTeamJunction",
      `${newAdminUid}_${teamSlug}`
    );
    if (isOwner && newAdminUid && docExists) {
      // 1: If invited new admin does have a UserTeamJunction, Update new owners UserTeamJunction to owners Role
      await QueryDB.updateDoc(
        "userTeamJunction",
        `${newAdminUid}_${teamSlug}`,
        {
          generalRole: "owner",
          status: "accepted",
          notificationSettings: {
            memberRequests: true,
            newMembers: true,
            newPostsInFeed: true,
            newComntsInFeed: true,
            upcomingEvents: true,
          },
        }
      ).then(async () => {
        // 2: Update team doc's createdBy to new owners UID3
        await QueryDB.updateDoc("teams", teamSlug, {
          createdBy: newAdminUid,
        }).then(() => {
          if (teamName) {
            // Notify new owner about ownerShip
            QueryDB_Notifications.createNewNotification(
              uid,
              newAdminUid,
              teamSlug,
              "teams-promote",
              `${notificationsTextMapping["teams-promote"]} <strong>${teamName}</strong>`
            );
          }
          // Delete current owners document/leave team
          return QueryDB.deleteDoc(collectionName, `${uid}_${teamSlug}`);
        });
      });
    }
    if (isOwner && !newAdminUid) {
      return QueryDB.deleteDoc(collectionName, teamSlug);
    }
  }

  if (!isOwner && !newAdminUid) {
    return QueryDB.deleteDoc(collectionName, `${uid}_${teamSlug}`);
  }
};
