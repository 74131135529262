import React, { FC, useEffect, useState } from "react";
import { Button, Col, Image } from "react-bootstrap";
import ThreeDotsIcon from "@/assets/actions/more-vertical.svg";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import { InterestedParticipatingUsersProps } from "../../types/cloudFunctions/participatingUserProps";

type props = {
  user: InterestedParticipatingUsersProps | null;
  setUpdateParticipant: React.Dispatch<React.SetStateAction<number>>;
  gatherSlug: string;
  currentUserGeneralRole?: "owner" | "admin" | "member" | "random";
};

const InterestedParticipatingUserCard: FC<props> = (props): JSX.Element => {
  const [openOption, setOpenOptions] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (props.user) {
      setIsAdmin(props.user.generalRole === "admin");
    }
  }, []);

  return (
    <Col xs={12} lg={6} className="mb-2">
      <div className=" gather__members--modal-user">
        <div className="d-flex align-items-center ">
          {props.user?.profileLogoUrl ? (
            <div>
              <Image width={40} height={40} src={props.user?.profileLogoUrl} />
            </div>
          ) : (
            <BaseLogo
              name={props.user?.userName as string}
              customStyles="avatar--small"
              isPlaceHolder={undefined}
            />
          )}
          <div className="info">
            <p className=" info-username ms-2`">{props.user?.userName}</p>
            <p className="info-fullname ms-2 text-white-50">
              {props.user?.fullName}
            </p>
          </div>
        </div>
        <div>
          {(props.user &&
            props.user.generalRole !== "owner" &&
            props.currentUserGeneralRole === "admin" &&
            currentUser.uid !== props.user.uid) ||
          (props.user &&
            currentUser.uid !== props.user.uid &&
            props.currentUserGeneralRole === "owner") ? (
            <picture>
              <Image
                aria-hidden
                onClick={() => {
                  setOpenOptions(!openOption);
                  setTimeout(() => {
                    // setOpenOptions(false);
                  }, 5000);
                }}
                src={ThreeDotsIcon}
                className="cursor-pointer"
              />
            </picture>
          ) : (
            ""
          )}
          {(props.user &&
            props.user.generalRole !== "owner" &&
            props.currentUserGeneralRole === "admin" &&
            currentUser.uid !== props.user.uid) ||
          (props.user &&
            currentUser.uid !== props.user.uid &&
            props.currentUserGeneralRole === "owner")
            ? openOption && (
                <ul className="gather__members--modal-user-options position-absolute">
                  <li
                    key="gatherOptionAdmins"
                    className="cursor-pointer"
                    aria-hidden
                    onClick={async () => {
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${props.user!.uid}_${props.gatherSlug}`,
                        { generalRole: isAdmin ? "member" : "admin" }
                      );

                      setIsAdmin(!isAdmin);
                      setOpenOptions(false);
                    }}
                  >
                    {!isAdmin ? "Make admin" : "Remove admin rights"}
                  </li>
                  <li
                    key="gatherOptionBanned"
                    aria-hidden
                    onClick={async () => {
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${props.user!.uid}_${props.gatherSlug}`,
                        {
                          status: "banned",
                          generalRole: "banned",
                        }
                      ).then(() => {
                        props.setUpdateParticipant((prev) => prev + 1);
                        setOpenOptions(false);
                      });
                    }}
                    className="text-danger cursor-pointer"
                  >
                    Ban from gathering
                  </li>
                  <li
                    key="gatherOptionRemove"
                    aria-hidden
                    onClick={async () => {
                      if (!props.user!.uid) return false;
                      await QueryDB.deleteDoc(
                        "userGatherJunction",
                        `${props.user!.uid}_${props.gatherSlug}`
                      ).then(() => {
                        props.setUpdateParticipant((prev) => prev + 1);
                      });

                      setOpenOptions(false);
                    }}
                    className="text-danger cursor-pointer"
                  >
                    Remove from gathering
                  </li>
                  {props.user?.generalRole !== "owner" &&
                    props.currentUserGeneralRole === "owner"}
                  <li
                    key="gatherOptionMakeOwner"
                    className="cursor-pointer"
                    aria-hidden
                    onClick={async () => {
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${props.user!.uid}_${props.gatherSlug}`,
                        { generalRole: "owner" }
                      );
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${props.user!.uid}_${props.gatherSlug}`,
                        { generalRole: "member" }
                      );

                      setIsAdmin(!isAdmin);
                      setOpenOptions(false);
                      if (props.currentUserGeneralRole === "owner") {
                        createToast({
                          type: "success",
                          message: (
                            <>
                              <p className="mb-0">
                                {props.user!.userName
                                  ? `Made ${props.user!.userName} ${
                                      props.user!.generalRole
                                    }!`
                                  : `Made user ${props.user!.generalRole}!`}
                              </p>
                              <Button
                                variant="primary"
                                onClick={async () => {
                                  await QueryDB.updateDoc(
                                    "userGatherJunction",
                                    `${props.user!.uid}_${props.gatherSlug}`,
                                    { generalRole: "owner" }
                                  );
                                  await QueryDB.updateDoc(
                                    "userGatherJunction",
                                    `${props.user!.uid}_${props.gatherSlug}`,
                                    { generalRole: "member" }
                                  ).then(() => {
                                    props.setUpdateParticipant(
                                      (prev) => prev + 1
                                    );
                                  });
                                }}
                                className="d-flex flex-column m-auto"
                              >
                                <p className="mb-0">Undo</p>
                              </Button>
                            </>
                          ),
                        });
                      }
                      setTimeout(() => {
                        props.setUpdateParticipant((prev) => prev + 1);
                      }, 2000);
                    }}
                  >
                    Make owner of gather
                  </li>
                </ul>
              )
            : ""}
        </div>
      </div>
    </Col>
  );
};
export default InterestedParticipatingUserCard;
