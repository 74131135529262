const calculateTimeLeft = (endDate) => {
    const endDateInMilliseconds = endDate.seconds * 1000;
    const nowInMilliseconds = Date.now();

    const differenceInMilliseconds = endDateInMilliseconds - nowInMilliseconds;

    if (differenceInMilliseconds < 0) {
        return null;
    }
    
    const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};

export default calculateTimeLeft;