// import { singleChallengeAltProps } from "@/features/challenge/utils/singleChallengeProps";
import React, { FC } from "react";
import treasureHuntBackground from "../assets/treasure-hunt.jpg";
import { brand } from "../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import paper from "/assets/ChallengeHeadPaperRip.png";
import { BestgamerEventHead } from "../../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventHead/BestgamerEventHead";

export const BestgamerTreasureHuntHead: FC<{isMobile: boolean}> = ({isMobile}) => {
  const startDate = "2025-07-28T12:34:56Z"; // START DATE
  let dateObj = new Date(startDate);
  let DateToS = dateObj.getTime() / 1000;

  const pageTitle = "Treasure Hunt";
  const city = "Rødovre";

  return (
    <div className="head">
      <div className="head__treasurehunt-image">
        <div>
          <img src={treasureHuntBackground} alt="" />
        </div>
      </div>

      {isMobile ? (
        <div className="activity activity--treasure-hunt">
          <div className="activity__head">
            <div className="activity__title">
              <h1>
                {pageTitle}
              </h1>
              <div className="countdown__heading">
                <h2>
                  {city}              
                </h2>
              </div>
              <div className="activity__separator">
                <div className="activity__paper">
                  <img src={paper} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BestgamerEventHead
          title={pageTitle}
          startDate={{
            seconds: Date.now(),
            nanoseconds: 0,
          }}
          endDate={{
            seconds: DateToS,
            nanoseconds: 0,
          }}
          brand={brand.brandValue}
        />
      )}
    </div>
  );
};
