import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Profile } from "@/models/database/profiles/profile.model";
import BaseUserAvatar from "@/components/BaseUserAvatar";

const NavigationBarAvatar: React.FunctionComponent<{
  styleProps: string;
  isPlaceholder?: boolean;
  closeWindow?: () => void;
  profile: Profile;
}> = ({ profile, closeWindow, styleProps, isPlaceholder }) => {
  return (
    <Link to={`/profile`} className="header__user" onClick={closeWindow}>
      <div className="user">
        <span className="user__info">
          <p className="user__nickname">{profile?.userName}</p>
          <p className="user__fullname">{profile?.fullName}</p>
        </span>

        <BaseUserAvatar
          userName={profile?.userName}
          logoUrl={profile.profileLogoUrl}
        />
      </div>
    </Link>
  );
};

export default memo(NavigationBarAvatar);
