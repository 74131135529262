import { Badge, Button, Image } from "@/components";
import { Profile } from "@/models/database/profiles/profile.model";
import { FC } from "react";
import i18next from "i18next";

export const ThemePreviewCard: FC<{
  theme: string;
  themeObj: any;
  currentUser: Profile;
  handleThemeChange: any;
}> = ({ theme, themeObj, currentUser, handleThemeChange }) => {
  // Variables
  const isActive      = theme === themeObj.value;
  const currentTheme  = themeObj.value;
  const themeName     = themeObj.creator;
  const themeLogo     = themeObj.logo;


  return (
    <div className={`switcher ${isActive ? "switcher--active" : "switcher--inactive"}`}>
      <div className="switcher__body">
        <div className="switcher__image">
          <div className={`switcher__decorative switcher__decorative--${currentTheme}`} />
          <div className="logo">
            <div className="logo__image">
              <Image alt="" imageSrc={themeLogo} />
            </div>
          </div>
        </div>
        <div className="switcher__heading">
          {themeName}
        </div>
      </div>
      <div className="switcher__actions">
        {isActive &&
          <Badge variant="primary" customStyles={`bg-${currentTheme}`}>
            Applied
          </Badge>
        }
        {!isActive &&
          <Badge
            variant="secondary"
            onClickAction={() => handleThemeChange(themeObj.value)}
          >
            {i18next.t("buttons.apply_theme")}
          </Badge>
        }
      </div>
    </div>
  );
};
