/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { FC, useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { ReduxTypes } from "@type/ReduxTypes/ReduxTypes";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { SingleGatherOverviewTypes } from "@type/GathersTypes/GathersTypes";
// import EditGatherForm from "../../forms/forms-components/gathers/EditGatherForm";
// import { AppDispatch } from "../../store/store";
// import { addViewedTeam } from "../../store/teams/action";

type Props = {
  slug: string;
};
const SingleGatherSettings: FC<Props> = ({ slug }): JSX.Element => {
  // const viewedGathers = useSelector<ReduxTypes, any>(
  //   (state) => state.viewedGathers.viewedGathers
  // );
  const navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<
    "info" | "rights" | "notifications"
  >("info");

  const gather = undefined as {
    generalInfo: any;
  };

  // if (!gather) {
  //   navigate(`/gathers/${slug}`);
  // }

  // useEffect(() => {
  //   dispatch(
  //     addViewedTeam({
  //       teamSlug: slug,
  //     })
  //   );
  // }, [updateTrigger]);

  return (
    <div className="gather-settings">
      <Row>
        <h2>Gather&apos;s settings</h2>
        <Col xs={12} xl={12}>
          <ul className=" nav nav-tab">
            <li
              key="gatherSettingTabInfo"
              aria-hidden
              onClick={() => setSelectedTab("info")}
              className={` nav-link ${selectedTab === "info" && "active"}`}
            >
              Gather&apos;s info
            </li>
            {/* <li
            aria-hidden
            onClick={() => setSelectedTab("rights")}
            className={` nav-link ${selectedTab === "rights" && "active"}`}
          >
            Rights and permissions
          </li> */}
            {/* <li
            aria-hidden
            onClick={() => setSelectedTab("notifications")}
            className={`nav-link ${
              selectedTab === "notifications" && "active"
            }`}
          >
            My notifications
          </li> */}
          </ul>
          {gather && (
            <>
              {/* {selectedTab === "info" && (
                <EditGatherForm
                  name={gather.generalInfo.name}
                  type={gather.generalInfo.type}
                  meetType={gather.generalInfo.meetType}
                  description={gather.generalInfo.description}
                  meetTypeSecondary={
                    gather.generalInfo.meetTypeSecondary as
                      | "discord"
                      | "teams"
                      | "skype"
                      | "zoom"
                  }
                  meetUrlOrAddress={gather.generalInfo.meetUrlOrAddress || ""}
                  logoUrl={gather.generalInfo.logoUrl}
                  eventFrequencyType="year"
                  isAllDay={gather.generalInfo.isAllDay}
                  startDate={
                    new Date(gather.generalInfo.startDate!._seconds).getTime() *
                    1000
                  }
                  endDate={
                    new Date(gather.generalInfo.endDate!._seconds).getTime() *
                    1000
                  }
                  slug={gather.generalInfo.slug}
                  games={gather.generalInfo.games || []}
                  currentUserGeneralRole={
                    gather.generalInfo.currentUserGeneralRole
                  }
                />
              )} */}
              {/* {selectedTab === "rights" && (
              <TeamRightsPermissionsSettings
                type={
                  (team.type as "public" | "private") || team.generalInfo.type
                }
                postInFeed={team.generalInfo.postInFeed}
                canLeaveComments={team.generalInfo.canLeaveComments}
                canSendRequest={team.generalInfo.canSendRequest}
                teamSlug={team.generalInfo.slug}
                setUpdateTrigger={setUpdateTrigger}
              />
            )} */}
              {/* {selectedTab === "notifications" && <TeamNotificationsSettings />} */}
            </>
          )}
        </Col>
        <Col xs={6} xl={4}>
          <Row className="flex-column">
            <Col>
              {/* <div className="w-100 single-team-settings-card mt-3"> */}
              {/* <SingleTeamCard
                teamLogo={team?.generalInfo?.teamLogo as string}
                name={team?.generalInfo?.name}
                slug=""
                games={team?.generalInfo?.games || []}
                isPreview
                colSpan={0}
                type={team?.generalInfo?.type}
                userRole="owner"
                canLeaveComments=""
                canSendRequest=""
                created={{
                  nanoseconds: 0,
                  seconds: 0,
                }}
                createdBy=""
                currentMembersNumber={0}
                description=""
                isCurrUserFavorite={false}
                isCurrUserFollower
                isCurrUserMember
                playerOfTheMonth=""
                playerOfTheMonthCriteria=""
                postInFeed=""
                teamId=""
                inTeamRole=""
              /> */}
              {/* </div> */}
            </Col>
            {/* {selectedTab === "info" && (
            <Col xs={6} className="player-of-the-month-settings">
              <SingleTeamBestPlayer criteria="" title="" />
            </Col>
          )} */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SingleGatherSettings;
