import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { SideMenuLinks } from "./SideMenuLinks";
import SideMenuAvatar from "./SideMenuAvatar.mobile.tsx";
import { Button } from "@/components/index.ts";
import { siteRoutes } from "../../../routes/siteRoutesList.ts";
import i18next from "i18next";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store.ts";
import { handleLogout } from "@/utils/sharedHelperFunctions/index.ts";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const SideMenuMobileUi: React.FunctionComponent<
  any & { isAuth: boolean; closeWindow?: () => void }
> = ({ show, closeWindow, isAuth, profile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  const closeWindowCallback = useCallback(
    () => closeWindow(false),
    [closeWindow]
  );

  const isBestGamer = brand.brandValue === "BESTGAMER";

  return (
    <div className={`offcanvas ${show ? "show" : ""}`}>
      <div className="offcanvas__header">
        {isAuth ? (
          <SideMenuAvatar
            profile={profile}
            closeWindow={() => closeWindowCallback()}
            styleProps="offcanvas__avatar"
          />
        ) : (
          ""
        )}
        <Button variant="link" onClickAction={() => closeWindowCallback()}>
          <CloseIcon width={isBestGamer ? 24 : 30} height={isBestGamer ? 24 : 30} />
        </Button>
      </div>
      <div className="offcanvas__body">
        <SideMenuLinks
          currentViewName={location.pathname.slice(1)}
          closeWindow={() => closeWindowCallback()}
        />
      </div>
      <div className="offcanvas__footer">
        <div className="offcanvas__actions">
          <Button
            variant="link"
            customStyles="btn-logout"
            onClickAction={async () => {
              // Add any updates to SideMenu.ui.tsx
              closeWindowCallback();
              await handleLogout(setProfile);
              navigate(siteRoutes["landing"]);
            }}
          >
            {!isBestGamer && (<LogoutIcon height={18} />)}
            <span>{i18next.t("buttons.log_out")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
