export const games = [
    {
      name: "Call of Duty: Warzone",
      value: "wz",
    },
    {
      name: "League of Legends",
      value: "lol",
    },
    {
      name: "PUBG",
      value: "pubg",
    },
    {
      name: "Dota2",
      value: "dota2",
    },
    {
      name: "Valorant",
      value: "valorant",
    },
    {
      name: "Apex Legends",
      value: "apex",
    },
    {
      name: "Minecraft",
      value: "minecraft",
    },
    {
      name: "Fortnite",
      value: "fortnite",
    },
    {
      name: "Rocket League",
      value: "rocketleague",
    },
    {
      name: "Roblox",
      value: "roblox",
    },
    {
      name: "Counter-Strike 2",
      value: "cs2"
    },
    {
      name: "Palworld",
      value: "palworld"
    },
    {
      name: "Grand Theft Auto V",
      value: "gtav"
    },
  ];