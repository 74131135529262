import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Spinner } from "@/components";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useNavigate } from "react-router-dom";
import { createQuickAccountUser } from "@/utils/sharedHelperFunctions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

interface LandingHeaderProps {
  Ref: React.RefObject<HTMLElement>;
}

const LandingHeader: React.FC<LandingHeaderProps> = ({ Ref }) => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(false);
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  const handleLogInAnonymously = async () => {
    isLoading(true);
    await createQuickAccountUser(setProfile).finally(async () => {
      navigate(siteRoutes["profile"]);
    });
    isLoading(false);
  };

  return (
    <header className="landing__header" ref={Ref}>
      <Image
        imageSrc={brand.logos.large}
        alt={brand.logos.alt_text}
        customStyles="landing__logo"
      />
      <nav className="landing__nav">
        <Button
          variant="secondary"
          onClickAction={async () => {
            if (!loading) {
              await handleLogInAnonymously();
            }
          }}
        >
          {loading ? <Spinner /> : "Quick account"}
        </Button>

        <Button variant="secondary" onClickAction={() => navigate("/login")}>
          Login
        </Button>
      </nav>
    </header>
  );
};

export default LandingHeader;
