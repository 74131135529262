import { FC } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createToast } from "@/utils/toaster/createToast";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { Games } from "@/models/database/games/games.model";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { SubmitResultsFormProps } from "./SubmitScore.fetcher";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ImageUpload } from "@/components/ImageUpload/ImageUpload";
import icon_upload from "../../../../assets/actions/cloud-arrow.svg";
import { Button, Image, Spinner } from "@/components";
import firebase from "firebase/app";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const SubmitScoreForm: FC<{
  challengeData: singleChallengeProps;
  gameData: Games;
  submitData: SubmitResultsFormProps;
  setFieldValue: (targetName: string, value: any) => Promise<void>;
}> = ({ challengeData, gameData, submitData, setFieldValue }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [submitting, isSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const [timeError, setTimeError] = useState<boolean>(false);
  // const [setCurrentGame] = useState<string>("");

  const timeToMiliSeconds = (value: any) => {
    if (
      challengeData.challengeData.game !== "Forza Horizon 5" &&
      challengeData.challengeData.game !== "F1 2022" &&
      challengeData.challengeData.game !== "Turbo Golf Racing"
    ) {
      return Number(value);
    }

    const minutes = Number(value.replaceAll(":", "").slice(0, 2)) * 60000;
    const seconds = Number(value.replaceAll(":", "").slice(2, 4)) * 1000;
    const miliSeconds = Number(value.replaceAll(":", "").slice(4, 7));
    const endValue = minutes + seconds + miliSeconds;

    return endValue;
  };

  const handleSubmit = async () => {
    if (
      !currentUser.uid ||
      !challengeData.challengeData.slug ||
      !challengeData.challengeData.name
    ) {
      return;
    }

    if (
      challengeData.challengeData.game === "Forza Horizon 5" &&
      (submitData.value as any).replaceAll(":", "").slice(0, 7).length < 7
    ) {
      setTimeError(true);
      setTimeout(() => {
        setTimeError(false);
      }, 4000);

      return;
    }

    if (!submitData.image && !submitData.value) {
      createToast({
        type: "error",
        message: "Please enter your score and upload a picture as proof.",
      });
      return;
    }

    if (!submitData.image) {
      createToast({
        type: "error",
        message: "Picture of the score is required.",
      });
      return;
    }

    if (!submitData.value) {
      createToast({
        type: "error",
        message: "Please enter your score as shown on the image.",
      });
      return;
    }

    isSubmitting(true);

    const document = firebase
      .firestore()
      .collection(`${brand.event.collectionForSubmissions}`)
      .doc(`${brand.event.documentForSubmissions}`)
      .collection("submissions")
      .doc();
    const data = {
      username: currentUser.userName,
      submissionImageUrl: submitData.image || "",
      challengeName: challengeData.challengeData.name,
      game: challengeData.challengeData.game,
      submissionDetails: {
        points: timeToMiliSeconds(submitData.value),
        type: gameData.submitScoreType,
      },
      profileLogoUrl: currentUser.profileLogoUrl,
      status: "pending",
      uid: currentUser.uid,
      challengeId: challengeData.challengeData.slug || "",
    };

    await document
      .set(
        {
          submissionId: document.id,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          ...data,
        },
        { merge: true }
      )
      .then(() => {
        createToast({
          type: "success",
          message: "Score submitted and awaiting check from admin",
        });
        navigate(siteRoutes["challenges"]);
        isSubmitting(false);
      })
      .catch(() => {
        createToast({
          type: "error",
          message:
            "Something went wrong trying to submit results, please try again or go to a admin for support",
        });
        isSubmitting(false);
      });
  };

  return (
    <>
      <div className="submitForm">
        <h1>Submit results form</h1>
        <h3>
          <span className="fw-7">Challenge:</span>{" "}
          {challengeData.challengeData.name || ""}
        </h3>

        <form
          onSubmit={async (e) => {
            e.preventDefault();
          }}
        >
          <div>
            <BaseTextInput
              label={
                gameData.submitScoreType && gameData.submitScoreType === "score"
                  ? "score"
                  : "time"
              }
              textType="text"
              value={submitData.value}
              placeholder={
                gameData.submitScoreType && gameData.submitScoreType === "score"
                  ? "0"
                  : "00:00:000"
              }
              setObjectState={setFieldValue}
              setObjectStateTargetName="value"
              error={undefined}
            />
          </div>

          <div>
            <label htmlFor="">
              {" "}
              <h3>Image confirmation</h3>
            </label>
            {submitData.image ? (
              <div>
                <figure className="adaptive">
                  <picture className="adaptive-photo">
                    <Image alt="" imageSrc={submitData.image} />
                  </picture>
                </figure>
              </div>
            ) : (
              <div className="create-team__placeholder">
                <picture className="adaptive">
                  <figure className="adaptive-photo">
                    <ImageUpload
                      acceptType="image/*"
                      setIsLoading={() => {}}
                      setFieldValue={setFieldValue}
                      setType={() => {}}
                      value={submitData.image}
                      name="image"
                    >
                      <Image alt="" imageSrc={icon_upload} />
                    </ImageUpload>
                  </figure>
                </picture>
              </div>
            )}
          </div>
        </form>
        <div className="submitForm__buttons">
          <Button
            variant="primary"
            type="submit"
            onClickAction={async () => {
              await handleSubmit();
            }}
            disabled={submitting}
          >
            {submitting ? <Spinner /> : "Submit results"}
          </Button>
        </div>
      </div>
    </>
  );
};
