import { collectionRef } from "@/api/collectionRef/collectionOperations";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { FC, useEffect, useState } from "react";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { useLocation, useParams } from "react-router-dom";
import { ErrorFallback, Spinner } from "@/components";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Games } from "@/models/database/games/games.model";
import { SubmitScoreForm } from "./SubmitScore.ui";
import { Query_shared } from "@/api/shared";
import { PageDoesntExist } from "@/pageDoesntExist";

export type SubmitResultsFormProps = {
  value: string;
  image: any;
};

export const SubmitScore: FC<{}> = ({}) => {
  const location = useLocation();
  const { slug } = useParams();

  const currentUser = useCurrentUserProfile((s) => s.user);
  const [gameData, setGameData] = useState<Games>();
  const isTimeBasedChallenge =
    gameData?.submitScoreType && gameData?.submitScoreType === "score"
      ? false
      : true;

  const initialValue: SubmitResultsFormProps = {
    value: "",
    image: undefined,
  };
  const [submitData, setSubmitData] =
    useState<SubmitResultsFormProps>(initialValue);

  const [data, loading, error, fetch_challenge] =
    useCloudFunctionCall<singleChallengeProps>("getSingleChallenge", slug);

  const userCameFromChallenge = location.state?.orgSlug === slug;
  const isCurrentUserBanned = data?.currUserProfile?.status === "banned";
  const isCurrUserParticiant =
    data?.isCurrUserParticipant && data?.currUserProfile?.status === "accepted";
  const getGameInfo = async () => {
    const games = collectionRef.games;
    const gamesRes = await games
      .where("value", "==", data.challengeData.gameType)
      .limit(1)
      .get();
    if (gamesRes && gamesRes.docs[0] && gamesRes.docs[0].data()) {
      return setGameData(gamesRes.docs[0].data());
    }
    return false;
  };

  const getChallengeInfo = async () => {
    await fetch_challenge({
      challengeId: encodeURIComponent(decodeURIComponent(slug!)),
      currUserId: currentUser.uid!,
    });
  };

  const formatTime = (
    isTimeFormat: boolean,
    value: string,
    isNormalInput: boolean
  ) => {
    let formatedValue = (value as any).replaceAll(":", "");

    if (isNormalInput) {
      return value;
    }

    if (!Number(formatedValue) && Number(formatedValue) !== 0) {
      return "";
    }

    if (isTimeFormat && value && value.length === 4 && !value.includes(":")) {
      formatedValue = `${value.slice(0, 2)}:${value.slice(2)}`;
      return formatedValue;
    }
    if (formatedValue.length > 6 && isTimeFormat) {
      if (formatedValue.length < 7 && isTimeFormat) {
        return value.slice(0, 9);
      }
      let minutes = formatedValue.slice(0, 2);
      let seconds = formatedValue.slice(2, 4);
      const miliSeconds = formatedValue.slice(4, 7);
      if (Number(minutes) > 59) {
        minutes = "59";
      }
      if (Number(seconds) > 59) {
        seconds = "59";
      }
      formatedValue = `${minutes}:${seconds}:${miliSeconds}`;
      return formatedValue;
    }
    return value;
  };

  useEffect(() => {
    const fetch = async () => {
      await getChallengeInfo();
      if (data) {
        await getGameInfo();
      }
    };
    if (slug) {
      fetch();
    }
  }, []);

  const setFieldValue = async (targetName: string, value: any) => {
    let val = value;
    if (targetName === "image" && value?.name) {
      const firebaseFileURL = await Query_shared.uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(value),
        fileName: `${value?.name}_${Date.now()}`,
      });
      val = firebaseFileURL;
    }

    if (targetName === "value") {
      val = formatTime(isTimeBasedChallenge, value, !isTimeBasedChallenge);
    }
    return setSubmitData((prev: any) => ({
      ...prev,
      [targetName]: val,
    }));
  };

  return (
    <>
      {data && isCurrentUserBanned && (
        <ErrorFallback>
          You have been banned from this challenge and cannot submit a score
        </ErrorFallback>
      )}
      {data && !userCameFromChallenge && <PageDoesntExist />}
      {data && !isCurrUserParticiant && (
        <ErrorFallback>
          You are not a participant in this challenge
        </ErrorFallback>
      )}
      {data && data?.challengeData.status !== "ongoing" && (
        <ErrorFallback>
          You can no longer submit scores in this challenge
        </ErrorFallback>
      )}

      {userCameFromChallenge &&
        !isCurrentUserBanned &&
        isCurrUserParticiant &&
        data?.challengeData.status === "ongoing" && (
          <>
            {!loading &&
            (!slug ||
              !gameData?.isManualSubmission ||
              !data?.isCurrUserParticipant) ? (
              <PageDoesntExist />
            ) : (
              ""
            )}

            {/* <h1> {JSON.stringify(submitData)}</h1> */}
            {gameData?.isManualSubmission &&
            data?.isCurrUserParticipant &&
            !loading &&
            data &&
            gameData &&
            !error ? (
              <SubmitScoreForm
                challengeData={data}
                gameData={gameData}
                submitData={submitData}
                setFieldValue={setFieldValue}
              />
            ) : (
              ""
            )}
            {loading && <Spinner />}
            {error && (
              <ErrorFallback>Failed to get challenge info</ErrorFallback>
            )}
          </>
        )}
    </>
  );
};
