import React, { memo } from "react";
import { Query_Imgix } from "../../../../api/imgix/index";
import { Post } from "@/models/database/posts/post.model";
import BaseVideoTagWrapper from "@/components/BaseWrappers/BaseVideoTagWrapper";
import { Image } from "@/components";
import { PostData } from "../../types/singlePostsProps";

const UserPost: React.FunctionComponent<{ data: PostData }> = ({ data }) => {
  const dataToRender = data.type.includes("shared")
    ? data.additionalData
    : data;
  try {
    return (
      <>
        {dataToRender &&
        (dataToRender.postPhoto ||
          dataToRender.postVideo ||
          dataToRender.postText) ? (
          <div className="post__media">
            {dataToRender.postPhoto ? (
              <Image
                alt=""
                imageSrc={
                  dataToRender.postPhoto.includes("wog.imgix") ||
                  dataToRender.postPhoto.includes("iplay.imgix")
                    ? `${dataToRender.postPhoto}?${Query_Imgix.getImgixUrlProps(
                        {
                          w: "836",
                        }
                      )}`
                    : dataToRender.postPhoto
                }
              />
            ) : (
              ""
            )}
            {dataToRender.postVideo ? (
              <BaseVideoTagWrapper videoSrc={dataToRender.postVideo} />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  } catch (error) {
    <em>Failed to load post, it might no longer be available.</em>;
  }
};

export default memo(UserPost);
