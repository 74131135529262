import gamepass from "../../../branchAndBrandSpecific/utils/brandVariables/assets/game-pass/gamepass.png";
import gamepass_sm from "../../../branchAndBrandSpecific/utils/brandVariables/assets/game-pass/gamepass_logo_sm.svg";
import legion from "../../../branchAndBrandSpecific/utils/brandVariables/assets/lenovo-legion/legion.svg";
import legion_sm from "../../../branchAndBrandSpecific/utils/brandVariables/assets/lenovo-legion/legion-sm.svg";
// import razer from "./assets/razer_sm.svg";
// import razer_sm from "./assets/razer_sm.svg";
// import psn from "./assets/psn.png";
// import psn_sm from "./assets/psn_sm.png";
import placeholder from "./assets/PlaceholderIcon.jpg";
import elgiganten from "../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten_sm.svg";
import quantum from "../../../branchAndBrandSpecific/utils/brandVariables/assets/jbl-quantum/quantum_logo.svg";
import tcl from "../../../branchAndBrandSpecific/utils/brandVariables/assets/tcl/tcl-logo.svg";
import Logitech from "../../../branchAndBrandSpecific/utils/brandVariables/assets/logitech/logitechg.svg";
import LogitechSmall from "../../../branchAndBrandSpecific/utils/brandVariables/assets/logitech/logitech-g-sm.svg";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import wog from "../../../branchAndBrandSpecific/utils/brandVariables/assets/wog-logo-new.svg"

const themes = [
  {
    value: "wog",
    label: "Wog",
    logo: wog,
    largeLogo: wog,
    creator: "World Of Gamers",
    placeholder,
  },
  // {
  //   value: "wog-light",
  //   label: "Wog-light",
  //   logo: brand.logos.small,
  //   largeLogo: brand.logos.large,
  //   creator: "Light",
  //   placeholder,
  // },
  {
    value: "jbl",
    label: "JBL",
    logo: quantum, // smaller logo for profile
    largeLogo: quantum,
    creator: "JBL! Quantum",
    placeholder,
  },
  // {
  //   value: "legion",
  //   label: "Legion",
  //   logo: legion_sm, // smaller logo for profile
  //   largeLogo: legion, // theme switcher should have different logo(larger)
  //   creator: "Lenovo Legion",
  //   placeholder,
  // },
  // {
  //   value: "psn",
  //   label: "Playstation",
  //   logo: psn_sm, // smaller logo for profile
  //   largeLogo: psn, // theme switcher should have different logo(larger)
  //   creator: "Playstation",
  //   placeholder,
  // },
  // {
  //   value: "razer",
  //   label: "Razer",
  //   logo: razer_sm, // smaller logo for profile
  //   largeLogo: razer_sm, // theme switcher should have different logo(larger)
  //   creator: "Razer",
  //   placeholder,
  // },
  // {
  //   value: "gamepass",
  //   label: "Game pass",
  //   logo: gamepass_sm, // smaller logo for profile
  //   largeLogo: gamepass, // theme switcher should have different logo(larger)
  //   creator: "Game Pass",
  //   placeholder,
  // },
  {
    value: "elgiganten",
    label: "Elgiganten",
    logo: elgiganten, // smaller logo for profile
    largeLogo: elgiganten, // theme switcher should have different logo(larger)
    creator: "Elgiganten",
    placeholder,
  },
  {
    value: "tcl",
    label: "TCL",
    logo: tcl, // smaller logo for profile
    largeLogo: tcl, // theme switcher should have different logo(larger)
    creator: "TCL",
    placeholder,
  },
  {
    value: "logitech",
    label: "Logitech",
    logo: LogitechSmall, // smaller logo for profile
    largeLogo: Logitech, // theme switcher should have different logo(larger)
    creator: "Logitech",
    placeholder,
  },
];

export default themes;
