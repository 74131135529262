import { auth } from "@/api/firebase/firebase.config";
import firebase from "firebase/app";

export const _setUserStatus = async (state: "online" | "offline") => {
  const dbRef = firebase.database().ref();
  const currentUserUid = auth.currentUser?.uid;
  await dbRef.update({
    [`status/${currentUserUid}/last_changed`]: Date.now(),
    [`status/${currentUserUid}/state`]: state,
  });
};
