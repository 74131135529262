import { auth } from "@/api/firebase/firebase.config";
import { Button, Spinner } from "@/components";
import CountrySelect from "@/components/CountrySelector/CountrySelect";
import countryCodes from "@/utils/constants/CountryCodes";
import { createToast } from "@/utils/toaster/createToast";
import { FC, useEffect, useState } from "react";
import firebase from "firebase/app";
import { useNavigate } from "react-router";
import { siteRoutes } from "../../../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const VerifyPhoneNumber: FC<{}> = ({}) => {
  const [phoneAuthValues, setPhoneAuthValues] = useState<{
    phoneNumber: string;
  }>({
    phoneNumber: auth.currentUser?.phoneNumber || "",
  });
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isSendCodeClicked, setIsSendCodeClicked] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const phoneNumberRegex = /^\d+$/;
  const isNumberValid = phoneNumberRegex.test(phoneAuthValues.phoneNumber);
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null);
  const [appVerifier, setAppVerifier] =
    useState<firebase.auth.ApplicationVerifier | null>(null);
  const setFieldValue = async (targetName: string, value: any) => {
    return setPhoneAuthValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Recaptcha verification
  useEffect(() => {
    if (!document.getElementById("sign-in-button")) {
      const signInButton = document.createElement("div");
      signInButton.id = "sign-in-button";
      document.body.appendChild(signInButton);
    }

    const verifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
    });
    setAppVerifier(verifier);
  }, []);

  // Check verification code
  useEffect(() => {
    if (verificationCode.length === 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [verificationCode]);

  const getCountryCodeFromPhoneNumber = (phoneNumber: string | undefined) => {
    let countryCode = "+46";
    if (phoneNumber) {
      countryCodes.some((code) => {
        if (code.dial_Code && phoneNumber.startsWith(code.dial_Code)) {
          countryCode = code.dial_Code;
          return true;
        }
        return false;
      });
    }
    return countryCode;
  };

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    getCountryCodeFromPhoneNumber(auth.currentUser?.phoneNumber || "+46")
  );
  const [loading, setLoading] = useState<boolean>(false);

  const startPhoneNumberAuthentication = async () => {
    setLoading(true);
    const phoneNumber = selectedCountryCode + phoneAuthValues.phoneNumber;

    if (!appVerifier || !auth.currentUser) {
      return;
    }

    try {
      const result = await auth.currentUser.linkWithPhoneNumber(
        phoneNumber,
        appVerifier
      );
      setConfirmationResult(result);
      createToast({
        type: "success",
        message:
          "Verification code has been sent to the provided mobile number",
      });
    } catch (error) {
      const message = (error as Error).message;
      createToast({
        type: "error",
        message,
      });
    }
    setIsSendCodeClicked(true);
    setLoading(false);
  };

  const completePhoneNumberAuthentication = async () => {
    if (!confirmationResult) {
      return;
    }

    try {
      const userCredential = await confirmationResult.confirm(verificationCode);

      if (userCredential.user) {
        createToast({
          type: "success",
          message: "Successfully added phone number to the existing account",
        });
        navigate(siteRoutes["profile"]);
      }
    } catch (error) {
      const message = (error as Error).message;
      createToast({
        type: "error",
        message,
      });
    }
  };

  return (
    <div>
      <label htmlFor="tel">
        <h5>
          Phone number{" "}
          {/* {!auth.currentUser?.phoneNumber && (
              <small>(Needed to claim Game Pass token)</small>
            )} */}
        </h5>
        <div>
          <div className="settings__phone">
            <input
              onChange={(e) => {
                if (e.target.value.startsWith(selectedCountryCode)) {
                  setFieldValue(
                    "phoneNumber",
                    e.target.value.slice(selectedCountryCode.length)
                  );
                } else {
                  setFieldValue("phoneNumber", e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "Backspace" &&
                  phoneAuthValues.phoneNumber === ""
                ) {
                  e.preventDefault(); // Prevent the default "Backspace" behavior

                  // Set the phoneVal to the selectedCountryCode
                  setFieldValue("phoneNumber", phoneAuthValues.phoneNumber);
                }
              }}
              type="tel"
              className="base-input"
              value={selectedCountryCode + phoneAuthValues.phoneNumber}
              placeholder="Phone number..."
            />
            <CountrySelect
              // authphoneCode={authPhoneCode}
              // countryCodes={countryCodes}
              onClick={(country: any) =>
                setSelectedCountryCode(country.dial_Code)
              }
            />
          </div>

          {isSendCodeClicked && (
            <div className="verify-code">
              <input
                type="text"
                className="base-input"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Verification code"
              />
            </div>
          )}
        </div>
      </label>
      <div className="settings__buttons">
        {isSendCodeClicked ? (
          <>
            <Button
              variant="primary"
              type="button"
              onClickAction={completePhoneNumberAuthentication}
              disabled={!isValid}
            >
              Verify
            </Button>
            <Button
              variant="secondary"
              onClickAction={startPhoneNumberAuthentication}
            >
              Send new code
            </Button>
          </>
        ) : (
          <Button
            variant={isNumberValid ? "primary" : "secondary"}
            disabled={!isNumberValid}
            // hidden={!isNumberValid}
            type="button"
            onClickAction={startPhoneNumberAuthentication}
          >
            {loading ? (
              <>
                <Spinner />
              </>
            ) : (
              "Send Code"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
