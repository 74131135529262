import React, { FC } from "react";
import { Button } from "../../../../src/components/Button/index";

export const BestgamerEventPartner: FC<{
  partner_logo: any;
  product_name: string;
  product_link: string;
  product_image: any;
  product_background: any;
}> = ({
  partner_logo,
  product_name,
  product_link,
  product_image,
  product_background,
}) => {
  return (
    <div className="bestgamer-event-partner">
      <div className="bestgamer-event-partner__meta">
        <div className="bestgamer-event-partner__logo bestgamer-event-partner__logo--placeholder">
          {partner_logo}
        </div>

        <div className="bestgamer-event-partner__content">
          <h2>Advanced gaming gear</h2>
          <div className="product-line">
            <div className="product-line__item">Gaming Mice</div>
            <div className="product-line__item">Keyboards</div>
            <div className="product-line__item">Headsets</div>
          </div>
          <h2>for the Ultimate Gaming</h2>
        </div>
      </div>

      <div className="bestgamer-event-partner__image">
        <div className="bestgamer-event-partner__background">
          <img src={product_background} alt="JBL QUANTUM 910" />
        </div>
        <div className="bestgamer-event-partner__product-image">
          <img src={product_image} alt="JBL QUANTUM 910" />
        </div>
      </div>

      <div className="bestgamer-event-partner__product">
        <h4>{product_name}</h4>
        <p>Award winning design and next level quality of gaming products!</p>
        <Button variant="primary" onClickAction={() => product_link}>
          See the product
        </Button>
      </div>
    </div>
  );
};
