import React, { useState, useEffect, FC } from "react";
import themes from "@/utils/themes/themes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { ReactComponent as FireInactive } from "../../../../../messages/assets/fire-inactive.svg";
import { ThemePreviewCard } from "./ThemePreviewCard";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import i18next from "i18next";
import { Button } from "@/components";

export const ThemeSwitcher: FC<{}> = ({}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [theme, setTheme] = useState(currentUser.theme || "wog");

  useEffect(() => {
    if (currentUser.uid) {
      const userTheme = currentUser.theme || "wog";
      document.documentElement.className = `theme-${userTheme}`;
      setTheme(userTheme);
    }
  }, [currentUser]);

  const handleThemeChange = async (themeValue: string) => {
    setTheme(themeValue);
    document.documentElement.className = `theme-${themeValue}`;
    await QueryDB.updateDoc("profiles", currentUser.uid, {
      theme: themeValue,
    }).then(() => {
      refreshUser(setUserProfile, currentUser);
      createToast({
        type: "success",
        message: "Theme updated",
      });
    });
  };

  return (
    <div className="theme">
      <div className="theme__alert">
          <p>{i18next.t(`profile.edit.themes.choose_msg`)}</p>
      </div>
      <div className="theme__grid">
        {themes.map((themeObj, i) => (
          <ThemePreviewCard
            key={i}
            theme={theme}
            themeObj={themeObj}
            currentUser={currentUser}
            handleThemeChange={handleThemeChange}
          />
        ))}
      </div>
    </div>
  );
};
