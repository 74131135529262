import { functions } from "@/api/firebase/firebase.config";
import {
  useGeneralStoreForCloudFunctionsForLists as _useGeneralStoreForCloudFunctionsForLists,
  useGeneralStoreForCloudFunctionsForListsPersisted,
  useGeneralStoreForLeaderboards,
} from "@/store/database/database.store";
import { useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

type Error = {
  message: string;
};

const useStoreHook = {
  true: useGeneralStoreForCloudFunctionsForListsPersisted,
  false: _useGeneralStoreForCloudFunctionsForLists,
};
/**
 * Usage: When you have to call a cloud function
 * @param cloud_function_name Name of the cloud function you are going to call
 * @returns error, loading state, data and fetch function, ref, _hasMore
 */
export default function useCloudFunctionCallForListsAndInfiniteScroll<
  T,
  PropTypes = any | undefined,
>(
  cloud_function_name: string,
  slug: string = "", // slug of profile -> could be own slug or other user slug
  filterKey: string, // inital filter value -> removed to avoid complication
  persist?: boolean,
  atomLocation?: string
) {
  const rawKey = `${cloud_function_name}_${slug}`;
  const [filter, setFilterKey] = useState<string>(() => filterKey);
  const key = `${rawKey}_${filter}`;

  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  const useGeneralStoreForCloudFunctionsForLists = useMemo(() => {
    const persistKey = String(!!persist) as "true" | "false";
    if (cloud_function_name === "getLeaderBoardForChallengeId") {
      return useGeneralStoreForLeaderboards;
    }
    return useStoreHook[persistKey];
  }, [persist]);

  const data = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.data
  )[key] as T | undefined;

  const hasMore = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.hasMore
  )[key] as boolean;

  const lastFeedDateInSeconds = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.lastFeedDateInSeconds
  )[key] as number | undefined;

  const dispatchData = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.dispatchData
  );

  const dispatchSingleData = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.dispatchSingleData
  );

  const updateHasMore = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setHasMore
  );

  const updateLastVisibleDate = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setLastFeedDateInSeconds
  );

  const functionQuery = useMemo(
    () => functions(cloud_function_name),
    [cloud_function_name]
  );

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  /**
   *
   * @param params Ensure that all the necessary properties required by the cloud function are passed as arguments or parameters.
   * @param merge If set to 'true', the new upcoming data from the cloud function will be merged with the existing data. Use 'merge: true' in scenarios involving infinite scrolling, where you need to retain the previous data and incorporate the new data seamlessly.
   */
  // let fetchTries = 0;

  async function fetch(params: PropTypes, filterKey: string, merge?: boolean) {
    if (hasMore === false && !!merge) {
      return;
    }
    setError(undefined);
    setLoading(true);

    try {
      //updating filter key with new props
      setFilterKey(filterKey);

      const dataKey = `${rawKey}_${filterKey}`;

      const response = await functionQuery(params || {});
      const _data = response.data;

      if (_data) {
        if (atomLocation) {
        } else {
          dispatchData(_data.dataArray, dataKey, merge);
        }
        // set has more
        updateHasMore(dataKey, _data.hasMore);

        //last visible item number
        updateLastVisibleDate(dataKey, _data.lastFeedDateInSeconds);
      } else {
      }
    } catch (error: any) {
      setLoading(false);
      setError({
        message: error,
      });
    }

    setLoading(false);
  }

  function setSingleDocument(data: any, filterKey: string) {
    const dataKey = `${rawKey}_${filterKey}`;
    dispatchSingleData(data, dataKey, true);
  }
  return [
    data,
    lastFeedDateInSeconds,
    hasMore,
    loading,
    error,
    fetch,
    ref,
    inView,
    setSingleDocument,
  ] as const;
}
