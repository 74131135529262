import React, { useState, FC, useEffect } from "react";
import { Link } from "react-router-dom";
import doesStringContainsOnlyEmoji from "@/features/profile/helperFunctions/doesStringContainsOnlyEmoji";
import { Button } from "@/components";
import { SinglePostContentType } from "./SinglePostContentType";
import { PostData } from "../../types/singlePostsProps";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SinglePostAction } from "./SinglePostActions";
import { SinglePostInfo } from "./SinglePostInfo";
import EditPostForm from "./SinglePostEditForm";
import { SinglePostComments } from "./SinglePostComments.fetcher";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";

import { Query_shared } from "@/api/shared";
import { getAuthorProfileBasedOnUserName } from "@/api/search/searchUsernameByName";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
const textMapping: {
  [key: string]: string;
} = {
  userPost: "",
  sharedPost: "shared a post",
  sharedChallenge: "shared a challenge",
  sharedGather: "shared a gather",
  sharedTeam: "shared a team",
  sharedNews: "shared a news",
};

export const SinglePostComponent: FC<{
  isFullPagePost?: boolean;
  actionButtons?: boolean;
  postData: PostData;
  isInModal: boolean;
  isFriendsOfCurrentUser: boolean;
  likeParentId: string;
  createCommentEnabled?: boolean;
  isAuthorized?: boolean;
}> = ({
  isFullPagePost,
  actionButtons,
  postData,
  isInModal,
  isFriendsOfCurrentUser,
  likeParentId,
  createCommentEnabled,
  isAuthorized,
}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [showMorePost, setShowMorePost] = useState(false);
  const [totalLikesNumberState, setTotalLikesNumberState] = useState(
    postData.totalLikesNumber || 0
  );
  const [postContentMessage, setPostContentMessage] = useState<string>(
    postData.postText || ""
  );
  const [showComments, setShowComments] = useState(false);
  const [isEditPost, setIsEditPost] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const numberOfLineBreaks = (postData.postText?.match(/\n/g) || []).length;
  const doesPostTextIsEmojiOnly = !!doesStringContainsOnlyEmoji(
    postData.postText
  );

  const [formattedPostContent, setFormattedPostContent] = useState<
    React.ReactNode[] | null
  >(null);

  const highlightAtMentions = async (
    text: string | null
  ): Promise<React.ReactNode[] | null> => {
    if (!text) return null;
    const words = text.split(" ");

    const mentions = await Promise.all(
      words.map(async (word: string, index) => {
        const key = `${word}_${index}`;

        // Check if word starts with @ and has at least 1 other character after it to
        if (word.startsWith("@") && word.length > 1) {
          const response = await getAuthorProfileBasedOnUserName(
            word.replace("@", ""),
            brand.brandValue
          );

          if (response[0]) {
            const isCurrUser = response[0].slug === currentUser.slug;
            const link = isCurrUser
              ? "/profile"
              : `/profile/${response[0].slug}`;

            return (
              <Link to={link} key={`${word}`}>
                <span className="post-highlight__tag">{word} </span>
              </Link>
            );
          }
          return <React.Fragment key={key}>{word} </React.Fragment>;
        }

        return <React.Fragment key={key}>{word} </React.Fragment>;
      })
    );
    setFormattedPostContent(mentions);
  };

  useEffect(() => {
    highlightAtMentions(postContentMessage);
  }, [postContentMessage]);

  return (
    <>
      {!isDeleted && (
        <>
          {shareModalOpen && (
            <ShareContentModalWindow
              show={shareModalOpen}
              closeWindow={() => setShareModalOpen(false)}
              sharedFrom={"post"}
              createType="share"
              // NEW
              postData={postData}
            />
          )}

          <div className={`post ${showComments ? "post--open" : ""}`}>
            {/* Refactored */}
            <SinglePostInfo
              authorUsername={postData.authorUsername}
              authorLogo={postData?.authorLogo}
              authorSlug={postData.authorSlug}
              type={postData.type || ""}
              whenWasPosted={{
                stringDate: new Date(
                  postData.created?._seconds * 1000
                ).toDateString(),
                howLongAgo: Query_shared.getWhenWasPosted(
                  postData.created?._seconds * 1000
                ),
              }}
              wasEdited={postData.wasEdited}
              setIsDeleted={setIsDeleted}
              setIsEditPost={setIsEditPost}
              isAuthorized={isAuthorized}
              postId={postData.id}
              currUserSlug={currentUser.slug}
              postData={postData}
              isInModal={isInModal}
            />
            {isEditPost ? (
              <EditPostForm
                originalPostText={postContentMessage || ""}
                postId={postData.id}
                setIsEditPost={setIsEditPost}
                setPostContentMessage={setPostContentMessage}
              />
            ) : (
              <div
                className={`post__content ${
                  doesPostTextIsEmojiOnly ? "large" : ""
                } ${showMorePost || numberOfLineBreaks > 3 ? "" : "more"}`}
              >
                {!postContentMessage ? "" : <p>{formattedPostContent}</p>}
                {postData.postText &&
                  (postData.postText.length > 80 || numberOfLineBreaks > 3) && (
                    // <Button
                    //   variant="link"
                    //   onClickAction={() => setShowMorePost((prev) => !prev)}
                    // >
                    //   {showMorePost ? "Show less" : "Show more"}
                    // </Button>
                    <></>
                  )}
              </div>
            )}
            {postData.type && (
              <SinglePostContentType
                type={postData.type}
                postPhoto={postData.postPhoto}
                postVideo={postData.postVideo}
                data={postData}
              />
            )}

            {actionButtons && (
              <SinglePostAction
                isEditPost={isEditPost}
                hasUserLiked={postData.hasCurrentUserLikedThisPost}
                totalCommentsNumber={postData.totalCommentsNumber}
                totalSharesNumber={postData.totalSharesNumber}
                setShowComments={setShowComments}
                setShareModalOpen={setShareModalOpen}
                setTotalLikesNumberState={setTotalLikesNumberState}
                totalLikesNumber={totalLikesNumberState}
                postId={postData.id}
                isFriendsOfCurrentUser={isFriendsOfCurrentUser}
                likeParentId={likeParentId}
              />
            )}
          </div>

          {showComments && (
            <SinglePostComments
              showComments={showComments}
              postId={postData.id as string}
              postAuthorUid={""}
              postDoesNotHaveComments={!postData.totalCommentsNumber}
              createCommentEnabled={createCommentEnabled}
              isAuthorized={isAuthorized}
            />
          )}
          {/* {showComments && <Spinner />} */}
        </>
      )}
    </>
  );
};
