import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useNavigate, useParams } from "react-router-dom";
import ProfileAndCommunity from "../components/ProfileAndCommunity.fetcher";
import ProfileFeed from "../components/ProfileFeed.fetcher";
import { useEffect, useState } from "react";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export default function ProfilePage({
  belongToCurrentUser,
}: {
  belongToCurrentUser?: boolean;
}) {
  const { slug } = useParams();
  const currentUserProfile = useCurrentUserProfile((s) => s.user);
  const currentUserSlug = currentUserProfile?.slug;
  const navigate = useNavigate();
  const [isFriendsOfCurrentUser, setIsFriendsOfCurrentUser] =
    useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (slug && slug === currentUserSlug) {
      navigate(siteRoutes["profile"]);
    }
  }, [slug, currentUserSlug]);

  if (!currentUserSlug) {
    //reload the window if there's no current user stored
    window.location.reload();
    return <></>;
  }
  // define the slug
  const slugToPass = slug || currentUserSlug;
  const _belongToCurrentUser =
    !!belongToCurrentUser || slug === currentUserSlug;
  const hasFeedFeature = brand.hasFeature.feed;
  return (
    <>
      <ProfileAndCommunity
        slug={slugToPass}
        setIsFriendsOfCurrentUser={setIsFriendsOfCurrentUser}
      >
        {hasFeedFeature && (
          <ProfileFeed
            isFriendsOfCurrentUser={isFriendsOfCurrentUser}
            slug={slugToPass}
            belongToCurrentUser={_belongToCurrentUser}
            currentUserProfile={currentUserProfile}
          />
        )}
      </ProfileAndCommunity>
    </>
  );
}
