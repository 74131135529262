import React, { useState, useCallback, FC, useEffect } from "react";
import { ReactComponent as PostIcon } from "../../assets/arrow-up.svg";
import EmojiPicker from "emoji-picker-react";
import icon_emoji from "../../../../assets/actions/emoji.svg";
import { Query_Imgix } from "./../../../../api/imgix/index";
import QueryDB_Notifications from "./../../../../api/queryDB/notifications/index";
import { ReactComponent as CameraIcon } from "../../../../assets/icons/camera.svg";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { Button, Spinner } from "@/components";
import EmojiWrapper from "@/components/EmojiWrapper/EmojiWrapper";
import ImagePreview from "@/components/ImagePreview/ImagePreview";
import { ImageUpload } from "@/components/ImageUpload/ImageUpload";
import { Profile } from "@/models/database/profiles/profile.model";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { timestamp } from "@/api/firebase/firebase.config";
import { v4 as uuid } from "uuid";
import { BaseTextInputWithMentions } from "@/components/BaseTextInput/BaseTextInputWithMentions";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
export type CreatePostInitialValuesProps = {
  postText: string;
  postMedia?: File;
};

const initialValues: CreatePostInitialValuesProps = {
  postText: "",
  postMedia: null,
};

export const CreatePostForm: FC<{
  postedIn: "feed" | "team" | "news";
  currentUser: Profile;
  communityData: GetUserCommunityBasedOnSlug;
  setFeedUpdateTrigger: any;
  disabledInput?: boolean;
  setSinglePost?: (data: any) => void;
  parentId?: string;
  isPreview?: boolean;
  inputPlaceholder?: string;
}> = ({
  postedIn,
  currentUser,
  communityData,
  setFeedUpdateTrigger,
  disabledInput,
  setSinglePost,
  parentId,
  isPreview = false,
  inputPlaceholder,
}): JSX.Element => {
  const [values, setValues] =
    useState<CreatePostInitialValuesProps>(initialValues);
  const [show, setShow] = useState(false);
  const [submitting, isSubmitting] = useState<boolean>(false);
  const [lastClickedEmoji, setLastClickedEmoji] = useState<string>("");
  const currUserProfile = useCurrentUserProfile((s) => s.user);

  const setFieldValue = (targetName: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  useEffect(() => {
    if (lastClickedEmoji) {
      setFieldValue("postText", `${values.postText}${lastClickedEmoji}`);
      setLastClickedEmoji(undefined);
    }
  }, [lastClickedEmoji]);

  const submit = useCallback(
    async (values: CreatePostInitialValuesProps) => {
      if (!currentUser.uid || isPreview) {
        window.location.reload();
        return;
      }

      if (!values.postMedia && !values.postText) {
        return createToast({
          type: "info",
          message: "Message or picture is required",
        });
      }

      isSubmitting(true);
      let firebaseFileURL: string | null = null;

      if (values?.postMedia?.name) {
        firebaseFileURL = await Query_Imgix.convertImageToImgIX(
          values.postMedia,
          `${postedIn}/${parentId}/${currentUser.slug}`
        );
      }
      const newPost = {
        postText: values.postText as string,
        postPhoto:
          values.postMedia && values.postMedia.type.includes("image")
            ? firebaseFileURL
            : null,
        postVideo:
          values.postMedia && values.postMedia.type.includes("video")
            ? firebaseFileURL
            : null,
        uid: currentUser.uid as string,
        wasEdited: false,
        totalSharesNumber: 0,
        postedIn: postedIn,
        type: "userPost",
        parentId: parentId || null,
      };

      const id = uuid();

      await QueryDB.createNewDoc("posts", newPost, id)
        .then(async () => {
          const existingUsers = Array.isArray(communityData?.friends.users)
            ? communityData?.friends.users.map((friend: any) => ({
                username: `@${friend.userName}`,
                slug: friend.slug,
                uid: friend.uid,
              }))
            : [];

          // const notificationsTextMapping = {
          //   "post-mention": "mentioned you in a post",
          // };

          setSinglePost({
            ...newPost,
            additionalData: {},
            authorLogo: currUserProfile.profileLogoUrl,
            authorSlug: currUserProfile.slug,
            authorUsername: currUserProfile.userName,
            created: timestamp.now(),
            fullName: currUserProfile.fullName,
            hasCurrentUserLikedThisPost: false,
            popularity: 0,
            totalCommentsNumber: 0,
            totalLikesNumber: 0,
            visibility: true,
            id,
          });

          const mentionedUsers = values.postText
            .split(" ")
            .filter((word: string) => word.startsWith("@"));

          const uniqueMentionedUsers = new Set(mentionedUsers);

          uniqueMentionedUsers.forEach((mentionedUsername) => {
            const mentionedUser = existingUsers.find(
              (user) => user.username === mentionedUsername
            );
            const isCurrentUser = mentionedUser.uid === currUserProfile.uid;
            if (mentionedUser && !isCurrentUser) {
              QueryDB_Notifications.createNewNotification(
                currentUser.uid,
                mentionedUser.uid,
                id,
                `post-mention-${postedIn}`,
                `${notificationsTextMapping[`post-mention`]}`
              );
            }
          });
        })
        .then(() => {
          createToast({
            type: "success",
            message: `Posted in ${postedIn}`,
          });
        })
        .catch((err) => {
          console.log(err);

          isSubmitting(false);
          createToast({
            type: "success",
            message: "Failed to upload post",
          });
        });
      isSubmitting(false);
      setValues(initialValues);
    },
    [currentUser]
  );

  return (
    <div className="post-form">
      <div className="post-form__avatar">
        <div className="avatar">
          <div className="avatar__content">
            <BaseUserAvatar
              logoUrl={currentUser.profileLogoUrl}
              userName={currentUser.userName}
            />
          </div>
        </div>
      </div>

      <div className="post-form__input">
        <form
          className="form"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <>
            <div className="form__content">
              <div className="form__input">
                <BaseTextInputWithMentions
                  label=""
                  textType="text"
                  value={values.postText}
                  placeholder={
                    inputPlaceholder ||
                    "Write something you would like to share"
                  }
                  setObjectState={setFieldValue}
                  setObjectStateTargetName="postText"
                  error={undefined}
                  isDisabled={disabledInput}
                />
                <EmojiWrapper show={show} setShow={setShow}>
                  <div className="form__emoji-picker">
                    <EmojiPicker
                      emojiVersion="0.6"
                      lazyLoadEmojis={true}
                      onEmojiClick={(emojiObject) => {
                        if (!disabledInput && !isPreview) {
                          setLastClickedEmoji(emojiObject.emoji);
                        }
                      }}
                    />
                  </div>
                </EmojiWrapper>
                <Button
                  variant="link"
                  onClickAction={() => setShow((pre) => !pre)}
                  customStyles="form__emoji-select"
                >
                  <img src={icon_emoji} alt="emoji" />
                </Button>
              </div>
              <span className="form__actions">
                <Button
                  variant="link"
                  customStyles="form__submit"
                  type="button"
                  onClickAction={() => {
                    if (!submitting) {
                      submit(values);
                    }
                  }}
                  disabled={
                    (values.postText.length <= 0 && !values.postMedia) ||
                    submitting
                  }
                >
                  {values.postText.length <= 0 ||
                  !values.postMedia ||
                  (values.postText.length > 0 && values.postMedia) ? (
                    <>
                      {submitting ? (
                        <Spinner />
                      ) : (
                        <PostIcon width={24} height={24} strokeWidth={2} />
                      )}
                    </>
                  ) : (
                    !submitting && (
                      <PostIcon width={24} height={24} strokeWidth={2} />
                    )
                  )}
                </Button>
              </span>
            </div>
          </>

          {!isPreview && !disabledInput && values.postMedia && (
            <div className="form__attachments">
              <ImagePreview
                funcToRemove={() => {
                  setFieldValue("postMedia", undefined);
                }}
                file={values.postMedia}
              />
            </div>
          )}

          <div className="post-form__actions">
            <ImageUpload
              isDisabled={isPreview || disabledInput}
              // acceptType="image/*, video/*" // Currently disabled as there is no NSFW filter on videos. TODO for later version
              acceptType="image/*"
              setIsLoading={() => {}}
              setFieldValue={setFieldValue}
              setType={() => {}}
              value={values.postMedia}
              name="postMedia"
            >
              <div className="post-media-upload">
                <CameraIcon width={22} height={18} stroke="#9BA0AD" />
                <span>{values.postMedia?.name ? "" : ""}</span>
              </div>
            </ImageUpload>
          </div>
        </form>
      </div>
    </div>
  );
};
