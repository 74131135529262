import BaseLogo from "@/components/BaseLogo/BaseLogo";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { FC } from "react";
import { Button } from "@/components";
import { handleCreateChat } from "./helperFunctions/messagesUtils";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { CommunityMember } from "./types/UserCommunity";

export const NewChatModal: FC<{
  selectedUsers: any;
  setSelectedUsers: any;
  setModalOpen: any;
  modalOpen: any;
  community: GetUserCommunityBasedOnSlug;
  chats: any[];
  selectedChat: string;
  setSelectedChat: any;
  setChatOpen: any;
}> = ({
  selectedUsers,
  setSelectedUsers,
  setModalOpen,
  modalOpen,
  community,
  chats,
  selectedChat,
  setSelectedChat,
  setChatOpen,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const handleUserSelect = (newUser: any) => {
    const newArray = [...selectedUsers];
    if (newArray.find((selUser) => selUser.uid === newUser.uid)) {
      const index = newArray.findIndex(
        (selUser) => selUser.uid === newUser.uid
      );
      newArray.splice(index, 1);
      return setSelectedUsers(newArray);
    }
    return setSelectedUsers((prev) => [...prev, newUser]);
  };

  return (
    <>
      <BaseModalWindow
        show={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setSelectedUsers([]);
        }}
      >
        <div className="chat-create">
          <div className="chat-create__left">
            <h2>New chat</h2>
            <h5>Select users</h5>
            <div className="chat-create__search">
              <input
                type="search"
                placeholder="Search"
                className="base-input"
              />
              {/* <SearchIcon color="#818694" /> */}
            </div>
            <div className="chat-create__community">
              {community.all.users
                .filter((selUser: CommunityMember) => {
                  if (
                    chats.find((chat, index) => {
                      if (selectedChat === null && index === 0) {
                        // setSelectedChat(chat);
                      }
                      return chat.includes(selUser.uid);
                    })
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((user: CommunityMember) => (
                  <div
                    className={`chat-create__user ${
                      selectedUsers.includes(user) ? "selected" : ""
                    }`}
                    key={user.uid}
                    onClick={() => handleUserSelect(user)}
                    aria-hidden
                  >
                    <div className="chat-create__user--image">
                      <BaseLogo
                        logoSrc={user.profileLogoUrl}
                        isPlaceHolder={undefined}
                        name={user.userName}
                      />
                    </div>
                    <div className="chat-create__user--info">
                      <div className="chat-create__user--name">
                        <h4>{user.userName}</h4>
                        <span>{user.fullName}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="chat-create__right">
            <div className="chat-select__wrapper">
              <div className="chat-create__search">
                <input
                  type="text"
                  placeholder="Chat name (optional)"
                  className="base-input"
                />
              </div>
              <div className="chat-select__users">
                <h5>
                  selected users come here (only 1 for now, more wil break)
                </h5>
                {selectedUsers
                  .filter((user) => user.uid)
                  .map((user: CommunityMember) => {
                    return (
                      <>
                        <div
                          className={`chat-create__user ${
                            selectedUsers.includes(user) ? "selected" : ""
                          }`}
                          key={user.uid}
                          onClick={() => {
                            if (user.uid) {
                              handleUserSelect(user);
                            }
                          }}
                          aria-hidden
                        >
                          <div className="chat-create__user--image">
                            <img src={user.profileLogoUrl} alt="" />
                          </div>
                          <div className="chat-create__user--info">
                            <div className="chat-create__user--name">
                              <h4>{user.userName}</h4>
                              <span>{user.fullName}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="chat-select__button">
              <Button
                customStyles="chat-select__button--create"
                variant="primary"
                onClickAction={async () => {
                  if (selectedUsers.length > 0) {
                    await handleCreateChat(selectedUsers, currentUser.uid);
                    const chatId = `${selectedUsers[0].uid}_${currentUser?.uid}`;
                    setSelectedChat(chatId);
                    setSelectedUsers([]);
                    setChatOpen(true);
                    setModalOpen(false);
                  }
                }}
              >
                Create chat
              </Button>
            </div>
          </div>
        </div>
      </BaseModalWindow>
    </>
  );
};
