import HeroImage from "../assets/heroDHS24.webp";
import HeroImageMobile from "../assets/heroDHS24.webp";

export const Elgiganten_heroVariables = {
  image: {
    src: HeroImage,
    alt: "Elgiganten er stolt main partner på Gamebox Festival",
    title: "Elgiganten er stolt main partner på Gamebox Festival",
    mobile: HeroImageMobile,
  },
  heading: "Don't miss our offers!",
  description: "Visit our new, huge gaming store inside Dreamhack, which is filled with 900 square meters of everything a gamer could possibly need. Perhaps best of all is that you get the chance to test everything before you buy it!",
  btn: {
    link: "",
    label: "",
  },
  author: "Elgiganten",
};
