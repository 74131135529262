export const createTeamOptions = {
  type: [
    { text: "Private", value: "private" },
    { text: "Public", value: "public" },
  ],
  criteriaPotm: [
    { text: "Stats based", value: "stats" },
    { text: "Custom", value: "custom" },
  ],
};
export const editTeamRights = {
  requests: {
    title: "Can send request to join team",
    dataValue: "canSendRequest",
    options: [
      { text: "All users", value: "all" },
      // { text: "friendsFriends", value: "friendsFriends" },
      { text: "Friends of any member", value: "friends" },
      { text: "No one", value: "no" },
    ],
  },

  postInFeed: {
    title: "Can post in feed",
    dataValue: "postInFeed",
    options: [
      { text: "All users", value: "all" },
      // { text: "Members only", value: "members" },
      { text: "Admins only", value: "admins" },
    ],
  },

  comments: {
    title: "Can leave comments",
    dataValue: "canLeaveComments",
    options: [
      { text: "All users", value: "all" },
      // { text: "Members only", value: "members" },
      { text: "Admins only", value: "admins" },
    ],
  },
};
