import postArrow_active from "@/assets/posts/post-active.svg";
import postArrow_inActive from "@/assets/posts/post-inactive.svg";
import icon_emoji from "@/assets/actions/emoji.svg";
import { FC, useState, useEffect } from "react";
import QueryDB from "@/api/queryDB/common/index";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import EmojiContainer from "@/components/EmojiWrapper";
import { discussionComment } from "../../types/DiscussionType";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { createToast } from "@/utils/toaster/createToast";
import SingleComment from "./SingleComment";

const DiscussionCommentComponent: FC<{
  setShowEmoji: React.Dispatch<React.SetStateAction<boolean>>;
  gatherPostId: string;
  showEmoji: boolean;
  gatherId: string;
}> = (props) => {
  const [posting, isPosting] = useState<boolean>(false);
  const [commentVal, setCommentVal] = useState<string>("");
  const [updateCommentsTrigger, setCommentUpdateTrigger] = useState<number>(0);

  const currentUser = useCurrentUserProfile((s) => s.user);
  const [comments, _loading_comments_, comments_Error, fetch_comments] =
    useCloudFunctionCall<discussionComment[]>(
      "getCommentsOfCurrentDiscussion",
      props.gatherPostId
    );
  const handleAddComment = async () => {
    if (commentVal.length !== 0 && !posting && currentUser.uid) {
      isPosting(true);
      await QueryDB.createNewDoc(
        "gatherDiscussionComments",
        {
          text: commentVal,
          createdBy: currentUser.uid,
          gatherPostId: props.gatherPostId,
          gatherId: props.gatherId,
        },
        currentUser.uid
      )
        .then(() => {
          createToast({
            type: "success",
            message: `Comment posted!`,
          });

          return true;
        })
        .catch(() => {
          createToast({
            type: "error",
            message: `Something went wrong tring to post comment.`,
          });
        });

      isPosting(false);
    }
  };

  useEffect(() => {
    if (!props.gatherPostId) {
      return;
    }
    const run = async () => {
      await fetch_comments({
        gatherPostId: props.gatherPostId,
      });
    };
    run();
  }, [updateCommentsTrigger]);
  return (
    <>
      <div className="gather__discussion--comments">
        <div className="gather__discussion--comments-list">
          <ul className="list-unstyled ps-0 position-relative">
            {comments &&
              comments.map((comment: discussionComment, index) => {
                const key = `${comment.created._nanoseconds}_${index}`;
                return (
                  <div key={key}>
                    <SingleComment
                      comment={{
                        ...comment,
                      }}
                      setCommentUpdateTrigger={setCommentUpdateTrigger}
                    />
                  </div>
                );
              })}
            {!_loading_comments_ && comments?.length === 0 && (
              <p>No comments yet.</p>
            )}
            <div className="gather__discussion--comments-input position-absolute">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Start typing..."
                  aria-label="Discussion comment"
                  value={commentVal}
                  onChange={(e) => {
                    setCommentVal(e.target.value);
                  }}
                />
                <span
                  className="d-none d-md-block input-group-text"
                  id="basic-addon1"
                >
                  <img
                    onClick={() => {
                      props.setShowEmoji(true);
                    }}
                    src={icon_emoji}
                    width={20}
                    height={20}
                  />
                </span>
              </div>
              <div className="ms-2">
                {commentVal.length === 0 || posting ? (
                  <img src={postArrow_inActive} width={24} height={19} />
                ) : (
                  ""
                )}
                {commentVal.length !== 0 && !posting ? (
                  <img
                    className="cursor-pointer"
                    onClick={async () => {
                      await handleAddComment();
                      setCommentVal("");
                    }}
                    src={postArrow_active}
                    width={24}
                    height={19}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
      <EmojiContainer
        setInputText={setCommentVal}
        inputText={commentVal}
        setShowImoji={props.setShowEmoji}
        showImoji={props.showEmoji}
      />
    </>
  );
};
export default DiscussionCommentComponent;
