import { functions } from "@/api/firebase/firebase.config";

export async function _updateUserCommunityStatus(
  action:
    | "send_friend_request"
    | "cancel_request"
    | "remove_friend"
    | "follow"
    | "unfollow"
    | "accept_friend_request"
    | "blocked",
  recipientUid: string
) {
  return await functions("sendUserCommunityRequest")({
    action,
    recipientUid,
  });
}
