import AfricaMap from "../../assets/continents/Africa.svg";
import AsiaMap from "../../assets/continents/Asia.svg";
import EuropeMap from "../../assets/continents/Europe.svg";
import NorthAmericaMap from "../../assets/continents/North_America.svg";
import SouthAmercaMap from "../../assets/continents/South_America.svg";
import AustraliaMap from "../../assets/continents/Australia.svg";

export const continents = [
  { value: "North America", map: NorthAmericaMap, text: "North America" },
  { value: "Europe", map: EuropeMap, text: "Europe" },
  { value: "Asia", map: AsiaMap, text: "Asia" },
  { value: "South America", map: SouthAmercaMap, text: "South America" },
  { value: "Africa", map: AfricaMap, text: "Africa" },
  { value: "Australia", map: AustraliaMap, text: "Australia" },
];
