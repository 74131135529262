import { Button, FavoriteButton } from "@/components";
import { FC, useState } from "react";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { ReactComponent as InviteUser } from "../../../../assets/actions/user-invite.svg";
import { ReactComponent as ShareIcon } from "../../../feed/assets/share-post-profile.svg";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import InviteUserModal from "@/components/InviteButton/InviteUserModal";
import { MembersTypes } from "../../types/MembersTypes";

export const SingleTeamActionsButtons: FC<{
  isPreview: boolean;
  teamData: SingleTeamResponseTypes;
  membersData?: MembersTypes[];
}> = ({ isPreview, teamData, membersData }) => {
  const [addMemberModalOpen, setAddMemberModalOpen] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  // Variables
  const userAccepted = teamData.currUserStatus === "accepted";
  const userCanInvite = teamData.canSendInvitation;

  return (
    <>
      {userCanInvite && (
        <BaseModalWindow
          show={addMemberModalOpen}
          closeModal={() => setAddMemberModalOpen(false)}
          title="Invite friends to team"
        >
          <InviteUserModal
            setAddMemberModalOpen={setAddMemberModalOpen}
            slug={teamData.slug}
            notificationType={"teams-requests"}
            featureName={teamData.name}
            usersToFilterFrom={membersData}
          />
        </BaseModalWindow>
      )}

      <div>
        {!isPreview && userAccepted && (
          <div className="card__icons">
            {userCanInvite && (
              <Button
                variant="link"
                data-challenges="card-icons"
                onClickAction={() => {
                  setAddMemberModalOpen(true);
                }}
              >
                <InviteUser />
              </Button>
            )}
            <FavoriteButton
              slug={`${currentUser.uid}_${teamData.slug}`}
              isPreview={isPreview}
              isInFavoritesOfCurrentUser={teamData.isCurrUserFavorite}
              featureType="team"
              name={teamData.name}
            />
            <Button
              variant="link"
              onClickAction={() => setShareModalOpen(true)}
            >
              <ShareIcon />
            </Button>
          </div>
        )}

        {/* General modal for sharing | New */}
        {shareModalOpen && (
          <ShareContentModalWindow
            sharedFrom={"team"}
            createType="create"
            show={shareModalOpen}
            closeWindow={() => setShareModalOpen(false)}
            postData={{
              uid: currentUser.uid,
              postText: "",
              postedIn: "",
              totalSharesNumber: 0,
              postVideo: "",
              created: {
                _seconds: teamData.created?.seconds,
                _nanoseconds: teamData.created?.nanoseconds,
              },
              postPhoto: "",
              wasEdited: false,
              commentsCount: 0,
              totalLikesNumber: 0,
              totalCommentsNumber: 0,
              id: teamData.slug,
              authorSlug: currentUser.slug,
              authorUsername: currentUser.userName,
              authorLogo: currentUser.profileLogoUrl,
              hasCurrentUserLikedThisPost: false,
              type: "sharedTeam",
              additionalData: teamData,
            }} // NEW
          />
        )}
      </div>
    </>
  );
};
