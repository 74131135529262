import { Timestamp } from "@/api/firebase/firebase.config";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

type TeamMembers = {
  fullName?: string;
  inTeamRole?: string;
  joinedTeamTimestamp: Timestamp;
  logoUrl?: string;
  monthlyPoints: number;
  role: "owner" | "admin" | "member";
  slug: string;
  uid: string;
  userName: string;
};
type Error = {
  message: string;
};
export function useTeamMembersHook(): readonly [TeamMembers[], boolean, Error] {
  const { slug } = useParams();
  const location = useLocation();
  const keyedSlug = `all_members_${slug}`;
  const [teamMembers, loading, error, fetch_allTeamMembers] =
    useCloudFunctionCall<any[] | any>("getTeamMembers", keyedSlug);

  useEffect(() => {
    if (!location.pathname.includes("team")) {
      return;
    }
    (async () => {
      if (!teamMembers) {
        fetch_allTeamMembers({
          slug,
          seeAll: true,
        });
      }
    })();
  }, [slug]);
  if (!location.pathname.includes("team")) {
    return;
  }

  return [teamMembers, loading, error] as const;
}
