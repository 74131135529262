import firebase from "firebase";

export const handleCreateChat = async (
  //   chat: Chat,
  selectedUsers: any[],
  profileUid: string
) => {
  let chats = [] as any[];

  const databaseRef = firebase
    .database()
    .ref("chat_usersIdJunction")
    .child(profileUid);

  databaseRef.on("value", (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const activeChats = Object.keys(data).filter((key) =>
        key.includes(profileUid)
      );
      chats = activeChats;
    } else {
      chats = [];
    }
  });

  if (
    selectedUsers.filter((selUser) => {
      return !chats.find((chat) => chat.includes(selUser.uid));
    }).length === 0
  ) {
    return;
  }

  //   Filter out users who already have a account, Might have be changed when we have groups
  if (selectedUsers.length > 0) {
    const userIds = [
      profileUid,
      ...selectedUsers
        .filter((selUser) => {
          return !chats.includes(selUser.uid);
        })
        .map((user: any) => {
          return user.uid;
        }),
    ].sort();
    const chatId = userIds.join("_");

    const newMessage = {
      created: Date.now(),
      sender: profileUid,
      receiver: "wog",
      content: {
        message: "New chat started",
      },
      readBy: {
        [profileUid]: true,
        wog: false,
      },
      edited: {
        state: false,
        0: "",
      },
      deletedBy: {
        [profileUid]: false,
        wog: false,
      },
      reactions: {
        [profileUid]: "",
        wog: "",
      },
    };

    const dbRef = firebase.database().ref("chat").child(chatId);
    const chatRef = dbRef.push();

    await chatRef.set(newMessage);

    await dbRef.update({
      [`unread`]: {
        [profileUid]: 0,
        [selectedUsers[0].uid]: 0,
      },
    });
  }
};
