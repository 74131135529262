import { collectionRef } from '@/api/collectionRef/collectionOperations';
import { Games } from '@/models/database/games/games.model';
import { useState, useEffect } from 'react';

const useGameInfo = (gameId) => {
  const [gameData, setGameData] = useState<Games>();;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getGameInfo = async () => {
        const games = collectionRef.games;
    
        const gamesRes = await games
            .where("value", "==", gameId)
            .limit(1)
            .get();
            console.log(gamesRes);
    
            if (gamesRes && gamesRes.docs[0] && gamesRes.docs[0].data()) {
                setLoading(false);
                return setGameData(gamesRes.docs[0].data());
            }
        setLoading(false);
        return false;
    };

    getGameInfo();
  }, [gameId]);

  return { gameData, loading, error };
};

export default useGameInfo;