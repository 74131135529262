import { ErrorFallback } from "@/components";
import SingleNewsCard from "./SingleNewsCard";

export const AllNewsItem = (news: any, _loading_news: boolean) => {
  try {
    return (
      <SingleNewsCard
        imageURL={news.imageURL}
        title={news.title}
        created={news.created}
        slug={news.slug}
        isFavoriteByCurrentUser={news.isFavoriteByCurrentUser}
        trending={news.trending}
        isPlaceholder={_loading_news}
        author={news.author}
        isFeatured={news.isFeatured}
        intro={news.intro}
        keywords={news.keywords}
      />
    );
  } catch {
    return <ErrorFallback>Failed to load news</ErrorFallback>;
  }
};
