import { Badge } from "@/components/Badge";
import i18next from "i18next";
import React, { memo, FC } from "react";

const SingleChallengeFormula: FC<{
  coefficients: { [key: string]: number } | undefined;
}> = (props): JSX.Element => {
  const hasMultipleCriteria =
    props.coefficients && Object.keys(props.coefficients).length > 1;
    
  const hasMinCriteria =
    props.coefficients && Object.values(props.coefficients)[0] === -1;
  return (
    <div className="challenge__formula">
      <div className="formula">
        <h3 className="formula__heading">{i18next.t("challenge.criteria.headline")}?</h3>
        <div className="formula__criteria">
          <ul className="formula__list">
            {props.coefficients ? (
              Object.entries(props.coefficients).map(
                ([key, value], index, array) => (
                  <React.Fragment key={key}>
                    <li className="formula__item" key={`${key}_${index}`}>
                      <div className="badge-wrapper">
                        <Badge variant="primary">
                          {!hasMultipleCriteria && hasMinCriteria && "Min "}
                          {!hasMultipleCriteria && !hasMinCriteria && "Max "}
                          {key}
                        </Badge>
                        {hasMultipleCriteria && (
                          <span className="formula__value">
                            {Math.abs(value)} pts
                          </span>
                        )}
                        {hasMultipleCriteria && index < array.length - 1 && (
                          <span className="formula__plus">+</span>
                        )}
                      </div>
                    </li>
                  </React.Fragment>
                )
              )
            ) : (
              <li>The coefficients were not specified.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(SingleChallengeFormula);
