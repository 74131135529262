import QueryDB from "@/api/queryDB/common";
import { Profile } from "@/models/database/profiles/profile.model";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";

export const userJunctionHandler = (
  status: "accepted" | "banned" | "invited" | "pendingRequest" | "rejected",
  currentUser: Profile,
  teamRole: string,
  docId: string,
  generalRole: string,
  teamSlug: string,
  alreadyMember?: boolean
) => {
  const baseData = {
    currUserStatus: status,
    status: status,
    generalRole: generalRole,
  } as {
    requestAccepted?: {
      acceptedByUid: string;
      acceptedByUsername: string;
      acceptedAt: any;
    };
    joinedTeamTimestamp?: any;
    invitedBy?: {
      invitedByUid: string;
      invitedByUsername?: string;
      invitedAt: any;
    };
  };

  if (status === "invited") {
    baseData.invitedBy = {
      invitedByUid: currentUser.uid,
      invitedByUsername: currentUser.userName,
      invitedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }

  if (
    alreadyMember === false ||
    (alreadyMember === undefined && status === "accepted")
  ) {
    baseData.requestAccepted = {
      acceptedByUid: currentUser.uid,
      acceptedByUsername: currentUser.userName,
      acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }

  if (status === "accepted") {
    baseData.joinedTeamTimestamp =
      firebase.firestore.FieldValue.serverTimestamp();
  }

  return QueryDB.updateDoc("userTeamJunction", docId, {
    ...baseData,
  }).catch(() => {
    createToast({
      type: "error",
      message: "Failed to handle update, please try again later",
    });
    return false;
  });
};
