import { FC } from "react";
import { ReactComponent as IconUser } from "../../assets/user-plus.svg";
import { Link } from "react-router-dom";

const SuggestedFriends: FC = (): JSX.Element => {
  const usersData = [
    {
      avatar: "https://ik.imagekit.io/gmng/profile/filename_6IPXbfXYI",
      userName: "Meastrix001",
      fullName: "Nick Roofthooft",
      link: "/profile/meastrix001",
    },
    {
      avatar: "https://wog.imgix.net/profile//e2d71fea-233f-4818-a627-43091a79148d?w=190&h=190&fit=crop",
      userName: "elleness",
      fullName: "",
      link: "/profile/elleness",
    },
    {
      avatar: "https://ik.imagekit.io/gmng/DefaultAvatars/foods-pepper-256.jpg?updatedAt=1702558078929",
      userName: "shubhdeep",
      fullName: "null",
      link: "/profile/shubhdeep",
    },
    {
      avatar: "https://firebasestorage.googleapis.com/v0/b/world-of-gamers-d663f.appspot.com/o/dev.wog.gg_1679991642756?alt=media&token=0da6308f-e665-465b-863b-aad9ce5f2f02",
      userName: "dashy6666",
      fullName: "Daryna Maiboroda",
      link: "/profile/dashy6666",
    },
    {
      avatar: "https://firebasestorage.googleapis.com/v0/b/world-of-gamers-d663f.appspot.com/o/dev.wog.gg_1666686787563?alt=media&token=fd675f45-c957-4067-bc57-6cdc9f14614d",
      userName: "helkurburrito",
      fullName: "",
      link: "/profile/helkurburrito",
    },
    {
      avatar: "https://wog.imgix.net/profile//491e1d88-1e28-404f-953a-007fd09f625f?w=190&h=190&fit=crop",
      userName: "Neroin",
      fullName: "Alexander Juul Jakobsen",
      link: "/profile/neroin",
    },
  ];
  return (
    <div className="friends">
      <div className="friends__header">
        <h2>Suggested Gamers</h2>
      </div>
      <ul className="friends__list">
        {usersData.map((user) => {
          return (
            <li className="friends__item" key={user.userName}>
              <Link to={user.link}>
                <div className="friend">
                  <div className="friend__image">
                    <div className="image">
                      <div className="image__content">
                        <img src={user.avatar} />
                      </div>
                    </div>
                  </div>
                  <div className="friend__name">
                    <span className="friend__username">{user.userName}</span>
                    {user.fullName && (
                      <span className="friend__fullname">{user.fullName}</span>
                      )}
                  </div>
                  <div className="friend__action">
                    <IconUser />
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SuggestedFriends;
