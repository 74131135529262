import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import { CreateChallengeHeader } from "./CreateChallengeHeader";
import { createChallengeInitialValues } from "../../utils/CreateChallengeValues";
import submit from "../../helperFunctions/createChallenge";
import CreateChallengeStep0 from "./pages/CreateChallengeStep0";
import { validateChallengeForm } from "@/utils/constants/validationUtils";
import { CreateChallengeFooter } from "./CreateChallengeFooter";
import { CreateChallengeStep1 } from "./pages/CreateChallengeStep1";
import { CreateChallengeStep2 } from "./pages/CreateChallengeStep2";
import CreateChallengeStep3 from "./pages/CreateChallengeStep3";
import { ChallengeCard } from "../challengeCard";
import { CreateChallengeFormTypes } from "../../utils/CreateChallengeTypes";
import { CreateChallengePreviewCard } from "./CreateChallengePreviewCard";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import ChallengeCardComponent from "../challengeCard/ChallengeCardComponent";
import placeholderImage from "../../../../assets/placeholders/placeholder.png";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const CreateChallengeForm: React.FunctionComponent<{
  isEdit: boolean;
  setIsEdit: any;
  challenge?: any;
}> = ({ isEdit, setIsEdit, challenge }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [challValues, setChallValues] = useState<
    CreateChallengeFormTypes | any
  >(
    challenge
      ? {
          game: challenge.challengeData.game,
          region: challenge.challengeData.region,
          name: challenge.challengeData.name,
          goal: challenge.challengeData.goal,
          description: challenge.challengeData.description || "",
          logoUrl: challenge.challengeData.logoUrl,
          onlyWins: challenge.challengeData.pointCountingType,
          duration: [
            new Date(challenge.challengeData.startDate['seconds'] * 1000),
            new Date(challenge.challengeData.endDate['seconds'] * 1000),
          ],
          criteria:
            challenge.challengeAdditionalData.fieldToCompareCoefficients,
          teamSize: challenge.challengeData.teamSize,
          type: challenge.challengeData.pointCountingType,
          fieldToCompare:
            challenge.challengeAdditionalData.fieldToCompareCoefficients,
          gameType: challenge.challengeData.gameType,
          mapModes: challenge.challengeAdditionalData.mapModes || [
            { text: "Any map", value: "" },
          ],
          mapNames: challenge.challengeAdditionalData.mapNames || [
            { text: "Any mode", value: "" },
          ],
          mapPool: challenge.challengeAdditionalData.mapPool,
        }
      : createChallengeInitialValues
  );

  const test = useCurrentUserProfile((s) => s.user);
  const [errors, setError] = useState<any>();
  const setFieldValue = async (
    targetName: string,
    value: any,
    targetNameAlt?: string,
    valueAlt?: string
  ) => {
    if (targetName === "mapNames" || targetName === "mapModes") {
      // BE has array support, Fe wil keep single options for now

      return setChallValues((prev: any) => ({
        ...prev,
        [targetName]: value ? value.split(",") : [],
        [targetNameAlt]: valueAlt,
      }));
    }

    // set value
    return setChallValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const steps = !isEdit
    ? [
        <CreateChallengeStep0
          errors={errors}
          values={challValues}
          setFieldValue={setFieldValue}
          setStep={setStep}
        />,
        <CreateChallengeStep1
          values={challValues}
          errors={errors}
          setFieldValue={setFieldValue}
          isEdit={isEdit}
        />,
        <CreateChallengeStep2
          values={challValues}
          errors={errors}
          setFieldValue={setFieldValue}
          isEdit={isEdit}
        />,
        <CreateChallengeStep3 values={challValues} />,
      ]
    : [
        <CreateChallengeStep1
          values={challValues}
          errors={errors}
          setFieldValue={setFieldValue}
          isEdit={isEdit}
        />,
        <CreateChallengeStep2
          values={challValues}
          errors={errors}
          setFieldValue={setFieldValue}
          isEdit={isEdit}
        />,
        <CreateChallengeStep3 values={challValues} />,
      ];

  const handleNextStep = async () => {
    const hasErrors = await validateChallengeForm(challValues, step);
    console.log(challValues);

    if ((!isEdit && !hasErrors) || isEdit) {
      setError(undefined);
      if (step + 1 === steps.length) {
        setIsSubmitting(true);
        // IF isEdit IS TRUE, IT WILL UPDATE SET DATA INSTEAD OF CREATE NEW DOC
        if (isEdit) {
          return await submit(challValues, challenge.challengeData.slug, true)
            .then(() => {
              setIsSubmitting(false);
              setIsEdit(false);
              navigate(
                `${siteRoutes["challengesAndSlug"]}${challenge.challengeData.slug}`
              );
            })
            .catch(() => {
              navigate(siteRoutes["challenges"]);
            });
        } else {
          const slug = encodeURIComponent(
            normalizeString(
              convertCleanEntityNameToSlug(cleanEntityName(challValues.name))
            )
          );
          console.log(slug);

          return await submit(challValues, slug, false)
            .then(() => {
              setIsSubmitting(false);
              navigate(`${siteRoutes["challengesAndSlug"]}${slug}`);
            })
            .catch((err) => {
              console.log(err);
              // navigate(siteRoutes["challenges"]);
            });
        }
      }
      return setStep(step + 1);
    }
    setError(hasErrors);
    return false;
  };

  const getChallengeImg = () => {
    if (challValues.logoUrl && challValues.logoUrl.name) {
      return URL.createObjectURL(challValues.logoUrl);
    }
    if (challValues.logoUrl && String(challValues.logoUrl)) {
      return challValues.logoUrl;
    }
    return placeholderImage;
  };

  return (
    <div className="create-challenge">
      <CreateChallengeHeader
        isEdit={isEdit}
        step={step}
        stepsAmount={steps.length}
        setStep={setStep}
        loading={submitting}
        handleNextStep={handleNextStep}
        values={challValues}
      />
      <div
        className={`create-challenge__content ${
          !isEdit && step === 0 ? "nocard" : ""
        } ${step + 1 === steps.length ? "preview" : ""}`}
      >
        {challValues && <div className="content">{steps[step]}</div>}

        {(isEdit && step + 1 !== steps.length) ||
        // (!isEdit && step !== 0) ||
        (!isEdit && step + 1 !== steps.length && step !== 0) ? (
          <div className="content__preview">
            <h5>Preview</h5>

            <ChallengeCardComponent
              key={challValues.slug}
              slug={challValues.slug}
              text={challValues.goal ?? "No goal provided."}
              name={
                challValues.name?.length > 0
                  ? challValues.name
                  : "Challenge name"
              }
              game={challValues.game}
              logo={getChallengeImg()}
              createdBy={challValues.createdBy}
              creatorType={challValues.creatorType}
              startDate={{
                _seconds: new Date(challValues.duration[0]).getTime() / 1000,
                _nanoseconds: new Date(challValues.duration[0]).getTime(),
              }}
              endDate={{
                _seconds: new Date(challValues.duration[1]).getTime() / 1000,
                _nanoseconds: new Date(challValues.duration[1]).getTime(),
              }}
              isPreview
              fieldToCompare={[
                ...Object.keys(challValues?.criteria || []),
                ...Object.values((challValues?.mapNames as string[]) || []),
                ...Object.values((challValues?.mapModes as string[]) || []),
              ]}
              author={{
                slug: currentUser.slug,
                uid: currentUser.uid,
                profileLogoUrl: currentUser.profileLogoUrl,
                userName: currentUser.userName,
                fullName: currentUser.fullName,
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <CreateChallengeFooter
        step={step}
        stepsAmount={steps.length}
        setStep={setStep}
        loading={submitting}
        handleNextStep={handleNextStep}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </div>
  );
};
