import { FC } from "react";
import { ReactComponent as ShareIcon } from "../../../assets/actions/share-arrow-right.svg";
import { ReactComponent as CopyIcon } from "../../../assets/actions/copy.svg";
import { ReactComponent as ChevronUp } from "../../../assets/actions/chevron-up.svg";
import { ReactComponent as CommentsIcon } from "../../../features/feed/assets/comments.svg";

const NewsSingleLoader: FC = (): JSX.Element => {
  return (
    <div className="article placeholder">
      <div className="article__head align-full">
        <div className="head">
            <div className="head__image wave" />
            <div className="head__heading">
                <div className="container">
                    <div className="head__content">
                    <div className="head__body">
                        <div className="head__title">
                            <h1 className="wave"/>
                        </div>
                        <div className="head__excerpt wave" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="article__grid">
        {/* page__actions */}
        <div className="article__actions">
            <div className="action">
                <div className="action__group">
                    <div className="action__item">
                        <CopyIcon width={24} height={24} stroke="#FFF" />
                    </div>
                    <div className="action__item">
                        <ShareIcon width={24} height={24} stroke="#FFF" />
                    </div>
                    <div className="action__item">
                        <CommentsIcon width={24} height={24} stroke="#FFF" />
                    </div>

                </div>
                <div className="action__item action__item--top">
                    <ChevronUp width={24} height={24} stroke="#FFF" />
                </div>
            </div>
        </div>

        <div className="article__content">
            {/* Author and date */}
            <div className="article__meta">
                <div className="article__author">
                    <div className="author">
                    <div className="author__image wave" />
                    <div className="author__username wave" />
                    </div>
                </div>

                <div className="article__posted wave">
                    <time />
                </div>
            </div>

            {/* News content */}
            <div className="article__main">
                <div className="wave" />
                <div className="wave" />
                <div className="wave" />
                <div className="wave" />
                <div className="wave" />
                <div className="wave" />
            </div>
        </div>

        {/* page__extra */}
        
        <div className="article__sidebar">
            <div className="badge-wrapper">
                <div className="badge bg-secondary wave" />
                <div className="badge bg-secondary wave" />
                <div className="badge bg-secondary wave" />
                <div className="badge bg-secondary wave" />
            </div>

            <div className="article__related">
            <h2>Related news</h2>
            <ul className="related">
                    <li className="related__item" >
                        <h3 className="related__heading">
                            <div className="wave" />
                            <div className="wave" />
                            <div className="wave" />
                        </h3>
                        <div className="related__meta">
                            <div className="related__author">
                                <div className="author">
                                    <div className="author__image" />
                                    <div className="author__username" />
                                </div>
                            </div>
                            <div className="meta__posted">
                                <time />
                            </div>
                        </div>
                    </li>
                    <li className="related__item" >
                        <h3 className="related__heading">
                            <div className="wave" />
                            <div className="wave" />
                            <div className="wave" />
                        </h3>
                        <div className="related__meta">
                            <div className="related__author">
                                <div className="author">
                                    <div className="author__image" />
                                    <div className="author__username" />
                                </div>
                            </div>
                            <div className="meta__posted">
                                <time />
                            </div>
                        </div>
                    </li>
                    <li className="related__item" >
                        <h3 className="related__heading">
                            <div className="wave" />
                            <div className="wave" />
                            <div className="wave" />
                        </h3>
                        <div className="related__meta">
                            <div className="related__author">
                                <div className="author">
                                    <div className="author__image" />
                                    <div className="author__username" />
                                </div>
                            </div>
                            <div className="meta__posted">
                                <time />
                            </div>
                        </div>
                    </li>
            </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSingleLoader;
