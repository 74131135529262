import { FC } from "react";
import SingleGatherSettingItems from "./SingleGatherSettingItems";
import SingleGatherTages from "./SingleGatherTages";
import SingleGatherParticipateButton from "./SingleGatherParticipateButton";

type props = {
  name: string;
  type: "private" | "public";
  meetType?: "offline" | "online";
  slug: string;
  isCurrentUserParticipant?: boolean;
  games: string[];
  currentUserGeneralRole?: "admin" | "random" | "owner" | "member";
  description?: string;
  isRequestPending?: boolean;
  isCurrentUserInterested: boolean;
  isPreview?: boolean;
};
const SingleGatherGeneralinfo: FC<props> = (props): JSX.Element => {
  return (
    <>
      <SingleGatherSettingItems
        currentUserGeneralRole={props.currentUserGeneralRole}
        gatherSlug={props.slug}
        name={props.name}
        isPreview={props.isPreview}
        isCurrentUserParticipant={props.isCurrentUserParticipant}
      />
      <SingleGatherTages
        name={props.name}
        type={props.type}
        slug={props.slug}
        games={props.games}
        meetType={props.meetType}
        description={props.description}
      />
      <SingleGatherParticipateButton
        type={props.type}
        isCurrentUserInterested={props.isCurrentUserInterested}
        slug={props.slug}
        isRequestPending={props.isRequestPending}
        isCurrentUserParticipant={props.isCurrentUserParticipant}
      />
    </>
  );
};

export default SingleGatherGeneralinfo;
