import { useEffect } from "react";
import { TeamOverviewCardProps } from "../../types/TeamTypes";
import { TeamCardComponent } from "../teamCard/TeamCardComponent";
import TeamsOverviwLoader from "@/components/skeletonLoaders/teamsOverviewLoader/teamsLoader";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useNavigate } from "react-router-dom";
import { useCacheDataArrayManager } from "@/hooks/useCacheDataArrayManager";
import { getMineTeamsFromCache } from "@/api/queryRealtime/Teams";
import { useMyTeamsData } from "../../hooks/useMyTeamsHook";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useInView } from "react-intersection-observer";

const filterKey = "mine-teams";
export default function MineTeams({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const currUser = useCurrentUserProfile((s) => s.user).uid;
  const navigate = useNavigate();
  // const [
  //   teamsData,
  //   lastFeedDateInSeconds,
  //   _hasMore,
  //   _loading,
  //   error,
  //   fetchTeamsData,
  //   ref,
  //   inView,
  // ] = useCacheDataArrayManager<any>(getMineTeamsFromCache, filterKey);

  const { data, loading, fetchMyTeamsData, fetchMoreMyTeamsNews, hasMore } =
    useMyTeamsData({
      client: brand.brandValue,
      limit: 20,
    });

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  //on first load
  useEffect(() => {
    (async () => {
      // fetchTeamsData(cfProps, false);
      fetchMyTeamsData();
    })();
  }, []);

  useEffect(() => {
    if (inView) {
      fetchMoreMyTeamsNews();
    }
  }, [inView]);

  // Variables
  const inViewRefElementIndex = data ? data?.length - 2 : -1;
  const teamsDataNotEmpty = data?.length !== 0;

  // Loading state
  if (loading) {
    return (
      <div className="teams__list">
        {Array.from(Array(6), (_, ind) => {
          return <TeamsOverviwLoader key={ind} />;
        })}
      </div>
    );
  }

  // No errors, there is teamsData and teamsData is not empty
  if (data && teamsDataNotEmpty) {
    return (
      <div className="teams__list">
        {data.map((team: TeamOverviewCardProps, index: number) => {
          const key = `${team.id}_${index}`;
          return (
            <div
              className="teams__item"
              key={key}
              ref={index === inViewRefElementIndex ? ref : null}
            >
              <TeamCardComponent teamData={team} />
            </div>
          );
        })}
      </div>
    );
  }

  // Any other case
  return (
    <div className="teams__empty">
      <p>
        You haven't made any teams yet.{" "}
        <span
          onClick={() => {
            navigate(siteRoutes["createTeam"]);
          }}
        >
          Create a new team
        </span>
      </p>
    </div>
  );
}
