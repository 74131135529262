import { FC, useState } from "react";

type props = {
  name: string;
  type: "private" | "public";
  meetType?: "offline" | "online";
  slug: string;
  games: string[];
  description?: string;
};

const SingleGatherTages: FC<props> = (props): JSX.Element => {
  const [fullDescription, showFullDescription] = useState<boolean>();

  return (
    <>
      <div className="Single-gather__badges">
        <span className="Single-gather__badges--type">{props.type}</span>
        {props.meetType && (
          <span className="Single-gather__badges--meetType">
            {props.meetType}
          </span>
        )}
      </div>

      <p
        className={`Single-gather__description ${
          fullDescription ? "" : "gather__description--break"
        }`}
      >
        {props.description}
      </p>

      {props.description && props.description?.length > 250 && (
        <p
          aria-hidden
          onClick={() => {
            showFullDescription(!fullDescription);
          }}
          className="Single-gather__description--show-btn "
        >
          {props.description?.length !== 0 && fullDescription
            ? "Show less"
            : props.description?.length && "Show more"}
        </p>
      )}
      <div>
        <ul className="Single-gather__tags">
          {props.games &&
            props.games.map((game) => {
              return <li key={game}>{game}</li>;
            })}
        </ul>
      </div>
    </>
  );
};

export default SingleGatherTages;
