import React, {
  useState,
  useEffect,
  memo,
  Dispatch,
  SetStateAction,
  // useCallback,
  FC,
} from "react";
import firebase from "firebase/app";
// import { games } from "@utils/shared/variables";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { Games } from "@/models/database/games/games.model";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import { DropDown } from "@/components";

// TODO: games, criteria and count shoud be received from BE

export const GameFilterPicker: FC<{
  selectedGame: string | undefined;
  setSelectedGame: Dispatch<SetStateAction<string | undefined>>;
  setUpdateTrigger: any;
  filterLocation: "teams" | "gathers" | "challenges" | "news";
}> = ({
  selectedGame,
  setSelectedGame,
  setUpdateTrigger,
  filterLocation,
}): JSX.Element => {
  const [gamesForCurrentChallenges, setGamesForCurrentChallenges] = useState<{
    [key: string]: number;
  }>({});
  const [games, setGames] = useState<Games[]>();
  useEffect(() => {
    if (selectedGame === "") {
      setSelectedGame("");
    }
  }, [selectedGame]);

  useEffect(() => {
    const fetch = async () => {
      const response = (await collectionRef.games.get()).docs.map((doc) => {
        return doc.data();
      });
      setGames(response);
    };
    fetch();
  }, []);

  useEffect(() => {
    const counts: { [key: string]: number } = {};
    const gamesNames: string[] = [];
    if (filterLocation === "challenges") {
      firebase
        .firestore()
        .collection("challenges")
        .where("status", "!=", "finished")
        .where("client", "==", "WOG")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            gamesNames.push(doc.data().game);
            return doc.data();
          });

          gamesNames.forEach(function (x) {
            counts[x] = (counts[x] || 0) + 1;
          });
          return setGamesForCurrentChallenges(counts);
        });
    }

    if (filterLocation === "teams") {
      firebase
        .firestore()
        .collection("teams")
        .where("type", "==", "public")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            return doc.data().games.map((game: string) => {
              const gameObj = games.find((obj) => obj.value === game);
              if (gameObj !== undefined) {
                return gamesNames.push(gameObj.name);
              }
              return false;
            });
          });
          gamesNames.forEach(function (x) {
            counts[x] = (counts[x] || 0) + 1;
          });
          setGamesForCurrentChallenges(counts);
        });
    }

    if (filterLocation === "gathers") {
      // const games = collectionRef.games;
      firebase
        .firestore()
        .collection("gathers")
        .where("status", "==", "active")
        .where("type", "==", "public")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            return doc.data().games.map((game: string) => {
              const gameObj = games.find((obj) => obj.value === game);
              if (gameObj !== undefined) {
                return gamesNames.push(gameObj.name);
              }
              if (gameObj === undefined && !gamesNames[gameObj.name]) {
                return gamesNames.push(gameObj.name);
              }
              return false;
            });
          });
          gamesNames.forEach(function (x) {
            counts[x] = (counts[x] || 0) + 1;
          });
          setGamesForCurrentChallenges(counts);
        });
    }

    if (filterLocation === "news") {
      firebase
        .firestore()
        .collection("news")
        .where("category", "==", "users")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            return doc.data().games.map((game: string) => {
              const gameObj = games.find((obj) => {
                return obj.value === game;
              });

              if (gameObj !== undefined) {
                return gamesNames.push(gameObj.value);
              }

              if (gameObj === undefined && !gamesNames[game]) {
                return gamesNames.push(game);
              }

              return false;
            });
          });
          gamesNames.forEach(function (x) {
            counts[x] = (counts[x] || 0) + 1;
          });
          setGamesForCurrentChallenges(counts);
        });
    }
  }, [games]);
  return (
    <>
      <select
        id="game-filter"
        onChange={(e: any) => {
          setSelectedGame(e.target.value);
          setUpdateTrigger((prev: number) => prev + 1);
        }}
      >
        {/* Default selected */}
        {!selectedGame && <option selected={!selectedGame}>Pick game</option>}

        {/* Games */}
        {Object.entries(gamesForCurrentChallenges).map(([game]) => (
          <option
            className={` ${selectedGame === game ? "selected" : ""}`}
            aria-hidden
            value={game}
            selected={selectedGame === game}
            key={game}
          >
            {game}
          </option>
        ))}

        {/* Clear */}
        <option className="" value="">
          Clear filter
        </option>
      </select>
    </>
  );
};
