import { functions } from "@/api/firebase/firebase.config";
import { useGeneralStoreForCloudFunctions } from "@/store/database/database.store";
import { useMemo, useState } from "react";

type Error = {
  message: string;
};
/**
 * Usage: When you have to call a cloud function
 * @param cloud_function_name Name of the cloud function you are going to call
 * @returns error, loading state, data and fetch function
 */
export default function useCloudFunctionCall<T>(
  cloud_function_name: string,
  slug: string = ""
) {
  const key = `${cloud_function_name}_${slug}`;
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  const data = useGeneralStoreForCloudFunctions((state: any) => state.data)[
    key
  ] as T | undefined;
  const dispatchData = useGeneralStoreForCloudFunctions(
    (s: any) => s.dispatchData
  );
  const functionQuery = useMemo(
    () => functions(cloud_function_name),
    [cloud_function_name]
  );

  /**
   *
   * @param params Ensure that all the necessary properties required by the cloud function are passed as arguments or parameters.
   * @param merge If set to 'true', the new upcoming data from the cloud function will be merged with the existing data. Use 'merge: true' in scenarios involving infinite scrolling, where you need to retain the previous data and incorporate the new data seamlessly.
   */
  async function fetch(params: any | undefined, merge?: boolean) {
    setLoading(true);
    let _data = {};
    if (params) {
      _data = params;
    }
    try {
      const response = await functionQuery(_data);
      dispatchData(response.data, key, merge);
    } catch (error: any) {
      setLoading(false);
      setError({
        message: error.message,
      });
    }

    setLoading(false);
  }

  return [data, loading, error, fetch] as const;
}
