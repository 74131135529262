import { GeneralInputProps } from "@/models/props/TextInput";
import React, { FocusEventHandler, FormEventHandler, useState } from "react";
const SelectInput: React.FunctionComponent<
  GeneralInputProps & {
    options?: { text: string | number; value: string | number }[];
    onChange?: FormEventHandler<HTMLSelectElement>;
    onBlur?: FocusEventHandler<HTMLSelectElement>;
    children: any;
    setDataId?: any;
  }
> = ({
  name,
  value,
  onChange,
  onBlur,
  styleProps,
  placeholder,
  options,
  children,
  isDisabled,
  setDataId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>();

  return (
    <select
      name={name}
      defaultValue={value}
      onChange={onChange}
      onBlur={() => {
        onBlur;
        setIsOpen(!isOpen);
      }}
      className="base-select"
      disabled={isDisabled}
    >
      {placeholder ? (
        <option value="" disabled>
          {placeholder}
        </option>
      ) : (
        ""
      )}

      {!children &&
        options &&
        options.map((element) => {
          const dataId = typeof element.value === "object" ? element.text : "";
          return (
            <option
              data-id={dataId}
              value={element.value}
              key={element.value}
              selected={element.value === value}
            >
              {element.text}
            </option>
          );
        })}

      {/* If children want to be added manualy */}
      {children && !options && children}
    </select>
  );
};

export default SelectInput;
