import LayoutFooter from "../../../../../branchAndBrandSpecific/layout/footer/layoutFooter";
import React, { } from "react";
import { useNavigate } from "react-router-dom";

const LandingFooter: React.FC = () => {   
    
    return (
        <div className="landing__footer">
          <LayoutFooter />
        </div>
    );
};

export default LandingFooter;