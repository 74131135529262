export interface Role {
  name: string;
  game: string;
  assigned: boolean;
}

export const mockRoles: Role[] = [
  { name: "IGL", game: "CS2", assigned: false },
  { name: "Lurker", game: "CS2", assigned: false },
  { name: "AWPer", game: "CS2", assigned: false },
  { name: "Support", game: "CS2", assigned: false },
  { name: "Entry fragger", game: "CS2", assigned: false },
  { name: "In-Game Leader", game: "PUBG", assigned: false },
  { name: "Entry Fragger", game: "PUBG", assigned: false },
  { name: "Sniper", game: "PUBG", assigned: false },
  { name: "Healer", game: "PUBG", assigned: false },
  { name: "Top Lane", game: "LOL", assigned: false },
  { name: "Jungle", game: "LOL", assigned: false },
  { name: "Mid Lane", game: "LOL", assigned: false },
  { name: "Bot Lane", game: "LOL", assigned: false },
  { name: "Support", game: "LOL", assigned: false },
];
