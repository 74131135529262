import { Container } from "react-bootstrap";
import { ReactComponent as ShareIcon } from "../../../../../src/assets/actions/chevron-right.svg";
import { Countdown } from "@/components/Countdown";
import useViewportWidth from "@/hooks/useViewportWidth";

export const BestGamerHomePageHeading = () => {
  const startDate = "2025-07-28T12:34:56Z"; // START DATE OF BEST GAMER
  let dateObj = new Date(startDate);
  let DateToS = dateObj.getTime() / 1000;

  const isMobile = useViewportWidth(667);

  return (
    <Container>
      <div className="head">
        <div className="head__content">
          <div className="content">
            <p className="content__date">15. februar 2025</p>
            <h2 className="content__location">Rødovre Centrum, Danmark</h2>
            <div className="activity__countdown">
              <Countdown
                type=""
                startDate={{
                  seconds: Date.now(),
                  nanoseconds: 0,
                }}
                endDate={{
                  seconds: DateToS,
                  nanoseconds: 0,
                }}
                heading={false}
                showDays={isMobile ? false : true}
              />
            </div>

            {/* <div className="content__linkto">
              <a> See us in Copenhagen</a>
              <ShareIcon />
            </div> */}
          </div>
        </div>
      </div>
    </Container>
  );
};
