import { TeamCard } from "@/features/team";

export default function ProfileSingleTeam(props: any) {
  const { games, isFavorite, name, slug, teamId, teamLogo } = props.props;
  return (
    <TeamCard
      firstFourParticipantsLogos={[{ slug: "demo", logoUrl: teamLogo }]}
      memberCountCalculated={2}
      games={games}
      isFavorite={isFavorite}
      name={name}
      slug={slug}
      teamId={teamId}
      teamLogo={teamLogo}
    />
  );
}
