import firebase from "firebase";

export const _deleteJunction = async (
  collectionName: string,
  docId: string,
  arrayName: string,
  id: string
) => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(docId)
    .set(
      {
        [arrayName]: firebase.firestore.FieldValue.arrayRemove(id),
      },
      { merge: true }
    );
};
