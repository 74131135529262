// import BaseModalWindow from "@components/BaseWrappers/BaseModalWindow";
// import { addNewDiscussion } from "@utils/gathers/gatherUtils";
// import { icon_camera_light, icon_close } from "@utils/images/images";
// import { getImgixUrlProps } from "@utils/shared/generalUtils";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import React, { FC, useState } from "react";
import { Image, Button } from "react-bootstrap";

const SingleGatherCreateDiscussionModal: FC<{
  gatherSlug: string;
  setUpdateTrigger: any;
  openPostField: boolean;
  setOpenPostField: any;
}> = (props): JSX.Element => {
  const [newDiscussion, setNewDiscussion] = useState<{
    subject: string;
    details: string;
    mediaUrls: [];
    isEdited: boolean;
  }>();
  const [openPostField, setOpenPostField] = useState<boolean>(
    props.openPostField
  );

  const handleRemoveImage = (image: string) => {
    const newArray = newDiscussion?.mediaUrls as string[];
    const loc = newArray.indexOf(image);
    if (loc === 0) {
      newArray.shift();
    }
    if (loc) {
      newArray.splice(loc, 1);
    }
    return setNewDiscussion((prev: any) => ({
      ...prev,
      mediaUrls: [...newArray],
    }));
  };
  const handleNewDiscussion = async () => {
    if (newDiscussion && newDiscussion.subject && newDiscussion.details)
      // await addNewDiscussion(
      //   props.gatherSlug,
      //   newDiscussion.subject,
      //   newDiscussion.details,
      //   newDiscussion.mediaUrls
      // );
      setNewDiscussion((prev: any) => ({
        ...prev,
        subject: "",
        details: "",
        mediaUrls: [],
      }));
    props.setOpenPostField(false);
    setOpenPostField(false);
  };
  return (
    <BaseModalWindow
      show={props.openPostField || openPostField}
      closeModal={() => {
        setOpenPostField(false);
        props.setOpenPostField(false);
      }}
    >
      {openPostField && (
        <div className="discussion-modal">
          <div className="discussion-modal__fields">
            {/* Subject */}
            <div className="discussion-modal__subject">
              <label htmlFor="subject">
                <h4>Subject</h4>
                <input
                  className="base-input"
                  type="text"
                  id="subject"
                  value={newDiscussion?.subject}
                  placeholder="Start typing..."
                  onChange={(e) => {
                    setNewDiscussion((prev: any) => ({
                      ...prev,
                      subject: e.target.value,
                    }));
                  }}
                />
              </label>
            </div>
            {/* Details */}
            <div className="discussion-modal__details">
              <label htmlFor="Details">
                <h4>Details</h4>
                <textarea
                  className="base-input"
                  value={newDiscussion?.details}
                  id="Details"
                  placeholder="Start typing..."
                  onChange={(e) => {
                    setNewDiscussion((prev: any) => ({
                      ...prev,
                      details: e.target.value,
                    }));
                  }}
                />
              </label>
            </div>
          </div>
          {/* Images */}
          <ul className="discussion-modal__images">
            <label htmlFor="Image" className="cursor-pointer">
              <picture className="adaptive">
                {/* <Image width={22} height={18} src={icon_camera_light} alt="" /> */}
              </picture>

              <input
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (newDiscussion?.mediaUrls) {
                      setNewDiscussion((prev: any) => ({
                        ...prev,
                        mediaUrls: [
                          ...newDiscussion.mediaUrls,
                          URL.createObjectURL(e?.target?.files![0]),
                        ],
                      }));
                    } else {
                      setNewDiscussion((prev: any) => ({
                        ...prev,
                        mediaUrls: [URL.createObjectURL(e?.target?.files![0])],
                      }));
                    }
                  }
                }}
                className="base-input d-none"
                type="file"
                multiple
                id="Image"
                placeholder="Start typing..."
              />
            </label>
            <ul className="discussion-modal__selected-images">
              {newDiscussion &&
                newDiscussion.mediaUrls &&
                newDiscussion.mediaUrls.length !== 0 &&
                newDiscussion.mediaUrls.map((url: string) => {
                  return (
                    <li key={newDiscussion.subject}>
                      <picture className="adaptive">
                        <figure className="adaptive-photo">
                          {/* <Image
                            width={50}
                            height={50}
                            src={
                              url?.includes("wog.imgix")
                                ? `${url}?${getImgixUrlProps({
                                    w: "50",
                                    h: "50",
                                  })}`
                                : url
                            }
                            alt=""
                          /> */}
                        </figure>
                        <Image
                          onClick={() => {
                            handleRemoveImage(url);
                          }}
                          width={25}
                          height={25}
                          // src={icon_close}
                          className="deleteBtn position-absolute"
                        />
                      </picture>
                    </li>
                  );
                })}
            </ul>
          </ul>
          <div className="discussion-modal__actions">
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setNewDiscussion((prev: any) => ({
                  ...prev,
                  subject: "",
                  details: "",
                  mediaUrls: [],
                }));
                setOpenPostField(false);
                props.setOpenPostField(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant={
                !newDiscussion?.subject || !newDiscussion?.details
                  ? "secondary"
                  : "primary"
              }
              type="button"
              disabled={!newDiscussion?.subject || !newDiscussion?.details}
              onClick={async () => {
                await handleNewDiscussion().then(() => {
                  setTimeout(() => {
                    props.setUpdateTrigger((prev: number) => prev + 1);
                  }, 2000);
                });
                setOpenPostField(false);
                props.setOpenPostField(false);
              }}
            >
              Comment
            </Button>
          </div>
        </div>
      )}
    </BaseModalWindow>
  );
};

export default SingleGatherCreateDiscussionModal;
