import { ReactComponent as Feed } from "../assets/icon_feed.svg";
import { ReactComponent as Friends } from "../assets/icon_friends.svg";
import { ReactComponent as Messages } from "../assets/icon_messages.svg";
import { ReactComponent as Zones } from "../assets/icon_zones.svg";
import { ReactComponent as Cup } from "../assets/icon_tournaments.svg";
import { ReactComponent as Gathers } from "../assets/icon_gathers.svg";
import { ReactComponent as News } from "../assets/icon_news.svg";
import { ReactComponent as Teams } from "../assets/icon_teams.svg";
import { ReactComponent as ChallengesLeft } from "../assets/challenges.svg";
import { ReactComponent as TrackerApp } from "../assets/flag.svg";
import { siteRoutes } from "../../../routes/siteRoutesList";
import {
  brand,
  scheduleData,
} from "../../../utils/brandVariables/brandVariables";

export const routes = {
  social: [
    brand.hasFeature.feed && {
      title: "strings.feed",
      link: siteRoutes["dashboard"],
      logo: Feed,
    },
    // { title: "Friends", link: "friends", logo: Friends },
    brand.hasFeature.messages && {
      title: "Messages",
      link: siteRoutes["messages"],
      logo: Messages,
    },

    brand.hasFeature.teams && {
      title: "strings.teams",
      link: siteRoutes["teams"],
      logo: Teams,
    },
    // { title: "Zones", link: "zones", logo: Zones },
    brand.hasFeature.news && {
      title: "strings.news",
      link: siteRoutes["news"],
      logo: News,
    },
  ],
  gaming: [
    brand.hasFeature.challenges && {
      title: "strings.challenges",
      link: siteRoutes["challenges"],
      logo: ChallengesLeft,
    },
    // brand.hasFeature.challenges && {
    //   title: "Challenges alternative",
    //   link: siteRoutes["newchallenges"],
    //   logo: ChallengesLeft
    // },
    brand.hasFeature.getstarted && {
      title: "Getting started",
      link: "/tracker",
      logo: TrackerApp,
    },
    brand.hasFeature.gathers && {
      title: "strings.gathers",
      link: siteRoutes["gathers"],
      logo: Gathers,
    },
    brand.hasFeature.dailytasks && {
      title: "Daily tasks",
      link: siteRoutes["dailytasks"],
      logo: undefined,
    },
    brand.hasFeature.games && {
      title: "Event games",
      link: siteRoutes["gamesPage"],
      logo: undefined,
    },
  ],
  winners: [
    brand.hasFeature.winners && {
      title: "Winners",
      link: siteRoutes["winners"],
      logo: Cup,
    },
  ],
  [scheduleData?.sideMenuTitle || ""]: [
    brand.hasFeature.schedule && {
      title: "Schedule",
      link: siteRoutes["schedule"],
      logo: undefined,
    },
  ],

  // esports: [
  //   { title: "Event QR code", link: "event-qr-code", logo: TournamentsLeft },
  // ],
  // WOG: [
  // { title: "Partners", link: "partners", logo: Partners },
  // { title: "Event QR code", link: "about", logo: TournamentsLeft },
  // { title: "Feedback", link: "help", logo: Help },
  // ],
};

export const routesForCreateButton = [
  brand.hasFeature.challenges && {
    title: "Challenges",
    link: siteRoutes["createChallenge"],
    logo: ChallengesLeft,
  },
  brand.hasFeature.teams && {
    title: "Teams",
    link: siteRoutes["createTeam"],
    logo: Teams,
  },
  brand.hasFeature.gathers && {
    title: "Gathers",
    link: siteRoutes["createGather"],
    logo: Gathers,
  },
];
