import { FC } from "react";
import TrackerAlert from "./trackerAlert";

const TrackerStepOne: FC = (): JSX.Element => {
  return (
    <div className="steps__1">
      <div className="steps__item">
          <span className="border-cut" />
          <div className="steps__number">
            <span>1</span>
          </div>
          <div className="steps__content">
            <h2>Download and install the tracker</h2>
            <p>
              The tracker is needed on the device you are playing on. It will gather match data for the leaderboards and automatically submit it to our platform.
            </p>
            <ol>
              <li>
                To get the latest version of the tracker, click on the download button to get the tracker installer. Make sure to save the file to a location you can easily access.

                <TrackerAlert />
              </li>
              <li>
                Open the downloaded file and follow the installation wizard instructions. The installation process is straightforward, and the tracker will be ready in no time.
              </li>
            </ol>
            <small>Game Tracker is not needed to track Dota2.</small>
        </div>
      </div>
    </div>
  );
};

export default TrackerStepOne;