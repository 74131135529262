import { collectionRef } from "@/api/collectionRef/collectionOperations";

export async function _validateIfEmailAlreadyExistInDB(email: string) {
  const profileDocs = await collectionRef.profiles
    .where("emailAddress", "==", email)
    .get();
  if (profileDocs.empty) {
    return false;
  }
  return true;
}
