import firebase from "firebase/app";
export async function _deleteRealtimeDoc(
  collectionName: string,
  docId: string,
  whereStart?: string,
  whereEnd?: string
) {
  firebase.database().ref(collectionName).child(docId).update({
    read: true,
    archived: false,
  });
}
