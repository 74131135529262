import React, { FC, useState } from "react";
import { v4 } from "uuid";
import { Link } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import { SingleTeamOverviewCardProps } from "../../types/TeamTypes";
import { getDifferenceInTime } from "@/utils/constants/formatDate";
import { Badge, Button } from "@/components";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { SingleTeamSeeAllModal } from "../modals/SingleTeamSeeAllModal";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import BaseUserAvatar from "@/components/BaseUserAvatar";

const SingleTeamMembers: FC<SingleTeamOverviewCardProps> = ({
  teamData,
  membersData,
  isPreview,
  setUpdateTrigger,
  userRole,
}): JSX.Element => {
  const [addMemberModalOpen, setAddMemberModalOpen] = useState<boolean>(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [seeAllMembersModalOpen, setSeeAllMembersModalOpen] =
    useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  if (seeAllMembersModalOpen) {
    blockScroll();
  } else {
    allowScroll();
  }
  type MemberProps = {
    logoUrl: string;
    slug: string;
    fullName: string;
    userName: string;
    role: string;
    monthlyPoints: number;
    joinedTeamTimestamp: any;
    inTeamRole: string;
  };

  // Conditions
  const hasMembers = membersData && membersData.length !== 0;
  const membersCount = teamData.currentMembersNumber;
  const hasAdminRights = userRole === "owner" || userRole === "admin";
  const hasMoreThanFourMembers = membersCount > 4;

  return (
    <>
      {(hasMoreThanFourMembers || hasAdminRights) && (
        <BaseModalWindow
          show={seeAllMembersModalOpen}
          closeModal={() => setSeeAllMembersModalOpen(false)}
          title="Team members"
        >
          <SingleTeamSeeAllModal
            teamData={teamData}
            userRole={userRole}
            setSeeAllMembersModalOpen={undefined}
            setAddMemberModalOpen={undefined}
            isFullPage={false}
            setUpdateTrigger={setUpdateTrigger}
          />
        </BaseModalWindow>
      )}

      {/* Heading */}
      <div className="team-members__heading">
        <h2>
          Members
          <sup>{membersCount}</sup>
        </h2>
        <div className="team-members__actions">
          {(hasMoreThanFourMembers || hasAdminRights) && (
            <>
              {!isPreview && (
                <Button
                  type="button"
                  variant="secondary"
                  customStyles="btn-sm team-members__btn team-members__btn--desktop"
                  onClickAction={() => {
                    if (!isPreview) {
                      setSeeAllMembersModalOpen(true);
                    }
                  }}
                >
                  {hasAdminRights ? "Manage members" : "See all"}
                </Button>
              )}
            </>
          )}
          <Link to={`${siteRoutes["teamsMembersAndSlug"]}${teamData.slug}`}>
            <Button
              variant="secondary"
              type="button"
              customStyles="btn-sm team-members__btn team-members__btn--mobile"
            >
              See all
            </Button>
          </Link>
        </div>
      </div>

      {/* List of members */}
      <ul className="team-members__list">
        <li
          className="team-members__item team-members__item--legend"
          key="team-members__legend"
        >
          <div className="legend">
            <div className="legend__user">User</div>
            <div className="legend__role">Role</div>
            <div className="legend__time">On team</div>
          </div>
        </li>
        {hasMembers ? (
          membersData.slice(0, isPreview ? 1 : 5).map((member: MemberProps) => {
            const isTeamRoleUnset =
              member.inTeamRole === null ||
              member.inTeamRole === "New" ||
              member.inTeamRole === "new";

            return (
              <li className="team-members__item" key={v4()}>
                <div className="team-members__member">
                  <div className="team-members__profile">
                    <Link to={`/profile/${member.slug}`}>
                      <div className="team-members__avatar">
                        <BaseUserAvatar
                          logoUrl={member.logoUrl}
                          userName={member.userName}
                        />
                      </div>
                      <div className="team-members__name">
                        <span className="team-members__username">
                          {isPreview ? currentUser.userName : member.userName}
                        </span>
                        <span className="team-members__fullname">
                          {isPreview ? currentUser.fullName : member.fullName}
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="team-members__role">
                    <div className="badge-wrapper">
                      {isTeamRoleUnset ? (
                        <Badge variant="secondary">Unassigned</Badge>
                      ) : (
                        <Badge variant="primary">{member.inTeamRole}</Badge>
                      )}
                    </div>
                  </div>
                  <div className="team-members__time">
                    {member?.joinedTeamTimestamp?._seconds && (
                      <time>
                        {getDifferenceInTime(
                          new Date(Date.now()).toISOString(),
                          new Date(
                            member?.joinedTeamTimestamp?._seconds * 1000
                          ).toISOString()
                        )}
                      </time>
                    )}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <></>
        )}
      </ul>
      {/* </div> */}
    </>
  );
};

export default SingleTeamMembers;
