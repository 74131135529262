import QueryDB from "@/api/queryDB/common";
import { Button } from "@/components";
import React, { FC, useState } from "react";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";

export const TeamNotificationsSettings: FC<{
  userRole: "owner" | "admin" | "random" | "member";
  team: SingleTeamResponseTypes;
  setUpdateTrigger: any;
}> = ({ userRole, team, setUpdateTrigger }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const isAdminOrOwner = userRole === "admin" || userRole === "owner";
  const [notificationsSettings, setNotificationSettings] = useState(
    isAdminOrOwner
      ? {
          all: Object.values(team.notificationSettings).every(
            (value) => value === true
          ),
          memberRequests: team.notificationSettings.memberRequests,
          newMembers: team.notificationSettings.newMembers,
          newPostsInFeed: team.notificationSettings.newPostsInFeed,
          newComntsInFeed: team.notificationSettings.newComntsInFeed,
          upcomingEvents: team.notificationSettings.upcomingEvents,
        }
      : {
          all: Object.values(team.notificationSettings).every(
            (value) => value === true || value === null
          ),
          newMembers: team.notificationSettings.newMembers,
          newPostsInFeed: team.notificationSettings.newPostsInFeed,
          newComntsInFeed: team.notificationSettings.newComntsInFeed,
          upcomingEvents: team.notificationSettings.upcomingEvents,
          memberRequests: false,
        }
  );
  const { all, ...rest } = notificationsSettings;

  const update = async () => {
    // Exclude all toggle
    const { all, ...rest } = notificationsSettings;

    await QueryDB.updateDoc(
      "userTeamJunction",
      `${currentUser.uid}_${team.slug}`,
      {
        notificationSettings: { ...rest },
      }
    )
      .then(() => {
        createToast({
          type: "success",
          message: "Updated notification settings",
        });
        setUpdateTrigger((prev: number) => prev + 1);
      })
      .catch((err) => {
        createToast({
          type: "error",
          message: "Failed to update notification settings",
        });
      });
  };

  const setNotificationState = (targetName: string, state: boolean) => {
    if (targetName !== "all") {
      setNotificationSettings((prev) => ({
        ...prev,
        all: false,
      }));
    }
    if (targetName === "all") {
      setNotificationSettings(
        isAdminOrOwner
          ? {
              all: !notificationsSettings.all,
              newMembers: !notificationsSettings.all,
              newPostsInFeed: !notificationsSettings.all,
              newComntsInFeed: !notificationsSettings.all,
              upcomingEvents: !notificationsSettings.all,
              memberRequests: !notificationsSettings.all,
            }
          : {
              all: !notificationsSettings.all,
              newMembers: !notificationsSettings.all,
              newPostsInFeed: !notificationsSettings.all,
              newComntsInFeed: !notificationsSettings.all,
              upcomingEvents: !notificationsSettings.all,
              memberRequests: false,
            }
      );
    } else {
      const allFalse = Object.entries(notificationsSettings).every(
        ([key, value]) => key === targetName || value === false
      );
      setNotificationSettings((prev) => ({
        ...prev,
        [targetName]: !state,
        all: allFalse ? false : prev.all,
      }));
    }
  };

  const options = [
    isAdminOrOwner
      ? {
          title: "Members request ( admins only )",
          checked:
            notificationsSettings.all || notificationsSettings.memberRequests,
          fieldName: "memberRequests",
          state: notificationsSettings.memberRequests,
        }
      : {},
    {
      title: "New members",
      checked: notificationsSettings.all || notificationsSettings.newMembers,
      fieldName: "newMembers",
      state: notificationsSettings.newMembers,
    },
    {
      title: "New posts in feed",
      checked:
        notificationsSettings.all || notificationsSettings.newPostsInFeed,
      fieldName: "newPostsInFeed",
      state: notificationsSettings.newPostsInFeed,
    },
    {
      title: "New comments in feed",
      checked:
        notificationsSettings.all || notificationsSettings.newComntsInFeed,
      fieldName: "newComntsInFeed",
      state: notificationsSettings.newComntsInFeed,
    },
    {
      title: "Upcoming events",
      checked:
        notificationsSettings.all || notificationsSettings.upcomingEvents,
      fieldName: "upcomingEvents",
      state: notificationsSettings.upcomingEvents,
    },
  ];
  return (
    <div className="content__notifications">
      <label
        className="form-check-label"
        htmlFor="all"
        aria-hidden
        onClick={() => {
          if (notificationsSettings.all) {
            setNotificationState("all", false);
          } else {
            setNotificationState("all", true);
          }
        }}
      >
        <span>Get all notifications</span>
        <input
          className="form-check-input"
          type="checkbox"
          id="toggle"
          checked={notificationsSettings.all || false}
        />
      </label>
      {options.map((option) => {
        if (option.title) {
          return (
            <label
              className="form-check-label"
              htmlFor={option.fieldName}
              aria-hidden
              onClick={() => {
                setNotificationState(option.fieldName, option.state);
              }}
            >
              <span>{option.title}</span>
              <input
                className="form-check-input"
                type="checkbox"
                id="toggle"
                checked={option.checked}
              />
            </label>
          );
        }
      })}

      <div>
        <Button variant="primary" onClickAction={() => update()}>
          Save changes
        </Button>
      </div>
    </div>
  );
};
