import { FC } from "react";

const TeamsOverviwLoader: FC = (): JSX.Element => {
  return (
    <>
      <div className="team-card placeholder">
        <div className="team-card__image">
          <div className="image wave">
            <div className="image__content" />
          </div>
        </div>
        <div className="team-card__meta">
          <div className="team-card__heading">
            <h5 className="wave" />
          </div>
          <ul className="team-card__badges">
            <li className="wave" key="wave" />
          </ul>
        </div>
      </div>
    </>
  );
};

export default TeamsOverviwLoader;
