import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import {
  lastDiscoverDocAtom,
  lastFinishedDocAtom,
  lastMineDocAtom,
  lastOngoingDocAtom,
} from "../utils/ChallengeItemAtom";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import firebase from "firebase";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import {
  challengesMineAtom,
  challengesNewAtom,
} from "@/utils/atoms/challengesAtoms/challengesAtom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import useProfile from "./useProfile";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";

interface ReturnValues {
  challengeData: any[];
  isLoading?: boolean;
  loadMoreChallenges: () => void;
  fetchNew: () => void;
  hasMore: boolean;
}

export function useChallengesv2({
  filters,
}: {
  filters: {
    game: string;
    status: string;
    createdBy: "discover-new" | "mine" | "zone-activity";
  };
}): ReturnValues {
  const [challengesNewAtomData, setChallengesNewAtomData] =
    useAtom(challengesNewAtom);
  const [challengesMineAtomData, setChallengesMineAtomData] =
    useAtom(challengesMineAtom);

  const challengesItems =
    filters.createdBy === "mine"
      ? challengesMineAtomData
      : challengesNewAtomData;

  const [lastDiscoverDoc, setLastDiscoverDoc] = useAtom(lastDiscoverDocAtom);
  const [lastMineDoc, setLastMineDoc] = useAtom(lastMineDocAtom);
  const [isQueryRunning, setIsQueryRunning] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const status = filters.status;
  const [brands, setBrands] = useState([brand.brandValue]);
  const brandArray = brand.subClientsForOverviews
    ? [brand.brandValue, ...brand.subClientsForOverviews]
    : [brand.brandValue];

  useEffect(() => setBrands(brandArray), [brand]);

  const loadMoreChallenges = () => {
    if (!isQueryRunning) {
      setLoadMore(true);
    }
  };

  const fetchChallenges = async (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>,
    isFirstRun: boolean
  ) => {
    console.log("running");
    if (isLoading || isQueryRunning) {
      return;
    }

    setIsQueryRunning(true);
    setIsLoading(true);
    const QueryResponse = await query.get();

    if (QueryResponse && QueryResponse.docs.length < 10) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    const formattedResponse: any[] = await Promise.all(
      QueryResponse.docs.map(async (doc) => {
        const author = await getAuthorProfileBasedOnUid(
          doc.data().createdBy,
          doc.data().client
        );
        return { author: author, id: doc.id, ...doc.data() };
      })
    );

    if (filters.createdBy === "discover-new") {
      // @ts-ignore
      setLastDiscoverDoc(QueryResponse.docs[QueryResponse.docs.length - 1]);
      setChallengesNewAtomData((prevItems) =>
        isFirstRun ? formattedResponse : [...prevItems, ...formattedResponse]
      );
    }

    if (filters.createdBy === "mine") {
      // @ts-ignore
      setLastMineDoc(QueryResponse.docs[QueryResponse.docs.length - 1]);
      setChallengesMineAtomData((prevItems) =>
        isFirstRun ? formattedResponse : [...prevItems, ...formattedResponse]
      );
    }

    setIsLoading(false);
    setLoadMore(false);
    setIsQueryRunning(false);
  };

  useEffect(() => {
    if (!isQueryRunning) {
      let query = collectionRef.challenge
        .where("status", "==", filters.status)
        .where("client", "in", brands);

      if (filters.game) {
        query = query.where("game", "==", filters.game);
      }

      if (filters.createdBy === "discover-new") {
        query = query.where("createdBy", "!=", currentUser.uid);
      }

      if (filters.createdBy === "mine") {
        query = query.where("createdBy", "==", currentUser.uid);
      }

      query = query.orderBy("createdBy").orderBy("startDate", "desc").limit(10);

      fetchChallenges(query, true);
    }
  }, [filters.createdBy, filters.game, filters.status, updateTrigger]);

  useEffect(() => {
    if (loadMore && !isQueryRunning) {
      let query = collectionRef.challenge
        .where("status", "==", filters.status)
        .where("client", "in", brands);

      if (filters.game) {
        query = query.where("game", "==", filters.game);
      }

      if (filters.createdBy === "discover-new") {
        query = query.where("createdBy", "!=", currentUser.uid);
      }

      if (filters.createdBy === "mine") {
        query = query.where("createdBy", "==", currentUser.uid);
      }

      query = query.orderBy("createdBy").orderBy("startDate", "desc").limit(10);
      query = query.startAfter(
        filters.createdBy === "discover-new" ? lastDiscoverDoc : lastMineDoc
      );

      fetchChallenges(query, false);
    }
  }, [loadMore]);

  const fetchNewChallenges = () => {
    setUpdateTrigger((prev) => prev + 1);
  };

  return {
    challengeData: challengesItems,
    isLoading,
    loadMoreChallenges,
    fetchNew: fetchNewChallenges,
    hasMore,
  };
}
