import { Button } from "@/components";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { siteRoutes } from "../../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

export const BestgameLandingNewsLetter: FC<{}> = ({}) => {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${siteRoutes["signUp"]}/${encodeURI(email)}`);
  };

  return (
    <div className="newsletter container">
      <div className="newsletter__content">
        <h2>gamers! see you soon</h2>
        <p>
          Sign up early to take a look at our activities and receive updates
          about the Best Gamer tour schedule
        </p>
        <div className="newsletter__actions">
          <BaseTextInput
            textType={"text"}
            setSingleState={setEmail}
            placeholder="yourEmail@gmail.com"
          />

          <Button variant="primary" onClickAction={() => handleClick()}>
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};
