import { GatherCard } from "@/features/gather";

export default function ProfileSingleGather(props: any) {
  const {
    games,
    gatherId,
    interestedInCount,
    isFavoriteByCurrentUser,
    logoUrl,
    meetType,
    name,
    participantsCount,
    slug,
    startDate,
    generalRole,
  } = props.props;

  return (
    <div className="profile__single-gather">
      {" "}
      <GatherCard
        generalRole={generalRole}
        isPlaceholder={false}
        games={games}
        gatherId={gatherId}
        interestedInCount={interestedInCount}
        isFavoriteByCurrentUser={isFavoriteByCurrentUser}
        logoUrl={logoUrl}
        meetType={meetType}
        name={name}
        participantsCount={participantsCount}
        slug={slug}
        startDate={startDate}
      />
    </div>
  );
}
