import React, { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import BaseVideoTagWrapper from "../BaseWrappers/BaseVideoTagWrapper";
import { ReactComponent as CloseIcon } from "../../assets/actions/close.svg";

const ImageInputPreview: React.FunctionComponent<{
  funcToRemove: () => void;
  file?: File | null;
  url?: string;
}> = ({ funcToRemove, file, url }) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string | undefined>(undefined);

  useEffect(() => {
    // create the preview
    if (file) {
      setType(file?.type?.slice(0, 5));
      const objectUrl = URL.createObjectURL(file);

      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
    setType(undefined);
  }, [file]);

  return (
    <div className="attachment">
      {preview ? (
        type === "video" ? (
          <div className="attachment__video">
            <div className="video">
              <div className="video__content">
              <BaseVideoTagWrapper
                videoSrc={preview}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="attachment__image">
            <div className="image">
              <div className="image__content">
                <Image
                  src={preview || url}
                  alt="Uploaded image"
                />
              </div>
            </div>
            <Button
              variant="link"
              type="button"
              onClick={() => funcToRemove()}
              className="attachment__cancel"
            >
              <CloseIcon width={26} height={26} />
            </Button>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default ImageInputPreview;
