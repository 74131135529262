import { FC } from "react";

const ChallengeOverviwLoader: FC = (): JSX.Element => {
  return (
    <>
      <div className="challenge-card placeholder">
        <div className="challenge-card__content">
          <div className="challenge-card__image">
            <div className="image">
              <div className="image__content" />
              <div className="challenge-card__game">
                <span className="challenge-card__game--text wave" />
              </div>
            </div>
          </div>
          <div className="challenge-card__heading">
            <h5 className="wave" />
            <div className="challenge-card__creator">
              <div className="challenge-card__avatar">
                <div className="image">
                  <div className="image__content wave" />
                </div>
              </div>
              <div>
                <p className="challenge-card__username wave" />
              </div>
            </div>
            <ul className="challenge-card__criteria">
              <li className="wave" key="challenge-card_key" />
            </ul>
          </div>
        </div>
        <div className="challenge-card__footer">
          <div>
            <div className="challenge-card__button wave" />
          </div>
          <div className="challenge-card__progress">
            <label />
            <progress value="10" max="100" className="challenges_overview">
              10%
            </progress>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeOverviwLoader;
