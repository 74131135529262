import { Profile } from "@/models/database/profiles/profile.model";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type UserProfileType = {
  user: Profile;
  setUser: (newUser: Profile | null) => void;
};
export const useCurrentUserProfile = create(
  persist<UserProfileType>(
    (set) => ({
      user: null as unknown as Profile,
      setUser: (profile) =>
        set(() => ({
          user: profile as Profile,
        })),
    }),
    {
      name: "firebase_currentUser_profile",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
