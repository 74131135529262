import { FC, useState, useEffect } from "react";
import { locationOptions } from "../../utils/locationOptions";
import GoogleMap from "../../helperFunctions/GoogleMap";
import SingleGatherCreator from "./SingleGatherCreator";
import copy from "@/assets/actions/copyIcon.svg";
import { createToast } from "@/utils/toaster/createToast";
import { Button } from "@/components/Button";

const SingleGatherLocationCreator: FC<{
  gatherSlug: string;
  isPlaceholder?: boolean;
  meetType?: "offline" | "online";
  meetTypeSecondary?: string;
  meetUrlAddress?: string;
}> = (props): JSX.Element => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`${
        props.meetType === "online" ? "display--row" : "display--column"
      }`}
    >
      {props.meetType === "online" ? (
        <div className="Single-gather__Location--online">
          {!isMobile ? (
            <div className="Single-gather__meet">
              <div>
                <h3>Link</h3>
              </div>
              <div
                className="Single-gather__meet--logo "
                aria-hidden
                onDoubleClick={() => {
                  navigator.clipboard.writeText(
                    props.meetType === "online"
                      ? props.meetUrlAddress!
                      : props.meetUrlAddress!.split("?")[0]
                  );
                  createToast({
                    type: "success",
                    message: "Copied to clipboard",
                  });
                }}
              >
                <>
                  <figure className=" adaptive ">
                    <picture
                      className="adaptive-photo"
                      style={{ height: "100%" }}
                    >
                      <img
                        width={75}
                        height={75}
                        src={
                          locationOptions.find(
                            (obj: any) =>
                              obj.platform === props.meetTypeSecondary
                          )?.logo || ""
                        }
                      />
                    </picture>
                  </figure>
                  <p>Copy to clipboard</p>
                </>
              </div>
            </div>
          ) : (
            <div className="Single-gather__Location">
              <h3>Location</h3>
              <div>
                <div className="Single-gather__meet--small">
                  <p>
                    {
                      locationOptions.find(
                        (obj: any) => obj.platform === props.meetTypeSecondary
                      )?.platform
                    }{" "}
                  </p>
                  <div
                    aria-hidden
                    className="Single-gather__link"
                    placeholder={props.meetUrlAddress}
                    onClick={() => {
                      navigator.clipboard.writeText(props.meetUrlAddress || "");
                      createToast({
                        type: "success",
                        message: "Copied to clipboard",
                      });
                    }}
                  >
                    <div className="Single-gather__link--copy">
                      <p>{props.meetUrlAddress}</p>
                    </div>
                    <div className="Single-gather__link--image">
                      <Button variant="">
                        <img src={copy} alt="copy" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="Single-gather__Loction--offline">
          <div>
            <h3>Location</h3>
          </div>
          <div className="Single-gather__map">
            <div id="map-container-sm">
              <GoogleMap location={props.meetUrlAddress || ""} />
            </div>
          </div>
        </div>
      )}

      <div
        className={`${
          props.meetType === "online"
            ? "Single-gather__creator--large"
            : "Single-gather__creator--small"
        }`}
      >
        <h3>Creator</h3>
        <SingleGatherCreator gatherSlug={props.gatherSlug} />
      </div>
    </div>
  );
};
export default SingleGatherLocationCreator;
