import React, { FC, useState } from "react";
import lock from "../../../../assets/icons/Private_lock.svg";
import { SingleTeamOverviewCardProps } from "../../types/TeamTypes";
import { SingleTeamGeneralInfo } from "./SingleTeamGeneralInfo";
import SingleTeamMembers from "./SingleTeamMembers";
import { SingleTeamFeedFetcher } from "./SingleTeamFeed.fetcher";

export const SingleTeamUi: FC<SingleTeamOverviewCardProps> = ({
  teamData,
  membersData,
  potmData,
  isPreview,
  setUpdateTrigger,
  setFeedUpdateTrigger,
  userRole,
  communityData,
  setSettingsOpen,
}): JSX.Element => {
  useState<boolean>(false);

  // Conditions
  const isPublic = teamData?.type === "public";
  const isPrivate = teamData?.type === "private";
  const isUserMember = teamData?.isCurrUserMember;
  const isUserAccepted = teamData?.currUserStatus === "accepted";
  const isOwnerOrAdmin = userRole === "owner" || userRole === "admin";

  return (
    <div className="single-team">
      {/* LEFT/ GENERAL INFO +  */}
      <div className="single-team__card">
        {teamData && (
          <SingleTeamGeneralInfo
            membersData={membersData}
            isPreview={false}
            userRole={userRole}
            setUpdateTrigger={setUpdateTrigger}
            teamData={teamData}
            communityData={communityData}
            setSettingsOpen={setSettingsOpen}
          />
        )}
      </div>

      {/* MIDDLE / FEED + MEMBERS */}
      {isPublic || (isPrivate && isPreview) || isUserAccepted ? (
        <div className="single-team__content">
          <div className="single-team__members">
            <SingleTeamMembers
              isPreview={isPreview}
              userRole={userRole}
              setUpdateTrigger={setUpdateTrigger}
              teamData={teamData}
              membersData={membersData}
              communityData={communityData}
            />
          </div>
          {isUserMember && (
            <div className="posts">
              <h3>Team feed</h3>
              <SingleTeamFeedFetcher
                communityData={communityData}
                slug={teamData.slug}
                isAuthorized={isOwnerOrAdmin}
                teamData={teamData}
                isPreview={isPreview}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      {/* IS PRIVATE MARKER */}

      {isPrivate && !isPreview && !isUserMember ? (
        <div className="single-team__private">
          <figure>
            <img src={lock} alt="" />
          </figure>
          <p>
            This is a private team. To see the members, upcoming events and news
            and more, please send a request to join the team.
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
