import React, { FC, useEffect } from "react";
import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";

// const AnyReactComponent = () => <div>{text}</div>;
const GoogleMap: FC<{
  location: string;
}> = (props): JSX.Element => {
  const defaultProps = {
    center: {
      lat: Number(props.location.split("?")[1]),
      lng: Number(props.location.split("?")[2]),
    },
    zoom: 16,
  };
  useEffect(() => {
    defaultProps.center.lat = Number(props.location.split("?")[1]);
    defaultProps.center.lng = Number(props.location.split("?")[2]);
  }, [props.location]);
  const loadMap1 = () => {
    const map = new window.google.maps.Map(
      document.getElementById("map-container-lg") as HTMLElement,
      {
        zoom: 18,
        center: defaultProps.center,
      }
    );
    new google.maps.Marker({
      position: defaultProps.center,
      map,
    });
  };

  const loadMap2 = () => {
    const map = new window.google.maps.Map(
      document.getElementById("map-container-sm") as HTMLElement,
      {
        zoom: 18,
        center: defaultProps.center,
      }
    );
    new google.maps.Marker({
      position: defaultProps.center,
      map,
    });
    const externalResource = document.getElementById("googleMapsApi");
    externalResource?.parentNode?.removeChild(externalResource);
  };
  useEffect(() => {
    const getKey = async () => {
      await CloudFunctions.callCloudFunction("getGoogleMapApi", null).then(
        (resp) => {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${resp}&libraries=places`;
          script.async = true;
          script.id = "googleMapsApi";
          script.onload = () => {
            if (document.getElementById("map-container-lg")) loadMap1();
            if (document.getElementById("map-container-sm")) loadMap2();
          };
          document.body.appendChild(script);
        }
      );
    };
    getKey();
  }, []);

  return <div style={{ width: "100%" }} />;
};

export default GoogleMap;
