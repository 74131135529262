import { Spinner } from "@/components/Spinner";
import { EditProfileFormValues } from "@/features/profile/types/EditProfileTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import React, { FC, useEffect, useState } from "react";
import { EditProfileForm } from "./EditProfileForm";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import i18n from "@/tranlations/i18n";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { auth } from "@/api/firebase/firebase.config";
import { VerifiyProfileForm } from "./VerifiyProfileForm";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

export const GeneralSettings: FC<{
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({ setCurrentTab }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const email =
    auth.currentUser?.email || currentUser.email || currentUser.emailAddress;
  const [editProfileValues, setEditProfileValues] =
    useState<EditProfileFormValues>({
      fullName: "",
      userName: "",
      profileDescription: "",
      lang: "",
      termsAccepted: false,
      email:
        auth.currentUser?.email?.replace(
          `${brand.brandValue.toLowerCase()}_`,
          ""
        ) ||
        currentUser?.email?.replace(`${brand.brandValue.toLowerCase()}_`, "") ||
        "",
    });
  const hasNotChanged =
    editProfileValues.fullName === currentUser.fullName &&
    editProfileValues.userName === currentUser.userName &&
    editProfileValues.profileDescription === currentUser.profileDescription &&
    editProfileValues.lang === currentUser.lang &&
    editProfileValues.lang === currentUser.lang &&
    editProfileValues.termsAccepted === currentUser.termsAccepted &&
    (editProfileValues.email === auth.currentUser?.email ||
      editProfileValues.email === currentUser.email);

  useEffect(() => {
    setEditProfileValues({
      fullName: currentUser.fullName || "",
      userName: currentUser.userName,
      profileDescription: currentUser.profileDescription,
      lang: currentUser.lang,
      termsAccepted: currentUser.termsAccepted || false,
      email:
        auth.currentUser?.email?.replace(
          `${brand.brandValue.toLowerCase()}_`,
          ""
        ) ||
        currentUser?.email?.replace(`${brand.brandValue.toLowerCase()}_`, "") ||
        "",
    });
    setLoading(false);
  }, [currentUser]);

  const setFieldValue = async (targetName: string, value: any) => {
    return setEditProfileValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };
  const saveChanges = async () => {
    if (hasNotChanged) {
      return createToast({
        type: "info",
        message: "Nothing to update.",
      });
    }
    setIsSubmitting(true);
    // TODO: CHECK IF THE CURRENT USERNAME and SLUG ISNT TAKEN
    const slug = normalizeString(
      convertCleanEntityNameToSlug(cleanEntityName(editProfileValues.userName))
    );

    await QueryDB.updateDoc("profiles", currentUser.uid, editProfileValues)
      .then(() => {
        refreshUser(setUserProfile, currentUser);
        i18n.changeLanguage(editProfileValues.lang || "eng");
        createToast({
          type: "success",
          message: "Profile updated",
        });
        navigate("/profile");
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to update profile",
        });
      });

    setIsSubmitting(false);
    // ....
  };
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {(brand.authMethod === "email" && !email) ||
          (brand.authMethod === "emailNoVerify" && !email) ||
          (brand.authMethod === "phone" && !auth.currentUser?.phoneNumber) ? (
            <VerifiyProfileForm />
          ) : (
            <>
              <EditProfileForm
                editProfileValues={editProfileValues}
                setFieldValue={setFieldValue}
                errors={errors}
                saveChanges={saveChanges}
                isSubmitting={isSubmitting}
                hasNotChanged={hasNotChanged}
                setCurrentTab={setCurrentTab}
              />
            </>
          )}
        </>
      )}
    </section>
  );
};
