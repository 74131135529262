import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { MembersTypes } from "../../types/MembersTypes";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { SingleTeamSeeAllModal } from "../modals/SingleTeamSeeAllModal";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ErrorFallback, Spinner } from "@/components";

export const SingleTeamSeeAllMembers: FC = (): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState<
    "owner" | "admin" | "random" | "member"
  >("owner");

  const [teamData, _loading, error, fetch_team] = useCloudFunctionCall<
    SingleTeamResponseTypes | any
  >("getSingleTeam", currentUser.slug);

  // Load data
  useEffect(() => {
    const fetch = async () => {
      await fetch_team({
        slug: encodeURIComponent(decodeURIComponent(slug)),
      });
    };

    if (slug) {
      fetch();
    }
  }, [slug]);

  useEffect(() => {
    if (!teamData) {
      return setUserRole("random");
    }
    if (teamData.createdBy === currentUser.uid) {
      return setUserRole("owner");
    }
    if (teamData.isCurrUserAdmin === true) {
      return setUserRole("admin");
    }
    if (teamData.isCurrUserMember === true) {
      return setUserRole("member");
    }
    return setUserRole("random");
  }, [teamData]);

  // Loading
  if (_loading) {
    return <Spinner />;
  }

  // If any errors
  if (error) {
    return (
      <ErrorFallback>
        <h2>Failed to load team's members</h2>

        <div>
          <Button
            onClickAction={() => {
              navigate(-1);
            }}
            variant="primary"
          >
            Back to team
          </Button>
        </div>
      </ErrorFallback>
    );
  }

  // If everything goes according to plan
  if (teamData) {
    return (
      <>
        <h2>{teamData.name}s' members</h2>
        <SingleTeamSeeAllModal
          teamData={teamData}
          userRole={userRole}
          setSeeAllMembersModalOpen={undefined}
          setAddMemberModalOpen={undefined}
          isFullPage
        />
      </>
    );
  }

  // Any other case
  return <></>;
};
