import React, { useState, useCallback, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ActiveFire } from "../../assets/fire-active.svg";
import { ReactComponent as InactiveFire } from "../../assets/fire-inactive.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { commentsResponse } from "../../types/commentsTypes";
import { Button } from "@/components";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { getAuthorProfileBasedOnUserName } from "@/api/search/searchUsernameByName";
import { SinglePostSingleCommentDropDownActions } from "./SinglePostSingleCommentDropdownActions";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import BaseUserAvatar from "@/components/BaseUserAvatar";
export const SinglePostSingleComment: FC<{
  comment: commentsResponse;
  postId: any;
  setComments: any;
  whenWasPosted: any;
  isAuthorized: boolean;
}> = ({
  comment,
  postId,
  setComments,
  whenWasPosted,
  isAuthorized,
}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [hasUserLikedState, setHasUserLikedState] = useState(
    comment.hasCurrentUserLikedThisComment
  );
  const [totalLikesNumberState, setTotalLikesNumberState] = useState(
    comment.totalLikesNumber
  );
  const [newCommentValue, setNewCommentValue] = useState<string>(
    comment.commentText
  );
  const [commentTextToRender, setCommentTextToRender] = useState<string>(); // In case text is updated, use this to localy show new text
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isEditComment, setIsEditComment] = useState<boolean>(false);
  const isCurrUserComment = currentUser.slug === comment.authorSlug;
  const commentAuthorLink = isCurrUserComment
    ? "/profile"
    : `/profile/${comment.authorSlug}`;

  const [formattedPostContent, setFormattedPostContent] = useState<
    React.ReactNode[] | null
  >(null);

  const handleLike = useCallback(async () => {
    if (!currentUser.uid) return;
    if (hasUserLikedState) {
      setHasUserLikedState(() => false);
      setTotalLikesNumberState((prev) => prev - 1);
    } else {
      setHasUserLikedState(() => true);
      setTotalLikesNumberState((prev) => prev + 1);
    }
  }, [hasUserLikedState, currentUser.uid, comment.id, postId]);

  const highlightAtMentions = async (
    text: string | null
  ): Promise<React.ReactNode[] | null> => {
    if (!text) return null;
    const words = text.split(" ");

    const mentions = await Promise.all(
      words.map(async (word: string, index) => {
        const key = `${word}_${index}`;

        // Check if word starts with @ and has at least 1 other character after it.
        if (word.startsWith("@") && word.length > 1) {
          const response = await getAuthorProfileBasedOnUserName(
            word.replace("@", ""),
            brand.brandValue
          );

          if (response[0]) {
            const isCurrUser = response[0].slug === currentUser.slug;
            const link = isCurrUser
              ? "/profile"
              : `/profile/${response[0].slug}`;

            return (
              <Link to={link} key={key}>
                <span className="post-highlight__tag">{word} </span>
              </Link>
            );
          }
          return <React.Fragment key={key}>{word} </React.Fragment>;
        }
        return <React.Fragment key={key}>{word} </React.Fragment>;
      })
    );
    setFormattedPostContent(mentions);
  };

  useEffect(() => {
    if (!formattedPostContent) {
      highlightAtMentions(commentTextToRender || comment.commentText);
    }
  }, [commentTextToRender, comment.commentText]);

  if (isDeleted) {
    return <></>;
  }

  try {
    return (
      <li className="comment__item">
        <div className="comment__author">
          <div className="author">
            <Link to={commentAuthorLink}>
              <div className="author__avatar">
                <div className="image">
                  <div className="image__content">
                    <BaseUserAvatar
                      logoUrl={comment.authorLogo}
                      userName={comment.authorUsername}
                    />
                  </div>
                </div>
              </div>
            </Link>
            <div className="author__info">
              <Link to={commentAuthorLink}>
                <span className="author__username">
                  {comment.authorUsername}
                </span>
              </Link>
              <time
                className="author__posted"
                dateTime={whenWasPosted?.stringDate}
              >
                {whenWasPosted?.howLongAgo}
              </time>
            </div>
          </div>
          <div>
            <SinglePostSingleCommentDropDownActions
              setIsDeleted={setIsDeleted}
              setIsEditComment={setIsEditComment}
              belongsToCurrentUser={currentUser.uid === comment.authorUid}
              isAuthorized={isAuthorized}
              commentId={comment.id}
              currUserSlug={currentUser.slug}
              postId={postId}
            />
          </div>
        </div>

        <div className="comment__content">
          {isEditComment ? (
            <div className="comment__edit">
              <BaseTextInput
                label="Update comment"
                textType="text"
                value={newCommentValue}
                placeholder=""
                setSingleState={setNewCommentValue}
                error={undefined}
              />
              <div>
                <Button
                  variant="primary"
                  disabled={newCommentValue === comment.commentText}
                  onClickAction={async () => {
                    await QueryDB.updateDoc(
                      `posts/${postId}/comments`,
                      comment.id,
                      { commentText: newCommentValue }
                    )
                      .then(() => {
                        createToast({
                          type: "success",
                          message: "Comment updated",
                        });
                        setFormattedPostContent(null);
                        setCommentTextToRender(newCommentValue);
                        setIsEditComment(false);
                      })
                      .catch(() => {
                        createToast({
                          type: "error",
                          message:
                            "Failed to update comment, please try again later",
                        });
                      });
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  onClickAction={() => {
                    setIsEditComment(false);
                    setNewCommentValue(comment.commentText);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <p>{formattedPostContent}</p>
          )}
        </div>

        <div className="comment__actions">
          <div className="action">
            <Button
              customStyles={`action__btn ${
                hasUserLikedState
                  ? "action__btn--active"
                  : "action__btn--inactive"
              }`}
              variant="link"
              onClickAction={handleLike}
            >
              {hasUserLikedState ? <ActiveFire /> : <InactiveFire />}
              <span>
                {totalLikesNumberState > 0 ? totalLikesNumberState : ""}
              </span>
            </Button>
          </div>

          {/* <div>
            {comment.authorSlug !== currentUser.slug && (
              <Button
                onClickAction={() => {
                  setReportModalOpen(true);
                }}
                variant="link"
              >
                <ReportIcon />
              </Button>
            )}
          </div> */}
        </div>
      </li>
    );
  } catch (error) {
    <>error</>;
  }
};
