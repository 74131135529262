import { FC } from "react";
const SingleTeamSeeAllModalMembersLoader: FC = (): JSX.Element => {
  return (
    <ul className="team-members__list">
        <li className="team-members__item placeholder wave">
            <div className="team-members__member team-members__member--request">
                <div className="team-members__profile">
                    <div className="team-members__avatar">
                        <div className="avatar">
                            <div className="avatar__content" />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
  );
};

export default SingleTeamSeeAllModalMembersLoader;