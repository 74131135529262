import { _createNewRealtimeDoc } from "./_createNewRealtimeDoc";
import { _updateRealtimeDoc } from "./_updateRealtimeDoc";
import { _validateSlugBasedOnCollectionName } from "./_validateSlugBasedOnCollectionName";
import { _CollectionsNamesWithSlug } from "./common.type";
import { _deleteRealtimeDoc } from "./_deleteRealtimeDoc";
import { _setUserStatus } from "./_setUserStatus";
import { _getUserStatus } from "./_getUserStatus";
import { _getRealtimeDoc } from "./_getRealtimeDoc";

export default class QueryRTDB {
  constructor() {}

  static updateRealtimeDoc = _updateRealtimeDoc;
  static createNewDoc = _createNewRealtimeDoc;
  static deleteDoc = _deleteRealtimeDoc;
  static setUserStatus = _setUserStatus;
  static getUserStatus = _getUserStatus;
  static getRealtimeDoc = _getRealtimeDoc;
  static async validateSlug(
    collectionName: _CollectionsNamesWithSlug,
    slug: string
  ) {
    return await _validateSlugBasedOnCollectionName(collectionName, slug);
  }
}
