import { FirebaseUser } from "@/api/firebase/firebase.config";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type AuthPersistState = {
  user: FirebaseUser;
  setUser: (newUser: FirebaseUser | null) => void;
};
export const useAuthPersistState = create(
  persist<AuthPersistState>(
    (set) => ({
      user: null as unknown as FirebaseUser,
      setUser: (newUser) =>
        set(() => ({
          user: newUser as FirebaseUser,
        })),
    }),
    {
      name: "firebase_auth_user",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
