import React, { memo, useState } from "react";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ShareIcon } from "../../assets/share-challenge.svg";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "../../../../api/imgix/index";
import { PostData } from "../../types/singlePostsProps";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

const SharedChallenge: React.FunctionComponent<{
  isFromFeed?: boolean;
  data: PostData;
}> = ({ data, isFromFeed }) => {
  const navigate = useNavigate();
  const [logoError, setLogoError] = useState<boolean>(false);

  if (data) {
    try {
      return (
        <div
          className={`shared-challenge ${isFromFeed ? "feed" : ""}`}
          aria-hidden
          onClick={() =>
            navigate(
              `${siteRoutes["challengesAndSlug"]}${data.additionalData!.slug}`
            )
          }
        >
          <div className="shared-challenge__image">
            <div className="image">
              <div className="image__content">
                <Image
                  onError={() => setLogoError(true)}
                  alt={`User-uploaded image for ${name} challenge`}
                  src={Query_shared.imageErrorHandler(
                    data.additionalData.logoUrl.includes("wog.imgix") ||
                      data.additionalData.logoUrl.includes("iplay.imgix")
                      ? `${
                          data.additionalData.logoUrl
                        }?${Query_Imgix.getImgixUrlProps({
                          w: "248",
                          h: "165",
                        })}`
                      : data.additionalData.logoUrl,
                    logoError,
                    undefined
                  )}
                />
              </div>
            </div>
          </div>

          <div className="shared-challenge__content">
            <h3 className="shared-challenge__heading">
              {data.additionalData.name}
            </h3>
            <span className="shared-challenge__game">
              {data.additionalData.game}
            </span>
            <p className="shared-challenge__objective">
              {data.additionalData.goal}
            </p>
          </div>

          <div className="shared-challenge__icon">
            <ShareIcon width={57} height={57} />
          </div>
        </div>
      );
    } catch (error) {
      return (
        <em>
          Failed to load shared challenge, it might no longer be available.
        </em>
      );
    }
  } else {
    return (
      <em>Failed to load shared challenge, it might no longer be available.</em>
    );
  }
};

export default SharedChallenge;
