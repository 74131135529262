import BaseUserAvatar from "@/components/BaseUserAvatar";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import React, { FC } from "react";
import WinnerBackground from "./assets/EventActivityWinnerBg.webp";
import { ReactComponent as WinnerSplash } from "./assets/EventActivityWinnerSpash.svg";
import { Container } from "react-bootstrap";
export const EventActivityWinnerCard: FC<{}> = ({}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <div className="winner">
        <h2>Winner of the day</h2>
        <p>Congratulations to the Best Gamer!</p>

      <div className="winner__layers">
        <img src={WinnerBackground} alt="" />

        <div>
          <WinnerSplash />
        </div>
      </div>

      <div className="winner__info">
        <div className="winner__rank">
          <h2>#1</h2>
        </div>

        <div className="winner__profile">
            <div className="winner__avatar">
              <BaseUserAvatar
                userName={currentUser.userName}
                logoUrl={currentUser.profileLogoUrl}
              />
            </div>
            <p>{currentUser.userName}</p>
        </div>

        <div className="winner__score">
          <h2>122</h2>
        </div>
      </div>
    </div>
  );
};
