import skype from "@/features/gather/assets/logos/skype.svg";
import discord from "@/features/gather/assets/logos/discord.svg";
import teams from "@/features/gather/assets/logos/teams.svg";
import zoom from "@/features/gather/assets/logos/zoom.svg";
import copy from "@/assets/actions/copyIcon.svg";
export const locationOptions = [
  {
    platform: "discord",
    logo: discord,
  },
  {
    platform: "teams",
    logo: teams,
  },
  {
    platform: "skype",
    logo: skype,
  },
  {
    platform: "zoom",
    logo: zoom,
  },
  {
    platform: "other",
    logo: copy,
  },
];
