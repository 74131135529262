import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { ReactComponent as IconTeam } from "../../assets/share-team.svg";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";
import { PostData } from "../../types/singlePostsProps";
import SharedTeamFallback from "./Fallbacks/SharedTeamFallback";

const SharedTeam: React.FunctionComponent<{
  data: PostData;
  isFromFeed: boolean;
}> = ({ data, isFromFeed }) => {
  const [logoError, setLogoError] = useState<boolean>(false);

  if (data && data.additionalData) {
    try {
      return (
        <Link
          to={`/teams/${data.additionalData.slug}`}
          title={data.additionalData.name}
        >
          <div className={`shared-team ${isFromFeed ? "feed" : ""}`}>
            <div className="shared-team__image">
              <div className="image">
                <div className="image__content">
                  <Image
                    onError={() => setLogoError(true)}
                    alt={`User-uploaded image for ${name} team`}
                    src={Query_shared.imageErrorHandler(
                      data.additionalData.teamLogo.includes("wog.imgix") ||
                        data.additionalData.teamLogo.includes("iplay.imgix")
                        ? `${
                            data.additionalData.teamLogo
                          }?${Query_Imgix.getImgixUrlProps({
                            w: "248",
                            h: "165",
                          })}`
                        : data.additionalData.teamLogo,
                      logoError,
                      undefined
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="shared-team__content">
              <h3 className="shared-team__heading">
                {data.additionalData.name}
              </h3>
              <p className="shared-team__excerpt">
                {data.additionalData.description}
              </p>
            </div>
            <div className="shared-team__icon">
              <IconTeam width={46} height={36} />
            </div>
          </div>
        </Link>
      );
    } catch (error) {
      return (
        <SharedTeamFallback />
      );
    }
  } else {
    return (
      <SharedTeamFallback />
    );
  }
};

export default SharedTeam;
