import { ChallengeCard } from "@/features/challenge";
import { AuthorProfile } from "@/models/database/profiles/profile.model";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export default function ProfileSingleChallenge({ props }: any) {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const {
    created,
    createdBy,
    creatorType,
    endDate,
    fieldToCompare,
    game,
    goal,
    logoUrl,
    name,
    slug,
    startDate,
  } = props;
  return (
    <ChallengeCard
      created={created}
      createdBy={createdBy}
      creatorType={creatorType}
      endDate={endDate}
      fieldToCompare={fieldToCompare}
      game={game}
      goal={goal}
      logoUrl={logoUrl}
      name={name}
      slug={slug}
      startDate={startDate}
      author={
        {
          slug: currentUser.slug,
          uid: currentUser.uid,
          profileLogoUrl: currentUser.profileLogoUrl || "",
          userName: currentUser.userName,
          fullName: currentUser.fullName || "",
        } as AuthorProfile
      }
    />
  );
}
