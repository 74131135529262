import BaseTooltipWrapper from "@/components/BaseWrappers/BaseTooltipWrapper";
import TextInput from "@/components/TextInput/TextInput";
import { createChallengeTooltipsText } from "@/features/challenge/utils/CreateChallengeTooltips";
import { FC } from "react";
import CreateChallengeMixedTextSelectInput from "./CreateChallengeMixedTextSelectInput";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { AiOutlineInfoCircle as InfoIcon } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
export const ChallengeCriteriaPicker: FC<{
  critType: string;
  setCritType: React.Dispatch<React.SetStateAction<string>>;
  state: any;
  fields: any;

  setFieldValue: any;
  handleUpdateCriteriaList: (
    type: "empty" | "add" | "remove" | "addEmpty",
    value?: number,
    key?: any
  ) => void;
  errors: any;
  name: string;
  isEdit: boolean;
}> = ({
  critType,
  setCritType,
  state,
  fields,
  setFieldValue,
  handleUpdateCriteriaList,
  errors,
  name,
  isEdit,
}) => {
  const getFilteredFields = () => {
    if (Object.entries(state).length === 0) {
      return fields;
    }

    const filteredVal = fields.map((field) => {
      if (!Object.entries(state).find(([key, value]) => key === field.value)) {
        return field;
      }
      return;
    });
    return filteredVal;
  };

  return (
    <>
      <fieldset className="radio-group">
        <BaseTooltipWrapper
          overlayTrigger={
            <legend className="radio-group__heading">
              <span>Criteria</span> <InfoIcon />{" "}
              {isEdit && <small>( cannot be edited )</small>}
            </legend>
          }
          tooltipContent={
            createChallengeTooltipsText?.[
              critType === "simple" || critType === "advanced"
                ? `tooltipCriteria${critType}`
                : "criteria"
            ]
          }
        />

        <div className="radio-group__input-group">
          <div className="radio-group__input">
            <label
              htmlFor="simple-criteria"
              className={`${critType === "simple" ? "active" : "inactive"}`}
            >
              Simple
            </label>
            <TextInput
              label=""
              id="simple-criteria"
              type="radio"
              value="simple"
              placeholder="Name"
              onChange={(e: any) => setCritType(e.target.value)}
              name={"picked"}
              hasUploading={false}
              isDisabled={isEdit}
            />
          </div>
          <div className="radio-group__input">
            <label
              htmlFor="advanced-criteria"
              className={`${critType === "advanced" ? "active" : "inactive"}`}
            >
              Advanced
            </label>
            <TextInput
              label=""
              id="advanced-criteria"
              type="radio"
              value="advanced"
              placeholder="Name"
              onChange={(e: any) => setCritType(e.target.value)}
              name={"picked"}
              hasUploading={false}
              isDisabled={isEdit}
            />
          </div>
        </div>
        <div className="criteria">
          <>
            {critType === "advanced" &&
              Object.entries(state)
                .reverse()
                .map(([key, value], index) => {
                  return (
                    <CreateChallengeMixedTextSelectInput
                      critType={isEdit ? "advanced" : critType}
                      fields={fields}
                      initialField={key}
                      initialNumber={value as string}
                      key={`${key}_${value}`}
                      objectsLength={Object.entries(state).length}
                      index={index}
                      handleUpdateCriteriaList={handleUpdateCriteriaList}
                      isSelectedOption
                      isEdit={isEdit}
                      selectedFields={Object.entries(state)}
                    />
                  );
                })}
          </>
          {!isEdit && critType && (
            <CreateChallengeMixedTextSelectInput
              critType={critType}
              fields={critType === "advanced" ? getFilteredFields() : fields}
              initialField={""}
              initialNumber={1}
              key={`${""}_${1}`}
              objectsLength={Object.entries(state).length}
              index={0}
              handleUpdateCriteriaList={handleUpdateCriteriaList}
              isSelectedOption={false}
              isEdit={isEdit}
              selectedFields={Object.keys(state)}
            />
          )}
        </div>
        {errors && errors[name] && (
          <FormErrorMessage text={errors[name]} isVisible={errors[name]} />
        )}
      </fieldset>
    </>
  );
};
