import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import icon_emoji from "../../../../assets/actions/emoji.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { Spinner } from "@/components/Spinner";
import EmojiWrapper from "@/components/EmojiWrapper/EmojiWrapper";

export const EditPostForm: FC<{
  originalPostText: string;
  postId: string;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  setPostContentMessage: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  originalPostText,
  postId,
  setIsEditPost,
  setPostContentMessage,
}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [errors, setErrors] = useState<any>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [lastClickedEmoji, setLastClickedEmoji] = useState<string>("");
  const [editPostVals, setEditPostVals] = useState<{
    postText: string;
  }>({
    postText: originalPostText || "",
  });
  const isStringValid =
    editPostVals.postText.length > 0 &&
    editPostVals.postText !== originalPostText;
  const setFieldValue = async (targetName: string, value: any) => {
    return setEditPostVals((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };
  useEffect(() => {
    if (lastClickedEmoji) {
      setFieldValue("postText", `${editPostVals.postText}${lastClickedEmoji}`);
      setLastClickedEmoji(undefined);
    }
  }, [lastClickedEmoji]);
  const submit = async () => {
    if (!currentUser.uid || !postId) return;
    // Check if text is not empty and same as before
    if (!isStringValid) {
      setErrors({
        postText: "Post message cannot be empty or the same as before",
      });
      return;
    }
    setSubmitting(true);
    await QueryDB.updateDoc("posts", postId, {
      postText: editPostVals.postText as string,
      wasEdited: true,
    })
      .then(() => {
        createToast({
          type: "success",
          message: "Post updated!",
        });
        setPostContentMessage(editPostVals.postText);
        setSubmitting(false);
        setIsEditPost(false);
      })
      .catch(() => {
        setSubmitting(false);
        setIsEditPost(false);
        createToast({
          type: "error",
          message: "Failed to update!",
        });
      });
  };

  return (
    <div className="post-form__input">
      <form
        className="form form__edit"
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          if (!submitting) {
            submit();
          }
        }}
      >
        <div className="form__content">
          <div className="form__input">
            <BaseTextInput
              label="Update your post"
              textType="text"
              value={editPostVals.postText}
              placeholder=""
              setObjectState={setFieldValue}
              setObjectStateTargetName="postText"
              error={errors && errors["postText"]}
            />
            <EmojiWrapper show={showEmoji} setShow={setShowEmoji}>
              <div className="form__emoji-picker">
                <EmojiPicker
                  emojiVersion="0.6"
                  lazyLoadEmojis={true}
                  onEmojiClick={(emojiObject) => {
                    setLastClickedEmoji(emojiObject.emoji);
                  }}
                />
              </div>
            </EmojiWrapper>
            <Button
              variant="link"
              onClick={() => setShowEmoji((pre) => !pre)}
              className="form__emoji-select"
            >
              <img src={icon_emoji} alt="emoji" />
            </Button>
          </div>
          <span className="form__actions">
            <Button
              variant="secondary"
              type="button"
              onClick={() => setIsEditPost(false)}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              disabled={submitting || !isStringValid}
            >
              {submitting ? <Spinner /> : "Save"}
            </Button>
          </span>
        </div>
      </form>
    </div>
  );
};

export default EditPostForm;
