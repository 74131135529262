import React, { FC, useState } from "react";
import { Task } from "../types/dailyTasksTypes";
import { ScheduleTaskCard } from "./ScheduleTaskCard";
import { ReactComponent as IconRight } from "../../../../src/assets/actions/chevron-right.svg";

export const TasksSchedule: FC<{
  dailyTasksData: Task[];
  activeTaskNmbr: number;
}> = ({ dailyTasksData, activeTaskNmbr }) => {
  const [foldedOpen, isFoldedOpen] = useState<boolean>(false);
  return (
    <div className="legend">
      <h5
        onClick={(e) => {
          e.preventDefault();
          isFoldedOpen(!foldedOpen);
        }}
        className={foldedOpen ? "open" : ""}
      >
        Daily tasks schedule
        <IconRight />
      </h5>

      {foldedOpen && (
        <div>
          <ul>
            {dailyTasksData.map((task: Task, index) => {
              const key = index + task.task;
              return (
                <div key={key}>
                  <ScheduleTaskCard
                    task={task}
                    activeTaskNmbr={activeTaskNmbr}
                  />
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
