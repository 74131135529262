import placeholderImage from "@/assets/placeholders/placeholder.png";
import placeholderImageSensitive from "@/assets/placeholders/placeholder_sensitive.gif";

export const _imageErrorHandler = (
  logo: string | undefined,
  isBroken: boolean,
  isPlaceHolder?: boolean
) => {
  if (logo === undefined && isPlaceHolder) {
    //   return placeholderImage;
    return placeholderImage;
  }
  if (isBroken) {
    return placeholderImageSensitive;
    //   return placeholderImageSensitive;
  }
  return logo;
};
