import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { atom } from 'jotai';
import { firestore } from '@/api/firebase/firebase.config';
import { collectionDoc } from '@/api/collectionRef/collectionOperations';

export const profilesAtom = atom(new Map<string, any>());

const useProfile = (uid: string) => {
    const [profiles, setProfiles] = useAtom(profilesAtom);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                if (profiles.has(uid)) {
                    return profiles.get(uid);
                }

                const doc = await collectionDoc.profiles(uid);
                if (doc.exists) {
                    const profile = doc.data();
                    setProfiles(prevProfiles => new Map(prevProfiles.set(uid, profile)));
                    return profile;
                } else {
                    setError('No such document!');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [uid, profiles, setProfiles]);

    const profile = profiles.get(uid);

    return { profile, loading, error };
};

export default useProfile;