export const additionalInfoText: { [key: string]: JSX.Element } = {
  "Team size": (
    <p>
      Only matches of the selected team size counts. F.e.
      <strong> solo</strong>,<strong> duo</strong>,<strong> trio</strong> and{" "}
      <strong>quad</strong>
    </p>
  ),
  "Challenge type": (
    <>
      <p>
        <strong>Record based: </strong> Highest score in a single match wins.
      </p>
      <p>
        <strong>Accumulative: </strong> Points are adding up every match you
        play.
      </p>
    </>
  ),
  maps: (
    <>
      <p>
        <strong>Any: </strong>
        Score will be tracked across all maps.
      </p>
      <p>
        <strong>Custom: </strong>
        Score will only be tracked on the selected map.
      </p>
    </>
  ),
  modes: (
    <>
      <p>
        <strong>Any: </strong>
        Score will be tracked across all game modes.
      </p>
      <p>
        <strong>Custom: </strong>
        Score will only be tracked on the selected game mode.
      </p>
    </>
  ),
};
