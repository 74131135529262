import React, { useState, useCallback, FC, useEffect, useRef } from "react";
import { Button } from "@/components/index";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "@/assets/actions/invite-user.svg";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import InviteUserModal from "./InviteUserModal";
import useDetectClickOutside from "@/hooks/useDetectClickOutside";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import BaseModalWindow from "../BaseModalWindow/BaseModalWindow";
import { ReactComponent as IconInvite } from "../../assets/actions/user-invite.svg";

export const InviteButton: FC<{
  slug: string;
  isPreview?: boolean;
  notificationType: string;
  featureName: string;
  userRole?: string;
}> = ({
  slug,
  isPreview,
  notificationType,
  featureName,
  userRole = "",
}): JSX.Element => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const user = useAuthPersistState((s) => s.user);
  const currentUserId = user?.uid;
  const [blockScroll, allowScroll] = useScrollBlock();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  useDetectClickOutside(wrapperRef, () => setInviteModalOpen(false));

  useEffect(() => {
    if (inviteModalOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
  });

  const handleClick = useCallback(() => {
    if (!currentUserId) {
      navigate(siteRoutes["home"]);
      return;
    }
    if (!isPreview) {
      setInviteModalOpen(true);
    }
  }, [setInviteModalOpen, currentUserId, history, isPreview]);

  return (
    <>
      <BaseModalWindow
        show={inviteModalOpen}
        closeModal={() => setInviteModalOpen(false)}
        title="Invite friends"
      >
        <InviteUserModal
          setAddMemberModalOpen={setInviteModalOpen}
          slug={slug}
          notificationType={notificationType}
          featureName={featureName}
        />
      </BaseModalWindow>

      <Button
        variant="link"
        data-challenges="card-icons"
        onClickAction={handleClick}
        disabled={isPreview}
      >
        <IconInvite />
      </Button>
    </>
  );
};
