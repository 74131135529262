import { clientAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";
import React, { FC } from "react";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import Hero from "../../../src/components/PageHero/PageHero";
import HeroImage from "./assets/pcspecialist_heroImage.jpg";
import HeroImageMobile from "./assets/pcspecialist_mobile.webp";
import LandingCallToAction from "@/features/landing/components/CallToAction";
import PCSpecialist from "../../../src/features/landing/assets/pcspecialist.webp";

const pageHero = {
    image: {
      src: HeroImage,
      alt: "Midjourney AI art for Counter-Strike 2",
      title: "Support for CS2 added",
      mobile: HeroImageMobile,
    },
    heading: "Prøv Månedens Gaming Gear fra PCSpecialist",
    description:
      "PCSpecialist Ultra 630 er en fantastisk måde at opdage en verden af pc-gaming på. Den er håndbygget på PCSpecialists topmoderne anlæg med mærkevarekomponenter fra førende producenter, og hver pc er grundigt testet for at sikre en førsteklasses kvalitet.",
    btn: {
      link: "",
      label: "",
    },
    authorName: "",
  };  

const PCSpecialistPartnerPage: FC = (): JSX.Element => {
    useDocumentTitle("PCSpecialist");
    const setCurrentClient = useSetAtom(clientAtom)
    setCurrentClient("PCSPECIALIST")

    return (
        <div className="partner">
            <Hero
                image={{
                    src: pageHero.image.src,
                    alt: pageHero.image.alt,
                    title: pageHero.image.title,
                    mobile: pageHero.image.mobile,
                }}
                heading={pageHero.heading}
                description={pageHero.description}
                btn={{
                    link: pageHero.btn.link,
                    label: pageHero.btn.label,
                }}
                authorName={pageHero.authorName}
                postedDate={""}
            />
            <div className="partner__heading">
                <h1>Prøv vores PC'er til Gamebox Festival</h1>
                <p>PCSpecialist har mere end 20 års erfaring og har opbygget et ry for at levere pc`er af høj kvalitet.</p>
            </div>

            <LandingCallToAction 
              title={"Prøv Månedens Gaming Gear fra PCSpecialist"}
              desc={"Håndbygget på PCSpecialists topmoderne anlæg med mærkevarekomponenter fra førende producenter, og hver pc er grundigt testet for at sikre en førsteklasses kvalitet."}
              image={PCSpecialist}
              float="left"
            />
        </div>
    );
};

export default PCSpecialistPartnerPage;