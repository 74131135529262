import firebase from "firebase/app";

export const submitSet = async (
  collectionName: string,
  data: firebase.firestore.UpdateData,
  docId?: string
): Promise<void> => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(docId)
    .set(
      {
        created: firebase.firestore.FieldValue.serverTimestamp(),
        ...data,
      },
      { merge: true }
    );
};

export const submitUpdate = async (
  collectionName: string,
  data: firebase.firestore.UpdateData,
  docId: string
): Promise<void> => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(docId)
    .update({
      ...data,
    });
};

export const submitDelete = async (
  collectionName: string,
  docId: string
): Promise<void> => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(`${docId}`)
    .delete();
};

export const submitUpdateProfile = async (
  collectionName: string,
  data: firebase.firestore.UpdateData,
  docId: string
): Promise<void> => {
  await firebase
    .firestore()
    .collection(collectionName)
    .doc(docId)
    .update({
      ...data,
    });
};
