import BaseTooltipWrapper from "@/components/BaseWrappers/BaseTooltipWrapper";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import SelectInput from "@/components/SelectInput/SelectInput";
import React, { useState } from "react";
import { AiOutlineInfoCircle as InfoIcon } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { createChallengeTooltipsText } from "@/features/challenge/utils/CreateChallengeTooltips";
export const CreateChallengeSelectMenu: React.FunctionComponent<{
  name: string;
  label: string;
  value: string;
  setFieldValue: any;
  errors: any;
  placeholder: string;
  options: any;
  isEdit: boolean;
  hasTooltip?: boolean;
  toolTipToShow?: string;
}> = ({
  name,
  label,
  value,
  setFieldValue,
  errors,
  placeholder,
  options,
  isEdit,
  hasTooltip,
  toolTipToShow,
}) => {
  const [dataId, setDataId] = useState<any>();
  return (
    <>
      <fieldset className="radio-group">
        <legend className="radio-group__heading">
          {label}
          {hasTooltip && toolTipToShow && (
            <BaseTooltipWrapper
              overlayTrigger={
                <legend>
                  <InfoIcon /> {isEdit && <small> ( cannot be edited )</small>}
                </legend>
              }
              tooltipContent={createChallengeTooltipsText?.[toolTipToShow]}
            />
          )}
        </legend>
        <SelectInput
          name={name}
          value={value}
          onChange={(e) => {
            if (name === "game" && value !== e.currentTarget.value) {
              setFieldValue("criteria", {});
            }

            const selectElement = document.querySelector(".base-select");
            const selectedOption =
              // @ts-ignore
              selectElement.options[selectElement.selectedIndex];
            const dataIdValue = selectedOption.getAttribute("data-id");

            if (dataIdValue) {
              setFieldValue("mapPool", dataIdValue);
            } else {
              setFieldValue("mapPool", "");
            }

            setFieldValue(name, e.currentTarget.value);
          }}
          styleProps={`base-input`}
          placeholder={placeholder}
          options={options}
          hasUploading={false}
          children={undefined}
          isDisabled={isEdit}
          setDataId={setDataId}
        />
        {errors && errors[name] && (
          <FormErrorMessage
            isVisible={!!errors[name]}
            text={errors[name] as string}
          />
        )}
      </fieldset>
    </>
  );
};
