export const filterButtons = [
  {
    text: "overview.challenges.badges.new",
    value: "discover-new",
  },
  {
    text: "overview.challenges.badges.mine",
    value: "mine",
  },
  // {
  //   text: "overview.challenges.badges.zone",
  //   value: "zone-activity",
  // },
];