import { FC } from "react";
import { Link } from "react-router-dom";
import ImageTeams from "../assets/teams.png";
import ImageChallenges from "../assets/challenges.png";
import { ReactComponent as IconChevron } from "../assets/chevron-right.svg";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import ImageLogitech from "../assets/logitech_card.png";
import ImageTCL from "../assets/tcl.png";
import ImagePCS from "../assets/pcspecialist.png";

const CtaCard: FC = (): JSX.Element => {
  const cardData = [
    brand.hasFeature.challenges && {
      image: ImageChallenges,
      title: "Challenges",
      description:
        "Define how you want to play! Join to compete with others in your favourite games",
      link: "/challenges",
    },
    brand.hasFeature.teams && {
      image: ImageTeams,
      title: "Teams",
      description:
        "Team up with friends or make new connections and join other teams",
      link: "/teams",
    },
    brand.brandValue === "ELGIGANTEN" && {
      image: ImageTCL,
      title: "TCL",
      description:
        "Opgrader din gaming, og nyd en jævn oplevelse uden sløring med AMD FreeSync Premium Pro-teknologi.",
      link: "/partners/tcl",
    },
    brand.brandValue === "ELGIGANTEN" && {
      image: ImageLogitech,
      title: "Logitech",
      description:
        "Takket være fantastisk design, konstruktion og en forkærlighed for racerspil opnår du et helt nyt niveau når du spiller med Logitech G.",
      link: "/partners/logitech",
    },
    brand.brandValue === "ELGIGANTEN" && {
      image: ImagePCS,
      title: "PCSpecialist",
      description:
        "Åbner døren til en ny verden af PC-gaming. Hver eneste PC er håndbygget og gennemgår grundige test for at sikre den bedste kvalitet! ",
      link: "/partners/pcspecialist",
    },
  ].filter(Boolean);
  return (
    <>
      {cardData.map((card) => {
        return (
          <Link to={card.link} key={card.title.toString()}>
            <div className="cta-card" title={card.title}>
              <div className="cta-card__content">
                <div className="cta-card__body">
                  <h2 className="cta-card__heading">
                    {card.title}
                    <span className="cta-card__icon">
                      <IconChevron />
                    </span>
                  </h2>
                  <p className="cta-card__description">{card.description}</p>
                </div>
                <div className="cta-card__image">
                  <img src={card.image} />
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};

export default CtaCard;
