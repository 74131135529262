import { FC } from "react";
import videoWebm from "../assets/bg_video.webm";
import videoMP4 from "../assets/bg_video.mp4";
import videoPoster from "../assets/bg_video_fallback.jpg";
import videoSepTop from "../assets/video_top.png";
import videoSepBot from "../assets/video_bot.png";
import { Button } from "@/components";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { BestgamerLandingPartners } from "./BestgamerLanding.partners";
import Logo from "../../../../../../branchAndBrandSpecific/utils/brandVariables/assets/bestgamer/bestGamerGreen.svg";
import { Container } from "react-bootstrap";
import { BestgamerLandingUSP } from "./BestgamerLanding.usp";

export const BestgamerLandingHead: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const eventDate = "Vinterferien 8. - 16. Februar 2025";
  const eventLocation = "Danmarks";

  return (
    <div className="head">
      <div className="head__header">
        <img className="head__separator" src={videoSepTop} alt="video separator" />
        <div className="brand">
          <img src={Logo} alt="" />
        </div>
      </div>

      <div className="head__body">
        <video
          autoPlay
          loop
          preload="metadata"
          muted
          poster={videoPoster}
        >
          <source src={videoWebm} type="video/webm" />
          <source src={videoMP4} type="video/mp4" />
        </video>

        <div className="head__content">
          <Container>
            <div className="head__heading">
              <h1>Er du blandt <span>bedste gamere?</span></h1>
              <div className="head__country">{eventLocation}</div>
            </div>

            <div className="head__usp">
              <BestgamerLandingUSP />
            </div>

            <div className="head__date">
              {eventDate}
            </div>

            <div className="head__actions">
              <Button
                variant="primary"
                onClickAction={() => navigate(siteRoutes["login"])}
              >
                Tilmeld dig nu
              </Button>
            </div>
          </Container>
        </div>
      </div>

      <div className="head__footer">
          <img className="head__separator" src={videoSepBot} alt="video separator" />
          <BestgamerLandingPartners />
        </div>
    </div>
  );
};
