const date = new Date();

const duration = [
  new Date(date.setHours(0, 0, 0, 0)),
  new Date(date.setHours(23, 59, 59, 999)),
] as Date[];

export const createChallengeInitialValues = {
  game: "Call of Duty Warzone",
  gameType: "wz",
  region: "Europe",
  name: "",
  goal: "",
  description: "",
  logoUrl: undefined,
  onlyWins: "Record",
  duration,
  criteria: {},
  teamSize: "",
  type: "public",
  creatorType: "Created By You",
  progressBarLabel: [],
  progressBarValue: "",
  fieldToCompare: [],
  mapPool: "",
};
