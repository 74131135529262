import logo from "../assets/jbl-quantum/quantum_logo.svg";

export const JBL_brandVariables = {
  siteTitle: "JBL DHS 2024",
  siteIcon: logo,
  name: "JBL Dreamhack Summer 2024",
  name_small: "JBL by Harman",
  logos: {
    large: logo,
    white: logo,
    small: logo,
    medium: logo,
    alt_text: "JBL by Harman logo",
    profile: logo,
  },
  brandTheme: "jbl", // Theme of the brand
  site: "", // Live url of site
  authMethod: "phone", // Only needed for events, gives user set options to manualy sign up, options are:  email | phone
  defaultCountryCode: "DK", // Only needed for retail platforms | For initial phone number | Changes all auth methods`
  defaultLanguage: "",
  brandStoreExtension: "", // Only needed for retail platforms | Extension used for email
  brandValue: "JBL", // Only needed for retail platforms | Brand value needed for BE related functions
  brandIntials: "j", // gets added after the username, to drasticaly descrease chance of duplicate usernames across different apps | Only needed for event platforms
  defaultNavigateUrl: "/",
  eventHasAutoQrSignup: false,
  hasSubClients: false,
  subClientsForOverviews: null, // string[] of clients used to fetch multiple types of clients for overview pages
  hasFeature: {
    themes: false,
    connections: false,
    achievements: false,
    messages: false,
    preferences: false,
    teams: false,
    news: true,
    challenges: false,
    singleChallenge: true,
    gathers: false,
    friends: false,
    zones: false,
    feed: false,
    winners: true,
    schedule: false,
    qrSignUp: false,
    manualSubmissions: true,
    landing: false,
    login: false,
    notifications: false,
    haslanguagePicker: false,
    getstarted: true,
    hero: false,
    search: true,
    routes: false,
    dailytasks: false,
    games: false,
    treasure_hunt: false,
    activities: false,
  },
  hasAuth: {
    steam: false,
    bnet: true,
    quickSignUp: true,
    google: true,
  },
  layout: {
    challenge: "default",
  },
  event: {
    forcedCredentialPopup: "email",
    eventCode: "JBL", // Only needed for event platforms
    collectionForSubmissions: "dreamhack_submissions",
    documentForSubmissions: "elgiganten_dreamhack_summer2024",
    isEvent: false,
    spacecoins_redirect: true,
  },
};
