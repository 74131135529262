import { FC } from "react";

const PageHeroLoader: FC = (): JSX.Element => {
  return (
    <div className="news-carousel placeholder">
      <div className="news-carousel__slider">
        <div className="news-carousel__card">
            <div className="hero">
                <div className="hero__image">
                    <div className="image">
                    <div className="image__content wave" />
                    </div>
                </div>
                <div className="hero__content wave">
                    <div className="hero__body" />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeroLoader;
