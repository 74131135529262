import { singleChallengeAltProps } from "@/features/challenge/utils/singleChallengeProps";
import { FC, useEffect } from "react";
import { SingleChallengeBestgamerHead } from "./SingleChallengeBestgamer.head";
import { SingleChallengeBestGamerLeaderboard } from "./SingleChallengeBestgamerLeaderboad.fetcher";
import { Spinner } from "@/components";
import { SingleChallengeBestgamerHighlights } from "./SingleChallengeBestGamerHighlights";
import useViewportWidth from "@/hooks/useViewportWidth";
import { Highlights } from "@/components/Highlights";
import ChallengeDisclaimer from "../../ChallengeDisclaimer";
import { BestgamerEventPartner } from "../../../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerPartner/BestgamerEventPartner";

import productImage from "./assets/jblQuantum.png";
import productImageBackground from "./assets/temp_bg.jpg";
import { EventDetails } from "../../../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventDetails";
export const SingleChallengeBestgamerUI: FC<{
  data: singleChallengeAltProps;
}> = ({ data }): JSX.Element => {
  const getGameTypeForBestGamer = () => {
    if (data?.gameType) {
      return data.gameType.includes("f1")
        ? "game-racing"
        : `game-${data.gameType}`;
    }
    return "";
  };

  const isBreakpoint = useViewportWidth(768);

  useEffect(() => {
    document.body.classList.add("challenges-bg");
    
    return () => {
      document.body.classList.remove("challenges-bg");
    };
  }, []);

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className={`bestgamer challenge-page ${getGameTypeForBestGamer()}`}>
      {/* About challenge */}
      <section className="challenge-page__head">
        <SingleChallengeBestgamerHead data={data} />
      </section>

      {/* Leaderboard */}
      <section className="challenge-page__leaderboard">
        <SingleChallengeBestGamerLeaderboard slug={data.slug} />
      </section>

      {/* Description */}
      <section className="challenge-page__description">
        <EventDetails
          goal={data.goal}
          description={data.description}
          game={data.game}
        />
      </section>

      {/* Highlights */}
      <section className="challenge-page__highlights">
        {isBreakpoint ? (
          <Highlights game={data.game} />
        ) : (
          <SingleChallengeBestgamerHighlights data={data} />
        )}
      </section>

      <section></section>
      {/* Partner */}
      <section className="challenge-page__challenge-partner">
        <BestgamerEventPartner
          partner_logo="partner logo"
          product_name="product name"
          product_link=""
          product_image={productImage}
          product_background={productImageBackground}
        />
      </section>

      {/* Disclaimer */}
      <section className="challenge-page__disclaimer">
        <ChallengeDisclaimer game={data.gameType} />
      </section>
    </div>
  );
};
