import QueryDBProfiles from "@/api/queryDB/profiles";
import { Spinner } from "@/components/Spinner";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import i18n from "@/tranlations/i18n";
import Layout from "../../../branchAndBrandSpecific/layout/Layout";
import UnAuthorizedLayout from "../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { Login } from "@/features/auth";
import { useLocation } from "react-router-dom";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

// import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";
const ProtectedRoutesProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  // user can't be empty at this point
  const user = useAuthPersistState((state) => state.user)!;
  const [error, setError] = useState<string | null>(null);
  const profile = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setIsLoading(true);
    try {
      // check if user doc is persisted in the session storage and authenticated user is same as profile doc user
      if (!profile || user.uid !== profile.uid) {
        setUserProfile(null); // set it null to refetch a new one
        async function fetchUser() {
          const _userProfile = await QueryDBProfiles.getUserBasedOnUid(
            user.uid
          );
          if (_userProfile) {
            setUserProfile(_userProfile);
            i18n.changeLanguage(
              _userProfile.lang || brand.defaultLanguage || "eng"
            );
            document.documentElement.lang =
              _userProfile.lang || brand.defaultLanguage || "eng";
          }
        }
        fetchUser();
      }
    } catch (error: any) {
      setError(error.message);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    const path =
      location.pathname.split("/")[1].charAt(0).toUpperCase() +
      location.pathname.split("/")[1].slice(1);
    // document.title = `${path ? `${path} | ` : ""} ${brand.name}`;
  }, [location]);

  if (isLoading && !user) {
    return <Spinner />;
  }
  if (!!error) {
    throw Error(error);
  }

  if (!user) {
    // in case user does not exist, reload the whole website to clear up session storage
    window.location.reload();
  }

  return (
    <>
      {!!profile && profile !== null ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <UnAuthorizedLayout>
          <Login />
        </UnAuthorizedLayout>
      )}
    </>
  );
};

export default ProtectedRoutesProfile;
