import React from "react";
import PreferencesData from "./PreferencesData";
import { ReactComponent as IconArrowRight } from "../../../../../../assets/actions/chevron-right.svg";

export const SettingsPreferences: React.FC<{
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrentChildTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({ setCurrentTab, setCurrentChildTab }) => {
  const prefTabsArray = Object.values(PreferencesData);

  return (
    <>
      <div className="preferences__content">
        <h5>Mute all notifications</h5>
        <div className="preferences__active switch">
          <input
            // onClick={() => {
            //   handleSwitchButton(isActivated);
            // }}
            // checked={!isConnectedCorrectly ? false : isActivated}
            id="toggle"
            type="checkbox"
            name="toggle"
          />
          <span />
        </div>
      </div>
      <ul className="preferences__tabs">
        {prefTabsArray.map((preference, i) => (
          <li
            key={i}
            onClick={() => {
              setCurrentTab("Preferences");
              setCurrentChildTab(preference.title);
            }}
          >
            {preference.title}

            <IconArrowRight />
          </li>
        ))}
      </ul>
    </>
  );
};
