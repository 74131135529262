import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { FC, useState } from "react";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { Button, Spinner } from "@/components";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";

export const SingleTeamDisbandModal: FC<{
  teamName: string;
  setDisbandTeamOpen: any;
  teamSlug: string;
  disbandTeamOpen: boolean;
  teamData: SingleTeamResponseTypes;
  deleting: boolean;
  handleDisbandTeam: any;
}> = (props): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [validation, setValidation] = useState<any>();
  const [deleting, setIsDeleting] = useState<boolean>(false);

  return (
    <BaseModalWindow
      show={props.disbandTeamOpen}
      closeModal={() => {
        props.setDisbandTeamOpen(false);
      }}
      title="Delete team"
    >
      <div className="disband">
        <p>
          Are you sure you would like to delete this team? Deleting your team
          will:
          <ul>
            <li>Remove all members, including you.</li>
            <li>Remove all posts in the team feed.</li>
            <li>Delete the team from the platform.</li>
          </ul>
        </p>
        <fieldset className="input-group">
          <legend className="input-group__heading">
            Type <strong>Delete!</strong> to confirm.
          </legend>
          <input
            type="search"
            id="disbandTeam"
            placeholder="Delete!"
            className="base-input"
            onChange={(e) => {
              setValidation(e.target.value);
            }}
          />
          <span className="input-group__help">
            Input field is case-sensitive.
          </span>
        </fieldset>
        <div className="disband__actions">
          <Button
            variant="secondary"
            type="button"
            onClickAction={() => props.setDisbandTeamOpen(false)}
          >
            Cancel
          </Button>

          {!deleting ? (
            <Button
              variant="primary"
              type="button"
              disabled={!deleting && validation !== `Delete!`}
              onClickAction={() => {
                setIsDeleting(true);
                props.handleDisbandTeam();
              }}
            >
              Confirm
            </Button>
          ) : (
            <Button variant="primary" type="button" disabled>
              <Spinner />
            </Button>
          )}
        </div>
      </div>
    </BaseModalWindow>
  );
};
