import React, { FC, useState, useEffect } from "react";
import { v4 } from "uuid";
import { EditTeamRightsFormValues } from "../../types/TeamTypes";
import { AuthorProfile } from "@/models/database/profiles/profile.model";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { UserListItem } from "./TeamRightsAdminListItem";
import {
  createTeamOptions,
  editTeamRights,
} from "../../utils/CreateTeamOptions";
import { Button, Image, Spinner } from "@/components";
import SelectInput from "@/components/SelectInput/SelectInput";
import { TeamRightsOptionsList } from "./TeamRightsOptionsList";
import TeamsAdminsListLoader from "@/components/skeletonLoaders/TeamsAdminsListLoader/TeamsAdminsListLoader";
type AdminResp = {
  profile: AuthorProfile;
  role: "owner" | "admin" | "random" | "member";
};

export const TeamAdminsList: FC<{
  teamSlug: string;
  userRole: "owner" | "admin" | "random" | "member";
}> = ({ teamSlug, userRole }) => {
  const [adminsList, adminLoading, communityError, fetchAdmins] =
    useCloudFunctionCall<{
      data: AdminResp[];
      isCurrentUserOwner: boolean;
    }>("getAdminsOfCurrentTeam");

  useEffect(() => {
    const fetch = async () => {
      await fetchAdmins({
        slug: teamSlug,
      });
    };

    if (teamSlug) {
      fetch();
    }
  }, []);

  if (adminLoading) {
    return <TeamsAdminsListLoader />
  }

  return (
    <div className="content__permissions">
      {/* Type */}
      <div className="admins">
        <fieldset className="input-group">
          <legend className="input-group__heading">Admins</legend>
          {adminsList &&
            adminsList.data.map((admin: AdminResp, index) => {
              return (
                <UserListItem
                  admin={admin.profile}
                  role={admin.role}
                  index={index}
                  teamSlug={teamSlug}
                  currentUserRole={userRole}
                />
              );
            })}
        </fieldset>
      </div>
    </div>
  );
};
