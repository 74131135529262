import React, { memo, FC } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const BaseTooltipWrapper: FC<{
  tooltipContent: JSX.Element;
  overlayTrigger: JSX.Element;
}> = ({ tooltipContent, overlayTrigger }): JSX.Element => {
  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={<Tooltip className="">{tooltipContent}</Tooltip>}
    >
      {overlayTrigger}
    </OverlayTrigger>
  );
};

export default memo(BaseTooltipWrapper);
