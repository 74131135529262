import { useMemo } from "react";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { useEffect, useState } from "react";

import { FeedTypeAndComponent } from "../../feed/utils/FeedTypeAndComponents";
import ProfileTabs from "./ProfileTabs";
import { Profile } from "@/models/database/profiles/profile.model";
import { ProfileTabsMobile } from "./ProfileTabs.mobile";
import FeedLoader from "@/components/skeletonLoaders/profileLoader/FeedLoader";
import { ErrorFallback } from "@/components";
import { CreatePostForm } from "@/features/feed";
import ChallengeOverviwLoader from "@/components/skeletonLoaders/challengeOverviewLoader/challengeLoader";
import TeamsOverviwLoader from "@/components/skeletonLoaders/teamsOverviewLoader/teamsLoader";
import { PostData } from "@/features/feed/types/singlePostsProps";
import { useAtom } from "jotai";
import { profileFeedAtom } from "@/utils/atoms/feedAtoms/feedAtoms";

export default function ProfileFeed({
  slug,
  belongToCurrentUser,
  currentUserProfile,
  isFriendsOfCurrentUser,
}: {
  slug: string;
  belongToCurrentUser: boolean;
  currentUserProfile: Profile;
  isFriendsOfCurrentUser: boolean;
}) {
  // should always be posts if it is other user's profile
  const activeTab = belongToCurrentUser
    ? (localStorage.getItem("activeTab") as FeedType) || "posts"
    : "posts";

  const [feedUpdateTrigger, setFeedUpdateTrigger] = useState<number>(0);
  const [feedType, setFeedType] = useState<FeedType>(() => activeTab);

  const [profileFeedDataAtom, setProfileFeedDataAtom] =
    useAtom(profileFeedAtom);
  const visitedFeedDoc = profileFeedDataAtom?.[slug];

  const filterKey = `${slug}_${feedType}`;
  const [
    feed, // this feed will be either posts/challenges/gathers/teams,etc based on feedType
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchFeed,
    ref,
    inView,
    setSinglePost,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<any[] | undefined, any>(
    "getUserFeedBasedOnSlug",
    slug,
    filterKey, // filter type
    true // to persist the data in the local storage.
  );

  const loaders = {
    posts: <FeedLoader />,
    challenges: <ChallengeOverviwLoader />,
    gathers: <FeedLoader />,
    teams: <TeamsOverviwLoader />,
    achievements: <FeedLoader />,
  };
  //only run at first load!
  useEffect(() => {
    const params: GetUserFeedBasedOnSlugProps = {
      slug,
      lastFeedDateInSeconds: undefined,
      type: feedType,
    };
    (async () => {
      fetchFeed(params, filterKey);
    })();
  }, [feedType, slug]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  useEffect(() => {
    if (feed) {
      setProfileFeedDataAtom({
        ...(profileFeedDataAtom || []),
        [slug]: feed,
      });
    }
  }, [feed]);
  // call this function when inView is true
  async function fetchMore() {
    const params: GetUserFeedBasedOnSlugProps = {
      slug,
      lastFeedDateInSeconds,
      type: feedType,
    };
    await fetchFeed(params, filterKey, true);
  }

  // add all the elements to this object with all the keys
  const ElementToRender = useMemo(() => {
    return FeedTypeAndComponent[feedType];
  }, [feedType]);

  // always set the inview reference to the 7th last element.
  //Why? When user starts to scroll down, we can start fetching the next 10 posts in advanced.
  const inViewRefElementIndex = useMemo(() => {
    let numberToSubtract = 4;
    if (feed && feed?.length <= 10) {
      numberToSubtract = 2;
    }
    return feed ? feed?.length - numberToSubtract : -1;
  }, [feedType, feed, hasMore]);

  const feedToRender = visitedFeedDoc || feed;
  return (
    <>
      <>
        <div className="profile__tabs">
          <ProfileTabs
            belongToCurrentUser={belongToCurrentUser}
            currentTab={feedType}
            setCurrentTab={setFeedType}
            currentUserProfile={currentUserProfile}
            slug={slug}
            loading={_loading}
          />
        </div>
        <ProfileTabsMobile />
        <div key={feedType} className={feedType}>
          {belongToCurrentUser && !error && feedType === "posts" && (
            <CreatePostForm
              postedIn="feed"
              currentUser={currentUserProfile}
              communityData={undefined}
              setFeedUpdateTrigger={setFeedUpdateTrigger}
              disabledInput={_loading}
              setSinglePost={(data) => setSinglePost(data, filterKey)}
            />
          )}

          {(feedToRender || []).map((_feed: any, ind: number) => {
            const key = _feed.slug || _feed.id || ind;
            return (
              <div
                className="feed-post"
                key={key}
                ref={ind === inViewRefElementIndex ? ref : null}
              >
                {/* this element will always be based on Feed type */}
                <ElementToRender
                  postData={_feed}
                  props={_feed}
                  isFriendsOfCurrentUser={
                    isFriendsOfCurrentUser ||
                    _feed.uid === currentUserProfile.uid
                  }
                />
              </div>
            );
          })}
          {_loading && loaders[feedType]}
        </div>
      </>
      {error && !feed && (
        <ErrorFallback>Failed to load {feedType}</ErrorFallback>
      )}
    </>
  );
}
