import { collectionRef } from "@/api/collectionRef/collectionOperations";
import QueryDB from "@/api/queryDB/common";
import { Button } from "@/components";
import { Games } from "@/models/database/games/games.model";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";

export const participateInChallenge = async (
  navigate: any,
  type: "public" | "private",
  game?: Games,
  uid?: string,
  challengeSlug?: string
): Promise<boolean> => {
  let isSuccess = false;

  // If no account is required: Join challenge
  if (!game.requiresAccount) {
    await QueryDB.createNewDoc(
      "junction_profile_challenge",
      {
        challengeId: challengeSlug,
        userId: uid,
        status: type === "public" ? "accepted" : "pendingRequest",
        userChallengeData: {
          points: 0,
        },
      },
      `${uid}_${challengeSlug}`
    )
      .then(() => {
        createToast({
          type: "success",
          message:
            type === "public"
              ? "Joined challenge2"
              : "Request to join is pending",
        });
        return (isSuccess = true);
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Something went wrong trying to join challenge",
        });
        return (isSuccess = false);
      });
  }
  // If account is required: Check if user is allowed to join, then join or throw error

  // Join user to challenge with ingameUsername
  if (game.requiresAccount) {
    if (!uid || !challengeSlug) return false;

    // check if user is connected or has a account
    const inGameUsername = await getInGameUserNameFromUid(uid, challengeSlug);
    const exists = await collectionRef.challenge.doc(challengeSlug).get();

    if (!exists) {
      return false;
    }
    await QueryDB.createNewDoc(
      "junction_profile_challenge",
      {
        challengeId: challengeSlug,
        userId: uid,
        status: type === "public" ? "accepted" : "pendingRequest",
        userChallengeData: {
          inGameUsername,
          points: 0,
        },
      },
      `${uid}_${challengeSlug}`
    )
      .then(() => {
        createToast({
          type: "success",
          message:
            type === "public"
              ? "Joined challenge 1"
              : "Request to join is pending",
        });
        return (isSuccess = true);
      })
      .catch(() => {
        createToast({
          type: "error",
          message: (
            <>
              Could not join challenge, make sure you are connected to the
              correct platform for this game
              <span />
              <Button
                variant="primary"
                onClickAction={() =>
                  navigate(siteRoutes["profileSettings"], {
                    state: {
                      to: "Connections",
                    },
                  })
                }
              >
                Add account
              </Button>
            </>
          ),
        });
        return (isSuccess = false);
      });
  }
  return isSuccess;
};

// Function to check if user is connected or has a account to the games platform

const getInGameUserNameFromUid = async (uid: string, challengeSlug: string) => {
  let inGameUsername;
  const challenge = await collectionRef.challenge.doc(challengeSlug).get();
  if (!challenge) {
    createToast({
      type: "error",
      message: "Could not find challenge to join",
    });
    return;
  }

  const gameType = challenge?.data()?.gameType;

  //   League of Legends
  if (gameType === "lol") {
    const doc = await QueryDB.getSingleDoc("profileLeagueOfLegends", uid);
    if (doc && doc?.gameName) {
      inGameUsername = doc?.gameName;
    }
  }

  //   CoD: Warzone / Battle.net games
  if (gameType === "wz") {
    const doc = await QueryDB.getSingleDoc("profileBnet", uid);
    if (doc && doc?.battletag) {
      inGameUsername = doc?.battletag;
    }
  }

  //   PUBG
  if (gameType === "pubg" || gameType === "PUBG") {
    const doc = await QueryDB.getSingleDoc("profilePubg", uid);
    if (doc && doc?.pubgName) {
      inGameUsername = doc?.pubgName;
    }
  }

  //   Dota 2 / Steam games
  if (gameType === "dota2") {
    const doc = await QueryDB.getSingleDoc("profileSteam", uid);
    if (doc && doc?.displayName) {
      inGameUsername = doc?.displayName;
    }
  }

  //   Throw error if user doesnt have a account on the platform
  if (!inGameUsername) {
    return createToast({
      type: "info",
      message: `You have not linked your ${challenge.data()
        ?.game} account to participate in this challenge.`,
    });
    // throw new Error(
    //   `Missing info, challenge game:  ${challenge.data()
    //     ?.game}, gameType: ${gameType}`
    // );
  }

  return inGameUsername;
};
