import { Button } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const SingleGather404 = ({ navigate }: any) => {
  return (
    <div>
      <p>
        This gather has most likely been removed or the creator is no longer
        active
      </p>
      <Button
        variant="primary"
        type="button"
        onClickAction={() => navigate(siteRoutes["gathers"])}
      >
        Back to gathers
      </Button>
    </div>
  );
};
