import { FC } from "react";
import { OverviewArchive, SelectDropdownFilter } from "@/components";
import i18next from "i18next";
import challs_background from "../../assets/background/overview-challenges.jpg";
import { challengeOverviewProps } from "../../types/props/OverviewProps";
import close_icon from "../../assets/logos/close.svg";
import { filterButtons } from "../../utils/challengeOverviewFilters";
import { GameFilterPicker } from "@/components/GameFilterPicker.tsx/GameFilterPicker";
import SelectDropdown from "@/components/SelectDropdown/SelectDropdown";
import {
  DropdownStatusOptions,
  challengesPlatformOptions,
} from "@/utils/constants/dropDownOption";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const ChallengeOverviewUi: FC<challengeOverviewProps> = ({
  selectedCreatorType,
  loading,
  setSelectedCreatorType,
  selectedGame,
  setSelectedGames,
  challengeTitle,
  setChallengeTitle,
  selectedPlatform,
  setSelectedPlatform,
  selectedStatus,
  setSelectedStatus,
}) => {
  return (
    <>
      <OverviewArchive
        headline={i18next.t("strings.challenges")}
        linkToBtnText="overview.challenges.create_btn"
        linkToBtnLink="/create-challenge"
        subtitle={i18next.t("overview.challenges.desc")}
        filterButtons={filterButtons}
        selectedFilter={selectedCreatorType}
        setSelectedFilter={setSelectedCreatorType}
        disableBadges={loading}
        background={challs_background}
        setChallengeTitle={setChallengeTitle}
      >
        {/* if children are added to  OverviewArchive it wil replace the filterButtons props  */}
      </OverviewArchive>

      <div className="filters">
        <div className="filters__heading">
          <h4>
            {i18next.t(
              challengeTitle || i18next.t("overview.challenges.headline.new")
            )}
          </h4>
        </div>
        <div className="filters__filter">
          {selectedGame || selectedStatus !== "ongoing" ? (
            <div
              aria-hidden
              onClick={() => {
                setSelectedStatus("ongoing");
                setSelectedPlatform(undefined);
                setSelectedGames(undefined);
                setSelectedCreatorType("discover-new");
                setChallengeTitle("");
              }}
              className="filters__reset"
            >
              <button className="filters__btn">
                <img width={21} height={21} src={close_icon} alt="Close icon" />
              </button>
            </div>
          ) : (
            ""
          )}

          {!brand.event.isEvent && (
            <div className="filters__options">
              {/* <SelectDropdown
              setState={setSelectedPlatform}
              state={selectedPlatform}
              defaultSelected="Platform"
              optionsList={challengesPlatformOptions}
            /> */}
              <SelectDropdownFilter
                setState={setSelectedStatus}
                state={selectedStatus}
                defaultSelected="ongoing"
                optionsList={DropdownStatusOptions}
              />
              <GameFilterPicker
                selectedGame={selectedGame}
                setSelectedGame={setSelectedGames}
                setUpdateTrigger={undefined}
                filterLocation="challenges"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
