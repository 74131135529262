import React, { FC, useState } from "react";
import { SingleGatherActionProps } from "../../types/cloudFunctions/SingleGatherTypes";
import { Query_Imgix } from "@/api/imgix";
import { InviteButton } from "@/components/InviteButton";
import { FavoriteButton } from "@/components/FavoriteButton";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";
import { Button } from "@/components";
import { ReactComponent as ShareIcon } from "../../../feed/assets/share-post-profile.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

const SingleGatherActions: FC<SingleGatherActionProps> = (
  props
): JSX.Element => {
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <div className="gather__figure">
      <picture className="adaptive">
        <figure className="gather__figure adaptive-photo ">
          <img
            src={`${
              props.logoUrl.includes("wog.imgix") ||
              props.logoUrl.includes("iplay.imgix")
                ? `${props.logoUrl}?${Query_Imgix.getImgixUrlProps({
                    w: "434",
                    h: "285",
                  })}`
                : props.logoUrl
            }`}
            alt="gather-logo"
          />
        </figure>
      </picture>
      <div>
        <ul className="gather__figure--actions">
          {(props.currentUserGeneralRole &&
            props.currentUserGeneralRole !== "random" &&
            props.type === "private") ||
          props.type === "public" ? (
            <InviteButton
              slug={`${props.gatherSlug}`}
              isPreview={props.isPreview}
              notificationType="gather-requests"
              featureName={props.name}
            />
          ) : (
            ""
          )}
          {(props.currentUserGeneralRole &&
            props.currentUserGeneralRole !== "random") ||
          props.isRequestPending ? (
            <FavoriteButton
              slug={`${props.gatherSlug}`}
              isPreview={props.isPreview}
              isInFavoritesOfCurrentUser={props.isFavoriteByCurrentUser}
              featureType="gather"
              name={props.name}
            />
          ) : (
            ""
          )}

          {/* General modal for sharing | New */}
          <Button variant="link" onClickAction={() => setShareModalOpen(true)}>
            <ShareIcon />
          </Button>

          <ShareContentModalWindow
            show={shareModalOpen}
            closeWindow={() => setShareModalOpen(false)}
            createType="create"
            sharedFrom={"gather"}
            postData={{
              uid: currentUser.uid,
              postText: "",
              postedIn: "",
              totalSharesNumber: 0,
              postVideo: "",
              created: undefined,
              postPhoto: "",
              wasEdited: false,
              commentsCount: 0,
              totalLikesNumber: 0,
              totalCommentsNumber: 0,
              id: props.gatherSlug,
              authorSlug: currentUser.slug,
              authorUsername: currentUser.userName,
              authorLogo: currentUser.profileLogoUrl,
              hasCurrentUserLikedThisPost: false,
              type: "sharedGather",
              additionalData: undefined, // To be refactored
            }} // NEW
          />
        </ul>
      </div>
    </div>
  );
};

export default SingleGatherActions;
