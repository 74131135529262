import React, { FC, useCallback, useEffect, useState } from "react";
import { News } from "@/models/database/news/News.model";
import placeholderImage from "../../../../assets/placeholders/placeholder.png";
import NavArrow from "@/components/NavArrow";
import PageHeroLoader from "@/components/skeletonLoaders/PageHeroLoader";
import Hero from "./../../../../components/PageHero/PageHero";


placeholderImage;
const SingleFeaturedNewsSlider: FC<{
  featuredNewsLoading: boolean;
  featuredNews: News[];
}> = ({ featuredNews, featuredNewsLoading }): JSX.Element => {
  const [isLoading, SetIsLoading] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const carouselItems = document.querySelectorAll(".news-carousel__card");
  const scrollToItem = (index) => {
    if (carouselItems[index]) {
      carouselItems[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    scrollToItem(activeIndex);
  }, [activeIndex]);

  const back = () => {
    if (activeIndex === 0) {
      return setActiveIndex(featuredNews.length - 1);
    }
    return setActiveIndex(activeIndex - 1);
  };

  const next = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= featuredNews.length) {
        return 0; // Reset to 0 if newIndex exceeds the length
      }
      return newIndex;
    });
  };

  const isElementInViewport = () => {
    const carouselContainer = document.querySelector(".news-carousel");
    const location = carouselContainer.getBoundingClientRect();

    return (
      location.top >= 0 &&
      location.left >= 0 &&
      location.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      location.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    let intervalId;
    if (featuredNews) {
      SetIsLoading(false);
      intervalId = setInterval(() => {
        // Check if the new carousel is in screen view
        if (isElementInViewport()) {
          next();
        }
      }, 9000);
    }
    return () => clearInterval(intervalId);
  }, [featuredNews]);

  // Variables
  const hasSeveralFeaturedNews = featuredNews?.length > 1;

  if (isLoading) {
    return <PageHeroLoader />;
  }

  return (
    <div className="news-carousel">
      <div className="news-carousel__slider">
        {featuredNews &&
          featuredNews?.map((news: any) => (
            <div className="news-carousel__card" key={news.slug}>
              <Hero
                image={{
                  src: news.imageURL,
                  alt: news.imageURL,
                  title: news.imageURL,
                }}
                description={news.intro}
                heading={news.title}
                btn={{
                  link: `/news/${news.slug}`,
                  label: "To article",
                }}
                authorName={news.author?.userName}
                postedDate={news.created}
              />
            </div>
          ))}
      </div>
      {hasSeveralFeaturedNews && (
        <div className="news-carousel__arrows">
          <NavArrow
            direction="left"
            size={30}
            onClickAction={() => {
              back();
            }}
          />
          <NavArrow
            direction="right"
            size={30}
            onClickAction={() => {
              next();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SingleFeaturedNewsSlider;
