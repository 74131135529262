import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

const defaultValues = [
    {
        amount: "8",
        text: i18next.t("landing.counter.cta1"),
    },
    {
        amount: "20+",
        text: i18next.t("landing.counter.cta2"),
    },
    {
        amount: "167",
        text: i18next.t("landing.counter.cta3"),
    },
]

const ElgigantenValues = [
    {
        amount: "50.000+",
        text: "visitors",
    },
    {
        amount: "10.000",
        text: "Connected computers",
    },
    {
        amount: "900 sqm",
        text: "gaming store",
    },
]

const InfoCounter: React.FC = () => {    
    return (                   
        <div className="info__counter">
            <div className="counter">
                {(brand.brandValue === "ELGIGANTEN" ? ElgigantenValues : defaultValues).map((item, index) => {
                    return (
                        <div className="counter__item" key={index}>
                            <span className="counter__count">
                                {item.amount}
                            </span>
                            <span className="counter__heading">
                                {item.text}
                            </span>
                        </div>
                    );
                })}
            </div>
            {brand.brandValue === "ELGIGANTEN" ? (
                <></>
            ) : (
                <div className="counter__description">
                    <p>
                        {i18next.t("landing.counter.support")}
                    </p>
                </div>
            )}
        </div>
    );
};

export default InfoCounter;