import React, { FC, useEffect, useState } from "react";
import { useCurrentUserProfile } from "./../../../src/store/auth/userProfile.store";
import { auth } from "./../../../src/api/firebase/firebase.config";
import { createQuickAccountUser } from "../../../src/utils/sharedHelperFunctions";
import { Spinner } from "./../../../src/components/Spinner/index";
import { useNavigate, useParams } from "react-router-dom";
import { siteRoutes } from "../../routes/siteRoutesList";

export const Challenge1337Redirect: FC<{}> = ({}) => {
  const [loadingStatus, setLoadingStatus] = useState<string>("Loading");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  // Redirect
  const handleRedirect = (res) => {
    setIsLoading(true);

    const user = res || currentUser;
    if (user) {
      setLoadingStatus("loading");
      setIsLoading(false);
      return navigate(siteRoutes["leet"]);
    }
  };

  // Anno login
  const handleSignAnonymously = async () => {
    setIsLoading(true);
    setLoadingStatus("Creating a quick account");
    await createQuickAccountUser(setProfile).then((res) => {
      return handleRedirect(res);
    });
  };

  // Start flow
  const handleNextStep = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if ((currentUser && currentUser.uid) || (auth && auth.currentUser?.uid)) {
      return handleRedirect(null);
    }

    if (auth && !auth?.currentUser?.uid) {
      return handleSignAnonymously();
    }
  };

  useEffect(() => {
    if (!isLoading && loadingStatus === "Loading" && auth) {
      handleNextStep();
    }
  }, [auth.currentUser]);

  return (
    <div className="spacecoins-redirect">
      <div>
        <h1>{loadingStatus}</h1>
        <Spinner />
      </div>
    </div>
  );
};
