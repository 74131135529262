import React, { useState, useCallback, useMemo, useEffect } from "react";
import { EditWithNoInitImage } from "./modals/EditWithNoInitImage";
import { EditWithInitImage } from "./modals/EditWithInitImage";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { DeleteModal } from "./modals/DeleteModal";
import { refreshUser } from "@/utils/sharedHelperFunctions";

export const EditProfileModal: React.FunctionComponent<{
  openEditModal: boolean;
  setLogoSrc: (arg: string | null) => void;
  setOpenEditModal: (arg: boolean) => void;
  logoSrc: string | null;
  name: string;
  isPlaceHolder: boolean;
  avatarHistory: any;
  profileCroppedImage: any;
  setCroppedProfileImage: any;
  handleInputChange: any;
  setCroppedAreaPixels: any;
  croppedAreaPixels: any;
}> = ({
  logoSrc,
  name,
  setOpenEditModal,
  setLogoSrc,
  openEditModal,
  profileCroppedImage,
  setCroppedProfileImage,
  handleInputChange,
  setCroppedAreaPixels,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [profileImage, setProfileImage] = useState(logoSrc);
  const [crop, setCrop] = useState({ x: 1, y: 1 });
  const [zoom, setZoom] = useState(1);
  const [currentContentOfModal, setCurrentContentOfModal] =
    useState("Edit image");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setProfileImage(logoSrc);
    setCroppedProfileImage(profileCroppedImage || logoSrc);
  }, [logoSrc, openEditModal]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsValue) => {
    setCroppedAreaPixels(croppedAreaPixelsValue);
  }, []);

  const onClose = useCallback(() => {
    setOpenEditModal(false);
    setCurrentContentOfModal("Edit image");
  }, [logoSrc]);

  // REMOVE IMAGE
  const removeImage = async () => {
    setCroppedProfileImage(null);

    await QueryDB.updateDoc("profiles", currentUser.uid, {
      profileLogoUrl: null,
    });
    refreshUser(setUserProfile, currentUser);
    setLogoSrc(null);
  };

  useEffect(() => {
    setCurrentContentOfModal("Edit image");
  }, [logoSrc]);

  const contentToDisplay: { [key: string]: JSX.Element } = useMemo(
    () => ({
      "Edit image": (
        <EditWithInitImage
          onCropComplete={onCropComplete}
          crop={crop}
          zoom={zoom}
          profileCroppedImage={profileCroppedImage}
          setCrop={setCrop}
          setZoom={setZoom}
        />
      ),
      "Confirm delete": (
        <DeleteModal
          logoSrc={logoSrc}
          setCurrentContentOfModal={setCurrentContentOfModal}
          removeImage={removeImage}
        />
      ),
    }),
    [
      crop,
      handleInputChange,
      logoSrc,
      name,
      onClose,
      onCropComplete,
      profileImage,
      removeImage,
      profileCroppedImage,
      zoom,
    ]
  );

  return (
    <div className="edit-profile__container">
      <div className="edit-profile">
        {contentToDisplay[currentContentOfModal]}
      </div>
    </div>
  );
};
