import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider_ID, auth } from "@/api/firebase/firebase.config";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { auhtOptions } from "../utils/customAuthOptions";
import { CustomSignInButton } from "./CustomAuthButton";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { SignUpAnonymously } from "./SignUpAnonymously";

export const FirebaseAuthMethods: FC<{
  email: string;
}> = ({ email }) => {
  const navigate = useNavigate();
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const setUser = useAuthPersistState((state) => state.setUser);
  const cloudFunctionUrl =
    "https://europe-west1-world-of-gamers-d663f.cloudfunctions.net/app/oauth/";
  const uiConfig = {
    // signInSuccessUrl: "/challenges",
    signInFlow: "popup",
    signInOptions: [
      (brand.authMethod === "email" || brand.authMethod === "emailNoVerify") &&
        GoogleAuthProvider_ID, // Google login
    ],
    callbacks: {
      signInSuccessWithAuthResult: async (res) => {
        const profileRef = await collectionDoc.profiles(res.user.uid);
        const profile = profileRef.data();

        if (profile) {
          localStorage.removeItem("authEmailLocal");
          setProfile(profile);
          setUser(res.user);
          navigate("/dashboard");
        }
        if (!profile || res.additionalUserInfo.isNewUser) {
          localStorage.setItem("signUpMethod", "google");
          localStorage.setItem("authEmailLocal", email);
          navigate("/enter-username");
        }
      },
    },
  };

  const isSignUp = window.location.pathname.includes("signup");
  return (
    <div className="actions">
      {isSignUp && brand.hasAuth.quickSignUp && (
        <div className="actions__card">
          {brand.hasAuth.quickSignUp && <SignUpAnonymously />}
        </div>
      )}

      {brand.hasAuth.google && (
        <div className="actions__card">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      )}

      {auhtOptions.map(
        ([logo, backgroundColor, suffix, longText, shortText]) => {
          if ((logo && longText) || shortText) {
            return (
              <div className="actions__card">
                <CustomSignInButton
                  logo={logo}
                  backgroundColor={backgroundColor}
                  url={`${cloudFunctionUrl}${suffix}`}
                  longText={longText}
                  shortText={shortText}
                  key={shortText}
                />
              </div>
            );
          }
        }
      )}
    </div>
  );
};
