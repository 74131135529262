import React, { FC } from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import plusIcon from "../../../../../assets/actions/plus.svg";
import { createOptions } from "@/utils/constants/CreateOption";
import { locationOptions } from "@/features/gather/utils/locationOptions";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { CreateGatherMeetAmount } from "@/utils/constants/dropDownOption";
import { RadioInputsGroup } from "@/components";
import LocationFinder from "@/components/LocationFinder/locationFinder";
import { CreateGatherTypes } from "@/features/gather/utils/createGatherValuesAndProps";

export const CreateGatherStep2: FC<{
  setNewGatherValue: any;
  gatherValues: CreateGatherTypes;
  error:
    | {
        error: string;
        message: string;
      }
    | undefined;
}> = ({ gatherValues, setNewGatherValue, error }): JSX.Element => {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <div className="gather-meet">
          <div className="gather-type">
            <RadioInputsGroup
              name="onlyWins"
              options={createOptions.meetType}
              label="Where do you meet"
              error={undefined}
              setFieldValue={setNewGatherValue}
              initVal={gatherValues.meetType || "Record"}
            />
          </div>
          {gatherValues.meetType === "online" && (
            <ul className="gather-meet__online">
              {locationOptions.map((meetUrlOrAddress, index) => {
                const key = index;
                return (
                  <li
                    key={key}
                    aria-hidden
                    className={`gather-meet__online--platforms ${
                      meetUrlOrAddress.platform ===
                        gatherValues.meetTypeSecondary &&
                      "gather-meet__online--selected"
                    }`}
                    onClick={() => {
                      setNewGatherValue(
                        "meetTypeSecondary",
                        meetUrlOrAddress.platform
                      );
                    }}
                  >
                    <img height={30} src={meetUrlOrAddress.logo!} alt="" />
                  </li>
                );
              })}
              <li
                aria-hidden
                className={`gather-meet__online--platforms ${
                  gatherValues.meetTypeSecondary === "other" &&
                  "gather-meet__online--selected"
                }`}
                onClick={() => {
                  setNewGatherValue("meetTypeSecondary", "other");
                }}
              >
                <img height={30} src={plusIcon} alt="" />
              </li>
            </ul>
          )}
          <div className="mb-2">
            {gatherValues.meetType === "online" &&
              gatherValues.meetTypeSecondary && (
                <BaseTextInput
                  textType="text"
                  label=""
                  value={gatherValues.meetUrlOrAddress}
                  setObjectState={setNewGatherValue}
                  setObjectStateTargetName="meetUrlOrAddress"
                  placeholder={`Insert a ${gatherValues.meetTypeSecondary} link to the gather here...`}
                />
              )}
            {gatherValues.meetType === "offline" && (
              <LocationFinder
                textType="text"
                label=""
                value={gatherValues.meetUrlOrAddress}
                setObjectState={setNewGatherValue}
                setObjectStateTargetName="meetUrlOrAddress"
                placeholder="Type in the address of your gather..."
              />
            )}
            {error && (
              <div className="mb-4">
                <FormErrorMessage
                  text={error.message}
                  isVisible={error.error === "meetUrlOrAddress"}
                />
              </div>
            )}
          </div>
        </div>
        <label htmlFor="frequencySelect" className="mb-3 mt-2 w-50">
          <h5>How often do you meet</h5>
          <select
            onChange={(e) => {
              setNewGatherValue("eventFrequencyType", e.target.value);
            }}
            className="select-gathers-dropdown"
            name=""
            id="frequencySelect"
          >
            {CreateGatherMeetAmount.map((val, index) => {
              const key = index;
              return (
                <option
                  key={key}
                  selected={val.value === gatherValues.eventFrequencyType}
                  value={val.value}
                >
                  {val.name}
                </option>
              );
            })}
          </select>
        </label>
        <div className="gather-meet__length">
          <h5>All day event</h5>
          <div>
            <div
              className={`switch ${
                gatherValues.isAllDay === true
                  ? "gather-meet__length--active"
                  : "gather-meet__length--inactive"
              } `}
            >
              <input
                onChange={() => {
                  setNewGatherValue("isAllDay", !gatherValues.isAllDay);
                }}
                id="toggle"
                type="checkbox"
                name="toggle"
              />
              <span />
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className="gather-meet__date">
          <>
            <div className="flex-column d-flex">
              <div className="d-flex me-1">
                <DatePicker
                  locale={enGB}
                  className="base-input me-2"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="Start date"
                  onChange={(e: any) => {
                    setNewGatherValue("startDate", e);
                  }}
                  selected={gatherValues.startDate}
                  value={gatherValues.startDate}
                  todayButton="Today"
                  isClearable
                />
                {!gatherValues.isAllDay && (
                  <DatePicker
                    selected={gatherValues.startTime}
                    onChange={(e: any) => {
                      setNewGatherValue("startTime", e);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    className="base-input me-2"
                    placeholderText="Start time"
                    value={gatherValues.startTime}
                    isClearable
                    dateFormat="HH:mm"
                    locale={enGB}
                    timeFormat="HH:mm"
                  />
                )}
              </div>
            </div>
            <div className="flex-column d-flex">
              <div className="d-flex ">
                <DatePicker
                  locale={enGB}
                  className="base-input me-2"
                  minDate={new Date()}
                  onChange={(e: any) => {
                    setNewGatherValue("endDate", e);
                  }}
                  placeholderText="End date"
                  selected={gatherValues.endDate}
                  value={gatherValues.endDate}
                  dateFormat="dd/MM/yyyy"
                  todayButton="Today"
                  isClearable
                />
                {!gatherValues.isAllDay && (
                  <DatePicker
                    selected={gatherValues.endTime}
                    onChange={(e: any) => {
                      setNewGatherValue("endTime", e);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    className="base-input me-2"
                    placeholderText="End time"
                    value={gatherValues.endTime}
                    dateFormat="HH:mm"
                    isClearable
                    locale={enGB}
                    timeFormat="HH:mm"
                  />
                )}
              </div>
            </div>
          </>
        </div>

        {error && (
          <div className="mb-4">
            <FormErrorMessage
              text={error.message}
              isVisible={error.error === "date"}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};
