import { handleScrollToTreasureHunt } from "./handleScrollToTreasureHunt";
import { createToast } from "./../../../../src/utils/toaster/createToast";

export const handleTreasureHuntQrCode = (
  result,
  hasAllCodes,
  setOpenCamera,
  setThidToUse
) => {
  try {
    if (result && result.text) {
      const formattedSlug = result.text.split("/").at(-1);
      setThidToUse(formattedSlug);
      setOpenCamera(false);
      handleScrollToTreasureHunt(hasAllCodes, formattedSlug);
    }
  } catch (e) {
    createToast({
      type: "error",
      message: "Failed to connect!",
    });
  }
};
