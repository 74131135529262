import React, { FC } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import plusIcon from "./assets/plus-icon.svg";
import { Badge } from "@/components";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const OverviewArchive: FC<{
  headline: string;
  linkToBtnText?: string;
  linkToBtnLink?: string;
  subtitle: string;
  filterButtons?: any[];
  selectedFilter: string;
  setSelectedFilter: any;
  isOldV?: boolean;
  children?: any;
  disableBadges: boolean | undefined;
  background: string;
  setChallengeTitle?: any;
}> = ({
  filterButtons,
  selectedFilter,
  setSelectedFilter,
  headline,
  linkToBtnLink,
  linkToBtnText,
  subtitle,
  children,
  disableBadges,
  background,
  setChallengeTitle,
}) => {
  return (
    <>
      <div className="archive__image align-full">
        <figure className="adaptive">
          <picture className="adaptive-photo">
            <img src={background} />
          </picture>
        </figure>
        <div className="archive__content ">
          <div className="overview-archive container">
            <div className="overview-archive__content">
              <h1>{headline}</h1>
              {!brand.event.isEvent && linkToBtnLink ? (
                <Link to={linkToBtnLink} className="btn btn-create-text">
                  <span className="overview-archive__content--create">
                    <img src={plusIcon} width={23} height={23} alt="" />

                    <span className="btn-text">{i18next.t(linkToBtnText)}</span>
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
            <h5>{i18next.t(subtitle)}</h5>
            {children}
            {!children && filterButtons && (
              <div className="badge-wrapper">
                {filterButtons.map((filter) => {
                  return (
                    <Badge
                      key={filter.value}
                      variant={
                        filter.value === selectedFilter
                          ? "primary"
                          : "secondary"
                      }
                      customStyles={`${disableBadges ? "bg-disabled" : ""}`}
                      onClickAction={() => {
                        if (!disableBadges) {
                          setSelectedFilter(filter.value);

                          if (setChallengeTitle) {
                            setChallengeTitle(filter.text);
                          }
                        }
                      }}
                    >
                      {i18next.t(filter.text)}
                    </Badge>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
