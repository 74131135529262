import { FC } from "react";
import logo from "@/assets/placeholders/placeholder.png";

const ProfileCardLoader: FC = (): JSX.Element => {
  return (
    <div className="profile__card profile-Placeholder__card">
      <div className="profile-Placeholder__avatar">
        <div className="profile-Placeholder__image">
          <img src={logo} className="custom-placeholder wave" />
        </div>
      </div>
      <div className="profile-Placeholder__content">
        <h1 className="profile-Placeholder__username custom-placeholder wave ">
          placeholder
        </h1>

        <p className="profile-Placeholder__discription custom-placeholder wave">
          placeholder
        </p>

        <div className="profile-Placeholder__community">
          {Array.from(Array(3), (e, index) => {
            return (
              <span key={index} className="custom-placeholder wave">
                placeholr
              </span>
            );
          })}
        </div>
        <div className="profile-Placeholder__button">
          <p className="custom-placeholder wave">placeholder</p>
        </div>
      </div>
    </div>
  );
};
export default ProfileCardLoader;
