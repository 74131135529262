import firebase from "firebase";
import { useEffect, useState } from "react";

export function useHighlights(game: string, amount: number) {
    const [highlights, setHighlights] = useState(null);
    const [highlightsLoading, setHighlightsLoading] = useState(true);
    
    useEffect(() => {
        const databaseRef = firebase.database().ref(`highlights/${game}`).orderByKey().limitToLast(amount);
    
        const handleData = (snapshot) => {
            if (snapshot.exists()) {
                setHighlights(Object.values(snapshot.val()));
            } else {
                console.log("No data available");
                setHighlights(null);
            }
            setHighlightsLoading(false);
        };
    
        databaseRef.on('value', handleData);
    
        const unsubscribe = () => databaseRef.off('value', handleData);
    
        return () => {
            unsubscribe();
        };
    }, [game, amount]);

    return {highlights, highlightsLoading};
}