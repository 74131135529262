import { ErrorFallback, Spinner } from "@/components";
import React, { useEffect, useState } from "react";
import { ListByDayItem } from "./ListByDayItem";

export const ListByDay: React.FC<{
  buttonNames: any;
  loading: boolean;
  error: any;
  data: any;
  availableHours: any;
}> = ({ buttonNames, loading, error, data, availableHours }) => {
  useEffect(() => {
    const divElements = document.querySelectorAll(".tabs__tab div");

    // Iterate through each div element
    divElements.forEach((div) => {
      // Check if the div has no child nodes or only whitespace
      if (!div.firstChild || div.innerHTML.trim() === "") {
        // Remove the empty div from its parent node
        div.parentNode.removeChild(div);
      }
    });
  }, [data]);

  return (
    <div className="tabs">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <ErrorFallback>Failed to load winners</ErrorFallback>
          ) : (
            <div className="tabs__tab">
              {/* Looping over buttonNames array to create tab buttons */}
              {data &&
                buttonNames.map((day: any) => {
                  return (
                    <div key={day}>
                      {data?.filter((doc: any) => doc.day === day).length !==
                        0 && (
                        <>
                          <button className="tabs__btn">{day}</button>
                          <div className="tab">
                            <section className="tabs__item">
                              {/* Loop over all available hours, removing any with no data for screen size */}
                              <ul>
                                {data &&
                                data.filter((doc: any) => doc.day === day)
                                  .length !== 0 &&
                                availableHours
                                  ? availableHours.map((hour: any, index) => {
                                      const key = hour + index;
                                      const hasHourData =
                                        data.filter(
                                          (doc: any) =>
                                            doc.startHour === hour &&
                                            doc.day === day
                                        ).length !== 0;

                                      if (!hasHourData) {
                                        return;
                                      }

                                      return (
                                        <div className="item" key={key}>
                                          {hasHourData && (
                                            <div className="item__times">
                                              <p>{hour}.00</p>
                                              <span className="item__divider" />
                                              <p>{hour + 1}.00</p>
                                            </div>
                                          )}

                                          <div className="item__content">
                                            {/* Loop over all users */}
                                            {data.map((doc: any) => {
                                              if (
                                                doc.day === day &&
                                                doc.startHour === hour
                                              ) {
                                                return (
                                                  <ListByDayItem data={doc} />
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </ul>
                            </section>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
