import HeroImage from "../assets/hero_image_cs2.jpg";

export const WOG_heroVariables = {
  image: {
    src: HeroImage,
    alt: "Midjourney AI art for Counter-Strike 2",
    title: "Support for CS2 added",
  },
  heading: "Support for CS2 added",
  description:
    "Experience a new level of excitement while playing Counter-Strike 2, as we proudly introduce support for CS2, enabling you to create thrilling challenges and take on your friends, even if you don't play simultaneously.    ",
  btn: {
    link: "/create-challenge",
    label: "Create a challenge",
  },
  authorName: "World of Gamers",
};
