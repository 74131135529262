import {
  TCL_heroVariables,
  WOG_heroVariables,
  Elgiganten_heroVariables,
} from "../brands";
import heroProps from "../brands/heroProps";

const brands = {
  "tcl.iplay.dk": TCL_heroVariables, // TCL
  "localhost:5173": WOG_heroVariables, // Set for local development
  "192.168.1.153:5173": Elgiganten_heroVariables, // Set for local development
  "localhost:5174": Elgiganten_heroVariables, // Set for local development
  "elgiganten.iplay.dk": Elgiganten_heroVariables,
  "elgiganten-event.web.app": Elgiganten_heroVariables,
  "dev.wog.gg": WOG_heroVariables,
};

const getHeroProps = () => {
  // Without http:// or https:// and without "/" in the end
  const hostname = window.location.host;
  const heroVaribles = brands[hostname] || brands["default"];
  return heroVaribles;
};

const hero = getHeroProps() as heroProps;

export default hero;
