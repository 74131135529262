import React from "react";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { Button } from "@/components";
import Modal from "react-modal";

const BaseModalWindow: React.FunctionComponent<{
  show: boolean;
  closeModal: () => void;
  size?: string | undefined;
  title?: string;
  styles?: string;
  children: React.ReactNode;
}> = (props) => {
  if (!props.show) return null;

  return (
    <div>
      <Modal
        isOpen={props.show}
        onRequestClose={props.closeModal}
        className="modal"
        overlayClassName="Overlay"
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <div className="modal__dialog">
          <div className="modal__header" >
            <h2>
              {props.title}
            </h2>
            <div className="modal__close">
              <Button onClickAction={() => props.closeModal()} variant="link">
                <CloseIcon width={30} height={30} />
              </Button>
            </div>
          </div>
          <div className="modal__body">
            {props.children}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BaseModalWindow;
