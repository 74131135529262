import React, { useState } from "react";
import { ErrorFallback, Spinner } from "..";
import { ListByDayItem } from "./ListByDayItem";
export const ListByDayMobile: React.FC<{
  buttonNames: any;
  loading: boolean;
  error: any;
  data: any;
  availableHours: any;
}> = ({ buttonNames, loading, error, data, availableHours }) => {
  const [isActive, setIsActive] = useState(undefined);

  return (
    <div className="tabs">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <ErrorFallback>Failed to load winners</ErrorFallback>
          ) : (
            <>
              <div className="tabs__tab">
                {/* Looping over buttonNames array to create tab buttons */}
                {buttonNames.map((day: any) => {
                  if (isActive === undefined) {
                    const exists =
                      data?.filter((doc: any) => doc.day === day).length !== 0;
                    if (exists) {
                      setIsActive(day);
                    }
                  }
                  return (
                    <>
                      {data?.filter((doc: any) => doc.day === day).length !==
                        0 && (
                        <button
                          className={`tabs__btn ${
                            isActive === day ? "tabs__btn--active" : ""
                          }`}
                          onClick={() => setIsActive(day)}
                        >
                          {day}
                        </button>
                      )}
                    </>
                  );
                })}
              </div>
              <section className="tabs__item">
                <ul>
                  {data &&
                    data.filter((doc: any) => doc.day === isActive).length !==
                      0 &&
                    availableHours &&
                    availableHours.map((hour: any) => {
                      const hasHourData =
                        data.filter(
                          (doc: any) =>
                            doc.startHour === hour && doc.day === isActive
                        ).length !== 0;

                      if (!hasHourData) {
                        return;
                      }

                      return (
                        <div className="item">
                          {data.filter(
                            (doc: any) =>
                              doc.startHour === hour && doc.day === isActive
                          ).length !== 0 && (
                            <div className="item__times">
                              <p>{hour}.00</p>
                              <span className="item__divider" />
                              <p>{hour + 1}.00</p>
                            </div>
                          )}
                          <div className="item__content">
                            {data.map((doc: any) => {
                              if (
                                doc.day === isActive &&
                                doc.startHour === hour
                              ) {
                                return <ListByDayItem data={doc} />;
                              }
                            })}
                          </div>
                        </div>
                      );
                    })}
                </ul>
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
};
