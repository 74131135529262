export const handleAddMember = (
  friend: CommunityUser,
  membersToAdd: CommunityUser[],
  setMembersToAdd: React.Dispatch<React.SetStateAction<CommunityUser[]>>,
  checkIfSelected: (friend: CommunityUser) => boolean
) => {
  if (membersToAdd.length > 0 && checkIfSelected(friend)) {
    const array = [...membersToAdd];
    const loc = membersToAdd.indexOf(friend, 0);
    array.splice(loc, 1);
    setMembersToAdd([]);

    return setMembersToAdd(array);
  }
  if (membersToAdd && !checkIfSelected(friend)) {
    const array = [...membersToAdd, friend];
    return setMembersToAdd(array);
  }
  return setMembersToAdd([friend]);
};

export const handleRemoveMember = (
  friend: CommunityUser,
  membersToAdd: CommunityUser[],
  setMembersToAdd: React.Dispatch<React.SetStateAction<CommunityUser[]>>
) => {
  const array = [...membersToAdd];
  const loc = membersToAdd.indexOf(friend, 0);
  array.splice(loc, 1);
  setMembersToAdd([]);
  return setMembersToAdd(array);
};
