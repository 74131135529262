import React, { FC, useEffect, useState } from "react";
import { Button } from "@/components/index";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import {
  handleAddMember,
  handleRemoveMember,
} from "./helperFunction/handleAddRemoveMember";
import { handleInviteMembers } from "./helperFunction/handleInviteMember";
import BaseUserAvatar from "../BaseUserAvatar";
import { Query_Imgix } from "@/api/imgix";
import { useCommunityData } from "@/hooks/useCommunityData";
import { InviteUserModalList } from "./InviteUserModalList";
import { MembersTypes } from "@/features/team/types/MembersTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { use } from "i18next";

type props = {
  setAddMemberModalOpen: any;
  slug: string;
  notificationType: string;
  featureName: string;
  usersToFilterFrom?: any[];
};

const InviteUserModal: FC<props> = (props): JSX.Element => {
  const currentUserProfile = useCurrentUserProfile((s) => s.user);
  const currentUserSlug = currentUserProfile?.slug;
  const [community, loading_community, communityError] = useCommunityData();
  const [searchValue, setSearchValue] = useState<string>("");
  const [membersToAdd, setMembersToAdd] = useState<any[]>([]);
  const [searchedMembers, setSearchedMembers] = useState<any[]>([]);

  const checkIfSelected = (friend: CommunityUser) => {
    if (membersToAdd.find((obj) => obj.uid === friend.uid) !== undefined) {
      return true;
    }
    return false;
  };

  const [
    membersForInviting,
    _LoadingMembersForInviting,
    errorPotm,
    fetch_membersForInviting,
  ] = useCloudFunctionCall<any>(
    "getUserCommunityForInvite",
    currentUserProfile.slug
  );
  const [membersToRender, setMembersToRender] = useState<any[]>([]);

  useEffect(() => {
    if (searchValue && community) {
      let filteredVal = community.friends.users.map((user: CommunityUser) => {
        if (
          (user.fullName &&
            user.fullName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (user.userName &&
            user.userName.toLowerCase().includes(searchValue.toLowerCase()))
        ) {
          return user;
        }
        return null;
      });

      setSearchedMembers(
        filteredVal && filteredVal.filter((users) => users !== null)
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (!currentUserSlug) {
      return;
    }

    const run = async () => {
      if (communityError) {
        return <span>something went wrong</span>;
      }
    };

    if (window.location.pathname.includes("/teams/")) {
      fetch_membersForInviting({
        slug: props.slug,
      });
    } else {
      if (community) {
        run();

        setMembersToRender(community?.friends?.users);
      }
    }
  }, [currentUserSlug, community, props.slug]);

  useEffect(() => {
    if (
      window.location.pathname.includes("/teams/") &&
      membersForInviting?.dataArray
    ) {
      setMembersToRender(membersForInviting?.dataArray);
    }
  }, [membersForInviting]);

  return (
    <div className="invite">
      <div className="invite__search">
        <label htmlFor="searchMember">
          <input
            type="search"
            id="searchMember"
            placeholder="Search by username"
            className="base-input"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </label>
      </div>
      <div className="invite__content">
        <div className="invite__friends">
          <InviteUserModalList
            searchValue={searchValue}
            loading_community={loading_community || _LoadingMembersForInviting}
            membersToRender={membersToRender}
            checkIfSelected={checkIfSelected}
            membersToAdd={membersToAdd}
            setMembersToAdd={setMembersToAdd}
            searchedMembers={searchedMembers}
          />
        </div>
        <div className="invite__friends invite__friends--selected">
          <ul className="invite__list">
            {membersToAdd.length !== 0 &&
              membersToAdd.map((friend, index) => {
                const key = `${friend.userName}_${index}`;
                return (
                  <li
                    className={`invite__item ${
                      checkIfSelected(friend) && "invite__item--selected"
                    }`}
                    aria-hidden
                    onClick={() => {
                      handleRemoveMember(friend, membersToAdd, setMembersToAdd);
                    }}
                    key={key}
                  >
                    <BaseUserAvatar
                      logoUrl={friend.profileLogoUrl}
                      userName={friend.userName}
                      customStyles=""
                    />
                    <div className="item__info">
                      <p>{friend.userName}</p>
                      <span>{friend.fullName}</span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      <div className="invite__actions">
        <Button
          type="button"
          variant={membersToAdd.length === 0 ? "secondary" : "primary"}
          disabled={membersToAdd.length === 0}
          onClickAction={() => {
            handleInviteMembers(
              membersToAdd,
              currentUserProfile,
              props.slug,
              props.notificationType,
              props.setAddMemberModalOpen,
              props.featureName
            );
          }}
        >
          Send invites
        </Button>
      </div>
    </div>
  );
};

export default InviteUserModal;
