import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { FC } from "react";
import { VerifiyEmail } from "./VerifyEmail";
import { VerifyPhoneNumber } from "./VerifyPhoneNumber";
import { EmailNoVerify } from "./EmailNoVerify";

export const VerifiyProfileForm: FC<{}> = ({}) => {
  return (
    <>
      {brand.authMethod === "emailNoVerify" && <EmailNoVerify />}
      {brand.authMethod === "email" && <VerifiyEmail />}
      {brand.authMethod === "phone" && <VerifyPhoneNumber />}
    </>
  );
};
