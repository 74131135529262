import { Button, Spinner } from "@/components";
import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import { AuthCard } from "./../components/AuthCard";
/*import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";*/
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";

export const VerifyEmail: FC<{}> = (): JSX.Element => {
  const [code, setCode] = useState<{
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  }>({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  });

  const setFieldValue = async (targetName: number, value: any) => {
    return setCode((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const navigate = useNavigate();
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  if (localStorage.getItem("authEmailLocal") === undefined) {
    navigate("/signup");
    return;
  }

  const baseAuthenticationProps = {
    method: "email",
    emailAddress: localStorage.getItem("authEmailLocal"),
    platform: brand.brandValue,
  } as AuthenticationProps;

  const resendCode = async () => {
    await authenticate({
      ...baseAuthenticationProps,
      action: "resent-verification-code",
    });
  };

  const handleVerifyEmail = async () => {
    let concatenatedString = "";

    Object.keys(code).forEach((key) => {
      const value = code[key];
      if (typeof value === "number") {
        concatenatedString += value.toString();
      }
    });

    await authenticate({
      ...baseAuthenticationProps,
      verificationCode: concatenatedString,
      action: "verify-email",
    });
  };

  // Auto run if code is 6 digits
  useEffect(() => {
    if (Object.values(code).every((value) => typeof value === "number")) {
      handleVerifyEmail();
    }
  }, [code]);

  useEffect(() => {
    if (
      response &&
      Object.values(code).every(
        (value) =>
          typeof value === "number" ||
          Object.values(code).every((value) => value === null)
      )
    ) {
      if (response.status === "verification-code-resent") {
        return createToast({
          type: "info",
          message: response.payload,
        });
      }

      if (response.status === "email-verification-sent") {
        return createToast({ type: "success", message: response.payload });
      }

      if (response.status === "user-verified") {
        createToast({
          type: "success",
          message: "Code accepted.",
        });
        return navigate(response.redirectURL);
      } else {
        createToast({
          type: "error",
          message: response.payload,
        });
      }
    }
  }, [response]);
  // Page Document Title
  useDocumentTitle(i18next.t("auth.verify.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__verify">
        <div className="verify">
          <AuthCard
            title={i18next.t("auth.verify.headline")}
            type="verify-email"
          >
            {/* Verify Email */}
            <div className="input verify__input">
              {Array.from(Array(6), (_, index) => {
                const key = `auth-${index}`;
                return (
                  <input
                    className="base-input"
                    id={`authcode-${index}`}
                    type="text"
                    maxLength={1}
                    value={code[index] === null ? "" : code[index]}
                    key={key}
                    disabled={_loading}
                    onPaste={async (e) => {
                      const pastedCode = await navigator.clipboard.readText();

                      setCode({
                        0: Number(pastedCode[0]),
                        1: Number(pastedCode[1]),
                        2: Number(pastedCode[2]),
                        3: Number(pastedCode[3]),
                        4: Number(pastedCode[4]),
                        5: Number(pastedCode[5]),
                      });
                    }}
                    onChange={(e: any) => {
                      if (!e.target.value && e.target.value !== "0") {
                        return setFieldValue(index, null);
                      }

                      if ((Number(e.target.value), code)) {
                        setFieldValue(index, Number(e.target.value));

                        return document
                          .getElementById(`authcode-${index + 1}`)
                          .focus();
                      }
                    }}
                  />
                );
              })}
            </div>

            <div>{_loading && <Spinner />}</div>
            <p>
              <small className="signup__actions">
                {i18next.t("auth.verify.validity")}
              </small>
            </p>
            <Button
              onClickAction={() => resendCode()}
              disabled={_loading}
              variant="link"
              customStyles="btn-request-code"
            >
              {i18next.t("auth.input.button.request")}
            </Button>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
