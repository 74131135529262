import React from "react";
import BaseUserAvatar from "../BaseUserAvatar";

const SearchInputDropdownItem: React.FunctionComponent<{
  link: string;
  name: string;
  type: string;
  profileLogoUrl?: string;
  logoUrl?: string;
  teamLogo?: string;
  imageURL?: string;
  title?: string;
  productLogoUrls?: string | string[];
  platform?: string;
  setClicked: (arg: boolean) => void;
}> = ({
  link,
  name,
  profileLogoUrl,
  productLogoUrls,
  imageURL,
  title,
  teamLogo,
  logoUrl,
  setClicked,
  platform,
}) => {
  const logoSrc =
    profileLogoUrl ||
    logoUrl ||
    teamLogo ||
    imageURL ||
    (Array.isArray(productLogoUrls) ? productLogoUrls[0] : productLogoUrls);
  return (
    <div className="result">
      <div className="result__image">
        <BaseUserAvatar logoUrl={logoSrc} userName={name} />
      </div>
      <div className="result__name">
        <span className="result__username">{name || title}</span>
      </div>
    </div>
  );
};

export default SearchInputDropdownItem;
