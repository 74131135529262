import BaseUserAvatar from "@/components/BaseUserAvatar";

type CustomSigninProps = {
  logo: string;
  backgroundColor: string;
  url: string;
  longText: string;
  shortText: string;
};

export const CustomSignInButton: React.FunctionComponent<CustomSigninProps> = ({
  logo,
  backgroundColor,
  url,
  longText,
  shortText,
}) => {
  return (
    <a
      onClick={() => {
        localStorage.setItem("signUpMethod", shortText);
      }}
      href={url}
      style={{ backgroundColor }}
      className="btn"
    >
      <figure>
        <BaseUserAvatar logoUrl={logo} userName="" />
      </figure>

      <span>
        <p>{longText}</p>
      </span>
    </a>
  );
};
