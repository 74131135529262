import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NewsOverviewUi } from "./NewsOverview.ui";
import { useMemo } from "react";
import { AllNewsItem } from "./NewsCardErrorWrapper";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { NewsOverviewLoaderCard } from "@/components/skeletonLoaders/NewsoverviewLoader/Newsloader";
import { useNewsData } from "../../hooks/useNewsData";
import { useInView } from "react-intersection-observer";

type NewsFilters = "discoverNew" | "editorial" | "userCreated" | "mine";
export const NewsOverview = ({ slug }: any): JSX.Element => {
  const navigate = useNavigate();
  const [selectedGame, setSelectedGames] = useState<string | undefined>("");
  const { ref, inView } = useInView({
    threshold: 0.25,
  });
  const [selectedCreatorType, setSelectedCreatorType] =
    useState<NewsFilters>("discoverNew");

  // Check if slug is present!
  if (slug) {
    navigate(`${siteRoutes["newsAndSlug"]}${slug}`);
  }

  const { allNews, featuredNews } = useNewsData({
    client: brand.brandValue,
    limit: 20,
  });

  useEffect(() => {
    if ((!allNews.loading && !allNews.data) || allNews.data.length === 0) {
      allNews.fetchNewData();
    }
  }, []);

  // Fetch more on scroll
  useEffect(() => {
    if (!allNews.loading && inView) {
      allNews.fetchMoreNews();
    }
  }, [inView, allNews.hasMore]);

  // Check if inview
  const inViewRefElementIndex = useMemo(() => {
    if (allNews.data) {
      return !!allNews.data.length ? allNews.data?.length - 1 : -1;
    }
  }, [allNews.data, allNews.hasMore]);

  return (
    <div>
      <NewsOverviewUi
        slug={slug}
        selectedCreatorType={selectedCreatorType}
        setSelectedCreatorType={setSelectedCreatorType}
        setFilters={undefined}
        setSelectedGames={setSelectedGames}
        selectedGame={selectedGame}
        allNews={allNews.data}
        featuredNews={featuredNews.data}
        allNewsLoading={allNews.loading}
        featuredNewsLoading={featuredNews.loading}
      >
        <div className="news__header">
          <h1>News</h1>
          <p>
            Most recent news from {brand.name}{" "}
            {brand.event.isEvent
              ? "during *insert_event_title*"
              : "and the game industry"}
            .
          </p>
        </div>
        <div className="news__list">
          {allNews.data &&
            allNews.data.map((news, index: number) => {
              return (
                <div
                  key={news.slug}
                  ref={index === inViewRefElementIndex ? ref : null}
                >
                  {AllNewsItem(news, allNews.loading)}
                </div>
              );
            })}
          {/* ADD SKELETON LOADER HERE WITH  hasMore & _loading_news AS CONDITIONS */}
          {allNews.loading
            ? Array.from(Array(4), (_, ind) => {
                return <NewsOverviewLoaderCard key={ind} />;
              })
            : ""}
        </div>
      </NewsOverviewUi>
    </div>
  );
};
