import { UserTeamJunction } from "@/models/database/teams/userTeamJunction.model";
import { create } from "zustand";

type CurrentUserRelationWithTeam = {
  currUserStatus: UserTeamJunction["status"] | "new";
  generalRole: UserTeamJunction["generalRole"];

  //.. add more fields if required
};

interface IUserTeamJunction {
  userTeamJunction: {
    [teamId: string]: CurrentUserRelationWithTeam;
  };
  //   updateTeamJunction: (teamId: string, newUpdate: UserTeamJunction) => void;
  setTeamJunction: (
    teamId: string,
    newUpdate: CurrentUserRelationWithTeam
  ) => void;
  getUserTeamJunction: (
    teamId: string
  ) => CurrentUserRelationWithTeam | undefined;
  removeTeamJunction: (teamId: string) => void;
}

export const userTeamJunctionStore = create<IUserTeamJunction>()(
  (set, get) => ({
    userTeamJunction: {},
    getUserTeamJunction: (teamId) => {
      return (
        get().userTeamJunction?.[teamId] || {
          currUserStatus: "new",
          generalRole: "random",
        }
      );
    },
    removeTeamJunction: (teamId) =>
      set((state) => {
        const newUpdate = { ...state.userTeamJunction };
        delete newUpdate[teamId];
        return {
          userTeamJunction: newUpdate,
        };
      }),
    setTeamJunction: (teamId, newUpdate) =>
      set((state) => {
        return {
          userTeamJunction: {
            ...state.userTeamJunction,
            [teamId]: newUpdate,
          },
        };
      }),
  })
);
