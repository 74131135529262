import React, { FC } from "react";
import DiscussionCommentComponent from "./DiscussionComment.fetcher";

type Props = {
  setShowEmoji: React.Dispatch<React.SetStateAction<boolean>>;
  gatherPostId: string;
  showEmoji: boolean;
  gatherId: string;
};

export const DiscussionComment: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <DiscussionCommentComponent
        setShowEmoji={props.setShowEmoji}
        showEmoji={props.showEmoji}
        gatherPostId={props.gatherPostId}
        gatherId={props.gatherId}
      />
    </>
  );
};
