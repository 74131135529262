import { FC } from "react";
import StaticOverviewUI from "./components/staticOverviewUI";
import TrackerStepOne from "./components/trackerStepOne";
import TrackerStepTwo from "./components/trackerStepTwo";
import TrackerStepThree from "./components/trackerStepThree";
import TrackerFAQ from "./components/trackerFAQ";
import useDocumentTitle from "@/hooks/useDocumentTitle";

const TrackerPage: FC = (): JSX.Element => {
  // Page Document Title
  useDocumentTitle('Get started and download our tracker');
  
  return (
    <div className="tracker-page">
        <StaticOverviewUI />
        <div className="tracker-page__steps">
          <div className="steps">
            <TrackerStepOne />
            <TrackerStepTwo />
            <TrackerStepThree />
          </div>
        </div>
        <div className="tracker-page__faq">
          <TrackerFAQ />
        </div>
      </div>
  );
};

export default TrackerPage;