import React, { ReactElement } from "react";
import { ImUser as UserIcon } from "@react-icons/all-files/im/ImUser";
import { ImUsers as CommunityIcon } from "@react-icons/all-files/im/ImUsers";
import { BsLightningFill as MicrosponsorIcon } from "@react-icons/all-files/bs/BsLightningFill";

export const CreatorTypesOptions: {
  [key: string]: { logo: ReactElement; activeColor: string };
} = {
  Community: {
    logo: <CommunityIcon fill="white" size={24} />,
    activeColor: "purple",
  },
  Microsponsor: {
    logo: <MicrosponsorIcon fill="white" width="32" />,
    activeColor: "pink",
  },
  "Created By You": {
    logo: <UserIcon fill="white" size={24} />,
    activeColor: "blue",
  },
};
