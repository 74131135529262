import React, { FC, useState } from "react";
import { Button, Dropdown, Image } from "react-bootstrap";
import firebase from "firebase";

export type ChatMessageProps = {
  message: any;
  profile: any;
  selectedChat: string;
};
export const ChatMessage: FC<ChatMessageProps> = ({
  message,
  profile,
  selectedChat,
}) => {
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const [isMessageEdit, setIsMessageEdit] = useState<boolean>(false);
  const [newMessageValue, setNewMessageValue] = useState<string>(
    message.data.content?.message
  );
  const otherUserUid = selectedChat.replace(profile?.uid, "").replace("_", "");

  const HandleUpdateMessage = (type: "delete" | "update" | "deleteAll") => {
    const dbRef = firebase.database().ref();

    if (type === "update") {
      dbRef.update({
        [`chat/${selectedChat}/${message.uid}/edited/state`]: true,
        [`chat/${selectedChat}/${message.uid}/edited/${new Date().getTime()}`]:
          newMessageValue,
      });
      setIsMessageEdit(false);
    }

    if (type === "delete") {
      dbRef.update({
        [`chat/${selectedChat}/${message.uid}/deletedBy/${profile?.uid}`]: true,
      });
      setIsMessageEdit(false);
    }
    if (type === "deleteAll") {
      dbRef.update({
        [`chat/${selectedChat}/${message.uid}/deletedBy/${profile?.uid}`]: true,
        [`chat/${selectedChat}/${message.uid}/deletedBy/${otherUserUid}`]: true,
      });
      // dbRef.update({
      //   [`chat/${selectedChat}/${message.uid}/deletedBy/${profile?.uid}`]: true,
      // });

      setIsMessageEdit(false);
    }
  };

  const handleLoadMessage = () => {
    if (message.data?.deletedBy.wog) {
      return message.data.content?.message;
    }
    if (
      message.data?.deletedBy[profile.uid] === true &&
      message.data?.deletedBy[otherUserUid] === true
    ) {
      return "This message has been deleted for everyone";
    }

    if (
      message.data?.deletedBy[profile.uid] === true &&
      message.data?.deletedBy[otherUserUid] === false
    ) {
      return "This message has been deleted for you";
    }

    if (message?.data?.edited?.state === true) {
      return Object.entries(message.data.edited)[
        Object.entries(message.data.edited).length - 2
      ][1];
    }

    return message.data.content?.message;
  };

  return (
    <>
      {selectedChat && profile && (
        <div className="chat-view__message">
          <p
            className={
              message.data.sender === profile?.uid
                ? "chat-view__message-sender"
                : "chat-view__message-receiver"
            }
            aria-hidden
            onDoubleClick={() => {
              if (
                (message?.data?.sender === profile?.uid &&
                  !message?.data?.deletedBy[profile?.uid]) ||
                !message?.data?.deletedBy[profile?.uid] ||
                (message?.data?.sender === profile.uid &&
                  !message?.data?.deletedBy[otherUserUid])
              ) {
                setMessageModalOpen(!messageModalOpen);
                setTimeout(() => {
                  setMessageModalOpen(false);
                }, 5000);
              }
            }}
          >
            <span
              title={`${
                message.data.sender === profile?.uid
                  ? "Sent by you on "
                  : `Recieved on `
              }${new Date(message.data.created).toLocaleTimeString()}`}
            >
              {isMessageEdit ? (
                <input
                  className="base-input"
                  type="text"
                  onChange={(e) => {
                    setNewMessageValue(e.target.value);
                  }}
                  value={newMessageValue}
                />
              ) : (
                <>
                  {message && (
                    <span
                      className={` chat-view__message ${
                        message?.data?.deletedBy &&
                        message?.data?.deletedBy[profile?.uid] === true
                          ? "deleted"
                          : ""
                      } ${
                        message?.data?.edited?.state === true ? "edited" : ""
                      } `}
                    >
                      {!message?.data?.deletedBy[profile?.uid] &&
                        message?.data?.edited?.state === true && (
                          <>
                            <span>Edited ●</span>{" "}
                          </>
                        )}

                      {handleLoadMessage()}
                    </span>
                  )}
                </>
              )}

              {isMessageEdit && (
                <div className="action-buttons">
                  {" "}
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={() => {
                      HandleUpdateMessage("update");
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setIsMessageEdit(false);
                      setNewMessageValue(message.data.content?.message);
                    }}
                    size="sm"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              )}

              {messageModalOpen && (
                <div
                  className={`chat-view__message-settings-modal ${
                    message.data.sender === profile?.uid ? "right " : `left `
                  }`}
                >
                  <Dropdown>
                    {message?.data?.sender === profile?.uid &&
                    !message?.data?.deletedBy[profile?.uid] ? (
                      <Dropdown.Item>
                        <button
                          type="button"
                          onClick={() => {
                            setIsMessageEdit(true);
                            setMessageModalOpen(false);
                          }}
                        >
                          Edit message
                        </button>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                    {!message?.data?.deletedBy[profile?.uid] && (
                      <>
                        <Dropdown.Item>
                          <button
                            className="danger"
                            type="button"
                            onClick={() => {
                              HandleUpdateMessage("delete");
                              setMessageModalOpen(false);
                            }}
                          >
                            Delete for you
                          </button>
                        </Dropdown.Item>
                      </>
                    )}
                    {message?.data?.sender === profile.uid &&
                    !message?.data?.deletedBy[otherUserUid] ? (
                      <Dropdown.Item>
                        <button
                          className="danger"
                          type="button"
                          onClick={() => {
                            HandleUpdateMessage("deleteAll");
                            setMessageModalOpen(false);
                          }}
                        >
                          Delete for everyone
                        </button>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                  </Dropdown>
                </div>
              )}
            </span>

            {message.data.content?.imageURL && (
              <picture className="adaptive">
                <figure className="adaptive-photo chat-view__message--image">
                  <Image
                    width={50}
                    height={50}
                    src={message.data.content?.imageURL}
                    alt=""
                  />
                </figure>
              </picture>
            )}
          </p>
        </div>
      )}
    </>
  );
};
