import { _CollectionsNamesWithSlug } from "./common.type";
import { firestore } from "@/api/firebase/firebase.config";

export async function _getSingleFirebaseDoc(
  collectionName: string,
  docId: string
) {
  const respone = await firestore.collection(collectionName).doc(docId).get();
  return respone.data();
}
