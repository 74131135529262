import { useNavigate } from "react-router-dom";
import React, {
  useState,
  ChangeEvent,
  useRef,
  useCallback,
  useEffect,
} from "react";

type Props = {
  children: any;
  setClicked: (arg: boolean) => void;
  fullSearch: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
};

export const SearchInput: React.FunctionComponent<Props> = ({
  setClicked,
  children,
  fullSearch,
  handleChange,
  setSearchValue,
  searchValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (fullSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [fullSearch]);

  return (
    <div className="search__input-group">
      {children}

      <input
        // onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        //   if (e.code === "Enter") {
        //     navigate(siteRoutes["search"]);
        //   }
        // }}
        id="search__input-group"
        autoComplete="disabled"
        placeholder="Search..."
        className={
          fullSearch ? "search__input search__input--open" : "search__input"
        }
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onFocus={() => setClicked(true)}
        value={searchValue}
      />
    </div>
  );
};
