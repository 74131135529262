import { v4 } from "uuid";
import QueryDB from "../queryDB/common";

export const _convertImageToImgIXAndUpdateInFirebase = async (
  image: File,
  collection: string,
  documentId: string,
  fieldName: string,
  root?: string
) => {
  const response = await fetch(
    `https://api.imgix.com/api/v1/sources/64a3da4dc3f870917e949f30/upload/${
      root ? `${root}/` : ""
    }${v4()}`,
    {
      method: "POST",
      body: image,
      headers: {
        Authorization:
          "Bearer ak_d8c4fded0545dcf3f285b0dd20152c48b4e7f6cce3aeb0250394b19a4a824f66",
      },
    }
  );

  const dataToUpdate = {
    [fieldName]: `https://wog.imgix.net/${root ? `${root}/` : ""}${
      response.url.split(root ? `/upload/${root}/` : "/upload/")[1]
    }`,
  };
  await QueryDB.updateDoc(collection, documentId, dataToUpdate);
  return `https://wog.imgix.net/${root ? `${root}/` : ""}${
    response.url.split(root ? `/upload/${root}/` : "/upload/")[1]
  }`;
};
