import useDetectClickOutside from "@/hooks/useDetectClickOutside";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const DropDown = ({
  children,
  title,
  logo,
}: {
  children: any;
  title?: string;
  logo?: any;
}) => {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>();
  const wrapperRef = useRef(null);

  const handleClose = () => {
    setDropDownOpen(false);
  };

  useDetectClickOutside(wrapperRef, handleClose);
  return (
    <div ref={wrapperRef} className="dropdown">
      {title || logo ? (
        <button
          className="dropdown__btn"
          ref={wrapperRef}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          {" "}
          {title || logo}
        </button>
      ) : (
        ""
      )}

      {dropDownOpen && (
        <div className="dropdown__options" onClick={() => handleClose()}>
          {children}
        </div>
      )}
    </div>
  );
};
