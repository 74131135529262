import { FC } from "react";
import StepImage from "../assets/step2.png";

const TrackerStepTwo: FC = (): JSX.Element => {
  return (
    <div className="steps__2">
        <div className="steps__item">
            <span className="border-cut" />
            <span className="border-cut" />
            <span className="border-cut" />
            <span className="border-cut" />
            <div className="steps__number">
                <span>2</span>
            </div>
            <div className="steps__content steps__grid steps__grid--1">
                <div className="steps__image">
                    <img src={StepImage} />
                </div>
                <div className="steps__body">
                    <h2>Log in to your account</h2>
                    <ol>
                    <li>
                        Open the Game Tracker application on your computer.
                    </li>
                    <li>
                        Log in using your World of Gamers account credentials.
                    </li>
                    <li>
                        Check if the tracker detected all supported games you have on your computer. If we can not locate the game, you will see an alert and a button to locate the game manually.
                    </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrackerStepTwo;