import { FC } from "react";
import UserPost from "../SinglePostVariants/UserPost";
import SharedChallenge from "../SinglePostVariants/SharedChallenge";
import SharedGather from "./../SinglePostVariants/SharedGather";
import SharedTeam from "../SinglePostVariants/SharedTeam";
import SharedNews from "../SinglePostVariants/SharedNews";
import SharedPost from "../SinglePostVariants/SharedPost";
import { PostData } from "../../types/singlePostsProps";

export const SinglePostContentType: FC<{
  type:
    | "userPost"
    | "sharedChallenge"
    | "sharedGather"
    | "sharedTeam"
    | "sharedNews"
    | "sharedPost";
  postPhoto: any;
  postVideo: any;
  postText?: string;
  isFromFeed?: boolean;
  data?: PostData;
}> = ({ type, postPhoto, postVideo, postText, isFromFeed, data }) => {
  const typeElements = {
    userPost: <UserPost data={data} />,
    sharedChallenge: <SharedChallenge isFromFeed={isFromFeed} data={data!} />,
    sharedGather: <SharedGather isFromFeed={isFromFeed!} data={data!} />,
    sharedTeam: <SharedTeam isFromFeed={isFromFeed!} data={data!} />,
    sharedNews: <SharedNews isFromFeed={isFromFeed!} data={data!} />,
    sharedPost: (
      <SharedPost
        type={type}
        data={data!}
        isFromFeed={isFromFeed}
        postPhoto={postPhoto}
        postVideo={postVideo}
      />
    ),
  };
  if (data) {
    return <>{typeElements[type]}</>;
  }
};
