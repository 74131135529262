import { FC } from "react";
import { SingleGatherProps } from "../../types/cloudFunctions/SingleGatherTypes";
import SingleGatherComponent from "./SingleGatherComponent";

export const SingleGatherUi: FC<SingleGatherProps> = (props) => {
  return (
    <>
      <SingleGatherComponent
        name={props.name}
        type={props.type}
        slug={props.slug}
        games={props.games}
        logoUrl={props.logoUrl}
        gatherId={props.gatherId}
        isCurrentUserInterested={props.isCurrentUserInterested}
        isFavoriteByCurrentUser={props.isFavoriteByCurrentUser}
        isRequestPending={props.isRequestPending}
        isAllDay={props.isAllDay}
        currentUserGeneralRole={props.currentUserGeneralRole}
        authorUid={props.authorUid}
        Profiles={props.Profiles}
        isPlaceholder={props.isPlaceholder}
        meetType={props.meetType}
        meetTypeSecondary={props.meetTypeSecondary}
        meetUrlOrAddress={props.meetUrlOrAddress}
        startDate={props.startDate}
        participantsCount={props.participantsCount}
        interestedInCount={props.interestedInCount}
        description={props.description}
        endDate={props.endDate}
        isCurrentUserParticipant={props.isCurrentUserParticipant}
      />
    </>
  );
};
