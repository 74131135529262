import React, { FC, useEffect } from "react";
import { ProfileCommunityModal } from "../components/CommunityMembersModal";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ErrorFallback, Spinner } from "@/components";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useCommunityData } from "@/hooks/useCommunityData";

export const CommunityPage: FC<{}> = ({}) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  // const [community, loading_community, communityError, fetch_2] =
  //   useCloudFunctionCall<GetUserCommunityBasedOnSlug>(
  //     "getUserCommunityBasedOnSlug",
  //     slug
  //   );
  const [
    community,
    loading_community,
    communityError
  ] = useCommunityData()

  // useEffect(() => {
  //   if (slug) {
  //     fetch_2({
  //       userSlug: slug,
  //     });
  //   }
  // }, [slug]);

  // If the slug doesn't exist
  if (!slug) {
    return <ErrorFallback>No community found, missing user's slug</ErrorFallback>;
  }

  // If there is an error with the community
  if (communityError) {
    <ErrorFallback>
      <p>No community found with slug {slug}</p>
      <Button variant="primary" onClickAction={() => navigate("/")}>
        Back to home
      </Button>
    </ErrorFallback>
  }

  // Loading friends, followers etc.
  if (loading_community) {
    return (
      <div className="community__loading">
        <h2>Loading {slug}'s community</h2>
        <Spinner />
      </div>
    )
  }

  // Any other case
  return (
    <div className="community__page">
      <div className="community__header">
        <h1>
          {slug}'s community
        </h1>
        <div>
          <Button
            variant="primary"
            onClickAction={() =>
              navigate(`${siteRoutes["profileAndSlug"]}${slug}`)
            }
            >
            Back
          </Button>
        </div>
      </div>
      <ProfileCommunityModal
        community={community}
        isFullPage
        slug={slug}
        userName={slug}
        />
    </div>
  );
};
