import { continents } from "./continents";

export const createOptions = {
  onlyWins: [
    { text: "Record based", value: "Record" },
    { text: "Accumulative", value: "Cumulative" },
  ],
  teamSize: [
    { text: "All", value: "All" },
    { text: "Solo", value: "Solo" },
    { text: "Duo", value: "Duo" },
    { text: "Trio", value: "Trio" },
    { text: "Quad", value: "Quad" },
  ],
  type: [
    { text: "Private", value: "private" },
    { text: "Public", value: "public" },
  ],
  meetType: [
    { text: "Online", value: "online" },
    { text: "Offline", value: "offline" },
  ],
  region: continents,
};

export const createLabels = {
  game: "Game",
  region: "Region",
  name: "Challenge name",
  goal: "Goal",
  description: "Description (optional)",
  logoUrl: "Cover image",
  onlyWins: "Challenge type",
  duration: "Duration",
  criteria: "Criteria",
  teamSize: "Team size",
  type: "Access",
};
