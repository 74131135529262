import firebase from "firebase";
import { useEffect, useState } from "react";

export function useHighlight(game: string) {
    const [highlight, setHighlight] = useState(null);
    const [highlightLoading, setHighlightLoading] = useState(true);
    
    useEffect(() => {
        const databaseRef = firebase.database().ref(`highlights/${game}`).orderByKey().limitToLast(1);

        const handleData = (snapshot) => {
            if (snapshot.exists()) {
                const val = snapshot.val();
                // As we're using limitToLast, the data will be an object with one property, so we need to extract the single child
                const lastHighlight = val[Object.keys(val)[0]];
                setHighlight(lastHighlight);
            } else {
                console.log("No data available");
            }
            setHighlightLoading(false);
        };

        // Fetch initial data
        databaseRef.on('value', handleData);

        // Subscribe to real-time updates
        const unsubscribe = () => databaseRef.off('value', handleData);

        return () => {
            // Unsubscribe when component unmounts
            unsubscribe();
        };
    }, []);

    return [highlight, highlightLoading] as const;
}