import { FC } from "react";

export const ChangePasswordResetCode: FC<{
  code: {
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
  _loading: boolean;
  setCode: React.Dispatch<
    React.SetStateAction<{
      0: number;
      1: number;
      2: number;
      3: number;
      4: number;
      5: number;
    }>
  >;
}> = ({ code, _loading, setCode }) => {
  return (
    <>
      {/* Code */}
      <div className="change-password__verify">
        {Array.from(Array(6), (_, index) => {
          const key = `auth-${index}`;
          return (
            <input
              className="base-input"
              id={`authcode-${index}`}
              type="text"
              maxLength={1}
              value={code[index]}
              key={key}
              disabled={_loading}
              onPaste={async () => {
                const pastedCode = await navigator.clipboard.readText();
                setCode({
                  0: Number(pastedCode[0]),
                  1: Number(pastedCode[1]),
                  2: Number(pastedCode[2]),
                  3: Number(pastedCode[3]),
                  4: Number(pastedCode[4]),
                  5: Number(pastedCode[5]),
                });
              }}
              onChange={(e) => {
                if (!e.target.value && e.target.value !== "0") {
                  return setCode((prev: any) => ({
                    ...prev,
                    [index]: null,
                  }));
                }

                if (Number(e.target.value) || Number(e.target.value) === 0) {
                  setCode((prev: any) => ({
                    ...prev,
                    [index]: Number(e.target.value),
                  }));

                  return document
                    .getElementById(`authcode-${index + 1}`)
                    .focus();
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};
