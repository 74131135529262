import {
  collectionDoc,
  collectionRef,
} from "@/api/collectionRef/collectionOperations";

export const getProfileData = async ({
  data,
}: {
  data: { userSlug: string | undefined; currUserId: string | undefined };
}) => {
  const { userSlug, currUserId } = data;

  try {
    if (!userSlug) {
      throw new Error("User's slug missing!");
    }

    if (!currUserId) {
      throw Error("Current user id missing!");
    }

    const userProfile = await collectionRef.profiles
      .where("slug", "==", userSlug)
      .limit(1)
      .get();

    if (userProfile.empty) {
      throw new Error("User not found!");
    }

    const userProfileData = userProfile.docs[0].data();

    // const gamesWithLinkedAccounts = await getConnectedAccounts(
    //   userProfileData.uid
    // );

    const userFollowers = await collectionDoc.userFollowers(
      userProfileData.uid
    );
    const userFollowersData = userFollowers.data();

    const belongsToCurrentUser = currUserId === userProfileData.uid;

    let isFriendsOfCurrentUser = false;
    let isFollowedByCurrentUser = false;

    let isFriendsRequestPending = false;

    if (userFollowersData) {
      isFriendsOfCurrentUser =
        !!userFollowersData.friends?.includes(currUserId);

      isFollowedByCurrentUser =
        !!userFollowersData.followers?.includes(currUserId);

      if (userFollowersData.statuses) {
        // check that other user has friend request pending
        const { statuses } = userFollowersData;

        // other user got/has a friend request from current user
        if (statuses[currUserId] === "got") {
          isFriendsRequestPending = true;
        }
      }
    }
    return {
      _profile: {
        ...userProfileData,
        belongsToCurrentUser,
        isFriendsOfCurrentUser,
        isFollowedByCurrentUser,
        // gamesWithLinkedAccounts,
        isFriendsRequestPending,
      },
      loading_profile: false,
      profileGeneralError: false,
    };
  } catch (error) {
    if (error) {
      return {
        _profile: {},
        loading_profile: false,
        profileGeneralError: error,
      };
    }

    throw new Error("Error fetching needed data!");
  }
};
