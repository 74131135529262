import { useGeneralStoreForCloudFunctionsForLists } from "@/store/database/database.store";
import { useState } from "react";
import { useInView } from "react-intersection-observer";

type Error = {
  message: string;
};

type Response = {
  dataArray: any[];
  hasMore: boolean;
  lastFeedDateInSeconds: number | null;
  status: "success" | "error";
};

export function useCacheDataArrayManager<T>(
  fn: (props: any) => Promise<Response>,
  key: string
) {
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const data = useGeneralStoreForCloudFunctionsForLists((s: any) => s.data)[
    key
  ] as T;

  const hasMore = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.hasMore
  )[key] as boolean;

  const lastFeedDateInSeconds = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.lastFeedDateInSeconds
  )[key] as number | undefined;

  const dispatchData = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.dispatchData
  );

  const updateHasMore = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setHasMore
  );

  const updateLastVisibleDate = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setLastFeedDateInSeconds
  );

  async function fetch(params: any, filterKey: string, merge?: boolean) {
    if (hasMore === false && !!merge) {
      return;
    }
    setError(undefined);
    setLoading(true);

    try {
      const dataKey = filterKey;

      const response = await fn(params || {});
      const _data = response;

      if (_data) {
        dispatchData(_data.dataArray, dataKey, merge);
        // set has more
        updateHasMore(dataKey, _data.hasMore);

        //last visible item number
        updateLastVisibleDate(dataKey, _data.lastFeedDateInSeconds);
      }
    } catch (error: any) {
      setLoading(false);
      setError({
        message: error,
      });
    }

    setLoading(false);
  }

  return [
    data,
    lastFeedDateInSeconds,
    hasMore,
    loading,
    error,
    fetch,
    ref,
    inView,
  ] as const;
}
