import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthPersistState } from "@/store/auth/auth.store";
import CommunityActionButtons from "./helperfunction/CommunityActionButton";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";
import { ChallengeCreatorProfile } from "@/features/challenge/utils/singleChallengeProps";
import BaseUserAvatar from "../BaseUserAvatar";

export type props = {
  creatorProfile: ChallengeCreatorProfile;
  creatorType?: string;

  isFollowedByCurrentUser: boolean;
  creatorFollowersNumber: number;
  isPlaceholder: boolean;
  isPreview?: boolean;
};
const CreatorCard: FC<props> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [creatorFollowers, setCreatorFollowers] = useState(
    props.creatorFollowersNumber
  );
  const user = useAuthPersistState((s) => s.user);
  const currentUserId = user?.uid;
  useEffect(() => {
    setCreatorFollowers(props.creatorFollowersNumber);
  }, [props.creatorFollowersNumber]);

  const handleLinkClick = useCallback(() => {
    if (!currentUserId) {
      navigate(siteRoutes["signUp1"]);
      createToast({
        type: "error",
        message: "You need to be logged to go here",
      });
    }
    navigate(`${siteRoutes["profileAndSlug"]}${props.creatorProfile.slug}`);
  }, [currentUserId, props.creatorProfile.slug]);

  return (
    <div className="creator-card">
      <div
        aria-hidden
        className="creator-card__content"
        onClick={handleLinkClick}
      >
        <h3 className="creator-card__heading">Creator</h3>
        <div className="creator-card__user">
          <div className="creator-card__avatar">
            <BaseUserAvatar
              logoUrl={props.creatorProfile.profileLogoUrl}
              userName={props.creatorProfile.userName}
            />
          </div>
          <div className="creator-card__name">
            <span className="creator-card__nickname">
              {props.isPlaceholder
                ? "text placeholder"
                : props.creatorProfile.userName}
            </span>
            <span className="creator-card__fullname">
              {props.creatorProfile.fullName && (
                <>{props.isPlaceholder ? "" : props.creatorProfile.fullName}</>
              )}
            </span>
          </div>
        </div>
        <div className="creator-card__followers">
          <span>{creatorFollowers}</span>
          <span>followers</span>
        </div>
      </div>
      {currentUserId !== props.creatorProfile.uid ? (
        <div className="creator-card__actions">
          <CommunityActionButtons
            isFollowedByCurrentUser={props.isFollowedByCurrentUser}
            isPlaceholder={props.isPlaceholder}
            uid={props.creatorProfile.uid}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CreatorCard;
