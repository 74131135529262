export const getRoleMenuLocation = (buttonRef) => {
  const menuHeight = 386;

  return {
    top:
      buttonRef.current.getBoundingClientRect().bottom +
        window.scrollY +
        menuHeight >
      window.innerHeight
        ? `${
            buttonRef.current.getBoundingClientRect().top +
            window.scrollY -
            menuHeight
          }px`
        : `${
            buttonRef.current.getBoundingClientRect().bottom + window.scrollY
          }px`,
    left: `${buttonRef.current.getBoundingClientRect().left}px`,
  };
};
