import { useState } from "react";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ChangePasswordSendEmail } from "./ChangePasswordSendEmail";
import { ChangePasswordResetCode } from "./ChangePasswordResetCode";
import { ChangePasswordResetPassword } from "./ChangePasswordResetPassword";
import i18next from "i18next";
export const ChangePasswordPage = () => {
  const [accountCredentials, setAccountCredentials] = useState<{
    email: string;
    password: string | undefined;
    passwordVerify: string | undefined;
  }>({
    email: "",
    password: "",
    passwordVerify: "",
  });

  const [passwordResetCodeSent, setPasswordResetCodeSent] =
    useState<boolean>(false);

  const [code, setCode] = useState<{
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  }>({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  });

  //   Checks
  const isPasswordValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).{8,}$/.test(
      accountCredentials.password
    ); // Check if password contains every rule
  const passwordsMatch =
    accountCredentials.password === accountCredentials.passwordVerify;

  // Auth logic
  const [response, _loading, _error, authenticate] = useCloudFunctionCall<any>(
    "userAuthentication",
    undefined
  );

  // Set new val
  const setFieldValue = async (targetName: string, value: any) => {
    return setAccountCredentials((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  //   Logic for code

  const handleNextStep = async () => {
    let concatenatedString = "";

    Object.keys(code).forEach((key) => {
      const value = code[key];
      if (typeof value === "number") {
        concatenatedString += value.toString();
      }
    });

    await authenticate({
      method: "email",
      platform: brand.brandValue,
      action: "reset-password",
      newPassword: accountCredentials.password,
      emailAddress: localStorage.getItem("authEmailLocal"),
      passwordResetCode: concatenatedString,
    });
  };

  const handleReset = () => {
    setAccountCredentials({
      email: "",
      password: "",
      passwordVerify: "",
    });
    setPasswordResetCodeSent(false);
    setCode({
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
    });
  };

  return (
    <div className="change-password">
      <div className="settings__alert">
        <p>{i18next.t("settings.password_reset.head")}</p>
        <ul>
          <li>{i18next.t("settings.password_reset.steps.1")}</li>
          <li>{i18next.t("settings.password_reset.steps.2")}</li>
          <li>{i18next.t("settings.password_reset.steps.3")}</li>
          <li>{i18next.t("settings.password_reset.steps.4")}</li>
        </ul>
      </div>

      {/* Send email for code */}
      <ChangePasswordSendEmail
        accountCredentials={accountCredentials}
        setFieldValue={setFieldValue}
        passwordResetCodeSent={passwordResetCodeSent}
        setPasswordResetCodeSent={setPasswordResetCodeSent}
      />

      {/* Reset password code input */}
      {passwordResetCodeSent && (
        <>
          <ChangePasswordResetCode
            code={code}
            setCode={setCode}
            _loading={_loading}
          />
          <p>
            The code sent to the provided email will be active for 10 minutes.
          </p>
        </>
      )}

      {/* New password flow */}
      {passwordResetCodeSent &&
        Object.values(code).every((value) => value !== null) && (
          <ChangePasswordResetPassword
            accountCredentials={accountCredentials}
            setFieldValue={setFieldValue}
            isPasswordValid={isPasswordValid}
            passwordsMatch={passwordsMatch}
            code={code}
            handleReset={handleReset}
          />
        )}
    </div>
  );
};
