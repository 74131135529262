import { FC, useState } from "react";
import { Query_shared } from "@/api/shared";

const BaseImage: FC<{
  logoUrl: string;
  aspectRatio: "3x4" | "16x9" | "3x4" | "1x1" | "4x6" | "2x1";
  customStyles?: string;
  isPlaceholder?: boolean;
  title?: string;
}> = ({ isPlaceholder = false, aspectRatio, logoUrl, customStyles, title }) => {
  const [logoError, setLogoError] = useState<boolean>(false);

  return (
    <div className={`aspect-ratio ${customStyles ? customStyles : ""}`}>
      <div className={`image image--${aspectRatio}`}>
        <div className="image__content">
          {logoUrl && logoUrl !== undefined && (
            <img
              title={title ? title : ""}
              src={Query_shared.imageErrorHandler(logoUrl, logoError, false)}
              onError={(err) => {
                setLogoError(true);
              }}
              alt={`image for news`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BaseImage;
