import { fullDays } from "@/utils/constants/weekDays";
import { scheduleData } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import React, { useEffect, useState } from "react";
import { ListByDay } from "@/components/ListByDay/ListByDay";
import { ListByDayMobile } from "@/components/ListByDay/ListByDayMobile";
// import { scheduleData } from "./data/ScheduleData";
// import { ScheduleList } from "./components/ScheduleList";

export const Schedule = () => {
  const days = Object.values(fullDays);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [availableHours, setAvailableHours] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleResize = () => {
    if (window.innerWidth < 1400) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    if (scheduleData) {
      let availableHours: any = [];

      scheduleData?.scheduleData.map((data) => {
        if (!availableHours.includes(data.startHour)) {
          availableHours.push(data.startHour);
        }
      });

      setAvailableHours(availableHours);
    }
  }, [scheduleData]);

  if (!scheduleData) {
    return (
      <>
        <p>Missing data</p>
      </>
    );
  }

  return (
    <section className="list-by-day">
      <h1>{scheduleData.header}</h1>
      <p>{scheduleData.subHeader}</p>
      {!isMobile && (
        <ListByDay
          buttonNames={days}
          loading={loading}
          error={error}
          data={scheduleData.scheduleData}
          availableHours={availableHours}
        />
      )}

      {isMobile && (
        <ListByDayMobile
          buttonNames={days}
          loading={loading}
          error={error}
          data={scheduleData.scheduleData}
          availableHours={availableHours}
        />
      )}
    </section>
  );
};
