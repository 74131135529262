export const cs2Weapons = {
  weapon_ak47: "AK-47",
  weapon_aug: "AUG",
  weapon_awp: "AWP",
  weapon_bizon: "PP-Bizon",
  weapon_cz75a: "CZ75-Auto",
  weapon_deagle: "Desert Eagle",
  weapon_elite: "Dual Berettas",
  weapon_famas: "FAMAS",
  weapon_fiveseven: "Five-SeveN",
  weapon_g3sg1: "G3SG1",
  weapon_galilar: "Galil AR",
  weapon_glock: "Glock-18",
  weapon_m249: "M249",
  weapon_m4a1: "M4A1",
  weapon_m4a1_silencer: "M4A1-s",
  weapon_mac10: "MAC-10",
  weapon_mag7: "MAG-7",
  weapon_mp5sd: "MP5-SD",
  weapon_mp7: "MP7",
  weapon_mp9: "MP9",
  weapon_negev: "Negev",
  weapon_nova: "Nova",
  weapon_p2000: "P2000",
  weapon_p250: "P250",
  weapon_p90: "P90",
  weapon_revolver: "R8 Revolver",
  weapon_sawedoff: "Sawed-Off",
  weapon_scar20: "SCAR-20",
  weapon_sg556: "SG 553",
  weapon_ssg08: "SSG 08",
  weapon_tec9: "Tec-9",
  weapon_ump45: "UMP-45",
  weapon_usp_silencer: "USP-S",
  weapon_xm1014: "XM1014",
  weapon_b22lr: "B22LR",
  weapon_cz75auto: "CZ75-Auto",
  weapon_dualberettas: "Dual Berettas",
  weapon_glock18: "Glock-18",
  weapon_hegrenade: "HE Grenade",
  weapon_incendiarygrenade: "Incendiary Grenade",
  weapon_knife: "Knife",
  weapon_m4a1_s: "M4A1-S",
  weapon_m4a4: "M4A4",
  weapon_mp5: "MP5",
  weapon_p226: "P226",
  weapon_r8revolver: "R8 Revolver",
  weapon_scout: "Scout",
  weapon_sg553: "SG 553",
  weapon_ssg080: "SSG 080",
  weapon_usps: "USP-S",
  weapon_knife_t: "Knife",
  weapon_hkp2000: "P2000",
};
