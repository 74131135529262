import React, { ButtonHTMLAttributes } from "react";

type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  props?: ButtonHTMLAttributes<HTMLButtonElement>;
  customStyles?: string;
  onClickAction?: any;
  variant: string;
  reff?: any;
  type?: "button" | "submit" | "reset" | undefined;
};

export function Button({
  props,
  children,
  customStyles,
  onClickAction,
  disabled,
  variant,
  reff,
  type,
}: ButtonProps) {
  return (
    <button
      type={type}
      ref={reff}
      disabled={disabled}
      onClick={onClickAction}
      className={`${customStyles ? customStyles : ""} ${
        variant ? `btn btn-${variant}` : ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
