import { Button, Spinner } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { teamDraftAtom } from "@/utils/atoms/draftsAtoms/draftsAtoms";
import { useAtom } from "jotai";
import { createToast } from "@/utils/toaster/createToast";

export const CreateTeamFooter: FC<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  stepsAmount: number;
  submitting: boolean;
  handleNextStep: () => Promise<false | void>;
}> = ({ step, setStep, submitting, handleNextStep, stepsAmount }) => {
  const navigate = useNavigate();
  const [teamDraft, setTeamDraft] = useAtom(teamDraftAtom);

  // Variables
  const isFirstStep = step === 0;

  // If your on the first step
  if (isFirstStep) {
    return (
      <div className="create-team__actions">
        <Button
          variant="secondary"
          onClickAction={() => {
            if (teamDraft) {
              createToast({
                type: "info",
                message: "Team draft removed",
              });
              setTeamDraft("");
            }
            navigate(siteRoutes["teams"]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={submitting}
          variant="primary"
          type="submit"
          onClickAction={() => handleNextStep()}
        >
          {submitting ? <Spinner /> : <>Preview Team</>}
        </Button>
      </div>
    );
  }

  // Any other case (incl. preview)
  return (
    <div className="create-team__actions">
      <Button
        variant="secondary"
        disabled={submitting}
        onClickAction={() => setStep((s) => s - 1)}
      >
        Back
      </Button>
    </div>
  );
};
