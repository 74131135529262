import QueryDB from "@/api/queryDB/common";
import { Button, DropDown, Spinner } from "@/components";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { createToast } from "@/utils/toaster/createToast";
import React, { FC, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/actions/more-vertical.svg";
import { ReportFormModal } from "@/components/ReportFormModal/ReportFormModal";

export const SinglePostDropDownActions: FC<{
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  belongsToCurrentUser: boolean;
  isAuthorized?: boolean;
  postId: any;
  currUserSlug: string;
}> = ({
  setIsDeleted,
  setIsEditPost,
  belongsToCurrentUser,
  postId,
  currUserSlug,
  isAuthorized,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [deleting, isDeleting] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState<boolean>(false);
  return (
    <>
      <BaseModalWindow
        show={confirmModalOpen}
        closeModal={() => {
          setConfirmModalOpen(false);
        }}
        title="Delete this post?"
      >
        <div className="modal__delete">
          <div className="delete-post">
            <div className="delete-post__body">
              <p>Are you sure you want to delete this post?</p>
            </div>
            <div className="delete-post__actions">
              <Button
                variant="secondary"
                onClickAction={() => setConfirmModalOpen(false)}
              >
                Keep
              </Button>

              <Button
                disabled={deleting}
                variant="primary"
                onClickAction={async () => {
                  if (deleting) {
                    return;
                  }
                  if (!postId || !belongsToCurrentUser) {
                    return setConfirmModalOpen(false);
                  }
                  isDeleting(true);
                  setIsDeleted(true);
                  await QueryDB.deleteDoc("posts", postId)
                    .then(() => {
                      createToast({
                        type: "info",
                        message: "Post deleted.",
                      });
                    })
                    .catch(() => {
                      setIsDeleted(false);
                      createToast({
                        type: "error",
                        message: "Post failed to delete, post restored.",
                      });
                    });
                  setConfirmModalOpen(false);
                  isDeleting(false);
                  return null;
                }}
              >
                {deleting ? <Spinner /> : "Delete"}
              </Button>
            </div>
          </div>
        </div>
      </BaseModalWindow>

      {/* Report modal */}
      <ReportFormModal
        show={reportModal}
        closeModal={() => {
          setReportModal(false);
        }}
        children={""}
        reportFrom="post"
        reportItemId={postId}
      />

      {/* Actions */}
      {belongsToCurrentUser || isAuthorized ? (
        <DropDown logo={<ThreeDotsIcon />}>
          {belongsToCurrentUser || isAuthorized ? (
            <a
              className="dropdown__option"
              onClick={(e) => {
                e.preventDefault();
                setConfirmModalOpen(true);
              }}
            >
              Delete
            </a>
          ) : (
            ""
          )}

          {belongsToCurrentUser && (
            <a
              className="dropdown__option"
              onClick={(e) => {
                setIsEditPost(true);
                e.preventDefault();
              }}
            >
              Edit
            </a>
          )}
        </DropDown>
      ) : (
        ""
      )}

      {!belongsToCurrentUser && !isAuthorized && (
        <DropDown logo={<ThreeDotsIcon />}>
          <a
            className="dropdown__option"
            onClick={(e) => {
              e.preventDefault();
              setReportModal(true);
            }}
          >
            Report
          </a>
        </DropDown>
      )}
    </>
  );
};
