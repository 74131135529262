import { _CollectionsNamesWithSlug } from "./common.type";
import firebase from "firebase";

export async function _createNewRealtimeDoc(
  // collectionName: _CollectionsNamesWithSlug,
  collectionName: string,
  data: Record<string, any>,
  docId: string
) {
  const dbRef = firebase.database().ref(collectionName).child(docId);
  const chatRef = dbRef.push();
  await chatRef.set(data);
}
