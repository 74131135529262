export const games = [
  {
    value: null,
    label: "All Games",
  },
  {
    value: "CSGO",
    label: "CS2",
  },
  {
    value: "fortnite",
    label: "Fortnite",
  },
  {
    value: "pubg",
    label: "PUBG",
  },
  {
    value: "dota2",
    label: "Dota 2",
  },
  {
    value: "f12022",
    label: "F1 2022",
  },
  {
    value: "f12023",
    label: "F1 2023",
  },
  {
    value: "f12024",
    label: "F1 2024",
  },
  {
    value: "fallguys",
    label: "Fall Guys",
  },
  {
    value: "fh5",
    label: "Forza Horizon 5",
  },
  {
    value: "fm7",
    label: "Forza Motorsport 7",
  },
  {
    value: "fm8",
    label: "Forza Motorsport (2023)",
  },
  {
    value: "rocketleague",
    label: "Rocket League",
  },
  {
    value: "overcooked!2",
    label: "Overcooked! 2",
  },
  {
    value: "doodlejump",
    label: "Doodlejump",
  },
  {
    value: "snakegooglemaps",
    label: "Snake",
  },
  {
    value: "spiderheck",
    label: "Spiderheck",
  },
  {
    value: "tgr",
    label: "Turbo Golf Racing",
  },
  {
    value: "vampiresurvivors",
    label: "Vampire Survivors",
  },
  {
    value: "wz",
    label: "Call of Duty Warzone",
  },
];
