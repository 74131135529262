import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { useMemo, useEffect, useRef, useState } from "react";
import { ProfileSinglePost } from "./FeedCards/ProfileSinglePost.ui";
import { Spinner } from "@/components";
import { CreatePostForm } from "@/features/feed";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
// import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCommunityData } from "@/hooks/useCommunityData";
import { useAtom } from "jotai";
import { feedAtom } from "@/utils/atoms/feedAtoms/feedAtoms";

export default function FeedFetcher() {
  const currentUser = useCurrentUserProfile((s) => s.user);
  // const dbRef = database_1.ref("/cache").child(currentUser.uid).child("valid");
  const filterKey = "general_feed_posts";
  const [feedUpdateTrigger, setFeedUpdateTrigger] = useState<number>(0);
  const [feedAtomData, setFeedAtomData] = useAtom(feedAtom);
  const [
    feed, // this feed will be either posts/challenges/gathers/teams,etc based on feedType
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchFeed,
    ref,
    inView,
    setSinglePost,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<any[] | undefined, any>(
    "getFeedPostsFromCache",
    "general_feed",
    filterKey,
    false
  );

  // const [communityData, loading_community, communityError, fetch_2] =
  //   useCloudFunctionCall<GetUserCommunityBasedOnSlug>(
  //     "getUserCommunityBasedOnSlug",
  //     currentUser.slug
  //   );
  const [communityData, loading_community, communityError] = useCommunityData();

  //only run at first load!
  useEffect(() => {
    fetchFeed({ lastFeedDateInSeconds: 0 }, filterKey);
  }, []);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView, feedUpdateTrigger]);

  useEffect(() => {
    if (feed) {
      setFeedAtomData(feed);
    }
  }, [feed]);
  // call this function when inView is true
  async function fetchMore() {
    await fetchFeed(
      {
        lastFeedDateInSeconds,
      },
      filterKey,
      true
    );
  }

  // always set the inview reference to the 7th last element.
  //Why? When user starts to scroll down, we can start fetching the next 10 posts in advanced.
  const inViewRefElementIndex = useMemo(() => {
    return feed ? feed?.length - 7 : -1;
  }, [feed, hasMore]);

  const feedToRender = feedAtomData || feed;

  return (
    <>
      {" "}
      {feed || (!_loading && feed?.length === 0) ? (
        <CreatePostForm
          postedIn="feed"
          currentUser={currentUser}
          communityData={communityData}
          setFeedUpdateTrigger={setFeedUpdateTrigger}
          disabledInput={_loading}
          setSinglePost={(data) => setSinglePost(data, filterKey)}
        />
      ) : (
        ""
      )}
      {!!feedAtomData &&
        feedAtomData.map((_feed: any, ind: number) => {
          const key = `${_feed.id}_${ind}`;
          const props = _feed;

          return (
            <div
              className="feed-post"
              key={key}
              ref={ind === inViewRefElementIndex ? ref : null}
            >
              {/* this element will always be based on Feed type */}
              <ProfileSinglePost
                postData={props}
                // setFeedUpdateTrigger={setFeedUpdateTrigger}
              />
            </div>
          );
        })}
      {_loading && <Spinner />}
    </>
  );
}
