import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import icon_emoji from "../../../../assets/actions/emoji.svg";
import { ReactComponent as PostIcon } from "../../assets/arrow-up.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import EmojiWrapper from "@/components/EmojiWrapper/EmojiWrapper";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { getAuthorProfileBasedOnUserName } from "@/api/search/searchUsernameByName";
import { BaseTextInputWithMentions } from "@/components/BaseTextInput/BaseTextInputWithMentions";
import BaseUserAvatar from "@/components/BaseUserAvatar";

type CommentProps = {
  author?: string;
  commentText: string;
};
const CreateCommentForm: React.FunctionComponent<{
  postId?: string;
  postAuthorUid: string;
  fetchComments: () => Promise<void>;
}> = ({ postId, postAuthorUid, fetchComments }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const initialValue: CommentProps = {
    commentText: "",
    author: currentUser.uid,
  };

  const [showEmoji, setShowEmoji] = useState(false);
  const [lastClickedEmoji, setLastClickedEmoji] = useState<string>("");
  const [commentValues, setCommentValues] =
    useState<CommentProps>(initialValue);

  const setFieldValue = (targetName: string, value: any) => {
    return setCommentValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  useEffect(() => {
    if (lastClickedEmoji) {
      setFieldValue(
        "commentText",
        `${commentValues.commentText}${lastClickedEmoji}`
      );
      setLastClickedEmoji(undefined);
    }
  }, [lastClickedEmoji]);

  const submit = async () => {
    if (!postId || !commentValues.author) return;
    if (commentValues.commentText.trim().length <= 0) {
      createToast({
        type: "error",
        message: "Comment cannot be empty",
      });
      return;
    }
    setSubmitting(true);
    await QueryDB.createNewDoc(`posts/${postId}/comments`, {
      created: firebase.firestore.FieldValue.serverTimestamp(),
      commentText: commentValues.commentText,
      authorUid: commentValues.author,
      isVisible: true,
      parentId: postId,
    })
      .then(() => {
        // Updating comment count -> live comment count updater
        const doc = window.document.getElementById(`${postId}_comment`);
        const prevCommentCount = +doc.innerHTML;
        if (!isNaN(prevCommentCount)) {
          doc.innerHTML = `${prevCommentCount + 1}`;
        }

        if (postAuthorUid !== currentUser.uid) {
          createToast({
            type: "success",
            message: "Comment posted",
          });
        }
        setCommentValues(initialValue);
        fetchComments();
        setSubmitting(false);
      })
      .then(async () => {
        const mentionedUsers = commentValues.commentText
          .split(" ")
          .filter((word: string) => word.startsWith("@"));
        const uniqueMentionedUsers = new Set(mentionedUsers);

        uniqueMentionedUsers.forEach(async (mentionedUsername: string) => {
          const mentionedUser = await getAuthorProfileBasedOnUserName(
            mentionedUsername.replace("@", ""),
            brand.brandValue
          );
          if (mentionedUser[0]) {
            QueryDB_Notifications.createNewNotification(
              currentUser.uid as string,
              mentionedUser[0].uid,
              postId as string,
              "comment-mention",
              `${notificationsTextMapping["comment-mention"]}`
            );
          }
        });
      })
      .catch(() => {
        setSubmitting(false);
        createToast({
          type: "error",
          message:
            "Something went wrong while posting comment, please try again later.",
        });
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!submitting) {
          submit();
        }
      }}
      autoComplete="off"
      className="post-form comment-form"
    >
      <div className="post-form__avatar comment-form__avatar">
        <BaseUserAvatar
          logoUrl={currentUser?.profileLogoUrl}
          userName={currentUser.userName}
        />
      </div>

      <div className="post-form__input comment-form__input">
        <div className="form">
          <div className="form__content">
            <div className="form__input">
              <BaseTextInputWithMentions
                label=""
                textType="text"
                value={commentValues.commentText}
                placeholder="Write a comment"
                setObjectState={setFieldValue}
                setObjectStateTargetName="commentText"
                error={undefined}
              />
              <EmojiWrapper show={showEmoji} setShow={setShowEmoji}>
                <div className="form__emoji-picker">
                  <EmojiPicker
                    emojiVersion="0.6"
                    lazyLoadEmojis={true}
                    onEmojiClick={(emojiObject) => {
                      setLastClickedEmoji(emojiObject.emoji);
                    }}
                  />
                </div>
              </EmojiWrapper>
              <Button
                variant="link"
                onClick={() => setShowEmoji((pre) => !pre)}
                className="form__emoji-select"
              >
                <img src={icon_emoji} alt="emoji" />
              </Button>
            </div>
            <span className="form__actions">
              <Button
                variant="link"
                className="form__submit"
                type="submit"
                disabled={
                  commentValues.commentText.trim().length <= 0 || submitting
                }
              >
                {commentValues.commentText.trim().length <= 0 ? (
                  <PostIcon width={24} height={24} strokeWidth={2} />
                ) : (
                  <PostIcon width={24} height={24} strokeWidth={2} />
                )}
              </Button>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateCommentForm;
