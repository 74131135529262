import {
  visitedTeamsAtom,
  visitedTeamsMembersAtom,
} from "@/utils/atoms/teamsAtoms/teamsAtoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { getTeamMembersBasedOnSlug } from "@/api/queryRealtime/Teams";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { getTeamData } from "@/api/firebaseQueries/getSingleTeamData";

export const useSingleTeamData = (slug) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const [visitTeamsAtomData, setVisitedTeamsAtomData] =
    useAtom(visitedTeamsAtom);

  const [visitTeamMembersAtomData, setVisitedTeamMembersAtomData] = useAtom(
    visitedTeamsMembersAtom
  );

  // Check if doc is visited before
  const visitedTeamDoc =
    visitTeamsAtomData.length !== 0 &&
    visitTeamsAtomData?.find((doc) => {
      return doc?.slug === slug;
    });

  // Check if doc is visited before
  const visitedTeamMembersDoc = visitTeamMembersAtomData?.[slug];

  const [teamData, setTeamData] = useState(visitedTeamDoc);
  const [loadingTeam, setLoadingTeam] = useState(false);
  const [teamError, setTeamError] = useState(false);

  const [teamMembers, setTeamMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(false);

  // Has been replaced with getTeamData(), migrated BE to FE
  // const [teamDataResponse, loading_SingleTeam, error, fetch_team] =
  // useCloudFunctionCall<SingleTeamResponseTypes>("getSingleTeam", slug);

  useEffect(() => {
    if (visitedTeamDoc) {
      setTeamData(visitedTeamDoc);
    }
    if (visitedTeamMembersDoc) {
      setTeamMembers(visitedTeamMembersDoc);
    }
  }, []);

  //   TEAM RELATED DATA------------------------------------
  useEffect(() => {
    if (!teamData) {
      setLoadingTeam(true);
    } else {
      setLoadingTeam(false);
    }
    // setTeamError(error ? true : false);
  }, [teamData]);

  const fetchTeam = async () => {
    const singleTeamResponse = await getTeamData({
      data: {
        slug: encodeURIComponent(decodeURIComponent(slug)),
        seeAll: false,
      },
      currUserId: currentUser.uid,
    });

    if (!singleTeamResponse) {
      return;
    }

    if (visitedTeamDoc) {
      const deepArr = [...visitTeamsAtomData];

      const updatedItems = deepArr.map((item: any) =>
        item?.slug === slug ? { ...singleTeamResponse } : item
      ) as any;

      // update existing doc with new data
      setVisitedTeamsAtomData(updatedItems);
    } else {
      // If Doc has not been loaded before
      setVisitedTeamsAtomData([
        singleTeamResponse,
        ...(visitTeamsAtomData || []),
      ]);
    }
    setTeamData(singleTeamResponse);
  };

  useEffect(() => {
    if (slug) {
      fetchTeam();
    }
  }, [slug]);

  //   MEMBERS RELATED DATA------------------------------------
  const fetchMembers = async () => {
    const response = await getTeamMembersBasedOnSlug({
      slug: slug,
      currUserId: currentUser.uid,
      lastFeedDateInSeconds: 0,
    });

    if (response.status === "success") {
      setLoadingMembers(false);
    }

    setVisitedTeamMembersAtomData({
      ...visitTeamMembersAtomData,
      [slug]: response.dataArray,
    });
    setTeamMembers(response.dataArray);
  };

  useEffect(() => {
    if (slug) {
      fetchMembers();
    }
  }, [slug]);

  return {
    teamData: teamData,
    loadingTeam: loadingTeam,
    fetchTeam: fetchTeam,
    teamError: teamError,

    teamMembers: teamMembers,
    loadingMembers: loadingMembers,
  };
};
