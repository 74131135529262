import { FC } from "react";
import { editTeamRights } from "../../utils/CreateTeamOptions";
import SelectInput from "@/components/SelectInput/SelectInput";
import { v4 } from "uuid";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { EditTeamRightsFormValues } from "../../types/TeamTypes";
import { Button, Spinner } from "@/components";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const TeamRightsOptionsList: FC<{
  setFieldValue: (targetName: string, value: any) => void;
  teamRights: EditTeamRightsFormValues;
  teamSlug: string;
  setUpdateTrigger: any;
}> = ({ setFieldValue, teamRights, teamSlug, setUpdateTrigger }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [_, _updatingSettings, settingsError, updateSettings] =
    useCloudFunctionCall<any>("updateTeamSettings", currentUser.slug);

  const handleSavePermisions = async () => {
    await updateSettings({
      slug: teamSlug,
      ...teamRights,
    })
      .then(() => {
        createToast({
          type: "success",
          message: "Updated team permissions",
        });
        setUpdateTrigger((prev: number) => prev + 1);
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to update team permissions",
        });
      });
  };

  return (
    <div className="rights">
      <div className="rights__content">
        {Object.values(editTeamRights).map((right) => {
          return (
            <fieldset
              key={v4()}
              className="input-group input-group__type--select"
            >
              <legend className="input-group__heading">{right.title}</legend>
              <SelectInput
                onChange={(e: any) =>
                  setFieldValue(right.dataValue, e.target.value)
                }
                name=""
                value={teamRights[right.dataValue]}
                placeholder=""
                hasUploading={false}
              >
                {right.options.map((option) => {
                  return (
                    <option
                      onClick={() =>
                        setFieldValue(right.dataValue, option.value)
                      }
                      value={option.value}
                      selected={teamRights[right.dataValue] === option.value}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </SelectInput>
            </fieldset>
          );
        })}
      </div>
      <div className="rights__actions">
        <Button
          onClickAction={handleSavePermisions}
          type="button"
          variant="primary"
          disabled={_updatingSettings}
        >
          {_updatingSettings ? <Spinner /> : "Save"}
        </Button>
      </div>
    </div>
  );
};
