import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import React from "react";
import { Button } from "react-bootstrap";

export const SettingDisconnectModal: React.FunctionComponent<{
  game: string;
  showModal: boolean;
  handleDisconnecting: () => void;
  handleCloseWindow: () => void;
}> = ({ game, showModal, handleCloseWindow, handleDisconnecting }) => {
  return (
    <>
      <div className="disconnect">
        <BaseModalWindow
          show={showModal}
          closeModal={handleCloseWindow}
          size="modal--disconnect-account"
          title="Disconnect account"
        >
          <p>
            {game === "PUBG" &&
              "Are you sure you want to disconnect PUBG?. Disconnecting will remove your ability to participate and create challenges for PUBG."}
            {game === "Steam" &&
              "Are you sure you want to disconnect steam? (disconnecting this account will disable the ability to participate and create challenges for thefollowing games : Dota2 )"}
            {game === "Battle.net" &&
              "Are you sure you want to disconnect Battle.net? "}
          </p>
          <div className="disconnect__buttons">
            <Button
              variant="secondary"
              className="disconnect__buttons--cancel"
              onClick={handleCloseWindow}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDisconnecting}>
              Disconnect
            </Button>
          </div>
        </BaseModalWindow>
      </div>
    </>
  );
};
