import { database_1 } from "@/api/firebase/firebase.config";
import { cs2Weapons } from "@/utils/constants/cs2Weapons";
import React, { useEffect, useState } from "react";
import { ReactComponent as TclLogo } from "../../utils/brandVariables/assets/tcl/tcl-logo.svg";
export type Props = {
  matchState: "match_started" | "match_ended";
  player1: {
    kills: number;
  };
  player2: {
    kills: number;
  };
  events: Events[];
};

export type Events = {
  kills: number;
  deaths: number;
  weaponName: string;
  wasHeadshot: boolean;
  player: "Player 1" | "Player 2";
  timestamp: EpochTimeStamp;
};

export const tclLogsPage = () => {
  const [usersData, setUsersData] = useState<Props>(null);
  const [logs, setLogs] = useState(null);
  const player1Score = usersData?.player1?.kills || 0;
  const player2Score = usersData?.player2?.kills || 0;

  const realtimeDB = database_1
    .ref("cs2")
    .child("TCL") // PCSPECIALIST
    .child("match_01")
    .orderByKey();

  const getLeaderboardData = async () => {
    realtimeDB.on("value", async (snapshot) => {
      if (snapshot.val()) {
        setUsersData(snapshot.val());

        if (snapshot.val()?.events) {
          const dataArray = Object.entries(snapshot.val()?.events).map(
            ([key, value]: any) => ({
              key,
              ...value,
            })
          );

          dataArray.sort((a, b) => b.timestampLocal - a.timestampLocal);
          const clearedArr = dataArray;
          setLogs(clearedArr);
        }
      }
    });
  };

  const getTime = (time) => {
    if (!time) {
      return "00:00";
    }

    const date = new Date(time * 1000);
    // console.log(date);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    getLeaderboardData();
  }, []);

  return (
    <div className="partner-tcl-logs">
      <div className="partner-tcl-logs__brand">
        <TclLogo />
      </div>
      <div className="partner-tcl-logs__meta">
        <section className="meta__hero">
          <h2>Hero</h2>
        </section>
        <div className="meta__score">
          <p>{player1Score}</p> <p>:</p> <p>{player2Score}</p>
        </div>
        <section className="meta__villain">
          <h2>Villain</h2>
        </section>
      </div>
      <div className="partner-tcl-logs__logs">
        <ul className="logs">
          {logs &&
            Object.values(logs)
              ?.slice(0, 4)

              .map((data: Events, index) => {
                const player = data.player === "Player 1" ? 1 : 0;
                const key = index;

                return (
                  <li
                    key={key}
                    className={`logs__item ${
                      player === 1 ? "align-left" : "align-right"
                    }`}
                  >
                    <div className="item">
                      <section className="item__info">
                        {player === 1 ? (
                          <>
                            <p>
                              {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                            </p>
                            <div>{data.wasHeadshot ? "Headshot" : "Kill"}</div>
                          </>
                        ) : (
                          <>
                            <div>{data.wasHeadshot ? "Headshot" : "Kill"}</div>
                            <p>
                              {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                            </p>
                          </>
                        )}
                      </section>
                      <section className="item__time">
                        <time>{getTime(data.timestamp)}</time>
                      </section>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};
