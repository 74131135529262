import React, { FC } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { createToast } from "@/utils/toaster/createToast";
import { handleCreateChat } from "./helperFunctions/messagesUtils";

type ChatViewProps = {
  friends: GetUserCommunityBasedOnSlug | undefined;
  setSelectedChat: any;
  chats: any[];
  loading: boolean;
};

export const ChatFriendsOnline: FC<ChatViewProps> = ({
  friends,
  setSelectedChat,
  chats,
  loading,
}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div className="chat-friends">
      <h3>Online</h3>
      <div id="chat-friends-list" className="chat-friends__list">
        <div className="chat-friends__list-content">
          <ul>
            {friends &&
              friends.friends.users.map((friend) => {
                let creatingChat = false;
                return (
                  <div
                    aria-hidden
                    key={friend.userName}
                    className="chat-friends__socials"
                    onClick={async () => {
                      if (
                        creatingChat === false &&
                        currentUser.uid &&
                        friend.uid
                      ) {
                        creatingChat = true;
                        await handleCreateChat(
                          [
                            {
                              uid: friend.uid,
                            },
                          ],
                          currentUser.uid
                        );
                        const chatId = chats.find(
                          (chat) =>
                            chat.includes(friend.uid) &&
                            chat.includes(currentUser.uid)
                        );
                        // if (
                        //   chats.find(
                        //     (chat) =>
                        //       chat.includes(friend.uid) &&
                        //       chat.includes(currentUserUid)
                        //   )
                        // ) {
                        // chatId = chats.find(
                        //   (chat) =>
                        //     chat.includes(friend.uid) &&
                        //     chat.includes(currentUserUid)
                        // );
                        // }
                        // Give firebase time to actualy create it, and to let the realtimeDB update after its created
                        if (
                          !chats.find(
                            (chat) =>
                              chat.includes(friend.uid) &&
                              chat.includes(currentUser.uid)
                          )
                        ) {
                          createToast({
                            type: "success",
                            message: `Creating new chat with ${friend.userName}`,
                          });
                        }
                        creatingChat = false;
                        setSelectedChat(chatId);
                      }
                    }}
                  >
                    {" "}
                    <BaseLogo
                      logoSrc={friend.profileLogoUrl}
                      isPlaceHolder={undefined}
                      name={friend.userName}
                    />
                    <span>{friend.userName}</span>
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};
