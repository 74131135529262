import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleGatherUi } from "./SingleGather.ui";
import {
  ParticipatingInterestedProps,
  SingleGatherResponse,
} from "../../types/cloudFunctions/SingleGatherTypes";
import { SingleGather404 } from "./SingleGather404";
import SingleGatherLoader from "@/components/skeletonLoaders/SingleGatherLoader/SingleGatherLoader";

export const SingleGather: FC = (): JSX.Element => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [data, _loading_data, data_error, fetch_data] =
    useCloudFunctionCall<SingleGatherResponse>("getSingleGather", slug);
  const [
    Participants,
    _loading_participant,
    ParticipantError,
    fetch_participants,
  ] = useCloudFunctionCall<ParticipatingInterestedProps>(
    "getTopFourGatherParticipantsAndInterested",
    slug
  );

  useEffect(() => {
    if (!slug) {
      return;
    }
    const run = async () => {
      await Promise.all([
        fetch_data({
          slug: encodeURIComponent(decodeURIComponent(slug!)),
        }),
        fetch_participants({
          slug: encodeURIComponent(decodeURIComponent(slug!)),
        }),
      ]);
    };
    run();
  }, [slug]);

  return (
    <>
      {/* In  case  gather doesnt exist anymore (or other error) */}
      {!_loading_data && (data_error || ParticipantError) && (
        <SingleGather404 navigate={navigate} />
      )}

      {!_loading_data && data && typeof data === "string" ? (
        <p>{data}</p>
      ) : (
        !_loading_data &&
        !data_error &&
        data &&
        Participants && (
          <SingleGatherUi
            name={data.name}
            type={data.type}
            slug={data.slug}
            games={data.games}
            logoUrl={data.logoUrl}
            gatherId={data.gatherId}
            isCurrentUserInterested={data.isCurrentUserInterested}
            isFavoriteByCurrentUser={data.isFavoriteByCurrentUser}
            isRequestPending={data.isRequestPending}
            isAllDay={data.isAllDay}
            currentUserGeneralRole={data.currentUserGeneralRole}
            authorUid={data.authorUid}
            Profiles={Participants}
            isPlaceholder={false}
            meetType={data.meetType}
            meetTypeSecondary={data.meetTypeSecondary}
            meetUrlOrAddress={data.meetUrlOrAddress}
            startDate={data.startDate}
            participantsCount={data.participantsCount}
            interestedInCount={data.interestedInCount}
            description={data.description}
            endDate={data.endDate}
            isCurrentUserParticipant={data.isCurrentUserParticipant}
          />
        )
      )}
      {/* Skeleton loader */}
      {_loading_data && <SingleGatherLoader />}
    </>
  );
};
