import brand_logo_large from "../assets/game-pass/gamepass_logo.svg";
import tabIcon from "../assets/appicon/appIcon-WOG@512.png";

export const GAMEPASS_brandVariables = {
  siteTitle: "Gamepass",
  siteIcon: tabIcon,
  name: "Gamepass",
  name_small: "gamepass",
  logos: {
    large: brand_logo_large,
    white: brand_logo_large,
    small: brand_logo_large,
    medium: brand_logo_large,
    alt_text: "Gamepass logo",
    profile: brand_logo_large,
  },
  brandTheme: "gamepass", // Theme of the brand
  site: "", // Live url of site
  authMethod: "phone", // Only needed for events, gives user set options to manualy sign up, options are:  email | phone
  defaultCountryCode: "DK", // Only needed for retail platforms | For initial phone number | Changes all auth methods`
  defaultLanguage: "eng",
  brandStoreExtension: "", // Only needed for retail platforms | Extension used for email
  brandValue: "GAMEPASS", // Only needed for retail platforms | Brand value needed for BE related functions
  brandIntials: "", // gets added after the username, to drasticaly descrease chance of duplicate usernames across different apps | Only needed for event platforms
  defaultNavigateUrl: "/",
  eventHasAutoQrSignup: false,
  hasSubClients: false,
  subClientsForOverviews: null, // string[] of clients used to fetch multiple types of clients for overview pages
  autoJoinChallenge: false,
  hasFeature: {
    themes: false,
    connections: false,
    achievements: false,
    messages: false,
    preferences: false,
    teams: false,
    news: false,
    challenges: true,
    singleChallenge: true,
    gathers: false,
    friends: false,
    zones: false,
    feed: false,
    winners: false,
    schedule: false,
    qrSignUp: false,
    manualSubmissions: true,
    landing: true,
    login: true,
    notifications: false,
    haslanguagePicker: false,
    getstarted: false,
    hero: false,
    search: true,
    routes: false,
    dailytasks: false,
    games: false,
    treasure_hunt: false,
    activities: false,
  },
  hasAuth: {
    steam: false,
    bnet: false,
    quickSignUp: true,
    google: true,
  },
  layout: {
    challenge: "default",
  },
  event: {
    forcedCredentialPopup: "email",
    eventCode: "", // Only needed for event platforms
    collectionForSubmissions: "",
    documentForSubmissions: "",
    isEvent: true,
    spacecoins_redirect: true,
  },
};
