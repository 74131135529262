import React, { FC } from "react";
import { Container } from "react-bootstrap";

interface BestGamerHomePageDividerProps {
    image: string;
    heading: string;
    paragraph: string;
    position: "top" | "bottom";
}

export const BestGamerHomePageDivider: FC<BestGamerHomePageDividerProps> = ({
    image,
    heading,
    paragraph,
    position,
}) => {
    const layoutClasses = `home__divider home__divider--${position}`;

    return (
    <section className={layoutClasses}>
        <img className="home__split" src={image} alt="" />
        <div className="message">
        <Container>
            <h2 className="message__heading">
                {heading}
            </h2>
            <p className="message__paragraph">
            {paragraph}
            </p>
        </Container>
        </div>
    </section>
    )
};
