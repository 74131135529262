import React, { useEffect, useState } from "react";
import { FiSearch as SearchIcon } from "@react-icons/all-files/fi/FiSearch";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { auth } from "@/api/firebase/firebase.config";
import countryCodes from "@/utils/constants/CountryCodes";

const CountrySelect = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);

  const [selectedCountry, setSelectedCountry] = useState<any>({
    flag: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
    dial_Code: currentUser.countryCode || "+372",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const phoneNumber = auth.currentUser?.phoneNumber || "";

  useEffect(() => {
    if (phoneNumber) {
      const matchedCountry = countryCodes.find(
        (country: any) =>
          country.dial_Code === selectedCountry.dial_Code ||
          (phoneNumber && phoneNumber.startsWith(country.dial_Code))
      );

      if (matchedCountry) {
        setSelectedCountry(matchedCountry);
      }
    }
  }, [phoneNumber, countryCodes]);

  const handleKeyDown = (event, country) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick(country);
    }
  };

  const handleCountryClick = (country) => {
    setSelectedCountry(country);
    onClick(country);
    setIsOpen(false);
  };

  const filteredCountries = countryCodes.filter(
    (country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (country.dial_Code ?? "").includes(searchTerm)
  );

  return (
    <div className="country-selector">
      <button
        type="button"
        className="country-selector__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <img src={selectedCountry.flag} alt="t" />{" "}
        </div>
      </button>
      {isOpen && (
        <>
          <ul className="country-selector__list">
            <div>
              <input
                type="text"
                placeholder="Search"
                className="country-selector__search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon size={16} className="country-selector__icon" />
            </div>
            {filteredCountries.map((country) => (
              <li key={country.flag}>
                <button
                  value={country.dial_Code}
                  className={
                    country.flag === selectedCountry.flag ? "selected" : ""
                  }
                  onClick={() => handleCountryClick(country)}
                  onKeyDown={(event) => handleKeyDown(event, country)}
                  tabIndex={0}
                  type="button"
                >
                  <img src={country.flag} alt="t" />
                  <p>{country.dial_Code}</p>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CountrySelect;
