import { FC } from "react";
import { ReactComponent as IconAlert } from "../../../assets/alert-circle.svg";

const SharedTeamFallback: FC = (): JSX.Element => {
  return (
    <div className="shared-team shared-team__fallback">
        <div className="shared-team__image">
            <div className="image">
                <div className="image__content" />
            </div>
        </div>
        <div className="shared-team__content">
            <h3 className="shared-team__heading">
            Team missing
            </h3>
            <p className="shared-team__excerpt">
            The shared team might no longer be available. This could either be because it has been deleted by the owner of the team, a technical error, or if it has been removed by an admin.
            </p>
        </div>
        <div className="shared-team__icon">
            <IconAlert width={46} height={36} />
        </div>
    </div>
  );
};

export default SharedTeamFallback;