import { FC } from "react";

const TrackerFAQ: FC = (): JSX.Element => {
    const faqData = [
        {
            id: "0",
            question: "Do I need the Tracker for all games?",
            answer: "No, you don't need the tracker for all games. Some games are API based, for which you don't need the tracker. Dota2 is currently our only API based games."
        },
        {
            id: "1",
            question: "What do I need to do for API based games?",
            answer: "You don't need to download the tracker. For the API based games you need to connect your game account (i.e. Steam, Battle.net). After that, your match data is gathered automatically once the match has ended. Dota2 is currently our only API based games."
        },
        {
            id: "2",
            question: "Is the Game Tracker compatible with operating systems other than Windows?",
            answer: "Currently, the Game Tracker is designed exclusively for Windows operating systems. We are actively exploring options for compatibility with other platforms, but as of now, it is available for Windows users only. Stay tuned for any updates regarding additional operating system support in the future."
        },
        {
            id: "3",
            question: "Can I install the tracker on multiple devices?",
            answer: "Yes, the tracker can be installed on more than a single device. The collection of game data is attached to the signed in user."
        },
        {
            id: "4",
            question: "How often does the tracker update my gaming stats?",
            answer: "The tracker continuously captures data while you play. Once a match is over, the data collection is sent to our platform, and leaderboards and challenges are updated hereafter."
        },
        {
            id: "5",
            question: "What do I do if the Game Tracker failed to detect my game?",
            answer: "It is important to open the Game Tracker before opening your game. The first time, it will say it failed to detect the game until you open up the game the first time after installing the tracker."
        },
        {
            id: "6",
            question: "What happens if the tracker still doesn't detect one of my games?",
            answer: "If the tracker fails to detect a supported game, you'll receive an alert with an option to locate the game manually. Click the designated button, and you'll be prompted to select the game's executable file on your computer."
        },
        {
            id: "7",
            question: "Can I participate in challenges for games not listed on the World of Gamers platform?",
            answer: "Currently, challenges are limited to games listed on the World of Gamers platform. We continuously update the supported games list, so keep an eye on our announcements for new additions."
        },
        {
            id: "8",
            question: "Is my gaming data shared with third parties?",
            answer: "No, we prioritize user privacy. Your gaming data is only used for the purpose of tracking your performance on the World of Gamers platform. We do not share this data with any third parties without your explicit consent. You can review our privacy policy for more details"
        },
        {
            id: "9",
            question: "What exactly does the tracker do in my computer?",
            answer: "The tracker logs your match data in selected games. It will store the data needed for the challenges you have joined. Once collected the match data is sent to our platform."
        },
    ]

  return (
      <div className="faq">
        <h2>Frequently Asked Questions</h2>
        {faqData.map((data) => {
            return (
                <details className="faq__details" key={data.id}> 
                <summary className="faq__question">
                    {data.question}
                </summary> 
                <p className="faq__answer">
                    {data.answer}
                </p>
            </details>
            );
        })}
    </div>
  );
};

export default TrackerFAQ;