import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import copy from "../../assets/actions/check.svg";
import { createToast } from "@/utils/toaster/createToast";
import { auth } from "@/api/firebase/firebase.config";
import { getUserGamepass } from "../../../branchAndBrandSpecific//helperFunctions/GetGamepassToken";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const GamePassBox: React.FunctionComponent<{
  belongsToCurrentUser: boolean;
}> = ({ belongsToCurrentUser }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [keyRedeemded, setKeyRedeemed] = useState(false);
  const [userGamepass, setUserGamepass] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
    if (isCopied === false) {
      clearTimeout(timeout);
    }
  }, [isCopied]);

  const GetGamePasskey = () => {
    if (!auth.currentUser || !auth.currentUser.phoneNumber) {
      createToast({
        type: "error",
        message: "Please verify your phone number first!",
      });
      setLoading(false);
      return;
    }

    setLoading(true);
    getUserGamepass("gamepass-dreamhack-summer-2023").then((res) => {
      setLoading(false);

      if (res.error) {
        createToast({
          type: "error",
          message: res.message,
        });
        return;
      }

      if (res === "No more codes") {
        setKeyRedeemed(true);
        setUserGamepass("Go to an admin to get token");
        return;
      }

      setKeyRedeemed(true);
      setUserGamepass(res);
    });
  };

  useEffect(() => {
    const checkClaimedToken = async () => {
      setLoading(true);
      try {
        const response = await getUserGamepass(
          "gamepass-dreamhack-summer-2023"
        );
        if (response && response !== "No more codes") {
          setKeyRedeemed(true);
          setUserGamepass(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    checkClaimedToken();
  }, []);

  return (
    <>
      <div className={`gamepass-code`}>
        <img src={brand.logos.large} alt="logo" />

        <div id="gamepass-code__token" className="gamepass-code__token">
          {keyRedeemded ? (
            <button
              className="gamepass-code__copy"
              type="button"
              disabled
              onClick={() => {
                navigator.clipboard.writeText(userGamepass);
                return setIsCopied(true);
              }}
            >
              <input
                name=""
                value={userGamepass}
                placeholder="Username"
                className="base-input"
              />

              <div
                className={`${
                  isCopied ? "gamepass-code__copy--wrapper" : "d-none"
                }`}
              >
                <div className="gamepass-code__copy--tooltip">
                  <p>Copied to clipboard</p>
                </div>
              </div>
              <div className="gamepass-code__copy--icon">
                <img src={copy} alt="" />
              </div>
            </button>
          ) : (
            <>
              <Button
                onClick={() => {
                  GetGamePasskey();
                }}
                className={` ${loading ? "d-none" : ""}`}
              >
                Claim Your Free Code
              </Button>
              <div
                className={`${loading ? "gamepass-code__spinner" : "d-none"} `}
              >
                <div className="spinner-border" role="status" />
              </div>
            </>
          )}
          <p className="gamepass-code__text">
            Redeem at{" "}
            <a
              href="https://www.microsoft.com/redeem"
              target="_blank"
              rel="noreferrer"
            >
              microsoft.com/redeem
            </a>{" "}
            by December 31, 2023. Valid for new PC Game Pass members only. Valid
            payment method required. Unless you cancel, you will be charged the
            then-current regular membership rate when the promotional period
            ends. Limit: 1 per person/account. Subject to the Microsoft Services
            Agreement, Game Pass terms and system requirements at:{" "}
            <a
              href="https://www.xbox.com/subscriptionterms"
              target="_blank"
              rel="noreferrer"
            >
              xbox.com/subscriptionterms.
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
