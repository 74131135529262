import { Button, Image } from "@/components";
import { Profile } from "@/models/database/profiles/profile.model";
import { FC } from "react";
import { ReactComponent as PlusIcon } from "../../../../../../assets/actions/plus.svg";

export const SettingsProfileHistory: FC<{
  currentUser: Profile;
  setOpenModal: any;
  setIsAvatar: any;
  setImgSrc: any;
  setIsLoading: any;
  isLoading: any;
  handleInputChange: any;
}> = ({
  currentUser,
  setOpenModal,
  setIsAvatar,
  setImgSrc,
  setIsLoading,
  isLoading,
  handleInputChange,
}) => {
  return (
    <div className="settings__history">
      <label htmlFor="modal-profile-image-input">
        <div className="settings__history-input">
          <PlusIcon />
        </div>

        <input
          accept="image/*"
          type="file"
          className=""
          id="modal-profile-image-input"
          onChange={(e) => {
            handleInputChange(e);
            return setOpenModal(true);
          }}
        />
      </label>

      {currentUser.profileLogoUrlHistory &&
        currentUser.profileLogoUrlHistory?.map((item: string, index) => {
          const key = `profileHistoryItem_${index}`;
          return (
            <Button
              key={key}
              variant="link"
              customStyles="settings__defined--btn-history"
              onClickAction={() => {
                setImgSrc(item);
                setIsAvatar(false);
                setIsLoading(null);
              }}
              disabled={isLoading === item}
            >
              <Image
                alt=""
                imgIxProps={[{ w: "" }]}
                imageSrc={item}
                customStyles="hover-image profile-image-input"
              />
            </Button>
          );
        })}
    </div>
  );
};
