import { FC } from "react";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import comments from "@/features/gather/assets/actions/comments.svg";
import { SingleGatherDiscussion } from "../../types/cloudFunctions/SingleGatherTypes";
import { DiscussionCard } from "../../types/DiscussionType";

const SingleDiscussionComponent: FC<SingleGatherDiscussion & DiscussionCard> = (
  props
): JSX.Element => {
  return (
    <div
      className="discussion-card"
      aria-hidden
      onClick={() => {
        props.setSelectedCardIndex(props.indexNumber);
        props.setModal(true);
      }}
    >
      <div key={props.gatherPostId} className="discussion-card__body">
        <div className="discussion-card__header">
          <div className="discussion-card__subject">
            <h5>{props.subject}</h5>
          </div>
          <div className="discussion-card__author">
            <div>
              <div className="discussion-card__logo">
                <BaseLogo
                  name={props.authorUserName as string}
                  logoSrc={props.authorProfileLogoUrl}
                  isPlaceHolder={undefined}
                />
              </div>

              <span className="discussion-card__username">
                {props.authorUserName}
              </span>
            </div>
            <small>{props.isEdited && "Edited"}</small>
          </div>
        </div>
        <div className=" discussion-card__topic">
          {props.mediaUrls.length !== 0 && (
            <div className="discussion-card__images">
              <ul>
                {props.mediaUrls &&
                  props.mediaUrls.length > 1 &&
                  props.mediaUrls.slice(0, 4).map((url, index) => {
                    return (
                      <li key={props.subject}>
                        <img
                          className={`${
                            index === 0 || index === 1 ? "mb-1" : ""
                          }`}
                          width={37}
                          height={37}
                          src={url}
                        />
                      </li>
                    );
                  })}
              </ul>
              {props.mediaUrls && props.mediaUrls.length === 1 && (
                <figure className="adaptive">
                  <picture className="adaptive-photo">
                    <img width={78} height={78} src={props.mediaUrls[0]} />
                  </picture>
                </figure>
              )}
              {props.mediaUrls && props.mediaUrls.length > 4 ? (
                <span>+{props.mediaUrls.length - 4}</span>
              ) : (
                ""
              )}
            </div>
          )}
          <p className=" discussion-card__content "> {props.details}</p>
        </div>
        <div>
          <div className="gather-divider" />
          <div className="discussion-card__footer ">
            <div aria-hidden>
              <img src={comments} width={21} />
            </div>
            <span>{props.CommentsAmount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleDiscussionComponent;
