import React, { HTMLAttributes } from "react";

type BadgeProps = {
  children: React.ReactNode;
  customStyles?: string;
  onClickAction?: () => void;
  variant: string;
  reff?: React.Ref<HTMLDivElement>; // Use React.Ref for refs
} & HTMLAttributes<HTMLDivElement>;

export function Badge({
  children,
  customStyles,
  onClickAction,
  variant,
  reff,
  ...props
}: BadgeProps) {
  return (
    <div
      ref={reff}
      onClick={onClickAction}
      className={`${customStyles ? customStyles : ""} ${
        variant ? `badge bg-${variant}` : ""
      }`}
      {...props}
    >
      {children}
    </div>
  );
}
