export const createTeamInitialValues = {
  name: "",
  description: "",
  logoUrl: undefined,
  type: "public" as const,
  creatorType: "Created By You",
  games: [],
};

export const editTeamValues = {
  name: "",
  teamLogo: "",
  description: "",
  games: [],
};
