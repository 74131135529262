// import { singleChallengeAltProps } from "@/features/challenge/utils/singleChallengeProps";
import React, { FC } from "react";
import { BestgamerEventHead } from "./../../../components/bestgamer/bestgamerEventHead/BestgamerEventHead";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { activityContent } from "../consts/activityContent";

export const EventActivityHead: FC<{ slug: string }> = ({ slug }) => {
  const startDate = "2025-07-28T12:34:56Z"; // START DATE
  let dateObj = new Date(startDate);
  let DateToS = dateObj.getTime() / 1000;
  const content = activityContent?.[slug];

  return (
    <div className="head">
      <div className="head__image">
        <div>
          <img src={content.image} alt="" />
        </div>
      </div>

      <BestgamerEventHead
        title={"Activity"}
        startDate={{
          seconds: Date.now(),
          nanoseconds: 0,
        }}
        endDate={{
          seconds: DateToS,
          nanoseconds: 0,
        }}
        brand={brand.brandValue}
      />
    </div>
  );
};
