import React, { Fragment, FC } from "react";
import SingleNotificationItem from "../SingleNotificationItem";
import { Query_shared } from "@/api/shared";
import readAllMessages from "../../helperFunctions/readAllMessages";
import { Button, ErrorFallback } from "@/components";
import CheckArrow from "../../assets/participate-active.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconCheckmark } from "../../assets/check.svg";

type Props = {
  loading: boolean;
  isFullPage?: boolean;
  notifications?: any;
  setDropdownOpen: any;
  unreadNotifications: number;
  updateNotifications: any;
};
const NotificationsList: FC<Props> = ({
  notifications,
  isFullPage,
  loading,
  setDropdownOpen,
  unreadNotifications,
  updateNotifications,
}): JSX.Element => {
  const navigate = useNavigate();
  try {
    return (
      <>
        <div className="notifications">
          <div className="notifications__actions">
            {unreadNotifications !== 0 && isFullPage && notifications ? (
              <>
                <Button
                  variant="link"
                  customStyles="notifications__btn"
                  onClickAction={async () =>
                    await readAllMessages(notifications).then(() => {
                      updateNotifications();
                    })
                  }
                >
                  <IconCheckmark stroke="#D1D5E0" width={20} height={20} />
                  <span aria-hidden>Mark all as read</span>
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          {notifications && (
            <>
              <div className="notifications__list">
                {notifications
                  .sort(
                    (a: any, b: any) =>
                      a.data.createdAt.seconds - b.data.createdAt.seconds
                  )
                  // .reverse()
                  .slice(0, isFullPage ? 25 : 5)
                  .map((doc: any) => {
                    return (
                      <Fragment key={doc.realtimeDocumentID}>
                        <SingleNotificationItem
                          data={doc.data}
                          docId={doc.realtimeDocumentID}
                          whenWasSent={Query_shared.getWhenWasPosted(
                            doc.data.createdAt._seconds * 1000
                          )}
                        />
                      </Fragment>
                    );
                  })}
              </div>
            </>
          )}

          {isFullPage === true && (
            <div className="notifications__information">
              <small>
                Read notifications are automatically deleted after 10 days.
              </small>
            </div>
          )}
        </div>

        <div className="notifications__actions">
          {isFullPage === false && unreadNotifications !== 0 ? (
            <>
              <Button
                variant="link"
                customStyles="notifications__btn"
                onClickAction={async () =>
                  await readAllMessages(notifications).then(() => {
                    updateNotifications();
                  })
                }
              >
                <IconCheckmark stroke="#D1D5E0" width={20} height={20} />
                <span aria-hidden>Mark all as read</span>
              </Button>
            </>
          ) : (
            <></>
          )}
          {isFullPage === false && (
            <>
              <a
                className="btn btn-link notifictations__btn"
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                All notifications
              </a>
            </>
          )}
        </div>
      </>
    );
  } catch {
    return <ErrorFallback>Error fetching Notifications</ErrorFallback>;
  }
};

export default React.memo(NotificationsList);
