import React, { FC } from "react";
import { Task } from "../types/dailyTasksTypes";
import { Button, Spinner } from "@/components";
import { createToast } from "@/utils/toaster/createToast";

export const ActiveTask: FC<{
  activeTaskData: Task;
  updateTask: (
    type: "quiz" | "participate",
    answerKey?: string
  ) => Promise<void>;
  loadingUpdate: boolean;
}> = ({ activeTaskData, updateTask, loadingUpdate }) => {
  return (
    <div className="active__task">
      {/* Card */}
      {/* <div> */}
      <div>
        <h4>{activeTaskData.task}</h4>
      </div>
      <Button
        variant={activeTaskData.participated ? "link" : "primary"}
        customStyles="task__action"
        onClickAction={() => {
          if (!activeTaskData.participated) {
            updateTask("participate");
          } else {
            createToast({
              type: "info",
              message: "Already a participant",
            });
          }
        }}
      >
        {!loadingUpdate &&
          activeTaskData.participated &&
          "collect reward from lenovo admin"}

        {!loadingUpdate && !activeTaskData.participated && "Participate"}

        {loadingUpdate && <Spinner />}
      </Button>
      {/* </div> */}
    </div>
  );
};
