import { FC } from "react";
import logo from "@/assets/placeholders/placeholder.png";

const SingleGatherLoader: FC = (): JSX.Element => {
  return (
    <div className=" Single-gather gather-placeholder">
      <div className="Single-gather__image ">
        <div className="gather-placeholder__figure">
          <img src={logo} className="custom-placeholder wave" />
        </div>
      </div>
      <div className="Single-gather__general-info">
        <h1 className="custom-placeholder wave">placeholder</h1>

        <ul className="gather-placeholder__type">
          <li className="custom-placeholder wave">Placeholder</li>
          <li className="custom-placeholder wave">Placeholder</li>
        </ul>
        <div className="gather-placeholder__discription">
          <p className="custom-placeholder wave">discription placeholder</p>
        </div>
        <ul className="gather-placeholder__tags">
          {Array.from(Array(3), (e, index) => {
            return (
              <li key={index} className="custom-placeholder wave">
                Placeholder
              </li>
            );
          })}
        </ul>
      </div>

      <div className="Single-gather__date gather-placeholder__date"></div>
      <div className="Single-gather__participants gather-placeholder__participants ">
        <p className="custom-placeholder wave">"text placeholder"</p>
        <div className="gather__participants--divider" />
        <p className="custom-placeholder wave">"text placeholder"</p>
      </div>
      <div className="Single-gather__discussion gather-placeholder__discussion">
        <div>
          <div className="gather-placeholder__image">
            <img src={logo} className="custom-placeholder wave" />
          </div>
          <p className="custom-placeholder wave">"text placeholder"</p>
        </div>
        <div>
          <div className="gather-placeholder__image">
            <img src={logo} className="custom-placeholder wave" />
          </div>
          <p className="custom-placeholder wave">"text placeholder"</p>
        </div>
      </div>
      <div className="Single-gather__location-creator gather-placeholder__location-creator ">
        <div className="gather-placeholder__meet-place"></div>
        <div className=" gather-placeholder__creator-card">
          <div>
            <div className="gather-placeholder__image">
              <img src={logo} className="custom-placeholder wave" />
            </div>
            <div className=" user">
              <p className="custom-placeholder wave">"text placeholder"</p>
            </div>
            <div className=" gather-placeholder__follower">
              <p className="custom-placeholder wave">followers</p>
            </div>
          </div>
          <div className="gather-placeholder__Button ">
            <p className="custom-placeholder wave">followers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleGatherLoader;
