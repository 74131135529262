import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
} from "@/utils/constants/stringProcessing";
import { CreateTeamTypes } from "../types/CreateTeamTypes";
import { validateNotTaken } from "@/utils/constants/validationUtils";

export const validateTeamForm = async (
  values: CreateTeamTypes,
  step: number
) => {
  const errors: {
    [key: string]: string;
  } = {};

  if (step === 0) {
    if (!values.type) {
      errors.type = "Choose access type";
    }
    if (values.name.length > 25) {
      errors.name = "Max 25 characters";
    }
    if (values.name.length < 3) {
      errors.name = "Min 3 characters";
    }
    if (!values.description) {
      errors.description = "Add a short description.";
    }
    if (values.games.length === 0) {
      errors.games = "Add some games or keywords.";
    }
    const slugToBeChecked = encodeURI(
      decodeURI(convertCleanEntityNameToSlug(cleanEntityName(values.name)))
    );

    const result = await validateNotTaken(
      "create",
      slugToBeChecked,
      "teams",
      "slug",
      "Team name",
      false
    );

    if (result) {
      errors.name = result;
    }

    const resultName = await validateNotTaken(
      "create",
      values.name,
      "teams",
      "name",
      "Team name",
      false
    );

    if (resultName) {
      errors.name = resultName;
    }

    if (!values.logoUrl) {
      errors.logoUrl = "Upload a picture";
    }
  }

  return Object.entries(errors).length === 0 ? undefined : errors;
};
