import { FC } from "react";
import AboutPicture1 from "../assets/aboutPicture1alt.png";
import AboutPicture2 from "../assets/aboutPicture2alt.png";
import AboutPicture3 from "../assets/about3.webp";
import AboutPicture4 from "../assets/about4.webp";
import { BestgamerLandingTextImage } from "./BestgamerLandingTextImage";

export const BestgamerLandingAbout: FC<{}> = ({}) => {
  const tourInfoHeading = "What is best gamer tour?";
  const participateInfoHeading = "Who can participate?";

  const aboutCards = [
    {
      image: AboutPicture1,
      title: "free play and challenges",
      tags: ["challenges", "activities", "treasure hunt"],
      desc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
      btn: "sign up",
    },
    {
      image: AboutPicture2,
      title: "fully automated data collection",
      tags: ["something", "and something"],
      desc: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu abia nostra, per inceptos himenaeos..",
      btn: "sign up",
    },
    {
      image: AboutPicture3,
      title: "for casual gamers",
      tags: ["family friendly", "free to play", "win prizes"],
      desc: "Have the best time with your family and friends at Best Gamer tour, explore new exclusive games, play on top performance computers and participate in challenges and chill activities!",
      btn: "what can i do at best gamer",
    },
    {
      image: AboutPicture4,
      title: "for competitive gamers",
      tags: ["fair score system", "set records", "prove your skills"],
      desc: "Want to get the title of the Best Gamer in your city? Meet other gamers, show us all you got, climb to the top of leaderboards and get all the loot!  ",
      btn: "how do i win the best gamer title",
    },
  ];

  return (
    <div className="about container">
      <h2>{tourInfoHeading}</h2>
      <div className="about__tour-info">
        {Array.from(aboutCards.slice(0, 2), (card, index) => {
          return (
            <BestgamerLandingTextImage {...card} layout="row" key={`tour` + index} />
          );
        })}
      </div>

      <h2>{participateInfoHeading}</h2>
      <div className="about__participate-info">
        {Array.from(aboutCards.slice(2, 4), (card, index) => {
          return (
            <BestgamerLandingTextImage {...card} layout="column" key={`participate` + index} />
          );
        })}
      </div>
    </div>
  );
};