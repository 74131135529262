import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { Spinner } from "@/components/Spinner";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import SelectInput from "@/components/SelectInput/SelectInput";
import QueryDB from "@/api/queryDB/common";
import checkIfPubgUsernameExist from "@/features/profile/helperFunctions/checkIfPubgUsernameExist";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

const pubgModalOptions = [
  { text: "Steam", value: "Steam" },
  { text: "Xbox", value: "Xbox" },
  { text: "Kakao", value: "Kakao" },
  { text: "PlayStation", value: "PlayStation" },
];

export const ConnectPubgForm: React.FunctionComponent<{
  onHide: () => void;

  setUser: (arg: string) => void;
}> = ({ onHide, setUser }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [error, setError] = useState<string | null>(null);
  const [openSuccessAlert, setOpenSuccessAlert] = useState<boolean>(false);

  const [values, setValues] = useState({ platform: "", pubgName: "" });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const setFieldValue = async (targetName: string, value: any) => {
    return setValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    setError(null);
    const nameExists = await checkIfPubgUsernameExist(
      values.pubgName,
      values.platform
    );

    if (typeof nameExists === "string") {
      setSubmitting(false);
      return setError(nameExists);
    }

    if (!nameExists) {
      setSubmitting(false);
      return setError(
        "The username doesn’t exist on this platform. Please check again."
      );
    }
    if (!currentUser.uid) {
      setSubmitting(false);
      return setError("You are not authenticated.");
    }
    QueryDB.createNewDoc(
      "profilePubg",
      {
        pubgPlatform: values.platform,
        pubgName: values.pubgName,
      },
      `${currentUser.uid}`
    ).then(() => {
      setSubmitting(false);
      setUser(values.pubgName);
      setOpenSuccessAlert(true);
    });
  };
  return (
    <>
      <BaseModalWindow
        show={openSuccessAlert}
        closeModal={() => {
          setOpenSuccessAlert(false);
        }}
        size="modal--connect-account"
        title="PUBG account connected"
      >
        <div className="connect-pubg">
          <p>Your PUBG account is successfully connected.</p>
          <p>Games unlocked:</p>
          <span>PUBG</span>

          <div className="connect-pubg__button">
            <Button variant="primary" onClick={onHide}>
              Understood
            </Button>
          </div>
        </div>
      </BaseModalWindow>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setError("");
          handleSubmit();
        }}
        autoComplete="off"
        className="connect"
      >
        <div className="connect__grid">
          <div className="connect__input-group">
            <BaseTextInput
              label="PUBG username"
              textType="text"
              value={values.pubgName}
              placeholder="PUBG username"
              setObjectState={setFieldValue}
              setObjectStateTargetName="pubgName"
              error={error ? error : ""}
              />
          </div>
          <div className="connect__input-group">
            <label className="settings__label">
              Select your platform
            </label>
            <SelectInput
              name=""
              value={values.platform}
              placeholder="Please choose an option"
              hasUploading={false}
              onChange={(e: any) => setFieldValue("platform", e.target.value)}
              >
              {pubgModalOptions.map((element) => (
                <option value={element.value} key={element.value}>
                  {element.text}
                </option>
              ))}
            </SelectInput>
          </div>
        </div>
        <div className="connect__actions">
          {submitting ? (
            <Button variant="secondary" type="submit" disabled>
              <Spinner />
            </Button>
          ) : (
            <Button variant="primary" type="submit" disabled={submitting}>
              Connect
            </Button>
          )}
        </div>

        {error && <p className="profile-connect-pubg__error">{error}</p>}
      </form>
    </>
  );
};
