import { useEffect, useMemo } from "react";
import { TeamCardComponent } from "../teamCard/TeamCardComponent";
import TeamsOverviwLoader from "@/components/skeletonLoaders/teamsOverviewLoader/teamsLoader";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useDiscoverTeamsData } from "../../hooks/useDiscoverTeamsHook";
import { useInView } from "react-intersection-observer";

const filterKey = "discover-new";
export default function DiscoverNewTeams({
  setLoading,
  setSelectedCreatorType: any,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCreatorType?;
}) {
  const { data, loading, fetchDiscoverTeamsData, hasMore } =
    useDiscoverTeamsData({
      client: brand.brandValue,
    });

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  //only runs at first render
  useEffect(() => {
    (async () => {
      fetchDiscoverTeamsData();
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (inView) {
        await fetchDiscoverTeamsData();
      }
    };

    fetchData();
  }, [inView, hasMore]);

  // Variables
  const inViewRefElementIndex = useMemo(() => {
    return data ? data?.length - 1 : 1;
  }, [data, hasMore]);
  const teamsDataNotEmpty = data?.length !== 0;

  // Loading state
  if (!data && loading) {
    return (
      <div className="teams__list">
        {Array.from(Array(12), (_, ind) => {
          return <TeamsOverviwLoader key={ind} />;
        })}
      </div>
    );
  }

  // No errors, there is teamsData and teamsData is not empty
  if (data && teamsDataNotEmpty) {
    return (
      <div className="teams__list">
        {data.map((team: any, index: number) => {
          const key = `${team.id}_${index}`;
          return (
            <div
              className="teams__item"
              key={key}
              ref={index === inViewRefElementIndex ? ref : null}
            >
              <TeamCardComponent teamData={team} />
            </div>
          );
        })}
      </div>
    );
  }

  // Any other case
  return (
    <div className="teams__empty">
      <p>There are no teams to discover.</p>
    </div>
  );
}
