import { CreatePostForm, SinglePostComponent } from "@/features/feed";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import React, { FC, useEffect, useState } from "react";
import { Query_shared } from "@/api/shared";
import { Spinner } from "@/components/Spinner";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import FeedLoader from "@/components/skeletonLoaders/profileLoader/FeedLoader";
import { useSingleTeamFeed } from "../hooks/useSingleTeamFeed";
import { useAtom, useAtomValue } from "jotai";
import { visitedTeamsFeedAtom } from "@/utils/atoms/teamsAtoms/teamsAtoms";

export const SingleTeamFeedFetcher: FC<{
  slug: string;
  isAuthorized: boolean;
  communityData: any;
  teamData: SingleTeamResponseTypes;
  isPreview?: boolean;
}> = ({ slug, isAuthorized, communityData, teamData, isPreview = false }) => {
  const filterKey = "team_feed";
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [feedUpdateTrigger, setFeedUpdateTrigger] = useState<number>(0);

  const [
    teamFeed, // this feed will be either posts/challenges/gathers/teams,etc based on feedType
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchFeed,
    ref,
    inView,
    setSinglePost,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<any[] | undefined, any>(
    "getTeamPosts",
    slug,
    filterKey,
    false
  );

  const [visitTeamsFeedAtomData, setVisitedTeamsFeedAtomData] =
    useAtom(visitedTeamsFeedAtom);
  const teamFeedData = visitTeamsFeedAtomData?.[slug] || teamFeed;
  useEffect(() => {
    if (teamFeed && !_loading) {
      setVisitedTeamsFeedAtomData({
        ...visitTeamsFeedAtomData,
        [slug]: teamFeed,
      });
    }
  }, [teamFeed, _loading]);

  useEffect(() => {
    fetchFeed({ lastFeedDateInSeconds: 0, teamId: slug }, filterKey);
  }, [feedUpdateTrigger]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  async function fetchMore() {
    await fetchFeed(
      {
        lastFeedDateInSeconds,
        teamId: slug,
      },
      filterKey,
      true
    );
  }
  const canPostInTeam = teamData.canCurrentUserPostInFeed;
  const canPostInComments = teamData.canCurrentUserLeaveComment;
  const noPostsInFeed = teamFeed?.length === 0;
  const teamFeedDataToUse = teamFeedData || teamFeed;
  // always set the inview reference to the 7th last element.
  //Why? When user starts to scroll down, we can start fetching the next 10 posts in advanced.
  const inViewRefElementIndex = teamFeed ? teamFeed?.length - 3 : -1;

  // Loading and no errors
  if (!teamFeedData && _loading && !error) {
    return (
      <>
        <FeedLoader />
      </>
    );
  }

  // If post feed is empty
  if (noPostsInFeed) {
    return (
      <>
        {canPostInTeam && (
          <CreatePostForm
            postedIn="team"
            currentUser={currentUser}
            communityData={communityData}
            setFeedUpdateTrigger={setFeedUpdateTrigger}
            disabledInput={_loading || isPreview}
            setSinglePost={(data) => setSinglePost(data, filterKey)}
            parentId={slug}
            isPreview={isPreview}
          />
        )}
        <p>There are currently no posts.</p>
      </>
    );
  }
  // Any other case
  return (
    <>
      {canPostInTeam && (
        <CreatePostForm
          postedIn="team"
          currentUser={currentUser}
          communityData={communityData}
          setFeedUpdateTrigger={setFeedUpdateTrigger}
          disabledInput={_loading || isPreview}
          setSinglePost={(data) => setSinglePost(data, filterKey)}
          parentId={slug}
          isPreview={isPreview}
        />
      )}
      {teamFeedData &&
        teamFeedData.map((post: any, index: number) => {
          const key = post.id;
          return (
            <div
              className="feed-post"
              key={key}
              ref={index === inViewRefElementIndex ? ref : null}
            >
              <SinglePostComponent
                postData={post}
                isFullPagePost={false}
                actionButtons
                isInModal={false}
                isFriendsOfCurrentUser={true}
                likeParentId={teamData.slug}
                createCommentEnabled={canPostInComments}
                isAuthorized={isAuthorized}
              />
            </div>
          );
        })}
    </>
  );
};
