import { Button } from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export default function NoTeamFoundError() {
  const { slug } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <h1>Error!</h1>
      <p className="invalid-feedback">
        The team named <strong>{slug}</strong> is not found.
      </p>
      <Button
        variant="outline-primary"
        customStyles="active"
        onClickAction={() => navigate(siteRoutes["teams"])}
      >
        Back to teams
      </Button>
    </div>
  );
}
