import { cacheDatabase, timestamp } from "../firebase/firebase.config";

type IProps = {
  currentUserId: string;
  lastFeedDateInSeconds: number | null;
};
const AMOUNT_TO_BE_FETCHED = 18;
type Response = {
  dataArray: any[];
  hasMore: boolean;
  lastFeedDateInSeconds: number | null;
  status: "success" | "error";
};

export async function getMineTeamsFromCache({
  currentUserId,
  lastFeedDateInSeconds,
}: IProps): Promise<Response> {
  let databaseRef = cacheDatabase
    .ref("/teams")
    .child(currentUserId)
    .orderByChild("negativeCreated");
  if (lastFeedDateInSeconds !== null) {
    databaseRef = databaseRef.startAfter(lastFeedDateInSeconds);
  }

  try {
    const myTeamsDataSnapshot = await databaseRef
      .limitToFirst(AMOUNT_TO_BE_FETCHED)
      .get();

    const dataArray: any[] = [];
    myTeamsDataSnapshot.forEach((team) => {
      const teamValue = team.val();
      if (teamValue) {
        dataArray.push(teamValue);
      }
    });
    const hasMore = dataArray.length === AMOUNT_TO_BE_FETCHED;
    let _lastFeedDateInSeconds: number | null = null;
    if (hasMore) {
      _lastFeedDateInSeconds = dataArray[dataArray.length - 1]?.negativeCreated;
    }

    return {
      dataArray,
      hasMore,
      lastFeedDateInSeconds: _lastFeedDateInSeconds,
      status: "success",
    };
  } catch (e: any) {
    return {
      dataArray: [],
      hasMore: false,
      lastFeedDateInSeconds: null,
      status: "error",
    };
  }
}

const teamMembersRef = cacheDatabase.ref("/team-members");
type Params = {
  slug: string;
  lastFeedDateInSeconds: number | null;
  currUserId: string;
};

export async function searchTeamMember(teamSlug: string, searchText: string) {
  const searchVals = await teamMembersRef
    .child(teamSlug)
    .orderByChild("userName")
    .startAt(searchText)
    .endAt(searchText + "\uf8ff")
    .limitToFirst(10)
    .get();

  const members: Response["dataArray"] = [];
  searchVals.forEach((val) => {
    const user = val.val();
    if (user) {
      //always keep curruser on top
      members.push(
        Object.assign(user, {
          fullName: user.fullName || "",
          joinedTeamTimestamp: {
            _seconds: user.joinedTeamTimestamp,
          },
          logoUrl: user.profileLogoUrl,
          roleScore: undefined,
        })
      );
    }
  });
  return members;
}
export async function getTeamMembersBasedOnSlug({
  slug,
  lastFeedDateInSeconds,
  currUserId,
}: Params): Promise<Response> {
  try {
    let teamMembersQuery = teamMembersRef.child(slug).orderByChild("roleScore");

    if (lastFeedDateInSeconds !== null) {
      teamMembersQuery = teamMembersQuery.startAfter(lastFeedDateInSeconds);
    }
    const [teamMembers, currentUserMember] = await Promise.all([
      teamMembersQuery.get(),
      teamMembersRef.child(slug).child(currUserId).get(),
    ]);

    const dataArray: any[] = [];

    const currUserVal = currentUserMember.val();

    if (currUserVal) {
      //always keep curruser on top
      dataArray.push(
        Object.assign(currUserVal, {
          fullName: currUserVal.fullName || "",
          joinedTeamTimestamp: {
            _seconds: currUserVal.joinedTeamTimestamp,
          },
          logoUrl: currUserVal.profileLogoUrl,
          roleScore: undefined,
        })
      );
    }

    teamMembers.forEach((userJunction) => {
      const userJunc = userJunction.val();
      if (!userJunc) {
        return;
      }
      if (userJunc.uid === currUserId) {
        //do not include curr user profile
        return;
      }
      dataArray.push(
        Object.assign(userJunc, {
          fullName: userJunc.fullName || "",
          joinedTeamTimestamp: {
            _seconds: userJunc.joinedTeamTimestamp,
          },
          roleScore: undefined,
          logoUrl: userJunc.profileLogoUrl,
        })
      );
    });
    const hasMore = dataArray.length === AMOUNT_TO_BE_FETCHED;
    let _lastFeedDateInSeconds: number | null = null;
    if (hasMore) {
      _lastFeedDateInSeconds = dataArray[dataArray.length - 1]?.negativeCreated;
    }
    return {
      dataArray,
      hasMore,
      lastFeedDateInSeconds: _lastFeedDateInSeconds,
      status: "success",
    };
  } catch (e: any) {
    return {
      dataArray: [],
      hasMore: false,
      lastFeedDateInSeconds: null,
      status: "error",
    };
  }
}

export function currentUserTeamMemberRef({ slug, currUserId }) {
  return teamMembersRef.child(slug).child(currUserId);
}

export async function getFirstFourMembersOfATeam({
  slug,
  currUserId,
}: {
  slug: string;
  currUserId: string;
}) {
  // const currUserVal = isCurrentUserMemberNow({
  //   slug,
  //   currUserId,
  // });

  const teamMembers = await teamMembersRef
    .child(slug)
    .orderByChild("roleScore")
    .limitToFirst(4)
    .get();

  const dataArray: any[] = [];

  // const currUserVal = currentUserMember.val();
  // if (currUserVal) {
  //   dataArray.push(
  //     Object.assign(currUserVal, {
  //       fullName: currUserVal.fullName || "",
  //       joinedTeamTimestamp: {
  //         _seconds: currUserVal.joinedTeamTimestamp,
  //       },
  //       logoUrl: currUserVal.profileLogoUrl,
  //       roleScore: undefined,
  //     })
  //   );
  // }

  teamMembers.forEach((userJunction) => {
    const userJunc = userJunction.val();
    if (!userJunc) {
      return;
    }
    if (userJunc.uid === currUserId) {
      //do not include curr user profile
      return;
    }
    dataArray.push(
      Object.assign(userJunc, {
        fullName: userJunc.fullName || "",
        joinedTeamTimestamp: {
          _seconds: userJunc.joinedTeamTimestamp,
        },
        logoUrl: userJunc.profileLogoUrl,
        roleScore: undefined,
      })
    );
  });
  return dataArray;
}
