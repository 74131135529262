import { FC } from "react";
import BaseImage from "@/components/BaseImage/BaseImage";
import { ReactComponent as LandingMarker } from "../../../../../../public/assets/bestgamer/landingCircleMarker.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components";
import { siteRoutes } from "../../../../../../branchAndBrandSpecific/routes/siteRoutesList";


interface BestgamerLandingTextImageProps {
    image: string;
    title: string;
    tags: string[];
    desc: string;
    btn: string;
    layout: "row" | "column";
}

export const BestgamerLandingTextImage: FC<BestgamerLandingTextImageProps> = ({
    layout,
    image,
    title,
    tags,
    desc,
    btn,
}) => {
    const navigate = useNavigate();

    const layoutClasses = `text-image text-image--${layout}`;
    
    return (
        <div className={layoutClasses}>
            <div className="text-image__image">
                <BaseImage logoUrl={image} aspectRatio="2x1" />
            </div>
            <div className="text-image__content">
                <div className="text-image__body">
                    <h3>{title}</h3>
                    <ul>
                        {tags.map((tag) => {
                            return (
                                <li>
                                    <LandingMarker />
                                    {tag}
                                </li>
                            );
                        })}
                    </ul>
                    <p>{desc}</p>
                    <Button
                        variant="primary"
                        onClickAction={() => {
                            return navigate(siteRoutes["signUp"]);
                        }}
                    >
                        {btn}
                    </Button>
                </div>
            </div>
        </div>
    );
};
