import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { _CollectionsNamesWithSlug } from "./common.type";

export async function _validateSlugBasedOnCollectionName(
  collectionName: _CollectionsNamesWithSlug,
  slug: string
) {
  const collectionDocs = await collectionRef[collectionName]
    .where("slug", "==", slug)
    .limit(1)
    .get();
  if (collectionDocs.empty) {
    return false;
  }
  return true;
}
