import firebase from "firebase/app";

export const _setNewsFavorite = async (newsId: string) => {
  if (!newsId) return;

  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("setNewsFavorite");

  await func({
    newsId,
  });
};
