import { _convertImageToImgIX } from "./_convertImageToImgix";
import { _convertImageToImgIXAndUpdateInFirebase } from "./_convertToImgixAndUpdateToFirebase";
import { _getImgixUrlProps } from "./_getImgixUrlProps";

export class Query_Imgix {
  constructor() {}
  static convertImageToImgIX = _convertImageToImgIX;
  static convertImageToImgIXAndUpdateInFirebase =
    _convertImageToImgIXAndUpdateInFirebase;
  static getImgixUrlProps = _getImgixUrlProps;
}
