import { Button, Spinner } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const CreateChallengeFooter: FC<{
  step: number;
  stepsAmount: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  handleNextStep: () => Promise<false | void>;
  isEdit: boolean;
  setIsEdit: any;
}> = ({
  step,
  setStep,
  loading,
  stepsAmount,
  handleNextStep,
  isEdit,
  setIsEdit,
}) => {
  const navigate = useNavigate();

  return (
    <div className="create-challenge__footer">
      {step !== 3 ? (
        <div className="footer__buttons">
          {step !== 0 && (
            <Button
              variant="secondary"
              disabled={loading}
              onClickAction={() => setStep((s) => s - 1)}
            >
              Back
            </Button>
          )}
          {step === 0 && (
            <Button
              variant="secondary"
              onClickAction={() => {
                if (isEdit) {
                  setIsEdit(false);
                } else {
                  navigate(siteRoutes["challenges"]);
                }
              }}
            >
              Cancel
            </Button>
          )}
          {isEdit || step !== 0 ? (
            <Button
              disabled={loading}
              variant="primary"
              type="button"
              onClickAction={() => handleNextStep()}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>{step === stepsAmount - 1 ? "Preview" : "Next step"}</>
              )}
            </Button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
