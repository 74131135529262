import React, { useState, useEffect } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { EditTeamTypes } from "../../types/CreateTeamTypes";
import { editTeamValues } from "../../utils/CreateTeamValues";
import { validateTeamSettingsValues } from "@/utils/constants/validationUtils";
import { CreateTeamStep1 } from "../create/pages/CreateTeamStep1";
import { Button, Spinner } from "@/components";
import { createOrUpdateTeams } from "../../helperFunctions/createOrUpdateTeam";
import { createToast } from "@/utils/toaster/createToast";

export const EditTeamForm: React.FunctionComponent<{
  name: string;
  teamLogo?: string;
  description: string;
  games: string[];
  teamSlug: string;
  setUpdateTrigger: any;
  isEdit: boolean;
  setSettingsOpen: any;
  teamSettings: any;
  setTeamSettings: any;
}> = (props) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [errors, setErrors] = useState();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined); // TEMP

  useEffect(() => {
    props.setTeamSettings({
      name: props.name,
      logoUrl: props.teamLogo,
      description: props.description,
      games: props.games,
    });
  }, []);

  const handleSubmit = async () => {
    const hasErrors = await validateTeamSettingsValues(
      props.teamSettings,
      props.name
    );

    if (hasErrors) {
      return setErrors(hasErrors);
    }

    setloading(true);
    await createOrUpdateTeams(
      props.teamSettings,
      props.teamSlug,
      currentUser,
      true
    )
      .then(() => {
        createToast({
          type: "success",
          message: `Team \`${props.teamSettings.name}\`'s info has been updated!`,
        });
      })
      .catch((err) => {
        createToast({
          type: "error",
          message: `Team \`${props.teamSettings.name}\`'s failed to update!`,
        });
      });

    props.setUpdateTrigger((prev: number) => prev + 1); //Update data after changes
    setErrors(undefined);
    setloading(false);
  };

  const setFieldValue = async (targetName: string, value: string) => {
    setHasChanged(true);
    return props.setTeamSettings((prev) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  return (
    <>
      <CreateTeamStep1
        setFieldValue={setFieldValue}
        teamValues={props.teamSettings}
        slug={props.teamSlug}
        errors={errors}
        isEdit={props.isEdit}
      />
      <div className="create-team__actions">
        <Button
          variant="secondary"
          onClickAction={() => props.setSettingsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={!hasChanged}
          variant="primary"
          onClickAction={() => handleSubmit()}
        >
          {loading ? <Spinner /> : "Save changes"}
        </Button>
      </div>
    </>
  );
};

export default EditTeamForm;
