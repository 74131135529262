import { _imageErrorHandler } from "./_imageErrorHandler";
import { _uploadFromBlobAsync } from "./_uploadFromBlobAsync";
import { _getWhenWasMadeOrPosted } from "./_getWhenWasMadeOrPosted";

export class Query_shared {
  constructor() {}
  static imageErrorHandler = _imageErrorHandler;
  static uploadFromBlobAsync = _uploadFromBlobAsync;
  static getWhenWasPosted = _getWhenWasMadeOrPosted;
}
