import React, { memo, FC, useState } from "react";
import { Link } from "react-router-dom";
import { CreatorTypesOptions } from "@/features/challenge/utils/CreatorTypes";
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import { Query_shared } from "@/api/shared/index";
import { Query_Imgix } from "@/api/imgix/index";
import { ChallengeOverviewCardProps } from "@/features/challenge/types/props/OverviewProps";
import { getProgressBarValue } from "@/features/challenge/helperFunctions/getProgressBarValue";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import i18next from "i18next";

const ChallengeCardComponent: FC<
  ChallengeOverviewCardProps & { isPreview?: boolean }
> = ({ isPlaceholder = false, isPreview, ...props }): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);
  const getLink = () => {
    if (isPreview) {
      return "/create-challenge";
    }
    if (isPlaceholder) {
      return "/challenges";
    }
    return `/challenges/${props.slug}`;
  };

  return (
    <div key={props.key} className="challenge-card">
      <div className="challenge-card__content">
        <div className="challenge-card__image" data-my-img="resizable">
          <Link to={getLink()}>
            <div className="image">
              <div className="image__content">
                <img
                  onError={() => setLogoError(true)}
                  src={Query_shared.imageErrorHandler(
                    props.logo?.includes("wog.imgix") ||
                      props.logo?.includes("iplay.imgix")
                      ? `${props.logo}?${Query_Imgix.getImgixUrlProps({
                          qty: "100",
                          w: "400",
                        })}`
                      : props.logo,
                    logoError,
                    isPlaceholder
                  )}
                />
              </div>
              <div className="challenge-card__game" title={props.game}>
                <span className="challenge-card__game--text">
                  {props.game === "Call of Duty Warzone"
                    ? "Warzone"
                    : props.game}
                </span>
              </div>
            </div>
          </Link>
        </div>{" "}
        <div className="challenge-card__heading">
          <Link to={getLink()}>
            <h5 title={props.name}>{props.name}</h5>
            <div
              className="challenge-card__creator"
              title={props.author?.userName}
            >
              <div className="challenge-card__avatar">
                {props.author && (
                  <div className="image">
                    <div className="image__content">
                      <BaseUserAvatar
                        logoUrl={props.author?.profileLogoUrl}
                        userName={props.author?.userName}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p className="challenge-card__username">
                  {isPlaceholder ? "Placeholder" : props.author?.userName}
                  {!props.author && props.goal ? props.goal : ""}
                </p>
              </div>
            </div>
            <ul className="challenge-card__criteria">
              {props.fieldToCompare &&
                props.fieldToCompare.map((field, ind) => {
                  return (
                    <li key={ind} title={field}>
                      {field}
                    </li>
                  );
                })}
            </ul>
          </Link>
        </div>
      </div>
      <div className="challenge-card__footer">
        {!isPreview && (
          <div>
            {props.isCurrUserParticipant ? (
              <Link
                className="challenge-card__button challenge-card__button--joined"
                to={getLink()}
                title={`You have already joined the ${props.name} challenge`}
              >
                {i18next.t("challenge.buttons.joined")}
              </Link>
            ) : (
              <Link
                className="challenge-card__button"
                to={getLink()}
                title={`Join the ${props.name} challenge`}
              >
                {i18next.t("challenge.buttons.join")}
              </Link>
            )}
          </div>
        )}
        <div
          className="challenge-card__progress"
          title={`Time left on the ${props.name} challenge`}
        >
          <ProgressBar
            id={`progress-bar__${props.slug}`}
            label={[""]}
            value={getProgressBarValue(
              props.startDate ? props.startDate._seconds : undefined,
              props.endDate ? props.endDate._seconds : undefined
            )}
            color={CreatorTypesOptions.Community.activeColor}
            onOverview
            isPlaceholder={false}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ChallengeCardComponent);
