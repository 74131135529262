import QueryDB_Profiles from "@/api/queryDB/profiles";
import { Button } from "@/components";
import { createToast } from "@/utils/toaster/createToast";
import { useState, useEffect } from "react";
type Props = {
  isPlaceholder: boolean;

  isFollowedByCurrentUser: boolean;
  uid: string;
};

export default function CommunityActionButtons(props: Props) {
  const [isFollowed, setIsFollowed] = useState(
    () => props.isFollowedByCurrentUser
  );

  // to ensure that the state gets updated when new data is fetched
  useEffect(() => {
    setIsFollowed(props.isFollowedByCurrentUser);
  }, [props.isFollowedByCurrentUser]);

  if (props.isPlaceholder) {
    return <div className="placeholder" />;
  }

  async function handleCommunityRequest(action: "follow" | "unfollow") {
    const recipientUid = props.uid;
    const actionAndReactDispatchEvent: {
      [key in typeof action]: (reaction: boolean) => void;
    } = {
      follow: (reaction) => {
        setIsFollowed(reaction);
      },

      unfollow: (reaction) => {
        setIsFollowed(!reaction);
      },
    };

    //trigger the action
    actionAndReactDispatchEvent[action](true);

    //trigger the cloud function
    try {
      await QueryDB_Profiles.updateUserCommunityStatus(action, recipientUid);
    } catch (e) {
      createToast({
        type: "error",
        message: `Failed to handle action. Please try again later`,
      });
      // if failed then revert the changes
      actionAndReactDispatchEvent[action](false);
    }
  }

  return (
    <>
      <Button
        variant="link"
        onClickAction={() =>
          handleCommunityRequest(isFollowed ? "unfollow" : "follow")
        }
        // onClick={handleFollowedRequest}
      >
        {isFollowed ? "Unfollow" : "Follow"}
      </Button>
    </>
  );
}
