import QueryRTDB from "@/api/queryDB/realTimeDB";
import "firebase/functions";

export const archiveOrReadNotification = async (
  notificationId: string,
  read: boolean,
  archived: boolean
) => {
  QueryRTDB.updateRealtimeDoc("notifications", notificationId, {
    [`read`]: read,
    [`archived`]: archived,
  });
};
