import { useParams } from "react-router-dom";
import { SingleChallenge } from "../components/single/SingleChallenge.fetcher";

export default function SingleChallengePage() {
  const { slug } = useParams();

  if (!slug) {
    return <></>;
  }
  return <SingleChallenge slug={slug} />;
}
