import { FC, useState } from "react";
import { ReactComponent as ShareIcon } from "../../../../assets/actions/share-arrow-right.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/actions/copy.svg";
import { ReactComponent as ChevronUp } from "../../../../assets/actions/chevron-up.svg";
import { ReactComponent as CommentsIcon } from "../../../feed/assets/comments.svg";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Author, singleNewsOverviewTypes } from "../../types/NewsTypes";
import { createToast } from "@/utils/toaster/createToast";
export const SingleNewsActions: FC<{
  data: singleNewsOverviewTypes & {
    author: Author;
  };
}> = ({ data }) => {
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const scrollToComments = () => {
    // Get the element with the class "comment"
    const commentElement = document.querySelector(".base-input");

    // Check if the element exists
    if (commentElement) {
      // Scroll to the element
      commentElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="article__actions">
      {/* Modal for sharing news */}
      <ShareContentModalWindow
        sharedFrom={"news"}
        createType="create"
        show={shareModalOpen}
        closeWindow={() => setShareModalOpen(false)}
        postData={{
          uid: currentUser.uid,
          postText: "",
          postedIn: "",
          totalSharesNumber: 0,
          postVideo: "",
          created: data.created,
          postPhoto: "",
          wasEdited: false,
          commentsCount: 0,
          totalLikesNumber: 0,
          totalCommentsNumber: 0,
          id: data.slug,
          authorSlug: currentUser.slug,
          authorUsername: currentUser.userName,
          authorLogo: currentUser.profileLogoUrl,
          hasCurrentUserLikedThisPost: false,
          type: "sharedNews",
          additionalData: data,
        }}
      />

      <div className="action">
        <div className="action__group">
          <div
            className="action__item"
            title="Copy the URL"
            onClick={() => {
              navigator.clipboard
                .writeText(window.location.href)
                .then(() => {
                  createToast({
                    type: "success",
                    message: "Copied link to clipboard",
                  });
                })
                .catch(() => {
                  createToast({
                    type: "error",
                    message: "Failed to copy to clipboard",
                  });
                });
            }}
          >
            <CopyIcon width={24} height={24} stroke="#FFF" />
          </div>

          <div
            className="action__item"
            title="Share with your friends"
            onClick={() => {
              setShareModalOpen(true);
            }}
          >
            <ShareIcon width={24} height={24} stroke="#FFF" />
          </div>

          <div 
            className="action__item"
            title="Go to comments"
            onClick={() => scrollToComments()}>
            <CommentsIcon width={24} height={24} stroke="#FFF" />
          </div>
        </div>

        <div className="action__item action__item--top"
            title="Back to the top"
            onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}>
          <ChevronUp width={24} height={24} stroke="#FFF" />
        </div>
      </div>
    </div>
  );
};
