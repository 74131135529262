import React, { FC } from "react";
import DiscussionModalComponent from "./DiscussionModalComponent";

type Props = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  discussion?: any;
  gatherSlug: string;
  discussionsAmount: number;
  activeIndex?: number;
  modal: boolean;
  handleSwitchButton: (arg: "prev" | "next") => void;
};

export const DiscussionModal: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <DiscussionModalComponent
        setModal={props.setModal}
        discussion={props.discussion}
        gatherSlug={props.gatherSlug}
        discussionsAmount={props.discussionsAmount}
        activeIndex={props.activeIndex}
        modal={props.modal}
        handleSwitchButton={props.handleSwitchButton}
      />
    </>
  );
};
