import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle as InfoIcon } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import BaseTooltipWrapper from "@/components/BaseWrappers/BaseTooltipWrapper";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { createChallengeTooltipsText } from "@/features/challenge/utils/CreateChallengeTooltips";

type GeneralInputValuesProps = { text: string; value: string }[];

export const RadioInputsGroup: React.FunctionComponent<{
  name: string;
  options: GeneralInputValuesProps;
  label: string;
  error: string | undefined;
  setFieldValue: any;
  initVal: string;
}> = ({ options, name, label, error, setFieldValue, initVal }) => {
  const [challType, setChallType] = useState<any>(undefined);
  useEffect(() => {
    setChallType(initVal);
  }, [initVal]);
  return (
    <>
      <fieldset className="radio-group">
        <BaseTooltipWrapper
          overlayTrigger={
            <legend className="radio-group__heading">
              <span>{label}</span>
              <InfoIcon />
            </legend>
          }
          tooltipContent={createChallengeTooltipsText[name]}
        />
        <div className="radio-group__input-group">
          {initVal &&
            challType &&
            options.map((option) => (
              <div className="radio-group__input" key={option.value}>
                <label
                  className={
                    challType === option.value ? "active" : "inactive"
                  }
                  key={option.value}
                  htmlFor={`${option.value}`}
                >
                  {option.text}
                </label>
                <input
                  type="radio"
                  name={name}
                  value={`${option.value}`}
                  id={`${option.value}`}
                  onClick={(e: any) => {
                    setChallType(e.target.value);
                    setFieldValue(name, e.target.value);
                  }}
                />
              </div>
            ))}
        </div>
        <FormErrorMessage text={error} isVisible={!!error} />
      </fieldset>
    </>
  );
};
