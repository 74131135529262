import React, { FC } from "react";
import { NewsOverviewTypes } from "../../types/NewsTypes";
import BaseImage from "@/components/BaseImage/BaseImage";
import { Badge } from "@/components";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { getDateInStringFormat } from "@/utils/constants/formatDate";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

const SingleNewsCard: FC<NewsOverviewTypes> = (props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div
      className="news-card"
      onClick={() => {
        navigate(`${siteRoutes["newsAndSlug"]}${props.slug}`);
      }}
    >
      {/* Left side image */}
      <div className="news-card__image">
        <BaseImage logoUrl={props.imageURL} aspectRatio="16x9" />
      </div>

      {/* Right side content */}
      <div className="news-card__info">
        {/* Badges */}
        <div className="news-card__badges">
          <div className="badge-wrapper">
            {props.keywords?.map((word, index) => {
              const key = word + "_" + index;
              return (
                <Badge variant="secondary" key={key}>
                  {word}
                </Badge>
              );
            })}
          </div>
        </div>

        {/* Title */}
        <div className="news-card__title">
          <h2>{props.title}</h2>
        </div>

        {/* Intro */}
        <div className="news-card__excerpt">
          <p>{props.intro}</p>
        </div>

        {/* Meta data  */}
        <div className="news-card__meta">
          {/* Author */}

          <div className="news-card__author">
            <div className="author">
              <div className="author__image">
                <BaseUserAvatar
                  logoUrl={props.author?.profileLogoUrl}
                  userName={props.author?.userName as string}
                />
              </div>

              <div className="author__name">{props.author?.userName}</div>
            </div>
          </div>

          {/* Date */}
          <div className="news-card__date">
            {props.created && props.created._seconds && (
              <time>{getDateInStringFormat(props.created._seconds)}</time>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleNewsCard;
