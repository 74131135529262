import React, { memo, FC, useState, useEffect } from "react";
import { FiSearch as SearchIcon } from "@react-icons/all-files/fi/FiSearch";
import { ReactComponent as Settings } from "../../assets/settings/Options-dots.svg";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { CommunityMember } from "./types/UserCommunity";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { ChatMedia } from "./ChatMedia";
import { Button, DropDown } from "@/components";

const ChatSingleConversationUserInfo: FC<{
  community: GetUserCommunityBasedOnSlug;
  selectedChat: string | null;
  setSelectedChat: any;
  setSearchChatQuerry: any;
  searchChatQuerry: string;
  chatMedia: any[];
}> = ({
  community,
  selectedChat,
  setSelectedChat,
  setSearchChatQuerry,
  searchChatQuerry,
  chatMedia,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [currentReceiverId, setCurrentReceiverId] = useState<any>();
  const [deleteModalVal, setDeleteModalVal] = useState<string>("");
  const [messagingUser, setMessagingUser] = useState<CommunityMember | null>(
    null
  );
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [modalsState, setModalsState] = useState<{
    mediaModal: false;
    deleteModal: false;
    settingsModal: false;
  }>({
    mediaModal: false,
    deleteModal: false,
    settingsModal: false,
  });
  const openSearch = () => {
    setIsOpen(!isOpen);
  };

  // Close search in case its empty
  useEffect(() => {
    if (searchChatQuerry === "") {
      setIsOpen(false);
    }
  }, [searchChatQuerry]);

  // Handle deleting of chats | delete junction
  const handleDeleteChat = async () => {
    setDeleteLoading(true);
    firebase.database().ref("chat").child(selectedChat!).remove();
    firebase
      .database()
      .ref("chat_usersIdJunction")
      .child(`${selectedChat!}/${selectedChat}`)
      .remove();
    setDeleteLoading(false);
    setSelectedChat(null);
  };

  // const profile = useSelector<ReduxTypes, profileTypes>(
  //   (state) => state.userProfile.Profile
  // );

  // const community =
  //   useSelector<ReduxTypes, any>(
  //     (state) => state.profileCommunity.profileCommunity?.all?.users
  //   ) || [];

  // Set current reciever user || Needs to be changed for group chats!
  useEffect(() => {
    if (selectedChat?.split("_")[0] !== currentUser?.uid) {
      setCurrentReceiverId(selectedChat?.split("_")[0]);
    }

    if (selectedChat?.split("_")[1] !== currentUser?.uid) {
      setCurrentReceiverId(selectedChat?.split("_")[1]);
    }
  }, [selectedChat, currentUser]);

  // Update in case community array changes
  useEffect(() => {
    if (currentReceiverId && community) {
      const foundUser = community.all.users.find((user: CommunityMember) => {
        return user.uid === currentReceiverId;
      });
      if (foundUser) {
        setMessagingUser(foundUser);
      }
    }
  }, [currentReceiverId, community]);

  // Update states
  const updateModalState = (field: string, value: boolean) => {
    // reset input in case modal closes
    if (field === "deleteModal") {
      setDeleteModalVal("");
    }
    setModalsState((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <>
      {/* Media modal */}
      <BaseModalWindow
        show={modalsState.mediaModal}
        closeModal={() => {
          updateModalState("mediaModal", false);
        }}
        styles="chat__media-modal"
      >
        <ChatMedia chatMedia={chatMedia} />
      </BaseModalWindow>

      {/* Delete modal */}
      <BaseModalWindow
        show={modalsState.deleteModal}
        closeModal={() => {
          updateModalState("deleteModal", false);
        }}
        title="Delete chat"
        styles="chat__delete-modal"
      >
        <div>
          <span>
            Type `delete` to delete chat with {messagingUser?.userName}{" "}
          </span>
        </div>
        <div className="chat__delete-modal--actions">
          <input
            className="base-input"
            type="text"
            value={deleteModalVal}
            onChange={(e) => {
              setDeleteModalVal(e.target.value);
            }}
            placeholder="delete"
            name=""
            id=""
          />

          <Button
            variant="secondary"
            disabled={deleteLoading || deleteModalVal !== "delete"}
            onClickAction={() => {
              handleDeleteChat();
            }}
          >
            {!deleteLoading ? (
              "Delete"
            ) : (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </BaseModalWindow>

      {/* Top of chat bar/ user info + sttings */}
      <div className="chat-user">
        <div className="chat-user__info">
          <Link to={`/profile/${messagingUser?.slug}`}>
            <div className="chat-user__image">
              <BaseLogo
                logoSrc={messagingUser?.profileLogoUrl}
                isPlaceHolder={undefined}
                name={messagingUser?.userName || ""}
              />
            </div>

            <h5>{messagingUser?.userName}</h5>
          </Link>
        </div>
        <div className={`chat-user__search ${isOpen ? "open" : ""}`}>
          <div className="chat-user__options">
            {/* Chat search */}
            <div className="chat-user__search--button">
              <SearchIcon
                color="#818694"
                onClick={openSearch}
                style={{ cursor: "pointer" }}
                size={20}
              />
            </div>
            <input
              value={searchChatQuerry}
              onChange={(e) => {
                setSearchChatQuerry(e.target.value);
              }}
              className={`base-input ${isOpen ? "visible" : ""}`}
            />

            {/* Dropdown chat settings */}
            <div className="chat-user__settings">
              <DropDown logo={<Settings />}>
                <a
                  href=""
                  className="dropdown__option"
                  onClick={() => {
                    updateModalState("mediaModal", true);
                  }}
                >
                  {" "}
                  Media
                </a>
                <a
                  onClick={() => {
                    updateModalState("deleteModal", true);
                  }}
                  className="dropdown__option chat-user__settings--delete-btn"
                >
                  Delete chat
                </a>
              </DropDown>

              {/* <img src={<Settings />} alt="settings" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChatSingleConversationUserInfo);
