import { GeneralInputProps } from "@/models/props/TextInput";
import React, { InputHTMLAttributes } from "react";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & GeneralInputProps;
const TextInput: React.FunctionComponent<TextInputProps> = ({
  type = "text",
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  styleProps = "base-input",
  isDisabled,
  label,
  id,
}) => {
  return (
    <div className="text-input">
      {label ? <label>{label}</label> : ""}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={styleProps}
        disabled={isDisabled}
        id={id}
      />
    </div>
  );
};

export default TextInput;
