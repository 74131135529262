import React, { memo } from "react";
import "firebase/auth";
import "firebase/firestore";
import SideMenuUi from "./SideMenu.ui";

export const SideMenu: React.FunctionComponent<{ currentViewName: any }> = ({
  currentViewName,
}) => {
  return (
    <>
      <SideMenuUi currentViewName={currentViewName} />
    </>
  );
};
