export const schedule1337 = [
  // Sunday
  { startHour: 0, endHour: 1, day: "Thursday" },
  { startHour: 1, endHour: 2, day: "Thursday" },
  { startHour: 2, endHour: 3, day: "Thursday" },
  { startHour: 3, endHour: 4, day: "Thursday" },
  { startHour: 4, endHour: 5, day: "Thursday" },
  { startHour: 5, endHour: 6, day: "Thursday" },
  { startHour: 6, endHour: 7, day: "Thursday" },
  { startHour: 7, endHour: 8, day: "Thursday" },
  { startHour: 8, endHour: 9, day: "Thursday" },
  { startHour: 9, endHour: 10, day: "Thursday" },
  { startHour: 10, endHour: 11, day: "Thursday" },
  { startHour: 11, endHour: 12, day: "Thursday" },
  { startHour: 12, endHour: 13, day: "Thursday" },
  { startHour: 13, endHour: 14, day: "Thursday" },
  { startHour: 14, endHour: 15, day: "Thursday" },
  { startHour: 15, endHour: 16, day: "Thursday" },
  { startHour: 16, endHour: 17, day: "Thursday" },
  { startHour: 17, endHour: 18, day: "Thursday" },
  { startHour: 18, endHour: 19, day: "Thursday" },
  { startHour: 19, endHour: 20, day: "Thursday" },
  { startHour: 20, endHour: 21, day: "Thursday" },
  { startHour: 21, endHour: 22, day: "Thursday" },
  { startHour: 22, endHour: 23, day: "Thursday" },
  { startHour: 23, endHour: 24, day: "Thursday" },

  // Friday
  { startHour: 0, endHour: 1, day: "Friday" },
  { startHour: 1, endHour: 2, day: "Friday" },
  { startHour: 2, endHour: 3, day: "Friday" },
  { startHour: 3, endHour: 4, day: "Friday" },
  { startHour: 4, endHour: 5, day: "Friday" },
  { startHour: 5, endHour: 6, day: "Friday" },
  { startHour: 6, endHour: 7, day: "Friday" },
  { startHour: 7, endHour: 8, day: "Friday" },
  { startHour: 8, endHour: 9, day: "Friday" },
  { startHour: 9, endHour: 10, day: "Friday" },
  { startHour: 10, endHour: 11, day: "Friday" },
  { startHour: 11, endHour: 12, day: "Friday" },
  { startHour: 12, endHour: 13, day: "Friday" },
  { startHour: 13, endHour: 14, day: "Friday" },
  { startHour: 14, endHour: 15, day: "Friday" },
  { startHour: 15, endHour: 16, day: "Friday" },
  { startHour: 16, endHour: 17, day: "Friday" },
  { startHour: 17, endHour: 18, day: "Friday" },
  { startHour: 18, endHour: 19, day: "Friday" },
  { startHour: 19, endHour: 20, day: "Friday" },
  { startHour: 20, endHour: 21, day: "Friday" },
  { startHour: 21, endHour: 22, day: "Friday" },
  { startHour: 22, endHour: 23, day: "Friday" },
  { startHour: 23, endHour: 24, day: "Friday" },

  // Saturday
  { startHour: 0, endHour: 1, day: "Saturday" },
  { startHour: 1, endHour: 2, day: "Saturday" },
  { startHour: 2, endHour: 3, day: "Saturday" },
  { startHour: 3, endHour: 4, day: "Saturday" },
  { startHour: 4, endHour: 5, day: "Saturday" },
  { startHour: 5, endHour: 6, day: "Saturday" },
  { startHour: 6, endHour: 7, day: "Saturday" },
  { startHour: 7, endHour: 8, day: "Saturday" },
  { startHour: 8, endHour: 9, day: "Saturday" },
  { startHour: 9, endHour: 10, day: "Saturday" },
  { startHour: 10, endHour: 11, day: "Saturday" },
  { startHour: 11, endHour: 12, day: "Saturday" },
  { startHour: 12, endHour: 13, day: "Saturday" },
  { startHour: 13, endHour: 14, day: "Saturday" },
  { startHour: 14, endHour: 15, day: "Saturday" },
  { startHour: 15, endHour: 16, day: "Saturday" },
  { startHour: 16, endHour: 17, day: "Saturday" },
  { startHour: 17, endHour: 18, day: "Saturday" },
  { startHour: 18, endHour: 19, day: "Saturday" },
  { startHour: 19, endHour: 20, day: "Saturday" },
  { startHour: 20, endHour: 21, day: "Saturday" },
  { startHour: 21, endHour: 22, day: "Saturday" },
  { startHour: 22, endHour: 23, day: "Saturday" },
  { startHour: 23, endHour: 24, day: "Saturday" },

  // Sunday
  { startHour: 0, endHour: 1, day: "Sunday" },
  { startHour: 1, endHour: 2, day: "Sunday" },
  { startHour: 2, endHour: 3, day: "Sunday" },
  { startHour: 3, endHour: 4, day: "Sunday" },
  { startHour: 4, endHour: 5, day: "Sunday" },
  { startHour: 5, endHour: 6, day: "Sunday" },
  { startHour: 6, endHour: 7, day: "Sunday" },
  { startHour: 7, endHour: 8, day: "Sunday" },
  { startHour: 8, endHour: 9, day: "Sunday" },
  { startHour: 9, endHour: 10, day: "Sunday" },
  { startHour: 10, endHour: 11, day: "Sunday" },
  { startHour: 11, endHour: 12, day: "Sunday" },
  { startHour: 12, endHour: 13, day: "Sunday" },
  { startHour: 13, endHour: 14, day: "Sunday" },
  { startHour: 14, endHour: 15, day: "Sunday" },
  { startHour: 15, endHour: 16, day: "Sunday" },
  { startHour: 16, endHour: 17, day: "Sunday" },
  { startHour: 17, endHour: 18, day: "Sunday" },
  { startHour: 18, endHour: 19, day: "Sunday" },
  { startHour: 19, endHour: 20, day: "Sunday" },
  { startHour: 20, endHour: 21, day: "Sunday" },
  { startHour: 21, endHour: 22, day: "Sunday" },
  { startHour: 22, endHour: 23, day: "Sunday" },
  { startHour: 23, endHour: 24, day: "Sunday" },
];
