import { FC } from "react";
import { Button } from "./components";
import { useNavigate } from "react-router";
import { siteRoutes } from "../branchAndBrandSpecific/routes/siteRoutesList";

export const PageDoesntExist: FC<{}> = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="page_doenst-exist">
      <h1>This page doesnt exist</h1>
      <Button
        variant="primary"
        onClickAction={() => navigate(siteRoutes["home"])}
      >
        Back to home
      </Button>
    </div>
  );
};
