import Logo from "../assets/logitech/logitechg.svg";
import LogoSmall from "../assets/logitech/logitech-g-sm.svg";

export const Legion_brandVariables = {
  siteTitle: "Lenovo Legion",
  siteIcon: "",
  name: "Lenovo Legion",
  name_small: "Legion",
  logos: {
    large: Logo,
    white: Logo,
    small: Logo,
    medium: Logo,
    alt_text: "Legion logo",
    profile: LogoSmall,
  },
  brandTheme: "legion", // Theme of the brand
  site: "", // Live url of site
  authMethod: "email", // Only needed for events, gives user set options to manualy sign up, options are:  email | phone
  defaultCountryCode: "DK", // Only needed for retail platforms | For initial phone number | Changes all auth methods`
  defaultLanguage: "eng",
  brandStoreExtension: "", // Only needed for retail platforms | Extension used for email
  brandValue: "LEGION", // Only needed for retail platforms | Brand value needed for BE related functions
  brandIntials: "", // gets added after the username, to drasticaly descrease chance of duplicate usernames across different apps | Only needed for event platforms
  defaultNavigateUrl: "/",
  eventHasAutoQrSignup: false,
  hasSubClients: false,
  subClientsForOverviews: null, // string[] of clients used to fetch multiple types of clients for overview pages
  autoJoinChallenge: false,
  hasFeature: {
    notifications: true,
    themes: false,
    connections: false,
    achievements: false,
    quickSignUp: false,
    messages: false,
    teams: false,
    news: false,
    challenges: true,
    singleChallenge: true,
    gathers: false,
    preferences: false,
    friends: true,
    zones: false,
    feed: true,
    winners: false,
    qrSignUp: true,
    manualSubmissions: true,
    landing: true,
    login: true,
    haslanguagePicker: true,
    getstarted: false,
    hero: true,
    search: false,
    routes: false,
    dailytasks: true,
    games: false,
    treasure_hunt: false,
    activities: false,
  },
  hasAuth: {
    steam: false,
    bnet: false,
    quickSignUp: true,
    google: true,
  },
  layout: {
    challenge: "default",
  },
  event: {
    forcedCredentialPopup: "email",
    eventCode: "", // Only needed for event platforms
    collectionForSubmissions: "",
    documentForSubmissions: "",
    isEvent: true,
    spacecoins_redirect: true,
  },
};
