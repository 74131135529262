import React from "react";
import { Badge } from "@/components";
const ChallengeCardAltLoader: React.FC = () => {
    return (
        <div className="challengesAlt__item">
                <div className="challengeAlt placeholder">
                    <div className="challengeAlt__image">
                        <div className="aspect-ratio">
                            <div className={`image image--3x4`}>
                                <div className="image__content wave" />
                            </div>
                        </div>

                        <div className="challengeAlt__game">
                            <div className="badge-wrapper">
                                <Badge variant="secondary">...</Badge>
                            </div>
                        </div>
                    </div>
                    <div className="challengeAlt__body">
                        <div className="challengeAlt__content">

                            <div className="user">
                                <div className="user__avatar">
                                    <div className="avatar">
                                        <div className="avatar__content wave" />
                                    </div>
                                </div>

                            </div>
                                <div className="challengeAlt__details">
                                    <div className="challengeAlt__title wave" />
                                    <div className="challengeAlt__author wave" />
                                </div>

                        </div>
                        <div className="challengeAlt__progress">
                            <label />
                            <progress value="10" max="100" className="challenges_overview">
                            10%
                            </progress>
                        </div>
                    </div>
                </div>
        </div>
    )
};

export default ChallengeCardAltLoader;