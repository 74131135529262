import React, { FC, useState } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SingleTeamOverviewCardProps } from "../../types/TeamTypes";
import { Image } from "@/components";
import { leaveTeam } from "../../helperFunctions/leaveTeam";
import { createToast } from "@/utils/toaster/createToast";
import { SingleTeamActionsButtons } from "./SingleTeamActionButtons";
import { SingleTeamStatusButton } from "./SingleTeamStatusButton";
import { SingleTeamDropdownOptions } from "./SingleTeamDropdownOptions";
import SingleTeamLeaveModal from "../modals/SingleTeamLeaveModal";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export const SingleTeamGeneralInfo: FC<SingleTeamOverviewCardProps> = ({
  teamData,
  isPreview,
  setUpdateTrigger,
  userRole,
  setSettingsOpen,
  membersData,
}): JSX.Element => {
  const [addMemberModalOpen, setAddMemberModalOpen] = useState<boolean>(false);
  const [leaveTeamModalopen, setLeaveTeamModalOpen] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const leaveTeamCheck = async () => {
    if (userRole === "owner") {
      return setLeaveTeamModalOpen(true);
    }

    await leaveTeam(
      "userTeamJunction",
      currentUser.uid!,
      teamData.slug,
      teamData.name,
      false
    )
      .then(() => {
        setUpdateTrigger((prev: number) => prev + 1);
      })
      .then(() => {
        createToast({
          type: "info",
          message: `You left team \`${teamData.name}\``,
        });
        navigate(siteRoutes["teams"]);
      });
    return null;
  };

  // Variables
  const teamType = teamData.type;
  const canSeeButtons = teamData.requestedToJoin === undefined && !isPreview;

  useDocumentTitle(`${teamData.name} - Team`);

  return (
    <>
      {leaveTeamModalopen && (
        <SingleTeamLeaveModal
          setLeaveTeamModalOpen={setLeaveTeamModalOpen}
          setUpdateTrigger={setUpdateTrigger}
          userRole={userRole}
          teamId={teamData.slug}
          leaveTeamModalOpen={leaveTeamModalopen}
          teamName={teamData.name}
        />
      )}

      <div className="card">
        <div className="card__image">
          <div className="image">
            <div className="image__content">
              <Image
                imageSrc={teamData.teamLogo}
                alt={`${teamData.name} image`}
                imgIxProps={[
                  {
                    w: "220",
                    h: "220",
                  },
                ]}
              />
            </div>
            <div className="card__type" title="CS2">
              <span className="card__type--text">{teamType}</span>
            </div>
          </div>

          {/* Action buttons / Invite, favourite, share */}
          {canSeeButtons && (
            <SingleTeamActionsButtons
              isPreview={isPreview}
              teamData={teamData}
              membersData={membersData}
            />
          )}
        </div>

        {/* Dropdown options: TODO */}
        {!isPreview && (
          <SingleTeamDropdownOptions
            teamData={teamData}
            userRole={userRole}
            leaveTeamCheck={() => leaveTeamCheck()}
            setSettingsOpen={setSettingsOpen}
            hasMembers={membersData?.length > 1}
          />
        )}

        {/* Games list */}
        <div className="card__info">
          <h1 title={teamData.name}>{teamData.name}</h1>
          <p>{teamData.description}</p>
          <ul className="card__tags">
            {teamData.games
              ? teamData.games.map((field) => {
                  return <li key={field}>{field}</li>;
                })
              : Array.from(Array(2), (_, index) => {
                  return (
                    <li
                      key={`placeholder_${index}`}
                      className="placeholder-wave placeholder"
                    />
                  );
                })}
          </ul>

          {/* team related actions/ follow, join, status */}
          <div className="card__actions">
            <SingleTeamStatusButton
              teamData={teamData}
              userRole={userRole}
              setUpdateTrigger={setUpdateTrigger}
            />
          </div>
        </div>
      </div>
    </>
  );
};
