import FeedFetcher from "../components/Feed.fetcher";
import SuggestedFriends from "../components/SuggestedFriends/SuggestedFriends";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useEffect } from "react";

export default function FeedPage() {
  // Page Document Title
  useDocumentTitle("Feed");

  const navigate = useNavigate();

  useEffect(() => {
    if (!brand.hasFeature.feed) {
      return navigate(siteRoutes["home"]);
    }
  }, [brand]);

  return (
    <div className="feed__grid">
      <div>
        <FeedFetcher />
      </div>
      <div className="feed__suggestions">
        <div className="feed__friends">
          <SuggestedFriends />
        </div>
      </div>
    </div>
  );
}
