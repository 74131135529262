import { Button } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export const SingleChallenge404 = ({ navigate }: any) => {

  // Page Document Title
  useDocumentTitle(`404 - Challenge not found`);
  
  return (
    <div>
      <p>
        This challenge has most likely been removed or the creator is no longer
        active
      </p>
      <Button
        variant="primary"
        type="button"
        onClickAction={() => navigate(siteRoutes["challenges"])}
      >
        Back to challenges
      </Button>
    </div>
  );
};
