import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import {
  ChallengesItemsAtoms,
  OngoingChallengesAtoms,
  lastFinishedDocAtom,
  lastOngoingDocAtom,
} from "../utils/ChallengeItemAtom";
import { ChallengeItem } from "../types/props/ChallengeItemProps";
import { firestore } from "@/api/firebase/firebase.config";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import firebase from "firebase";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
interface ReturnValues {
  challengeData: {
    ongoing: ChallengeItem[];
    finished: ChallengeItem[];
  };
  isLoading?: boolean;
  loadMoreChallenges: () => void;
  isEmpty: {
    ongoing: boolean;
    finished: boolean;
  };
}

export function useChallenges(challengeStatus: string): ReturnValues {
  const [challengesItems, setChallengesItems] = useAtom(ChallengesItemsAtoms);
  const [challengesOngoing, setChallengesOngoing] = useAtom(
    OngoingChallengesAtoms
  );
  const [lastOngoingDoc, setLastOngoingDoc] = useAtom(lastOngoingDocAtom);
  const [lastFinishedDoc, setLastFinishedDoc] = useAtom(lastFinishedDocAtom);
  const [isQueryRunning, setIsQueryRunning] = useState(false);

  const [isOngoingEmpty, setIsOngoingEmpty] = useState(false);
  const [isFinishedEmpty, setIsFinishedEmpty] = useState(false);

  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const status = challengeStatus;
  const prevStatusRef = useRef<string>();
  const challengeData = {
    ongoing: challengesOngoing,
    finished: challengesItems,
  };
  const isEmpty = {
    ongoing: isOngoingEmpty,
    finished: isFinishedEmpty,
  };

  const loadMoreChallenges = () => {
    if (!isQueryRunning) {
      setLoadMore(true);
    }
  };

  const [brands, setBrands] = useState([brand.brandValue]);

  let brandArray = [];
  if (brand.subClientsForOverviews) {
    brandArray = [brand.brandValue, ...brand.subClientsForOverviews];
  }

  useEffect(() => {
    if (brandArray.length > 0) {
      setBrands(brandArray);
    } else {
      setBrands([brand.brandValue]);
    }
  }, []);

  const fetchChallenges = (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>
  ) => {
    setIsQueryRunning(true);
    const statusChanged = prevStatusRef.current !== status;
    prevStatusRef.current = status;

    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const challengesArray: ChallengeItem[] = [];
        snapshot.forEach((doc) => {
          const existingDoc = challengesItems.find(
            (item) => item.id === doc.id
          );
          if (!existingDoc) {
            challengesArray.push({
              id: doc.id,
              ...doc.data(),
            });
            if (status === "ongoing") {
              // @ts-ignore
              setLastOngoingDoc(snapshot.docs[snapshot.docs.length - 1]);
            }

            if (status === "finished") {
              // @ts-ignore
              setLastFinishedDoc(snapshot.docs[snapshot.docs.length - 1]);
            }
          }
        });

        if (status === "ongoing") {
          setIsOngoingEmpty(snapshot.empty);
          if (statusChanged && challengesArray.length > 0) {
            setChallengesOngoing(challengesArray);
          } else {
            setChallengesOngoing((prevItems) => [
              ...prevItems,
              ...challengesArray,
            ]);
          }
        }

        if (status === "finished") {
          setIsFinishedEmpty(snapshot.empty);
          if (statusChanged && challengesArray.length > 0) {
            setChallengesItems(challengesArray);
          } else {
            setChallengesItems((prevItems) => [
              ...prevItems,
              ...challengesArray,
            ]);
          }
        }

        setIsLoading(false);
        setLoadMore(false);
        setIsQueryRunning(false);
      },
      (e) => {
        console.log("Error with fetching", e);
        setIsLoading(false);
        setLoadMore(false);
        setIsQueryRunning(false);
      }
    );

    return () => unsubscribe();
  };

  useEffect(() => {
    if (!isQueryRunning) {
      const query = collectionRef.challenge
        .where("status", "==", status)
        .where("client", "in", brands)
        .orderBy("startDate", "desc")
        .limit(10);
      if (loadMore && status === "ongoing") {
        query.startAfter(lastOngoingDoc);
      }
      if (loadMore && status === "finished") {
        query.startAfter(lastFinishedDoc);
      }
      fetchChallenges(query);
    }
  }, [status]);

  useEffect(() => {
    if (loadMore && !isQueryRunning) {
      const query = collectionRef.challenge
        .where("status", "==", status)
        .where("client", "in", brands)
        .orderBy("startDate", "desc")
        .limit(10)
        .startAfter(status === "ongoing" ? lastOngoingDoc : lastFinishedDoc);
      fetchChallenges(query);
    }
  }, [loadMore]);

  return {
    challengeData,
    isLoading,
    loadMoreChallenges,
    isEmpty,
  };
}
