import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";
import {
  featuredNewsAtom,
  newsOverviewAtom,
} from "@/utils/atoms/newsAtoms/newsAtoms";
import { getBrandVariables } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import BrandProps from "@branchAndBrandSpecific/utils/brandVariables/brands/BrandProps";

export const useNewsData = ({
  client,
  limit,
}: {
  client: string;
  limit: number;
}) => {
  const [newsData, setNewsData] = useAtom(newsOverviewAtom);
  const [featuredNews, setFeaturedNews] = useAtom(featuredNewsAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFeaturedLoading, setIsFeaturedLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastVisibleAmount, setLastVisibleAmount] = useState<number>(0);

  const newsQueryInit = collectionRef.news.where("client", "==", client);
  const featuredNewsInit = collectionRef.news
    .where("client", "==", client)
    .where("isFeatured", "==", true)
    .get();

  let authors: Record<string, any> = {};

  const getAuthor = async (uid: string) => {
    if (authors[uid]) {
      return authors[uid];
    }

    const response = await getAuthorProfileBasedOnUid(uid, client);

    authors[uid] = response;

    if (response) {
      return response;
    }
  };

  const fetchNews = async () => {
    setIsLoading(true);

    const newsQuery = await newsQueryInit.limit(20).get();
    const newsResponse = await Promise.all(
      newsQuery.docs.map(async (doc) => {
        const author = getBrandVariables(
          doc.data().client
        ) as unknown as BrandProps;

        if (author) {
          return {
            ...doc.data(),
            author: {
              fullName: "",
              profileLogoUrl: author.logos.small,
              slug: "",
              uid: "",
              userName: author.name,
            },
          };
        } else {
          return doc.data();
        }
      })
    );

    setLastVisibleAmount(lastVisibleAmount + newsResponse.length);

    if (newsResponse.length < limit) {
      setHasMore(false);
    }
    if (newsResponse) {
      setNewsData(newsResponse);
    }
    setIsLoading(false);
  };

  const fetchMoreNews = async () => {
    const newsQuery = await newsQueryInit
      .startAfter(lastVisibleAmount)
      .limit(20)
      .get();

    const newsResponse = await Promise.all(
      newsQuery.docs.map(async (doc) => {
        const author = getBrandVariables(
          doc.data().client
        ) as unknown as BrandProps;

        if (author) {
          return {
            ...doc.data(),
            author: {
              fullName: "",
              profileLogoUrl: author.logos.small,
              slug: "",
              uid: "",
              userName: author.name,
            },
          };
        } else {
          return doc.data();
        }
      })
    );

    setLastVisibleAmount(lastVisibleAmount + newsResponse.length);

    if (newsResponse.length < limit) {
      setHasMore(false);
    }
    if (newsResponse) {
      setNewsData((prevData) => [...prevData, ...newsResponse]);
    }
  };

  const fetchFeaturedNews = async () => {
    const featuredNewsQueryInit = await featuredNewsInit;
    const featuredNewsResponse = await Promise.all(
      featuredNewsQueryInit.docs.map(async (doc) => {
        const author = getBrandVariables(
          doc.data().client
        ) as unknown as BrandProps;
        if (author) {
          return {
            ...doc.data(),
            author: {
              fullName: "",
              profileLogoUrl: author.logos.small,
              slug: "",
              uid: "",
              userName: author.name,
            },
          };
        } else {
          return doc.data();
        }
      })
    );

    setFeaturedNews(featuredNewsResponse);
    setIsFeaturedLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, [client]);

  useEffect(() => {
    fetchFeaturedNews();
  }, [client]);

  const fetchNewData = () => {
    fetchNews();
  };

  return {
    allNews: {
      data: newsData,
      loading: isLoading,
      fetchNewData,
      fetchMoreNews,
      hasMore,
    },
    featuredNews: {
      data: featuredNews,
      loading: isFeaturedLoading,
    },
  };
};
