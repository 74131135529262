import React, { useEffect, FC, useState } from "react";
import { commentsResponse } from "../../types/commentsTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { SinglePostSingleComment } from "./SinglePostSingleComment";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Query_shared } from "@/api/shared";
import CreateCommentForm from "./SinglePostCreateCommentForm";
import { ErrorFallback, Spinner } from "@/components";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { useAuthorProfile } from "@/store/profiles/authorProfile.store";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";

export const SinglePostComments: FC<{
  showComments: boolean;
  postId: string;
  postAuthorUid: string;
  createCommentEnabled?: boolean;
  postDoesNotHaveComments?: boolean;
  isAuthorized?: boolean;
}> = ({
  showComments,
  postId,
  postAuthorUid,
  createCommentEnabled,
  postDoesNotHaveComments,
  isAuthorized,
}): JSX.Element => {
  const [commentToRender, setCommentToRender] = useState<
    commentsResponse[] | undefined
  >(undefined);
  const getAuthorProfiles = useAuthorProfile((s) => s.getProfile);
  const setAuthorProfiles = useAuthorProfile((s) => s.setProfile);

  const commentRef = collectionRef.comments(postId).orderBy("created");
  useEffect(() => {
    commentRef.onSnapshot(async (snapshot) => {
      const commentDocs = snapshot.docs;
      const commentsArray: commentsResponse[] = [];

      for (const comment of commentDocs) {
        const commentData = comment.data();

        const authorProfile = getAuthorProfiles(commentData.authorUid);
        if (authorProfile) {
          Object.assign(commentData, {
            authorSlug: authorProfile.slug,
            authorUsername: authorProfile.userName,
            authorLogo: authorProfile.profileLogoUrl,
            totalLikesNumber: 0,
            hasCurrentUserLikedThisComment: false,
            id: comment.id,
          });
        } else {
          const searchUser = await getAuthorProfileBasedOnUid(
            commentData.authorUid,
            "WOG"
          );

          setAuthorProfiles({
            slug: searchUser.slug,
            profileLogoUrl: searchUser.profileLogoUrl,
            uid: searchUser.uid,
            userName: searchUser.userName,
            fullName: searchUser.userName,
          });

          Object.assign(commentData, {
            authorSlug: searchUser.slug,
            authorLogo: searchUser.profileLogoUrl,
            authorUsername: searchUser.userName,
          });
        }
        commentsArray.push(commentData as unknown as commentsResponse);
      }
      setCommentToRender(commentsArray);
    });
  }, [postId]);
  const commentState =
    createCommentEnabled ||
    (!createCommentEnabled && commentToRender && commentToRender.length !== 0);
  try {
    return (
      <>
        {" "}
        {showComments ? (
          <div className="comment">
            <ul className="comment__list">
              {commentState &&
                commentToRender &&
                commentToRender.length > 0 &&
                commentToRender.map((comment) => {
                  try {
                    return (
                      <SinglePostSingleComment
                        comment={comment}
                        postId={postId}
                        setComments={undefined}
                        whenWasPosted={{
                          stringDate: new Date(
                            comment.created.seconds * 1000
                          ).toDateString(),
                          howLongAgo: Query_shared.getWhenWasPosted(
                            comment.created.seconds * 1000
                          ),
                        }}
                        key={comment.id}
                        isAuthorized={isAuthorized}
                      />
                    );
                  } catch (error) {
                    {
                      error && (
                        <>
                          <ErrorFallback>Comment failed to load</ErrorFallback>
                        </>
                      );
                    }
                  }
                })}
            </ul>

            {/* {loading && <Spinner />} */}
            {createCommentEnabled && (
              <CreateCommentForm
                postId={postId}
                postAuthorUid={postAuthorUid}
                fetchComments={() => null}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  } catch (error) {
    {
      {
        error && <ErrorFallback>Failed to fetch comments</ErrorFallback>;
      }
    }
  }
};
