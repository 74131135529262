import { brand } from "../../utils/brandVariables/brandVariables";
import React from "react";
import "../../../src/utils/globalStyles/legal.scss";
import { Link } from "react-router-dom";

export const TermsOfUse: React.FunctionComponent = () => {
  return (
    <main>
      <div className="container">
        <div className="">
          <Link to="/" className="btn btn-primary">
            Go home
          </Link>
        </div>
        <article className="legal">
          <header>
            <h1>Terms and Conditions (&ldquo;Terms&rdquo;)</h1>
            <p>Last updated: November 22, 2022</p>
          </header>
          <section>
            <h2>1. Introduction</h2>
            <p>
              Please read these Terms and Conditions (&ldquo;Terms&rdquo;,
              &ldquo;Terms and Conditions&rdquo;) carefully before using the{" "}
              {brand.name} website (the &ldquo;Service&rdquo;) operated by iPlay
              ApS (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;).
            </p>
            <p>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </p>
          </section>
          <section>
            <h2>2. Accounts</h2>
            <p>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>
            <p>
              Other than content you own, which you may have opted to include on
              this Website, under these Terms, iPlay ApS and/or its licensors
              own all rights to the intellectual property and material contained
              in this Website, and all such rights are reserved. You are granted
              a limited license only, subject to the restrictions provided in
              these Terms, for purposes of viewing the material contained on
              this Website.
            </p>
          </section>
          <section>
            <h2>3. Links to Other Web Sites</h2>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by iPlay ApS.
            </p>
            <p>
              iPlay ApS has no control over, and assumes no responsibility for,
              the content, privacy policies, or practices of any third party web
              sites or services. You further acknowledge and agree that iPlay
              ApS shall not be responsible or liable, directly or indirectly,
              for any damage or loss caused or alleged to be caused by or in
              connection with use of or reliance on any such content, goods or
              services available on or through any such web sites or services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>
          </section>
          <section>
            <h2>4. Termination</h2>
            <p>
              We may terminate or suspend access to our Service immediately,
              without prior notice or liability, for any reason whatsoever,
              including without limitation if you breach the Terms.
            </p>
            <p>
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </p>
            <p>
              We may terminate or suspend your account immediately, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if you breach the Terms.
            </p>
            <p>
              Upon termination, your right to use the Service will immediately
              cease. If you wish to terminate your account, you may simply
              discontinue using the Service.
            </p>
            <p>
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </p>
          </section>
          <section>
            <h2>5. Limitation of liability</h2>
            <p>
              In no event shall iPlay ApS, nor any of its officers, directors
              and employees, be liable to you for anything arising out of or in
              any way connected with your use of this Website, whether such
              liability is under contract, tort or otherwise, and iPlay ApS,
              including its officers, directors and employees shall not be
              liable for any indirect, consequential or special liability
              arising out of or in any way related to your use of this Website.
            </p>
          </section>
          <section>
            <h2>6. Governing Law</h2>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of Denmark, without regard to its conflict of law provisions.
            </p>
            <p>
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>
          </section>
          <section>
            <h2>7. Changes</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time.
            </p>
            <p>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </p>
          </section>
          <section>
            <h2>8. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us.
            </p>
          </section>
        </article>
      </div>
    </main>
  );
};
