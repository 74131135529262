import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { Profile } from "@/models/database/profiles/profile.model";
import BaseUserAvatar from "@/components/BaseUserAvatar";

const ProfileOnlineMembers: FC<{
  isLoading: boolean;
  community: any;
  error?: string;
}> = (props): JSX.Element => {
  const [isLoading, setIsloading] = useState<boolean>(props.isLoading);

  useEffect(() => {
    setIsloading(props.isLoading);
  }, [props.isLoading]);

  // Variables
  const hasNoFriends = props.community?.friends?.users.length === 0;

  // if there is an error
  if (props.error) {
    return <span>{props.error}</span>;
  }

  // if the friends are being loaded
  if (isLoading) {
    return (
      <div className="friends">
        <ul className="friends__list">
          {Array.from(Array(5), (_, ind) => {
            return (
              <li key={ind} className="friends__item">
                <div className="friends__avatar">
                  <div className="avatar avatar__placeholder">
                    <div className="avatar__content">
                      <div className="avatar__letter" />
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  // if the user has no friends
  if (hasNoFriends) {
    return (
      <div className="friends">
        <ul className="friends__list">
          <li className="friends__item">
            {i18next.t("profile.friends.no_friends_msg")}
          </li>
        </ul>
      </div>
    );
  }

  // Any other case where the user has friends
  return (
    <div className="friends">
      <ul className="friends__list">
        {props.community?.friends?.users?.map((friend: Profile) => {
          return (
            <li className="friends__item" key={friend.uid}>
              <Link to={`/profile/${friend.slug}`}>
                <div className="friends__avatar">
                  <BaseUserAvatar
                    logoUrl={friend?.profileLogoUrl}
                    userName={friend?.userName}
                    title={friend?.userName}
                  />
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileOnlineMembers;
