import { useState, useEffect } from "react";
import { atom, useAtom } from "jotai";
import { collectionDoc } from "@/api/collectionRef/collectionOperations";

export const singleChallengeAtom = atom(new Map<string, any>());

const useSingleChallenge = (uid: string) => {
  const [challenges, setChallenges] = useAtom(singleChallengeAtom);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        if (challenges.has(uid)) {
          return challenges.get(uid);
        }

        const doc = await collectionDoc.challenge(encodeURI(uid));

        if (doc.exists) {
          const challenge = doc.data();
          setChallenges(
            (prevchallenges) => new Map(prevchallenges.set(uid, challenge))
          );
          return challenge;
        } else {
          setError("No such document!");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenge();
  }, [uid, challenges, setChallenges]);

  const challenge = challenges.get(uid);

  return { challenge, loading, error };
};

export default useSingleChallenge;
