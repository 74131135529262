import React, { useEffect, useState } from "react";
import { ListByDay } from "../../components/ListByDay/ListByDay";
import { winnerData } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import firebase from "firebase/app";
import { ListByDayMobile } from "../../components/ListByDay/ListByDayMobile";
import { FormattedListType } from "../../components/ListByDay/types/winnersTypes";
import { fullDays } from "@/utils/constants/weekDays";

export const Winners = () => {
  const [listDataArray, setListDataArray] = useState<FormattedListType[]>();
  const [availableHours, setAvailableHours] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const days = Object.values(fullDays);

  useEffect(() => {
    const getWinners = async () => {
      let availableHours: any = [];

      const winnersData = await firebase
        .firestore()
        .collection(winnerData.collection)
        .doc(winnerData.winnersDocName)
        .collection(winnerData.subCollection)
        .orderBy(winnerData.orderByType)
        .get();

      const formattedData = winnersData.docs.map((winner) => {
        const startHour = new Date(
          (winner.data().challengeStartDate as any).seconds * 1000
        ).getHours();

        const day = new Date(
          (winner.data().challengeStartDate as any).seconds * 1000
        ).getDay();

        if (!availableHours.includes(startHour)) {
          availableHours.push(startHour);
        }
        return {
          author: {
            userProfileLogoUrl: winner.data().userProfileLogoUrl,
            userName: winner.data().userName,
            userSlug: winner.data().userSlug,
            userFullName: winner.data().userFullName,
            userPhoneNumber: winner.data().userPhoneNumber,
          },
          listEventData: {
            name: winner.data().challengeName,
            startDate: winner.data().challengeStartDate,
            slug: winner.data().challengeSlug,
          },
          startHour: startHour,
          day: days[day],
        } as FormattedListType;
      });
      setListDataArray(formattedData);
      setAvailableHours(availableHours);
    };

    getWinners();
  }, []);

  const [isMobile, setIsMobile] = useState<boolean>();

  const handleResize = () => {
    if (window.innerWidth < 1400) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  if (!winnerData) {
    return (
      <>
        <p>Missing data</p>
      </>
    );
  }
  return (
    <section className="list-by-day">
      <h1>{winnerData.header}</h1>
      <p>{winnerData.subHeader}</p>
      {!isMobile && (
        <ListByDay
          buttonNames={days}
          loading={loading}
          error={error}
          data={listDataArray}
          availableHours={availableHours}
        />
      )}

      {isMobile && (
        <ListByDayMobile
          buttonNames={days}
          loading={loading}
          error={error}
          data={listDataArray}
          availableHours={availableHours}
        />
      )}
    </section>
  );
};
