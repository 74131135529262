import { partnerRoute } from "../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/partnerRoutes";

export const findPartner = (partner: string) => {
    for (const key in partnerRoute) {
        if (partnerRoute.hasOwnProperty(key)) {
            const partnerObject = partnerRoute[key];
            
            if (partnerObject.partner === partner) {
                return partnerObject;
            }
        }
    }

    return null;
}