const mockUsernamesAddons = [
  "Craze",
  "Racer",
  "Zapper",
  "Pirate",
  "Mage",
  "Ninja",
  "Tiger",
  "Byte",
  "Quest",
  "Rebel",
  "Crafter",
  "Trooper",
  "Legend",
  "Paladin",
  "Flyer",
  "Storm",
  "Sonic",
  "Smasher",
  "Gamer",
  "Samurai",
  "Quasar",
  "Prowler",
  "Nebula",
  "Rover",
  "Dynamo",
  "Zephyr",
  "Masher",
  "Blaster",
  "Ranger",
  "Cobra",
  "Pioneer",
  "Nomad",
  "Quake",
  "Shifter",
  "Muncher",
  "Blast",
  "Cyclone",
  "Punisher",
  "Noodle",
  "Runner",
  "Mauler",
  "Quick",
  "Strider",
  "Sizzle",
  "Predator",
  "Nudge",
  "Hero",
  "Warrior",
  "Boss",
  "Minion",
  "Assassin",
  "Thief",
  "Vandal",
  "Zombie",
  "Dragon",
];

export default mockUsernamesAddons;
