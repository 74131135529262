import { _validateIfEmailAlreadyExistInDB } from "./_validateIfEmailAlreadyExistInDB";
import { _getUserBasedOnUid } from "./_getUserBasedOnUid";
import { _updateUserCommunityStatus } from "./_updateUserCommunityStatus";

export default class QueryDB_Profiles {
  constructor() {}

  static async getUserBasedOnUid(uid: string) {
    return await _getUserBasedOnUid(uid);
  }
  static async validateIfEmailExists(email: string) {
    return await _validateIfEmailAlreadyExistInDB(email);
  }
  static updateUserCommunityStatus = _updateUserCommunityStatus;
}
