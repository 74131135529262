import firebase from "firebase/app";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const handlePrizeCollected = (currentUser) => {
  const dbRef = firebase.database();

  dbRef
    .ref(`treasure_hunt/${brand.brandValue}/testing/users/${currentUser.uid}`)
    .update({
      hasCollectedPrize: true,
    });
};

export const hasPrizeBeenCollected = (currentUser) => {
  const dbRef = firebase.database();
  let hasBeenCollected = false;
  dbRef
    .ref(
      `treasure_hunt/${brand.brandValue}/testing/users/${currentUser.uid}/hasCollectedPrize`
    )
    .once("value", async (snapshot) => {
      if (snapshot.val() && snapshot.val() === true) {
        return (hasBeenCollected = true);
      } else {
        return (hasBeenCollected = false);
      }
    });

  return hasBeenCollected;
};
