import { FC, useState } from "react";
import DataView from "@/components/DateView/DataView";
import SingleGatherActions from "./SingleGatherActions";
import { SingleGatherProps } from "../../types/cloudFunctions/SingleGatherTypes";
import SingleGatherGeneralinfo from "./SingleGatherGeneralinfo";
import SingleGatherParticipatingInterested from "./SingleGatherParticipatingInterested";
import { SingleGatherDiscussions } from "./SingleGatherDiscussions.fetcher";
import SingleGatherLocationCreator from "./SingleGatherLocationCreator";
import { ReactComponent as PlusIcon } from "../../../../assets/actions/plus.svg";

const SingleGatherComponent: FC<SingleGatherProps> = (props): JSX.Element => {
  const [openPostField, setOpenPostField] = useState<boolean>(false);
  return (
    <div className="gather Single-gather">
      <div className="Single-gather__image">
        <SingleGatherActions
          logoUrl={props.logoUrl}
          gatherSlug={props.slug}
          name={props.name}
          isFavoriteByCurrentUser={props.isFavoriteByCurrentUser}
          type={props.type}
          isRequestPending={props.isRequestPending}
        />
      </div>
      <div className="Single-gather__general-info">
        <SingleGatherGeneralinfo
          name={props.name}
          type={props.type}
          meetType={props.meetType}
          slug={props.slug}
          isCurrentUserParticipant={props.isCurrentUserParticipant}
          games={props.games}
          currentUserGeneralRole={props.currentUserGeneralRole}
          description={props.description}
          isRequestPending={props.isRequestPending}
          isCurrentUserInterested={props.isCurrentUserInterested}
        />
      </div>

      <div className="Single-gather__date">
        {props.startDate && (
          <div className=" gather__date--container gather__date--container-bg">
            <DataView
              isAllDay={props.isAllDay}
              startDate={props.startDate!}
              endDate={props.endDate}
            />
          </div>
        )}
      </div>
      <div className="Single-gather__participants">
        <div className="gather__participants gather__users--container-sm ">
          <SingleGatherParticipatingInterested
            profiles={props.Profiles?.participantProfiles}
            otherCount={props.Profiles?.otherParticipantCount || 0}
            title="Participants"
            gatherSlug={props.slug}
            gatherName={props.name}
            currentUserGeneralRole={props.currentUserGeneralRole}
          />

          <div className="gather__participants--divider" />
          <SingleGatherParticipatingInterested
            profiles={props.Profiles.interestedProfiles}
            otherCount={props.Profiles?.otherInterestedInCount || 0}
            title="Interested"
            gatherName={props.name}
            gatherSlug={props.slug}
            currentUserGeneralRole={props.currentUserGeneralRole}
          />
        </div>
      </div>

      {props.type === "private" && !props.startDate ? (
        <></>
      ) : (
        <>
          <div className="Single-gather__discussion">
            <h3>
              Discussion{" "}
              <button>
                <PlusIcon onClick={() => setOpenPostField(true)} />
              </button>
            </h3>

            <SingleGatherDiscussions
              data={props}
              openPostField={openPostField}
              setOpenPostField={setOpenPostField}
            />
          </div>
          <div className="Single-gather__location-creator">
            <SingleGatherLocationCreator
              isPlaceholder={props.isPlaceholder}
              gatherSlug={props.gatherId}
              meetType={props.meetType}
              meetTypeSecondary={props.meetTypeSecondary}
              meetUrlAddress={props.meetUrlOrAddress}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SingleGatherComponent;
