import QueryDB from "@/api/queryDB/common";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { userJunctionHandler } from "@/features/team/helperFunctions/userJunctionHandler";
import { Profile } from "@/models/database/profiles/profile.model";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";

export const handleInviteMembers = async (
  membersToAdd: CommunityUser[],
  currentUserProfile: Profile,
  slug: string,
  notificationType: string, // Adjust the type as needed
  setAddMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  featureName: string
) => {
  if (
    membersToAdd.length < 1 ||
    !currentUserProfile.uid ||
    !currentUserProfile.slug
  ) {
    return;
  }

  const invitePromises = membersToAdd.map(async (uid) => {
    if (notificationType === "teams-requests") {
      QueryDB.createUserProfileJunction(
        "userTeamJunction",
        `${uid.uid}_${slug}`,
        {
          uid: uid.uid,
          teamId: slug,
          generalRole: "random",
          monthlyPoints: 0,
          status: "invited",
          pending: true,
          joinedTeamTimestamp: null,
          notificationSettings: {
            memberRequests: false,
            newMembers: false,
            newPostsInFeed: false,
            newComntsInFeed: false,
            upcomingEvents: false,
          },
          invitedBy: {
            invitedByUid: currentUserProfile.uid,
            invitedByUsername: currentUserProfile.userName,
            invitedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          isFavorite: true,
          isPlayerOfTheMonth: false,
          inTeamRole: null,
        }
      ).then(() => {
        QueryDB_Notifications.createNewNotification(
          currentUserProfile.uid,
          uid.uid,
          slug,
          notificationType,
          `${notificationsTextMapping[notificationType]} <strong>${featureName}</strong>`
        );
      });
    }
  });

  const hasOnly1User = membersToAdd.length === 1;
  try {
    await Promise.all(invitePromises)
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to send invite to 1 or more users",
        });
      })
      .then(() => {
        createToast({
          type: "success",
          message: `Sent invite${hasOnly1User ? "" : "s"} to ${
            hasOnly1User
              ? membersToAdd[0].userName
              : `${membersToAdd.length} users`
          } to join team ${featureName}`,
        });
      });
    setAddMemberModalOpen(false);
  } catch (error) {
    // Handle any errors that occurred during the invitations.
    console.error("Error sending invitations:", error);
  }
};
