import HeroImage from "../assets/tcl_hero.jpg";

export const TCL_heroVariables = {
  image: {
    src: HeroImage,
    alt: "Midjourney AI art for Counter-Strike 2",
    title: "Support for CS2 added",
  },
  heading: "TCL QD-Mini LED 4K TV",
  description: "C755 | Brights and Darks from Edge to Edge",
  btn: {
    link: "/",
    label: "Learn more",
  },
  author: "TCL",
};
