import { CreatePostForm, SinglePostComponent } from "@/features/feed";
import { SinglePostComments } from "@/features/feed/components/SinglePost/SinglePostComments.fetcher";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { useCommunityData } from "@/hooks/useCommunityData";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

export const SingleNewsFeed: FC<{}> = ({}) => {
  const { slug } = useParams();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const filterKey = `news_feed_${slug}`;
  const [communityData, loading_community, communityError] = useCommunityData();
  const [
    newsFeed,
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchNewsFeed,
    ref,
    inView,
    setSinglePost,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<any[] | undefined, any>(
    "getTeamPosts",
    slug,
    filterKey,
    false
  );
  const inViewRefElementIndex = newsFeed ? newsFeed?.length - 3 : -1;
  useEffect(() => {
    fetchNewsFeed(
      {
        lastFeedDateInSeconds,
        teamId: slug,
        isPostedInNews: true,
      },
      filterKey,
      true
    );
  }, [slug]);

  return (
    <>
      <CreatePostForm
        postedIn="news"
        currentUser={currentUser}
        communityData={communityData}
        setFeedUpdateTrigger={undefined}
        disabledInput={undefined}
        setSinglePost={(data) => setSinglePost(data, filterKey)}
        parentId={slug}
        isPreview={false}
        inputPlaceholder="Leave your comment"
      />

      {newsFeed &&
        newsFeed.map((post: any, index: number) => {
          const key = post.id;
          return (
            <div
              className="feed-post"
              key={key}
              ref={index === inViewRefElementIndex ? ref : null}
            >
              <SinglePostComponent
                postData={post}
                isFullPagePost={false}
                actionButtons
                isInModal={false}
                isFriendsOfCurrentUser={true}
                likeParentId={post.slug}
                createCommentEnabled={true}
                isAuthorized={false}
              />
            </div>
          );
        })}
    </>
  );
};
