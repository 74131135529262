import BaseUserAvatar from "@/components/BaseUserAvatar";
import { formattedTime } from "@/features/challenge/helperFunctions/formatMsToTime";
import { FC } from "react";

export const SingleChallengeBestgamerLeaderboardUser: FC<{ user: any }> = ({
  user,
}) => {

  const userPoints = user.userChallengeData.pointsType === "ms" ? formattedTime(user.userChallengeData.points) : user.userChallengeData.points;
  
  return (
    <li className="leaderboard__user">
      <div className="user">
        <div className="user__rank">
          <span>#{user?.userChallengeData?.rank}</span>
        </div>

        <div className="user__data">
          <div className="user__profile">
            <BaseUserAvatar
              userName={user?.profile?.userName}
              logoUrl={user?.profile?.profileLogoUrl}
            />
            <span>{user?.profile?.userName}</span>
          </div>

          <div className="user__score">
            <span>{userPoints}</span>
          </div>
        </div>
      </div>
    </li>
  );
};
