import React, { FC } from "react";
import { Query_shared } from "@/api/shared";
import { SinglePostComponent } from "../SinglePost/SinglePostComponent";
import { SinglePost } from "@/features/profile/types/SinglePostProps";
import { PostData } from "../../types/singlePostsProps";

export const ProfileSinglePost: FC<{
  postData: PostData;
  isFriendsOfCurrentUser?: boolean;
}> = ({ postData, isFriendsOfCurrentUser }): JSX.Element => {
  return (
    <>
      {postData && (
        <SinglePostComponent
          postData={postData}
          isFullPagePost={false}
          actionButtons
          isInModal={false}
          isFriendsOfCurrentUser={isFriendsOfCurrentUser}
          likeParentId={postData.id}
          createCommentEnabled
        />
      )}
    </>
  );
};
