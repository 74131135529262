import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { discoverTeamsAtom } from "@/utils/atoms/teamsAtoms/teamsAtoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

export const useDiscoverTeamsData = ({ client }: { client: string }) => {
  const [discoverTeamsData, setDiscoverTeamsData] = useAtom(discoverTeamsAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [lastVisible, setLastVisible] = useState<any>(null);

  const queryInit = collectionRef.teams
    .where("client", "==", client)
    .where("type", "==", "public")
    .where("createdBy", "!=", currentUser?.uid);

  const fetchDiscoverTeams = async () => {
    setIsLoading(true);

    const query = lastVisible
      ? await queryInit.startAfter(lastVisible).limit(4).get()
      : await queryInit.limit(20).get(); // On initial load get first 10 OR if teams already been loaded before fetch first x amount to prevent jumping,

    const response = query.docs.map((doc) => {
      return doc.data();
    });

    setLastVisible(query.docs[query.docs.length - 1]);

    if (lastVisible) {
      setHasMore(response.length === 1);
      setDiscoverTeamsData((prevData) => [...prevData, ...response]);
    } else {
      setHasMore(response.length < 20);

      setDiscoverTeamsData(response);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDiscoverTeams();
  }, [client]);

  return {
    data: discoverTeamsData,
    loading: isLoading,
    fetchDiscoverTeamsData: fetchDiscoverTeams,
    hasMore: hasMore,
  };
};
