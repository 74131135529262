export const updateArray = (value, prevArray) => {
    const newArray = [...prevArray];
    if (newArray && newArray.includes(value)) {
      const loc = newArray.indexOf(value);
      newArray.splice(loc, 1);
      return [...newArray];
    }
    if (newArray && !newArray.includes(value)) {
      const array = [...prevArray];
      array.push(value);
      return [...array];
    }
    return value;
  };