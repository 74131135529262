import React, { useState, useRef, memo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchInput } from "./NavbarSearchInput";
import SearchInputDropdownItem from "./NavbarSearchInputDropdownItem";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { searchUsernameByName } from "@/api/search/searchUsernameByName";
import useDetectClickOutside from "@/hooks/useDetectClickOutside";
import { ReactComponent as IconSearch } from "./assets/search.svg";
import { Spinner } from "..";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

const NavbarSearchInputWrapper: React.FunctionComponent<{
  setFullParentSearch: any;
}> = ({ setFullParentSearch }) => {
  const [clicked, setClicked] = useState(false);
  const [fullSearch, setFullSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const [results, setResults] = useState<
    {
      slug: string;
      uid: string;
      userName: string;
      profileLogoUrl?: string;
      country?: string;
    }[]
  >([]);
  const wrapperRef = useRef(null);
  useDetectClickOutside(wrapperRef, () => {
    setClicked(false);
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleTypingStopped = async () => {
    const response = await searchUsernameByName(searchValue, brand.brandValue);
    setLoading(false);
    if (response.length !== 0) {
      setResults(response);
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.length > 4) {
      clearTimeout(typingTimeout.current);
      setLoading(true);
      typingTimeout.current = setTimeout(handleTypingStopped, 1500);
      return () => clearTimeout(typingTimeout.current);
    } else {
      return () => clearTimeout(typingTimeout.current);
    }
  }, [searchValue]);

  return (
    <div className="search" ref={wrapperRef}>
      <SearchInput
        fullSearch={fullSearch}
        setClicked={setClicked}
        handleChange={handleChange}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      >
        <IconSearch
          onClick={() => {
            setFullSearch(!fullSearch);
            setFullParentSearch(!fullSearch);
          }}
        />
      </SearchInput>
      {clicked && results.length !== 0 ? (
        <ul className="search__results">
          {results.map((result, index) => {
            const key = `${result.userName}_${index}`;
            const isCurrUser = result.slug === currentUser.slug;
            try {
              return (
                <li
                  className="search__item"
                  onClick={() => {
                    const link = isCurrUser
                      ? "/profile"
                      : `/profile/${result.slug}`;

                    navigate(link);
                    setLoading(false);
                    setClicked(false);
                    setSearchValue("");
                    setResults([]);
                  }}
                  key={key}
                >
                  <SearchInputDropdownItem
                    link={`/profile/${result.slug}`}
                    name={`${result.userName}`}
                    profileLogoUrl={result.profileLogoUrl}
                    type="User"
                    setClicked={setClicked}
                  />
                </li>
              );
            } catch (error) {
              return <></>;
            }
          })}

          {/* TODO: For later version */}
          {/* {searchValue.trim() === "" && (
            <>
              <Dropdown.Item
                as={Link}
                to="/search"
                className="more-results"
                onClick={() => setClicked(false)}
              >
                See more results
              </Dropdown.Item>
            </>
          )} */}
        </ul>
      ) : (
        ""
      )}

      {!loading &&
        clicked &&
        results.length === 0 &&
        searchValue.length > 4 && (
          <>
            <ul className="search__results">
              <li
                className="search__item search__item--empty"
                key="results_empty"
              >
                <div className="result result__empty">
                  <div className="result__name">
                    <span className="result__fallback">
                      No results. Results are case-sensitive.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </>
        )}
      {loading && <Spinner />}
    </div>
  );
};

export default memo(NavbarSearchInputWrapper);
