import { FC, useEffect } from "react";
import CreatorCard from "@/components/CreatorCard";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { AuthorProfile } from "@/models/database/profiles/profile.model";

const SingleGatherCreator: FC<{
  gatherSlug: string;
}> = (props): JSX.Element => {
  const [creatorProfile, _loading, _Error, fetch_creatorProfile] =
    useCloudFunctionCall<
      AuthorProfile & {
        isCurrentUserFollower: boolean;
        followersCount: number;
      }
    >("getAuthorProfileBasedOnSlugAndType", props.gatherSlug);

  useEffect(() => {
    const run = async () => {
      if (!props.gatherSlug) {
        return;
      }
      await fetch_creatorProfile({
        slug: props.gatherSlug,
        type: "gathers",
      });
    };
    run();
  }, []);

  return (
    <>
      <div className=" Single-gather__creator">
        {!_loading && creatorProfile && (
          <CreatorCard
            creatorProfile={creatorProfile as any}
            isFollowedByCurrentUser={creatorProfile.isCurrentUserFollower}
            creatorFollowersNumber={creatorProfile.followersCount}
            isPlaceholder={false}
          />
        )}
      </div>
    </>
  );
};
export default SingleGatherCreator;
