import React, { memo, FC, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import refresh from "../../assets/actions/Refresh.svg";

const LeaderboardUpdate: FC<{
  isUpdated: (arg: boolean) => void;
  timerVal: number;
}> = (props): JSX.Element => {
  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();

  const timeChecking =
    new Date(props.timerVal).getTime() - new Date().getTime() < 0;
  const getTime = () => {
    const time = new Date(props.timerVal).getTime() - new Date().getTime();
    if (time >= 0) {
      setMinutes(() => Math.floor((time / 1000 / 60) % 60));
      setSeconds(() => Math.floor((time / 1000) % 60));
    } else {
      props.isUpdated(true);
    }
  };

  useEffect(() => {
    if (!timeChecking) {
      const interval = setInterval(() => getTime(), 1000);
      return () => clearInterval(interval);
    }

    return props.isUpdated(false);
  }, []);
  return (
    <div className="refresh">
      {!timeChecking && (
        <span className="refresh__time">
          Updates in: {minutes! && minutes !== undefined ? `0${minutes}` : "00"}
          :{seconds! < 10 && seconds !== undefined ? `0${seconds}` : seconds}
        </span>
      )}
      {timeChecking && (
        <div
          className="refresh__button"
          onClick={() => props.isUpdated(true)}
          aria-hidden
        >
          <span>Refresh</span>
          <figure>
            <picture>
              <Image src={refresh} width={20} />
            </picture>
          </figure>
        </div>
      )}
    </div>
  );
};

export default memo(LeaderboardUpdate);
