import { Author, singleNewsOverviewTypes } from "../../types/NewsTypes";
export const SingleNewsHeading = (props: {
  isPlaceholder: boolean;
  data: singleNewsOverviewTypes & {
    author: Author;
  };
}) => {
  return (
    <div className="head__heading">
      <div className="container">
        <div className="head__content">
          <div className="head__body">
            <div className="head__title">
              <h1>{props.data.title}</h1>
            </div>
            <div className="head__excerpt">
              <p className="lead">{props.data.intro}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
