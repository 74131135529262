import { cacheDatabase } from "@/api/firebase/firebase.config";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";
import { useState, useEffect } from "react";

const useFetchLeaderboard = (slug: string, platform: string) => {
  const [loading, setLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const databaseRef = cacheDatabase.ref("leaderboard");

  useEffect(() => {
    const fetchAllLeaderboard = async () => {
      setLoading(true);
      databaseRef
        .child(slug)
        .orderByKey()
        .on("value", async (snapshot) => {
          if (snapshot.val()?.leaderboard) {
            const newLeaderboardDataArray = [];

            await Promise.all(
              snapshot
                .val()
                .leaderboard.filter((element) => {
                  return element?.userId && Object.keys(element).length !== 0;
                })
                .map(async (val) => {
                  if (val && val.userId) {
                    const authorProfile = await getAuthorProfileBasedOnUid(
                      val.userId,
                      platform
                    );

                    if (authorProfile) {
                      newLeaderboardDataArray.push({
                        profile: authorProfile,
                        userChallengeData: val.userChallengeData,
                        userId: val.userId,
                      });
                    } else if (!authorProfile && val.userChallengeData) {
                      newLeaderboardDataArray.push({
                        profile: {
                          fullName: "",
                          slug: "",
                          uid: "",
                          userName: "Failed to load user's info",
                        },
                        userId: val.userId,
                        userChallengeData: val.userChallengeData,
                      });
                    }
                  }
                })
            );

            setLeaderboardData(newLeaderboardDataArray);
          }
        });

      setLoading(false);
    };

    fetchAllLeaderboard();

    return () => {
      databaseRef.child(slug).off();
    };
  }, [slug]);

  return { loading, leaderboardData };
};

export default useFetchLeaderboard;
