import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ThreeDotsIcon } from "@/assets/actions/more-vertical.svg";
import { Image, Button, Dropdown, DropdownButton } from "react-bootstrap";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { Query_shared } from "@/api/shared/index";
import { Query_Imgix } from "@/api/imgix/index";
import QueryDB from "@/api/queryDB/common";
import { discussionComment } from "../../types/DiscussionType";

const SingleComment: FC<{
  comment: discussionComment;
  setCommentUpdateTrigger: any;
}> = (props): JSX.Element => {
  const [editComment, setEditComment] = useState<boolean>(false);
  const [editCommentVal, setEditCommentVal] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);

  const handleUpdateComment = async () => {
    if (editCommentVal.length !== 0 && !loading) {
      setLoading(true);
      await QueryDB.updateDoc(
        "gatherDiscussionComments",
        props.comment.commentId,
        {
          text: editCommentVal,
        }
      );
      setTimeout(() => {
        props.setCommentUpdateTrigger((prev: number) => prev + 1);
        setEditComment(false);
        setLoading(false);
        setEditCommentVal("");
      }, 1000);
    }
  };
  const handleDeleteComment = async () => {
    setLoading(true);
    await QueryDB.deleteDoc(
      "gatherDiscussionComments",
      props.comment.commentId
    );
    setTimeout(() => {
      props.setCommentUpdateTrigger((prev: number) => prev + 1);
      setLoading(false);
      setEditComment(false);
    }, 1000);
  };
  return (
    <>
      <BaseModalWindow
        show={deleteModalOpen}
        closeModal={() => {
          setDeleteModalOpen(false);
        }}
        size="modal--team-disband "
        title="Are you sure you want to delete this comment?"
      >
        <div className="modal--gather-buttons">
          <Button
            onClick={() => {
              setDeleteModalOpen(false);
            }}
            variant="secondary"
            type="button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteComment();
            }}
            type="button"
            variant="primary"
          >
            Delete
          </Button>
        </div>
      </BaseModalWindow>
      <li aria-hidden>
        <div className="discussion-comment">
          <Link
            to={`/profile/${props.comment.authorSlug}`}
            className="discussion-comment__info"
          >
            <Image
              width={22}
              height={22}
              src={Query_shared.imageErrorHandler(
                props.comment?.authorLogo?.includes("wog.imgix") ||
                  props.comment?.authorLogo?.includes("iplay.imgix")
                  ? `${props.comment
                      ?.authorLogo}?${Query_Imgix.getImgixUrlProps({
                      w: "22",
                      h: "22",
                    })}`
                  : props.comment.authorLogo,
                logoError,
                false
              )}
              onError={() => setLogoError(true)}
            />

            <p>
              {props.comment.authorUsername}
              <small>
                {Query_shared.getWhenWasPosted(
                  props.comment.created._seconds * 1000
                )}
              </small>
            </p>
          </Link>
          <div className="gathers-discussion-comment">
            {props.comment.isCurrentUserAuthorOfComment ? (
              <Dropdown className="menu_dropdown">
                <DropdownButton
                  variant="clear"
                  className=""
                  align="end"
                  title={<ThreeDotsIcon />}
                >
                  <Dropdown.Item
                    className=""
                    onClick={() => setEditComment(true)}
                  >
                    Edit comment
                  </Dropdown.Item>
                  <Dropdown.Item
                    className=""
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    Delete comment
                  </Dropdown.Item>
                </DropdownButton>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
        {!editComment && <p className="comment">{props.comment.text}</p>}
        {editComment && (
          <div>
            <textarea
              className="base-input w-100"
              value={editCommentVal || props.comment.text}
              onChange={(e) => {
                setEditCommentVal(e.target.value);
              }}
            />
            <div className="discussion-comment__edit">
              <Button
                variant="secondary"
                onClick={() => {
                  setEditComment(false);
                }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                variant="primary"
                disabled={
                  (editCommentVal.length === 0 &&
                    editCommentVal !== props.comment.text) ||
                  loading
                }
                onClick={() => {
                  handleUpdateComment();
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        )}
      </li>
    </>
  );
};
export default SingleComment;
