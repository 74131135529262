import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

const SideMenuAvatar: React.FunctionComponent<{
  styleProps: string;
  isPlaceholder?: boolean;
  closeWindow?: () => void;
  profile: Profile;
}> = ({ profile, closeWindow, styleProps, isPlaceholder }) => {
  const isBestGamer = brand.brandValue === "BESTGAMER";

  return (
    <Link to={`/profile`} className="offcanvas__user" onClick={closeWindow}>
      <div className="user">
        {!isBestGamer && (
          <span className="user__info">
          <span className="user__username">{profile?.userName}</span>
          <span className="user__fullname">{profile?.fullName}</span>
        </span>
        )}

        <div className="user__avatar">
          <BaseUserAvatar
            userName={profile?.userName}
            logoUrl={profile.profileLogoUrl}
          />
        </div>
      </div>
    </Link>
  );
};

export default SideMenuAvatar;
