import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { Team } from "@/models/database/teams/team.model";
import { UserTeamJunction } from "@/models/database/teams/userTeamJunction.model";
import { awaitPromiseAll } from "@/utils/sharedHelperFunctions/awaitPromiseAll";

const teamsRef = collectionRef.teams;
const userTeamJunctionRef = collectionRef.userTeamJunction;

// This file is a migration from BE to FE. the logic here is is copy-paste from the BE getSingleTeam cloudfunction.

// Original call ˅˅˅˅
// const [teamDataResponse, loading_SingleTeam, error, fetch_team] =
//   useCloudFunctionCall<SingleTeamResponseTypes>("getSingleTeam", slug);

export const getTeamData = async ({
  data,
  currUserId,
}: {
  data: {
    slug: string;
    seeAll: boolean;
  };
  currUserId: string;
}) => {
  const { slug } = data;
  const teamDoc = await teamsRef.where("slug", "==", slug).limit(1).get();

  if (teamDoc.empty) {
    throw new Error("Team not found!");
  }

  const teamData = teamDoc.docs[0].data() as Team & { teamId: string };
  teamData.teamId = teamDoc.docs[0].id;

  const promises = (await awaitPromiseAll([
    userTeamJunctionRef
      .doc(`${currUserId}_${teamData.teamId}`)
      .get() as Promise<unknown>,
    collectionRef.teamsFollowers.doc(teamData.slug).get() as Promise<unknown>,
  ])) as any[];

  const junctionForCurrUser = promises[0];

  // if team private
  if (
    teamData.type === "private" &&
    (!junctionForCurrUser.exists ||
      (junctionForCurrUser.data() as UserTeamJunction).status !== "accepted")
  ) {
    // return partial team
    return {
      teamLogo: teamData.teamLogo,
      description: teamData.description,
      games: teamData.games,
      name: teamData.name,
      slug: teamData.slug,
      currUserStatus: junctionForCurrUser.data()?.status,
    };
  }

  // public team (or private but user is in it) return full
  const currTeamFollowers = promises[1];

  return {
    isCurrUserFavorite: !!junctionForCurrUser.data()?.isFavorite,
    isCurrUserFollower: !!currTeamFollowers
      .data()
      ?.followers.includes(currUserId || ""),
    isCurrUserMember: junctionForCurrUser.data()?.status === "accepted",
    currUserStatus: junctionForCurrUser.data()?.status,
    isCurrUserAdmin:
      teamData.createdBy === currUserId ||
      junctionForCurrUser.data()?.generalRole === "admin",
    ...teamData,
  };
};
