import React from "react";
import { BestGamerPartnerData } from "./../utils/partnerData";
import { useBestGamerChallenges } from "../../../../helperFunctions/useBestGamerChallenges";
import { useNavigate } from "react-router-dom";
import { Challenge } from "@/models/database/challenges/challenge.model";
import { siteRoutes } from "../../../../routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";

export const BestGamerHomePagePartners = () => {
  const navigate = useNavigate();

  const { challenges, loading } = useBestGamerChallenges({
    gamesToPick: ["subwaysurfers", "fortnite", "fallguys", "f12023"],
  });

  const handleClick = (link) => {
    if (loading) {
      createToast({
        type: "info",
        message: "Loading challenges, please wait",
      });
      return;
    }

    if (link) {
      navigate(link);
    } else {
      createToast({
        type: "info",
        message: "Currently no active challenge for this game",
      });
    }
  };

  return (
    <div className="partners">
      {BestGamerPartnerData.map((partner, index) => {
        const key = index + "_" + partner.gameType;
        const BestGamerLogo = partner.bestGamerLogo;
        const ChallengeLogo = partner.challengeLogo;
        const PartnerLogo = partner.partnerLogo;
        const challengeData = challenges?.[partner.gameType] as {
          data: Challenge;
          exists: boolean;
        };

        const link = challengeData?.data?.slug
          ? siteRoutes["challengesAndSlug"] + challengeData?.data?.slug
          : "";

        return (
          <div key={key} className="partner" onClick={() => handleClick(link)}>
            <div className="partner__background">
              <img src={partner.backgroundImage} alt="" />
            </div>
            <div className="partner__content">
              <div className="content">
                <div className="content__logos">
                  <div className="content__brand">
                    <BestGamerLogo />
                  </div>
                  <div className="content__partner">
                    <PartnerLogo />
                  </div>
                </div>
                <div className="content__challenge">
                  <ChallengeLogo />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
