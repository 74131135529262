import React from "react";
import SingleChallengePage from "./SingleChallengePage";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import SingleChallengeEventPage from "./SingleChallengeEventPage";
import SingleChallengeBestgamerPage from "./layouts/bestgamer/SingleChallengeBestgamerPage";
export const SingleChallengePageRenderLogic = () => {
  // Layout for events
  if (brand.layout.challenge === "event") {
    return <SingleChallengeEventPage />;
  }

  // layout for bestgamer
  if (brand.layout.challenge === "bestgamer") {
    return <SingleChallengeBestgamerPage />;
  }

  //   Default layout
  return <SingleChallengePage />;
};
