export const languages = ["eng", "dan"];

export const language = [
    {
        label: "English",
        value: "eng"
    },
    {
        label: "Danish",
        value: "dan"
    },
]