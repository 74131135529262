import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { siteRoutes } from "../../routes/siteRoutesList";
import React from "react";
import IconFacebook from "./assets/facebook.svg";
import IconInstagram from "./assets/instagram.svg";
import IconYouTube from "./assets/youtube.svg";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import i18next from "i18next";

const footerSocial = [
  {
    icon: IconFacebook,
    link: "https://www.facebook.com/",
    alt: "Facebook",
  },
  {
    icon: IconInstagram,
    link: "https://www.instagram.com/",
    alt: "Instagram",
  },
  {
    icon: IconYouTube,
    link: "https://www.youtube.com/",
    alt: "YouTube",
  },
];

const ElgigantenSocial = [
  {
    icon: IconFacebook,
    link: "https://www.facebook.com/ElgigantenGaming/",
    alt: "Facebook",
  },
  {
    icon: IconInstagram,
    link: "https://www.instagram.com/elgigantengaming/",
    alt: "Instagram",
  },
  {
    icon: IconYouTube,
    link: "https://www.youtube.com/channel/UCfNikAEVosR26UIvdJPTU8w",
    alt: "YouTube",
  },
];

const LayoutFooter: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [footer, setFooter] = useState(footerSocial);
  const footerLinks = {
    "Terms of Use": siteRoutes["terms"],
    "Privacy policy": siteRoutes["privacy-policy"],
  };

  const hasAlert = brand.hasFeature.getstarted;
  const isElgiganten = brand.brandValue === "ELGIGANTEN";

  useEffect(() => {
    if (isElgiganten) {
      setFooter(ElgigantenSocial);
    }
  }, [brand, footer]);

  return (
    <footer className={`footer ${hasAlert && "footer__getstarted"}`}>
      <div className="container">
        {brand.hasFeature.getstarted && (
          <div className="footer__alert">
            <div className="alert">
              <div className="alert__content">
                <div className="alert__body">
                  <h2>{i18next.t("footer.alert.headline")}</h2>
                  <p>{i18next.t("footer.alert.description")}</p>
                </div>
                <div className="alert__actions">
                  <Link to="/tracker" className="btn btn-primary btn-sm">
                    <span className="btn-text btn-text--lg">
                      {i18next.t("footer.alert.button")}
                    </span>
                    <span className="btn-text btn-text--sm">
                      {i18next.t("footer.alert.link")}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="footer__content">
          <div className="footer__body">
            <div className="footer__logo">
              <img src={brand.logos.small} alt="Brand mark" />
            </div>
            <div className="footer__legal">
              <p>
                Copyright &copy; 2024{" "}
                <a
                  href="https://iplay.agency"
                  target="_blank"
                  title="iPlay ApS"
                >
                  iPlay ApS
                </a>
                {brand.event.isEvent && <> for {brand.name}</>}
              </p>
            </div>
            <div className="footer__navigation">
              <ul className="nav">
                {Object.entries(footerLinks).map(([key, value]) => {
                  return (
                    <li className="nav__item" key={key}>
                      <span
                        onClick={() => {
                          navigate(value);
                        }}
                      >
                        {key}
                      </span>
                      <span className="nav__divider">·</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="footer__socials">
            <ul className="socials">
              {footer.map((social, index) => {
                return (
                  <li className="socials__item" key={social.alt}>
                    <a href={social.link} target="_blank">
                      <img
                        src={social.icon}
                        width={24}
                        height={24}
                        alt={social.alt}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LayoutFooter;
