import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// Overview
export const discoverTeamsAtom = atomWithStorage("WOG_discover_teams", []);
export const myTeamsAtom = atomWithStorage("WOG_my_teams", []);

// Single
export const visitedTeamsAtom = atomWithStorage("WOG_team_data", []);
export const visitedTeamsMembersAtom = atomWithStorage("WOG_team_members", []);
export const visitedTeamsFeedAtom = atomWithStorage("WOG_team_feed", []);
