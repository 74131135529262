export default function EmojiWrapper({
  children,
  show,
  setShow,
}: {
  children: React.ReactNode;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      {show && (
        // Just a pseudo element which will appear in the background of emoji table, clicking on it will hide the emoji table again
        <div
          onClick={() => setShow((prevState) => !prevState)}
          
        />
      )}
      {show && children}
    </>
  );
}
