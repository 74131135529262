import QueryDB from "@/api/queryDB/common";
import { Button } from "@/components";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { clientAtom } from "@/utils/atoms/atomClient";
import { createToast } from "@/utils/toaster/createToast";
import { useAtomValue } from "jotai";
import React, { FC, useState } from "react";
import { ChangePasswordResetPassword } from "./../password/ChangePasswordResetPassword";

export const EmailNoVerify: FC<{}> = ({}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [email, setEmail] = useState<string>(currentUser?.email || null);
  const [newEmail, setNewEmail] = useState<string>();
  const isEmailSame = newEmail === email;
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
  const currentClient = localStorage.getItem("client");

  const updateEmail = async () => {
    await QueryDB.updateDoc("profiles", currentUser.uid, {
      email: newEmail || null,
    }).then(() => {
      setProfile({
        ...currentUser,
        email: newEmail,
      });
      createToast({
        type: "success",
        message: "Email address updated",
      });
      setNewEmail("");
    });
  };
  console.log(currentClient);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="settings__email">
          <BaseTextInput
            label="Email address"
            textType="text"
            value={newEmail || email}
            placeholder="Email address..."
            setSingleState={setNewEmail}
            setObjectStateTargetName="emailVal"
            error={undefined}
          />
          {currentClient === "TCL" && !currentUser.email && (
            <p>
              <small>
                Tilføj din e-mail adresse for at deltage i lodtrækningen om en
                præmie.{" "}
              </small>
            </p>
          )}
        </div>
        <div className="settings__group settings__group--buttons">
          <Button
            onClickAction={() => {
              if (!isEmailSame && isEmailValid) {
                updateEmail();
              }
            }}
            variant={!isEmailSame && isEmailValid ? "primary" : "secondary"}
          >
            Save email
          </Button>
        </div>
      </form>
    </>
  );
};
