import React, { FC, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import PartipateRequestList from "./ParticipateRequestList";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { InterestedParticipatingUsersProps } from "../../types/cloudFunctions/participatingUserProps";
import InterestedParticipatingUserCard from "./InterestedParticipatingUserCard";

type prop = {
  gatherSlug: string;
  gatherName: string;
  currentUserGeneralRole?: "owner" | "admin" | "member" | "random";
  model: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRequest: React.Dispatch<React.SetStateAction<boolean>>;
};

const ParticipateInterestedModel: FC<prop> = (props) => {
  const [
    interestedUsers,
    _loading_interested,
    _interested_Error,
    fetch_interestedUsers,
  ] = useCloudFunctionCall<InterestedParticipatingUsersProps[]>(
    "getGatherFollowers",

    props.gatherSlug
  );
  const [
    participatingUsers,
    _loading_participating,
    _participating_Error,
    fetch_participatingUsers,
  ] = useCloudFunctionCall<InterestedParticipatingUsersProps[]>(
    "getGatherFollowers",
    props.gatherSlug
  );

  const [updateParticipant, setUpdateParticipant] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<"part" | "int">("part");

  useEffect(() => {
    if (!props.gatherSlug) {
      return;
    }
    // to fetch current user's profile data

    const run = async () => {
      await Promise.all([
        fetch_interestedUsers({
          slug: props.gatherSlug,
          type: "interested",
          lastUserCreatedDate: undefined,
        }),
        fetch_participatingUsers({
          slug: props.gatherSlug,
          type: "participant",
          lastUserCreatedDate: undefined,
        }),
      ]);
    };
    run();
  }, [updateParticipant]);

  return (
    <>
      <BaseModalWindow
        show={props.model}
        closeModal={() => {
          props.setModal(false);
        }}
        size="modal--gather-member"
        title={`Users in ${props.gatherName}`}
      >
        <Row>
          <Col xs={12} xl={6} className="gather-participants__search">
            {/* need to be fixed */}
            <label htmlFor="searchMember" className="w-100">
              <input
                type="search"
                id="searchMember"
                placeholder="Search by name, username"
                className="base-input w-100"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
          </Col>
        </Row>
        <PartipateRequestList
          gatherSlug={props.gatherSlug}
          setUpdateParticipant={setUpdateParticipant}
          setIsRequest={props.setIsRequest}
        />
        <div className="gather-participants__filters">
          <h4
            aria-hidden
            onClick={() => {
              setActiveFilter("part");
            }}
            className={`cursor-pointer me-2 ${
              activeFilter === "part"
                ? "gather-participants__filters--active"
                : "gather-participants__filters--inactive"
            }`}
          >
            #participants
          </h4>
          <h4
            aria-hidden
            onClick={() => {
              setActiveFilter("int");
            }}
            className={`cursor-pointer ${
              activeFilter === "int"
                ? "gather-participants__filters--active"
                : "gather-participants__filters--inactive"
            }`}
          >
            #interested
          </h4>
        </div>
        <Row className="gather__members--modal-users">
          {activeFilter === "part" &&
            participatingUsers &&
            participatingUsers.map(
              (user: InterestedParticipatingUsersProps) => {
                return (
                  <InterestedParticipatingUserCard
                    gatherSlug={props.gatherSlug}
                    user={user}
                    setUpdateParticipant={setUpdateParticipant}
                    currentUserGeneralRole={props.currentUserGeneralRole}
                  />
                );
              }
            )}
          {activeFilter === "int" &&
            interestedUsers &&
            interestedUsers.map((user: InterestedParticipatingUsersProps) => {
              return (
                <InterestedParticipatingUserCard
                  gatherSlug={props.gatherSlug}
                  user={user}
                  setUpdateParticipant={setUpdateParticipant}
                  currentUserGeneralRole={props.currentUserGeneralRole}
                />
              );
            })}
        </Row>
      </BaseModalWindow>
    </>
  );
};
export default ParticipateInterestedModel;
