import firebase from "firebase/app";
import "firebase/functions";
import { useEffect } from "react";

export const _getRealTimeNotifications = (currentUserUid: any) => {
  let data;
  let error;
  let unreadAmount;

  const databaseRef = firebase.database().ref("notifications");

  if (!currentUserUid) {
    error =
      "Failed to authenticate request, please make sure you are logged in.";
  }

  databaseRef
    .orderByChild("recipientUid")
    .equalTo(currentUserUid)
    .on("value", async (snapshot) => {
      if (snapshot.val()) {
        const response = Object.values(snapshot.val()).map((snap, index) => {
          return {
            data: snap,
            realtimeDocumentID: Object.entries(snapshot.val())[index][0], // doc uid
          };
        }) as any[];

        const unreadAmountResponse = Object.values(snapshot.val()).filter(
          (obj: any) => obj.read === false
        ).length;
        data = response;
        unreadAmount = unreadAmountResponse;
        return;
      }
      return;
    });

  return { data, unreadAmount, error } as const;
};
