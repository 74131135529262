import { FC, useState } from "react";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { Button, Spinner } from "@/components";
import { joinTeam } from "../../helperFunctions/joinTeam";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { leaveTeam } from "../../helperFunctions/leaveTeam";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { userJunctionHandler } from "../../helperFunctions/userJunctionHandler";
import { createToast } from "@/utils/toaster/createToast";

export const SingleTeamStatusButton: FC<{
  teamData: SingleTeamResponseTypes;
  userRole: string;
  setUpdateTrigger: any;
}> = ({ teamData, userRole, setUpdateTrigger }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  // Variables
  const isMember = teamData.generalRole === "member";
  const isAdmin = teamData.generalRole === "admin";
  const isNotOwner = userRole !== "owner";
  const isInTeam = teamData.currUserStatus === "accepted";
  const hasPendingRequest = teamData.currUserStatus === "pendingRequest";
  const hasBeenInvited = teamData.currUserStatus === "invited";
  const isRejected = teamData.currUserStatus === "rejected";
  const hasNotRequestedToJoin = teamData.requestedToJoin === false;
  const teamIsPrivate = teamData.type === "private";
  const hasRandomRole = userRole === "random";
  const canCurrentUserRequestToJoin = teamData.canCurrentUserSendRequest;

  // Loading "skeleton"
  if (loading) {
    return (
      <Button variant="primary" disabled>
        <Spinner />
      </Button>
    );
  }

  if (hasBeenInvited) {
    return (
      <>
        <Button
          variant="primary"
          type="button"
          onClickAction={async () => {
            setLoading(true);
            await userJunctionHandler(
              "accepted",
              currentUser,
              null,
              `${currentUser.uid}_${teamData.slug}`,
              "member",
              teamData.slug,
              true
            ).catch(() => {
              setLoading(false);
            });
            setUpdateTrigger((prev: number) => prev + 1);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
          disabled={loading}
        >
          Accept
        </Button>{" "}
        <Button
          variant="secondary"
          type="button"
          onClickAction={async () => {
            setLoading(true);
            leaveTeam(
              "userTeamJunction",
              currentUser.uid,
              teamData.slug,
              teamData.name,
              false,
              undefined
            ).catch(() => {
              setLoading(false);
            });
            navigate("/teams");
            createToast({
              type: "info",
              message: `You rejected to join ${teamData.name}`,
            });
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
          disabled={loading}
        >
          Reject
        </Button>
      </>
    );
  }

  if (
    !canCurrentUserRequestToJoin &&
    hasNotRequestedToJoin &&
    hasRandomRole &&
    isNotOwner &&
    !isMember
  ) {
    return (
      <>
        {" "}
        <Button variant="primary" disabled>
          Join requests not allowed
        </Button>{" "}
      </>
    );
  }
  // Request to join btn
  if (hasNotRequestedToJoin && isNotOwner && (teamIsPrivate || hasRandomRole)) {
    return (
      <Button
        variant="primary"
        type="button"
        onClickAction={async () => {
          setLoading(true);
          await joinTeam(currentUser.uid, teamData.slug, teamData.type).catch(
            () => {
              setLoading(false);
            }
          );
          setUpdateTrigger((prev: number) => prev + 1);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }}
        disabled={loading}
      >
        Request to join
      </Button>
    );
  }

  // Pending request
  if (hasPendingRequest) {
    return (
      <Button variant="primary" disabled>
        Pending request
      </Button>
    );
  }

  // Option to leave the team
  if ((isMember || isAdmin) && isInTeam) {
    return (
      <Button
        variant="secondary"
        onClickAction={async () => {
          setLoading(true);
          leaveTeam(
            "userTeamJunction",
            currentUser.uid,
            teamData.slug,
            teamData.name,
            false,
            undefined
          )
            .then(() => {
              createToast({
                type: "info",
                message: `You left team ${teamData.name}`,
              });
            })
            .catch(() => {
              setLoading(false);
              createToast({
                type: "error",
                message: `Failed to leave team ${teamData.name}`,
              });
            });

          setLoading(false);
          navigate(siteRoutes["teams"]);
        }}
      >
        {loading ? <Spinner /> : "Leave team"}
      </Button>
    );
  }

  // After a declined join request
  if (isRejected) {
    return (
      <Button variant="primary" disabled>
        Request denied
      </Button>
    );
  }

  // Any scenario not covered above
  return <></>;
};
