import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SingleGatherDiscussion,
  SingleGatherProps,
} from "../../types/cloudFunctions/SingleGatherTypes";
import { Spinner } from "@/components/Spinner";
import { DiscussionCard } from "../discussionCard/index";
import { Timestamp } from "@/api/firebase/firebase.config";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { DiscussionModal } from "../discussionModel/index";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import SingleGatherCreateDiscussionModal from "./SingleGatherCreateDiscussionModal";

export const SingleGatherDiscussions: FC<{
  data: SingleGatherProps;
  openPostField: boolean;
  setOpenPostField: any;
}> = ({ data, openPostField, setOpenPostField }): JSX.Element => {
  const { slug } = useParams();
  const filterKey = encodeURIComponent(decodeURIComponent(slug!));

  // const [
  //   Discussions,
  //   lastVisiblePostDate,
  //   hasMore,
  //   _loading_Discussion,
  //   _DiscussionsError,
  //   fetch_discussion,
  //   ref,
  //   inView,
  // ] = useCloudFunctionCallForListsAndInfiniteScroll<
  //   SingleGatherDiscussion[] | undefined
  // >(
  //   "getGatherDiscussions",
  //   "single_gather_gather_discussions",
  //   filterKey,
  //   true // persisting
  // );

  const [
    Discussions,
    _loading_Discussion,
    _DiscussionsError,
    fetch_discussion,
  ] = useCloudFunctionCall<SingleGatherDiscussion[]>(
    "getGatherDiscussions",
    slug
  );

  const [openDiscussionModal, setOpenDiscussionModal] =
    useState<boolean>(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);

  //only run at first load!
  useEffect(() => {
    (async () => {
      // fetch_discussion(
      //   {
      //     lastVisiblePostDate: undefined,
      //     slug: encodeURIComponent(decodeURIComponent(slug!)),
      //   },
      //   filterKey,
      //   false
      // );

      fetch_discussion({
        lastVisiblePostDate: undefined,
        slug: encodeURIComponent(decodeURIComponent(slug!)),
      });
    })();
  }, []);

  // useEffect(() => {
  //   if (inView) {
  //     fetchMore();
  //   }
  // }, [inView]);

  // call this function when inView is true
  // const fetchMore = async () => {
  //   await fetch_discussion(
  //     {
  //       slug: encodeURIComponent(decodeURIComponent(slug!)),

  //       lastVisiblePostDate: lastVisiblePostDate,
  //     },
  //     filterKey,
  //     true
  //   );
  // };

  // const inViewRefElementIndex = useMemo(() => {
  //   return Discussions ? Discussions?.length - 9 : -1;
  // }, [Discussions, hasMore]);

  const handleSwitchButton = (type: "next" | "prev") => {
    if (Discussions && Discussions.length) {
      if (type === "prev" && selectedCardIndex - 1 === 0) {
        return setSelectedCardIndex(Discussions.length - 1);
      }
      if (type === "next" && selectedCardIndex >= Discussions.length) {
        return setSelectedCardIndex(0);
      }

      if (type === "next") {
        return setSelectedCardIndex((prev: number) => prev + 1);
      }
    }

    return setSelectedCardIndex((prev: number) => prev - 1);
  };
  return (
    <>
      {Discussions && openDiscussionModal && (
        <DiscussionModal
          setModal={setOpenDiscussionModal}
          modal={openDiscussionModal}
          discussion={Discussions[selectedCardIndex]}
          gatherSlug={slug!}
          discussionsAmount={Discussions.length}
          activeIndex={selectedCardIndex}
          handleSwitchButton={handleSwitchButton}
        />
      )}

      {openPostField && (
        <SingleGatherCreateDiscussionModal
          gatherSlug={data.slug}
          setUpdateTrigger={undefined}
          openPostField={openPostField}
          setOpenPostField={setOpenPostField}
        />
      )}

      <div className="discussions-list">
        {Discussions &&
          Discussions.map((Discussion, ind) => {
            const key = Discussion.gatherPostId;

            return (
              <div
                key={key}
                // ref={ind === inViewRefElementIndex ? ref : null}
              >
                <DiscussionCard
                  indexNumber={ind}
                  subject={Discussion.subject}
                  details={Discussion.details}
                  mediaUrls={Discussion.mediaUrls}
                  commentsCount={Discussion.commentsCount}
                  created={Discussion.created}
                  authorSlug={Discussion.authorSlug}
                  authorUid={Discussion.authorUid}
                  authorUserName={Discussion.authorUserName}
                  authorProfileLogoUrl={Discussion.authorProfileLogoUrl}
                  isEdited={Discussion.isEdited}
                  gatherPostId={Discussion.gatherPostId}
                  CommentsAmount={0}
                  setModal={setOpenDiscussionModal}
                  setSelectedCardIndex={setSelectedCardIndex}
                />
              </div>
            );
          })}
      </div>
      {/* Skeleton loader */}
      {/* ADD SKELETON LOADER HERE WITH loading PROP AS CONDITION */}
      {/* ... */}
      {/* TEMP */}
      {_loading_Discussion && <Spinner />}
    </>
  );
};
