import React, { memo, useState, FC, useEffect } from "react";
import { ReactComponent as MultiplyIcon } from "../../../assets/actions/multiply.svg";
import { ReactComponent as PlusIcon } from "../../../assets/actions/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/actions/minus.svg";
import { Button } from "@/components";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";

const CreateChallengeMixedTextSelectInput: FC<{
  fields: any;
  initialField: string;
  initialNumber: string | number;
  objectsLength: number;
  index: number;
  critType: string;
  handleUpdateCriteriaList: (
    type: "empty" | "add" | "remove" | "addEmpty",
    value?: number,
    key?: any
  ) => void;
  isSelectedOption: boolean;
  isEdit: boolean;
  selectedFields: any;
}> = ({
  initialField,
  initialNumber,
  fields,
  objectsLength,
  index,
  critType,
  handleUpdateCriteriaList,
  isSelectedOption,
  isEdit,
  selectedFields,
}): JSX.Element => {
  const [inputValue, setInputValue] = useState<any>(initialNumber || 1);
  const [selectValue, setSelectValue] = useState<any>(initialField);
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isEdit) {
      return;
    }
    if (critType === "simple" && inputValue && selectValue && hasClicked) {
      handleUpdateCriteriaList("empty");
      setError("");
      return handleUpdateCriteriaList("add", Number(inputValue), selectValue);
    }

    if (!hasClicked) {
      return;
    }
    if (!inputValue || inputValue === 0) {
      return setError("Please enter a valid amount.");
    }
    if (!selectValue) {
      return setError("Please pick a criteria.");
    }

    if (inputValue && selectValue) {
      setError("");
    }
    return setError("");
  }, [selectValue, inputValue, hasClicked]);

  return (
    <>
      <div
        className={`${
          critType === "advanced"
            ? "criteria__item criteria__item--advanced"
            : "criteria__item criteria__item--simple"
        }`}
      >
        {critType === "advanced" ? (
          <>
            <input
              type={isSelectedOption ? "text" : "text"}
              disabled={isSelectedOption}
              onChange={(e) => {
                if (isSelectedOption) {
                  return;
                }
                if (critType === "advanced" && Number(e.target.value) < 0) {
                  setInputValue(0);
                  setHasClicked(true);
                  return;
                }
                setHasClicked(true);
                setInputValue(e.target.value);
              }}
              onBlur={(e) => {
                if (isSelectedOption) {
                  return;
                }
                if (
                  (critType === "advanced" && Number(inputValue) <= 0) ||
                  Number(inputValue) < 0
                ) {
                  setInputValue(0);
                  setHasClicked(true);
                  setError("Please enter a valid number.");
                  return;
                }
                setHasClicked(true);
                setInputValue(e.target.value);
              }}
              value={inputValue}
              placeholder="_"
              className="base-input"
            />
            <div className="item__multiply">
              <MultiplyIcon />
            </div>
          </>
        ) : (
          <>
            <select
              onChange={(e) => {
                setInputValue(e.target.value);
                setHasClicked(true);
              }}
              onClick={(e) => {
                setInputValue(e.currentTarget.value);
                setHasClicked(true);
              }}
              onBlur={(e) => {
                setInputValue(e.target.value);
                setHasClicked(true);
              }}
              defaultValue={inputValue}
              placeholder="_"
              className="base-input"
            >
              <option label="Amount" disabled value="" />
              <option value={1} key="Max">
                Max
              </option>
              <option value={-1} key="Min">
                Min
              </option>
            </select>
          </>
        )}
        <select
          disabled={isSelectedOption}
          onChange={(e) => {
            if (isSelectedOption) {
              return;
            }
            setHasClicked(true);
            if (e.target.value) {
              setSelectValue(e.target.value);
              // setUpdateTrigger((prev) => prev + 1);
            }
          }}
          onClick={(e) => {
            if (isSelectedOption) {
              return;
            }
            setHasClicked(true);
            if (e.currentTarget.value) {
              setSelectValue(e.currentTarget.value);
              // setUpdateTrigger((prev) => prev + 1);
            }
          }}
          onBlur={(e) => {
            if (isSelectedOption) {
              return;
            }
            setHasClicked(true);
            if (e.target.value) {
              setSelectValue(e.target.value);
              // setUpdateTrigger((prev) => prev + 1);
            }
          }}
          value={selectValue}
          className="base-input"
        >
          <option label="Choose option" value="" />

          {fields.map((f) => {
            if (f) {
              return (
                <option value={f.value} key={f.value}>
                  {f.text}
                </option>
              );
            }
          })}
        </select>

        {critType === "advanced" && (
          <>
            <div className="item__buttons">
              {!isSelectedOption && (
                <Button
                  variant="link"
                  customStyles="item__btn item__btn--add"
                  onClickAction={() => {
                    if (selectValue && inputValue && !error) {
                      handleUpdateCriteriaList(
                        "add",
                        Number(inputValue),
                        selectValue
                      );
                      setSelectValue("");
                      setError("");
                      setInputValue(1);
                      setHasClicked(false);
                    }
                  }}
                >
                  <PlusIcon />
                  Add to list
                </Button>
              )}

              {!isEdit && isSelectedOption && selectValue ? (
                <Button
                  variant="link"
                  customStyles="item__btn item__btn--remove"
                  onClickAction={() =>
                    handleUpdateCriteriaList(
                      "remove",
                      Number(inputValue),
                      selectValue
                    )
                  }
                >
                  <RemoveIcon />
                  Remove
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
      {!isSelectedOption && error && (
        <FormErrorMessage text={error} isVisible={!!error} />
      )}
    </>
  );
};

export default memo(CreateChallengeMixedTextSelectInput);
