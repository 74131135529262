import React, { FC, useState } from "react";
import { games } from "@/utils/constants/games";
import { Button } from "@/components/Button";
import { updateArray } from "./helperFunctions/updateArray";
import { ReactComponent as PlusIcon } from "../../features/challenge/assets/actions/plus.svg";
import { Badge } from "../Badge";


const KeywordsSelector: FC<{
  setObjectValue: any;
  setObjectTargetName: string;
  objectValue: any[];
  label: string;
  inputPlaceholder: string;
  disableCustom: boolean;
}> = ({
  setObjectValue,
  setObjectTargetName,
  objectValue,
  label,
  inputPlaceholder,
  disableCustom,
}): JSX.Element => {
  const [customInputVal, setCustomInputVal] = useState<string>("");

  const handleChange = (e: any) => {
    if (e || customInputVal) {
      setObjectValue(
        setObjectTargetName,
        updateArray(e?.target?.dataset?.value || e, objectValue)
      );
    }
  };
  return (
    <>
      <div className="keywords">
        {label ? <label>{label}</label> : ""}

        {!disableCustom && (
          <div className="keywords__custom">
            <input
              type="text"
              className="base-input"
              id="inlineFormInputGroup"
              placeholder={inputPlaceholder}
              value={customInputVal}
              onChange={(e) => {
                setCustomInputVal(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleChange(customInputVal);
                  setCustomInputVal("");
                }
              }}
            />
            <Button
              variant="secondary"
              type="button"
              onClickAction={() => {
                handleChange(customInputVal);
                setCustomInputVal("");
              }}
            >
              <PlusIcon />
              Add to list
            </Button>
          </div>
        )}

        {/* List of pre-defined keywords */}
        <div className="keywords__options">
          <div className="badge-wrapper">
            {games.map((game: any, index) => {
              const key = `${game.value}_${index}`;
              return (
                <Badge
                  variant={
                    objectValue && objectValue.includes(game.value)
                      ? "primary"
                      : "secondary"
                  }
                  key={key}
                  onClick={() => handleChange(game.value)}
                  data-value={game.value}
                >
                    <span data-value={game.value}>{game.name}</span>
                </Badge>
              );
            })}
          </div>
        </div>

        {/* List of selected keywords */}
        <div className="keywords__selected">
          {objectValue.length !== 0 && (
            <>
              <p>Selected:</p>
            </>
          )}
          <div className="badge-wrapper">
            {objectValue.map((hit: any) => {
              return (
                <Badge
                  key={hit}
                  variant={
                    objectValue && objectValue.includes(hit)
                      ? "primary"
                      : "secondary"
                  }
                  onClick={handleChange}
                   data-value={hit}
                >
                  <span data-value={hit}>{hit}</span>
                </Badge>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordsSelector;
