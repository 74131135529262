import { createToast } from "@/utils/toaster/createToast";
import React, { useState, useEffect } from "react";

export const ImageUpload: React.FunctionComponent<{
  value: any;
  acceptType: string;
  setIsLoading: (arg: boolean) => void;
  setType: (arg: string) => void;
  styleProps?: string;
  children: any;
  setFieldValue: any;
  name: string;
  isDisabled?: boolean;
}> = ({
  acceptType,
  children,
  setIsLoading,
  setType,
  styleProps,
  setFieldValue,
  value,
  name,
  isDisabled = false,
}) => {
  const [fileSrc, setFileSrc] = useState<File | undefined>(undefined);

  useEffect(() => {
    if (!value) {
      setFileSrc(undefined);
    }
  }, [value]);

  const handleInput = async (file: File | undefined) => {
    setIsLoading(true);
    setFileSrc(file);
    const type = file?.type.slice(0, 5);
    setType(type || "");
    if (
      !file ||
      (type === "image" && file.size > 15e6) ||
      (type === "video" && file.size > 2e8)
    ) {
      createToast({
        type: "error",
        message: "File is too big and can not be uploaded",
      });
      setIsLoading(false);
      return;
    }
    setFieldValue(name, file);
    setIsLoading(false);
  };

  return (
    <>
      <label htmlFor={name} className={`image-input ${styleProps}`}>
        <input
          disabled={isDisabled}
          type="file"
          onChange={(e) => {
            if (fileSrc) {
              handleInput(e?.target?.files?.[0]);
              setFileSrc(e?.target?.files?.[0]);
            } else {
              handleInput(e?.target?.files?.[0]);
            }
          }}
          accept={acceptType}
          id={name}
          value=""
        />
        {children}
      </label>
    </>
  );
};
