import { FC } from "react";

const SingleChallengeLoader: FC = (): JSX.Element => {
  return (
    <div className="challenge placeholder">
      {/* General info (Image, time, desc, tags, title) */}
      <div className="challenge__info">
        <div className="info">
          <div className="info__meta">
            <div>
              <div className="info__image">
                <div className="image wave" />
              </div>
              <div className="challenge__actions">
                <div className="challenge__participate wave" />
              </div>
            </div>

            <div>
              <div className="info__heading wave" />
              <div className="info__badges">
                <span className="info__type wave" />
                <span className="info__game wave" />
              </div>
              <p className="info__goal" />

              <div className="info__participants">
                <div className="participants">
                  <ul className="participants__list">
                    {Array.from(Array(3), (e, index) => {
                      return (
                        <li className="participants__item" key={index}>
                          <div className="participants__avatar">
                            <div className="avatar wave">
                              <div className="avatar__content" />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="info__progress">
            <div className="progress">
              <label className="progress__label">
                <span className="progress__started wave" />
                <span className="progress__status wave" />
                <span className="progress__ended wave" />
              </label>
              <progress
                value="0"
                max="100"
                className="singel_challenge_overview wave"
              >
                10%
              </progress>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons (Edit, Fav, Invite) */}
      <div className="challenge__icons">
        <div>
          {Array.from(Array(4), (e, index) => {
            return (
              <button className="btn btn-link wave" key={index}>
                <span />
              </button>
            );
          })}
        </div>
      </div>

      {/* Additional info fields */}
      <div className="challenge__additional">
        <div className="challenge__tooltip wave" />
      </div>

      {/* Formula */}
      <div className="challenge__formula">
        <div className="formula wave" />
      </div>

      {/* Author card */}
      <div className="challenge__creator">
        <div className="creator-card wave" />
      </div>

      {/* Description */}
      <div className="challenge__description">
        <h3 className="wave" />
        <p className="wave" />
      </div>

      {/*  Leaderboard */}

      <div className="challenge__leaderboard">
        <div className="leaderboard">
          <div className="leaderboard__heading wave">
            <h2 />
          </div>
          <div className="leaderboard__tabs">
            <div className="badge-wrapper">
              <div className="badge wave" />
              <div className="badge wave" />
              <div className="badge wave" />
            </div>
          </div>
          <div className="leaderboard__list">
            <div className="leaderboard__item leaderboard__item--info wave" />
            {Array.from(Array(4), (e, index) => {
              return (
                <div className="leaderboard__item leaderboard__item--placeholder wave" key={index} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleChallengeLoader;
