import chall_icon from "../assets/challenges-icon-l.svg";
import chall_vector from "../assets/challenges-vector.svg";
import team_icon from "../assets/teams-icon-l.svg";
import team_vector from "../assets/team-vector.svg";
import gather_icon from "../assets/gathers-icon-l.svg";
import gather_vector from "../assets/gathers-vector.svg";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const mobileLinksAndPages = [
  {
    tab: "Challenges",
    slug: "challenges",
    icon: chall_icon,
    iconClassName: "",
    vector: chall_vector,
    vectorClassName: "",
    hasFeature: brand.hasFeature.challenges,
  },
  {
    tab: "Teams",
    slug: "teams",
    icon: team_icon,
    iconClassName: "",
    vector: team_vector,
    vectorClassName: "",
    hasFeature: brand.hasFeature.teams,
  },
  {
    tab: "Gathers",
    slug: "gathers",
    icon: gather_icon,
    iconClassName: "",
    vector: gather_vector,
    vectorClassName: "",
    hasFeature: brand.hasFeature.gathers,
  },
  // {
  //   tab: "achievements",
  //   slug: "achievements",
  //   icon: team_icon,
  //   iconClassName: "",
  //   vector: team_vector,
  //   vectorClassName: "",
  //   hasFeature: true,
  // },
] as {
  tab: string;
  slug: "gathers" | "teams";
  icon: string;
  iconClassName: string;
  vector: string;
  vectorClassName: string;
  hasFeature: boolean;
}[];
