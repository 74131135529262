import BaseUserAvatar from "@/components/BaseUserAvatar";
import { FC } from "react";
import { Link } from "react-router-dom";

export const SingleTeamSeeAllOtherUser: FC<{ user: any }> = ({ user }) => {
  return (
    <>
      <Link to={`/profile/${user.slug}`}>
        <div className="team-members__avatar">
          <BaseUserAvatar logoUrl={user.logoUrl} userName={user.userName} />
        </div>
        <div className="team-members__name">
          <span className="team-members__username">{user.userName}</span>
          {user.fullName && (
            <span className="team-members__fullname">{user.fullName}</span>
          )}
        </div>
      </Link>
    </>
  );
};
