import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import doesStringContainsOnlyEmoji from "@/features/profile/helperFunctions/doesStringContainsOnlyEmoji";
import UserPost from "./UserPost";
import { PostData } from "../../types/singlePostsProps";
import { Query_shared } from "@/api/shared";
import SharedPostFallback from "./Fallbacks/SharedPostFallback";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { getAuthorProfileBasedOnUserName } from "@/api/search/searchUsernameByName";
import BaseUserAvatar from "@/components/BaseUserAvatar";

const SharedPost: React.FunctionComponent<{
  type: string;
  postPhoto: any;
  postVideo: any;
  isFromFeed?: boolean;
  data?: PostData;
}> = ({ type, postPhoto, postVideo, isFromFeed, data }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [formattedPostContent, setFormattedPostContent] = useState<
    React.ReactNode[] | null
  >(null);
  const [showMorePost, setShowMorePost] = useState(false);
  const numberOfLineBreaks = (data?.postText?.match(/\n/g) || []).length;
  const doesPostTextIsEmojiOnly = !!doesStringContainsOnlyEmoji(
    data.additionalData?.postText
  );

  const highlightAtMentions = async (
    text: string | null
  ): Promise<React.ReactNode[] | null> => {
    if (!text) return null;
    const words = text.split(" ");

    const mentions = await Promise.all(
      words.map(async (word: string, index) => {
        const key = `${word}_${index}`;

        // Check if word starts with @ and has at least 1 other character after it.
        if (word.startsWith("@") && word.length > 1) {
          const response = await getAuthorProfileBasedOnUserName(
            word.replace("@", ""),
            brand.brandValue
          );

          if (response[0]) {
            const isCurrUser = response[0].slug === currentUser.slug;
            const link = isCurrUser
              ? "/profile"
              : `/profile/${response[0].slug}`;

            return (
              <Link to={link} key={key}>
                <span className="post-highlight__tag">{word} </span>
              </Link>
            );
          }
          return <React.Fragment key={key}>{word} </React.Fragment>;
        }
        return <React.Fragment key={key}>{word} </React.Fragment>;
      })
    );
    setFormattedPostContent(mentions);
  };

  useEffect(() => {
    if (!formattedPostContent) {
      highlightAtMentions(data.additionalData?.postText);
    }
  }, [data.additionalData]);

  if (data && data.additionalData?.parentPostId) {
    try {
      return (
        <div className="post shared-post">
          <div className="post__info">
            <div className="post__user">
              <div className="post__avatar">
                <BaseUserAvatar
                  logoUrl={data.additionalData?.authorLogo}
                  userName={data.additionalData?.authorUsername}
                />
              </div>
              <div className="post__author">
                <Link
                  className="author__username"
                  to={`/profile/${data.additionalData?.authorSlug}`}
                >
                  <strong>{data.additionalData?.authorUsername}</strong>
                </Link>

                <span className="author__timestamp">
                  <time
                    dateTime={new Date(
                      data.additionalData?.created?._seconds * 1000
                    ).toDateString()}
                  >
                    {Query_shared.getWhenWasPosted(
                      data.additionalData?.created?._seconds * 1000
                    )}
                  </time>{" "}
                  {data.wasEdited ? <span>&#9679; Edited</span> : ""}
                </span>
              </div>
            </div>
          </div>

          <div
            className={`post__content ${
              doesPostTextIsEmojiOnly ? "large" : ""
            } ${showMorePost || numberOfLineBreaks > 3 ? "" : "more"}`}
          >
            {!data.additionalData.postText ? (
              ""
            ) : (
              <p>{formattedPostContent} </p>
            )}
            {/* {data.additionalData.postText &&
              (data.additionalData.postText.length > 80 || numberOfLineBreaks > 3) && (
                <Button
                  variant="link"
                  onClick={() => setShowMorePost((prev) => !prev)}
                >
                  {showMorePost ? "Show less" : "Show more"}
                </Button>
              )} */}
          </div>

          <UserPost data={data} />
        </div>
      );
    } catch (error) {
      return <SharedPostFallback />;
    }
  } else {
    return <SharedPostFallback />;
  }
};

export default memo(SharedPost);
