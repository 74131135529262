import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { CreateGatherTypes } from "@/features/gather/utils/createGatherValuesAndProps";
import React, { FC, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import icon_upload from "../../../../../assets/actions/cloud-arrow.svg";
import { createChallengeOptions } from "@/features/challenge/utils/CreateChallengeOptions";
import { Query_Imgix } from "@/api/imgix";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import KeywordsSelector from "@/components/KeywordsSelector/KeywordsSelector";
import { RadioInputsGroup, Spinner } from "@/components";

export const CreateGatherStep1: FC<{
  setNewGatherValue: any;
  gatherValues: CreateGatherTypes;
  error:
    | {
        error: string;
        message: string;
      }
    | undefined;
}> = ({ gatherValues, setNewGatherValue, error }): JSX.Element => {
  const [imgLoading, setImgLoading] = useState<boolean>(false);

  const handleInput = async (file: File | undefined) => {
    setImgLoading(true);
    setNewGatherValue("logoUrl", undefined);

    const type = file?.type.slice(0, 5);
    if (!file || (type === "image" && file.size > 15e6)) {
      return;
    }

    if (file) {
      const FileToBlob = URL.createObjectURL(file);
      setImgLoading(false);
      setNewGatherValue("logoUrl", FileToBlob);
      setNewGatherValue("logoUrlFile", file);
    }
  };

  return (
    <div className="create-gather">
      <Row>
        <Col xs={12} lg={6}>
          <div className="gather-type">
            <RadioInputsGroup
              name="Type"
              options={createChallengeOptions.type}
              label="Type"
              error={undefined}
              setFieldValue={setNewGatherValue}
              initVal={gatherValues.type || "Record"}
            />
          </div>
          <Row>
            <Col xs={12} lg={9}>
              <div className="gather-name-input">
                <BaseTextInput
                  textType="text"
                  label="Name"
                  value={gatherValues.name}
                  setObjectState={setNewGatherValue}
                  setObjectStateTargetName="name"
                  placeholder="Name your gather something catchy..."
                  error={undefined}
                />
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <label htmlFor="gather-logo" className="create-gather__setimage">
                <h4>Image</h4>
                <div className="create-gather__image">
                  {imgLoading && <Spinner />}
                  {!imgLoading && (
                    <Image
                      height={40}
                      width={50}
                      className="input-image"
                      src={gatherValues.logoUrl || icon_upload}
                      alt=""
                    />
                  )}
                </div>

                <input
                  type="file"
                  className="d-none base-input"
                  id="gather-logo"
                  onChange={(e) => {
                    if (e && e.target.files) {
                      handleInput(e.target.files[0]);
                    }
                  }}
                />
              </label>
              {error && (
                <FormErrorMessage
                  text={error.message}
                  isVisible={error.error === "logoUrl"}
                />
              )}
            </Col>
          </Row>
          <div className="mb-2">
            <BaseTextInput
              textType="textarea"
              label="Description"
              value={gatherValues.description}
              setObjectState={setNewGatherValue}
              setObjectStateTargetName="description"
              placeholder="Details of the gather..."
            />
          </div>
          <div>
            <KeywordsSelector
              setObjectValue={setNewGatherValue}
              setObjectTargetName="games"
              objectValue={gatherValues.games || []}
              label="Keywords"
              inputPlaceholder="Type custom keyword..."
              disableCustom={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
