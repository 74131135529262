import { Button } from "@/components/Button";
import { Spinner } from "@/components/Spinner";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createQuickAccountUser } from "@/utils/sharedHelperFunctions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import i18next from "i18next";

export const SignUpAnonymously: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(false);
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  const handleLogInAnonymously = async () => {
    isLoading(true);
    await createQuickAccountUser(setProfile).finally(async () => {
      navigate(siteRoutes["profile"]);
    });
    isLoading(false);
  };

  return (
    <Button
      variant="secondary"
      onClickAction={async () => {
        await handleLogInAnonymously();
      }}
    >
      <span>
        <p>{loading ? <Spinner /> : i18next.t("auth.input.button.quick_account")}</p>
      </span>
    </Button>
  );
};
