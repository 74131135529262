import React, { useState, FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import Consent from "@/components/Consent/Consent";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import LandingHeader from "./components/Header";
import LandingHero from "./components/Hero";
import LandingFooter from "./components/Footer";
import ElgImage from "./assets/DHS24.webp";
import jblImage from "../../../branchAndBrandSpecific/features/partners/assets/jbl_challenge.webp";
import TCL from "./assets/tcl.webp";
import PCSpecialist from "./assets/pcspecialist.webp";
import LandingCallToAction from "./components/CallToAction";
import racingImage from "./assets/elgRacing.webp";

export const LandingElgiganten: FC = (): JSX.Element => {
  const [activeLang, setActiveLang] = useState<string>(
    localStorage.getItem("lang") || "eng"
  );
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    function updateHeaderHeight() {
      if (headerRef.current) {
        const height = headerRef.current.clientHeight;
        setHeaderHeight(height);
      }
    }
    
    updateHeaderHeight();

    window.onresize = updateHeaderHeight;   
    
    return () => {
      window.onresize = null;
    };
  }, [headerRef])

  useEffect(() => {
    if (currentUser) {
      navigate(siteRoutes["home"]);
    }
  }, [currentUser]);

  // Page Document Title
  useDocumentTitle(`Elgiganten at Dreamhack Summer 2024`);

  return (
    <div className="landing">
      <div className="container">
        <LandingHeader Ref={headerRef} />
        <LandingHero 
            headerHeight={headerHeight}
            headlineColored="Dreamhack Summer 2024"
            headline="where the magic of gaming comes to life!"
            description="Visit our enormous booth to take part in all our great activities and giveaways and don't miss our famous 1337 giveaway! We also have a large store where we will have huge awesome deals. Take the opportunity to pick up the latest gaming products for a good price!"
            image={ElgImage}
            btn_label="See what's in store for Dreamhack"
        />

        <LandingCallToAction 
         title={"JBL Quantum CS2 Challenge"}
         desc={
           "Put your agility and reflexes to the ultimate test in the CS2 Time trial Challenge! Navigate through our thrilling course filled with dynamic obstacles, sharp turns, and unexpected twists. Your mission: complete the course in record time. Are you ready to compete for the fastest time and claim victory? Lace up your virtual running shoes and prove your speed and skill in this exhilarating challenge!"
         }
         image={jblImage}
          link="/signup"
          label={"Sign up today"}
          float="left"
          arrow="1"
          arrowClr="green"
        />
        <LandingCallToAction 
          title={"Racing Challenge"}
          desc={"Test your racing skills and set the fastest time in our F1® 23 challenge. Try the award-winning Logitech G design. The TRUEFORCE feedback technology reacts directly to the events in the game and ensures unprecedented realism."}
          image={racingImage}
          link="/signup"
          label={"Join the challenge"}
          float="right"
          arrow="2"
          arrowClr="green"
        />
        <LandingCallToAction 
          title={"TCL 1v1 CS2 Challenge"}
          desc={"Join us for the ultimate 1v1 CS2 showdown at DreamHack, happening inside the Elgiganten store. Test your skills in intense, head-to-head battles on cutting-edge gaming setups, and try out TCL gaming monitors. Whether you're a pro or a newcomer, this is your chance to shine and dominate the competition. Don't miss the action—come and prove you're the best!"}
          image={TCL}
          link="/signup"
          label={"Challenge a friend"}
          float="left"
          // arrow="3"
          // arrowClr="green"
        />
        {/* <LandingCallToAction 
          title={"Try your Ultimate Gaming PC with PCSpecialist!"}
          desc={"Experience unrivalled Gaming PC performance in your favourite games and compete in online action with Gaming PCs from the leading custom PC provider. Our award-winning Gaming PC ranges are built with the latest technology from world-renowned brands such as Intel, AMD & Nvidia, so you can enjoy gameplay in the highest settings possible."}
          image={PCSpecialist}
          link="/signup"
          label={"Try it out now"}
          float="right"
        /> */}
      </div>
      <LandingFooter />
      <Consent layout="small" />
    </div>
  );
};
