import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { FC, useEffect, useRef, useState } from "react";
// import { SingleTeamSeeAllRequests } from "./SingleTeamSeeAllModalRequests";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { Badge } from "@/components";
import { SingleTeamSeeAllMembers } from "./SingleTeamSeeAllModalMembers";
// import { SingleTeamSeeAllBanned } from "./SingleTeamSeeAllModalBanned";
// import { SingleTeamSeeRejected } from "./SingleTeamSeeAllModalRejected";
import {
  getTeamMembersBasedOnSlug,
  searchTeamMember,
} from "@/api/queryRealtime/Teams";
import { useCacheDataArrayManager } from "@/hooks/useCacheDataArrayManager";
import { useDebounce } from "@/hooks/useDebounce";
import { SingleTeamSeeAllOthers } from "./SingleTeamSeeAllOthers";

export const SingleTeamSeeAllModal: FC<{
  teamData: SingleTeamResponseTypes;
  userRole: "owner" | "admin" | "member" | "random";
  setSeeAllMembersModalOpen: any;
  setAddMemberModalOpen: any;
  isFullPage: boolean;
  setUpdateTrigger?: any;
}> = ({
  teamData,
  userRole,
  setSeeAllMembersModalOpen,
  setAddMemberModalOpen,
  isFullPage,
  setUpdateTrigger,
}) => {
  const { slug } = teamData;
  const filterKey = `all_members_${slug}`;
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchedMembers, setSearchedMembers] = useState<any[] | undefined>(
    undefined
  );
  // const [teamMembers, setTeamMembers] = useState<any[]>();
  const [activeTab, setActiveTab] = useState<
    "Members" | "Requests" | "Banned" | "Rejected"
  >("Members");
  const tabs =
    userRole === "admin" || userRole === "owner"
      ? ["Members", "Requests", "Banned", "Rejected"]
      : ["Members"];

  const [
    teamMembers,
    _lastFeedDateInSeconds,
    _hasMore,
    _loadingAllMembers,
    _errorAllMembers,
    fetchTeamMembersData,
    ref,
    inView,
  ] = useCacheDataArrayManager<any>(getTeamMembersBasedOnSlug, filterKey);
  const [memberList, setMemberList] = useState([]);

  function fetchTeamMembers() {
    //for fetch more -> set lastFeedDateInSeconds as number
    return fetchTeamMembersData(
      {
        slug,
        currUserId: currentUser.uid,
        lastFeedDateInSeconds: _lastFeedDateInSeconds || null,
      },
      filterKey
    );
  }

  useEffect(() => {
    if (inView) {
      fetchTeamMembers();
    }
  }, [inView]);
  useEffect(() => {
    if (teamData.slug) {
      fetchTeamMembers();
    }
  }, [teamData.slug]);

  useEffect(() => {
    setMemberList(teamMembers);
  }, [teamMembers]);

  const searchableDebouncedText = useDebounce(searchValue, 250);
  useEffect(() => {
    async function searchMembers() {
      const searchedMembers = await searchTeamMember(
        slug,
        searchableDebouncedText
      );
      setSearchedMembers(searchedMembers);
    }
    if (Boolean(searchableDebouncedText)) {
      searchMembers();
    } else {
      setSearchedMembers(undefined);
    }
  }, [searchableDebouncedText]);

  const teamMembersToRender = searchedMembers || memberList;

  const handleAcceptUser = (user: any) => {
    memberList.push(user);
  };

  const handleUpdateMembersList = (userId: string) => {
    const updatedList = memberList.filter(
      (member: any) => member.uid !== userId
    );
    setMemberList(updatedList);
  };

  // Variables
  const membersTab = activeTab === "Members";
  const requestsTab = activeTab === "Requests";
  const bannedTab = activeTab === "Banned";
  const rejectedTab = activeTab === "Rejected";
  const isCurrUserOwnerOrAdmin =
    teamData.isCurrUserAdmin || teamData.createdBy === currentUser.uid;

  return (
    <div
      className={`single-team__members ${
        isFullPage ? "single-team__members--full" : ""
      }`}
    >
      {/* Badges */}
      {isCurrUserOwnerOrAdmin && (
        <>
          <div className="badge-wrapper">
            {tabs.map((tab) => {
              return (
                <Badge
                  key={tab}
                  onClick={() =>
                    setActiveTab(
                      tab as "Members" | "Requests" | "Banned" | "Rejected"
                    )
                  }
                  variant={tab === activeTab ? "primary" : "secondary"}
                >
                  {tab}
                </Badge>
              );
            })}
          </div>
          <h4>{activeTab}</h4>
        </>
      )}

      {membersTab && (
        <>
          {/* Search */}
          <div className="single-team__search">
            <label htmlFor="searchMember" className="search">
              <input
                type="search"
                id="searchMember"
                placeholder="Search by name, username..."
                className="base-input"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
          </div>
          <SingleTeamSeeAllMembers
            teamMembers={teamMembersToRender}
            teamData={teamData}
            userRole={userRole}
            handleUpdateMembersList={handleUpdateMembersList}
            handleAcceptUser={handleAcceptUser}
          />
        </>
      )}
      {requestsTab && (
        <SingleTeamSeeAllOthers
          userRole={userRole}
          teamData={teamData}
          handleAcceptUser={handleAcceptUser}
          statusType={"pendingRequest"}
        />
      )}
      {bannedTab && (
        <SingleTeamSeeAllOthers
          userRole={userRole}
          teamData={teamData}
          isFullPage={false}
          handleAcceptUser={handleAcceptUser}
          statusType={"banned"}
        />
      )}
      {rejectedTab && (
        <SingleTeamSeeAllOthers
          teamData={teamData}
          userRole={userRole}
          handleAcceptUser={handleAcceptUser}
          statusType={"rejected"}
        />
      )}
    </div>
  );
};
