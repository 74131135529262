
export const NewsOverviewLoaderCard = () => {
  return (
    <div className="news-card placeholder">
      {/* Left side image */}
      <div className="news-card__image wave" />

      {/* Right side content */}
      <div className="news-card__info">
        {/* Badges */}
        <div className="news-card__badges">
          <div className="badge-wrapper">
            {Array.from(Array(4), (e, index) => {
              return (
                <div key={index} className="badge bg-secondary  wave" />
              );
            })}
          </div>
        </div>

        {/* Title */}
        <div className="news-card__title">
          <h2 className="wave" />
        </div>

        {/* Intro */}
        <div className="news-card__excerpt">
          <p  className="wave" />
        </div>

        {/* Meta data  */}
        <div className="news-card__meta">
          <div className="news-card__author">
            {/* Author */}
            <div className="author">
              <div className="author__image wave" />

              <div className="author__name wave" />
            </div>
          </div>

          <div className="news-card__date wave">
            <time />
          </div>
        </div>
      </div>
    </div>
  );
};
