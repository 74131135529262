import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TeamCardComponent } from "../teamCard/TeamCardComponent";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import EditTeamForm from "../settings/EditTeamForm";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import { TeamRightsPermissionsSettings } from "../..";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { ReactComponent as BreadCrumbArrow } from "../../../../assets/actions/chevron-right.svg";
import { TeamNotificationsSettings } from "../settings/TeamNotificationsSettings";
import { TeamAdminsList } from "../settings/TeamAdminsList";
import { editTeamValues } from "../../utils/CreateTeamValues";
// import { ReactComponent as BreadCrumbArrow } from "../../../../assets/actions/chevron-right.svg";

type Props = {
  slug: string;
  team: SingleTeamResponseTypes;
  setSettingsOpen: any;
  userRole: "owner" | "admin" | "random" | "member";
  setUpdateTrigger: any;
};
const SingleTeamSettings: FC<Props> = ({
  slug,
  team,
  setSettingsOpen,
  userRole,
  setUpdateTrigger,
}): JSX.Element => {
  const [teamSettings, setTeamSettings] = useState<any>(editTeamValues);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const isAdmin = userRole === "admin";
  const isOwner = userRole === "owner";
  const hasAdminRights = userRole === "owner" || userRole === "admin";
  const isMember = userRole === "member";
  const isInTeam = userRole !== "random";
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined); // TEMP

  const [selectedTab, setSelectedTab] = useState<
    "info" | "rights" | "notifications" | "admins" | undefined
  >(undefined);

  useEffect(() => {
    // create the preview
    if (teamSettings.logoUrl?.name) {
      const objectUrl = URL.createObjectURL(teamSettings.logoUrl);
      setImgSrc(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
    return () => setImgSrc(undefined);
  }, [teamSettings.logoUrl]);

  if (!isInTeam) {
    setSettingsOpen(false);
    createToast({
      type: "error",
      message: "Permission denied",
    });
  }

  useEffect(() => {
    if (isOwner) {
      setSelectedTab("info");
    }
    if (isAdmin) {
      setSelectedTab("rights");
    }
    if (isMember) {
      setSelectedTab("notifications");
    }
  }, [userRole]);

  if (!team) {
    navigate(`${siteRoutes["teamsAndSlug"]}${slug}`);
  }

  const pages = {
    info: hasAdminRights ? (
      <EditTeamForm
        name={team.name}
        description={team.description}
        games={team.games}
        teamSlug={team.slug}
        teamLogo={team.teamLogo}
        setUpdateTrigger={setUpdateTrigger}
        isEdit
        setSettingsOpen={setSettingsOpen}
        teamSettings={teamSettings}
        setTeamSettings={setTeamSettings}
      />
    ) : undefined,
    rights: hasAdminRights ? (
      <TeamRightsPermissionsSettings
        type={team.type}
        postInFeed={team.postInFeed}
        canLeaveComments={team.canLeaveComments}
        canSendRequest={team.canSendRequest}
        teamSlug={team.slug}
        setUpdateTrigger={setUpdateTrigger}
        userRole={userRole}
      />
    ) : undefined,
    notifications: (
      <TeamNotificationsSettings
        userRole={userRole}
        team={team}
        setUpdateTrigger={setUpdateTrigger}
      />
    ),
    admins: <TeamAdminsList teamSlug={team.slug} userRole={userRole} />,
  };
  return (
    <div className="team-settings">
      <div className="team-settings__header">
        <p onClick={() => setSettingsOpen(false)}>Team</p>
        <BreadCrumbArrow />
        <h1>{selectedTab?.charAt(0).toUpperCase() + selectedTab?.slice(1)}</h1>
      </div>
      <div className="team-settings__content">
        <div className="content__body">
          <ul className="content__tabs">
            {isOwner && (
              <li
                key="singleTeamSettingsInfo"
                aria-hidden
                onClick={() => setSelectedTab("info")}
                className={
                  selectedTab === "info"
                    ? "content__tab content__tab--active "
                    : "content__tab"
                }
              >
                Info
              </li>
            )}

            {hasAdminRights && (
              <li
                key="singleTeamSettingsRights"
                aria-hidden
                onClick={() => setSelectedTab("rights")}
                className={
                  selectedTab === "rights"
                    ? "content__tab content__tab--active"
                    : "content__tab"
                }
              >
                Rights and Permissions
              </li>
            )}

            <li
              key="singleTeamSettingsNotifications"
              aria-hidden
              onClick={() => setSelectedTab("notifications")}
              className={
                selectedTab === "notifications"
                  ? "content__tab content__tab--active"
                  : "content__tab"
              }
            >
              Notifications
            </li>
            {hasAdminRights && (
              <li
                key="singleTeamSettingsAdmins"
                aria-hidden
                onClick={() => setSelectedTab("admins")}
                className={
                  selectedTab === "admins"
                    ? "content__tab content__tab--active"
                    : "content__tab"
                }
              >
                Admins
              </li>
            )}
          </ul>
          {team && (
            <>
              {pages[selectedTab]}

              {/* {selectedTab === "notifications" && <TeamNotificationsSettings />} */}
            </>
          )}
        </div>
        <div className="content__preview">
          <h2>Preview</h2>
          <div>
            <TeamCardComponent
              teamData={{
                teamId: slug,
                isFavorite: false,
                name: teamSettings?.name || team.name,
                slug: slug,
                games: teamSettings?.games || team.games || [],
                teamLogo: imgSrc || team.teamLogo || "",
                firstFourParticipantsLogos: [],
                memberCountCalculated: 0,
                id: undefined,
                type: "public",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTeamSettings;
