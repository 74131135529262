import { FC } from "react";
import { Link } from "react-router-dom";
import { SinglePostDropDownActions } from "./SinglePostDropdownAction";
import { PostData } from "../../types/singlePostsProps";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseUserAvatar from "@/components/BaseUserAvatar";

export const SinglePostInfo: FC<{
  authorUsername: string;
  authorLogo: string;
  authorSlug: string;
  type: string;
  whenWasPosted: any;
  wasEdited: boolean;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthorized: boolean;
  postId: any;
  currUserSlug: string;

  isInModal: boolean;
  postData: PostData;
}> = ({
  authorUsername,
  authorLogo,
  authorSlug,
  type,
  whenWasPosted,
  wasEdited,
  setIsDeleted,
  setIsEditPost,
  isAuthorized,
  postId,
  currUserSlug,
  isInModal,
}) => {
  const textMapping: {
    [key: string]: string;
  } = {
    userPost: "",
    sharedPost: "shared a post",
    sharedChallenge: "shared a challenge",
    sharedGather: "shared a gather",
    sharedTeam: "shared a team",
    sharedNews: "shared a news",
  };
  const currentUser = useCurrentUserProfile((s) => s.user);

  try {
    return (
      <div className="post__info">
        <div className="post__user">
          <div className="post__avatar">
            <BaseUserAvatar logoUrl={authorLogo} userName={authorUsername} />
          </div>
          <div className="post__author">
            <Link className="author__username" to={`/profile/${authorSlug}`}>
              <strong>{authorUsername}</strong> {textMapping[type]}
            </Link>
            <span className="author__timestamp">
              <time dateTime={whenWasPosted?.stringDate}>
                {whenWasPosted?.howLongAgo}
              </time>{" "}
              {wasEdited ? <span>&#9679; Edited</span> : ""}
            </span>
          </div>
        </div>
        {!isInModal ? (
          <SinglePostDropDownActions
            setIsEditPost={setIsEditPost}
            postId={postId}
            belongsToCurrentUser={authorSlug === currUserSlug}
            isAuthorized={isAuthorized}
            currUserSlug={currUserSlug}
            setIsDeleted={setIsDeleted}
          />
        ) : (
          ""
        )}
      </div>
    );
  } catch {
    <></>;
  }
};
