export const getDifferenceInDays = (
  date1: string | Date | number,
  date2: string | Date | number
): number => {
  const date1Date = new Date(date1);
  const date2Date = new Date(date2);
  const difference = date2Date.getTime() - date1Date.getTime();

  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const getDifferenceInTime = (
  date1: string | Date | number,
  date2: string | Date | number
): any => {
  const date1Date = new Date(date1);
  const date2Date = new Date(date2);
  const difference = Math.abs(date1Date.getTime() - date2Date.getTime()) / 1000;
  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const oneMonth = oneDay * 30;
  const oneYear = oneDay * 365;
  const times = {
    years: Math.floor(difference / oneYear),
    months: Math.floor(difference / oneMonth),
    days: Math.floor((difference % oneYear) / oneDay),
    hours: Math.floor((difference % oneDay) / oneHour),
    minutes: Math.floor((difference % oneHour) / oneMinute),
    seconds: Math.floor(difference % oneMinute),
  };
  if (times.years !== 0) {
    return times.years + " Year" + `${times.years > 1 ? "s" : ""}`;
  }
  if (times.months !== 0) {
    return times.months + " month" + `${times.months > 1 ? "s" : ""}`;
  }
  if (times.days !== 0) {
    return times.days + " day" + `${times.days > 1 ? "s" : ""} `;
  }
  if (times.minutes !== 0) {
    return times.minutes + " minute" + `${times.minutes > 1 ? "s" : ""}`;
  }
  if (times.seconds !== 0) {
    return times.seconds + " seconds";
  }
  return null;
};

export const getDateInStringFormat = (timestamp) => {
  const date = new Date(timestamp * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const dateString = `${month} ${day}, ${year}`;
  if (!month) {
    return "";
  }
  return dateString;
};
