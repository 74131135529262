export const awaitPromiseAll = async <T>(
  promises: Promise<T>[],
  isThrow = false,
  toThrow?: Error
) => {
  try {
    const res = await Promise.all(promises);
    return res;
  } catch (err) {
    if (isThrow) {
      if (toThrow) {
        throw toThrow;
      }
      throw err;
    }
    return [];
  }
};
