import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { QrReader } from "react-qr-reader";
import CompletionBadge from "./components/CompletionBadge";
import Checkpoints from "./components/Checkpoints";
import { getTreasureHuntData } from "./helperfunctions/getTreasureHuntData";
import { setTreasureHuntState } from "./helperfunctions/setTreasureHuntState";
import { checkIfCompletedTreasureHunt } from "./helperfunctions/checkIfCompletedTreasureHunt";
import { handleTreasureHuntQrCode } from "./helperfunctions/handleTreasureHuntQrCode";
import { TreasureHuntButtons } from "./components/TreasureHuntButtons/TreasureHuntButtons";
import { codes } from "./consts/codes";
import { createEffects } from "../../../src/utils/sharedHelperFunctions/createEffects";
import confetti from "canvas-confetti";
import { createToast } from "@/utils/toaster/createToast";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { BestgamerTreasureHunt } from "./partnerLayouts/bestgamer/BestgamerTreasureHunt";

export const TreasureHunt = () => {
  const { thid } = useParams();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [hasAllCodes, setHasAllCodes] = useState<boolean>(false);
  const [scoreInProcentage, setScoreInProcentage] = useState<number>(0);
  const [thidToUse, setThidToUse] = useState<string>(thid);
  const [isCameraConnected, setIsCameraConnected] = useState<boolean>(true);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [hasFetchedInit, setHasFetchedInit] = useState<boolean>(false);
  const [checkingProgress, isCheckingProgress] = useState<boolean>(false);
  const [padding, setPadding] = useState(0);

  const navigate = useNavigate();
  const intervalIdRef = useRef(null);
  const [usersCollectedData, setUsersCollectedData] = useState<
    { key: string; value: boolean }[]
  >([] || null);
  const [treasureHuntCodes, setTreasureHuntCodes] = useState<
    {
      code: string;
      codeIndex: number;
      location: string;
    }[]
  >([]);

  if (!currentUser) {
    createToast({
      type: "info",
      message: "User not found",
    });

    return <></>;
  }

  const handleResult = async (result: any) => {
    handleTreasureHuntQrCode(result, hasAllCodes, setOpenCamera, setThidToUse);
  };

  const handlePaddingChange = (newPadding: number) => {
    setPadding(newPadding);
  };

  // GetCurrent users treasure hunt data
  useEffect(() => {
    if (currentUser) {
      getTreasureHuntData(
        currentUser,
        setTreasureHuntCodes,
        setUsersCollectedData
      );
    }
  }, [currentUser]);

  // Check if user has completed whole treasurehunt
  useEffect(() => {
    let hasCodeMissing = false;

    codes.map((code, index) => {
      if (!usersCollectedData[code]) {
        hasCodeMissing = true;
        return;
      } else {
        return false;
      }
    }) as any[];

    setHasAllCodes(hasCodeMissing ? false : true);

    const run = async () => {
      if (hasFetchedInit === false && usersCollectedData.length !== 0) {
        await checkIfCompletedTreasureHunt(
          setScoreInProcentage,
          usersCollectedData,
          scoreInProcentage
        ).then(async (res) => {
          if (thidToUse) {
            await setTreasureHuntState(
              currentUser,
              isCheckingProgress,
              checkingProgress,
              thidToUse,
              usersCollectedData,
              setScoreInProcentage,
              res.score
            );
          }
          setThidToUse("");
          if (thid) {
            navigate("/treasure-hunt");
          }
          setHasFetchedInit(true);
          return;
        });
      } else {
        if (thidToUse && !checkingProgress) {
          await setTreasureHuntState(
            currentUser,
            isCheckingProgress,
            checkingProgress,
            thidToUse,
            usersCollectedData,
            setScoreInProcentage,
            scoreInProcentage
          );
          if (thid) {
            navigate("/treasure-hunt");
          }
          setThidToUse("");
          return;
        }
      }
    };

    if (usersCollectedData) {
      run();
    }
  }, [usersCollectedData, thidToUse]);

  useEffect(() => {
    // handleTreasureHuntEffect(hasAllCodes, intervalIdRef);
    if (hasAllCodes) {
      createEffects({
        effect: "random",
      });
      intervalIdRef.current = setInterval(() => {
        createEffects({
          effect: "random",
        });
      }, 1500); // Adjust the interval duration as needed
    }
    return () => {
      confetti.reset();
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      confetti.reset();
      document.getElementsByTagName("canvas")?.[0]?.remove();
    };
  }, [hasAllCodes]);

  if (brand.brandValue === "BESTGAMER") {
    return (
      <BestgamerTreasureHunt
        scoreInProcentage={scoreInProcentage}
        hasAllCodes={hasAllCodes}
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
        setIsCameraConnected={setIsCameraConnected}
        treasureHuntCodes={treasureHuntCodes}
        usersCollectedData={usersCollectedData}
        handleResult={handleResult}
        isCameraConnected={isCameraConnected}
      />
    );
  }

  return (
    <div className="treasurehunt">
      <div className="treasurehunt__header">
        <CompletionBadge scoreInProcentage={scoreInProcentage} />

        <div className="treasurehunt__content">
          {scoreInProcentage === 100 && (
            <div className="treasurehunt__status">100&#37;</div>
          )}
          <h1>Treasure hunt</h1>
          {hasAllCodes ? (
            <p>
              Congratulations on finishing the Treasure Hunt! Find an Elgiganten
              employee and show the screen to collect your prize.
            </p>
          ) : (
            <p>
              Find and scan all the QR codes in different areas of the store to
              win Dreamhack Coins!
            </p>
          )}
          <TreasureHuntButtons
            openCamera={openCamera}
            setOpenCamera={setOpenCamera}
            hasAllCodes={hasAllCodes}
            setIsCameraConnected={setIsCameraConnected}
            onPaddingChange={handlePaddingChange}
          />
        </div>
      </div>

      <Checkpoints
        treasureHuntCodes={treasureHuntCodes}
        usersCollectedData={usersCollectedData}
      />

      {isCameraConnected && openCamera && (
        <div className="treasurehunt__camera">
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result) => handleResult(result)}
          />
        </div>
      )}
    </div>
  );
};
