import { FC } from "react";
import TrackerImage from "../assets/trackercard-demo.png"
import { Link } from "react-router-dom";

const TrackerCard: FC = (): JSX.Element => {
  return (
    <div className="tracker-card">
      <div className="tracker-card__image">
        <div className="image">
          <div className="image__content">
            <img src={TrackerImage} />
          </div>
        </div>
      </div>
      <div className="tracker-card__content">
        <div className="tracker-card__body">
          <h2>
              Supercharge Your Gaming Experience!
          </h2>
          <p>
              Download our Windows tracker now: unlock your scores and dominate the leaderboards
          </p>
          <div className="tracker-card__counter">
            <div className="counter">
              <div className="counter__item">
                <span className="counter__count">8</span>
                <span className="counter__heading">games</span>
              </div>
              <div className="counter__item">
                <span className="counter__count">20+</span>
                <span className="counter__heading">challenges</span>
              </div>
              <div className="counter__item">
                <span className="counter__count">167</span>
                <span className="counter__heading">criterias</span>
              </div>
            </div>
            <div className="tracker-card__actions">
              <Link
                className="btn btn-primary" 
                to="/tracker">
                Download tracker
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackerCard;