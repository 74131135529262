import firebase from "firebase/app";

const refreshSteamConnection = async () => {
  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("refreshSteamConnection");

  try {
    const result = await func();
    return result.data;
  } catch (error: any) {
    return error.message || "An error occurred. Please try later.";
  }
};

export default refreshSteamConnection;
