import { _CollectionsNamesWithSlug } from "./common.type";
import { firestore } from "@/api/firebase/firebase.config";
import firebase from "firebase";

export async function _createNewDoc(
  // collectionName: _CollectionsNamesWithSlug,
  collectionName: string,
  data: Record<string, any>,
  docId?: string
) {
  return firestore
    .collection(collectionName)
    .doc(docId)
    .set(
      {
        created: firebase.firestore.FieldValue.serverTimestamp(),
        ...data,
      },
      { merge: true }
    );
}
