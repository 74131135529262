import React, { useState, useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SingleTeamUi } from "./SingleTeamUi";
import { PotmTypes } from "../../types/PotmTypes";
import SingleTeamSettings from "./SingleTeamSettings";
import { TeamsLoader } from "@/components/skeletonLoaders/SingleTeamsLoader/TeamsLoader";
import NoTeamFoundError from "../error/NoTeamFoundError";
import { useCommunityData } from "@/hooks/useCommunityData";
import { currentUserTeamMemberRef } from "@/api/queryRealtime/Teams";
import UserIsBannedFromTeam from "../error/UserIsBannedFromTeam";
import { userTeamJunctionStore } from "@/store/team/userTeamJunction.store";
import { useSingleTeamData } from "../hooks/useSingleTeamData";

export const SingleTeamFetcher: FC = (): JSX.Element => {
  const { slug } = useParams();
  const currentUser = useCurrentUserProfile((s) => s.user);

  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [updateFeedTrigger, setFeedUpdateTrigger] = useState<number>(0);

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const {
    teamData,
    loadingTeam,
    fetchTeam,
    teamError,
    teamMembers,
    loadingMembers,
  } = useSingleTeamData(slug);

  const setTeamJunction = userTeamJunctionStore((s) => s.setTeamJunction);
  const userTeamJunc = userTeamJunctionStore((s) => s.getUserTeamJunction);

  // ---------------------FETCHERS----------------------------------------------

  const [potmData, _loadingPotm, errorPotm, fetch_potm] = useCloudFunctionCall<
    PotmTypes | any
  >("getTeamPlayerOfTheMonth", slug);

  const { currUserStatus = "new", generalRole = "random" } = userTeamJunc(slug);

  const [communityData, loading_community, communityError] = useCommunityData();
  // --------------------------------------------------------------------------

  // Reload single team if notification forces it (kicked, accepted etc)
  useEffect(() => {
    const handleLocalStorageUpdate = () => {
      fetchTeam();
    };

    document.addEventListener("reload_singleTeam", handleLocalStorageUpdate);
    return () => {
      document.removeEventListener(
        "reload_singleTeam",
        handleLocalStorageUpdate
      );
    };
  }, []);

  useEffect(() => {
    fetchTeam();
  }, [updateTrigger]);

  useEffect(() => {
    setSettingsOpen(false);
    const fetch = async () => {
      const _slug = encodeURIComponent(decodeURIComponent(slug));

      const currentUserRef = currentUserTeamMemberRef({
        slug: _slug,
        currUserId: currentUser.uid,
      });

      currentUserRef.on("value", (data) => {
        const currUserVal = data.val();
        if (currUserVal) {
          Object.assign(currUserVal, {
            fullName: currUserVal.fullName || "",
            joinedTeamTimestamp: {
              _seconds: currUserVal.joinedTeamTimestamp,
            },
            logoUrl: currUserVal.profileLogoUrl,
            roleScore: undefined,
          });
          //when curr user joins the team -> it should be defined
          currentUserRef.off();
        }
      });
    };
    if (slug) {
      fetch();
    }
  }, [slug]);

  useEffect(() => {
    if (teamData) {
      setTeamJunction(slug, {
        currUserStatus: teamData.currUserStatus,
        generalRole: teamData.generalRole,
      });
    }
  }, [teamData]);

  // Variables
  const userIsBanned = teamData?.currUserStatus === "banned";

  if (teamError) {
    // Error fallback if there's no team found!
    return <NoTeamFoundError />;
  }
  if (loadingTeam) {
    //Loading single Team details
    return <TeamsLoader />;
  }

  if (!teamData && teamError && !loadingTeam) {
    // There's no team data and no error either
    return <NoTeamFoundError />;
  }

  if (userIsBanned) {
    return <UserIsBannedFromTeam />;
  }

  if (settingsOpen) {
    return (
      <SingleTeamSettings
        slug={teamData.slug}
        team={teamData}
        setSettingsOpen={setSettingsOpen}
        userRole={generalRole}
        setUpdateTrigger={setUpdateTrigger}
      />
    );
  }

  return (
    <SingleTeamUi
      isPreview={false}
      userRole={generalRole}
      setUpdateTrigger={setUpdateTrigger}
      setFeedUpdateTrigger={setFeedUpdateTrigger}
      teamData={teamData}
      membersData={teamMembers || []}
      potmData={potmData}
      communityData={communityData}
      setSettingsOpen={setSettingsOpen}
    />
  );
};
