import BaseUserAvatar from "@/components/BaseUserAvatar";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedListType } from "./types/winnersTypes";

export const ListByDayItem: FC<{ data: FormattedListType }> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div key={data.author.userName}>
      <strong
        onClick={() => {
          if (data?.listEventData?.slug) {
            navigate(
              `${siteRoutes["challengesAndSlug"]}${data.listEventData.slug}`
            );
          }
        }}
      >
        {data.listEventData.name}
      </strong>
      <div
        className="item__user"
        onClick={() => {
          if (data?.author?.userSlug) {
            navigate(`${siteRoutes["profileAndSlug"]}${data.author.userSlug}`);
          }
        }}
      >
        <div className="user__avatar">
          <BaseUserAvatar
            userName={data.author.userName}
            logoUrl={data.author.userProfileLogoUrl}
          />
        </div>
        <p>{data.author.userName}</p>
      </div>
    </div>
  );
};
