import { FC } from "react";
import { ReactComponent as IconAlert } from "../../../assets/alert-circle.svg";

const SharedPostFallback: FC = (): JSX.Element => {
  return (
    <div className="post shared-post shared-post__fallback">
        <div className="post__info">
            <div className="post__user">
                <div className="post__avatar">
                <div className="avatar">
                    <div className="avatar__content" />
                </div>
                </div>
                <div className="post__author">
                    <strong>Unavailable</strong>

                    <span className="author__timestamp">
                        Sorry, this content isn't available right now.
                    </span>
                </div>
            </div>
        </div>

        <div className="post__content">
            The shared post might no longer be available. This could either be because it has been deleted by the author, a technical error, or if it has been removed by an admin.
        </div>
    </div>
  );
};

export default SharedPostFallback;