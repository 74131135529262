import React, { FC, useState, useEffect } from "react";
import { Task } from "../types/dailyTasksTypes";
import { Button, Spinner } from "@/components";
import { createToast } from "@/utils/toaster/createToast";
import { ReactComponent as WonIcon } from "../assets/Done.svg";
import { ReactComponent as LoseIcon } from "../assets/Cross.svg";

export const PassiveTask: FC<{
  activeTaskData: Task;
  updateTask: (
    type: "quiz" | "participate",
    answerKey?: string
  ) => Promise<void>;
  loadingUpdate: boolean;
}> = ({ activeTaskData, updateTask, loadingUpdate }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [hasEnded, setHasEnded] = useState<boolean>(false);
  const [hasWon, setHasWon] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (activeTaskData.participated) {
      setHasEnded(true);
    }
    if (activeTaskData.won) {
      setHasWon(activeTaskData.won);
    }
  }, [activeTaskData, loadingUpdate]);

  return (
    <div className="active__task">
      {/* Card */}
      {/* <div> */}
      <div>
        <h4>{activeTaskData.task}</h4>
        {!hasEnded && (
          <ul>
            {Object.entries(activeTaskData.options).map(([key, value]) => {
              return (
                <>
                  <li
                    className={key === selectedAnswer ? "selected" : ""}
                    onClick={() => {
                      setSelectedAnswer(key);
                    }}
                  >
                    <h6>{key}</h6>
                  </li>
                </>
              );
            })}
          </ul>
        )}
        {hasEnded && (
          <div className="task__ended">
            {/* {hasWon === undefined && <div>Waiting for admin to verify</div>} */}
            {hasWon === true && (
              <div>
                <WonIcon /> Correct
              </div>
            )}
            {hasWon === false && (
              <div>
                <LoseIcon />
                Wrong
              </div>
            )}
          </div>
        )}
      </div>

      <div className="task__ended">
        {/* {hasWon === undefined && <div>Waiting for admin to verify</div>} */}
        {activeTaskData.participated && activeTaskData.won && (
          <div>
            <WonIcon /> Correct
          </div>
        )}
        {activeTaskData.participated && !activeTaskData.won && (
          <div>
            <LoseIcon />
            Wrong
          </div>
        )}
      </div>

      <Button
        variant={activeTaskData.participated ? "link" : "primary"}
        customStyles="task__action"
        onClickAction={async () => {
          if (!activeTaskData.participated) {
            await updateTask("quiz", selectedAnswer).then((res: any) => {
              if (res === "Correct!") {
                setHasWon(true);
              }
              if (res === "Wrong!") {
                setHasWon(false);
              }
            });
          } else {
            createToast({
              type: "info",
              message: "Already submitted",
            });
          }
        }}
        disabled={
          (!selectedAnswer && !activeTaskData.participated) || loadingUpdate
        }
      >
        {/* {!loadingUpdate &&
          activeTaskData.participated &&
          "collect reward from lenovo admin"} */}

        {activeTaskData.participated &&
          !activeTaskData.won &&
          "Collect Reward From Lenovo Admin"}

        {!loadingUpdate &&
          !activeTaskData.participated &&
          !activeTaskData.won &&
          "Submit The Answer"}

        {!loadingUpdate &&
          activeTaskData.participated &&
          activeTaskData.won &&
          "collect reward from lenovo admin"}

        {loadingUpdate && <Spinner />}
      </Button>
      {/* </div> */}
    </div>
  );
};
