import { FC, useEffect, useState } from "react";
import { SingleTeamUi } from "../../single/SingleTeamUi";
import { CreateTeamTypes } from "@/features/team/types/CreateTeamTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const CreateTeamStep2: FC<{
  teamValues: CreateTeamTypes;
}> = ({ teamValues }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined); // TEMP

  //   TEMP
  useEffect(() => {
    // create the preview
    if (teamValues.logoUrl) {
      const objectUrl = URL.createObjectURL(teamValues.logoUrl);
      setImgSrc(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
    return () => setImgSrc(undefined);
  }, [teamValues.logoUrl]);

  return (
    <>
      <SingleTeamUi
        membersData={[
          {
            logoUrl: currentUser.profileLogoUrl,
            uid: currentUser.uid,
            slug: currentUser.slug,
            fullName: currentUser.fullName,
            userName: currentUser.userName,
            role: "owner",
            inTeamRole: "owner",
            monthlyPoints: undefined,
            joinedTeamTimestamp: {
              nanoseconds: 0,
              seconds: 0,
            },
          },
        ]}
        communityData={{
          all: {
            totalNumber: 0,
            users: [],
          },
          followers: {
            totalNumber: 0,
            users: [],
          },
          following: {
            totalNumber: 0,
            users: [],
          },
          friends: {
            totalNumber: 0,
            users: [],
          },
        }}
        isPreview={true}
        userRole={"owner"}
        teamData={{
          created: {
            nanoseconds: 0,
            seconds: 0,
          },
          canLeaveComments: "all",
          canSendRequest: "all",
          createdBy: currentUser.uid,
          currentMembersNumber: 1,
          description: teamValues.description,
          games: teamValues.games,
          isCurrUserFavorite: true,
          isCurrUserFollower: true,
          isCurrUserMember: true,
          inTeamRole: "owner",
          name: teamValues.name,
          playerOfTheMonth: "",
          playerOfTheMonthCriteria: "",
          postInFeed: "all",
          slug: teamValues.name!,
          teamLogo: imgSrc!,
          type: teamValues.type,
          colSpan: undefined,
          setModalOpenBestPlayer: false,
          currUserStatus: "accepted",
          userRole: "owner",
          generalRole: "owner",
          isCurrUserAdmin: true,
          canSendInvitation: false,
          notificationSettings: {
            newPostsInFeed: false,
            memberRequests: false,
            newComntsInFeed: false,
            upcomingEvents: false,
            newMembers: false,
          },
          requestedToJoin: false,
          canCurrentUserLeaveComment: false,
          canCurrentUserPostInFeed: false,
          canCurrentUserSendRequest: false,
        }}
      />
    </>
  );
};
