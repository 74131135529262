import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { convertStringToSlug } from "@/utils/sharedHelperFunctions";
import React, { useState, FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CreateGatherStep1 } from "./pages/CreateGatherStep1";
import { CreateGatherStep2 } from "./pages/CreateGatherStep2";
import { createGatherInitialValues } from "../../utils/createGatherValuesAndProps";
import ValidateGatherForm from "../../utils/createGatherValidation";
import { submitGather } from "../../utils/createGather";
import { CreateGatherFooter } from "./CreateGatherFooter";
import { CreateGatherHeader } from "./CreateGatherHeader";

const CreateGatherForm: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<{
    error: string;
    message: string;
  }>();
  const [gatherValues, setGatherValues] = useState(createGatherInitialValues);
  const setNewGatherValue = async (targetName: string, value: any) => {
    if (targetName === "meetType" && value === "offline") {
      setGatherValues((prev) => ({
        ...prev,
        meetTypeSecondary: "",
      }));
    }

    if (error) {
      ValidateGatherForm(gatherValues, step, setError);
      return;
    } else {
      setError(undefined);
    }

    setGatherValues((prev) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const steps = [
    <CreateGatherStep1
      setNewGatherValue={setNewGatherValue}
      gatherValues={gatherValues}
      error={error}
    />,
    <CreateGatherStep2
      setNewGatherValue={setNewGatherValue}
      gatherValues={gatherValues}
      error={error}
    />,
    // <CreateGatherStepPreview gatherValues={gatherValues} />,
  ];

  const handleNextStep = async () => {
    const isValid = await ValidateGatherForm(gatherValues, step, setError);

    if (isValid) {
      if (step === steps.length - 1) {
        const slug = encodeURIComponent(convertStringToSlug(gatherValues.name));
        return submitGather(gatherValues, slug)
          .then(() => {
            navigate(`${siteRoutes["GathersAndSlug"]}${slug}`);
          })
          .catch((err) => {
            navigate(siteRoutes["gathers"]);
          });
      }
      setStep(step + 1);
    }
    return false;
  };
  return (
    <>
      <div className="create-gather">
        {/* HEADER */}
        <CreateGatherHeader
          step={step}
          stepsAmount={steps.length}
          setStep={setStep}
          handleNextStep={handleNextStep}
          name={gatherValues.name}
        />

        {/* CONTENT */}
        <>{steps[step]}</>

        {/* FOOTER */}
        <CreateGatherFooter
          step={step}
          stepsAmount={steps.length}
          setStep={setStep}
          error={error}
          handleNextStep={handleNextStep}
        />
      </div>
    </>
  );
};

export default CreateGatherForm;
