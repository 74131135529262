const mockUsernames = [
  "cyber",
  "rogue",
  "zen",
  "pixel",
  "mystic",
  "neon",
  "turbo",
  "blaze",
  "quantum",
  "nova",
  "retro",
  "cosmic",
  "tech",
  "lunar",
  "fire",
  "shadow",
  "star",
  "galactic",
  "vortex",
  "cypher",
  "dark",
  "chrono",
  "crimson",
  "electro",
  "element",
  "emerald",
  "flux",
  "ghost",
  "hyper",
  "infinity",
  "iron",
  "laser",
  "lunar",
  "magic",
  "phantom",
  "polar",
  "quantum",
  "raptor",
  "reaper",
  "silver",
  "sonic",
  "spectral",
  "storm",
  "sun",
  "ultra",
  "viper",
  "void",
  "warrior",
  "zero",
  "abyss",
  "alpha",
  "blitz",
  "chrono",
  "delta",
  "dynamo",
  "echo",
  "flare",
  "frost",
  "epic",
  "legendary",
  "pro",
  "elite",
  "power",
  "clutch",
  "carry",
  "intense",
  "friendly",
  "toxic",
  "salty",
  "rage",
];

export default mockUsernames;
