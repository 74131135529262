import react, { FC, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";

export const SingleChallengeImage: FC<{
    url: string;
    alt: string;
}> = ({
    url,
    alt
}): JSX.Element => {  
    const [logoError, setLogoError] = useState<boolean>(false);
    useEffect(() => {
      setLogoError(false);
    }, [url]);

    return (
        <div className="image">
            <div className="image__content">
                <Image
                alt={`User-uploaded image for ${alt} challenge`}
                src={Query_shared.imageErrorHandler(
                    url?.includes("iplay.imgix")
                    ? `${
                        url
                        }?${Query_Imgix.getImgixUrlProps({
                        qty: "35",
                        w: "600",
                        h: "400",
                        fit: "crop",
                        })}`
                    : url,
                    logoError,
                    false
                )}
                onError={() => setLogoError(true)}
                />
            </div>
        </div>
    );
};