import React, { FC } from "react";
import { Button } from "@/components";
import { useNavigate } from "react-router-dom";

export const CreateGatherFooter: FC<{
  step: number;
  stepsAmount: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  error: any;
  handleNextStep: () => Promise<false | void>;
}> = ({ step, stepsAmount, setStep, error, handleNextStep }) => {
  const navigate = useNavigate();

  return (
    <div className="create-gather__buttons">
      <div className="create-gather__buttons--layout">
        <Button
          onClickAction={() => {
            if (step === 0) {
              navigate("/gathers");
            } else {
              setStep(step - 1);
            }
          }}
          variant="secondary"
          customStyles={`${step !== stepsAmount - 1 ? "" : "d-none"}`}
        >
          {step === 0 ? "Cancel" : "Back"}
        </Button>

        {step !== stepsAmount - 1 ? (
          <Button
            onClickAction={() => {
              if (!error) {
                handleNextStep();
              }
            }}
            disabled={error}
            variant={`${error ? "primary" : "secondary"}`}
          >
            Next Step
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
