import { singleChallengeAltProps } from "@/features/challenge/utils/singleChallengeProps";
import { FC } from "react";

import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { BestgamerEventHead } from "../../../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventHead/BestgamerEventHead";

export const SingleChallengeBestgamerHead: FC<{
  data: singleChallengeAltProps;
}> = ({ data }) => {
  return (
    <div className="head">
      <div className="head__challenge-image">
        <div>
          <img src={data.logoUrl} alt="" />
        </div>
      </div>

      <BestgamerEventHead
        title={data.game}
        startDate={data.startDate}
        endDate={data.endDate}
        brand={brand.brandValue}
      />
    </div>
  );
};
