import { Button, Spinner } from "@/components";
import { FC } from "react";
import { CreateChallengeFormTypes } from "../../utils/CreateChallengeTypes";

export const CreateChallengeHeader: FC<{
  isEdit: boolean;
  step: number;
  stepsAmount: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  handleNextStep: () => Promise<false | void>;
  values: CreateChallengeFormTypes;
}> = ({
  isEdit,
  step,
  setStep,
  loading,
  stepsAmount,
  values,
  handleNextStep,
}) => {
  return (
    <div className="create-challenge__header">
      {step + 1 !== stepsAmount ? (
        <div className="header__title">
          <h2>{isEdit ? "Edit" : "New"} Challenge</h2>
          <h6>{`Step ${step + 1}/${stepsAmount}`}</h6>
        </div>
      ) : (
        <div className="header__ready">
          {isEdit ? (
            <h3>Is {values.name} ready to be updated?</h3>
          ) : (
            <h3>
              Is <em>{values.name}</em> ready to go live?
            </h3>
          )}
          <div>
            <Button
              variant="secondary"
              onClickAction={() => setStep((s) => s - 1)}
              disabled={loading}
            >
              Edit
            </Button>
            <>
              {loading && (
                <span className="btn btn-loading">
                  <Spinner />
                </span>
              )}
            </>

            {!loading && (
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClickAction={() => handleNextStep()}
              >
                {isEdit ? <>Yes, update</> : <>Yes, activate</>}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
