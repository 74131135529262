import React, { FC } from "react";
import { CurrentTaskData, DailyTasksResponse } from "./types/dailyTasksTypes";
import { TasksSlider } from "./components/TasksSlider";
import { ActiveTask } from "./components/ActiveTask";
import { LockedTask } from "./components/LockedTask";
import { PassiveTask } from "./components/PassiveTask";
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import cardBGImage from "./assets/CardBackground.png";
import { getProgressBarValue } from "./../../../src/features/challenge/helperFunctions/getProgressBarValue";

export const SingleTaskItem: FC<{
  dailyTasksData: DailyTasksResponse;
  updateTask: any;
  loading_update: boolean;
}> = ({ dailyTasksData, updateTask, loading_update }) => {
  return (
    <div>
      {/* Current active task */}
      {(dailyTasksData &&
        dailyTasksData.activeTask &&
        dailyTasksData.activeTask.won &&
        dailyTasksData.activeTask.participated) ||
      (dailyTasksData && dailyTasksData.activeTask === null) ? (
        <h5>Task {dailyTasksData.activeTask?.taskNumber + 1 || 1} UPCOMING</h5>
      ) : (
        <>
          {dailyTasksData && dailyTasksData.activeTask && (
            <h5>Task {dailyTasksData.activeTask?.taskNumber} ACTIVE NOW</h5>
          )}
        </>
      )}
      <section className="tasks__active">
        <img className="tasks__bg" src={cardBGImage} alt="" />

        {/* For active task */}
        {dailyTasksData &&
          dailyTasksData.activeTask &&
          !dailyTasksData.activeTask.won &&
          dailyTasksData.activeTask.type === "active" && (
            <ActiveTask
              activeTaskData={dailyTasksData.activeTask}
              updateTask={updateTask}
              loadingUpdate={loading_update}
            />
          )}

        {/* For locked task */}
        {(dailyTasksData &&
          dailyTasksData.activeTask &&
          dailyTasksData.activeTask?.won &&
          dailyTasksData.activeTask?.participated) ||
        (dailyTasksData && dailyTasksData.activeTask === null) ? (
          <LockedTask />
        ) : (
          ""
        )}

        {/* For passive task */}
        {dailyTasksData &&
          dailyTasksData.activeTask &&
          dailyTasksData.activeTask.type === "passive" && (
            <PassiveTask
              activeTaskData={dailyTasksData.activeTask}
              updateTask={updateTask}
              loadingUpdate={loading_update}
            />
          )}
      </section>

      {dailyTasksData && dailyTasksData.activeTask && (
        <div className="challenge__progress">
          <ProgressBar
            id="challenge-time"
            label={[
              dailyTasksData.activeTask.time,
              dailyTasksData.activeTask.timeRemaining
                ? `Time left: ${Math.floor(
                    dailyTasksData.activeTask.timeRemaining / 60
                  )} minutes`
                : `Starts in: ${Math.floor(
                    dailyTasksData.activeTask.startsIn / 60
                  )} minutes`,
              dailyTasksData.activeTask.taskEndTimeString,
            ]}
            value={getProgressBarValue(
              dailyTasksData.activeTask.startDate,

              dailyTasksData.taskArray[dailyTasksData.activeTask.taskNumber]
                ?.startDate
            )}
            color=""
            // color={
            //   CreatorTypesOptions[data.challengeData.creatorType]
            //     ? CreatorTypesOptions[data.challengeData.creatorType].activeColor
            //     : CreatorTypesOptions.Community.activeColor
            // }
            onOverview={true}
            isPlaceholder={false}
          />
        </div>
      )}
    </div>
  );
};
