import { atom } from "jotai";
import { ChallengeItem } from "../types/props/ChallengeItemProps";
import firebase from "firebase/app";
import "firebase/firestore";

export const ChallengesItemsAtoms = atom<ChallengeItem[]>([]);

export const OngoingChallengesAtoms = atom<ChallengeItem[]>([]);
export const FinishedChallengesAtoms = atom<ChallengeItem[]>([]);

export const lastOngoingDocAtom =
  atom<firebase.firestore.QueryDocumentSnapshot>(null);
export const lastFinishedDocAtom =
  atom<firebase.firestore.QueryDocumentSnapshot>(null);

export const lastDiscoverDocAtom =
  atom<firebase.firestore.QueryDocumentSnapshot>(null);
export const lastMineDocAtom =
  atom<firebase.firestore.QueryDocumentSnapshot>(null);

export const ChallengesStatusAtoms = atom("ongoing");

export const SingleChallengeAtom = atom<ChallengeItem | null>(null);
