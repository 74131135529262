import React, { FC, useState, useRef, useEffect } from "react";
import { ReactComponent as Close } from "../../../../assets/actions/close.svg";
import { ReactComponent as OptionDots } from "../../../../assets/actions/Options-dots.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { leaveTeam } from "../../helperFunctions/leaveTeam";
import { createToast } from "@/utils/toaster/createToast";
import { DropDown } from "@/components";
import firebase from "firebase/app";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { userJunctionHandler } from "../../helperFunctions/userJunctionHandler";

const SingleTeamMemberOptions: FC<{
  userRole: any;
  user: any;
  handleUpdateMembersList: (uid: string) => void;
  teamName: string;
  teamData: SingleTeamResponseTypes;
}> = (props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [localUserRole, setLocalUserRole] = useState<string>(
    props.user?.generalRole === "admin" ? "admin" : "member"
  );
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const handleSetAdminRole = async (state: boolean) => {
    if (
      props.user &&
      props.teamName &&
      (props.userRole === "owner" || props.userRole === "admin")
    ) {
      setLocalUserRole(state ? "admin" : "member");

      QueryDB.updateDoc(
        "userTeamJunction",
        `${props.user.uid}_${props.teamData.slug}`,
        {
          generalRole: state ? "admin" : "member",
        }
      )
        .then(() => {
          createToast({
            type: state ? "success" : "info",
            message: state
              ? `Added admin permissions to ${props.user.userName}!`
              : `Removed admin permissions from ${props.user.userName}!`,
          });
          QueryDB_Notifications.createNewNotification(
            currentUser.uid,
            props.user.uid,
            props.teamData.slug,
            "teams-admin-rights",
            `${state ? "Gave you" : "Removed your"} ${
              notificationsTextMapping["teams-admin-rights"]
            }`
          );
        })
        .catch((err) => {
          createToast({
            type: state ? "success" : "info",
            message: "Failed to change admin rights, please try again later",
          });
          setLocalUserRole(state ? "member" : "admin");
        });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  // Variables
  const hasAdminRights =
    props.teamData.isCurrUserAdmin ||
    props.teamData.createdBy === currentUser.uid;

  const memberIsNotAdmin = localUserRole !== "admin";
  if (hasAdminRights) {
    return (
      <div className="team-members__options">
        <DropDown logo={<OptionDots />}>
          {localUserRole !== "admin" ? (
            <>
              {localUserRole !== "admin" ? (
                <a
                  className="dropdown__option"
                  aria-hidden
                  onClick={() => {
                    handleSetAdminRole(true);
                  }}
                >
                  Give admin permissions
                </a>
              ) : (
                <a
                  className="dropdown__option"
                  aria-hidden
                  onClick={() => {
                    handleSetAdminRole(false);
                  }}
                >
                  Remove admin permissions
                </a>
              )}
              <a
                className="dropdown__option danger"
                aria-hidden
                onClick={() => {
                  leaveTeam(
                    "userTeamJunction",
                    props.user.uid,
                    props.teamData.slug,
                    props.teamData.name,
                    false
                  )
                    .then(() => {
                      createToast({
                        type: "success",
                        message: `Removed ${props.user.userName} from team ${props.teamName}`,
                      });
                      QueryDB_Notifications.createNewNotification(
                        currentUser.uid,
                        props.user.uid,
                        props.teamData.slug,
                        "teams-removed-by-admin",
                        `${notificationsTextMapping["teams-removed-by-admin"]} <strong>${props.teamData.name}</strong>`
                      );
                    })
                    .then(() => {
                      setOpen(false);
                      props.handleUpdateMembersList(props.user.uid);
                    })
                    .catch(() => {
                      return createToast({
                        type: "error",
                        message: `Something went wrong, please try again later.`,
                      });
                    });
                }}
              >
                Remove from team
              </a>
              <a
                className="dropdown__option danger"
                aria-hidden
                onClick={() => {
                  userJunctionHandler(
                    "banned",
                    currentUser,
                    "",
                    `${props.user.uid}_${props.teamData.slug}`,
                    "banned",
                    props.teamData.slug
                  )
                    .then(() => {
                      createToast({
                        type: "success",
                        message: `Banned ${props.user.userName} from ${props.teamName}`,
                      });
                    })
                    .then(() => {
                      props.handleUpdateMembersList(props.user.uid);
                    })
                    .catch(() => {
                      return createToast({
                        type: "error",
                        message: `Something went wrong, please try again later.`,
                      });
                    });
                }}
              >
                Ban from team
              </a>
            </>
          ) : (
            <a
              className="dropdown__option"
              aria-hidden
              onClick={() => {
                handleSetAdminRole(false);
              }}
            >
              Remove admin permissions
            </a>
          )}
        </DropDown>
      </div>
    );
  }

  return <div className="team-members__options" />;
};

export default SingleTeamMemberOptions;
