import React, { FC } from "react";
import { Author, singleNewsOverviewTypes } from "../../types/NewsTypes";
import { SingleNewsHeading } from "./SingleNewsHeading";
import { SingleNewsActions } from "./SingleNewsActions";
import { SingleNewsExtra } from "./SingleNewsExtra";
import { SingleNewsFeed } from "./SingleNewsFeed";
import ReactMarkdown from "react-markdown";
import { SingleNewsMeta } from "./SingleNewsMeta";
import BaseImage from "@/components/BaseImage/BaseImage";

type Props = {
  data: singleNewsOverviewTypes & {
    author: Author;
  };
};
export const SingleNewsUI: FC<Props> = ({ data }): JSX.Element => {
  return (
    <div className="article">
      <div className="article__head align-full">
        <div className="head">
          <div className="head__image">
            <BaseImage logoUrl={data.imageURL} aspectRatio="16x9" />
          </div>
          <SingleNewsHeading isPlaceholder={false} data={data} />
        </div>
      </div>
      <div className="article__grid">
        {/* page__actions */}
        <SingleNewsActions data={data} />

        <div className="article__content">
          {/* Author and date */}
          <SingleNewsMeta data={data} />

          <div className="article__main">
            <ReactMarkdown>{data.content}</ReactMarkdown>
            {/* <Editor editorState={editorState} onChange={undefined} readOnly /> */}
          </div>

          {/* page__comments */}
          <h2>Discussion</h2>
        </div>

        {/* page__extra */}
        <SingleNewsExtra data={data} />
      </div>
      <div className="article__grid article__grid--secondary">
        <div />
        <div className="article__content">
          <SingleNewsFeed />
        </div>
      </div>
    </div>
  );
};
