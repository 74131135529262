import firebase from "firebase";
import { brand } from "../utils/brandVariables/brandVariables";
import { useEffect, useState } from "react";
export const useBestGamerChallenges = ({
  gamesToPick,
}: {
  gamesToPick: string[];
}) => {
  const [challenges, setChallenges] = useState<any>();
  const [loading, setLoading] = useState<boolean>();

  const brandArray = brand?.subClientsForOverviews
    ? [...brand?.subClientsForOverviews, brand.brandValue]
    : [brand.brandValue];

  const initQuery = firebase
    .firestore()
    .collection("challenges")
    .where("status", "==", "ongoing")
    .where("client", "in", brandArray);

  const fetch = async () => {
    setLoading(true);
    const response = await initQuery.get();
    const formattedResponse = response?.docs.map((doc) => doc.data());

    const challengesObj = gamesToPick.reduce((acc, game) => {
      const gameData = formattedResponse.find((doc) => doc.gameType === game);
      acc[game] = {
        data: gameData,
        exists: !!gameData,
      };
      return acc;
    }, {});

    setLoading(false);
    setChallenges(challengesObj);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    challenges,
    loading,
  } as const;
};
