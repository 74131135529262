import { siteRoutes } from "../../../routes/siteRoutesList";
import { ReactComponent as PartnerLogo } from "../../../../branchAndBrandSpecific/utils/brandVariables/assets/bestgamer/partnerlogo.svg";


export const getBestGamerChallengeRoutes = ({ challenges }) => {
  return [
    challenges?.subwaysurfers?.exists && {
      title: "Subway surfers",
      link: `${siteRoutes["challengesAndSlug"]}${challenges?.subwaysurfers?.data?.slug}`,
      logo: PartnerLogo,
      game: "subwaysurfers", // Must match name used in scss (theme-bestgamer.scss)
    },
    challenges?.fortnite?.exists && {
      title: "Fortnite",
      link: `${siteRoutes["challengesAndSlug"]}${challenges?.fortnite?.data?.slug}`,
      logo: PartnerLogo,
      game: "fortnite", // Must match name used in scss (theme-bestgamer.scss)
    },
    challenges?.fallguys?.exists && {
      title: "Fallguys",
      link: `${siteRoutes["challengesAndSlug"]}${challenges?.fallguys?.data?.slug}`,
      logo: PartnerLogo,
      game: "fallguys", // Must match name used in scss (theme-bestgamer.scss)
    },
    challenges?.f12023?.exists && {
      title: "Racing",
      link: `${siteRoutes["challengesAndSlug"]}${challenges?.f12023?.data?.slug}`,
      logo: PartnerLogo,
      game: "racing", // Must match name used in scss (theme-bestgamer.scss)
    },
  ].filter(Boolean);
};
