import firebase from "firebase/app";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const getTreasureHuntData = (
  currentUser,
  setTreasureHuntCodes,
  setUsersCollectedData
) => {
  const dbRef = firebase.database();

  dbRef
    .ref(`treasure_hunt/${brand.brandValue}/testing/codes/`)
    .on("value", async (snapshot) => {
      if (snapshot.val()) {
        const keys = Object.entries(snapshot.val()).reduce(
          (acc, [key, value]) => {
            if (key) {
              // @ts-ignore

              acc[key] = { ...value };
              return acc;
            }
          },
          {} as Record<string, { value: boolean }>
        );
        // @ts-ignore

        setTreasureHuntCodes(keys);
      }
      return;
    });

  dbRef
    .ref(`treasure_hunt/${brand.brandValue}/testing/users/${currentUser.uid}`)
    .on("value", async (snapshot) => {
      if (snapshot.val()) {
        setUsersCollectedData(snapshot.val());
      }
      return;
    });
};
