import { FC, useEffect } from "react";
import { Countdown } from "@/components/Countdown";
import { BestgamerEventPartner } from "./../../components/bestgamer/bestgamerPartner/BestgamerEventPartner";
import { EventActivityContent } from "./components/EventActivity.content";
import { useParams } from "react-router-dom";
import { EventActivityHead } from "./components/EventActivity.Head";
import productImage from "../../../src/features/challenge/components/single/layouts/bestgamer/assets/jblQuantum.png";
import productImageBackground from "../../../src/features/challenge/components/single/layouts/bestgamer/assets/temp_bg.jpg";
import { activityContent } from "./consts/activityContent";
import { PageDoesntExist } from "@/pageDoesntExist";
import useViewportWidth from "@/hooks/useViewportWidth";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";

export const EventActivity: FC<{}> = ({}) => {
  const { slug } = useParams();
  const isMobile = useViewportWidth(992);
  const setCurrentClient = useSetAtom(clientAtom);

  useEffect(() => {
    document.body.classList.add("activity-event");
    setCurrentClient("PARTNER");

    return () => {
      document.body.classList.remove("activity-event");
      setCurrentClient("");

    };
  }, [isMobile]);

  const content = activityContent?.[slug];

  if (!content) {
    return <PageDoesntExist />;
  }
  return (
    <div
      className="bestgamer event-activity game-activity">
      {/* Treasurehunt grid */}
      <section className="event-activity__head">
        <EventActivityHead slug={slug} />
      </section>

      {isMobile && (
        <section className="event-activity__countdown">
          <div className="activity__countdown">
            <Countdown
              type="activity"
              startDate={{
                seconds: 1720637614000,
                nanoseconds: 1720637614000,
              }}
              endDate={{
                seconds: 1720637615000,
                nanoseconds: 1720637614000,
              }}
              heading={false}
            />
          </div>
        </section>
      )}

      <section className="event-activity__content">
        <EventActivityContent eventId={slug} />
      </section>

      <section className="event-activity__partner">
          <BestgamerEventPartner
            partner_logo={"partner logo"}
            product_name={"JBL QUANTUM 910"}
            product_link={""}
            product_image={productImage}
            product_background={productImageBackground}
          />
      </section>
    </div>
  );
};
