import { FC, useEffect } from "react";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { BestgamerLandingHead } from "./components/BestgamerLanding.head";
import { BestgamerLandingAbout } from "./components/BestgamerLanding.about";
import { BestgameLandingNewsLetter } from "./components/BestgamerLanding.newsletter";
import FooterImage from "./assets/footerImage.png";

export const BestgamerLandingUI: FC<{}> = ({}) => {
  useEffect(() => {
    document.body.classList.add("bg-landing");
    
    return () => {
      document.body.classList.remove("bg-landing");
    };
  }, []);

  return (
    <div className="bg-landing">
      <section className="bg-landing__head">
        <BestgamerLandingHead />
      </section>

      <section className="bg-landing__about">
        <BestgamerLandingAbout />
      </section>

      <section className="bg-landing__newsletter">
        <BestgameLandingNewsLetter />
      </section>

      <div className="bg-landing__footer">
        <div>
          <img src={FooterImage} alt="" />
        </div>
      </div>
    </div>
  );
};
