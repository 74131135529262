import React, { useState, FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import Consent from "@/components/Consent/Consent";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import LandingHeader from "./components/Header";
import LandingHero from "./components/Hero";
import LandingHeading from "./components/Heading";
import LandingCallToAction from "./components/CallToAction";
import ChallengesImage from "./assets/challenges.png";
import TrackerImage from "./assets/tracker.png";
import SocialImage from "./assets/social.png";
import WOGImage from "./assets/wog.png";
import LandingFooter from "./components/Footer";
import i18next from "i18next";
import { BestgamerLandingUI } from "./layouts/bestgamer/BestgamerLanding.ui";

export const Landing: FC = (): JSX.Element => {
  const [activeLang, setActiveLang] = useState<string>(
    localStorage.getItem("lang") || "eng"
  );
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    function updateHeaderHeight() {
      if (headerRef.current) {
        const height = headerRef.current.clientHeight;
        setHeaderHeight(height);
      }
    }

    updateHeaderHeight();

    window.onresize = updateHeaderHeight;

    return () => {
      window.onresize = null;
    };
  }, [headerRef]);

  useEffect(() => {
    if (currentUser) {
      navigate(siteRoutes["dashboard"]);
    }
  }, [currentUser]);

  // Page Document Title
  useDocumentTitle(`Welcome to World of Gamers ${brand.siteTitle}`);

  if (brand.brandValue === "BESTGAMER") {
    return (
      // <div className="landing">
      // <div className="container">
      <BestgamerLandingUI />
      // </div>
      // </div>
    );
  }

  return (
    <div className="landing">
      <div className="container">
        <LandingHeader Ref={headerRef} />
        <LandingHero headerHeight={headerHeight} />
        <LandingHeading />

        <LandingCallToAction
          title={i18next.t("landing.cta.challenges.headline")}
          desc={i18next.t("landing.cta.challenges.description")}
          image={ChallengesImage}
          link="/signup"
          label={i18next.t("landing.cta.challenges.button")}
          float="left"
          arrow="1"
        />

        <LandingCallToAction
          title={i18next.t("landing.cta.experience.headline")}
          desc={i18next.t("landing.cta.experience.description")}
          image={TrackerImage}
          link="/signup"
          label={i18next.t("landing.cta.experience.button")}
          float="right"
          arrow="2"
        />

        <LandingCallToAction
          title={i18next.t("landing.cta.social.headline")}
          desc={i18next.t("landing.cta.social.description")}
          image={SocialImage}
          link="/signup"
          label={i18next.t("landing.cta.social.button")}
          float="left"
          arrow="3"
        />

        <LandingCallToAction
          title={i18next.t("landing.cta.about.headline")}
          desc={i18next.t("landing.cta.about.description")}
          image={WOGImage}
          link="/signup"
          label={i18next.t("landing.cta.about.button")}
          float="right"
        />
      </div>
      <LandingFooter />
      <Consent layout="default" />
    </div>
  );
};
