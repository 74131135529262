import { ChallengeOverviewResponse } from "@/features/challenge/types/cloudFunctions/ChallengeOverview";
import { atom } from "jotai";

export const clientAtom = atom("");
export const authCredentialsAtom = atom({
  email: "",
  password: "",
});

export const challengesOverviewDataAtom = atom<any>("");
