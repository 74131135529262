import React, { FC, useEffect, useState } from "react";
import { BestgamerTreasureHuntHead } from "./components/BestgamerTreasurehunt.Head";
import { BestgamerTreasureHuntScore } from "./components/BestgamerTreasureHunt.Score";
import { BestgamerEventPartner } from "../../../../components/bestgamer/bestgamerPartner/BestgamerEventPartner";

import productImage from "../../../../../src/features/challenge/components/single/layouts/bestgamer/assets/jblQuantum.png";
import productImageBackground from "../../../../../src/features/challenge/components/single/layouts/bestgamer/assets/temp_bg.jpg";
import { Countdown } from "@/components/Countdown";
import { EventDetails } from "./../../../../components/bestgamer/bestgamerEventDetails/index";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";
import useViewportWidth from "@/hooks/useViewportWidth";

export const BestgamerTreasureHunt: FC<{
  scoreInProcentage: any;
  hasAllCodes: any;
  openCamera: any;
  setOpenCamera: any;
  setIsCameraConnected: any;
  treasureHuntCodes: {
    code: string;
    codeIndex: number;
    location: string;
  }[];
  usersCollectedData: {
    key: string;
    value: boolean;
  }[];
  handleResult: any;
  isCameraConnected: any;
}> = ({
  scoreInProcentage,
  hasAllCodes,
  openCamera,
  setOpenCamera,
  setIsCameraConnected,
  treasureHuntCodes,
  usersCollectedData,
  handleResult,
  isCameraConnected,
}) => {
  const setCurrentClient = useSetAtom(clientAtom);
  
  useEffect(() => {
    document.body.classList.add("treasurehunt-bg");
    setCurrentClient("YOUSEE");
    
    return () => {
      document.body.classList.remove("treasurehunt-bg");
      setCurrentClient("");
    };
  }, []);

  const isMobile = useViewportWidth(992);

  return (
    <div className="bestgamer bg-treasurehunt game-treasurehunt">
      {/* Treasurehunt grid */}
      <section className="bg-treasurehunt__head">
        <BestgamerTreasureHuntHead isMobile={isMobile} />
      </section>

      {isMobile && (
        <section className="bg-treasurehunt__countdown">
          <div className="activity__countdown">
            <Countdown
              type="challenge"
              startDate={{
                seconds: 1720637614000,
                nanoseconds: 1720637614000,
              }}
              endDate={{
                seconds: 1720637615000,
                nanoseconds: 1720637614000,
              }}
              heading={false}
            />
          </div>
        </section>
      )}

      <section className="bg-treasurehunt__score">
        <BestgamerTreasureHuntScore
          scoreInProcentage={scoreInProcentage}
          hasAllCodes={hasAllCodes}
          openCamera={openCamera}
          setOpenCamera={setOpenCamera}
          setIsCameraConnected={setIsCameraConnected}
          treasureHuntCodes={treasureHuntCodes}
          usersCollectedData={usersCollectedData}
          handleResult={handleResult}
          isCameraConnected={isCameraConnected}
        />
      </section>

      {/* Description */}
      <section className="bg-treasurehunt__description">
        <EventDetails
          goal={
            "To complete the treasure hunt and get your prize you need to find all 10 treasure locations in the Best Gamer area. Read clues on the treasure hunt cards to understand where to look for the next point. All the locations have a sticker with QR code that you need to scan. Get your point and start looking for the next treasure location!"
          }
          description={""}
          game={"Treasure hunt"}
        />
      </section>

      {/* Prize */}
      <section className="bg-treasurehunt__partner">
        <BestgamerEventPartner
          partner_logo={"partner logo"}
          product_name={"JBL QUANTUM 910"}
          product_link={""}
          product_image={productImage}
          product_background={productImageBackground}
        />
      </section>
    </div>
  );
};
