import firebase from "firebase/app";

const checkIfPubgUsernameExist = async (username: string, platform: string) => {
  if (!username || !platform) return false;

  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("isPubgAccountExists");

  try {
    const result = await func({ username, platform });

    return !!result.data;
  } catch (error: any) {
    return error.message || "An error occurred. Please try later.";
  }
};

export default checkIfPubgUsernameExist;
