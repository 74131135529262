import { Button } from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export default function UserIsBannedFromTeam() {
  const { slug } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <h1>Banned</h1>
      <p className="invalid-feedback">You have been banned from this team.</p>
      <Button
        variant="outline-primary"
        customStyles="active"
        onClickAction={() => navigate(siteRoutes["teams"])}
      >
        Back to teams
      </Button>
    </div>
  );
}
