import { FC } from "react";
import logo from "@/assets/placeholders/placeholder.png";

const FeedLoader: FC = (): JSX.Element => {
  return (
    <div className="profile-Placeholder__field">
      <div className="profile-Placeholder__tab">
        <p className="custom-placeholder wave">placeholder</p>
      </div>
      <div className="profile-Placeholder__feed">
        <div className="profile-Placeholder__logo">
          <img src={logo} className="custom-placeholder wave" />
          <div>
            <p className="custom-placeholder wave">placeholderrrr</p>
            <span className="custom-placeholder wave">place</span>
          </div>
        </div>

        <div className="profile-Placeholder__post">
          <img src={logo} className="custom-placeholder wave" />
        </div>
        <div className="profile-Placeholder__icons-line" />
      </div>
    </div>
  );
};
export default FeedLoader;
