import { useEffect, useState } from "react";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import TextInput from "../TextInput/TextInput";
import { Button } from "../Button";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { Spinner } from "../Spinner";
import { BaseTextInput } from "../BaseTextInput/BaseTextInput";
import { handleLogout } from "@/utils/sharedHelperFunctions";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import i18next from "i18next";

export const ForceCredentialPopup = ({
  popupType,
}: {
  popupType?: "email" | "phone";
}) => {
  const [credential, setCredential] = useState<string>("");
  const [termsState, setTermsState] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  useEffect(() => {
    blockScroll();
  }, []);

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(credential);
  const isPhoneValid =
    /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/.test(
      credential
    );
  const windowItem = document.querySelector(".forcedPopup__content");
  const fadedWindowItem = document.querySelector(".forcedPopup__faded");
  const typeToUse = brand.event.forcedCredentialPopup || popupType;

  if (!typeToUse) {
    return;
  }

  const credentialType = {
    email: {
      placeholder: i18next.t("auth.popup.credential.email.placeholder"),
      id: "forced-email",
      value: typeToUse,
      label: i18next.t("auth.popup.credential.email.label"),
      isValid: isEmailValid,
    },
    phone: {
      placeholder: i18next.t("auth.popup.credential.phone.placeholder"),
      id: "forced-phone",
      value: typeToUse,
      label: i18next.t("auth.popup.credential.phone.label"),
      isValid: isPhoneValid,
    },
  };

  const handleNextStep = async () => {
    setIsUpdating(true);
    let userData = currentUser;

    if (typeToUse === "email") {
      userData.email = credential;
      userData.emailAddress = credential;

      await QueryDB.updateDoc("profiles", currentUser.uid, {
        email: credential,
        emailAddress: credential,
        termsAccepted: termsState,
      }).then(() => {
        windowItem.classList.add("closed");
        fadedWindowItem.remove();
        setTimeout(() => {
          allowScroll();
          windowItem.remove();
        }, 1000);
        setProfile(userData);
        localStorage.removeItem("cameFromQR");
      });
    }

    if (typeToUse === "phone") {
      userData.phoneNumber = credential;

      await QueryDB.updateDoc("profiles", currentUser.uid, {
        phoneNumber: credential,
        termsAccepted: termsState,
      }).then(() => {
        windowItem.classList.add("closed");
        fadedWindowItem.remove();
        setProfile(userData);
        localStorage.removeItem("cameFromQR");
      });
    }
    setIsUpdating(false);
  };

  const credentialToUse = credentialType[typeToUse];
  return (
    <div className="forcedPopup">
      <section className="forcedPopup__faded" />
      <section className="forcedPopup__content">
        <div className="content">
          <h2>{i18next.t("auth.popup.headline")}</h2>
          <p>
            {i18next.t("auth.popup.description", {
              credentialToUse: credentialToUse.value,
            })}
          </p>
          <TextInput
            label={credentialToUse.label}
            id={credentialToUse.id}
            type="text"
            value={credential}
            placeholder={credentialToUse.placeholder}
            onChange={(e: any) => setCredential(e.target.value)}
            name={credentialToUse.value}
            hasUploading={false}
          />
          <BaseTextInput
            label={i18next.t("auth.popup.terms")}
            textType="checkmark"
            value={termsState}
            placeholder=""
            setSingleState={() => {
              setTermsState(!termsState);
            }}
            error={undefined}
          />
          <div className="content__actions">
            <Button
              variant={credentialToUse.isValid ? "primary" : "secondary"}
              onClickAction={() => {
                if (credentialToUse.isValid) {
                  handleNextStep();
                }
              }}
              disabled={isUpdating || !credentialToUse.isValid}
            >
              {isUpdating ? <Spinner /> : i18next.t("auth.popup.btn")}
            </Button>
          </div>
          <div
            className="content__alt"
            onClick={async () => {
              await handleLogout(setProfile);
              navigate(siteRoutes["login"]);
            }}
          >
            {i18next.t("auth.popup.logout")}
          </div>
        </div>
      </section>
    </div>
  );
};
