import React, { FC, useEffect } from "react";
import { Lootbox } from "./components/Lootbox";
import { DailyTasksResponse } from "./types/dailyTasksTypes";
import useCloudFunctionCall from "./../../../src/hooks/useCloudFunctionCall";
import { Leaderboard } from "./../../../src/features/challenge/components/leaderboard/Leaderboard";

export const DailyTasksLeaderboard: FC<{}> = ({}) => {
  const [dailyTasksData, loading, error, fetch_dailyTasks] =
    useCloudFunctionCall<DailyTasksResponse>("getListOfDailyTasks", "");

  const isFullPage = window.location.pathname.includes(
    "daily-tasks-leaderboard-full"
  );

  useEffect(() => {
    const ellFade = document.querySelector(".leaderboard__main-blur-line");
    const ellTabs = document.querySelector(".leaderboard__tabs");

    if (ellFade) {
      ellFade.remove();
    }
    if (ellTabs) {
      ellTabs.remove();
    }
    const fetch = async () => {
      await fetch_dailyTasks({});
    };
    fetch();
  }, []);

  return (
    <div className={`dailytasksleaderboard ${isFullPage ? "full" : ""}`}>
      <div className="dailytasksleaderboard__grid">
        <div
          className={`${
            isFullPage
              ? "dailytasksleaderboard__leaderboard-full"
              : "dailytasksleaderboard__leaderboard"
          }`}
        >
          <Leaderboard
            slug={`fairplay-ranking-fortnite`}
            color={undefined}
            isPreview={false}
            status={dailyTasksData?.activeTask ? "ongoing" : "upcoming"}
            game=""
          />
        </div>
        {/* ) : (
          <Spinner />
        )} */}
      </div>
    </div>
  );
};
