import React, { memo } from "react";

const ProgressBar: React.FunctionComponent<{
  value: string | number;
  id: string;
  label: string[];
  color: string;
  onOverview: boolean;
  isPlaceholder: boolean;
}> = ({ value, id, label, color, onOverview, isPlaceholder }) => {
  const style = `${
    isPlaceholder
      ? "placeholder placeholder-wave progress-bar--placeholder"
      : ""
  }`;
  return (
    <>
      <label htmlFor={id}>
        <span className={style}>{label[0]}</span>
        <span className={style}>{label[1]}</span>
        <span className={style}>{label[2]}</span>
      </label>
      <progress
        value={isPlaceholder ? "" : value}
        max="100"
        id={id}
        className={`${color} ${
          onOverview ? "challenges_overview" : "singel_challenge_overview"
        }`}
      >
        {value}%
      </progress>
    </>
  );
};

export default memo(ProgressBar);
