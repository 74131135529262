import { _createNewDoc } from "./_createNewDoc";
import { _updateFirebaseDoc } from "./_updateFirebaseDoc";
import { _validateSlugBasedOnCollectionName } from "./_validateSlugBasedOnCollectionName";
import { _CollectionsNamesWithSlug } from "./common.type";
import { _deleteFirebaseDoc } from "./_deleteFirebaseDoc";
import { _getSingleFirebaseDoc } from "./_getSingleFirebaseDoc";
import { _createJunction } from "./_createJunction";
import { _createUserProfileJunction } from "./_createUserProfileJunction";
import { _deleteJunction } from "./_deleteJunction";

export default class QueryDB {
  constructor() {}

  static updateDoc = _updateFirebaseDoc;
  static createNewDoc = _createNewDoc;
  static deleteDoc = _deleteFirebaseDoc;
  static getSingleDoc = _getSingleFirebaseDoc;
  static createJunction = _createJunction;
  static deleteJunction = _deleteJunction;
  static createUserProfileJunction = _createUserProfileJunction;
  static async validateSlug(
    collectionName: _CollectionsNamesWithSlug,
    slug: string
  ) {
    return await _validateSlugBasedOnCollectionName(collectionName, slug);
  }
}
