import QueryDB from "@/api/queryDB/common";
import { Profile } from "@/models/database/profiles/profile.model";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";
import { Query_Imgix } from "./../../../api/imgix/index";
import { CreateTeamTypes } from "../types/CreateTeamTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

type Team = {
  createdBy: string;
  currentMembersNumber: number;
  description?: string;
  games: string[];
  name: string;
  slug: string;
  teamLogo: string;
  type: string;
  playerOfTheMonth: string;
  playerOfTheMonthCriteria: string;
  canSendRequest: "all" | "friends" | "friendsFriends" | "no";
  postInFeed: "all" | "members" | "admins";
  canLeaveComments: "all" | "members" | "admins";
  client: string;
};

export const createOrUpdateTeams = async (
  values: CreateTeamTypes,
  slug: string,
  currentUser: Profile,
  isEdit: boolean
) => {
  let logoImage: string | null = values?.logoUrl;
  if (!currentUser) {
    return;
  }

  if (values?.logoUrl?.name) {
    logoImage = await Query_Imgix.convertImageToImgIX(
      values?.logoUrl,
      `team/${slug}`
    );
  }

  const team: Team = {
    createdBy: currentUser.uid,
    currentMembersNumber: 0,
    description: values.description,
    games: values.games,
    name: values.name,
    slug,
    teamLogo: logoImage,
    type: values.type,
    playerOfTheMonth: "",
    playerOfTheMonthCriteria: "",
    canSendRequest: "all",
    postInFeed: "all",
    canLeaveComments: "all",
    client: brand.brandValue,
  };

  if (isEdit) {
    await QueryDB.updateDoc("teams", slug, {
      name: values.name,
      teamLogo: logoImage,
      games: values.games,
      description: values.description,
    }).catch((err) => {
      return createToast({
        type: "error",
        message: "Failed to update team",
      });
    });
  }
  if (!isEdit) {
    // Create team
    try {
      await QueryDB.createNewDoc("teams", team, slug);
    } catch (error) {
      throw new Error("Failed to create team");
    }

    // Join current user into team
    await QueryDB.createNewDoc(
      "userTeamJunction",
      {
        uid: currentUser.uid,
        teamId: team.slug,
        generalRole: "owner",
        joinedTeamTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        monthlyPoints: 0,
        status: "accepted",
        pending: true,
        notificationSettings: {
          memberRequests: true,
          newMembers: true,
          newPostsInFeed: false,
          newComntsInFeed: false,
          upcomingEvents: true,
        },
        isFavorite: true,
        isPlayerOfTheMonth: false,
        inTeamRole: "Owner",
        created: firebase.firestore.FieldValue.serverTimestamp(),
      },
      `${currentUser.uid}_${team.slug}`
    ).catch((res) => {
      return createToast({
        type: "error",
        message: "Failed to Join current user into team",
      });
    });
  }
};
