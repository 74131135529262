import React, { memo, useEffect, useState } from "react";
import { ReactComponent as BellIcon } from "../../assets/bell.svg";
import { Badge } from "@/components";

const NotificationsBell: React.FunctionComponent<{
  newNotifications?: number;
}> = ({ newNotifications }) => {
  return (
    <>
      <div>
        {newNotifications && newNotifications !== 0 ? (
          <Badge variant="primary">{newNotifications}</Badge>
        ) : (
          ""
        )}
        <BellIcon height={22} />
      </div>
    </>
  );
};

export default memo(NotificationsBell);
