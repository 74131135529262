import React, { FC, useEffect } from "react";
import { Author, singleNewsOverviewTypes } from "../../types/NewsTypes";
import { useNavigate, useParams } from "react-router-dom";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { AuthorProfile } from "@/models/database/profiles/profile.model";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { getDateInStringFormat } from "@/utils/constants/formatDate";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { Badge } from "@/components/Badge";

type TrendingNews = {
  title: string;
  slug: string;
  isFeatured: boolean;
  created: any;
  author: AuthorProfile;
};

export const SingleNewsExtra: FC<{
  data: singleNewsOverviewTypes & {
    author: Author;
  };
}> = ({ data }) => {
  const [featuredNews, _loading, _error, fetch_featuredNews] =
    useCloudFunctionCall<{
      wog: TrendingNews[]; // max 3 as per design
      community: TrendingNews[]; //max 3
    }>("getTrendingWogAndCommunityNews", undefined);

  useEffect(() => {
    fetch_featuredNews({});
  }, []);
  const navigate = useNavigate();
  return (
    <div className="article__sidebar">
        <div className="badge-wrapper">
          {data.keywords?.map((key, index) => {
            const indKey = `${key}_${index}`;
            return <Badge key={indKey} variant="secondary">{key}</Badge>;
          })}
          {data.games?.map((game, index) => {
            const indKey = `${game}_${index}`;
            return <Badge key={indKey} variant="secondary">{game}</Badge>;
          })}
        </div>

        <div className="article__related">
          {featuredNews?.wog && (
            <>
              <h2>Related news</h2>
              <ul className="related">
                {featuredNews?.wog?.map((news) => {
                  return (
                    <li
                      key={news.slug}
                      className="related__item"
                      onClick={() => {
                        navigate(`${siteRoutes["newsAndSlug"]}${news.slug}`);
                      }}
                    >
                      <h3 className="related__heading">{news.title}</h3>
                      <div className="related__meta">
                        <div className="related__author">
                          <div className="author">
                            <div className="author__image">
                              <BaseUserAvatar
                                userName={news.author?.userName}
                                logoUrl={news.author?.profileLogoUrl}
                                />
                            </div>
                            <div className="author__username">
                              {news.author.userName}
                            </div>
                          </div>
                        </div>
                        <div className="meta__posted">
                          <time>
                            {getDateInStringFormat(news.created._seconds)}
                          </time>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
    </div>
  );
};
