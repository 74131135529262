import { Badge, Button } from "@/components";
import React, { FC, useState } from "react";
import { ProfileCommunityModalUser } from "./CommunityMembersModalUser";
import {
  CommunityMember,
  UserCommunityMembersKeys,
} from "@/features/messages/types/UserCommunity";
import i18next from "i18next";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { Query_Imgix } from "@/api/imgix";
import { Link, useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const ProfileCommunityModal: FC<{
  community: GetUserCommunityBasedOnSlug;
  setCommunityMembersModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isFullPage?: boolean;
  slug?: string;
  userName: string;
}> = ({
  community,
  setCommunityMembersModalOpen,
  isFullPage,
  slug,
  userName,
}) => {
  const tabsNames = ["friends", "followers", "following"];
  const [currentTab, setCurrentTab] =
    useState<UserCommunityMembersKeys>("friends");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);
  return (
    <div className="modal__community">
      <div className="community">
        <div className="community__heading">
          <div className="community__options">
            <div className="community__tabs">
              <div className="badge-wrapper">
                {tabsNames.map((tabName) => (
                  <Badge
                    key={tabName}
                    variant={currentTab === tabName ? "primary" : "secondary"}
                    onClickAction={() =>
                      setCurrentTab(tabName as UserCommunityMembersKeys)
                    }
                  >
                    <span>
                      {i18next.t(`profile.community.tabs.${tabName}_plural`)}
                    </span>
                    <sup>
                      {community?.[tabName as UserCommunityMembersKeys]
                        ?.totalNumber ?? 0}
                    </sup>
                  </Badge>
                ))}
              </div>
            </div>
            {community?.[currentTab as UserCommunityMembersKeys].users
              .length !== 0 && (
              <div className="community__search">
                <input
                  placeholder={i18next.t(`profile.community.search.placeholder`)}
                  type="search"
                  className="base-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="community__users">
          <div
            className={`community__friends ${
              isFullPage ? "community__friends--full" : ""
            }`}
          >
            <ul className="community__list">
              {community &&
                community?.[currentTab as UserCommunityMembersKeys]?.users
                  ?.filter((user) =>
                    user.userName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((user: CommunityMember) => {
                    const isCurrUser = user.uid === currentUser.uid;
                    const link = isCurrUser
                      ? "/profile"
                      : `/profile/${user.slug}`;
                    return (
                      <li className="community__item" key={user.slug}>
                        <a
                          onClick={() => {
                            navigate(link);
                            if (setCommunityMembersModalOpen) {
                              setCommunityMembersModalOpen(false);
                            }
                          }}
                        >
                          <BaseUserAvatar
                            logoUrl={user?.profileLogoUrl}
                            userName={user?.userName}
                          />
                          <div className="item__info">
                            <p>{user.userName}</p>
                            {user?.fullName && <span>{user?.fullName}</span>}
                          </div>
                        </a>
                      </li>
                    );
                  })}
              {community?.[currentTab as UserCommunityMembersKeys].users
                .length === 0 && (
                <li className="community__item community__item--empty">
                  {currentTab === "friends" && (
                    <>
                      {userName} {i18next.t("profile.community.tabs.empty_string1")} {i18next.t(`profile.community.tabs.friends_plural`)} {i18next.t("profile.community.tabs.empty_string2")}.
                    </>
                  )}
                  {currentTab === "followers" && (
                    <>
                      {userName} {i18next.t("profile.community.tabs.following_empty_string1")} {i18next.t(`profile.community.tabs.followers_plural`)} {i18next.t("profile.community.tabs.following_empty_string2")}.
                    </>
                  )}
                  {currentTab === "following" && (
                    <>
                      {userName} {i18next.t("profile.community.tabs.following_empty_string1")} {i18next.t(`profile.community.tabs.following_plural`)} {i18next.t("profile.community.tabs.following_empty_string2")}.
                    </>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
