import React, { useEffect, useState } from "react";
import NotificationsUi from "./Notifications.ui";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";

const NotificationsDropdown: React.FunctionComponent<{
  currentUser: any;
}> = ({ currentUser }) => {
  const [notifications, setNotifications] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const databaseRef = firebase.database().ref("notifications");
  const [hasRan, setHasRan] = useState<number>(0);
  const [unreadNotificationsNmbr, setUnreadNotificationsNmbr] =
    useState<number>(0);

  const getNotifications = async () => {
    setHasRan(0);
    if (!currentUser.uid) {
      createToast({
        type: "error",
        message: "Please log in to get notifications.",
      });
      return;
    }

    databaseRef
      .orderByChild("recipientUid")
      .equalTo(currentUser.uid)
      .on("value", async (snapshot) => {
        if (snapshot.val()) {
          const response = Object.values(snapshot.val()).map((snap, index) => {
            return {
              data: snap,
              realtimeDocumentID: Object.entries(snapshot.val())[index][0], // doc uid
            };
          }) as any[];

          const unreadAmountResponse = Object.values(snapshot.val()).filter(
            (obj: any) => obj.read === false
          ).length;

          setHasRan((prev) => prev + 1);

          setNotifications(response.reverse());
          setUnreadNotificationsNmbr(unreadAmountResponse);
          return;
        }
        return;
      });
  };

  const typesToTriggerReload = {
    "teams-joined-rejected": "teams-joined-rejected", // Adding user who was rejected
    "teams-removed-by-admin": "removed you from team", // If removed from team
    "teams-remove": "teams-remove", // If removed from team
    "teams-requests-accepted": "teams-requests-accepted", // Request accepted
    "teams-admin-rights": "teams-admin-rights", // Rights changed
    // "teams-requests-rejected": "rejected your request", // Reject a request
    "teams-promote": "teams-promote",
  };

  useEffect(() => {
    // Ignore first render when all notification are fetched, only condition everything that comes later
    if (hasRan > 1) {
      if (
        window.location.pathname.includes(notifications[0].data.contentId) &&
        typesToTriggerReload[notifications[0].data.type]
      ) {
        setTimeout(() => {
          document.dispatchEvent(new Event("reload_singleTeam"));
        }, 1000);
      }
    }
  }, [hasRan]);

  useEffect(() => {
    if (currentUser.uid) {
      setLoading(true);
      getNotifications();
    }
  }, []);

  return (
    <>
      {notifications && (
        <NotificationsUi
          updateNotifications={() => getNotifications()}
          loading={loading}
          isFullPage={false}
          notifications={notifications}
          unreadNotifications={unreadNotificationsNmbr}
        />
      )}
    </>
  );
};

export default NotificationsDropdown;
