import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

const LocationFinder: React.FunctionComponent<{
  label?: string;
  isDisabled?: boolean;
  textType: "text" | "textarea" | "file";
  value?: string;
  setSingleState?: any;
  setObjectState?: any;
  setObjectStateTargetName?: string;
  placeholder?: string;
  error?:
    | {
        error: string;
        message: string;
      }
    | undefined;
}> = (props) => {
  const autoCompleteRef = useRef() as any;
  const inputRef = useRef() as any;
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    country: "est",
  };
  const [searchAddress, setSearchAddress] = useState<string>("");
  const loadSearch = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      if (props.setSingleState) {
        setSearchAddress(place.formatted_address);
        return props.setSingleState(
          `${
            place.formatted_address
          }?${place.geometry.location.lat()}? ${place.geometry.location.lng()}`
        );
      }
      if (props.setObjectState) {
        setSearchAddress(place.formatted_address);

        return props.setObjectState(
          props.setObjectStateTargetName,
          `${
            place.formatted_address
          }?${place.geometry.location.lat()}? ${place.geometry.location.lng()}`
        );
      }
      return false;
    });
    const externalResource = document.getElementById("googleMapsApi");
    externalResource?.parentNode?.removeChild(externalResource);
  };
  const [data, loading, error, fetch_api] = useCloudFunctionCall(
    "getGoogleMapApi",
    undefined
  );

  useEffect(() => {
    const getKey = async () => {
      await fetch_api({}).then(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCErxVmyTaDZVDlh7cEYvRHCX139bTH1eo&libraries=places`;
        script.async = true;
        script.id = "googleMapsApi";
        script.onload = () => loadSearch();
        document.body.appendChild(script);
      });
    };
    getKey();
  }, []);

  return (
    <>
      {props.label ? (
        <Form.Label as="h3">
          <h3>{props.label}</h3>
        </Form.Label>
      ) : (
        ""
      )}
      <input
        ref={inputRef}
        id="google-autocomplete"
        className="w-100 base-input"
        placeholder={props.placeholder}
        value={searchAddress}
        onChange={async (e) => {
          setSearchAddress(e.target.value);
        }}
        type={props.textType}
      />
      {props.error && (
        <FormErrorMessage
          text={props.error.message}
          isVisible={props.error.error === props.setObjectStateTargetName}
        />
      )}
    </>
  );
};

export default LocationFinder;
