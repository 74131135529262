import QueryDB from "@/api/queryDB/common";

export const checkGameActivation = async (
  collectionName: string,
  fieldName: string,
  uid: string,
  setValue: boolean
): Promise<void | unknown | null> => {
  if (!uid) return null;

  try {
    await QueryDB.createNewDoc(
      collectionName,
      {
        [fieldName]: setValue,
      },
      uid
    );
    return;
  } catch (error) {
    return error;
  }
};
