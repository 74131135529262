import { memo, useEffect, useState, FC, useMemo } from "react";
import { SlugProps } from "@/features/challenge/types/props/SingleChallengeProps";
import LeaderboardTableRows from "./LeaderboardTableRows";
import LeaderboardUpdate from "./LeaderboardUpdate";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Badge, Button, Spinner } from "@/components";
import LeaderboardParticipantsTable from "./LeaderboardParticipantsTable";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import {
  LeaderBoardBasedOnChallengeId_Props,
  LeaderBoardBasedOnChallengeId_Response,
} from "../../types/cloudFunctions/LeaderBoard";
import { Fn, tabsNames } from "../../utils/constants";
import firebase from "firebase";
import { useAuthorProfile } from "@/store/profiles/authorProfile.store";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";
import { useInView } from "react-intersection-observer";
import { cacheDatabase } from "@/api/firebase/firebase.config";
import { brand } from "./../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import i18next from "i18next";

export const Leaderboard: FC<
  SlugProps & {
    color: string;
    game: string;
    isPreview?: boolean;
    status?: string;
    client?: string;
  }
> = ({ slug, color, game, isPreview = false, status, client }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [leaderboardData, setLeaderboardData] = useState<any[]>([]);
  const [updatingTimer, setUpdatingTimer] = useState<number>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFilterType, setPropsFilters] = useState<
    "ALL" | "FRIENDS" | "FOLLOWING"
  >("ALL");

  console.log(slug);

  const [refOfAll, inViewOfAll] = useInView();
  const databaseRef = cacheDatabase.ref("leaderboard");

  const updateLeaderboardHandler = (isTimeOut: boolean) => {
    if (isTimeOut) {
      setUpdatingTimer(undefined);
      setIsUpdated(true);
    }
  };

  const filterKey = selectedFilterType;

  // NEW DATA
  const [
    leaderboard,
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchLeaderboard,
    ref,
    inView,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    LeaderBoardBasedOnChallengeId_Response,
    LeaderBoardBasedOnChallengeId_Props // props types for cloud function
  >(
    "getLeaderBoardForChallengeId",
    slug,
    filterKey,
    false // persisting
  );

  //ignore this code block; DO  NOT MODIFY IT ----------------
  const currentUserInThisChallenge = leaderboard?.currentUser;
  console.log(leaderboard);

  const participants = leaderboard?.participants || [];

  // if user is participating then userChallengeData will not be undefined
  const isUserParticipated = !!currentUserInThisChallenge?.userChallengeData;
  console.log(currentUserInThisChallenge);

  const fetchAllLeaderboard = async () => {
    console.log(slug);

    setLoading(true);
    databaseRef
      .child(slug)
      .orderByKey()
      .on("value", async (snapshot) => {
        console.log(snapshot.val());

        if (snapshot.val()?.leaderboard) {
          const newLeaderboardDataArray: any[] = [];

          await Promise.all(
            snapshot
              .val()
              .leaderboard.filter((element) => {
                return element?.userId && Object.keys(element).length !== 0;
              })
              .map(async (val: any) => {
                let author;
                if (val && val.userId) {
                  const authorProfile = await getAuthorProfileBasedOnUid(
                    val.userId,
                    brand.brandValue
                  );

                  if (authorProfile) {
                    author = authorProfile;
                  } else {
                    if (client) {
                      const authorProfile2 = await getAuthorProfileBasedOnUid(
                        val.userId,
                        client
                      );
                      if (authorProfile2) {
                        author = authorProfile2;
                      }
                    }

                    if (!author) {
                      const authorProfile3 = await getAuthorProfileBasedOnUid(
                        val.userId,
                        "WOG"
                      );
                      if (authorProfile3) {
                        author = authorProfile3;
                      }
                    }
                  }

                  if (author) {
                    newLeaderboardDataArray.push({
                      profile: author,
                      userChallengeData: val.userChallengeData,
                    });
                  } else if (!author && val.userChallengeData) {
                    newLeaderboardDataArray.push({
                      profile: {
                        fullName: "",
                        slug: "",
                        uid: "",
                        userName: "Failed to load user's info",
                      },
                      userChallengeData: val.userChallengeData,
                    });
                  }
                }
              })
          );
          console.log(newLeaderboardDataArray);

          setLeaderboardData(newLeaderboardDataArray);
        } else {
          fetchLeaderboard(
            {
              challengeId: slug!,
              currentUserId: currentUser.uid,
              type: selectedFilterType,
              lastVisibleItemNumber: 0,
            },
            filterKey,
            false
          );
        }
      });

    setLoading(false);
    if (status !== "ongoing") {
      databaseRef.off();
    }

    return;
  };

  //only run at first load and when the tab has changed!
  useEffect(() => {
    const fetchData = async () => {
      if (
        !leaderboard ||
        selectedFilterType === "FOLLOWING" ||
        selectedFilterType === "FRIENDS"
      ) {
        await fetchLeaderboard(
          {
            challengeId: slug!,
            currentUserId: currentUser.uid,
            type: selectedFilterType,
            lastVisibleItemNumber: 0,
          },
          filterKey,
          false
        );
      }

      if (selectedFilterType === "ALL") {
        await fetchAllLeaderboard();
      }
    };

    fetchData();
  }, [slug, selectedFilterType]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  useEffect(() => {
    if (selectedFilterType === "ALL") {
      fetchAllLeaderboard();
    }
  }, [inViewOfAll]);

  useEffect(() => {
    if (status !== "ongoing") {
      databaseRef.off();
    }
  }, [status]);

  // call this function when inView is true
  const fetchMore = async () => {
    if (selectedFilterType === "ALL") {
      fetchAllLeaderboard();
    } else {
      await fetchLeaderboard(
        {
          challengeId: slug,
          currentUserId: currentUser.uid,
          type: selectedFilterType,
          lastVisibleItemNumber: lastFeedDateInSeconds,
        },
        selectedFilterType,
        true
      );
    }
  };

  const inViewRefElementIndex = useMemo(() => {
    return leaderboard ? leaderboard?.participants.length - 1 : -1;
  }, [leaderboard, hasMore]);

  const currUser = () => (
    <div className="leaderboard__user">
      <div className="your-score">
        <div className="your-score__heading">
          {i18next.t("challenge.leaderboard.score.yours")}
        </div>
        <div className="your-score__content">
          <p> {i18next.t("challenge.leaderboard.score.description")}</p>
        </div>
      </div>
    </div>
  );
  const MemoizedCurrUser = memo(currUser);
  return (
    <>
      <div className="challenge__leaderboard">
        <div className="leaderboard">
          <div className="leaderboard__heading">
            <h2>{i18next.t("challenge.leaderboard.headline")}</h2>
          </div>
          {!brand.event.isEvent && (
            <div className="leaderboard__tabs">
              <div className="badge-wrapper">
                {tabsNames.map((tabName) => (
                  <Badge
                    variant={
                      selectedFilterType === tabName ? "primary" : "secondary"
                    }
                    onClickAction={() => setPropsFilters(tabName)}
                    key={tabName}
                  >
                    {i18next
                      .t(`challenge.leaderboard.tabs.${tabName}`)
                      .toLowerCase()}
                  </Badge>
                ))}
              </div>
              <div className="leaderboard__refresh">
                {updatingTimer && (
                  <LeaderboardUpdate
                    isUpdated={updateLeaderboardHandler}
                    timerVal={updatingTimer}
                  />
                )}
              </div>
            </div>
          )}
          {(currentUserInThisChallenge?.userChallengeData &&
            Object.keys(currentUserInThisChallenge.userChallengeData).length >
              0) ||
          isUserParticipated ? (
            // double div to mimick the list with all user, but for current user score
            <div className="leaderboard__list leaderboard__list--user">
              <div className="leaderboard__item leaderboard__item--user">
                <div className="your-score">
                  <div className="your-score__heading">
                    {i18next.t("challenge.leaderboard.score.yours")}
                  </div>
                  <div className="your-score__content">
                    <LeaderboardTableRows
                      bgStyle=""
                      rank={
                        currentUserInThisChallenge?.userChallengeData?.rank ??
                        "_"
                      }
                      logo={currentUser.profileLogoUrl}
                      name={[currentUser.userName, currentUser.fullName]}
                      points={
                        currentUserInThisChallenge?.userChallengeData?.points ??
                        "_"
                      }
                      slug={currentUser.slug}
                      game={game}
                      pointsType={
                        currentUserInThisChallenge?.userChallengeData
                          ?.pointsType
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <MemoizedCurrUser />
          )}
          <div className="leaderboard__list">
            <LeaderboardParticipantsTable
              color={color}
              items={
                selectedFilterType === "ALL" ? leaderboardData : participants
              }
              observerRef={ref}
              observerRefAll={refOfAll}
              inViewIndex={inViewRefElementIndex}
              loading={_loading || loading}
              game={game}
            >
              <>
                {/* {_loading || loading ? <Spinner /> : ""} */}
                {!loading && selectedFilterType === "ALL" && (
                  <div className="alert">
                    {
                      <p className="alert__message">
                        {i18next.t(
                          "challenge.leaderboard.score.noParticipants"
                        )}
                      </p>
                    }

                    {currentUser.uid || !isPreview ? (
                      <div className="alert__action">
                        {/* <InviteButton slug={slug!} text="Invite" /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!_loading && selectedFilterType === "FRIENDS" && (
                  <div className="alert">
                    <p className="alert__message">
                      {i18next.t("challenge.leaderboard.score.noFriends")}
                    </p>
                    <div className="alert__action">
                      {/* <InviteButton slug={slug!} /> */}
                    </div>
                  </div>
                )}
                {!_loading && selectedFilterType === "FOLLOWING" && (
                  <div className="alert">
                    <p className="alert__message">
                      {i18next.t("challenge.leaderboard.score.noFollowing")}
                    </p>
                    <div className="alert__action">
                      <Button
                        variant="primary"
                        onClickAction={() => setPropsFilters("ALL")}
                      >
                        {i18next.t("challenge.buttons.tabs.back")}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            </LeaderboardParticipantsTable>
          </div>
        </div>
      </div>
    </>
  );
};
