import QueryDB_Profiles from "@/api/queryDB/profiles";
import { Button } from "@/components";
import { createToast } from "@/utils/toaster/createToast";
import i18next from "i18next";
import { useState, useEffect } from "react";
type Props = {
  isPlaceholder: boolean;
  belongsToCurrUser: boolean;
  isFriendsOfCurrentUser: boolean;
  isFollowedByCurrentUser: boolean;
  isFriendsRequestPending: boolean;
  receivedFriendRequest: boolean;
  uid: string;
};

export default function ProfileCommunityActionButtons(props: Props) {
  const [isFriend, setIsFriend] = useState(() => props.isFriendsOfCurrentUser);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isFollowed, setIsFollowed] = useState(
    () => props.isFollowedByCurrentUser
  );
  const [isRequestPending, setRequestPending] = useState(
    () => props.isFriendsRequestPending
  );

  // to ensure that the state gets updated when new data is fetched
  useEffect(() => {
    setIsFriend(props.isFriendsOfCurrentUser);
    setIsFollowed(props.isFollowedByCurrentUser);
    setRequestPending(props.isFriendsRequestPending);
    setIsBlocked((prev) => !prev);
  }, [
    props.isFriendsOfCurrentUser,
    props.isFollowedByCurrentUser,
    props.isFriendsRequestPending,
  ]);

  if (props.belongsToCurrUser) {
    return <></>;
  }

  if (props.isPlaceholder) {
    return <div className="placeholder" />;
  }

  async function handleCommunityRequest(
    action:
      | "send_friend_request"
      | "cancel_request"
      | "remove_friend"
      | "follow"
      | "unfollow"
      | "accept_friend_request"
      | "blocked"
  ) {
    const recipientUid = props.uid;
    const actionAndReactDispatchEvent: {
      [key in typeof action]: (reaction: boolean) => void;
    } = {
      accept_friend_request: (reaction) => {
        // can't be used based on current design
        setIsFriend(reaction);
        setRequestPending(!reaction);
      },
      cancel_request: (reaction) => {
        setRequestPending(!reaction);
      },
      follow: (reaction) => {
        setIsFollowed(reaction);
      },
      remove_friend: (reaction) => {
        setIsFriend(!reaction);
      },
      send_friend_request: (reaction) => {
        setRequestPending(reaction);
      },
      unfollow: (reaction) => {
        setIsFollowed(!reaction);
      },
      blocked: (reaction) => {
        setIsBlocked(!reaction);
      },
    };

    //trigger the action
    actionAndReactDispatchEvent[action](true);

    //trigger the cloud function
    try {
      await QueryDB_Profiles.updateUserCommunityStatus(action, recipientUid);
      if (action === "send_friend_request") {
        createToast({
          type: "success",
          message: "You have sent a friend request.",
        });
        return;
      }
      if (action === "accept_friend_request") {
        createToast({
          type: "success",
          message: "You have accepted the friend request.",
        });
        return;
      }
      if (action === "cancel_request") {
        createToast({
          type: "success",
          message: "You have cancelled the friend request.",
        });
        return;
      }
      if (action === "remove_friend") {
        createToast({
          type: "success",
          message: "You are no longer friends.",
        });
        return;
      }
    } catch (e) {
      createToast({
        type: "error",
        message: `Failed to handle action. Please try again later`,
      });
      // if failed then revert the changes
      actionAndReactDispatchEvent[action](false);
    }
  }

  // Variables
  const receivedRequest       = props.receivedFriendRequest;
  const isFriendOfCurrentUser = props.isFriendsOfCurrentUser;

  // If the users are friends
  if (isFriend) {
    return (
      <Button
        variant="secondary"
        onClickAction={() => handleCommunityRequest("remove_friend")}
      >
        {i18next.t("profile.actions.unfriend")}
      </Button>
    )
  }

  // If you have sent a request
  if (isRequestPending) {
    return (
      <Button
        variant="secondary"
        onClickAction={() => handleCommunityRequest("cancel_request")}
      >
        {i18next.t("profile.actions.cancel_friend_request")}
      </Button>
    )
  }

  // If a request has been sent to you
  if (receivedRequest) {
    return (
      <>
        <Button
          variant="primary"
          onClickAction={() =>
            handleCommunityRequest("accept_friend_request")
          }
        >
          {i18next.t("profile.actions.accept_friend_request")}
        </Button>
        <Button
          variant="secondary"
          onClickAction={() => handleCommunityRequest("cancel_request")}
        >
          {i18next.t("profile.actions.decline_friend_request")}
        </Button>
      </>
    )
  }

  // Any other case
  return (
    <>
      <Button
        variant="primary"
        onClickAction={() =>
          handleCommunityRequest("send_friend_request")
        }
      >
        {i18next.t("profile.actions.add_friend")}
      </Button>
      {isFriendOfCurrentUser && (
        <Button
          variant="link"
          onClickAction={() =>
            handleCommunityRequest(isFollowed ? "unfollow" : "follow")
          }
          // onClick={handleFollowedRequest}
        >
          {isFollowed ? i18next.t("profile.actions.unfollow") : i18next.t("profile.actions.follow")}
        </Button>
      )}
    </>
  );
}
