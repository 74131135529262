import React, { FC, useCallback, useEffect, useState } from "react";
import i18next from "i18next";
import { Query_Imgix } from "@/api/imgix";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { SettingsProfileHistory } from "./SettingsProfileHistory";
import { SettingsProfileImageButtons } from "./SettingsProfileImageButtons";
import { SettingsProfileAvatars } from "./SettingsProfileAvatars";
import { EditProfileModal } from "./SettingsProfileUploadModal";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import { submitUpdateProfile } from "@/utils/constants/formSubmitUtils";
import { getCroppedImg } from "@/features/profile/helperFunctions/cropImage";

export const SettingsProfileImage: FC<{}> = ({}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [openModal, setOpenModal] = useState(false);
  const [imgSrc, setImgSrc] = useState<any>(undefined);
  const [isAvatar, setIsAvatar] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState<string | null>();
  const [profileCroppedImage, setCroppedProfileImage] = useState<string>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageAsFileFormat, setImageAsFileFormat] = useState<File>();
  // HANDLE IMG CHANGE
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCroppedProfileImage(URL.createObjectURL(e?.target?.files![0]));
    setImgSrc(URL.createObjectURL(e?.target?.files![0]));
    setImageAsFileFormat(e?.target?.files![0]);
  };

  const handleInput = async (file: string | undefined) => {
    if (file) {
      setImgSrc(file);
      setIsAvatar(true);
      setIsLoading(null);
    }
  };

  const submitImage = async () => {
    setIsSubmiting(true);
    if (!profileCroppedImage && imgSrc) {
      await submit(imgSrc, currentUser.uid, currentUser.profileLogoUrlHistory);
      return setIsSubmiting(false);
    }
    if (profileCroppedImage) {
      await submitCroppedImage();
      return setIsSubmiting(false);
    }
    if (!profileCroppedImage && !imgSrc) {
      await submit(null, currentUser.uid, currentUser.profileLogoUrlHistory);
      return setIsSubmiting(false);
    }
  };

  const submit = async (
    imgixURl: string | null,
    uid?: string,
    avatarArr = []
  ) => {
    if (!uid) return;
    let newArray;
    if (imgixURl) {
      if (avatarArr && avatarArr.length < 5) {
        newArray = [imgixURl, ...avatarArr];
      } else if (avatarArr && avatarArr.length > 4) {
        const newHistoryArr = avatarArr.slice(0, 4);
        newArray = [imgixURl, ...newHistoryArr];
      }
    } else {
      newArray = [...avatarArr];
    }

    await submitUpdateProfile(
      "profiles",
      {
        profileLogoUrl: imgixURl || null,
        profileLogoUrlHistory: newArray,
      },
      uid
    )
      .then(() => {
        refreshUser(setUserProfile, currentUser);
        createToast({
          type: "success",
          message: "Avatar updated",
        });
      })
      .catch((err) => {
        createToast({
          type: "error",
          message: "Failed to update avatar",
        });
      });
    setIsSubmiting(false);
  };

  // Update image
  const submitProfileLogo = useCallback(async () => {
    const url = await Query_Imgix.convertImageToImgIX(
      imageAsFileFormat,
      `profile/${currentUser.slug}`
    );

    if (!url) {
      createToast({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
    }

    await QueryDB.updateDoc("profiles", currentUser.uid, {
      profileLogoUrl: url || null,
    })
      .then(() => {
        refreshUser(setUserProfile, currentUser);
        createToast({
          type: "success",
          message: "Avatar updated",
        });
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to update avatar",
        });
      });

    setImgSrc(undefined);
    setIsAvatar(false);
    setIsSubmiting(false);
  }, [imgSrc]);

  // SUBMIT CROPPED IMAGE
  const submitCroppedImage = async () => {
    if (!profileCroppedImage && !imgSrc) {
      return;
    }

    if (!profileCroppedImage && imgSrc) {
      return submitProfileLogo();
    }

    if (
      String(profileCroppedImage) &&
      profileCroppedImage.startsWith("https")
    ) {
      await submit(
        profileCroppedImage,
        currentUser.uid,
        currentUser.profileLogoUrlHistory
      );
      refreshUser(setUserProfile, currentUser);
      return;
    }

    const cropped = await getCroppedImg(
      profileCroppedImage!,
      croppedAreaPixels,
      0,
      imageAsFileFormat.type
    );

    const BlobToFile = new File([cropped], imageAsFileFormat.name);

    const url = await Query_Imgix.convertImageToImgIX(
      BlobToFile,
      `profile/${currentUser.slug}`
    );

    await submit(url, currentUser.uid, currentUser.profileLogoUrlHistory);
    refreshUser(setUserProfile, currentUser);
  };

  return (
    <>
      <div className="settings__alert">
        <p>{i18next.t(`profile.edit.avatar.upload_msg`)}</p>
      </div>
      <div className="settings__content">
        <div>
          <SettingsProfileImageButtons
            imgSrc={imgSrc || profileCroppedImage || ""}
            setImgSrc={setImgSrc}
            profile={currentUser}
            setIsAvatar={setIsAvatar}
            setIsSubmiting={setIsSubmiting}
            isUpdating={isSubmiting}
            submitProfileLogo={submitImage}
            setCroppedProfileImage={setCroppedProfileImage}
            setOpenModal={setOpenModal}
            submitImage={submitImage}
          />
        </div>
        {!openModal && (
          <div className="settings__defined">
            <h5>{i18next.t(`profile.edit.avatar.upload_title`)}</h5>

            <SettingsProfileHistory
              currentUser={currentUser}
              setOpenModal={setOpenModal}
              setIsAvatar={setIsAvatar}
              setImgSrc={setImgSrc}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
            />
            <SettingsProfileAvatars
              handleInput={handleInput}
              setIsAvatar={setIsAvatar}
            />
          </div>
        )}
        {openModal && (
          <div>
            <EditProfileModal
              logoSrc={currentUser.profileLogoUrl}
              setLogoSrc={setImgSrc}
              setOpenEditModal={setOpenModal}
              openEditModal={openModal}
              name={currentUser.userName}
              isPlaceHolder
              avatarHistory={currentUser.profileLogoUrlHistory}
              profileCroppedImage={profileCroppedImage}
              setCroppedProfileImage={setCroppedProfileImage}
              handleInputChange={handleInputChange}
              setCroppedAreaPixels={setCroppedAreaPixels}
              croppedAreaPixels={croppedAreaPixels}
            />
          </div>
        )}
      </div>
    </>
  );
};
