import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import useCloudFunctionCall from "./useCloudFunctionCall";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { friendListAtom } from "@/utils/atoms/communityAtoms/communityAtoms";

type CommunityUser = {
  slug: string;
  profileLogoUrl?: string;
  userName: string;
  fullName?: string;
  uid: string;
};
type UserCommunity = {
  all: {
    totalNumber: number;
    users: CommunityUser[];
  };
  followers: {
    totalNumber: number;
    users: CommunityUser[];
  };
  following: {
    totalNumber: number;
    users: CommunityUser[];
  };
  friends: {
    totalNumber: number;
    users: CommunityUser[];
  };
};

/**
 *
 * @param otherUsersSlug optional: Pass only if you want to fetch other user's community!
 * @returns [communityData, loading, error]
 */
export function useCommunityData() {
  const { slug } = useParams();
  const location = useLocation();
  const isUserOnProfilePage =
    location.pathname === `/profile/${slug}` ||
    location.pathname === "/profile";
  //only use the slug if user is on profile page - otherwise use currentuser slug
  const otherUserSlug = isUserOnProfilePage ? slug : null;
  const [friendListDataAtom, setFriendListDataAtom] = useAtom(friendListAtom);
  const visitedCommunityDoc = friendListDataAtom?.[slug];

  const currUser = useCurrentUserProfile((s) => s.user);
  const userSlug = otherUserSlug || currUser.slug;

  const [communityData, loading_community, communityError, fetchCommunityData] =
    useCloudFunctionCall<UserCommunity>(
      "getUserCommunityBasedOnSlug",
      userSlug
    );

  useEffect(() => {
    if (!communityData) {
      //only fetch if user is on profile page
      //only fetch if there's no community data!
      fetchFresh();
    }
  }, [userSlug]);

  function fetchFresh() {
    fetchCommunityData({
      userSlug: userSlug,
    });
  }

  useEffect(() => {
    if (communityData && slug) {
      setFriendListDataAtom({
        ...(friendListDataAtom || []),
        [slug]: communityData,
      });
    }
  }, [communityData, slug]);

  const dataToReturn = communityData || visitedCommunityDoc;
  const loading = loading_community && !dataToReturn;
  return [dataToReturn, loading, communityError, fetchFresh] as const;
}
