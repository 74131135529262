import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import QueryDB from "@/api/queryDB/common";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { ParticipatingUsersProps } from "../../types/cloudFunctions/participatingUserProps";
import { Button } from "@/components/Button";

type props = {
  gatherSlug: string;
  setUpdateParticipant: React.Dispatch<React.SetStateAction<number>>;
  setIsRequest: React.Dispatch<React.SetStateAction<boolean>>;
};
const PartipateRequestList: FC<props> = (props) => {
  const [
    requestingUsers,
    _loading_users,
    _request_Error,
    fetch_RequestingUsers,
  ] = useCloudFunctionCall<ParticipatingUsersProps>(
    "getUsersRequestForGathers",
    props.gatherSlug
  );

  const [updateTrigger, setUpdateTrigger] = useState<number>(0);

  useEffect(() => {
    if (!props.gatherSlug) {
      return;
    }
    const run = async () => {
      await fetch_RequestingUsers({
        slug: props.gatherSlug,
      });
    };
    run();
  }, [updateTrigger]);

  useEffect(() => {
    if (requestingUsers && requestingUsers.response.length !== 0) {
      props.setIsRequest(true);
    }
  }, [requestingUsers]);

  return (
    <>
      {requestingUsers && requestingUsers?.response.length !== 0 && (
        <h4 className="text-white-50">Requests </h4>
      )}
      {requestingUsers &&
        requestingUsers?.response.length !== 0 &&
        requestingUsers?.response.map((user: any) => {
          return (
            <li className="gather-participants__requests">
              <div className="gather-participants__requests--buttons">
                <div className="d-flex">
                  <Link
                    to={`/profile/${user.slug}`}
                    className="gather-participants__users"
                  >
                    <figure>
                      {user.profileLogoUrl ? (
                        <Image
                          width={40}
                          height={40}
                          src={user.profileLogoUrl}
                          className="rounded-circle"
                        />
                      ) : (
                        <div
                          className="gahter-interested__logo"
                          style={{ width: "40px", height: "40px" }}
                        >
                          <p
                            className="text-center mb-0"
                            style={{ width: "40px" }}
                          >
                            {user.userName.slice(0, 1)}
                          </p>
                        </div>
                      )}
                    </figure>
                    <div>
                      <p className="gather-participants__users--username">
                        {user.userName}
                      </p>
                      <p className="gather-participants__users--fullname">
                        {user.fullName}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="gather-participants__buttons">
                  <Button
                    variant="primary"
                    onClickAction={async () => {
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${user.uid}_${props.gatherSlug}`,
                        {
                          status: "participant",
                          generalRole: "member",
                        }
                      ).then(() => {
                        setUpdateTrigger((prev) => prev + 1);
                        props.setUpdateParticipant((prev) => prev + 1);
                      });
                    }}
                    customStyles="gather-participants__buttons--accept"
                  >
                    Accept
                  </Button>
                  <Button
                    variant="secondary"
                    onClickAction={async () => {
                      await QueryDB.updateDoc(
                        "userGatherJunction",
                        `${user.uid}_${props.gatherSlug}`,
                        {
                          status: "declined",
                          generalRole: "Rejected",
                        }
                      ).then(() => {
                        setUpdateTrigger((prev) => prev + 1);
                      });
                    }}
                    customStyles="gather-participants__buttons--decline"
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </li>
          );
        })}
    </>
  );
};
export default PartipateRequestList;
