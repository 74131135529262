import { Button } from "@/components";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { teamDraftAtom } from "@/utils/atoms/draftsAtoms/draftsAtoms";
import { useAtom } from "jotai";

export const CreateTeamDraftModal = ({
  draftModalOpen,
  setDraftModalOpen,
  handleSetDraft,
}: {
  draftModalOpen: any;
  setDraftModalOpen: any;
  handleSetDraft: any;
}) => {
  const [teamDraft, setTeamDraft] = useAtom(teamDraftAtom);
  return (
    <>
      <BaseModalWindow
        show={draftModalOpen}
        closeModal={() => {
          setDraftModalOpen(false);
        }}
        title="Create team draft found"
      >
        <div className="disband">
          <p>Continue where you left off?</p>
          <fieldset className="input-group"></fieldset>
          <div className="disband__actions">
            <Button
              variant="secondary"
              type="button"
              onClickAction={() => {
                setDraftModalOpen(false);
                setTeamDraft("");
              }}
            >
              Start new team
            </Button>

            <Button
              variant="primary"
              type="button"
              onClickAction={() => {
                setDraftModalOpen(false);
                handleSetDraft();
              }}
            >
              Load team draft
            </Button>
          </div>
        </div>
      </BaseModalWindow>
    </>
  );
};
