import React, { FC } from "react";
import { ReactComponent as WonIcon } from "../assets/Done.svg";
import { ReactComponent as LoseIcon } from "../assets/Cross.svg";
import { ReactComponent as BlankIcon } from "../assets/blank.svg";
import { Task } from "../types/dailyTasksTypes";

export const ScheduleTaskCard: FC<{ task: Task; activeTaskNmbr: number }> = ({
  task,
  activeTaskNmbr,
}) => {
  return (
    <li
      className={`legend__task ${task.won === true ? "won" : ""} ${
        task.won === false ? "lose" : ""
      }`}
    >
      <div>
        <p>Task {task.taskNumber}</p>
        <p>{task.task}</p>
      </div>

      <figure>
        {task.won === true && <WonIcon width={24} height={24} />}

        {(!task.won && task.taskNumber < activeTaskNmbr) ||
        task.won === false ? (
          <LoseIcon width={24} height={24} />
        ) : (
          ""
        )}

        {task.won === null && task.taskNumber >= activeTaskNmbr && (
          <BlankIcon width={24} height={24} />
        )}
      </figure>
    </li>
  );
};
