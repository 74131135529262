import React from "react";
import { Navigate } from "react-router-dom";
import { ReactComponent as ChevronUp } from "../../assets/actions/chevron-up.svg";
import { ReactComponent as ChevronRight } from "../../assets/actions/chevron-right.svg";
import { ReactComponent as ChevronDown } from "../../assets/actions/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "../../assets/actions/chevron-left.svg";


interface NavArrowProps {
    direction: 'up' | 'right' | 'down' | 'left';
    size: number,
    onClickAction?: (() => void) | (() => typeof Navigate);
}

const NavArrow: React.FC<NavArrowProps> = ({
    direction,
    size,
    onClickAction
}) => {    
    const chevron = {
        'up': <ChevronUp width={size} height={size} />,
        'right': <ChevronRight width={size} height={size} />,
        'down': <ChevronDown width={size} height={size} />,
        'left': <ChevronLeft width={size} height={size} />,
    }
    
    return (
        <button
            className='nav-arrow'
            onClick={onClickAction}
            >
            {chevron[direction]}
        </button>
    );
};

export default NavArrow;