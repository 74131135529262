import { FC } from "react";
import "../../utils/globalStyles/_errorRoutes.scss";
import { ReactComponent as ErrorIcon } from "../../assets/errors/error.svg";
import { Button } from "@/components";
import { Link } from "react-router-dom";

const ErrorRoutes: FC = (): JSX.Element => {
  return (
    <div className="error-page">
      <div className="error-page__content">
        <div className="error-page__image">
          <ErrorIcon width={800} />
        </div>
        <div className="error-page__heading">
          <h1>It's not you. It's us.</h1>
          <p>We are not quite sure what went wrong.</p>
          <div className="error-page__actions">
            <Link to="/" className="btn btn-primary">
              Go home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorRoutes;
