import { auth } from "@/api/firebase/firebase.config";
import { Button } from "@/components";
import { FC, useEffect, useState } from "react";
import firebase from "firebase/app";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { useValidatorEmailOrUsername } from "@/features/auth/helperFunctions/validateEmailOrUsername";
import { siteRoutes } from "../../../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const VerifiyEmail: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [emailAuthValues, setEmailAuthValues] = useState<{
    emailVal: string;
    passwordVal: string;
    passwordVerifVal: string;
  }>({
    emailVal: auth.currentUser?.email || "",
    passwordVal: "",
    passwordVerifVal: "",
  });
  const [errors, setErrors] = useState<any>();
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
    emailAuthValues.emailVal
  );
  const passwordsMatch =
    emailAuthValues.passwordVal === emailAuthValues.passwordVerifVal;

  const setFieldValue = async (targetName: string, value: any) => {
    return setEmailAuthValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };
  useEffect(() => {}, []);
  useValidatorEmailOrUsername("email", emailAuthValues.emailVal, setErrors);
  const handleUpdateEmail = async () => {
    if (errors) {
      return false;
    } else {
      setErrors("");
    }

    if (auth.currentUser) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        emailAuthValues.emailVal,
        emailAuthValues.passwordVal
      );
      try {
        await auth.currentUser.linkWithCredential(credential);
        await firebase.auth().currentUser?.reload();
        createToast({
          type: "success",
          message: "Successfully updated account",
        });
        navigate(siteRoutes["profile"]);
      } catch (error) {
        const message = (error as Error).message;
        createToast({
          type: "error",
          message,
        });
      }
    }
  };

  return (
    <form>
      {/* EMAIL */}
      <div className="settings__email">
        <BaseTextInput
          label="Email address"
          textType="text"
          value={emailAuthValues.emailVal}
          placeholder="Email address..."
          setObjectState={setFieldValue}
          setObjectStateTargetName="emailVal"
          error={undefined}
        />
      </div>

      {/* PASSWORD */}
      {emailAuthValues.emailVal && isEmailValid && (
        <div className="settings__password">
          <BaseTextInput
            label="Password"
            textType="password"
            value={emailAuthValues.passwordVal}
            placeholder="Password..."
            setObjectState={setFieldValue}
            setObjectStateTargetName="passwordVal"
            error={undefined}
          />
        </div>
      )}

      {/* PASSWORD VERIFY */}
      {emailAuthValues.passwordVal &&
        emailAuthValues.passwordVal.length >= 6 && (
          <div className="settings__password">
            <BaseTextInput
              label="Repeat password"
              textType="password"
              value={emailAuthValues.passwordVerifVal}
              placeholder="Repeat password..."
              setObjectState={setFieldValue}
              setObjectStateTargetName="passwordVerifVal"
              error={undefined}
            />
          </div>
        )}

      <div className="settings__buttons">
        <Button
          variant="primary"
          type="button"
          onClickAction={() => {
            handleUpdateEmail();
          }}
          disabled={!emailAuthValues.emailVal || !passwordsMatch}
        >
          Verify with email
        </Button>
      </div>
    </form>
  );
};
