import React, { FC, useEffect, useState, useCallback } from "react";
import { FiSearch as SearchIcon } from "@react-icons/all-files/fi/FiSearch";
import placeholderImage from "../../assets/placeholders/placeholder.png";
import { NewChatModal } from "./NewChatModal";
import { CommunityMember } from "./types/UserCommunity";
import { ChatSingleUserItem } from "./ChatSingleUserItem";
import BaseLogo from "@/components/BaseLogo/BaseLogo";

export const ChatMessagesList: FC<{
  community: any;
  isChatOpen: boolean;
  setChatOpen: (open: boolean) => void;
  setSelectedChat: (chatId: string | null) => void;
  selectedChat: string | null;
  chats: any[];
  loading: boolean;
}> = ({
  community,
  isChatOpen,
  setChatOpen,
  setSelectedChat,
  selectedChat,
  chats,
  loading,
}): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<CommunityMember[]>([]);
  const [searchQuerry, setSearchQuerry] = useState<string>("");

  useEffect(() => {
    if (selectedChat) {
      setSelectedChat(selectedChat);
      setChatOpen(true);
    }
  }, [selectedChat]);

  const handleChatOpen = useCallback(
    (chatId: string) => {
      setSelectedChat(chatId);
      setChatOpen(true);
    },
    [setChatOpen]
  );

  const handleUserSelect = (newUser: CommunityMember) => {
    const newArray = [...selectedUsers];
    if (newArray.find((selUser) => selUser.uid === newUser.uid)) {
      const index = newArray.findIndex(
        (selUser) => selUser.uid === newUser.uid
      );
      newArray.splice(index, 1);
      return setSelectedUsers(newArray);
    }
    return setSelectedUsers((prev) => [...prev, newUser]);
  };

  return (
    <>
      {/* Modal for creating new chat */}
      <NewChatModal
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        community={community}
        chats={chats}
        selectedChat={selectedChat || ""}
        setSelectedChat={setSelectedChat}
        setChatOpen={setChatOpen}
      />

      <div className="chat-messages">
        <h3>Messages</h3>
        <div className="chat-messages__search">
          <input
            onChange={(e) => {
              setSearchQuerry(e.target.value);
            }}
            value={searchQuerry}
            type="search"
            placeholder="Search"
            className="base-input"
          />
          <SearchIcon color="#818694" />
          <div
            className="chat-messages__create"
            onClick={() => setModalOpen(true)}
            role="button"
            aria-hidden
          />
        </div>

        {/* List with exisiting chats */}
        <div id="chat-user">
          <div className="chat-messages__recent">
            {!loading && chats
              ? chats.map((chatId) => {
                  return (
                    <>
                      <ChatSingleUserItem
                        community={community}
                        currentChatId={chatId}
                        searchQuerry={searchQuerry}
                        selectedChat={selectedChat}
                        handleChatOpen={handleChatOpen}
                        isChatOpen={isChatOpen}
                      />
                    </>
                  );
                })
              : ""}

            {loading &&
              Array.from(Array(8)).map(() => {
                return (
                  <div
                    key="placeholder"
                    className="chat-messages__user"
                    role="button"
                    aria-hidden
                  >
                    <div className="chat-messages__user--info">
                      <div
                        className={`chat-messages__user--image ${
                          loading ? "placeholder placeholder-wave" : ""
                        }`}
                      >
                        <BaseLogo
                          logoSrc={placeholderImage}
                          isPlaceHolder={undefined}
                          name="placeholder"
                        />
                      </div>
                      <div className="chat-messages__preview">
                        <h4 className="placeholder placeholder-wave">
                          {" "}
                          Placeholder
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
