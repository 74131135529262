export const createGatherInitialValues = {
  type: "private",
  name: "",
  logoUrl: "",
  description: "",
  meetType: "offline",
  meetTypeSecondary: "",
  meetUrlOrAddress: "",
  eventFrequencyType: "one-time",
  isAllDay: false,
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  games: [],
} as CreateGatherTypes;

export type CreateGatherTypes = {
  startTime?: any;
  endTime?: any;
  name: string;
  type: "private" | "public";
  meetType: "offline" | "online";
  meetTypeSecondary: "discord" | "teams" | "skype" | "zoom" | string;
  meetUrlOrAddress: string;
  games?: string[];
  logoUrl: string;
  logoUrlFile: File;
  description?: string;
  eventFrequencyType: "one-time" | "week" | "month" | "year";
  isAllDay: boolean;
  startDate: any;
  endDate: any;
};
