import { create } from "zustand";
import { persist } from "zustand/middleware";

// WARN: DO NOT CALL THIS FUNCTION! THIS FUNCTION IS TO ONLY CALL FROM useCloudFunctionCall hook.
export const useGeneralStoreForCloudFunctions = create((set) => ({
  data: {},
  dispatchSingleData: (data: any, cloud_function_name: string) =>
    set((state: any) => {
      const prevData = state.data[cloud_function_name] || [];

      return {
        data: {
          ...state.data,
          [cloud_function_name]: [data, ...prevData],
        },
      };
    }),
  dispatchData: (data: any, cloud_function_name: string, merge?: boolean) =>
    set((state: any) => {
      const prevData = state.data[cloud_function_name] || [];
      if (merge) {
        return {
          data: {
            ...state.data,
            [cloud_function_name]: [...prevData, ...data],
          },
        };
      } else {
        return {
          data: {
            ...state.data,
            [cloud_function_name]: data,
          },
        };
      }
    }),
}));

export const useGeneralStoreForCloudFunctionsForLists = create((set) => ({
  data: {},
  hasMore: {},
  lastFeedDateInSeconds: {},
  setHasMore: (key: string, more: boolean) =>
    set((state: any) => {
      return {
        ...state,
        hasMore: {
          ...state.hasMore,
          [key]: more,
        },
      };
    }),
  setLastFeedDateInSeconds: (key: string, _lastFeedDateInSeconds: number) =>
    set((state: any) => {
      return {
        ...state,
        lastFeedDateInSeconds: {
          ...state.lastFeedDateInSeconds,
          [key]: _lastFeedDateInSeconds,
        },
      };
    }),
  dispatchSingleData: (data: any, key: string) =>
    set((state: any) => {
      const prevData = state.data[key] || [];
      return {
        data: {
          ...state.data,
          [key]: [data, ...prevData],
        },
      };
    }),
  dispatchData: (data: any, key: string, merge?: boolean) =>
    set((state: any) => {
      const prevData = state.data[key] || [];

      if (merge) {
        return {
          ...state,
          data: {
            ...state.data,
            [key]: [...prevData, ...data],
          },
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          [key]: data,
        },
        hasMore: {
          ...state.hasMore,
          [key]: true,
        },
      };
    }),
}));

// Do not persist the leaderboard, it will give false result
export const useGeneralStoreForLeaderboards = create(
  // persist(
  (set) => ({
    data: {},
    hasMore: {},
    lastFeedDateInSeconds: {},
    setHasMore: (key: string, more: boolean) =>
      set((state: any) => {
        return {
          ...state,
          hasMore: {
            ...state.hasMore,
            [key]: more,
          },
        };
      }),
    setLastFeedDateInSeconds: (key: string, _lastFeedDateInSeconds: number) =>
      set((state: any) => {
        return {
          ...state,
          lastFeedDateInSeconds: {
            ...state.lastFeedDateInSeconds,
            [key]: _lastFeedDateInSeconds,
          },
        };
      }),
    dispatchSingleData: (data: any, key: string) =>
      set((state: any) => {
        const { participants, currentUser } = data;

        const prevData = state.data[key] || [];
        // update the participants alone, if merge is true
        return {
          ...state.data,
          data: {
            ...state.data,
            [key]: {
              participants: [...prevData.participants, ...participants],
            },
          },
        };
      }),
    dispatchData: (data: any, key: string, merge?: boolean) =>
      set((state: any) => {
        const { participants, currentUser } = data;

        if (merge) {
          const prevData = state.data[key] || [];
          // update the participants alone, if merge is true
          return {
            ...state,
            data: {
              ...state.data,
              [key]: {
                participants: [...prevData.participants, ...participants],
              },
            },
          };
        }
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              participants: participants,
              currentUser: currentUser,
            },
          },

          hasMore: {
            ...state.hasMore,
            [key]: true,
          },
        };
      }),
  })
);

export const useGeneralStoreForCloudFunctionsForListsPersisted = create(
  persist(
    (set) => ({
      data: {},
      hasMore: {},
      lastFeedDateInSeconds: {},
      setHasMore: (key: string, more: boolean) =>
        set((state: any) => {
          return {
            ...state,
            hasMore: {
              ...state.hasMore,
              [key]: more,
            },
          };
        }),
      setLastFeedDateInSeconds: (key: string, _lastFeedDateInSeconds: number) =>
        set((state: any) => {
          return {
            ...state,
            lastFeedDateInSeconds: {
              ...state.lastFeedDateInSeconds,
              [key]: _lastFeedDateInSeconds,
            },
          };
        }),
      dispatchSingleData: (newData: any, key: string) =>
        set((state: any) => {
          const prevData = state.data[key] || [];

          return {
            ...state,
            data: {
              ...state.data,
              [key]: [newData, ...prevData],
            },
          };
        }),
      dispatchData: (data: any, key: string, merge?: boolean) =>
        set((state: any) => {
          const prevData = state.data[key] || [];
          if (merge) {
            return {
              ...state,
              data: {
                ...state.data,
                [key]: [...prevData, ...data],
              },
            };
          }
          return {
            ...state,
            data: {
              ...state.data,
              [key]: data,
            },
            hasMore: {
              ...state.hasMore,
              [key]: true,
            },
          };
        }),
    }),
    {
      name: "cloud_functions_for_list_data",
    }
  )
);

export const useGeneralFirestoreQueryStore = create(
  persist(
    (set) => ({
      data: {},
      dispatchData: (data: any, entity: string, merge?: boolean) =>
        set((state: any) => {
          const prevData = state.data[entity] || [];
          if (merge) {
            return {
              data: {
                ...state.data,
                [entity]: [...prevData, ...data],
              },
            };
          }
          return {
            data: {
              ...state.data,
              [entity]: data,
            },
          };
        }),
    }),
    {
      name: "firestore_query_persistance",
    }
  )
);

export const useGeneralFirestoreDocumentStore = create(
  persist(
    (set) => ({
      data: {},
      dispatchData: (data: any, entity: string) =>
        set((state: any) => {
          return {
            data: {
              ...state.data,
              [entity]: data,
            },
          };
        }),
    }),
    {
      name: "persistance_firestore_document_data",
    }
  )
);
