type NotificationType =
  | "request-to-join-team"
  | "teams-requests"
  | "gather-requests"
  | "challenges-requests"
  | "friends-requests"
  | "message-recieved"
  | "comment-mention"
  | "post-mention"
  | "post-commented"
  | "member-joined-challenge";

export const showActionTypes = {
  "request-to-join-team": {
    type: "teams-requests",
    notificationRespType: "teams-requests-accepted",
    collection: "teams",
  },
  "teams-requests": {
    type: "teams-requests",
    notificationRespType: "teams-requests-accepted",
    collection: "teams",
  },

  "gather-requests": {
    type: "gather-requests",
    notificationRespType: "gather-requests-accepted",
    collection: "gathers",
  },
  "challenges-requests": {
    type: "challenges-requests",
    notificationRespType: "challenges-requests-accepted",
    collection: "challenges",
  },
  "friends-requests": {
    type: "friends-requests",
    notificationRespType: "friends-requests-accepted",
    collection: "profiles",
  },
} as {
  [key in NotificationType]: {
    type: string;
    notificationRespType: string;
    collection: string;
  };
};
export const notificationRoutes = {
  "teams-requests": "teams",
  "member-joined-team": "teams",
  "removed-from-team": "teams",
  "teams-removed-by-admin": "teams",
  "teams-admin-rights": "teams",
  "request-to-join-team": "teams",
  "teams-requests-accepted": "teams",
  "team-post": "teams",
  "gather-requests": "gathers",
  "challenges-requests": "challenges",
  "member-joined-challenge": "challenges",
  "challenges-requests-accepted": "challenges",
  "friends-requests": "profile",
  "message-recieved": "post",
  "comment-mention": "post",
  "post-mention-teams": "teams",
  "post-mention-news": "news",
  "post-mention-gathers": "gathers",
  "post-mention-posts": "post",
  "post-mention-feed": "post",
  "post-commented": "post",
  "other-user-comment": "post",
  "post-liked": "post",
};
