import { FC, useEffect } from "react";
import i18next from "i18next";
import gamesIcons from "@/features/profile/utils/ConnectGameAccounts";
import { Button } from "@/components";
import { cloudFunctionUrl } from "@/features/auth/components/AuthConnectOptions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import refreshSteamConnection from "@/features/profile/helperFunctions/refreshSteamConnection";

export const SettingsAccountsHeader: FC<{}> = () => {
  return (
    <div className="settings__header">
      <h4>{i18next.t("profile.edit.connections.title")}</h4>
      <p>
        {i18next.t("profile.edit.connections.desc1")} {brand.name}{" "}
        {i18next.t("profile.edit.connections.desc2")}
      </p>
    </div>
  );
};
