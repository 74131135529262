import { useEffect } from "react";
import { cannonEffect } from "./effects/cannonEffects";
import { customshapesEffect } from "./effects/customshapesEffect";
import { emojiEffect } from "./effects/emojiEffect";
import { fireworksEffect } from "./effects/fireworksEffect";
import { randomDirectionEffect } from "./effects/randomDirectionEffect";
import { realisticEffect } from "./effects/realisticEffect";
import { schoolprideEffect } from "./effects/schoolprideEffect";
import { snowEffect } from "./effects/snowEffect";
import { starsEffect } from "./effects/starsEffect";

export const createEffects = ({
  effect,
}: {
  effect:
    | "cannon"
    | "randomdirection"
    | "realistic"
    | "fireworks"
    | "stars"
    | "snow"
    | "schoolpride"
    // | "customshapes" // Heavy effect
    | "emoji"
    | "random";
}) => {
  const EffectsFunctions = {
    cannon: cannonEffect,
    customshapes: customshapesEffect,
    emoji: emojiEffect,
    fireworks: fireworksEffect,
    randomdirection: randomDirectionEffect,
    realistic: realisticEffect,
    schoolpride: schoolprideEffect,
    snow: snowEffect,
    stars: starsEffect,
  };

  const getRandomEffect = () => {
    const effectsArray = Object.values(EffectsFunctions);
    const randomIndex = Math.floor(Math.random() * effectsArray.length);
    return effectsArray[randomIndex]();
  };

  try {
    if (effect === "random") {
      return getRandomEffect();
    }

    return EffectsFunctions[effect]();
  } catch {
    EffectsFunctions["cannon"]();
  }
};
