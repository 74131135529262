export const createChallengeTooltipsText: { [key: string]: JSX.Element } = {
  criteria: (
    <p>
        Choose between simple or advanced criteria.
    </p> //The text before was: Enter one or many criterias to be multiplied by the respective multiplier. 
  ),
  onlyWins: (
    <>
      <p>
        <strong>Record based:</strong> Highest score in a single match wins.
      </p>
      <p>
        <strong>Accumulative: </strong> Points are adding up every match you
        play.
      </p>
    </>
  ),
  type: (
    <>
      <p>
        <strong>Private: </strong>
        Only invited users can participate in this challenge.
      </p>
      <p>
        <strong>Public: </strong>
        Any user can participate in this challenge.
      </p>
    </>
  ),
  tooltipCriteriasimple: (
    <>
      <p>
        Choose between maximum or minimum and select the criteria for success.
      </p>
    </>
  ),

  tooltipCriteriaadvanced: (
    <>
      <p>
        Create the formula of success. Higher multiplier gives more points per
        criteria.
      </p>
    </>
  ),

  mapSelect: (
    <>
      <p>
        <strong>Any: </strong>
        Score will be tracked across all maps.
      </p>
      <p>
        <strong>Custom: </strong>
        Score will only be tracked on the selected map.
      </p>
    </>
  ),

  gameModeSelect: (
    <>
      <p>
        <strong>Any: </strong>
        Score will be tracked across all game modes.
      </p>
      <p>
        <strong>Custom: </strong>
        Score will only be tracked on the selected game mode.
      </p>
    </>
  ),
};
