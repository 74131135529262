import { Button } from "@/components";
import wog_image from "../../assets/image.png"
import React, { useCallback, useEffect, useState } from "react";
import InfoCounter from "../InfoCounter";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
interface LandingHeroProps {
    headerHeight: number;
    headline?: string;
    headlineColored?: string;
    description?: string;
    image?: string;
    infoCounter?: boolean;
    btn_label?: string;
}

const LandingHero: React.FC<LandingHeroProps> = ({ 
    headerHeight,
    headline,
    headlineColored,
    description,
    image,
    infoCounter,
    btn_label,
}) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const windowWidth = window.screen.width;

    const handleResize = useCallback(() => {
        setIsMobile(windowWidth < 991);
    }, []);

    useEffect(() => {
        // Initial check
        handleResize();

        // Event listener for window resize
        window.onresize = handleResize;

        // Cleanup (optional since we're setting window.onresize directly)
        return () => {
            window.onresize = null;
        };
    }, [handleResize]);

    return (
        <div 
            className="landing__hero"            
            style={{ height: !isMobile ? `calc(100vh - (32px + ${headerHeight}px))` : "auto" }}
        >
            <div className="info">
                <div className="info__content">
                    <div className="info__body">
                        <div className="info__heading">
                            <h1>
                                <span>{headlineColored ? headlineColored : i18next.t("landing.hero.headlineColored")}</span> {headline ? headline : i18next.t("landing.hero.headline")}
                            </h1>
                            <p>
                                {description ? description : i18next.t("landing.hero.description")}
                            </p>
                            <Button 
                                variant="primary"
                                onClickAction={() => navigate("/signup")}
                            >
                                {btn_label ? btn_label : i18next.t("landing.hero.cta")}
                            </Button>
                        </div>
                        {infoCounter !== false && (
                            <InfoCounter />
                        )}
                    </div>
                </div>
                <div className="info__image">
                    {image ? (
                        <img src={image} alt="" />
                    ) : (
                        <div className="blur" >
                        <img src={wog_image} alt="" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LandingHero;