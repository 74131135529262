import { FC } from "react";
import StepImage from "../assets/step3.png";
import { Link } from "react-router-dom";

const TrackerStepThree: FC = (): JSX.Element => {
  return (
    <div className="steps__3">
      <div className="steps__item">
          <span className="border-cut" />
          <span className="border-cut" />
          <span className="border-cut" />
          <div className="steps__number">
            <span>3</span>
          </div>
          <div className="steps__content steps__grid steps__grid--2">
            <div className="steps__body">
                <h2>Play and compete</h2>
                <ol>
                    <li>
                        Visit the challenges section on World of Gamers platform to discover ongoing competitions. Join challenges, compete with other users, and see how you stack up on the leaderboards.
                    </li>
                    <li>
                        Launch your favorite games and start playing as you normally would. The tracker works silently in the background, capturing valuable data.
                    </li>
                    <li>
                        Head over to the leaderboard section of the challenges you have joined to see where you stand. Track your progress, compare your stats with friends, and strive to reach the top.
                    </li>
                </ol>
                <div className="steps__actions">
                  <Link to="/challenges" className="btn btn-secondary">
                    See all challenges
                  </Link>
                </div>
            </div>
            <div className="steps__image">
                <img src={StepImage} />
            </div>
        </div>
      </div>
      <span className="steps__endline" />
    </div>
  );
};

export default TrackerStepThree;