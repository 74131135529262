import BaseUserAvatar from "@/components/BaseUserAvatar";
import { FC } from "react";
import { Author, singleNewsOverviewTypes } from "../../types/NewsTypes";
import { getDateInStringFormat } from "@/utils/constants/formatDate";

export const SingleNewsMeta: FC<{
  data: singleNewsOverviewTypes & {
    author: Author;
  };
}> = ({ data }) => {
  return (
    <div className="article__meta">
      <div className="article__author">
        <div className="author">
          <div className="author__image">
            <BaseUserAvatar
              userName={data.author?.userName}
              logoUrl={data.author?.profileLogoUrl}
            />
          </div>
          <div className="author__username">
            <span>{data.author?.userName}</span>
          </div>
        </div>
      </div>

      <div className="article__posted">
        <time>{getDateInStringFormat(data?.created._seconds)}</time>
      </div>
    </div>
  );
};
