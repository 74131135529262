import i18next from "i18next";
import React from "react";

interface Props {
    client: string,
}

const ChallengeTerms: React.FC<Props> = ({
  client,
}) => {
    const brand = client;
    
    return (
        <div className="challenge__terms">
            <h3>{i18next.t("challenge.terms.heading")}</h3>
            <p>
                {i18next.t("challenge.terms.preBrand")} {brand} {i18next.t("challenge.terms.postBrand")}
            </p> 
            
        </div>
    );
};

export default ChallengeTerms;