const baseUri = "https://ik.imagekit.io/gmng/DefaultAvatars";
const folderIntelligence = `${baseUri}/GoodVsBad`;
const folderRacing = `${baseUri}/Racing`;
const folderCasual = `${baseUri}/Casual`;
const folderBattlegrounds = `${baseUri}/Battlegrounds`;

export const Avatars = [
  {
    category: "Intelligence",
    values: [
      { img: `${folderIntelligence}/1.jpg`, },
      { img: `${folderIntelligence}/2.jpg`, },
      { img: `${folderIntelligence}/3.jpg`, },
      { img: `${folderIntelligence}/4.jpg`, },
      { img: `${folderIntelligence}/5.jpg`, },
      { img: `${folderIntelligence}/6.jpg`, },
      { img: `${folderIntelligence}/7.jpg`, },
      { img: `${folderIntelligence}/8.jpg`, },
      { img: `${folderIntelligence}/9.jpg`, },
      { img: `${folderIntelligence}/10.jpg`, },
    ],
  },
  {
    category: "Racing",
    values: [
      { img: `${folderRacing}/1.jpg`, },
      { img: `${folderRacing}/2.jpg`, },
      { img: `${folderRacing}/3.jpg`, },
      { img: `${folderRacing}/4.jpg`, },
      { img: `${folderRacing}/5.jpg`, },
      { img: `${folderRacing}/6.jpg`, },
      { img: `${folderRacing}/7.jpg`, },
      { img: `${folderRacing}/8.jpg`, },
      { img: `${folderRacing}/9.jpg`, },
      { img: `${folderRacing}/10.jpg`, },
    ],
  },
  {
    category: "Casual",
    values: [
      { img: `${folderCasual}/1.jpg`, },
      { img: `${folderCasual}/2.jpg`, },
      { img: `${folderCasual}/3.jpg`, },
      { img: `${folderCasual}/4.jpg`, },
      { img: `${folderCasual}/5.jpg`, },
      { img: `${folderCasual}/6.jpg`, },
      { img: `${folderCasual}/7.jpg`, },
      { img: `${folderCasual}/8.jpg`, },
      { img: `${folderCasual}/9.jpg`, },
      { img: `${folderCasual}/10.jpg`, },
    ],
  },
  {
    category: "Battlegrounds",
    values: [
      { img: `${folderBattlegrounds}/1.jpg`, },
      { img: `${folderBattlegrounds}/2.jpg`, },
      { img: `${folderBattlegrounds}/3.jpg`, },
      { img: `${folderBattlegrounds}/4.jpg`, },
      { img: `${folderBattlegrounds}/5.jpg`, },
      { img: `${folderBattlegrounds}/6.jpg`, },
      { img: `${folderBattlegrounds}/7.jpg`, },
      { img: `${folderBattlegrounds}/8.jpg`, },
      { img: `${folderBattlegrounds}/9.jpg`, },
      { img: `${folderBattlegrounds}/10.jpg`, },
    ],
  },
];

export const avatarsRaw = [
  `${folderIntelligence}/1.jpg`,
  `${folderIntelligence}/2.jpg`,
  `${folderIntelligence}/3.jpg`,
  `${folderIntelligence}/4.jpg`,
  `${folderIntelligence}/5.jpg`,
  `${folderIntelligence}/6.jpg`,
  `${folderIntelligence}/7.jpg`,
  `${folderIntelligence}/8.jpg`,
  `${folderIntelligence}/9.jpg`,
  `${folderIntelligence}/10.jpg`,
  `${folderRacing}/1.jpg`,
  `${folderRacing}/2.jpg`,
  `${folderRacing}/3.jpg`,
  `${folderRacing}/4.jpg`,
  `${folderRacing}/5.jpg`,
  `${folderRacing}/6.jpg`,
  `${folderRacing}/7.jpg`,
  `${folderRacing}/8.jpg`,
  `${folderRacing}/9.jpg`,
  `${folderRacing}/10.jpg`,
  `${folderCasual}/1.jpg`,
  `${folderCasual}/2.jpg`,
  `${folderCasual}/3.jpg`,
  `${folderCasual}/4.jpg`,
  `${folderCasual}/5.jpg`,
  `${folderCasual}/6.jpg`,
  `${folderCasual}/7.jpg`,
  `${folderCasual}/8.jpg`,
  `${folderCasual}/9.jpg`,
  `${folderCasual}/10.jpg`,
  `${folderBattlegrounds}/1.jpg`,
  `${folderBattlegrounds}/2.jpg`,
  `${folderBattlegrounds}/3.jpg`,
  `${folderBattlegrounds}/4.jpg`,
  `${folderBattlegrounds}/5.jpg`,
  `${folderBattlegrounds}/6.jpg`,
  `${folderBattlegrounds}/7.jpg`,
  `${folderBattlegrounds}/8.jpg`,
  `${folderBattlegrounds}/9.jpg`,
  `${folderBattlegrounds}/10.jpg`,
];
