import { FC } from "react";
import { SettingDisconnectModal } from "./SettingDisconnectModal";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ConnectPubgForm } from "./ConnectPubgForm";
import { ConnectCoDForm } from "./ConnectCoDForm";
import { SettingsAccountsTypes } from "@/features/profile/types/SettingsAccountsTypes";
import { disconnectGameAccount } from "@/features/profile/helperFunctions/disconnectGameAccount";

export const SettingsAccountsModals: FC<SettingsAccountsTypes> = ({
  disconnectedBnetModal,
  setDisconnectedBnetModal,
  setLinkedBattletag,
  disconnectedSteamModal,
  setSteamId,
  setDisconnectedSteamModal,
  disconnectedPubgModal,
  setPubgUsername,
  setDisconnectedPubgModal,
  openPubgModal,
  setOpenPubgModal,
  openCoDModal,
  setOpenCoDModal,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  return (
    <>
      <BaseModalWindow
        show={openPubgModal}
        closeModal={() => {
          setOpenPubgModal(false);
        }}
        size="modal--connect-account"
        title="Connect account"
      >
        <>
          <p>
            Connect your PUBG account to participate in PUBG challenges on
            {brand.name}.
          </p>
          <ConnectPubgForm
            onHide={() => setOpenPubgModal(false)}
            setUser={setPubgUsername}
          />
        </>
      </BaseModalWindow>

      <BaseModalWindow
        show={openCoDModal}
        closeModal={() => {
          setOpenCoDModal(false);
        }}
        size="modal--connect-account"
        title="Connect account"
      >
        <>
          <p>
            Connect your Call of Duty account to participate in Warzone
            Challenges.
          </p>
          <ConnectCoDForm onHide={() => setOpenCoDModal(false)} />
        </>
      </BaseModalWindow>

      <SettingDisconnectModal
        game="PUBG"
        showModal={disconnectedPubgModal}
        handleDisconnecting={async () => {
          await disconnectGameAccount("profilePubg", currentUser.uid);
          setPubgUsername(null);
          setDisconnectedPubgModal(false);
        }}
        handleCloseWindow={() => setDisconnectedPubgModal(false)}
      />

      <SettingDisconnectModal
        game="Steam"
        showModal={disconnectedSteamModal}
        handleDisconnecting={async () => {
          await disconnectGameAccount("profileSteam", currentUser.uid);
          setSteamId(null);
          setDisconnectedSteamModal(false);
        }}
        handleCloseWindow={() => setDisconnectedSteamModal(false)}
      />

      <SettingDisconnectModal
        game="Battle.net"
        showModal={disconnectedBnetModal}
        handleDisconnecting={async () => {
          await disconnectGameAccount("profileBnet", currentUser.uid);
          setLinkedBattletag("");

          setDisconnectedBnetModal(false);
        }}
        handleCloseWindow={() => setDisconnectedBnetModal(false)}
      />
    </>
  );
};
