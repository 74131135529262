import React, { useEffect, useState } from "react";
import { Button } from "@/components/index";
import EmojiPicker from "emoji-picker-react";
import icon_emoji from "@/assets/actions/emoji.svg";
import EmojiWrapper from "./EmojiWrapper";

const EmojiContainer: React.FunctionComponent<{
  setInputText: (arg: string) => void;
  inputText: string | undefined;
  setShowImoji: React.Dispatch<React.SetStateAction<boolean>>;
  showImoji: boolean;
}> = ({ setInputText, inputText, setShowImoji, showImoji }) => {
  const [lastClickedEmoji, setLastClickedEmoji] = useState<string>("");
  const clickHandler = () => {
    setShowImoji(!showImoji);
  };
  useEffect(() => {
    if (lastClickedEmoji) {
      setInputText(`${inputText}${lastClickedEmoji}`);
      setLastClickedEmoji(undefined);
    }
  }, [lastClickedEmoji]);
  return (
    <>
      <EmojiWrapper show={showImoji} setShow={setShowImoji}>
        <div className="form__emoji-picker">
          <EmojiPicker
            emojiVersion="0.6"
            lazyLoadEmojis={true}
            onEmojiClick={(emojiObject) => {
              setLastClickedEmoji(emojiObject.emoji);
            }}
          />
        </div>
      </EmojiWrapper>
      <Button
        variant="link"
        onClickAction={clickHandler}
        customStyles="form__emoji-select"
      >
        <img src={icon_emoji} alt="emoji" />
      </Button>
    </>
  );
};
export default EmojiContainer;
