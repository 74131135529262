import { ReactComponent as Feed } from "../../../layout/sideNavigation/assets/icon_feed.svg";
import { ReactComponent as Friends } from "../../../layout/sideNavigation/assets/icon_friends.svg";
import { ReactComponent as Messages } from "../../../layout/sideNavigation/assets/icon_messages.svg";
import { ReactComponent as Zones } from "../../../layout/sideNavigation/assets/icon_zones.svg";
import { ReactComponent as Cup } from "../../../layout/sideNavigation/assets/icon_tournaments.svg";
import { ReactComponent as Gathers } from "../../../layout/sideNavigation/assets/icon_gathers.svg";
import { ReactComponent as News } from "../../../layout/sideNavigation/assets/icon_news.svg";
import { ReactComponent as Teams } from "../../../layout/sideNavigation/assets/icon_teams.svg";
import { ReactComponent as ChallengesLeft } from "../../../layout/sideNavigation/assets/challenges.svg";
import { ReactComponent as TrackerApp } from "../../../layout/sideNavigation/assets/flag.svg";
import { ReactComponent as SmartPhone } from "../../../../src/assets/icons/smartphone.svg";
import {
  brand,
  scheduleData,
} from "../../../utils/brandVariables/brandVariables";
import { siteRoutes } from "../../../routes/siteRoutesList";
// import { ReactComponent as LogitechLogoSmall } from "../assets/logitech/logitech-g-sm.svg";
import { ReactComponent as TCLLogo } from "../assets/tcl/tcl-logo.svg";
import { ReactComponent as PCSpecialistLogoSmall } from "../assets/pcspecialist/pcs-logo-main_small.svg";
import { ReactComponent as Elgiganten } from "../assets/elgiganten/elgiganten_sm_white.svg";
import { ReactComponent as JBL } from "../assets/jbl-quantum/quantum_logo.svg";
import { ReactComponent as TreasureHuntGift } from "../assets/gift.svg";

export const Elgiganten_siteRoutes = {
  social: [
    brand.hasFeature.feed && {
      title: "strings.feed",
      link: siteRoutes["dashboard"],
      logo: Feed,
    },
    // { title: "Friends", link: "friends", logo: Friends },
    brand.hasFeature.messages && {
      title: "Messages",
      link: siteRoutes["messages"],
      logo: Messages,
    },

    brand.hasFeature.teams && {
      title: "strings.teams",
      link: siteRoutes["teams"],
      logo: Teams,
    },
    // { title: "Zones", link: "zones", logo: Zones },
    brand.hasFeature.news && {
      title: "strings.news",
      link: siteRoutes["news"],
      logo: News,
    },
  ],
  activities: [
    {
      title: "1337 Challenge",
      link: siteRoutes["leet"],
      logo: Elgiganten,
    },
    // {
    //   title: "TCL Live",
    //   link: siteRoutes["tcl_logs"],
    //   logo: Elgiganten,
    // },
    // {
    //   title: "Space Coins",
    //   link: `https://browser-game.web.app`,
    //   logo: Elgiganten,
    // },
    // {
    //   title: "Treasure Hunt",
    //   link: siteRoutes["treasureHunt"],
    //   logo: Elgiganten,
    // logo: TreasureHuntGift,
    // },
    // brand.hasFeature.challenges && {
    //   title: "strings.challenges",
    //   link: siteRoutes["challenges"],
    //   logo: ChallengesLeft,
    // },
    brand.hasFeature.getstarted && {
      title: "Getting started",
      link: "/tracker",
      logo: TrackerApp,
    },
    brand.hasFeature.gathers && {
      title: "strings.gathers",
      link: siteRoutes["gathers"],
      logo: Gathers,
    },
    brand.hasFeature.games && {
      title: "Event games",
      link: siteRoutes["gamesPage"],
      logo: SmartPhone,
    },
  ],
  winners: [
    brand.hasFeature.winners && {
      title: "Winners",
      link: siteRoutes["winners"],
      logo: Cup,
    },
  ],
  challenges: [
    {
      title: "TCL 1v1 Challenge",
      link: siteRoutes["tcl"],
      logo: TCLLogo,
    },
    {
      title: "JBL Quantum Trial",
      link: siteRoutes["jbl"],
      logo: JBL,
    },
    // {
    //   title: "PCSpecialist",
    //   link: siteRoutes["pcspecialist"],
    //   logo: PCSpecialistLogoSmall,
    // },
    {
      title: "F1® 23 Challenge",
      link: siteRoutes["racing"],
      logo: Elgiganten,
    },
  ],
  [scheduleData?.sideMenuTitle || ""]: [
    brand.hasFeature.schedule && {
      title: "Schedule",
      link: siteRoutes["schedule"],
      logo: undefined,
    },
  ],
};
