import React, { FC, useState, useEffect } from "react";
import { SingleTeamResponseTypes } from "../../types/TeamTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { SingleTeamSeeAllOtherButtons } from "./SingleTeamSeeAllOthersButtons";
import { SingleTeamSeeAllOtherUser } from "./SingleTeamSeeAllOthersUser";
import SingleTeamSeeAllModalMembersLoader from "./SingleTeamSeeAllModalMembersLoader";

export const SingleTeamSeeAllOthers: FC<{
  userRole: "owner" | "admin" | "member" | "random";
  teamData: SingleTeamResponseTypes;
  isFullPage?: boolean;
  handleAcceptUser: (uid: string) => void;
  statusType: "rejected" | "pendingRequest" | "banned";
}> = ({ userRole, teamData, handleAcceptUser, isFullPage, statusType }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [
    rejectedTeamMembersData,
    _loadingRejectedMembers,
    _errorRejectedMembers,
    fetch_rejectedTeamMembers,
  ] = useCloudFunctionCall<any[] | any>(
    "getTeamMembersBasedOnStatus",
    `_${statusType}_${currentUser.slug}`
  );
  const [usersToRender, setUsersToRender] = useState<any[]>();

  //Fetch the rejected Requests to join the team
  useEffect(() => {
    fetch_rejectedTeamMembers(
      {
        slug: teamData.slug,
        seeAll: false,
        status: statusType,
      },
      false
    );
  }, [teamData.slug]);

  useEffect(() => {
    setUsersToRender(rejectedTeamMembersData);
  }, [rejectedTeamMembersData]);

  const handleUpdateList = (userToRemoveUid: string) => {
    setUsersToRender((prevUsers) =>
      prevUsers.filter((user) => user.uid !== userToRemoveUid)
    );
  };

  // Variables
  const hasAdminRights = userRole === "owner" || userRole === "admin";
  const hasPendingRequest = usersToRender?.length !== 0;
  const noPendingRequest = rejectedTeamMembersData?.length === 0;
  const isRejectedTab = statusType === "rejected";
  const isPendingTab = statusType === "pendingRequest";
  const isBannedTab = statusType === "banned";

  // Loading state
  if (_loadingRejectedMembers) {
    return <SingleTeamSeeAllModalMembersLoader />;
  }

  // Not loading and has no pending requests
  if (!_loadingRejectedMembers && noPendingRequest) {
    return (
      <>
        {isRejectedTab && <p>No rejected users.</p>}
        {isPendingTab && <p>No pending requests.</p>}
        {isBannedTab && <p>No banned users.</p>}
      </>
    );
  }

  // Has users to render and pending request while having admin rights
  if (usersToRender && hasPendingRequest && hasAdminRights) {
    return (
      <>
        <ul className="team-members__list">
          {usersToRender?.map((user: any, index) => {
            if (user) {
              return (
                <li
                  className="team-members__item"
                  key={`singleTeamMemberRequests_${index}`}
                >
                  <div className="team-members__member team-members__member--request">
                    <div className="team-members__profile">
                      <SingleTeamSeeAllOtherUser user={user} />
                    </div>
                    <div className="team-members__actions">
                      <SingleTeamSeeAllOtherButtons
                        // handleUpdateJunction={handleUpdateJunction}
                        user={user}
                        statusType={statusType}
                        handleUpdateList={handleUpdateList}
                        handleAcceptUser={handleAcceptUser}
                        teamData={teamData}
                        isFullPage={isFullPage}
                      />
                    </div>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </>
    );
  }

  // Any other case
  return <> </>;
};
