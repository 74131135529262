import React, { FC } from "react";
import DiscussionCardComponent from "./DiscussionCardComponent";

type Props = {
  subject: string;
  details: string;
  mediaUrls: string[];
  commentsCount: number;
  created: {
    _seconds: number;
    _nanoseconds: number;
  };
  authorSlug: string;
  authorUid: string;
  authorUserName: string;
  authorProfileLogoUrl?: string;
  isEdited: boolean;
  gatherPostId: string;
  isPlaceholder?: undefined;
  CommentsAmount: number;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCardIndex: React.Dispatch<React.SetStateAction<number>>;
  indexNumber: number;
};

export const DiscussionCard: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <DiscussionCardComponent
        subject={props.subject}
        details={props.details}
        mediaUrls={props.mediaUrls}
        commentsCount={props.commentsCount}
        created={props.created}
        authorSlug={props.authorSlug}
        authorUid={props.authorUid}
        authorUserName={props.authorUserName}
        authorProfileLogoUrl={props.authorProfileLogoUrl}
        isEdited={props.isEdited}
        gatherPostId={props.gatherPostId}
        isPlaceholder={undefined}
        CommentsAmount={props.CommentsAmount}
        setModal={props.setModal}
        indexNumber={props.indexNumber}
        setSelectedCardIndex={props.setSelectedCardIndex}
      />
    </>
  );
};
