import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import React, { FC, useState, useEffect } from "react";
import NotificationsList from "../bell/NotificationsList";
import { ErrorFallback, Spinner } from "@/components";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export const NotificationPage: FC<{}> = ({}) => {
  const [notifications, setNotifications] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [unreadNotificationsNmbr, setUnreadNotificationNmbr] =
    useState<number>(0);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const databaseRef = firebase.database().ref("notifications");

  // Get all current user notifications
  const getNotifications = async () => {
    if (!currentUser.uid) {
      createToast({
        type: "error",
        message: "Please log in to get notifications.",
      });
      return;
    }

    databaseRef
      .orderByChild("recipientUid")
      .equalTo(currentUser.uid)
      .on("value", async (snapshot) => {
        if (snapshot.val()) {
          const response = Object.values(snapshot.val()).map((snap, index) => {
            return {
              data: snap,
              realtimeDocumentID: Object.entries(snapshot.val())[index][0], // doc uid
            };
          }) as any[];

          const unreadAmountResponse = Object.values(snapshot.val()).filter(
            (obj: any) => obj.read === false
          ).length;
          setLoading(false);
          setNotifications(response.reverse());
          setUnreadNotificationNmbr(unreadAmountResponse);
          return;
        }
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getNotifications();
  }, [currentUser]);
  
  // Page Document Title
  useDocumentTitle('Notifications');

  return (
    <>
      {!error && (
        <div className="notification-page">
          <h1>Notifications</h1>
          <div className="notification-page__grid">
            <NotificationsList
              updateNotifications={() => getNotifications()}
              isFullPage={true}
              notifications={notifications}
              loading={loading}
              setDropdownOpen={() => undefined}
              unreadNotifications={unreadNotificationsNmbr}
            />
          </div>
        </div>
      )}
      {loading && <Spinner />}
      {error && (
        <ErrorFallback>
          <>
            <h3>Failed to load notifications</h3>
            <p>Reason: {error}</p>
          </>
        </ErrorFallback>
      )}
    </>
  );
};
