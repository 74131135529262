import React, { useEffect } from "react";

export default function (ref: React.MutableRefObject<any>, func: any) {
  useEffect(() => {
    // function that runs when clicked outside the item
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
