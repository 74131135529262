import { createToast } from "@/utils/toaster/createToast";
import { _CollectionsNamesWithSlug } from "./common.type";
import firebase from "firebase";

export const _createUserProfileJunction = async (
  collection: string,
  docID: string,
  dataToUpdate: any
) => {
  await firebase
    .firestore()
    .collection(collection)
    .doc(docID)
    .set({
      ...dataToUpdate,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      return true;
    });
};
