import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";
import { useDebounce } from "@/hooks/useDebounce";
import { useEffect } from "react";

export function useValidatorEmailOrUsername(
  type: "email" | "username",
  payload: string,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  const payloadDebounced = useDebounce(payload, 700);
  useEffect(() => {
    if (!!payloadDebounced) {
      (async () => {
        try {
          const response = await CloudFunctions.callCloudFunction(
            "isUsernameInvalid",
            {
              payload: payloadDebounced,
              type,
            }
          );
          if (response === false) {
            setError(undefined);
          } else {
            setError(response);
          }
          return;
        } catch (e) {
          setError("Something went wrong!");
          return;
        }
      })();
    }
  }, [payloadDebounced]);
}
