import React, { useEffect, memo } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import battlenetLogo from "../assets/auth-bnet.svg";
import steamLogo from "../assets/auth-steam.svg";
import {
  EmailAuthProvider_ID,
  GoogleAuthProvider_ID,
  PhoneAuthProvider_ID,
  auth,
} from "@/api/firebase/firebase.config";
import { CustomSignInButton } from "./CustomSignInButton";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

const uiConfig = {
  // signInSuccessUrl: "/challenges",
  signInFlow: "popup",
  signInOptions: [
    brand.authMethod === "email" && GoogleAuthProvider_ID, // Google login
    brand.authMethod === "email" && {
      provider: EmailAuthProvider_ID, // New email login
      requireDisplayName: false,
    },
    brand.authMethod === "phone" && {
      provider: PhoneAuthProvider_ID,
      requireDisplayName: false,
      defaultCountry: brand.defaultCountryCode,
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

export const cloudFunctionUrl =
  "https://europe-west1-world-of-gamers-d663f.cloudfunctions.net/app/oauth/";

const AuthConnectOptions: React.FunctionComponent<{
  from?: string;
}> = ({ from }) => {
  useEffect(() => {
    const el = document.getElementsByClassName("firebaseui-idp-google");
    if (el && el.length !== 0) {
      el[0].addEventListener("click", () => {
        localStorage.setItem("signUpMethod", "google");
      });
    }
  });

  useEffect(() => {
    const el2 = document.getElementsByClassName("firebaseui-idp-password");
    if (el2 && el2.length !== 0) {
      el2[0].addEventListener("click", () => {
        localStorage.setItem("signUpMethod", "email");
      });
    }
  });

  //moved to routes auth /src/pages/routes
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(async (user) => {
  //       if (user) {
  //         const userDocument = await firebase
  //           .firestore()
  //           .collection("profiles")
  //           .doc(user?.uid)
  //           .get();

  //         if (userDocument?.data()?.userName && userDocument?.data()?.region) {
  //           return history.push("/profile");
  //         }

  //         return history.push("/sign-up-2");
  //       }
  //       return true;
  //     });
  //   return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  // }, [history, from]);

  return (
    <>
      <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
        <div className="firebaseui-card-content ps-0">
          {[
            [steamLogo, "#2D2E33", "steam", "STEAM", "STEAM"],
            [battlenetLogo, "#2D2E33", "bnet", "Battle.net", "Battle.net"],
          ].map(([logo, backgroundColor, suffix, longText, shortText]) => (
            <CustomSignInButton
              logo={logo}
              backgroundColor={backgroundColor}
              url={`${cloudFunctionUrl}${suffix}`}
              longText={longText}
              shortText={shortText}
              key={shortText}
            />
          ))}
        </div>
      </div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </>
  );
};

export default memo(AuthConnectOptions);
