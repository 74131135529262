import React from "react";

type Props = {
  treasureHuntCodes: {
    code: string;
    codeIndex: number;
    location: string;
  }[];
  usersCollectedData: {
    key: string;
    value: boolean;
  }[];
};

const Checkpoints: React.FC<Props> = ({
  treasureHuntCodes,
  usersCollectedData,
}) => {
  return (
    <div className="checkpoints">
      <div className="checkpoints__heading">
        <h2>Find the clues</h2>
        <p>Find all the clues from this list!</p>
      </div>
      <ul className="checkpoints__list">
        {Object.entries(treasureHuntCodes).map(([key, value], index) => {
          return (
            <li
              className={`${
                usersCollectedData[key]
                  ? "checkpoints__item checkpoints__item--collected"
                  : "checkpoints__item"
              }`}
              data-id={key}
            >
              <div className="point">
                <div className="point__value">Point {index + 1}</div>
                <div className="point__label">{value.location}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Checkpoints;
