import { FC } from "react";
import { brand } from "../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Container } from "react-bootstrap";
import PartnerLogo from "../../../../../../branchAndBrandSpecific/utils/brandVariables/assets/bestgamer/partnerLogo.svg";

const images = [
  PartnerLogo,
  PartnerLogo,
  PartnerLogo,
  PartnerLogo,
  PartnerLogo,
  PartnerLogo,
];

export const BestgamerLandingPartners: FC<{}> = ({}) => {

  return (
    <div className="partners">
      <Container>
        <div className="partners__slider">
          <div className="partners__list">
            {images.map((image, index) => (
                <div className="partners__item" key={index}>
                  <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
