import React from "react";
import "./styles/event-games.scss";
import { ReactComponent as ElgigantenLogo } from "../../utils/brandVariables/assets/elgiganten/elgiganten.svg";
import spaceastronaut from "./assets/Capture3.png";
import spaceschooter from "./assets/scc.jpg";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
export const GamesPage = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);
  return (
    <div className="event-games">
      <div
        className="event-games__1337"
        onClick={() => {
          navigate(siteRoutes["leet"]);
        }}
      >
        <div>
          <ElgigantenLogo />
          <p>1337 challenge</p>
        </div>
      </div>

      <div
        className="event-games__space-astronaut"
        onClick={() => {
          window.open("https://browser-game.web.app/", "_blank");
        }}
      >
        <div>
          <img src={spaceastronaut} alt="" />
          <p>Space astronaut</p>
        </div>
      </div>

      <div
        className="event-games__space-astronaut"
        onClick={() => {
          if (currentUser) {
            window.open(
              `https://browser-game.web.app/${currentUser.uid}_${currentUser.slug}`,
              "_blank"
            );
          }
        }}
      >
        <div>
          <img src={spaceschooter} alt="" />
          <p>Space coins</p>
        </div>
      </div>
    </div>
  );
};
