import { Button } from "@/components";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { formattedTime } from "@/features/challenge/helperFunctions/formatMsToTime";
import { FC } from "react";

export const SingleChallengeBestgamerLeaderboardCurrentUser: FC<{
  currentUserData: any;
  currentUser: any;
}> = ({ currentUserData, currentUser }) => {


  const userPoints = currentUserData?.userChallengeData?.pointsType === "ms" ? formattedTime(currentUserData?.userChallengeData?.points) : "-";


  return (
    <div className="currentuser">
      <div className="currentuser__rank">
        <span>
          {currentUserData?.userChallengeData?.rank
            ? `#${currentUserData?.userChallengeData?.rank}`
            : "-"}
        </span>
      </div>

      <div className="currentuser__profile">
        <BaseUserAvatar
          userName={currentUser?.userName}
          logoUrl={currentUser?.profileLogoUrl}
        />
        <span>{currentUser?.userName}</span>
      </div>

      <div className="currentuser__score">
        <span>{userPoints}</span>
      </div>
    </div>
  );
};
