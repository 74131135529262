import QueryDB from "@/api/queryDB/common";
import { Button } from "@/components";
import { FC } from "react";
import { ReactComponent as IconTrash } from "../../assets/actions/trash.svg";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB_Notifications from "@/api/queryDB/notifications";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { userJunctionHandler } from "../../helperFunctions/userJunctionHandler";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const SingleTeamSeeAllOtherButtons: FC<{
  user: any;
  statusType: "rejected" | "pendingRequest" | "banned";
  teamData: any;
  handleUpdateList: (uid: string) => void;
  handleAcceptUser: (uid: string) => void;
  isFullPage: boolean;
}> = ({
  user,
  statusType,
  teamData,
  handleUpdateList,
  handleAcceptUser,
  isFullPage,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const isPendingTab = statusType === "pendingRequest";
  const isRejectedTab = statusType === "rejected";
  const isBannedTab = statusType === "banned";

  const handleError = () => {
    return createToast({
      type: "error",
      message: `Something went wrong, please try again later.`,
    });
  };
  if (isPendingTab) {
    return (
      <>
        <Button
          variant="secondary"
          onClickAction={async () => {
            userJunctionHandler(
              "rejected",
              currentUser,
              null,
              `${user.uid}_${teamData.slug}`,
              "Rejected",
              teamData.slug
            )
              .then(() => {
                createToast({
                  type: "info",
                  message: `You rejected ${user.userName}'s request to join the team ${teamData?.name}`,
                });

                handleUpdateList(user.uid);
              })
              .catch(() => {
                handleError();
              });
          }}
        >
          {isFullPage ? "Decline" : <IconTrash />}
        </Button>
        <Button
          variant="primary"
          onClickAction={async () => {
            userJunctionHandler(
              "accepted",
              currentUser,
              null,
              `${user.uid}_${teamData.slug}`,
              "member",
              teamData.slug
            )
              .then(() => {
                createToast({
                  type: "success",
                  message: `You accepted ${user.userName}'s request to join team ${teamData?.name}`,
                });
                handleUpdateList(user.uid);
                handleAcceptUser(user);
              })
              .catch(() => {
                handleError();
              });
          }}
        >
          Accept
        </Button>
      </>
    );
  }

  if (isBannedTab) {
    return (
      <>
        <Button
          variant="primary"
          onClickAction={async () => {
            userJunctionHandler(
              "accepted",
              currentUser,
              "",
              `${user.uid}_${teamData.slug}`,
              "member",
              teamData.slug
            )
              .then(() => {
                createToast({
                  type: "success",
                  message: `You added ${user.userName} back to the team ${teamData?.name}`,
                });
                handleUpdateList(user.uid);
                handleAcceptUser(user);
              })
              .catch(() => {
                handleError();
              });
          }}
        >
          Add to members
        </Button>
        <Button
          variant="secondary"
          onClickAction={async () => {
            await QueryDB.deleteDoc(
              "userTeamJunction",
              `${user.uid}_${teamData.slug}`
            )
              .then(() => {
                handleUpdateList(user.uid);
                createToast({
                  type: "info",
                  message: `You removed the ban on ${user.userName}, ${user.userName} can now request to join again.`,
                });
              })
              .catch(() => {
                handleError();
              });
          }}
        >
          Remove ban
        </Button>
      </>
    );
  }

  // Actions in the rejected tab
  if (isRejectedTab) {
    return (
      <Button
        variant="primary"
        onClickAction={async () => {
          userJunctionHandler(
            "accepted",
            currentUser,
            "",
            `${user.uid}_${teamData.slug}`,
            "member",
            teamData.slug
          )
            .then(() => {
              createToast({
                type: "success",
                message: `You added ${user.userName} to team ${teamData?.name}`,
              });
              handleUpdateList(user.uid);
              handleAcceptUser(user);
              QueryDB_Notifications.createNewNotification(
                currentUser.uid,
                user.uid,
                teamData.slug,
                "teams-joined-rejected",
                `${notificationsTextMapping["teams-joined-rejected"]} <strong>${teamData.name}</strong>`
              );
            })
            .catch(() => {
              handleError();
            });
        }}
      >
        Add
      </Button>
    );
  }

  // Any other case
  return <></>;
};
