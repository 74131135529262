import firebase from "firebase/app";

export const getUserGamepass = async (eventCode: string) => {
  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("claimGamepassToken");

  try {
    const result = await func({ eventCode });
    return result.data;
  } catch (e) {
    return "No more codes";
  }
};
