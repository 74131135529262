import { Button } from "@/components";
import React, { FC, useState } from "react";
import { ReactComponent as ActiveFire } from "../../assets/fire-active.svg";
import { ReactComponent as InactiveFire } from "../../assets/fire-inactive.svg";
import { ReactComponent as ShareIcon } from "../../assets/corner-up-right.svg";
import { ReactComponent as CommentIcon } from "../../assets/comments.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";

export const SinglePostAction: FC<{
  isEditPost: boolean;
  hasUserLiked: boolean;
  totalCommentsNumber: number;
  totalSharesNumber: number;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
  setShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalLikesNumberState: (value: React.SetStateAction<number>) => void;
  totalLikesNumber: number;
  postId: any;
  isFriendsOfCurrentUser: boolean;
  likeParentId: string;
}> = ({
  isEditPost,
  hasUserLiked,
  totalCommentsNumber,
  totalSharesNumber,
  setShowComments,
  setShareModalOpen,
  setTotalLikesNumberState,
  totalLikesNumber,
  postId,
  isFriendsOfCurrentUser,
  likeParentId,
}) => {
  const [hasUserLikedState, setHasUserLikedState] = useState(hasUserLiked);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const allowSharing = !window.location.pathname.includes("/news");
  const handleLike = async () => {
    if (!currentUser.uid) {
      createToast({
        type: "error",
        message: "Failed to authorize, please make sure you are logged in.",
      });
      return;
    }
    if (hasUserLikedState) {
      setHasUserLikedState(false);
      setTotalLikesNumberState((prev: number) => prev - 1);
      try {
        await QueryDB.deleteDoc(
          `posts/${postId}/likes`,
          currentUser.uid,
          "userId",
          currentUser.uid
        );
      } catch (e) {
        //Revert like and push toast notification!
        createToast({
          type: "error",
          message: `Failed to ${
            hasUserLikedState ? "add" : "remove"
          } like from post`,
        });
        setHasUserLikedState((prev) => !prev);
        setTotalLikesNumberState((prev: number) => prev + 1);
      }
    } else {
      setHasUserLikedState(true);
      setTotalLikesNumberState((prev: number) => prev + 1);
      // await addLikeToPost(id as string, currentUser.uid);
      try {
        QueryDB.createNewDoc(
          `posts/${postId}/likes`,
          {
            userId: currentUser.uid,
            teamId: likeParentId || null,
          },
          currentUser.uid
        );
      } catch (e) {
        //toast notification and revert the change
        createToast({
          type: "error",
          message: `Failed to ${
            hasUserLikedState ? "add" : "remove"
          } like from post`,
        });
        setHasUserLikedState((prev) => !prev);
        setTotalLikesNumberState((prev: number) => prev - 1);
      }
    }
  };

  return (
    <div className="post__actions">
      <div className="post__interactions">
        <Button
          variant="link"
          customStyles={`post__btn post__btn--like ${
            hasUserLikedState ? "post__btn--active" : ""
          }`}
          onClickAction={handleLike}
          disabled={isEditPost}
        >
          {hasUserLikedState ? <ActiveFire /> : <InactiveFire />}{" "}
          <span>{totalLikesNumber > 0 ? totalLikesNumber : ""}</span>
        </Button>

        {isFriendsOfCurrentUser || window.location.pathname === "/dashboard" ? (
          <Button
            variant="link"
            customStyles="post__btn post__btn--comment"
            onClickAction={() => {
              setShowComments((prev) => !prev);
            }}
            disabled={isEditPost}
          >
            <CommentIcon stroke="#9BA0AD" />{" "}
            <span id={`${postId}_comment`}>
              {totalCommentsNumber > 0 ? totalCommentsNumber : ""}
            </span>
          </Button>
        ) : (
          ""
        )}
      </div>
      {allowSharing && (
        <div className="post__share">
          <Button
            variant="link"
            customStyles="post__btn post__btn--share"
            onClickAction={() => setShareModalOpen(true)}
            disabled={isEditPost}
          >
            <ShareIcon stroke="#9BA0AD" />{" "}
            <span>{totalSharesNumber > 0 ? totalSharesNumber : ""}</span>
          </Button>
        </div>
      )}
    </div>
  );
};
