import { FC } from "react";
import { Link } from "react-router-dom";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { Query_Imgix } from "@/api/imgix";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

const UserList: FC<{
  isPlaceholder?: boolean;
  profiles: any[];
  otherCount: number;
}> = (props) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  return (
    <>
      <ul className="user-list">
        {props.profiles &&
          props.profiles.map((user) => {
            const isCurrUser = user.slug === currentUser.slug;
            const link = isCurrUser ? "/profile" : `/profile/${user.slug}`;
            return (
              <li key={user.slug}>
                <Link to={link}>
                  <BaseLogo
                    logoSrc={`${
                      user.profileLogoUrl
                    }?${Query_Imgix.getImgixUrlProps({
                      qty: "35",
                      w: "46",
                      h: "46",
                    })}`}
                    name={user?.userName as string}
                    isPlaceHolder={props.isPlaceholder}
                  />
                </Link>
              </li>
            );
          })}
        {props.otherCount && props.otherCount !== 0 ? (
          <li className="user-list__count">
            {props.otherCount > 100 ? `${99}+` : props.otherCount}
          </li>
        ) : (
          ""
        )}
      </ul>
    </>
  );
};
export default UserList;
