// Calculate where the dropdown appears when typing

export const calculateDropdownPosition = (
  textarea: HTMLTextAreaElement,
  caretPos: number
): { top: number; left: number } => {
  // Create elements
  const divElement = document.createElement("div");
  const spanElement = document.createElement("span");

  const text = document.createTextNode(textarea.value.substr(0, caretPos));

  // Add childs to elements
  divElement.appendChild(text);
  divElement.appendChild(spanElement);

  // Set styles for dropdown element
  divElement.style.cssText = textarea.style.cssText;
  divElement.style.width = `${textarea.offsetWidth}px`;
  divElement.style.height = "auto";
  divElement.style.visibility = "hidden";
  divElement.style.whiteSpace = "pre-wrap";
  divElement.style.wordWrap = "break-word";

  (textarea.parentElement as HTMLElement)?.appendChild(divElement);
  const rect = spanElement.getBoundingClientRect();
  const textareaRect = textarea.getBoundingClientRect();
  (textarea.parentElement as HTMLElement)?.removeChild(divElement);

  const lineNumber =
    (textarea.value.substring(0, caretPos).match(/\n/g) || []).length + 1;

  let top;
  if (lineNumber === 1) {
    top = 33;
  } else if (lineNumber === 2) {
    top = 60;
  } else {
    top = 60;
  }

  const left = rect.left - textareaRect.left + textarea.scrollLeft;

  return {
    top,
    left,
  };
};
