import { FC } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Button, Spinner } from "@/components";
import { SingleChallengeBestgamerLeaderboardUser } from "./components/SingleChallengeBestgamerLeaderboardUser";
import { SingleChallengeBestgamerLeaderboardCurrentUser } from "./components/SingleChallengeBestgamerLeaderboardCurrentUser";
import useFetchLeaderboard from "@/features/challenge/helperFunctions/useAllLeaderboardData";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const SingleChallengeBestGamerLeaderboard: FC<{ slug: string }> = ({
  slug,
}) => {
  const currentBrand = brand.brandValue;
  const currentUser = useCurrentUserProfile((s) => s.user);
  const { loading, leaderboardData } = useFetchLeaderboard(slug, currentBrand);

  const currentUserData = leaderboardData?.find((doc) => {
    return doc.userId === currentUser.uid;
  });

  if (loading) {
    return <Spinner />;
  }  

  return (
    <div className="leaderboard">
      <div className="leaderboard__title">
          <h2>Leaderboard</h2> 
      </div>

      <div className="leaderboard__head">
        Your current score
      </div>

      <div className="leaderboard__currentuser">
        <SingleChallengeBestgamerLeaderboardCurrentUser
          currentUser={currentUser}
          currentUserData={currentUserData}
        />
      </div>

      <Button variant="primary">Share</Button>
      <ul className="leaderboard__list">
        {leaderboardData.map((user) => {
          return <SingleChallengeBestgamerLeaderboardUser user={user} />;
        })}
      </ul>
    </div>
  );
};
