import React, { memo, FC } from "react";
import { AiOutlineInfoCircle as InfoIcon } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import BaseTooltipWrapper from "@/components/BaseWrappers/BaseTooltipWrapper";
import { additionalInfoText } from "@/utils/constants/tooltipsInfo";

const SingleChallengeAdditionalInfoWithTooltip: FC<{
  title: string;
  value: string;
  tooltip?: string;
}> = ({ title, value, tooltip }): JSX.Element => {
  return (
    <BaseTooltipWrapper
      overlayTrigger={
        <div className="challenge__tooltip">
          <span>{title}: </span>
          {value === "Cumulative" ? (
            <span>accumulative</span>
          ) : (
            <span>{value}</span>
          )}
          <InfoIcon />
        </div>
      }
      tooltipContent={additionalInfoText[tooltip || title]}
    />
  );
};

export default memo(SingleChallengeAdditionalInfoWithTooltip);
