import { FC, useEffect, useRef, useState } from "react";
import { Spinner } from "@/components";
import { useHighlights } from "@/features/challenge/hooks/useHighlights";
import { singleChallengeAltProps } from "@/features/challenge/utils/singleChallengeProps";
import { ReactComponent as ChevronRight } from "@/assets/actions/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "@/assets/actions/chevron-left.svg";
import { ReactComponent as IconViews } from "@/assets/actions/eye.svg";
import { ReactComponent as IconCheck } from "@/assets/actions/check.svg";
import { SingleChallengeBestgamerSingleHighlight } from "./components/SingleChallengeBestgamerSingleHighlight";
import i18next from "i18next";

export const SingleChallengeBestgamerHighlights: FC<{
  data: singleChallengeAltProps;
}> = ({ data }) => {
    const { highlights, highlightsLoading } = useHighlights(data.gameType, 5);
    const ref = useRef<HTMLDivElement>(null);
    const videoWidth = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const [canScrollRight, setCanScrollRight] = useState<boolean>(true);

    if (highlightsLoading) {
        return <Spinner />;
    }

    if (!highlights) {
        return null;
    }

    const updateScrollButtons = () => {
        const { scrollLeft, clientWidth, scrollWidth } = ref.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    };

    const scrollRight = () => {
        if (ref.current && videoWidth.current) {
            ref.current.scrollLeft += videoWidth.current.clientWidth;
            updateScrollButtons();
        }
    };
    
    const scrollLeft = () => {
        if (ref.current && videoWidth.current) {
            ref.current.scrollLeft -= videoWidth.current.clientWidth;
            updateScrollButtons();
        }
    };

    const viewsHeight = document.getElementsByClassName("views").item(0)?.clientHeight || 19;
    const controlsPlacement = {
        "bottom": viewsHeight + 22 + "px",
    }

    const scrollDirectionRight = canScrollRight ?
    "challenge-highlights__body--right"
    :
    "";

    const scrollDirectionLeft = canScrollLeft ?
    "challenge-highlights__body--left"
    :
    "";

    return (
        <div className="challenge-highlights">
            <h2>{i18next.t("challenge.highlights.heading")}</h2>
            <p>{i18next.t("challenge.highlights.description")}.</p>

            <div className={`challenge-highlights__body ${scrollDirectionRight} ${scrollDirectionLeft}`}>
                <div className="challenge-highlights__content" ref={ref}>
                    <div className="challenge-highlights__list">
                        {highlights?.map((video, index) => {
                            return (
                                <div key={index} ref={videoWidth}>
                                    <SingleChallengeBestgamerSingleHighlight 
                                        video={video}
                                        index={index}
                                    />
                                </div>
                            )
                        }
                    )}
                    </div>
                </div>
                {canScrollLeft && (
                    <div className="challenge-highlights__action challenge-highlights__action--prev" style={controlsPlacement}>
                        <button className="challenge-highlights__btn" onClick={scrollLeft}>
                            <ChevronLeft />
                        </button>
                    </div>
                )}
                {canScrollRight && (
                    <div className="challenge-highlights__action challenge-highlights__action--next" style={controlsPlacement}>
                        <button className="challenge-highlights__btn" onClick={scrollRight}>
                            <ChevronRight />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};