import React, { FC } from "react";
import GatherCardComponent from "./GatherCardComponent";

type Props = {
  name: string;
  slug: string;
  meetType: "offline" | "online";
  games: string[];
  participantsCount: number;
  interestedInCount: number;
  startDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  logoUrl: string;
  isFavoriteByCurrentUser: boolean;
  gatherId: string;
  isPlaceholder: boolean;
  generalRole: "owner" | "admin" | "member" | "random";
};

export const GatherCard: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <GatherCardComponent
        name={props.name}
        slug={props.slug}
        meetType={props.meetType}
        games={props.games}
        participantsCount={props.participantsCount}
        interestedInCount={props.interestedInCount}
        startDate={props.startDate as any}
        logoUrl={props.logoUrl}
        isFavoriteByCurrentUser={props.isFavoriteByCurrentUser}
        gatherId={props.gatherId}
        isPlaceholder={false}
        setUpdateTrigger={{}}
        generalRole={props.generalRole}
      />
    </>
  );
};
