import React from "react";
import useProfile from "../../../../../src/features/challenge/hooks/useProfile";
import { Link } from "react-router-dom";
import BaseUserAvatar from "@/components/BaseUserAvatar";

type Props = {
  uid: string;
};

const LeaderboardUserItem: React.FC<Props> = ({ uid }) => {
  const { profile, loading, error } = useProfile(uid);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return (
      <div className="user">
        <div className="user__image" />
        <div className="user__name">
          <div className="user__name--nickname">Error fetching user</div>
        </div>
      </div>
    );
  }

  return (
    <div className="user">
      <div className="user__image">
        <div className="image">
          <div className="image__content">
            <BaseUserAvatar
              logoUrl={profile?.profileLogoUrl}
              userName={profile?.userName[0]}
            />
          </div>
        </div>
      </div>
      <div className="user__name">
        <div className="user__name--nickname">
          <Link to={`/profile/${profile?.slug}`}>{profile?.userName}</Link>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardUserItem;
