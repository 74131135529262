import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleChallenge, {
  singleChallengeAtom,
} from "../hooks/useSingleChallenge";
import { Button } from "@/components/Button";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { CreateChallengeForm } from "../components/create/CreateChallenge.fetcher";
import { SingleChallenge404 } from "../components/single/SingleChallenge404";
import SingleChallengeLoader from "@/components/skeletonLoaders/singleChallengeLoader/SingleChallengeLoader";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { partnerRoute } from "../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/partnerRoutes";
import { useAtom } from "jotai";
import { clientAtom } from "../../../utils/atoms/atomClient";
import SingleChallengeFormula from "../components/single/SingleChallengeFormula";
import ChallengeTerms from "../components/single/ChallengeTerms";
import ChallengeDisclaimer from "../components/single/ChallengeDisclaimer";
import { Image } from "react-bootstrap";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";
import ProgressBar from "../components/ProgressBar";
import { getChallengeDate } from "../helperFunctions/getChallengeDate";
import { getProgressBarValue } from "../helperFunctions/getProgressBarValue";
import { ParticipateButton } from "../components/challengeCard/SingleChallengeCardButtons";
import { Leaderboard } from "../components/leaderboard/Leaderboard";
import { CreatorTypesOptions } from "../utils/CreatorTypes";
import { InviteButton } from "@/components/InviteButton";
import { ReportFormModal } from "@/components/ReportFormModal/ReportFormModal";
import { ShareContentModalWindow } from "@/components/ShareModalWindow/ShareContentModalWindow";

import { ReactComponent as EditIcon } from "../../../assets/actions/edit.svg";
import { ReactComponent as ShareIcon } from "../../feed/assets/share-post-profile.svg";
import { ReactComponent as ReportIcon } from "../../../assets/actions/report_triangle.svg";
import SingleChallengeAdditionalInfoWithTooltip from "../components/single/SingleChallengeAdditionalInfoWithTooltip";
import CreatorCard from "@/components/CreatorCard";
import useProfile from "../hooks/useProfile";
import { use } from "i18next";
import { set } from "date-fns";
import { singleChallengeProps } from "../utils/singleChallengeProps";
import useProfileChallengeJunction from "../hooks/useProfileChallengeJunction";
// import useChallengeParticipants from "../hooks/useChallengeParticipants";

const SingleChallengeAltPage: FC = (): JSX.Element => {
  const { slug } = useParams();
  const { challenge, loading, error } = useSingleChallenge(slug);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [currentClient, setCurrentClient] = useAtom(clientAtom);
  const [ChallengeCreator, setChallengeCreator] = useState<any>(null);
  const { profile } = useProfile(challenge?.createdBy);
  const { challengeUser } = useProfileChallengeJunction(
    challenge?.slug,
    currentUser?.uid
  );
  // const { challengeParticipantCount } = useChallengeParticipants(challenge?.slug);

  console.log("creator profile", profile);
  console.log("challenge user", challengeUser);
  // console.log('challengeParticipantCount', challengeParticipantCount);

  useEffect(() => {
    setChallengeCreator(profile);
  }, [challenge, profile]);

  const [logoError, setLogoError] = useState<boolean>(false);
  useEffect(() => {
    setLogoError(false);
  }, [challenge]);

  console.log(challenge);
  const isCreatedByCurrUser = challenge?.createdBy === currentUser.uid;

  const isCurrentUserBanned = challenge?.currUserProfile?.status === "banned";
  const isCurrentUserPending =
    challenge?.currUserProfile?.status === "pendingRequest";
  const isPrivateForUser =
    !isCreatedByCurrUser &&
    challenge?.type === "private" &&
    !challenge?.isCurrUserParticipant;
  const isRacingGame = challenge?.gameType.includes("f120");
  console.log(challenge);

  const challengeAdditionalArray = [
    {
      title: "Team size",
      value: challenge?.teamSize ?? "Not specified",
      toolTipKey: "Team size",
    },
    {
      title: "Challenge type",
      value: challenge?.pointCountingType
        ? challenge?.pointCountingType === "Cumulative"
          ? "Accumulative"
          : challenge?.pointCountingType
        : "Not specified",
      toolTipKey: "Challenge type",
    },
    {
      title: challenge?.challengeAdditionalData.mapPool
        ? "Map pool"
        : `${isRacingGame ? "Track" : "Map"}`,
      value: challenge?.challengeAdditionalData?.mapNames
        ? challenge?.challengeAdditionalData.mapPool
        : "Not specified",
      toolTipKey: "Map pool",
    },
  ];

  const ChallengeClient = challenge?.client;
  const isEventSite = brand.event.isEvent === true;

  const ChallengeType = challenge?.type;
  const isPrivateChallenge = ChallengeType === "private";

  // Set different themes for individual partner pages
  useEffect(() => {
    if (brand.brandTheme === "elgiganten") {
      const partnerKeys = Object.keys(partnerRoute);

      partnerKeys.forEach((key) => {
        const partner = partnerRoute[key].partner;

        if (ChallengeClient === partner) {
          document.documentElement.className = partnerRoute[key].theme;
          setCurrentClient(partner);
        }
      });
    }
  }, [brand.brandTheme, partnerRoute, challenge]);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);

  const [data, setData] = useState<singleChallengeProps>({
    challengeData: null,
    status: null,
    challengeAdditionalData: null,
    currUserProfile: {
      profile: null,
      userChallengeData: [],
    },
    isInCurrUserFavorites: false,
    isCurrUserParticipant: false,
    isCreatedByCurrUser: false,
    creatorProfile: null,
    participantsCount: 0,
    lastThreeParticipantsProfiles: [],
    isCreatorFollowedByCurrentUser: false,
    creatorFollowersCount: null,
  });

  // console.log('profile', profile?.uid === challenge?.createdBy);

  const isUserFav = profile?.favoriteChallenges?.includes(challenge?.slug)
    ? true
    : false;

  useEffect(() => {
    if (ChallengeCreator !== null && challenge !== null) {
      setData({
        challengeData: challenge,
        status: challenge?.status,
        challengeAdditionalData: challenge?.challengeAdditionalData,
        currUserProfile: {
          profile: currentUser,
          userChallengeData: challengeUser?.userChallengeData,
        },
        isInCurrUserFavorites: isUserFav,
        isCurrUserParticipant: challengeUser !== undefined ? true : false, //missing
        isCreatedByCurrUser: currentUser?.uid === challenge?.createdBy,
        creatorProfile: ChallengeCreator ? ChallengeCreator : null,
        participantsCount: 0, //missing
        lastThreeParticipantsProfiles: [], //missing
        isCreatorFollowedByCurrentUser: false, //missing
        creatorFollowersCount: ChallengeCreator
          ? ChallengeCreator.followersCount
          : null,
      });
    }
  }, [challenge, ChallengeCreator, profile]);

  console.log("btn", data);

  // temp
  const isPreview = false;

  if (!loading) {
    return (
      <>
        {isCurrentUserBanned && (
          <>
            <div className="challenge__banned">
              <h2>You have been banned from this challenge</h2>
              <p>
                You can no longer participate in, view or interact with the
                following challenge.
              </p>
              <h3>{challenge.name}</h3>
              <Button
                onClickAction={() => navigate(siteRoutes["challenges"])}
                variant="primary"
              >
                Back to challenges
              </Button>
            </div>
          </>
        )}
        {/* Edit flow */}
        {isEdit && !loading && (
          <CreateChallengeForm
            isEdit={isEdit}
            challenge={challenge}
            setIsEdit={setIsEdit}
          /> // This is the same as create flow with exceptions
        )}

        {/* In  case challenge or author doesnt exist anymore (or other error) */}
        {!isEdit && !loading && error && (
          <SingleChallenge404 navigate={navigate} />
        )}

        {/* Single challenge page */}

        <div className="challenge">
          {/* General info (Image, time, desc, tags, title) */}
          <div className="challenge__info">
            <div className="info">
              <div
                className={`info__meta ${
                  challenge.status !== "ongoing" ? "ongoing" : ""
                }`}
              >
                <div className="info__item">
                  <div className="info__image">
                    <div className="image">
                      <div className="image__content">
                        <Image
                          alt={`User-uploaded image for ${challenge.name} challenge`}
                          src={Query_shared.imageErrorHandler(
                            challenge.logoUrl?.includes("iplay.imgix")
                              ? `${
                                  challenge.logoUrl
                                }?${Query_Imgix.getImgixUrlProps({
                                  qty: "35",
                                  w: "600",
                                  h: "400",
                                  fit: "crop",
                                })}`
                              : challenge.logoUrl,
                            logoError,
                            false
                          )}
                          onError={() => setLogoError(true)}
                        />
                      </div>
                    </div>
                  </div>
                  {!isPrivateForUser && challenge.status === "ongoing" && (
                    <div className="challenge__actions">
                      <div className="challenge__participate">
                        {data.challengeData && (
                          <ParticipateButton
                            data={data}
                            isPreview={
                              isPreview || challenge.status !== "ongoing"
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {!isPrivateForUser && challenge.status === "finished" && (
                    <div className="challenge__actions">
                      <div className="challenge__participate">
                        <Button variant="primary" disabled={true}>
                          Finished
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <h1 className="info__heading">{challenge.name}</h1>
                  <div className="info__badges">
                    {isPrivateChallenge && (
                      <span className="info__type">{ChallengeType}</span>
                    )}
                    <span className="info__game">{challenge.game}</span>
                  </div>
                  <p className="info__goal">{challenge.goal}</p>

                  {/* <div className="info__participants">
    <div className="participants">
        <ul className="participants__list">
        {data.lastThreeParticipantsProfiles.map((profile, index) => {
        return (
            <li
            className="participants__item"
            key={`${profile?.userName}_${index}`}
            >
                <div className="participants__avatar">
                    <div className="avatar">
                        <div className="avatar__content">
                            <BaseUserAvatar
                            logoUrl={profile.profileLogoUrl}
                            userName={profile.userName}
                            />
                        </div>
                    </div>
                </div>
            </li>
        );
        })}
        </ul>
        <div className="participants__names">
            <p>
                {data.lastThreeParticipantsProfiles.map((profile, index) => {
                return (
                    <span key={index}>
                        <span>{profile.userName}</span>
                            {index + 1 !== data.lastThreeParticipantsProfiles.length
                            ? ", "
                        : " "}
                    </span>
                );
                })}
                {data.lastThreeParticipantsProfiles.length <
                data.participantsCount - 3 ? (
                    <>
                        {i18next.t("challenge.participants.and")} <span>{data.participantsCount - 3} {i18next.t("challenge.participants.more")}</span>
                    </>
                ) : (
                ""
                )}
                {data.lastThreeParticipantsProfiles.length !== 0 && (
                    <>{i18next.t("challenge.participants.participating")}</>
                )}
            </p>
        </div>
    </div>
</div> */}
                </div>
              </div>
              {!isPrivateForUser && (
                <div>
                  <ProgressBar
                    id="challenge-time"
                    label={getChallengeDate(
                      challenge?.startDate
                        ? challenge?.startDate?.["seconds"]
                        : undefined,
                      challenge?.endDate
                        ? challenge?.endDate?.["seconds"]
                        : undefined
                    )}
                    value={getProgressBarValue(
                      challenge?.startDate
                        ? challenge?.startDate?.["seconds"]
                        : undefined,
                      challenge?.endDate
                        ? challenge?.endDate?.["seconds"]
                        : undefined
                    )}
                    color=""
                    onOverview={false}
                    isPlaceholder={false}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Buttons (Edit, Fav, Invite) */}
          <div className="challenge__icons">
            <div>
              {isCreatedByCurrUser ? (
                <Button
                  variant="link"
                  disabled={isPreview || challenge.status === "finished"}
                  onClickAction={() => {
                    setIsEdit(true);
                  }}
                >
                  <EditIcon />
                </Button>
              ) : (
                ""
              )}
              {/* <FavoriteButton
                            slug={`${challenge.slug}`}
                            isPreview={isPreview}
                            isInFavoritesOfCurrentUser={data.isInCurrUserFavorites}
                            featureType="challenge"
                            name={challenge.name}
                        /> */}

              {isCreatedByCurrUser || challenge.type ? (
                <InviteButton
                  slug={`${challenge.slug}`}
                  isPreview={isPreview || challenge.status !== "ongoing"}
                  notificationType="challenges-requests"
                  featureName={challenge.name}
                />
              ) : (
                ""
              )}

              <Button
                variant="link"
                onClickAction={() => setShareModalOpen(true)}
              >
                <ShareIcon />
              </Button>

              <Button
                variant="link"
                onClickAction={() => {
                  setReportModalOpen(true);
                }}
              >
                <ReportIcon />
              </Button>

              {/* Challenge report modal */}
              <ReportFormModal
                show={reportModalOpen}
                closeModal={() => {
                  setReportModalOpen(false);
                }}
                children={""}
                reportFrom="challenge"
                reportItemId={challenge.slug}
              />

              {/* General modal for sharing | New */}
              <ShareContentModalWindow
                sharedFrom={"challenge"}
                createType="create"
                show={shareModalOpen}
                closeWindow={() => setShareModalOpen(false)}
                postData={{
                  uid: currentUser.uid,
                  postText: "",
                  postedIn: "",
                  totalSharesNumber: 0,
                  postVideo: "",
                  created: challenge.created,
                  postPhoto: "",
                  wasEdited: false,
                  commentsCount: 0,
                  totalLikesNumber: 0,
                  totalCommentsNumber: 0,
                  id: challenge.slug,
                  authorSlug: currentUser.slug,
                  authorUsername: currentUser.userName,
                  authorLogo: currentUser.profileLogoUrl,
                  hasCurrentUserLikedThisPost: false,
                  type: "sharedChallenge",
                  additionalData: challenge,
                }} // NEW
              />
            </div>
          </div>

          {/* Additional info fields */}
          <div className="challenge__additional">
            {!isPrivateForUser &&
              challengeAdditionalArray
                .filter((obj) => obj.value !== "")
                .map((additionalField) => {
                  if (additionalField.value) {
                    return additionalField.title !== "Team size" &&
                      challenge.game !== "PUBG" ? (
                      <SingleChallengeAdditionalInfoWithTooltip
                        title={additionalField.title}
                        value={additionalField.value}
                        key={additionalField.title}
                        tooltip={additionalField.toolTipKey || null}
                      />
                    ) : (
                      challenge.game === "PUBG" && (
                        <SingleChallengeAdditionalInfoWithTooltip
                          title={additionalField.title}
                          value={additionalField.value}
                          key={additionalField.title}
                          tooltip={additionalField.toolTipKey || null}
                        />
                      )
                    );
                  }
                })}
          </div>

          {/* Formula */}
          {!isPrivateForUser && (
            <SingleChallengeFormula
              coefficients={
                challenge.challengeAdditionalData.fieldToCompareCoefficients
              }
            />
          )}

          {/* Author card */}
          {!isEventSite && (
            <div className="challenge__creator">
              {ChallengeCreator && (
                <CreatorCard
                  creatorProfile={ChallengeCreator}
                  isFollowedByCurrentUser={
                    profile?.isCreatorFollowedByCurrentUser
                  }
                  creatorFollowersNumber={profile?.followersCount}
                  isPlaceholder={false}
                />
              )}
            </div>
          )}

          {/* Description */}
          {challenge.description && !isEventSite && (
            <div className="challenge__description">
              <h3>Description</h3>
              <p>{challenge.description}</p>
            </div>
          )}

          {isEventSite && (
            <>
              <ChallengeTerms client={currentClient} />
            </>
          )}

          {/*  Leaderboard */}
          {!isPrivateForUser ? (
            <Leaderboard
              slug={encodeURIComponent(slug)} //required to be encoded!
              color={
                CreatorTypesOptions[challenge.creatorType]
                  ? CreatorTypesOptions[challenge.creatorType].activeColor
                  : CreatorTypesOptions.Community.activeColor
              }
              isPreview={false}
              status={challenge.status}
              client={challenge.client}
              game={challenge.game}
            />
          ) : (
            <div className="challenge__private">
              {/* <div className="leaderboard"> */}
              <h2>This is a private challenge</h2>
              <p>
                {isCurrentUserPending
                  ? "You have requested to join this challenge."
                  : "You can send a request to join this challenge."}
              </p>
              {/* </div> */}
            </div>
          )}

          <ChallengeDisclaimer game={challenge.gameType} />
        </div>
      </>
    );
  }

  if (loading) {
    return <SingleChallengeLoader />;
  }
  return <></>;
};

export default SingleChallengeAltPage;
