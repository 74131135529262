import React, { ButtonHTMLAttributes } from "react";
//example component
type ButtonProps = {
  children?: React.ReactNode;
  onClickAction?: any;
  isFullPageError?: boolean;
};

export function ErrorFallback({ children }: ButtonProps) {
  return <div className="ErrorFallback">{children ? children : "Error"}</div>;
}
