import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useState, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SingleChallengeUi } from "./SingleChallenge.ui";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { Button } from "@/components";
import { CreateChallengeForm } from "../create/CreateChallenge.fetcher";
import { SingleChallenge404 } from "./SingleChallenge404";
import { Leaderboard } from "../leaderboard/Leaderboard";
import { CreatorTypesOptions } from "../../utils/CreatorTypes";
import { participateInChallenge } from "../../helperFunctions/participateUserIntoChallenge";
import SingleChallengeLoader from "@/components/skeletonLoaders/singleChallengeLoader/SingleChallengeLoader";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { Games } from "@/models/database/games/games.model";
import { useSingleChallengeData } from "@/api/firebaseQueries/getSingleChallengeData";
import { discoverChallengesAtom } from "@/utils/atoms/challengesAtoms/challengesAtom";
import { useAtom } from "jotai";

export const SingleChallenge: FC<{
  slug: string;
}> = ({ slug }): JSX.Element => {
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [visitedChallengesAtomData, setVisitedChallengesAtomData] = useAtom(
    discoverChallengesAtom
  );

  // Check if doc is visited before, if so set as default state for newsData
  const visitedChallengeDoc = visitedChallengesAtomData?.[slug];

  const { data, loading, error, fetch_challenge } = useSingleChallengeData({
    data: {
      challengeId: slug,
      currUserId: currentUser.uid,
    },
  });
  console.log(visitedChallengeDoc);

  const challengeToRender = visitedChallengeDoc || data;

  const isCurrentUserPending =
    challengeToRender?.currUserProfile?.status === "pendingRequest";
  const isCurrentUserBanned =
    challengeToRender?.currUserProfile?.status === "banned";
  const isPrivateForUser =
    !challengeToRender?.isCreatedByCurrUser &&
    challengeToRender?.challengechallengeToRender?.type === "private" &&
    !challengeToRender?.isCurrUserParticipant;

  const isRacingGame =
    challengeToRender?.challengechallengeToRender?.gameType.includes("f120");

  const getGameInfo = async () => {
    const games = collectionRef.games;
    const gamesRes = await games
      .where("name", "==", challengeToRender.challengeData.game)
      .limit(1)
      .get();

    if (gamesRes && gamesRes.docs[0] && gamesRes.docs[0].data()) {
      return gamesRes.docs[0].data() as Games;
    }
    return undefined;
  };

  useEffect(() => {
    fetch_challenge();
  }, [slug, isEdit]);

  // const response = useSingleChallengeData({
  //   data: {
  //     challengeId: encodeURIComponent(decodeURIComponent(slug!)),
  //     currUserId: currentUser.uid!,
  //   },
  // });

  useEffect(() => {
    if (data) {
      if (visitedChallengeDoc) {
        let deepArr = visitedChallengesAtomData[slug];
        deepArr = data;
        const updatedItems = deepArr;

        // update existing doc with new data
        setVisitedChallengesAtomData(updatedItems);
      } else {
        // If Doc has not been loaded before
        setVisitedChallengesAtomData({
          ...(visitedChallengesAtomData || []),
          [slug]: data,
        });
      }
    }
  }, [data]);

  console.log(challengeToRender, data);

  return (
    <>
      {isCurrentUserBanned && (
        <>
          <div className="challenge__banned">
            <h2>You have been banned from this challenge</h2>
            <p>
              You can no longer participate in, view or interact with the
              following challenge.
            </p>
            <h3>{challengeToRender.challengeData.name}</h3>
            <Button
              onClickAction={() => navigate(siteRoutes["challenges"])}
              variant="primary"
            >
              Back to challenges
            </Button>
          </div>
        </>
      )}
      {/* Edit flow */}
      {isEdit && !loading && (
        <CreateChallengeForm
          isEdit={isEdit}
          challenge={data}
          setIsEdit={setIsEdit}
        /> // This is the same as create flow with exceptions
      )}

      {/* In  case challenge or author doesnt exist anymore (or other error) */}
      {!isEdit && !loading && error && (
        <SingleChallenge404 navigate={navigate} />
      )}

      {/* Single challenge page */}
      {/* Note: Do not add loading state here, it will makes the page slow */}
      {!isCurrentUserBanned && !isEdit && !error && challengeToRender && (
        <SingleChallengeUi
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          data={challengeToRender} // Contains main data about challenge
          isPreview={false}
          isPrivateForUser={isPrivateForUser}
          additionalFields={[
            {
              title: "Team size",
              value:
                challengeToRender?.challengeData.teamSize ?? "Not specified",
              toolTipKey: "Team size",
            },
            {
              title: "Challenge type",
              value: challengeToRender?.challengeData.pointCountingType
                ? challengeToRender?.challengeData.pointCountingType ===
                  "Cumulative"
                  ? "Accumulative"
                  : challengeToRender?.challengeData.pointCountingType
                : "Not specified",
              toolTipKey: "Challenge type",
            },
            {
              title: challengeToRender?.challengeData.challengeAdditionalData
                .mapPool
                ? "Map pool"
                : `${isRacingGame ? "Track" : "Map"}`,
              value: challengeToRender?.challengeData
                .challengeAdditionalchallengeToRender?.mapNames
                ? challengeToRender?.challengeData.challengeAdditionalData
                    .mapPool
                  ? challengeToRender?.challengeData.challengeAdditionalData
                      .mapPool
                  : challengeToRender?.challengeData.challengeAdditionalchallengeToRender?.mapNames.join(
                      ", "
                    ) || "All"
                : "All",
              toolTipKey: "maps",
            },
            {
              title: "Modes",
              value: challengeToRender?.challengeData
                .challengeAdditionalchallengeToRender?.mapModes
                ? challengeToRender?.challengeData.challengeAdditionalchallengeToRender?.mapModes.join(
                    ", "
                  )
                : "All",
              toolTipKey: "modes",
            },
          ]}
        >
          {!isPrivateForUser ? (
            <Leaderboard
              slug={encodeURIComponent(slug)} //required to be encoded!
              color={
                CreatorTypesOptions[challengeToRender.challengeData.creatorType]
                  ? CreatorTypesOptions[
                      challengeToRender.challengeData.creatorType
                    ].activeColor
                  : CreatorTypesOptions.Community.activeColor
              }
              isPreview={false}
              status={challengeToRender.challengeData.status}
              client={challengeToRender.challengeData.client}
              game={challengeToRender.challengeData.game}
            />
          ) : (
            <div className="challenge__private">
              {/* <div className="leaderboard"> */}
              <h2>This is a private challenge</h2>
              <p>
                {isCurrentUserPending
                  ? "You have requested to join this challenge."
                  : "You can send a request to join this challenge."}
              </p>
              <Button
                variant="primary"
                disabled={isCurrentUserPending}
                onClickAction={async () => {
                  if (!isCurrentUserPending) {
                    await participateInChallenge(
                      navigate,
                      "private",
                      await getGameInfo(),
                      currentUser.uid,
                      challengeToRender.challengeData.slug
                    ).then(async () => {
                      await fetch_challenge();
                    });
                  }
                }}
              >
                {isCurrentUserPending ? "Request pending" : "Send request"}
              </Button>
              {/* </div> */}
            </div>
          )}
        </SingleChallengeUi>
      )}
      {/* Skeleton loader */}
      {!challengeToRender && loading && <SingleChallengeLoader />}
    </>
  );
};
