import BaseLogo from "@/components/BaseLogo/BaseLogo";
import i18next from "i18next";
import { FC } from "react";
import { Profile } from "@/models/database/profiles/profile.model";
import { Button, Spinner } from "@/components";

export const SettingsProfileImageButtons: FC<{
  imgSrc: string;
  profile: Profile;
  setIsSubmiting: any;
  submitProfileLogo: any;
  setIsAvatar: any;
  setImgSrc: any;
  isUpdating: boolean;
  setCroppedProfileImage: any;
  setOpenModal: any;
  submitImage: any;
}> = ({
  imgSrc,
  profile,
  setIsSubmiting,
  submitProfileLogo,
  setIsAvatar,
  setImgSrc,
  isUpdating,
  setCroppedProfileImage,
  setOpenModal,
  submitImage,
}) => {
  return (
    <div className="settings__info">
      {/* <div className="settings__alert">
        <p>{i18next.t(`profile.edit.avatar.upload_msg`)}</p>
      </div> */}
      <div className="settings__image">
        <div className="image">
          <div className="image__content">
            <BaseLogo
              logoSrc={imgSrc || profile.profileLogoUrl}
              name={profile.userName}
              isPlaceHolder={undefined}
            />
          </div>
        </div>
      </div>

      {imgSrc && (
        <div className="settings__changes">
          <Button
            variant="primary"
            customStyles="settings__changes--save"
            disabled={isUpdating}
            onClickAction={async () => {
              setIsSubmiting(true);
              await submitProfileLogo();
              setImgSrc(undefined);
              setCroppedProfileImage(undefined);
              setIsSubmiting(false);
              setOpenModal(false);
            }}
          >
            {isUpdating ? <Spinner /> : "Save changes"}
          </Button>
          <Button
            variant="secondary"
            disabled={isUpdating}
            customStyles="settings__changes--cancel"
            onClickAction={() => {
              setIsAvatar(false);
              setImgSrc(undefined);
              setCroppedProfileImage(undefined);
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      )}

      {profile.profileLogoUrl && !imgSrc && (
        <Button
          variant="secondary"
          customStyles="settings__changes--remove"
          onClickAction={async () => {
            setIsSubmiting(true);
            await submitImage();
            setImgSrc(undefined);
            setCroppedProfileImage(undefined);
            setOpenModal(false);
          }}
          disabled={isUpdating}
        >
          {isUpdating ? <Spinner /> : i18next.t("buttons.remove_avatar")}
        </Button>
      )}
    </div>
  );
};
