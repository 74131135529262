import i18next from "i18next";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const AuthCard: FC<{
  children: any;
  title: string;
  type: "login" | "signup" | "verify-email" | "" | "forgot-password";
}> = ({ children, title, type }) => {
  const navigate = useNavigate();
  return (
    <div className="authentication__panel">
      <div className="panel">
        <div className="panel__title">
          <h1>{title}</h1>
          {type === "login" && (
            <p>
              {i18next.t("auth.authcard.unregistered")}{" "}
              <span onClick={() => navigate("/signup")}>
              {i18next.t("auth.authcard.create")}.
              </span>
            </p>
          )}

          {type === "signup" && (
            <p onClick={() => navigate("/login")}>
              {i18next.t("auth.authcard.registered")} <span> {i18next.t("auth.authcard.login")}</span>
            </p>
          )}
          {type === "verify-email" && (
            <p>
              {i18next.t("auth.authcard.verify_email")}{" "}
              {localStorage.getItem("authEmailLocal")}
            </p>
          )}
          {type == "forgot-password" && (
            <p>
              {i18next.t("auth.authcard.forgot_password")}
            </p>
          )}
        </div>
        <div className="panel__content">{children}</div>
      </div>
    </div>
  );
};
