import { continents } from "@/utils/constants/continents";

export const createChallengeOptions = {
  onlyWins: [
    { text: "Record based", value: "Record" },
    { text: "Accumulative", value: "Cumulative" },
  ],
  teamSize: [
    { text: "All", value: "All" },
    { text: "Solo", value: "Solo" },
    { text: "Duo", value: "Duo" },
    { text: "Trio", value: "Trio" },
    { text: "Quad", value: "Quad" },
  ],
  type: [
    { text: "Private", value: "private" },
    { text: "Public", value: "public" },
  ],
  region: continents,
};
