import placeholderImage from "../../../assets/placeholders/placeholder.png";
import { Button } from "@/components";

export const TeamsLoader = () => {
  return (
    <>
      <div className="single-team placeholder">
        {/* LEFT/ GENERAL INFO +  */}
        <div className="single-team__card">
          <div className="card">
          <div className="card__image">
            <div className="image wave">
              <div className="image__content">
              </div>
            </div>
          </div>

          {/* Games list */}
          <div className="card__info">
            <h1 className="wave" />
            <p className="wave" />
            <ul className="card__tags">
              <li className="wave" />
            </ul>
          </div>
        </div>

        </div>
          <div className="single-team__content">
            <div className="single-team__members">
              {/* Heading */}
              <div className="team-members__heading wave">
                <h2 />
                <div className="team-members__actions wave" />
              </div>

              {/* List of members */}
              <ul className="team-members__list">
                <li
                  className="team-members__item team-members__item--legend"
                  key="team-members__legend wave"
                >
                </li>
                <li className="team-members__item wave" />
                <li className="team-members__item wave" />
              </ul>
            </div>
          </div>
      </div>
    </>
  );
};
