import { FC } from "react";
import { ReactComponent as IconUSP } from "/public/assets/bestgamer/landingCircleMarker.svg";

const usps = [
    "Test dine evner",
    "Kæmp mod andre gamere",
    "Vind fede præmier"
];

export const BestgamerLandingUSP: FC<{}> = ({}) => {
    return (
        <div className="usp">
            <div className="usp__list">
                {usps.map((tag) => {
                return (
                    <div className="usp__item">
                    <IconUSP /> <span>{tag}</span>
                    </div>
                );
                })}
            </div>
        </div>
    );
};
