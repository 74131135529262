/* Returns true if toCheck only contains alphanumeric characters 
and false if it contains one or more */
export const checkEntityNameValidity = (toCheck: string): boolean => {
  return !!toCheck.match(/^[0-9a-zA-Z]+$/);
};

// step 1
export const normalizeString = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Step 2
/* Converts the passed string to a slug */
export const convertCleanEntityNameToSlug = (toConvert: string): string => {
  return toConvert
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[.#$\/\[\]~*'()]/g, "");
};

// Step 3
/* Removes then unecessary spaces from the passed string */
export const cleanEntityName = (toClean: string): string => {
  return toClean.replace(/\s+/g, " ").replace(/^\s+|\s+$/, "");
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
