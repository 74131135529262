import { useState, FC, useEffect } from "react";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_Imgix } from "@/api/imgix";
import { useScrollBlock } from "./../../../hooks/useScrollBlock";
import BaseLogo from "../../../components/BaseLogo/BaseLogo";
import { Query_shared } from "./../../../api/shared/index";
import { Button } from "./../../../components/Button/index";
import ProfileCommunityActionButtons from "./ProfileCommunityActionButtons";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { ProfileCommunityModal } from "./CommunityMembersModal";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import useDocumentTitle from "@/hooks/useDocumentTitle";

const ProfileGeneralInfo: FC<
  Profile & {
    belongsToCurrUser: boolean;
    isFollowedByCurrentUser: boolean;
    isFriendsOfCurrentUser: boolean;
    isFriendsRequestPending: boolean;
    receivedFriendRequest: boolean;
    isPlaceholder: boolean;
    community: GetUserCommunityBasedOnSlug;
    error?: string;
  }
> = (props): JSX.Element => {
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const numberOfLineBreaks = (props.profileDescription?.match(/\n/g) || [])
    .length;
  const [blockScroll, allowScroll] = useScrollBlock();
  const [communityMembersModalOpen, setCommunityMembersModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (communityMembersModalOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [communityMembersModalOpen]);

  const isMobile = windowWidth < 991;

  if (props.error) {
    //SHOW ERROR UI
    return <span>{props.error}</span>;
  }

  useDocumentTitle(`${props.userName} - Profile`);

  return (
    <>
      <BaseModalWindow
        show={communityMembersModalOpen}
        closeModal={() => {
          setCommunityMembersModalOpen(false);
        }}
        title={`${props.userName}'s ${i18next.t("profile.community.heading")}`}
      >
        <ProfileCommunityModal
          setCommunityMembersModalOpen={setCommunityMembersModalOpen}
          community={props.community}
          userName={props.userName}
        />
      </BaseModalWindow>

      <div className="card">
        {/* <div className="card__content"> */}
        <div className="card__vector">{/* <img src={vector} alt="" /> */}</div>
        <div className="card__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo
                logoSrc={Query_shared.imageErrorHandler(
                  props.profileLogoUrl?.includes("wog.imgix") ||
                    props.profileLogoUrl?.includes("iplay.imgix")
                    ? `${props.profileLogoUrl}?${Query_Imgix.getImgixUrlProps({
                        w: "190",
                        h: "190",
                        fit: "crop",
                      })}`
                    : props.profileLogoUrl,
                  false,
                  props.isPlaceholder
                )}
                name={props.userName}
                isPlaceHolder={props.isPlaceholder}
              />
            </div>

            <div className="avatar__zone">
              {/* <div>
                  <img src={themeLogo || wogS} alt="img" />
                </div> */}
            </div>
          </div>

          {/* THIS NEEDS TO BE ADDED LATER WITH REALTIME DB */}
          {/* {props.isOnline ? (
              <div className="avatar__status" />
            ) : (
              ""
            )} */}
        </div>
        <div className="card__content">
          <div className="card__user">
            {props.userName ? (
              <h1 className="user__nickname">{props.userName}</h1>
            ) : (
              <h1 className="user__nickname placeholder">Username</h1>
            )}

            {props.fullName && (
              <p className="user__fullname">{props.fullName}</p>
            )}
            {!props.uid && !props.fullName && (
              <p className="user__fullname placeholder">Name</p>
            )}
          </div>
          <div
            className={`card__bio ${
              showMoreDescription
                ? "card__bio--expanded"
                : "card__bio--collapsed"
            }`}
          >
            <div className="biography">
              <p>
                {props.profileDescription &&
                  props.uid &&
                  props.profileDescription}

                {props.belongsToCurrUser &&
                  !props.profileDescription &&
                  props.uid && <>{i18next.t("strings.share_about_yourself")}</>}

                {!props.profileDescription && !props.uid && (
                  <p className="placeholder">placeholder</p>
                )}
              </p>
            </div>
            {props.profileDescription &&
              (props.profileDescription?.length > 100 ||
                numberOfLineBreaks > 3) && (
                <Button
                  variant="link"
                  onClickAction={() => setShowMoreDescription((prev) => !prev)}
                >
                  {showMoreDescription ? "Show less" : "Show more"}
                </Button>
              )}
          </div>
          {brand.hasFeature.friends && (
            <div
              className="card__community"
              onClick={() => {
                if (!isMobile) {
                  setCommunityMembersModalOpen(true);
                }
              }}
            >
              <>
                {props.community &&
                  ["friends", "followers", "following"]
                    .filter((key) =>
                      Object.prototype.hasOwnProperty.call(props.community, key)
                    )
                    .map((key) => {
                      return (
                        <div className={`community__${key}`} key={key}>
                          {isMobile ? (
                            <a
                              onClick={() => {
                                navigate(
                                  `${siteRoutes["profileCommunityAndSlug"]}${
                                    props.slug || ""
                                  } `
                                );
                              }}
                            >
                              <span className="community__amount">
                                {props.community[key].totalNumber}
                              </span>
                              <p className="community__title">
                                {key === "friends" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.friends_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.friends_singular")
                                    )
                                  )
                                }
                                {key === "followers" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.followers_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.followers_singular")
                                      )
                                  )
                                }
                                {key === "following" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.following_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.following_singular")
                                    )
                                  )
                                }
                              </p>
                            </a>
                          ) : (
                            <button
                              type="submit"
                              onClick={() => setCommunityMembersModalOpen(true)}
                            >
                              <span className="community__amount">
                                {props.community[key].totalNumber}
                              </span>
                              <p className="community__title">
                                {key === "friends" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.friends_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.friends_singular")
                                    )
                                  )
                                }
                                {key === "followers" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.followers_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.followers_singular")
                                      )
                                  )
                                }
                                {key === "following" && (
                                  props.community[key].totalNumber > 1 ? (
                                    i18next.t("profile.community.tabs.following_plural")
                                    ) : (
                                      i18next.t("profile.community.tabs.following_singular")
                                    )
                                  )
                                }
                              </p>
                            </button>
                          )}
                        </div>
                      );
                    })}
              </>
            </div>
          )}
          <div className="card__actions">
            {props.belongsToCurrUser ? (
              <Link to="/profile-settings" className="btn btn-secondary">
                {i18next.t("buttons.manage_btn")}
              </Link>
            ) : (
              ""
            )}
            <ProfileCommunityActionButtons
              belongsToCurrUser={props.belongsToCurrUser}
              isFollowedByCurrentUser={props.isFollowedByCurrentUser}
              isFriendsOfCurrentUser={props.isFriendsOfCurrentUser}
              isFriendsRequestPending={props.isFriendsRequestPending}
              receivedFriendRequest={props.receivedFriendRequest}
              isPlaceholder={props.isPlaceholder}
              uid={props.uid}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ProfileGeneralInfo;
