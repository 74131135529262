import React, { FC, useState, useRef } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/actions/close.svg";
import { Button } from "../../../../components/Button/index";
import QueryDB from "@/api/queryDB/common";
import { Badge } from "@/components";
import { Role, mockRoles } from "../../utils/MockRolesForTeam";
import { getRoleMenuLocation } from "../../helperFunctions/getRoleMenuLocation";
import { createToast } from "@/utils/toaster/createToast";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

const SingleTeamRoleAssignMenu: FC<{
  userRole: any;
  user: any;
  teamId: string;
  teamOwner: string;
}> = (props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const roleCharLimit = 15;
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [selectedRole, setSelectedRole] = useState("");

  const isAdmin = props.userRole === "admin";
  const isOwner = props.userRole === "owner";

  //  TODO: Remove last 2 conditions for isTeamRoleUnset after teams is cleared
  const isTeamRoleUnset =
    props.user.inTeamRole === null ||
    props.user.inTeamRole === "New" ||
    props.user.inTeamRole === "new";

  const handleSetUserRole = async () => {
    const isFromCurrUser = props.user.slug === currentUser.slug;
    if (selectedRole && selectedRole.length < roleCharLimit) {
      await QueryDB.updateDoc(
        "userTeamJunction",
        `${props.user.uid}_${props.teamId}`,
        {
          inTeamRole: selectedRole,
        }
      ).then(() => {
        createToast({
          type: "info",
          message: `Role set to ${selectedRole} for ${
            isFromCurrUser ? "you" : props.user.userName
          }.`,
        });
        setOpen(false);
      });
    }
    return false;
  };

  const groupedRoles = mockRoles.reduce(
    (acc, role) => {
      if (!acc[role.game]) {
        acc[role.game] = [];
      }
      acc[role.game].push(role);
      return acc;
    },
    {} as { [game: string]: Role[] }
  );

  const menuStyles = buttonRef.current ? getRoleMenuLocation(buttonRef) : {};

  return (
    <div className="roles">
      <Button reff={buttonRef} variant="link">
        <div className="badge-wrapper">
          {isAdmin || isOwner ? (
            <>
              {isTeamRoleUnset ? (
                <Badge
                  variant={selectedRole ? "primary" : "secondary"}
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {selectedRole || "Set a role"}
                </Badge>
              ) : (
                <Badge
                  variant="primary"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {selectedRole || props.user.inTeamRole || "Role"}
                </Badge>
              )}
            </>
          ) : (
            <>
              {isTeamRoleUnset ? (
                <Badge variant="secondary">Unassigned</Badge>
              ) : (
                <Badge variant="primary">{props.user.inTeamRole}</Badge>
              )}
            </>
          )}
        </div>
      </Button>

      {/* Badge dropdown */}
      {isOpen && (
        <div ref={ref} className="roles__dropdown" style={menuStyles}>
          <div className="roles__header">
            <div className="roles__heading">Set a role</div>
            <Button
              variant="link"
              type="button"
              onClickAction={() => setOpen(false)}
            >
              <CloseIcon width={26} height={26} />
            </Button>
          </div>

          <div className="roles__body">
            <input
              placeholder="Custom Role..."
              className="base-input"
              onChange={(e) => {
                if (e.target.value.length <= roleCharLimit) {
                  return setSelectedRole(e.target.value);
                }
                return false;
              }}
              value={selectedRole}
            />
            <div className="roles__list">
              {Object.keys(groupedRoles).map((game) => (
                <div key={game}>
                  <div className="roles__subheading">{game}</div>
                  <div className="badge-wrapper">
                    {groupedRoles[game].map((role) => (
                      <Badge
                        key={role.game + role.name}
                        variant={
                          selectedRole === role.name ? "primary" : "secondary"
                        }
                        onClick={() => setSelectedRole(role.name)}
                      >
                        {role.name}
                      </Badge>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="roles__actions">
            <Button
              variant={selectedRole ? "primary" : "secondary"}
              customStyles="btn-sm"
              type="button"
              onClickAction={() => {
                handleSetUserRole();
              }}
            >
              Set
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default SingleTeamRoleAssignMenu;
