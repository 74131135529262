import React from "react";
import Form from "react-bootstrap/Form";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";
import { BaseTextInputWithMentions } from "./BaseTextInputWithMentions";
import { v4 } from "uuid";

export const BaseTextInput: React.FunctionComponent<{
  label?: string | any;
  isDisabled?: boolean;
  textType: "text" | "textarea" | "file" | "password" | "number" | "checkbox" | "checkmark";
  value?: any;
  setSingleState?: any;
  setObjectState?: any;
  setObjectStateTargetName?: string;
  placeholder?: string;
  error?: string | undefined;
  functionOnEnter?: any;
}> = (props) => {
  return (
    <>
      {props.label && props.textType !== "checkmark" ? (
        <label className="settings__label">{props.label}</label>
      ) : (
        ""
      )}

      {props.textType === "file" && (
        <input
          className={`settings__file base-input ${
            props.textType === "file" && "d-none"
          }`}
          id={v4()}
          autoComplete="disabled"
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.isDisabled}
          onKeyDown={(e) => {
            if (props.functionOnEnter && e.key === "Enter") {
              return props.functionOnEnter();
            }
          }}
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return false;
          }}
          type={props.textType}
        />
      )}

      {props.textType === "checkbox" && (
        <div
          className={`switch ${
            props.value === true ? "switch--active" : "switch--inactive"
          } `}
        >
          <input
            onChange={(e) => {
              if (props.setSingleState) {
                return props.setSingleState(!props.value);
              }
              if (props.setObjectState) {
                props.setObjectState(
                  props.setObjectStateTargetName,
                  !props.value
                );
              }
              return false;
            }}
            id="toggle"
            checked={props.value}
            type="checkbox"
            name="toggle"
            value={props.value}
          />
          <span />
        </div>
      )}

      {props.textType === "checkmark" && (
        <>
          <div className="base-input__checkmark">
              <label htmlFor={`checkmark`} className="checkmark">
                  {props.label && (
                      <span className="checkmark__label">
                          {props.label}
                      </span>
                  )}
                  <input
                      id={`checkmark`}
                      type="checkbox"
                      checked={props.value}
                      value={props.value}
                      onChange={(e) => {
                        if (props.setSingleState) {
                          return props.setSingleState(!props.value);
                        }
                        if (props.setObjectState) {
                          props.setObjectState(
                            props.setObjectStateTargetName,
                            !props.value
                          );
                        }
                        return false;
                      }}
                  />
                  <span className="checkmark__item"></span>
              </label>
          </div>
        </>
      )}

      {props.textType === "text" ||
      props.textType === "password" ||
      props.textType === "number" ? (
        <input
          autoComplete="disabled"
          id={v4()}
          className="base-input settings__input"
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          value={props.value}
          onKeyDown={(e) => {
            if (props.functionOnEnter && e.key === "Enter") {
              return props.functionOnEnter();
            }
          }}
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return false;
          }}
          type={props.textType}
        />
      ) : (
        ""
      )}

      {props.textType === "textarea" && (
        <textarea
          autoComplete="disabled"
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          className="base-input settings__textarea"
          onKeyDown={(e) => {
            if (props.functionOnEnter && e.key === "Enter") {
              props.functionOnEnter();
            }
          }}
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return null;
          }}
          name=""
          id={v4()}
          value={props.value}
        >
          {props.value}
        </textarea>
      )}
      {props.error && <FormErrorMessage text={props.error} isVisible={true} />}
    </>
  );
};
