import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconArrow } from "../../features/home/assets/arrow-right.svg";
import { getDateInStringFormat } from "@/utils/constants/formatDate";
import heroProps from "./brands/heroProps";
import { Button } from "../Button";

const Hero: FC<heroProps> = ({
  image,
  description,
  heading,
  btn,
  authorName,
  postedDate,
}): JSX.Element => {
  const hasMobileImage = image.mobile !== undefined;
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [mobileImage, setMobileImage] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pageWidth <= 768 && hasMobileImage) {
      setMobileImage(true);
    }
  }, [pageWidth, hasMobileImage]);

  useEffect(() => {
    if (pageWidth <= 768) {
      setIsMobile(true);
    }
  }, [pageWidth, setIsMobile]);

  return (
    <div className="hero">
      <div className="hero__image">
        <div className="image">
          <div className="image__content">
            <img
              src={mobileImage ? image.mobile : image.src}
              alt={image.alt}
              title={image.title}
            />
          </div>
        </div>
      </div>
      <div className="hero__content">
        <div className="hero__body">
          <div className="hero__text">
            <h2 className="hero__heading">{heading}</h2>
            {isMobile ? (
              <></>
            ) : (
              <p className="hero__description">{description}</p>
            )}
            {btn.label && (
              <Button
                onClickAction={() => {
                  navigate(btn.link || undefined);
                }}
                customStyles="hero__btn"
                variant="primary"
              >
                {btn.label} <IconArrow strokeWidth={2} />
              </Button>
            )}
          </div>
        </div>
        {authorName && (
          <div className="hero__meta">
            <div className="hero__author">
              <>By {authorName}</>
            </div>
            {postedDate && (
              <div className="hero__timestamp">
                {getDateInStringFormat(postedDate?._seconds)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
