import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";

export const joinTeam = async (currentUserId: any, slug: any, type: any) => {
  await QueryDB.createNewDoc(
    "userTeamJunction",
    {
      pending: true,
      uid: currentUserId,
      teamId: slug,
      generalRole: "random",
      joinedTeamTimestamp: null,
      monthlyPoints: 0,
      status: "pendingRequest",
      notificationSettings: {
        memberRequests: false,
        newMembers: false,
        newPostsInFeed: false,
        newComntsInFeed: false,
        upcomingEvents: false,
      },

      isFavorite: true,
      isPlayerOfTheMonth: false,
      inTeamRole: null,
    },
    `${currentUserId}_${slug}`
  ).catch((err) => {
    createToast({
      type: "error",
      message: "Failed to create request, please try again later",
    });
  });
};
