import React, { useEffect } from "react";
import NavigationBar from "./topNavigation/components/NavigationBar.fetcher";
import { ToastContainer } from "react-toastify";
import { SideMenu } from "./sideNavigation/components/SideMenu.fetcher.ui";
import { useLocation, useNavigate } from "react-router-dom";
import UnAuthorizedLayout from "./UnAuthorizedLayout";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "./../utils/brandVariables/brandVariables";
import { siteRoutes } from "../../branchAndBrandSpecific/routes/siteRoutesList";
import LayoutFooter from "../layout/footer/layoutFooter";
import Consent from "@/components/Consent/Consent";
import firebase from "firebase/app";
import QueryDB from "@/api/queryDB/common";
import { useAtomValue } from "jotai";
import { clientAtom } from "@/utils/atoms/atomClient";
import { auth } from "@/api/firebase/firebase.config";
import { ForceCredentialPopup } from "@/components/ForcedCredentialPopup/ForcedCredentialPopup";

export default function Layout({ children }: { children: React.ReactNode }) {
  const noSideMenu = false;
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);
  // IN CASE OF SIGN UP 3 OR OTHER SIGN UP PAGES, ADD HERE | WILL REMOVE SIDEBAR AND FIX SCSS
  if (location.pathname === "/signup-success") {
    return <UnAuthorizedLayout>{children}</UnAuthorizedLayout>;
  }
  const currentClient = localStorage.getItem("client")?.trim() || "";
  const currentCLientAtom = useAtomValue(clientAtom);
  // Set the theme of the current user on page load/refresh
  useEffect(() => {
    // Scroll to the top of the window

    if (brand.hasFeature.themes) {
      document.documentElement.className = `theme-${currentUser.theme}`;
    } else {
      document.documentElement.className = `theme-${brand.brandTheme}`;
    }
    if (document.body.classList.contains("landing-bg")) {
      document.body.classList.remove("landing-bg");
    }

    if (
      (window.location.pathname === "/" ||
        window.location.pathname === "/landing") &&
      currentUser
    ) {
      navigate(siteRoutes["home"]);
    }
  }, [currentUser]);

  useEffect(() => {
    const arr = (currentUser?.allPlatforms as any[]) || [];

    if (
      currentClient !== "" &&
      !currentUser?.allPlatforms?.includes(currentClient)
    ) {
      arr.push(currentClient);
    }

    if (
      currentCLientAtom !== "" &&
      !currentUser?.allPlatforms?.includes(currentCLientAtom)
    ) {
      arr.push(currentCLientAtom);
    }

    if (currentClient && arr?.length !== 0) {
      QueryDB.updateDoc("profiles", currentUser.uid, {
        allPlatforms: arr,
      }).then(() => {});
    }
  }, [currentUser, currentClient, currentCLientAtom]);

  const shouldRenderPopup = () => {
    // return true;
    const forcedCredentialPopup = brand.event.forcedCredentialPopup;

    if (forcedCredentialPopup === "email") {
      return !currentUser.email && !auth?.currentUser?.email;
    }

    if (forcedCredentialPopup === "phone") {
      return !currentUser.phoneNumber && !auth?.currentUser?.phoneNumber;
    }

    return false;
  };

  const getContainerClass = () => {
    if (
      (window.location.pathname === "/home" ||
        window.location.pathname === "/treasure-hunt") &&
      brand.brandValue === "BESTGAMER"
    ) {
      return "bestgamer";
    } else {
      return "";
    }
  };

  return (
    <>
      {!noSideMenu && (
        <>
          <SideMenu currentViewName="home" />
          <NavigationBar />
          <main>
            <div className={`container ${getContainerClass()}`}>{children}</div>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
              theme="dark"
              limit={3}
            />
          </main>
          <LayoutFooter />
        </>
      )}
      <Consent
        layout={brand.brandValue === "ELGIGANTEN" ? "small" : "default"}
      />

      {shouldRenderPopup() && <ForceCredentialPopup />}
    </>
  );
}
