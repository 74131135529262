import {
  Author,
  singleNewsOverviewTypes,
} from "@/features/news/types/NewsTypes";
import { News } from "@/models/database/news/News.model";
import { atomWithStorage } from "jotai/utils";

export const newsOverviewAtom = atomWithStorage<News[]>("WOG_news_all", []);
export const featuredNewsAtom = atomWithStorage<News[]>(
  "WOG_news_featured",
  []
);
export const visitedNewsAtom = atomWithStorage<singleNewsOverviewTypes[]>(
  "WOG_news_data",
  []
);
