import React, { FC, useState } from "react";
import { EditTeamRightsFormValues } from "../../types/TeamTypes";
import { AuthorProfile } from "@/models/database/profiles/profile.model";
import { createTeamOptions } from "../../utils/CreateTeamOptions";
import { TeamRightsOptionsList } from "./TeamRightsOptionsList";
type AdminResp = {
  profile: AuthorProfile;
  role: "owner" | "admin" | "random" | "member";
};

export const TeamRightsPermissionsSettings: FC<{
  type: "public" | "private";
  postInFeed: "all" | "members" | "admins";
  canLeaveComments: "all" | "members" | "admins";
  canSendRequest: "all" | "friends" | "friendsFriends" | "no";
  teamSlug: string;
  setUpdateTrigger: any;
  userRole: "owner" | "admin" | "random" | "member";
}> = (props) => {
  const [teamRights, setTeamRights] = useState<EditTeamRightsFormValues | any>({
    type: props.type,
    postInFeed: props.postInFeed,
    canLeaveComments: props.canLeaveComments,
    canSendRequest: props.canSendRequest,
  });

  const setFieldValue = (targetName: string, value: any) => {
    return setTeamRights((prev: EditTeamRightsFormValues) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  return (
    <div className="content__permissions">
      {/* Type */}
      <div className="access">
        <fieldset className="radio-group">
          <legend className="radio-group__heading">
            <span>Type</span>
          </legend>
          <div className="radio-group__input-group">
            {createTeamOptions.type.map((option) => {
              return (
                <div className="radio-group__input" key={option.text}>
                  <label
                    className={
                      teamRights.type === option.value ? "active" : "inactive"
                    }
                    htmlFor={`${option.value}`}
                  >
                    {option.text}
                  </label>
                  <input
                    type="radio"
                    value={`${option.value}`}
                    id={`${option.value}`}
                    onClick={(e: any) => setFieldValue("type", e.target.value)}
                  />
                </div>
              );
            })}
          </div>
        </fieldset>
      </div>

      {/* Rights Select */}
      <TeamRightsOptionsList
        setFieldValue={setFieldValue}
        teamRights={teamRights}
        teamSlug={props.teamSlug}
        setUpdateTrigger={props.setUpdateTrigger}
      />
    </div>
  );
};

export default TeamRightsPermissionsSettings;
