import { RadioInputsGroup } from "@/components";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { ImageUpload } from "@/components/ImageUpload/ImageUpload";
import KeywordsSelector from "@/components/KeywordsSelector/KeywordsSelector";
import { CreateTeamTypes } from "@/features/team/types/CreateTeamTypes";
import { createTeamLabels } from "@/features/team/utils/CreateTeamLabels";
import { createTeamOptions } from "@/features/team/utils/CreateTeamOptions";
import { FC } from "react";
import { ReactComponent as IconUpload } from "../../../../challenge/assets/actions/upload-cloud.svg";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";

export const CreateTeamStep1: FC<{
  teamValues: CreateTeamTypes;
  errors: any;
  isEdit: boolean;
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  slug?: string;
}> = ({ teamValues, errors, isEdit, setFieldValue, slug }) => {
  return (
    <div className="content">
      {/* Type */}
      {!isEdit && (
        <RadioInputsGroup
          name="type"
          options={createTeamOptions.type}
          label={createTeamLabels.type}
          error={errors && errors["type"] ? errors["type"] : undefined}
          setFieldValue={setFieldValue}
          initVal={teamValues.type}
        />
      )}

      {/* Name */}
      <fieldset className="input-group">
        <legend className="input-group__heading">Team name</legend>
        <BaseTextInput
          label=""
          textType="text"
          value={teamValues.name}
          placeholder="Name"
          setObjectState={setFieldValue}
          setObjectStateTargetName="name"
          error={errors && errors["name"] ? errors["name"] : undefined}
        />
        <span className="input-group__help-grid">
          {isEdit && (
            <span className="input-group__help">
              The URL will remain: /teams/{slug}
            </span>
          )}
          <span className="input-group__help">Max. 25 characters</span>
        </span>
      </fieldset>

      {/* Description */}
      <fieldset className="input-group input-group__type--textarea">
        <legend className="input-group__heading">Description</legend>
        <BaseTextInput
          textType="textarea"
          value={teamValues.description}
          placeholder="Description"
          setObjectState={setFieldValue}
          setObjectStateTargetName="description"
          error={errors && errors["description"]}
        />
        <span className="input-group__help">Max. 470 characters</span>
      </fieldset>

      {/* Logo upload */}
      <fieldset className="input-group input-group__type--file">
        <legend className="input-group__heading">
          Image <small>Max 25MB</small>
        </legend>
        <ImageUpload
          acceptType="image/*"
          setIsLoading={() => {}}
          setFieldValue={setFieldValue}
          setType={() => {}}
          value={teamValues.logoUrl}
          name="logoUrl"
        >
          <div className="input-group__upload">
            {teamValues.logoUrl ? (
              <span
                title={teamValues.logoUrl?.name || teamValues.logoUrl}
                className="input-group__filename"
              >
                {teamValues.logoUrl?.name || teamValues.logoUrl}
              </span>
            ) : (
              <IconUpload />
            )}
          </div>
        </ImageUpload>

        {errors && errors["logoUrl"] && (
          <FormErrorMessage
            text={errors["logoUrl"] as string}
            isVisible={errors["logoUrl"]}
          />
        )}
      </fieldset>

      {/* Games/Keywords */}
      <fieldset className="input-group">
        <legend className="input-group__heading">Tags</legend>
        <KeywordsSelector
          setObjectValue={setFieldValue}
          setObjectTargetName="games"
          objectValue={teamValues.games || []}
          label=""
          inputPlaceholder="Create your own tag ..."
          disableCustom={false}
        />
        {errors && errors["games"] && (
          <FormErrorMessage
            text={errors["games"] as string}
            isVisible={errors["games"]}
          />
        )}
      </fieldset>
    </div>
  );
};
