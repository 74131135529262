import { useNavigate, useParams } from "react-router-dom";
import React, { FC, useEffect } from "react";
import { createToast } from "@/utils/toaster/createToast";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { PostData } from "../../types/singlePostsProps";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { SinglePostComments } from "../SinglePost/SinglePostComments.fetcher";
import { Button, ErrorFallback, Spinner } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { SinglePostComponent } from "../..";

export const SinglePostPage: FC<{}> = ({}): JSX.Element => {
  const { slug } = useParams();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  const [post, loading, data_error, fetch_data] =
    useCloudFunctionCall<PostData>("getPostBasedOnSlug", slug);

  useEffect(() => {
    const getPostData = async () => {
      await fetch_data({
        postId: slug,
        currUserId: currentUser.uid,
      }).catch(() => {
        createToast({
          type: "error",
          message: "Something went wrong trying to load post",
        });
        navigate(siteRoutes["dashboard"]);
      });
    };
    if (slug) {
      getPostData();
    }
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {data_error && (
        <ErrorFallback>
          <h2>Failed to load post</h2>
          <Button
            variant="primary"
            onClickAction={() => {
              navigate(siteRoutes["dashboard"]);
            }}
          >
            Back to feed
          </Button>
        </ErrorFallback>
      )}
      <div className="single-post">
        <div>
          {post && (
            <SinglePostComponent
              postData={post}
              isFullPagePost={true}
              actionButtons
              isInModal={false}
              isFriendsOfCurrentUser={true}
              likeParentId={post.id}
              createCommentEnabled={true}
            />
          )}
        </div>

        <div className="single-post__comments">
          {post && (
            <SinglePostComments
              showComments={false}
              postId={slug as string}
              postAuthorUid={""}
            />
          )}
        </div>
      </div>
    </>
  );
};
