import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { ReactComponent as IconGather } from "../../assets/share-gather.svg";
import { Query_shared } from "../../../../api/shared/index";
import { Query_Imgix } from "../../../../api/imgix/index";
import { PostData } from "../../types/singlePostsProps";
import { Badge } from "@/components";

const SharedGather: React.FunctionComponent<{
  data: PostData;
  isFromFeed: boolean;
}> = ({ isFromFeed = false, data }) => {
  const [logoError, setLogoError] = useState<boolean>(false);
  if (data && data.additionalData) {
    try {
      return (
        <Link
          to={`/gathers/${data.additionalData.slug}`}
          title={data.additionalData.name}
        >
          <div
            aria-hidden
            className={`shared-gather ${isFromFeed ? "feed" : ""}`}
          >
            <div className="shared-gather__image ">
              <div className="image">
                <div className="image__content">
                  <Image
                    onError={() => setLogoError(true)}
                    alt={`User-uploaded image for ${name} team`}
                    src={Query_shared.imageErrorHandler(
                      data.additionalData.logoUrl.includes("wog.imgix") ||
                        data.additionalData.logoUrl.includes("iplay.imgix")
                        ? `${
                            data.additionalData.logoUrl
                          }?${Query_Imgix.getImgixUrlProps({
                            w: "248",
                            h: "165",
                          })}`
                        : data.additionalData.logoUrl,
                      logoError,
                      undefined
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="shared-gather__content">
              <div className="shared-gather__header">
                <h3 className="shared-gather__heading">
                  {data.additionalData.name}
                </h3>
                <div className="badge-wrapper">
                  <Badge variant={"primary"}>{data.additionalData.type}</Badge>
                  <Badge variant={"secondary"}>
                    {data.additionalData.meetType}
                  </Badge>
                </div>
              </div>
              <ul className="shared-gather__tags">
                {data.additionalData.games.map((game: any) => {
                  return (
                    <li title={game} key={game}>
                      {game}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="shared-gather__icon">
              <IconGather width={36} height={36} />
            </div>
          </div>
        </Link>
      );
    } catch (error) {
      return (
        <em>Failed to load shared gather, it might no longer be available.</em>
      );
    }
  } else {
    return (
      <em>Failed to load shared gather, it might no longer be available.</em>
    );
  }
};

export default SharedGather;
