import React, { FC } from "react";

export const EventDetails: FC<{
  goal: string;
  description: string;
  game: string;
}> = ({ goal, description, game}): JSX.Element => {
  return (
    <>
      {game && <h2>{game} challenge</h2>}

      {goal && (
        <>
          <h3>What to do</h3>
          <p>{goal}</p>
        </>
      )}

      {description && (
        <>
          <h3>How we calculate your score</h3>
          <p>{description}</p>
        </>
      )}
    </>
  );
};
