import React, { FC } from "react";
import { ReactComponent as LockIcon } from "../assets/lock.svg";

export const LockedTask: FC<{}> = () => {
  return (
    <div className="active__task">
      <div className="task__locked">
        <figure>
          <LockIcon />
        </figure>
        <h6>The task will be active soon!</h6>
      </div>
    </div>
  );
};
