import React, { FC, useState, useEffect } from "react";
import BannerImage from "../assets/TrackerBanner.jpg";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

const StaticOverviewUI: FC = (): JSX.Element => {
  const [link, setLink] = useState<string>();
  const getLink = async () => {
    const link = await firebase
      .database()
      .ref("/tracker-version")
      .child("spike-tracker")
      .child("link")
      .get();

    if (link) {
      setLink(link.val());
      return;
    }
  };

  useEffect(() => {
    getLink();
  }, []);

  return (
    <div className="archive__image align-full">
      <figure className="adaptive">
        <picture className="adaptive-photo">
          <img src={BannerImage} />
        </picture>
      </figure>
      <div className="archive__content ">
        <div className="overview-archive container">
          <div className="overview-archive__content">
            <h1>Getting Started</h1>
          </div>
          <p>
            Start tracking your gaming performance and climb the leaderboards!
          </p>
          <div className="overview-archive__actions">
            <Link className="btn btn-primary" to={link}>
              Download for Windows
            </Link>
          </div>
          <small>For Windows 10 and Windows 11.</small> {" "}
          {/* <small>Game Tracker is not needed to track PUBG or Dota2.</small> */}
        </div>
      </div>
    </div>
  );
};

export default StaticOverviewUI;
