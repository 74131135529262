import { FC } from "react";
import { Button, Spinner } from "@/components";

export const CreateTeamHeader: FC<{
  step: number;
  stepsAmount: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  submitting: boolean;
  handleNextStep: () => Promise<false | void>;
  name: string;
}> = ({ step, setStep, submitting, name, stepsAmount, handleNextStep }) => {

  // Variables
  const isPreviewPage = step + 1 === stepsAmount;

  // Preview Team before submitting
  if (isPreviewPage) {
    return (
        <div className="create-team__alert">
          <h3>Is {name} ready to go live?</h3>
          <div className="create-team__actions">
            <Button
              variant="secondary"
              onClickAction={() => setStep((s) => s - 1)}
              disabled={submitting}
            >
              Edit
            </Button>

            {
              <Button
                variant="primary"
                type="button"
                onClickAction={() => handleNextStep()}
              >
                {submitting ? <Spinner /> : "Yes, activate"}
              </Button>
            }
          </div>
        </div>
    )
  }

  // Any other case
  return (
    <>
      <h1>Create a new team</h1>
      <span>{`Step ${step + 1}/${stepsAmount}`}</span>
    </>
  );
};
