import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VscCircleFilled as UnreadIcon } from "@react-icons/all-files/vsc/VscCircleFilled";
import { archiveOrReadNotification } from "./../helperFunctions/readNotification";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "./../../../utils/toaster/createToast";
import SingleNotificationActions from "./SingleNotificationActions";
import {
  notificationRoutes,
  showActionTypes,
} from "@/utils/constants/notificationVariables";
import { Button, ErrorFallback } from "@/components";
import { typesToIgnore } from "../helperFunctions/notificationWithActions";
import BaseUserAvatar from "@/components/BaseUserAvatar";

const SingleNotificationItem: React.FunctionComponent<{
  data: any;
  docId: string;
  whenWasSent: any;
}> = ({ data, docId, whenWasSent }) => {
  const [read, setRead] = useState<boolean>(data.read);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [toasted, hasToasted] = useState<boolean>(false);
  const [dataObj, setDataObj] = useState<any>(false);

  useEffect(() => {
    const fetch = async () => {
      const response = await QueryDB.getSingleDoc(
        showActionTypes[data.type]?.collection,
        data.contentId
      );
      if (response) {
        setDataObj(response);
      }
    };
    if (showActionTypes[data.type]) {
      fetch();
    }
  }, []);
  useEffect(() => {
    setRead(data.read);
  }, [data.read]);

  const readNotification = useCallback(async () => {
    if (!read) {
      if (typesToIgnore[data.type]) {
        return;
      }
      await archiveOrReadNotification(docId, true, false);
      setRead(true);
    }
  }, [read, data.type, data.notificationId]);

  if (
    !data.archived &&
    !data.read &&
    data.type === "message-recieved" &&
    window.location.pathname === "/messages"
  ) {
    readNotification();
  }

  if (
    !data.read &&
    !read &&
    !data.archived &&
    !toasted &&
    data.type === "message-recieved" &&
    window.location.pathname !== "/messages"
  ) {
    readNotification();
    hasToasted(true);
    createToast({
      type: "success",
      message: (
        <div
          onClick={() => {
            navigate(data.link);
          }}
        >
          {name} {data.content}
        </div>
      ),
    });
  }

  try {
    return (
      <>
        <div
          className="notifications__item"
          onClick={() => {
            if (!read && !showActionTypes[data.type]) {
              readNotification();
            }
          }}
        >
          {!read ? (
            <div className="notifications__status">
              <UnreadIcon color="#9b00ff" />
            </div>
          ) : (
            ""
          )}
          <Button
            onClickAction={() => {
              navigate(
                notificationRoutes[data.type]
                  ? `/${notificationRoutes[data.type]}/${data.contentId}`
                  : "/dashboard"
              );
            }}
            variant="notification"
          >
            <div className="notification">
              <div className="notification__image">
                <div className="image">
                  <div className="image__content">
                    <BaseUserAvatar
                      logoUrl={data.senderProfileLogoUrl}
                      userName={data.senderUsername}
                    />
                  </div>
                </div>
              </div>
              <div className="notification__content">
                <div className="notification__message">
                  <p>
                    <span className="notification__sender">
                      {data.senderUsername}
                    </span>{" "}
                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                  </p>
                </div>
                <div className="notification__meta">
                  <time>{whenWasSent}</time>
                </div>
              </div>
            </div>
          </Button>
          {showActionTypes[data.type] && (
            <SingleNotificationActions
              read={read}
              setRead={setRead}
              data={data}
              docId={docId}
              dataObj={dataObj}
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </div>
      </>
    );
  } catch {
    <ErrorFallback> Error loading notification</ErrorFallback>;
  }
};

export default SingleNotificationItem;
