import React, { FC } from "react";
import { TeamCardComponent } from "./TeamCardComponent";

type Props = {
  teamId: string;
  name: string;
  slug: string;
  games: string[];
  teamLogo: string;
  firstFourParticipantsLogos: { slug: string; logoUrl: string }[];
  memberCountCalculated: number;
  isFavorite: boolean;
};

export const TeamCard: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <TeamCardComponent
        teamData={{
          teamId: props.slug,
          name: props.name,
          slug: props.slug,
          games: props.games || [],
          teamLogo: props.teamLogo,
          firstFourParticipantsLogos: props.firstFourParticipantsLogos || [],
          memberCountCalculated: props.memberCountCalculated,
          isFavorite: props.isFavorite,

          id: undefined,
          type: "public",
        }}
      />
    </>
  );
};
