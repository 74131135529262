import React, { FC, useEffect, useState } from "react";
import { Button } from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SingleTeamLeaveProps } from "../../types/LeaveTeamProps";
import { CommunityMember } from "@/features/messages/types/UserCommunity";
import { leaveTeam } from "../../helperFunctions/leaveTeam";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { createToast } from "@/utils/toaster/createToast";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { Query_Imgix } from "@/api/imgix";
import BaseUserLoader from "@/components/skeletonLoaders/BaseUserLoader/BaseUserLoader";
import { useCacheDataArrayManager } from "@/hooks/useCacheDataArrayManager";
import {
  getTeamMembersBasedOnSlug,
  searchTeamMember,
} from "@/api/queryRealtime/Teams";
import { useDebounce } from "@/hooks/useDebounce";

const SingleTeamLeaveModal: FC<SingleTeamLeaveProps> = (props): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [newOwner, setNewOwner] = useState<CommunityMember[]>([]);
  const [searchedMembers, setSearchedMembers] = useState<any[] | undefined>(
    undefined
  );
  const filterKey = `all_members_leave_model_${props.teamId}`;
  const [
    teamMembers,
    _lastFeedDateInSeconds,
    _hasMore,
    _loadingAllMembers,
    _errorAllMembers,
    fetchTeamMembersData,
    ref,
    inView,
  ] = useCacheDataArrayManager<any>(getTeamMembersBasedOnSlug, filterKey);
  function fetchTeamMembers() {
    //for fetch more -> set lastFeedDateInSeconds as number
    return fetchTeamMembersData(
      {
        slug: props.teamId,
        currUserId: currentUser.uid,
        lastFeedDateInSeconds: _lastFeedDateInSeconds || null,
      },
      filterKey
    );
  }

  useEffect(() => {
    if (props.userRole === "random") {
      return;
    }
    if (inView) {
      fetchTeamMembers();
    }
  }, [inView]);
  useEffect(() => {
    if (props.teamId) {
      if (props.userRole === "random") {
        return;
      }
      fetchTeamMembers();
    }
  }, [props.teamId]);

  const checkIfSelected = (friend: CommunityMember) => {
    if (newOwner.some((obj) => obj.slug === friend.slug)) {
      return true;
    }
    return false;
  };

  const handleLeaveTeam = () => {
    if (newOwner.length !== 0) {
      return leaveTeam(
        "userTeamJunction",
        currentUser.uid!,
        props.teamId,
        props.teamName,
        true,
        newOwner[0].uid
      )
        .then(() => {
          props.setUpdateTrigger((prev: number) => prev + 1);
          createToast({
            type: "info",
            message: `You left ${props.teamName} and assinged \`${newOwner[0].userName}\` as the new owner.`,
          });
        })
        .catch((err) => {
          createToast({
            type: "error",
            message: `Something went wrong, please try again later.`,
          });
        })
        .finally(() => {
          // navigate("/teams");
        });
    }
  };

  const handleAddMember = (friend: CommunityMember) => {
    if (newOwner.length > 0 && checkIfSelected(friend)) {
      const array = [...newOwner];
      const loc = newOwner.indexOf(friend, 0);
      array.splice(loc, 1);
      setNewOwner([]);
      return setNewOwner(array);
    }
    return setNewOwner([friend]);
  };
  const searchableDebouncedText = useDebounce(searchValue, 250);
  useEffect(() => {
    async function searchMembers() {
      const searchedMembers = await searchTeamMember(
        props.teamId,
        searchableDebouncedText
      );
      setSearchedMembers(searchedMembers);
    }
    if (Boolean(searchableDebouncedText)) {
      searchMembers();
    } else {
      setSearchedMembers(undefined);
    }
  }, [searchableDebouncedText]);

  const teamMembersToRender = searchedMembers || teamMembers;

  return (
    <BaseModalWindow
      show={props.leaveTeamModalOpen}
      closeModal={() => {
        props.setLeaveTeamModalOpen(false);
      }}
      title="Leave team"
    >
      <div className="leave">
        <p>
          Assign a new owner from the members list before leaving. Warning: this
          action is irreversible and you will lose the ownership of the team.
        </p>

        <div className="invite">
          <div className="invite__search">
            <label htmlFor="searchMember">
              <input
                type="search"
                id="searchMember"
                placeholder="Search by username"
                className="base-input"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="invite__content invite__content--single">
            <div className="invite__friends">
              <ul className="invite__list">
                {!searchValue &&
                  teamMembersToRender &&
                  teamMembersToRender.length !== 0 &&
                  teamMembersToRender.map((member: any, index) => {
                    return member.uid !== currentUser.uid ? (
                      <li
                        className={`invite__item ${
                          checkIfSelected(member) && "invite__item--selected"
                        }`}
                        aria-hidden
                        onClick={() => {
                          handleAddMember(member);
                        }}
                        key={`newOwnerSearchResult_${index}`}
                      >
                        <BaseUserAvatar
                          logoUrl={member.profileLogoUrl}
                          userName={member.userName}
                          customStyles=""
                        />
                        <div className="item__info">
                          <p>{member.userName}</p>
                          <span>{member.fullName}</span>
                        </div>
                      </li>
                    ) : (
                      ""
                    );
                  })}

                {searchValue.length !== 0 &&
                  teamMembersToRender &&
                  (searchedMembers && searchedMembers.length === 0 ? (
                    <li
                      className="invite__item invite__item--empty"
                      key="friends_empty"
                    >
                      <div>
                        <p>Nothing matched your search.. Try again!</p>
                      </div>
                    </li>
                  ) : (
                    searchedMembers &&
                    searchedMembers.map((friend, index) => {
                      const key = `${friend.userName}_${index}`;
                      return (
                        <>
                          {friend.uid !== currentUser.uid ? (
                            <li
                              className={`invite__item ${
                                checkIfSelected(friend) &&
                                "invite__item--selected"
                              }`}
                              aria-hidden
                              onClick={() => {
                                handleAddMember(friend);
                              }}
                              key={key}
                            >
                              <div>
                                <BaseUserAvatar
                                  logoUrl={friend.profileLogoUrl}
                                  userName={friend.userName}
                                  customStyles=""
                                />
                                <div className="item__info">
                                  <p>{friend.userName}</p>
                                  <span>{friend.fullName}</span>
                                </div>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  ))}

                {_loadingAllMembers && (
                  <li className="invite__item" key="comunity_invite">
                    <div>
                      <BaseUserLoader />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="invite__actions">
            <Button
              variant={newOwner.length === 0 ? "secondary" : "primary"}
              type="button"
              disabled={newOwner.length === 0}
              onClickAction={() => {
                handleLeaveTeam();
              }}
            >
              Confirm and leave
            </Button>
          </div>
        </div>
      </div>
    </BaseModalWindow>
  );
};

export default SingleTeamLeaveModal;
