import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import React from "react";
import { ChallengeItem } from "../../types/props/ChallengeItemProps";
import { Link } from "react-router-dom";
import BaseImage from "../../../../components/BaseImage/BaseImage";
import { getProgressBarValue } from "../../helperFunctions/getProgressBarValue";
import ProgressBar from "../ProgressBar";
import { Badge } from "@/components";
import useProfile from "../../hooks/useProfile";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import ChallengeCardAltLoader from "@/components/skeletonLoaders/ChallengeCardAlt";
import { fromUnixTime, format } from "date-fns";

const ChallengeCardAlt: React.FC<ChallengeItem> = ({
  id,
  client,
  created,
  createdBy,
  startDate,
  endDate,
  game,
  gameType,
  name,
  slug,
  goal,
  pointCountingType,
  status,
  type,
  logoUrl,
  creatorType,
  author,
  fieldToCompare,
  challengeAdditionalData,
  description,
}) => {
  const { profile, loading } = useProfile(createdBy);
  const isEventSite = brand.event.isEvent;

  const startDateFormatted =
    startDate?.["seconds"] &&
    format(fromUnixTime(startDate["seconds"]), "dd.MM.yyyy");
  const endDateFormatted =
    endDate?.["seconds"] &&
    format(fromUnixTime(endDate["seconds"]), "dd.MM.yyyy");
  const startTimeFormatted =
    startDate?.["seconds"] &&
    format(fromUnixTime(startDate["seconds"]), "HH:mm");
  const endTimeFormatted =
    endDate?.["seconds"] && format(fromUnixTime(endDate["seconds"]), "HH:mm");

  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/world-of-gamers-d663f.appspot.com";
  const replacementUrl = "https://ik.imagekit.io/gmng/";

  if (logoUrl.includes(baseUrl)) {
    logoUrl = logoUrl.replace(baseUrl, replacementUrl) + "&tr=w-250,q-90";
  } else {
    logoUrl = logoUrl + "?&tr=w-250,q-90";
  }

  if (!loading) {
    return (
      <div className="challengesAlt__item" key={id}>
        <Link to={`${siteRoutes["newchallenge"]}${slug}`}>
          <div className="challengeAlt">
            <div className="challengeAlt__image">
              <BaseImage logoUrl={logoUrl} aspectRatio="3x4" title={name} />
              <div className="challengeAlt__game">
                <div className="badge-wrapper">
                  <Badge variant="secondary">{game}</Badge>
                </div>
              </div>
            </div>
            <div className="challengeAlt__body">
              <div className="challengeAlt__content">
                <div className="user">
                  <div className="user__avatar">
                    <div className="avatar">
                      <div className="avatar__content">
                        {isEventSite ? (
                          <BaseLogo
                            name={brand.name}
                            logoSrc={brand.siteIcon}
                          />
                        ) : (
                          <BaseLogo
                            name={profile.userName}
                            logoSrc={profile.profileLogoUrl}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="challengeAlt__details">
                  <div className="challengeAlt__title">{name}</div>

                  <div className="challengeAlt__author">
                    {isEventSite
                      ? client !== "WOG"
                        ? client
                        : brand.name
                      : profile.userName}
                  </div>
                </div>
              </div>

              <div className="challengeAlt__date">
                {startDateFormatted &&
                  endDateFormatted &&
                  (startDateFormatted === endDateFormatted ? (
                    <div className="challengeAlt__startdate challengeAlt__startdate--center">
                      {startDateFormatted}
                    </div>
                  ) : (
                    <>
                      <div className="challengeAlt__startdate">
                        {startDateFormatted}
                      </div>

                      <div className="challengeAlt__enddate">
                        {endDateFormatted}
                      </div>
                    </>
                  ))}
              </div>

              <ProgressBar
                id={`${slug}`}
                label={[""]}
                value={getProgressBarValue(
                  startDate?.["seconds"],
                  endDate?.["seconds"]
                )}
                color={status === "ongoing" ? "green" : "red"}
                onOverview={false}
                isPlaceholder={false}
              />

              <div className="challengeAlt__time">
                {startDateFormatted && endDateFormatted && (
                  <>
                    <div className="challengeAlt__starttime">
                      {startTimeFormatted}
                    </div>
                    <div className="challengeAlt__endtime">
                      {endTimeFormatted}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  return <ChallengeCardAltLoader />;
};

export default ChallengeCardAlt;
