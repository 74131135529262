import { useEffect, useRef } from "react";
import i18next from "i18next";
import { Profile } from "@/models/database/profiles/profile.model";
import { Button } from "@/components";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export default function ProfileTabs({
  belongToCurrentUser,
  setCurrentTab,
  currentTab,
  currentUserProfile,
  slug,
  loading,
}: {
  slug: string;
  belongToCurrentUser: boolean;
  currentTab: FeedType;
  setCurrentTab: React.Dispatch<React.SetStateAction<FeedType>>;
  currentUserProfile: Profile;
  loading: boolean;
}) {
  const handleTabClick = (tab: FeedType) => {
    setCurrentTab(tab);
  };
  const tabsRef = useRef<any>({});

  // Only run code when on profile page
  if (window.location.pathname.includes("/profile")) {
    // Set feed type back to feed/posts when window is smaller the 1400
    window.addEventListener("resize", (e) => {
      // When going from desktop to mobile, and user isnt on posts tab, reset back to posts
      if (window.innerWidth < 1400 && currentTab !== "posts") {
        setCurrentTab("posts");
      }
      // When going from desktop to mobile and current tab is not posts, and then going back to desktop fails to re-add to tab marker.
      //This code wil fix that
      if (window.innerWidth === 1400) {
        if (currentTab && tabsRef.current[currentTab]) {
          const activeTab = tabsRef.current[currentTab];
          const { offsetLeft, offsetWidth } = activeTab;
          const tabs = activeTab.closest(".profile__tabs");

          tabs.style.setProperty("--tab-highlight-width", `${offsetWidth}px`);
          tabs.style.setProperty("--tab-highlight-left", `${offsetLeft}px`);
        }
      }
    });
  }

  const getProfileTabs = (
    belongsToCurrentUser: boolean
  ): { [key: string]: String | undefined } =>
    belongsToCurrentUser
      ? {
          posts: brand.hasFeature.feed ? "Feed" : undefined,
          challenges: brand.hasFeature.challenges ? "challenges" : undefined,
          gathers: brand.hasFeature.gathers ? "gathers" : undefined,
          teams: brand.hasFeature.teams ? "teams" : undefined,
          achievements: brand.hasFeature.achievements
            ? "achievements"
            : undefined,
        }
      : {
          posts: "Feed",
        };

  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    const profileTabs = getProfileTabs(belongToCurrentUser);
    if (savedTab && profileTabs[savedTab]) {
      setCurrentTab(savedTab as FeedType);
    } else {
      setCurrentTab("posts");
    }
  }, [slug, currentUserProfile?.slug, currentUserProfile?.uid]);

  useEffect(() => {
    localStorage.setItem("activeTab", currentTab);
    if (currentTab && tabsRef.current[currentTab]) {
      const activeTab = tabsRef.current[currentTab];
      const { offsetLeft, offsetWidth } = activeTab;

      const tabs = activeTab.closest(".profile__tabs");

      tabs.style.setProperty("--tab-highlight-width", `${offsetWidth}px`);
      tabs.style.setProperty("--tab-highlight-left", `${offsetLeft}px`);
    }
  }, [loading, currentTab]);

  return (
    <>
      {Object.keys(getProfileTabs(belongToCurrentUser)).map((key, index) => {
        if (
          Object.values(getProfileTabs(belongToCurrentUser))[index] !==
          undefined
        ) {
          return (
            <Button
              variant="link"
              key={key}
              reff={(el: any) => {
                tabsRef.current[key] = el;
              }}
              onClickAction={() => handleTabClick(key as FeedType)}
              customStyles={`profile__tab ${
                key === currentTab ? `profile__tab--active` : ""
              }`}
            >
              {i18next.t(`profile.tabs.${key}`)}
            </Button>
          );
        }
      })}
    </>
  );
}
