import { _callCloudFunction } from "./_callCloudFunction";
import { _shareChallengeAsPost } from "./_shareChallengeAsPost";
import { _sharePost } from "./_sharePost";
import { _setNewsFavorite } from "./_updateNewsFavourite";

export default class CloudFunctions {
  constructor() {}
  static callCloudFunction = _callCloudFunction;
  static updateNewsFavorite = _setNewsFavorite;
  static sharePost = _sharePost;
  static shareChallengeAsPost = _shareChallengeAsPost;
}
