import React from "react";
import { ReactComponent as IconArrowRight } from "../../../assets/actions/chevron-right.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";

export const SettingsTabs: React.FC<{
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrentChildTab: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  tabs: string[];
  mobileUi?: boolean;
}> = ({
  currentTab,
  setCurrentTab,
  setCurrentChildTab,
  title,
  mobileUi,
  tabs,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <div className="tabs__content">
      <ul className="tabs__buttons">
        {tabs.map((tab, i) => {
          if (tab) {
            return (
              <li
                key={i}
                onClick={() => {
                  setCurrentChildTab("");
                  setCurrentTab(tab);
                  window.scrollTo({
                    top: 0,
                  });
                }}
                className={currentTab === tab ? "active" : ""}
              >
                {tab === "Profile image" && mobileUi && (
                  // TODO:
                  // <BaseLogo
                  //   customStyles="tabs__avatar"
                  //   logoSrc={currentUser.profileLogoUrl}
                  //   name={currentUser.userName}
                  // />
                  <></>
                )}
                {tab} <IconArrowRight />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
