import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

const TrackerAlert: FC = (): JSX.Element => {
  const [link, setLink] = useState<string>();
  const getLink = async () => {
    const link = await firebase
      .database()
      .ref("/tracker-version")
      .child("spike-tracker")
      .child("link")
      .get();

    if (link) {
      setLink(link.val());
      return;
    }
  };

  useEffect(() => {
    getLink();
  }, []);

  return (
    <div className="alert">
      <div className="alert__content">
        <div className="alert__body">
          <h3>Ready to get started?</h3>
          <p>Download our tracker and dominate the leaderboards!</p>
        </div>
        <div className="alert__actions">
          <Link to={link} className="btn btn-primary btn-sm">
            Download tracker
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrackerAlert;
