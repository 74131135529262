import { FC, useState } from "react";
import { Query_shared } from "@/api/shared";

const BaseUserAvatar: FC<{
  logoUrl?: string;
  userName: string;
  customStyles?: string;
  isPlaceholder?: boolean;
  title?: string;
}> = ({ isPlaceholder = false, logoUrl, userName, customStyles, title }) => {
  const [logoError, setLogoError] = useState<boolean>(false);

  return (
    <div className={`user__avatar ${customStyles ? customStyles : ""}`}>
      <div className="avatar">
        <div className="avatar__content">
          {logoUrl && logoUrl !== undefined && (
            <img
              title={title ? title : ""}
              src={Query_shared.imageErrorHandler(logoUrl, logoError, false)}
              onError={(err) => {
                setLogoError(true);
              }}
              alt={`Avatar for ${userName}`}
            />
          )}
          {(logoUrl === undefined || logoUrl === null || logoUrl === "") &&
          name !== null ? (
            <div className="avatar__letter">
              <span>{userName && userName.slice(0, 1)}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default BaseUserAvatar;
