import { FC, useState } from "react";
import { Button } from "@/components/Button";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import QueryDB from "@/api/queryDB/common";

export type Props = {
  type: "private" | "public";
  isCurrentUserInterested: boolean;
  slug: string;
  isCurrentUserParticipant?: boolean;
  isRequestPending?: boolean;
};

const SingleGatherParticipateButton: FC<Props> = (props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [interestedLoading, setInterestedLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <>
      {!props.isRequestPending && !props.isCurrentUserParticipant && (
        <>
          {loading ? (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            </button>
          ) : (
            <Button
              customStyles="Single-gather__participate--btn"
              variant="primary"
              onClickAction={async () => {
                setLoading(true);
                await QueryDB.createNewDoc(
                  "userGatherJunction",
                  {
                    uid: currentUser.uid,
                    gatherSlug: props.slug,
                    gatherId: props.slug,
                    status: props.type === "public" ? "participant" : "pending",
                    isFavorite: true,
                    generalRole: props.type === "public" ? "member" : "random",
                    isInterested: false,
                    notificationSettings: {
                      memberRequests: true,
                      newMembers: true,
                      newPostsInFeed: true,
                      newComntsInFeed: true,
                    },
                  },
                  `${currentUser.uid}_${props.slug}`
                );
              }}
              disabled={loading}
            >
              Participate
            </Button>
          )}
        </>
      )}

      <>
        {props.isRequestPending ? (
          <Button
            customStyles="Single-gather__participate---btn"
            variant="primary"
          >
            Request pending
          </Button>
        ) : (
          <>
            {!props.isCurrentUserParticipant &&
              !props.isCurrentUserInterested && (
                <Button
                  customStyles="Single-gather__participate--btn"
                  variant="secondary"
                  onClickAction={async () => {
                    setInterestedLoading(true);
                    await QueryDB.createNewDoc(
                      "userGatherJunction",

                      {
                        uid: currentUser.uid,
                        gatherSlug: props.slug,
                        status: null,
                        gatherId: props.slug,
                        isFavorite: true,
                        generalRole:
                          props.type === "private" ? "random" : "member",
                        isInterested: true,
                        notificationSettings: {
                          memberRequests: true,
                          newMembers: true,
                          newPostsInFeed: true,
                          newComntsInFeed: true,
                        },
                      },
                      `${currentUser.uid}_${props.slug}`
                    );
                  }}
                >
                  {interestedLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Interested"
                  )}
                </Button>
              )}
          </>
        )}
      </>
    </>
  );
};

export default SingleGatherParticipateButton;
