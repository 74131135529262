import React, { FC } from "react";
// import iconUpload from "../../../../../assets/actions/upload-cloud.svg";
import { ReactComponent as IconUpload } from "../../../assets/actions/upload-cloud.svg";
import { CreateChallengeFormTypes } from "@/features/challenge/utils/CreateChallengeTypes";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import DateRangePicker from "@/components/DateRangePicker/DateRangePicker";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { ImageUpload } from "@/components/ImageUpload/ImageUpload";

export const CreateChallengeStep2: FC<{
  values: CreateChallengeFormTypes;
  errors: any;
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  isEdit: boolean;
}> = ({ values, setFieldValue, errors, isEdit }): JSX.Element => {
  return (
    <>
      {/*  Duration input */}

      <fieldset className="input-group input-group__type--date">
        <legend className="input-group__heading">
          Duration {isEdit && <small>( cannot be edited )</small>}
        </legend>
        <DateRangePicker
          errors={errors}
          name="duration"
          value={values.duration}
          setFieldValue={setFieldValue}
          isDisabled={isEdit}
        />
      </fieldset>
      {/*  Name input */}

      <fieldset className="input-group">
        <legend className="input-group__heading">Challenge name</legend>
        <BaseTextInput
          textType="text"
          value={values.name}
          placeholder="Name"
          setObjectState={setFieldValue}
          setObjectStateTargetName="name"
          error={errors && errors["name"]}
        />
        <span
          className={`input-group__help ${
            values.name.length >= 25 ? "danger" : ""
          }`}
        >
          Max. 25 characters <span> ({25 - values.name.length} left)</span>
        </span>
      </fieldset>

      {/*  Goal input */}
      <fieldset className="input-group">
        <legend className="input-group__heading">Objective</legend>
        <BaseTextInput
          textType="text"
          value={values.goal}
          placeholder="Goal"
          setObjectState={setFieldValue}
          setObjectStateTargetName="goal"
          error={errors && errors["goal"]}
        />
        <span
          className={`input-group__help ${
            values.name.length >= 70 ? "danger" : ""
          }`}
        >
          Max. 70 characters <span> ({70 - values.goal.length} left)</span>
        </span>
      </fieldset>

      {/* Description */}
      <fieldset className="input-group input-group__type--textarea">
        <legend className="input-group__heading">Description</legend>
        <BaseTextInput
          textType="textarea"
          value={values.description}
          placeholder="Description"
          setObjectState={setFieldValue}
          setObjectStateTargetName="description"
          error={errors && errors["description"]}
        />
        <span
          className={`input-group__help ${
            values.name.length >= 470 ? "danger" : ""
          }`}
        >
          Max. 470 characters{" "}
          <span> ({470 - values.description.length} left)</span>
        </span>
      </fieldset>

      {/* Logo upload */}
      <fieldset className="input-group input-group__type--file">
        <legend className="input-group__heading">
          Image <small>Max 25MB</small>
        </legend>
        <ImageUpload
          acceptType="image/*"
          setIsLoading={() => {}}
          setFieldValue={setFieldValue}
          setType={() => {}}
          value={values.logoUrl}
          name="logoUrl"
        >
          <div className="input-group__upload">
            {values.logoUrl ? (
              <span
                title={values.logoUrl.name}
                className="input-group__filename"
              >
                {values.logoUrl.name}
              </span>
            ) : (
              <IconUpload />
            )}
          </div>
          {values.logoUrl ? (
            <span title={values.logoUrl.name}>Change image</span>
          ) : (
            <span>Select image</span>
          )}
        </ImageUpload>

        {errors && errors["logoUrl"] && (
          <FormErrorMessage
            text={errors["logoUrl"] as string}
            isVisible={errors["logoUrl"]}
          />
        )}
      </fieldset>
    </>
  );
};
