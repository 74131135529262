import { FC, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ThreeDotsIcon } from "@/assets/actions/more-vertical.svg";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { Button, DropDown } from "@/components";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

const SingleGatherSettingItems: FC<{
  currentUserGeneralRole?: "owner" | "admin" | "member" | "random";
  gatherSlug: string;
  name: string;
  isPreview?: boolean;
  isCurrentUserParticipant?: boolean;
}> = (props): JSX.Element => {
  const [deleteGatherModal, openDeleteGatherModal] = useState<boolean>(false);
  const [leaveDeleteVal, setDeleteGatherVal] = useState<string>("");
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  const handleDeleteGather = async () => {
    if (props.currentUserGeneralRole !== "owner") {
      return;
    }
    await QueryDB.deleteDoc("gathers", props.gatherSlug);
    await QueryDB.deleteDoc(
      "gatherPosts",
      props.gatherSlug,
      "gatherSlug",
      props.gatherSlug
    );
    await QueryDB.deleteDoc(
      "gatherDiscussionComments",
      props.gatherSlug,
      "gatherId",
      props.gatherSlug
    );
    await QueryDB.deleteDoc(
      "userGatherJunction",
      props.gatherSlug,
      "gatherSlug",
      props.gatherSlug
    );
    navigate(siteRoutes["gathers"]);
  };

  const handleLeaveGather = async () => {
    if (props.currentUserGeneralRole === "owner") {
      createToast({
        type: "info",
        message: "You cannot leave as owner, assign a new owner first.",
      });
      return;
    }

    await QueryDB.deleteDoc(
      "userGatherJunction",
      `${currentUser.uid}_${props.gatherSlug}`
    );
    navigate(siteRoutes["gathers"]);
  };

  return (
    <>
      <BaseModalWindow
        show={deleteGatherModal}
        closeModal={() => {
          openDeleteGatherModal(false);
        }}
        size="modal--team-disband "
        title="Delete gather"
      >
        <div>
          <p>
            Deleting this gather wil remove all users, delete all discussions
            and comments related to this gather
          </p>

          <input
            type="text"
            id="delete-team-form"
            className="base-input w-100 text-center"
            placeholder="Delete gather!"
            onChange={(e) => {
              setDeleteGatherVal(e.target.value);
            }}
          />
          <div className="modal--gather-buttons">
            <Button
              onClickAction={() => {
                openDeleteGatherModal(false);
                setDeleteGatherVal("");
              }}
              type="button"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              onClickAction={() => {
                handleDeleteGather();
              }}
              type="button"
              variant="primary"
              disabled={leaveDeleteVal !== `Delete gather!`}
            >
              Delete
            </Button>
          </div>
        </div>
      </BaseModalWindow>
      <div className=" Single-gather__settings">
        <h1>{props.name}</h1>
        <DropDown logo={<ThreeDotsIcon />}>
          {props.currentUserGeneralRole === "owner" && (
            <Link
              className="dropdown__option"
              to={`/gather-settings/${props.gatherSlug}`}
            >
              Gather settings
            </Link>
          )}

          {props.isCurrentUserParticipant &&
            props.currentUserGeneralRole !== "random" && (
              <a className="dropdown__option">Notifications settings</a>
            )}

          <a className="dropdown__option">Report the gather</a>

          {props.currentUserGeneralRole !== "owner" &&
            props.isCurrentUserParticipant && (
              <a
                className="dropdown__option text-danger"
                onClick={async () => {
                  handleLeaveGather();
                }}
              >
                Leave gather
              </a>
            )}

          {props.currentUserGeneralRole === "owner" && (
            <a
              className="dropdown__option text-danger"
              onClick={() => {
                openDeleteGatherModal(true);
              }}
            >
              Delete
            </a>
          )}
        </DropDown>
      </div>
    </>
  );
};

export default SingleGatherSettingItems;
