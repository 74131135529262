export const _getWhenWasMadeOrPosted = (created: number): string => {
  const date = new Date(created);
  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds) ?? {
    seconds: 1,
    label: "second",
  };
  const count = Math.floor(seconds / interval?.seconds);
  return `${count > 0 ? count : interval?.seconds} ${interval?.label}${
    count !== 1 ? "s" : ""
  } ago`;
};
