import { FC } from "react";

const BaseUserLoader: FC = (): JSX.Element => {
  return (
    <>
      <div className="user placeholder">
        <div className="user__avatar">
          <div className="avatar">
            <div className="avatar__content" />
          </div>
        </div>
        <div className="user__content">
          <div className="user__name">
            <span className="user__username" />
            <span className="user__fullname" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseUserLoader;
