import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";

// Define your atom
export const challengeUserAtom = atom(new Map<string, any>());

const useProfileChallengeJunction = (challengeId, userId) => {
  const id = `${userId}_${challengeId}`;

  const [challengeUsers, setChallengeUser] = useAtom(challengeUserAtom);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (challengeUsers.has(id)) {
          return challengeUsers.get(id);
        }

        const doc = await collectionDoc.junctionProfileChallenge(id);

        if (doc.exists) {
          const challengeUsers = doc.data();
          setChallengeUser(
            (prevProfiles) => new Map(prevProfiles.set(id, challengeUsers))
          );
          return challengeUsers;
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [challengeId, userId, challengeUsers, setChallengeUser]);

  const challengeUser = challengeUsers.get(id);

  return { challengeUser, loadingJunction: loading, error };
};

export default useProfileChallengeJunction;
