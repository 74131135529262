import React from "react";
import { auth } from "@/api/firebase/firebase.config";
import NavigationBarUi from "./NavigationBar.ui";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
const NavigationBar: React.FunctionComponent = () => {
  const currentUser = auth.currentUser;
  const profile = useCurrentUserProfile((s) => s.user);
  return (
    <>
      {profile && currentUser && (
        <NavigationBarUi currentUser={currentUser} profile={profile} />
      )}
    </>
  );
};

export default NavigationBar;
