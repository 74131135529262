import firebase from "firebase/app";
import "firebase/functions";

export const _createNewNotification = async (
  senderUid: string,
  recipientUid: string,
  contentId: string,
  type: string,
  content: string,
  userType = "public"
) => {
  const func = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("addNewNotification");
  func({
    senderUid,
    recipientUid,
    contentId,
    type,
    content,
    userType,
  });
};
