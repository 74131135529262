import { toast } from "react-toastify";

type ToastProps = {
  type: "info" | "success" | "error" | "default" | "warning";
  message: string | any;
};
export const createToast = (props: ToastProps) => {
  switch (props.type) {
    case "info":
      toast.info(props.message);
      break;
    case "success":
      toast.success(props.message);
      break;
    case "warning":
      toast.warn(props.message);
      break;
    case "error":
      toast.error(props.message);
      break;
    case "default":
      toast(props.message);
      break;
    default:
      break;
  }
};
