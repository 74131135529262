import { FC, useState } from "react";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@/components/Button/index";
import QueryDB from "@/api/queryDB/common/index";
import CloudFunctions from "@/api//cloudFunctions/CloudFunctions";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import {
  DiscussionModalProps,
  discussionComment,
} from "../../types/DiscussionType";
import { ReactComponent as ThreeDotsIcon } from "@/assets/actions/more-vertical.svg";
import { ReactComponent as IconArrowLeft } from "../../../../assets/actions/chevron-left.svg";
import { ReactComponent as IconArrowRight } from "../../../../assets/actions/chevron-right.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { DiscussionComment } from "../discussionComment/index";

const DiscussionModalComponent: FC<DiscussionModalProps> = (props) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [showFullimage, setShowFullimage] = useState<boolean>(false);
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isToEdit, setIsToEdit] = useState<boolean>(false);
  const [editDiscussion, setEditDiscussion] = useState<{
    subject: string;
    details: string;
    mediaUrls: string[];
  }>();

  const handleDeleteDicussion = async () => {
    await CloudFunctions.callCloudFunction("getCommentsOfCurrentDiscussion", {
      gatherPostId: props.discussion!.gatherPostId,
    }).then(async (res) => {
      if (res && res.length !== 0) {
        await QueryDB.deleteDoc("gatherPosts", props.discussion!.gatherPostId);
        res.map(async (comment: discussionComment) => {
          await QueryDB.deleteDoc(
            "gatherDiscussionComments",
            comment.commentId
          );
        });
      }
      if (res && res.length === 0) {
        await QueryDB.deleteDoc("gatherPosts", props.discussion!.gatherPostId);
      }
    });
    props.setModal(false);
  };

  const handleUpdateDiscussion = async () => {
    await QueryDB.updateDoc("gatherPosts", props.discussion!.gatherPostId, {
      subject: editDiscussion!.subject,
      details: editDiscussion!.details,
      isEdited: true,
    });

    setIsToEdit(false);
  };

  return (
    <>
      <BaseModalWindow
        show={deleteModalOpen}
        closeModal={() => {
          setDeleteModalOpen(false);
        }}
        size="modal--gather-delete "
        title="Are you sure you want to delete this discussion?"
      >
        <div className="modal--gather-buttons">
          <Button
            variant="secondary"
            onClickAction={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClickAction={() => {
              handleDeleteDicussion();
            }}
          >
            Delete
          </Button>
        </div>
      </BaseModalWindow>

      <BaseModalWindow
        show={showFullimage}
        closeModal={() => {
          setShowFullimage(false);
        }}
        size="modal--gather-image"
        title=""
      >
        <div className="gather__discussion-image-modal">
          <img
            aria-hidden
            onClick={() => {
              setShowFullimage(false);
            }}
            className="cursor-pointer"
            src={props.discussion?.mediaUrls[selectedIndex]}
          />
        </div>
      </BaseModalWindow>
      <BaseModalWindow
        show={props.modal}
        closeModal={() => props.setModal(false)}
        size="modal--gather-discussion"
        title=""
      >
        <div
          className="gather__discussion--wrapper"
          aria-hidden
          onClick={() => {
            if (showEmoji) {
              setShowEmoji(false);
            }
          }}
        >
          <Row>
            {!isToEdit && (
              <Col xs={12} lg={6}>
                <div className="single-discussion">
                  <h2 className="">
                    Discussion
                    <small>
                      ({props.activeIndex}/{props.discussionsAmount})
                    </small>
                  </h2>

                  <div className="single-discussion__arrows">
                    <button
                      className="single-discussion__arrows--left"
                      type="button"
                    >
                      <IconArrowLeft
                        onClick={() => {
                          props.handleSwitchButton("prev");
                        }}
                      />
                    </button>
                    <button
                      className="single-discussion__arrows--right"
                      type="button"
                    >
                      <IconArrowRight
                        onClick={() => {
                          props.handleSwitchButton("next");
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div className={`gather__discussion--modal-post `}>
                  <div className="dropdown-gathers">
                    <h4>{props.discussion?.subject}</h4>
                    {currentUser.uid === props.discussion?.authorUid && (
                      <Dropdown className="menu_dropdown">
                        <DropdownButton
                          variant="clear"
                          className=""
                          align="end"
                          title={<ThreeDotsIcon />}
                        >
                          <Dropdown.Item
                            className=""
                            onClick={() => {
                              setIsToEdit(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={async () => {
                              setDeleteModalOpen(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                      </Dropdown>
                    )}
                  </div>
                  <div className="single-discussion__author">
                    <Link to={`/profile/${props.discussion?.authorSlug}`}>
                      <img
                        width={22}
                        height={22}
                        src={props.discussion?.authorProfileLogoUrl}
                        className=""
                      />
                      <p className="author">
                        {props.discussion?.authorUserName}
                      </p>
                    </Link>
                  </div>
                  <p className="gather__discussion--details">
                    {props.discussion?.details}
                  </p>
                  <div className="gather__discussion--media">
                    <ul className="list-unstyled ">
                      {props.discussion?.mediaUrls.map((url, index) => {
                        return (
                          <li key={url + props.discussion?.gatherPostId}>
                            <picture className="adaptive me-2 ">
                              <figure className="adaptive-photo">
                                <img
                                  onClick={() => {
                                    setSelectedIndex(index);
                                    setShowFullimage(true);
                                  }}
                                  width={102}
                                  height={102}
                                  src={url}
                                  className="cursor-pointer mb-2"
                                />
                              </figure>
                            </picture>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
            )}
            {isToEdit && (
              <Col xs={12} lg={6}>
                <div className="gather__discussion--modal-post edit">
                  <li className="gather__discussion--modal-post--item">
                    {/* Subject */}
                    <div className="single-discussion__edit">
                      <label htmlFor="subject">
                        <p>Subject</p>
                        <input
                          className="base-input"
                          type="text"
                          id="subject"
                          value={editDiscussion?.subject}
                          placeholder="Start typing..."
                          onChange={(e) => {
                            setEditDiscussion((prev: any) => ({
                              ...prev,
                              subject: e.target.value,
                            }));
                          }}
                        />
                      </label>
                    </div>
                    {/* Details */}
                    <div className="single-discussion__details">
                      <label htmlFor="Details">
                        <p>Details</p>
                        <textarea
                          className="base-input w-100"
                          value={editDiscussion?.details}
                          id="Details"
                          placeholder="Start typing..."
                          onChange={(e) => {
                            setEditDiscussion((prev: any) => ({
                              ...prev,
                              details: e.target.value,
                            }));
                          }}
                        />
                      </label>
                    </div>

                    <div className="single-discussion__buttons">
                      <Button
                        variant="secondary"
                        type="button"
                        onClickAction={() => {
                          setIsToEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={
                          !editDiscussion?.subject || !editDiscussion?.details
                            ? "secondary"
                            : "primary"
                        }
                        type="button"
                        disabled={
                          !editDiscussion?.subject || !editDiscussion?.details
                        }
                        onClickAction={async () => {
                          await handleUpdateDiscussion();
                        }}
                      >
                        Save changes
                      </Button>
                    </div>
                  </li>
                </div>
              </Col>
            )}

            <Col xs={12} lg={6}>
              <DiscussionComment
                setShowEmoji={setShowEmoji}
                showEmoji={showEmoji}
                gatherPostId={props.discussion!.gatherPostId}
                gatherId={props.gatherSlug}
              />
            </Col>
          </Row>
        </div>
      </BaseModalWindow>
    </>
  );
};
export default DiscussionModalComponent;
